import React, { useState, useEffect } from 'react';

import BannerPista from '../../images/personaje.png';
import ImgIncognito from '../../images/incognita.png';
import BtnAnterior from '../../images/btn_anterior.png';
import BtnSiguiente from '../../images/btn_siguiente.png';


import './Pistas.scss';

const Pistas = () => {
    const [valorInicial, setValorInicial] = useState(0);

    const text_pistas = [ "Es una mujer a la que le gusta el estudio.", "Tiene puesto un vestido.", "Le han dado un reconocimiento por sus estudios."]
    
    const [pista, setPista] = useState("Es una mujer a la que le gusta el estudio.");

    const cambioPista = (valor) => {
        let valorActual = valorInicial + valor;
        setValorInicial(valorActual);

        for (let index = 0; index < text_pistas.length; index++) {
            const element = text_pistas[valorActual];
            setPista(element);
            console.log(element);            
        }
    }

    useEffect(() => {
    
    }, [valorInicial])

    return (
        <div className='pistas'>
            <img className='img_incognito' src={BannerPista} alt='imagen'/>
            <img className='icono_incognito' src={ImgIncognito} alt='imagen'/>
            <p className='texto_pista'>{pista}</p>
            <div className='botones'>
                {
                    valorInicial === 0 ? null : <img src={BtnAnterior} alt='boton' onClick={() => cambioPista(-1)}/>
                }                
                <p className='text_pista'>PISTAS</p>
                {
                    valorInicial === 2 ? null : <img src={BtnSiguiente} alt='boton' onClick={() => cambioPista(1)}/>
                }
                
            </div>
        </div>
    )
}

export default Pistas