import {useEffect, useContext} from 'react';
import './Personajes.scss';
import Pistas2 from '../pistas/Pistas2';
import CorrectoPopUp from '../mensajes popUp/correcto/CorrectoPopUp';
import IncorrectoPopUp from '../mensajes popUp/incorrecto/IncorrectoPopUp';
import ContextProvider from '../../context/ContextProvider';



const Personajes = () => {
	const {correcto, incorrecto, setCorrecto, setIncorrecto} = useContext(ContextProvider)
	const obtenetID = (e) => {
		const parentDiv = e.target.parentNode.id;
		console.log(parentDiv);

		if(parentDiv === "personaje2"){
			setCorrecto(true);
		}else{
			setIncorrecto(true);
		}
	}

  useEffect(() => {
  
  }, [correcto, incorrecto]);

  return (
    <div className='contenido_personajes'>
        {
          (correcto) ? <CorrectoPopUp id="personaje2"></CorrectoPopUp> : null
        }

		{
          (incorrecto) ? <IncorrectoPopUp></IncorrectoPopUp> : null
        }
        
        <div className='img_personaje'> 
          <svg
            version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
            x="0px" y="0px"
            width="857px" height="673px" 
            viewBox="0 0 857 673" 
            onClick={obtenetID}

          >
  <g id="fondo">
    <g>
      <rect x="5.54" y="8.06" width="847.46" height="658.16"/>
    </g>
    <rect x="8.34" y="10.42" fill="#78BA4A" width="841.92" height="653.79"/>
    <path fill="#96CF5E" d="M850.25,193.13V10.42H8.34v179.39C269.08,174.07,573.22,166.55,850.25,193.13z"/>
    <rect x="8.34" y="10.42" fill="#6EB3F2" width="841.92" height="157.75"/>
    <rect x="8.34" y="166.82" width="841.92" height="2.02"/>
    <path fill="#9CD1F7" d="M8.34,127.78v40.39h841.92v-20.23C641.56,117.08,251.64,116.55,8.34,127.78z"/>
  </g>
  <g id="item_21_">
    <g>
      <path d="M51.5,91.68c0.7-0.29,4.56-2.8,5.66-3.45c-0.3-1.21-1.47-5.12-1.7-5.8c1.46-1.21,2.36-3.49,2.49-5.88
        c0.12-2.25-0.89-3.93-1.79-5.93c-0.62-1.38-0.68-2.16-0.36-3.68c0.28-1.35,0.76-2.82,0.67-2.87c-3.02-1.69-5.11,1.8-4.67,4.44
        c0.25,1.52,0.97,2.83,1.38,3.87c0.26,0.67,0.06,1.41-0.32,1.52c-0.95-2.01-3.93-10.08-4.56-11.37c-0.97-1.98-2.09-4.7-4.28-4.08
        c-0.38,0.11-0.56,0.56-0.16,1.76c-1.1-2.69-2.6-3.02-3.51-2.26c-0.35,0.29-0.07,1.72,0.3,2.93c-0.38-0.39-0.94-0.84-1.5-0.28
        c-0.57,0.57,0.02,2.85,0.33,3.92C38,65.7,40.72,74.68,42.81,78.69c1.36,2.61,3.65,6.13,6.5,5.83
        C49.88,87.18,50.81,89.45,51.5,91.68z"/>
      <path fill="#F7D1BD" d="M50.23,84.14c0.59,2.39,1.33,4.58,2.07,6.82c0.71-0.29,4.21-2,4.22-2.01
        c-0.38-1.21-1.4-4.74-1.92-6.03c-0.22,0.04-2.14,0.51-1.97-0.16c1.81-0.8,3.25-1.79,3.96-3.7c0.73-1.96,0.71-4.29-0.04-6.22
        c-0.52-1.33-1.52-2.67-1.59-4.13c-0.08-1.59,0.46-2.71,0.93-4.23c-1.23-0.93-3.05,0.2-3.41,2.16c-0.17,0.94-0.08,1.94,0.17,2.84
        c0.29,1.05,0.99,2.01,1.26,3.07c0.3,1.19-0.09,1.65-1.48,2.25c-1.82,0.79-3.64,2.16-3.87,5.27c-0.94-0.68,0.23-4.99,3.73-5.89
        c-1.27-2.94-2.46-6.16-3.62-9.14c-0.53-1.36-1.39-3.48-2.2-4.69c-1.03-1.54-1.6-1.59-2.21-1.35c-0.36,0.14-0.08,1.16,0.53,2.7
        c1.46,3.57,3.14,8.02,2.37,8.03c-0.8-2.24-3.04-8.28-3.96-9.89c-0.92-1.6-1.64-2.01-2.46-1.57c-0.51,0.27,1.14,3.71,2.07,6.38
        c0.93,2.67,2.83,6.77,1.83,7.19c-0.62-2.66-2.72-7.76-3.29-9.14c-0.57-1.38-1.33-2.24-1.82-1.85c-1.18,0.93,4.26,11.46,3.19,11.66
        c-0.7-2.17-2.43-5.9-2.89-7.47c-0.54,0.2-0.59,1.58,0.14,4.16c0.61,2.17,1.92,5.46,2.53,6.99c0.75,1.9,2.55,5.24,4.24,6.46
        c1.22,0.88,2.85,0.87,4.54,0.42C51.58,83.65,50.45,84.07,50.23,84.14z"/>
      <path fill="#FAE3D6" d="M53.18,75.53c-1.09,0.17-3.36,2.2-3.49,4.23C49.56,81.79,55.74,75.12,53.18,75.53z"/>
      <path fill="#FAE3D6" d="M48.15,70.37c-0.89,0.09-4.29,1.9-4.54,4.1C43.36,76.67,50.23,70.15,48.15,70.37z"/>
      <path d="M55.34,67.26c-0.5-0.19-1.01-0.49-0.93-1.09c0.1-0.79,0.71-1.19,1.44-1.28c0.05-0.01,0.04-0.08,0-0.09
        c-0.86-0.2-1.64,0.66-1.64,1.49c0,0.59,0.53,1.04,1.08,1.2C55.31,67.41,55.32,67.33,55.34,67.26z"/>
      <path d="M48.7,69.59c-2.36,0.92-4.71,2.36-5.55,4.44C42.76,72.03,47.21,69.16,48.7,69.59z"/>
    </g>
    <path d="M104.93,203.62c0.06-6.59,0.16-13.17-0.07-19.76c-0.1-2.93-0.51-4.84-0.72-7.76c-0.1-1.35,0.95-26.13,1.26-32.36
      c3.72-0.54,7.98-1.63,9.76-2.26c-0.48-2.97-0.98-5.63-1.38-8.61c-0.42-3.15-0.85-6.28-1.31-9.42c1.8-2.69,4.8-4.3,6.12-7.35
      c0.66-1.52,0.47-3.22-0.24-4.65c-1-2.02-1.44-4.22-1.96-6.4c-1.09-4.57-2.1-9.14-3.3-13.69c-0.61-2.32-3.1-18.52-3.73-19.42
      c-0.56-0.79-7.29-1.36-11.35-1.66c-0.85-0.32-2.78-0.55-3.13-0.64c0.12-2.3,0.06-5.96,0.04-7.28c-0.06-3.43-0.2-6.74-0.46-8.4
      c1.77,1.54,2.67-0.18,2.99-1.05c0.43-1.18,0.42-2.59,0.47-3.84c0.06-1.39,0.05-3.13-0.37-4.83c0.65-1.18,1.23-2.97,1.36-5.84
      c0.08-1.83-0.46-3.69-1.2-5.33c0.3-0.04,0.88,0.16,1.23,0.31c-0.17-2.37-1.65-4.51-4.37-5.47c0.43-0.32,1.15-0.45,1.7-0.52
      c-1.17-2.18-5.3-4-8.16-3.85c0.06-0.14,0.21-0.66,0.21-0.89c-3.82-0.79-10.63,0.72-14.26,3.26c0-0.14-0.06-0.57-0.06-0.72
      c-0.53,0-1.54,0.56-2.02,1.09c-0.05-0.41-0.21-1.15-0.15-1.59c-0.66,0.27-1.96,1.58-2.51,2.51c-2.13,3.59-1.85,8.83-0.08,12.39
      c0.03,0.51,0.08,1,0.14,1.49c0.07,1.04,0.18,2.18,0.32,3.22c-0.34,0.1-0.73,0.15-0.99,0.4c-0.21,0.21,0.35,0.39,1.22,0.44
      c0.14,0.43,0.32,0.84,0.56,1.23c-0.7,1.08-1.02,2.5-1.07,3.69c-0.04,0.99-0.03,2.23,0.62,3.08c-0.46,1.55,0.22,4.87,0.37,5.37
      c-1,1.06-0.6,1.97,0.89,2.45c0.79,2.07,3.92,6.89,5.81,9.63c-1.81,0.25-3.3,0.65-3.38,0.73c-0.09,0.07-0.08,0.34-0.05,0.46
      c-4.71-0.02-9.27,0.83-10.06,1.65c-0.7,0.73-0.48,21.52-0.05,28.68c-0.31-0.84-0.64-1.68-0.99-2.51
      c-0.72-1.74-4.87-10.82-5.09-14.47c-2.17,0.08-6.83,3.23-8.04,5.33c1.93,6.06,9.82,19.2,11.47,22.32c1.25,2.36,2.75,5.58,5.31,6.76
      c1.02,0.47,2.11,0.22,2.88-0.57c0.4-0.4,0.69-0.88,0.92-1.39c0,0.17,0,0.33,0,0.5c0.07,6.79,0.47,23.63,0.37,25.72
      c1.96,0.93,5.22,0.85,7.75,0.86c0.23,8.1-1.48,50.56-2.62,67.03c-0.25,3.55,0.14,10.36,0.15,10.54c0.23,0.29,0.82,0.54,1.16,0.65
      c-6.15,0.63-13.75,1.48-14.15,6.85c1.1,0.93,5.98,1.34,7.87,1.27c-0.94,0.93-1.74,2.33-1.72,4.24c0.02,1.34,27.79,0.93,35.7-1.82
      c0.43-1.56,0.22-5.58-0.29-7.23c1.31-0.18,2.41-0.66,2.41-1.05c0-0.71-0.39-4.58-1.18-9.56
      C104.64,210.4,104.87,208.98,104.93,203.62z"/>
    <path fill="#4275C4" d="M99.05,86.6c1.42,0.55,2.42,1.34,2.69,1.95c-0.35,0.91-0.81,1.85-1.14,3.26
      c-1.29,5.56-1.74,29.24-1.54,33.36c0.14,2.98,0.17,10.23,0.11,13.22c-0.02,0.95-0.08,2.74-0.16,4.88c4-0.23,11.2-1.32,14.65-2.61
      c-0.36-2.35-0.72-4.66-1.04-6.86c-0.93-6.38-1.86-11.79-2.9-18.15c-1.03-6.35-2.19-12.69-2.93-19.08
      c-0.73-6.32-1.21-12.7-0.48-19.03c0.49-0.06,0.49,1.47,0.55,4.25c0.06,2.78,0.42,8.44,0.93,12.62c0.8,6.54,1.94,13.03,3.12,19.52
      c0.46,2.49,0.87,4.98,1.25,7.47c1.7-2.13,4.39-3.53,5.46-6.09c0.34-0.83,0.49-1.67,0.16-2.53c-0.34-0.87-0.85-1.68-1.1-2.59
      c-0.6-2.14-1.08-4.32-1.56-6.49c-0.99-4.48-2.12-8.9-3.22-13.36c-0.53-2.18-2.77-17.54-3.08-17.91c-0.31-0.37-4.17-1.06-10.54-1.54
      C98.69,73.59,99.28,83.85,99.05,86.6z"/>
    <path fill="#6C9CD9" d="M108.11,73.13c0.24,0.34,1.95,11.72,2.36,14.18c0.55,3.28,1.54,7.33,2.3,10.62
      c0.76,3.29,2.56,10.56,2.99,12.12c0.26,0.93,1.58,2.67,2.18,4.12c0.06-0.46,0.02-0.92-0.16-1.38c-0.34-0.87-0.85-1.68-1.1-2.59
      c-0.6-2.14-1.08-4.32-1.56-6.49c-0.99-4.48-2.12-8.9-3.22-13.36c-0.53-2.18-2.77-17.54-3.08-17.91c-0.31-0.37-4.17-1.06-10.54-1.54
      c0.03,0.18,0.05,0.39,0.08,0.62C101.55,71.74,107.88,72.79,108.11,73.13z"/>
    <path fill="#2C57A2" d="M110.33,103.53c-1.25-7.42-2.4-15.96-3.52-23.81c0.02,0.59,0.03,1.28,0.05,2.08
      c0.06,2.78,0.42,8.44,0.93,12.62c0.8,6.54,1.94,13.03,3.12,19.52c0.46,2.49,0.87,4.98,1.25,7.47c0.78-0.97,1.76-1.79,2.69-2.63
      C112.67,115.03,111.01,107.56,110.33,103.53z"/>
    <path fill="#2C57A2" d="M101.26,99.12c0.14-1.95,0.34-5.6,1.66-8.53c-0.34-0.44-1.03-0.77-1.6-1.03
      c-0.26,0.63-0.52,1.34-0.73,2.25c-1.29,5.56-1.74,29.24-1.54,33.36c0.08,1.57,0.12,4.32,0.14,7.01c0.41-6.58,1.25-13.13,1.53-19.72
      C100.92,108.02,100.94,103.56,101.26,99.12z"/>
    <path fill="#BFE3F5" d="M79.85,79c0.14-0.21,0.29-0.41,0.43-0.61c0.49-0.71,1-1.42,1.56-2.08c0.52-0.63,1.07-1.23,1.63-1.82
      c-0.01,0-0.02,0-0.03,0c-1.31-0.11-2.21-0.27-3.06-0.58c-0.36-0.13-0.68-0.32-0.97-0.53c0.06,0.46,0.12,1.39,0.17,2.23
      C79.6,75.89,79.71,77.3,79.85,79z"/>
    <path fill="#BFE3F5" d="M88.05,77.16c1.01,1.17,1.94,2.43,2.75,3.75c1.64-1.66,2.74-3.89,3.37-6.21
      c-0.05-0.91-0.11-1.78-0.18-2.61c-1.28,0.99-3.6,2.2-5.11,2.39c-1.11,0.14-2.43,0.15-3.59,0.11C86.22,75.43,87.23,76.2,88.05,77.16
      z"/>
    <path fill="#9CC9E3" d="M81.13,75c0.55,0.13,1.1,0.18,1.65,0.22c0.22-0.25,0.45-0.49,0.68-0.73c-0.01,0-0.02,0-0.03,0
      c-1.31-0.11-2.21-0.27-3.06-0.58c-0.36-0.13-0.68-0.32-0.97-0.53c0.02,0.15,0.04,0.35,0.06,0.57C79.94,74.41,80.45,74.83,81.13,75z
      "/>
    <path fill="#9CC9E3" d="M90.35,74.08c-0.53,0.2-1.04,0.34-1.48,0.4c-1.11,0.14-2.43,0.15-3.59,0.11
      c0.5,0.45,1.03,0.88,1.53,1.32C88.05,75.64,89.29,74.96,90.35,74.08z"/>
    <path fill="#DAEEFA" d="M87.71,76.79c0.11,0.12,0.23,0.24,0.34,0.37c1.01,1.17,1.94,2.43,2.75,3.75
      c0.2-0.2,0.38-0.4,0.56-0.62C90.56,79.27,89.02,77.29,87.71,76.79z"/>
    <path fill="#BFE3F5" d="M79.46,117.83c-0.33,2.26-1.11,7.48-1.34,12.52c1.41,0.38,2.84,0.72,3.93,0.8
      c2.41,0.19,4.87,0.14,7.29,0.06c2.55-0.08,5.01-0.51,7.5-1.05c0.39-0.08,0.77-0.18,1.15-0.27c-0.04-1.87-0.09-3.93-0.17-5.29
      c-0.19-3.41-1.46-15.62-1.75-17.87c-0.8-6.18-1.64-12.35-1.6-18.59c0.02-3.28-0.03-7.42-0.2-11.28c-0.75,1.88-2.63,4.34-3.73,5.19
      c-0.82-1.37-4.72-6.15-5.81-7.33c-0.21,16.81,0,43.13,0.84,55.77c-1.98-1.57-2.19-43.13-1.46-55.56c-0.43,0.53-3.89,4.77-4.15,5.43
      c0.21,2.54,0.43,5.25,0.46,5.86c0.09,1.76,0.17,3.51,0.27,5.26c0.36,5.85,0.68,10.62,0.04,16.47
      C80.43,110.75,79.93,114.61,79.46,117.83z"/>
    <path fill="#9CC9E3" d="M90.31,84.58c2.37-2.18,3.48-4.5,4-6.98c-0.01-0.24-0.02-0.49-0.03-0.73
      c-0.75,1.88-2.63,4.34-3.73,5.19c-0.82-1.37-4.72-6.15-5.81-7.33c-0.01,0.61-0.01,1.24-0.02,1.87
      C84.72,77.03,89.14,81.58,90.31,84.58z"/>
    <path fill="#9CC9E3" d="M84.03,76.55c0.03-0.58,0.05-1.12,0.08-1.62c-0.43,0.53-3.89,4.77-4.15,5.43
      c0.04,0.51,0.08,1.03,0.13,1.54C81.29,80.44,84.02,76.9,84.03,76.55z"/>
    <path fill="#7D3D24" d="M89.49,135.23c2.53-0.12,5.38-0.45,8.57-1.04c0-0.09-0.01-1.6-0.05-3.47
      c-0.32,0.08-0.64,0.15-0.96,0.21c-2.31,0.46-4.58,0.95-6.94,1.05c-0.24,0.01-0.48,0.02-0.73,0.03
      C89.5,133.09,89.56,134.16,89.49,135.23z"/>
    <path fill="#613117" d="M89.49,135.23c0.2-0.01,0.4-0.02,0.6-0.03c0.04-0.4,0.07-0.8,0.09-1.21c0.02-0.68-0.03-1.34-0.11-2
      c-0.23,0.01-0.46,0.02-0.68,0.03C89.5,133.09,89.56,134.16,89.49,135.23z"/>
    <path fill="#CFA627" d="M88.6,132.04c-1.81,0.06-3.64,0.09-5.43-0.01c-0.12,1,0.03,2.02,0.41,3.06
      c1.5,0.17,3.21,0.24,5.12,0.18C88.78,134.19,88.72,133.12,88.6,132.04z"/>
    <path fill="#E3CC78" d="M88.6,132.04c-0.32,0.01-0.64,0.02-0.95,0.03c0.05,0.9,0.12,1.8,0.06,2.71
      c-0.01,0.17-0.02,0.34-0.04,0.51c0.34,0,0.68-0.01,1.03-0.02C88.78,134.19,88.72,133.12,88.6,132.04z"/>
    <path fill="#7D3D24" d="M82.38,131.98c-1.04-0.08-2.76-0.28-4.28-0.86c-0.03,0.83-0.04,1.65-0.03,2.45
      c1.15,0.62,2.7,1.11,4.65,1.4C82.4,133.97,82.27,132.97,82.38,131.98z"/>
    <path fill="#613117" d="M97.05,130.93c-0.37,0.07-0.74,0.15-1.11,0.22c0.04,1.13,0.07,2.26,0.09,3.38
      c0.66-0.1,1.34-0.22,2.03-0.35c0-0.09-0.01-1.6-0.05-3.47C97.69,130.8,97.37,130.87,97.05,130.93z"/>
    <path fill="#9CC9E3" d="M94.19,103.6c0.41,3.35,0.61,6.71,0.75,10.09c0.1,2.51,0.19,5.02,0.34,7.53
      c0.15,2.5,0.41,5,0.55,7.5c0.03,0.55,0.05,1.1,0.08,1.65c0.31-0.06,0.62-0.13,0.93-0.2c0.39-0.08,0.77-0.18,1.15-0.27
      c-0.04-1.87-0.09-3.93-0.17-5.29c-0.19-3.41-1.46-15.62-1.75-17.87c-0.8-6.18-1.64-12.35-1.6-18.59c0.02-3.07-0.02-6.89-0.17-10.52
      c-0.38,5.42-1.42,8.61-1.17,14.05C93.29,94.93,93.8,100.36,94.19,103.6z"/>
    <path fill="#9CC9E3" d="M79.46,117.83c-0.27,1.83-0.83,5.61-1.16,9.66c0.8-3.78,2.98-12.56,3.45-19.56
      c0.51-7.63-0.33-12.35-1.25-19.9c0.06,1.15,0.11,2.3,0.18,3.46c0.36,5.85,0.68,10.62,0.04,16.47
      C80.43,110.75,79.93,114.61,79.46,117.83z"/>
    <path fill="#4275C4" d="M97.68,70.86c0.47,2.81,0.72,13.26,0.64,15.51c-0.44-0.13-1.58-0.45-1.7,0
      c1.19,0.44,3.74,1.7,4.04,2.18c-1.12,2.72-1.61,6.55-1.7,9.26c-0.14,4.74-0.6,14.6-0.74,21.34c-0.57-7.61-2.84-24.58-2.8-30.96
      c0.03-5.08-0.15-12.55-0.9-16.55c0.07-0.29,0.25-0.68,0.3-1.29C95.29,70.36,97.24,70.66,97.68,70.86z"/>
    <path fill="#6C9CD9" d="M97.77,71.58c-0.03-0.27-0.06-0.51-0.1-0.71c-0.44-0.2-2.39-0.5-2.86-0.51
      c-0.02,0.22-0.05,0.41-0.09,0.57C95.78,70.99,96.8,71.21,97.77,71.58z"/>
    <path fill="#6C9CD9" d="M100.43,89.13c0.07-0.2,0.15-0.39,0.22-0.58c-0.28-0.46-2.65-1.64-3.89-2.12
      C97.96,87.32,100.16,89,100.43,89.13z"/>
    <path fill="#E0A61F" d="M69.37,38.57c-1.85-3.91-1.25-10.09,2.01-13.02c0.02,0.42,0.2,1.3,0.38,1.66
      c0.37-0.62,1.15-1.24,1.81-1.46c0,0.18,0.07,0.71,0.24,0.85c3.19-2.18,9.15-4.04,13.94-3.47c-0.02,0.14-0.09,0.35-0.18,0.5
      c-0.52,0.14-1.72,0.61-1.63,0.99c3.12-1.59,7.75,0.47,9.41,2.46c-0.52,0.07-1.35,0.39-1.59,0.87c2.76,1.2,4.33,3.02,4.49,4.71
      c-0.23-0.09-0.95-0.19-1.23,0.08c1.69,3.37,1.4,7.66,0.22,10.82c-0.28-0.72-0.68-1.35-1.31-1.49c-1.14-0.25-2.2,0.88-2.67,1.89
      c-0.08,0.17-0.16,0.42-0.21,0.68c-0.78,0.9-1.19,2.18-1.42,3.34c-0.58-1.52-1.21-4.51-1.36-6.48c-0.64,0.52-1.36,1.83-1.76,2.69
      c-0.12-1.14-0.01-2.29,0-3.43c-0.7,0.01-1.42,2.37-1.8,3.67c-0.35-1.16-0.55-2.31-0.58-3.54c-0.76,0.08-0.87,2.97-1.06,4.48
      c-0.68-0.54-1.7-4.61-1.75-7.41c-0.16-0.17-0.31-0.34-0.45-0.52c-0.23,1.23-0.43,2.8-0.83,3.54c-1.23-1.79-1.84-4.03-2.48-6.1
      c-0.61,0.32-1.41,1.36-1.8,2.1c-0.26-0.62-0.5-1.49-0.61-2.28c-0.69,0.38-1.4,1.87-1.71,3.07c-0.47-0.86-0.75-1.81-0.85-2.81
      c-0.52,0-0.99,1.9-1.14,2.9c-1.62-2.31-2.95-4.91-3.71-7.62c-0.42,0.23,0.37,2.67,0.56,3.5C69.56,35,69.37,36.89,69.37,38.57z"/>
    <path fill="#EBC575" d="M71.07,26.83c0.05,0.48,0.13,0.94,0.25,1.4c0.44-0.63,1.29-1.43,1.83-1.58
      c0.02,0.41,0.16,0.98,0.26,1.37c2.41-2.48,8.9-4.64,14.21-4.45c0.08-0.14,0.13-0.32,0.15-0.45c-4.79-0.57-10.76,1.29-13.94,3.47
      c-0.17-0.13-0.24-0.67-0.24-0.85c-0.67,0.22-1.44,0.84-1.81,1.46c-0.18-0.36-0.36-1.24-0.38-1.66c-1.47,1.32-2.4,3.3-2.81,5.43
      C68.84,30.2,70.5,27.07,71.07,26.83z"/>
    <path fill="#EBC575" d="M94.72,27.25c0.23-0.09,0.46-0.15,0.65-0.17c-1.63-1.96-6.15-3.98-9.27-2.52
      C89.19,24.13,92.67,25.07,94.72,27.25z"/>
    <path fill="#EBC575" d="M98.01,32.59c0.1,0.02,0.19,0.04,0.25,0.06c-0.16-1.6-1.59-3.33-4.1-4.53
      C95.7,29.33,97.54,31.18,98.01,32.59z"/>
    <path fill="#BF851C" d="M69.49,36.45c0.13-1.01,0.38-1.98,0.82-2.74c-0.12-0.51-0.47-1.66-0.62-2.5
      C68.84,32.76,68.98,34.7,69.49,36.45z"/>
    <path fill="#FFFFFF" d="M70.08,50.29c0.13-1.57,0.7-3.34,1.97-4.31c2.16-1.64,2.52,2.03,2.4,3.33
      c-0.1,1.04-0.4,2.09-1.03,2.93c-0.58,0.77-1.36,1.13-2.11,0.92C70.56,52.96,69.93,52.1,70.08,50.29z"/>
    <path fill="#F7D1BD" d="M70.52,45.18c0.41,0,0.83-0.02,1.24-0.04c-0.41,0.24-0.76,0.57-1.05,0.95
      C70.64,45.79,70.58,45.49,70.52,45.18z"/>
    <path fill="#F7D1BD" d="M76.68,59.48c-2.13,0.9-5.96,0.37-5.3-0.48c1.58-2.01,4.01-4.82,4.17-8.12
      c0.01-0.15-0.19-0.21-0.25-0.07c-0.79,1.94-3.26,6.12-4.32,7.11c-0.25-1-0.7-3.28-0.4-4.41c0.43,0.42,1.9,0.96,3.09-0.38
      c0.94-1.06,1.33-2.32,1.53-3.69c0.19-1.28,0-3.53-1.09-4.37c0.54-0.08,1.41-0.44,1.42-1.02c-0.93-0.46-4.42,0.01-5.18,0.14
      c-0.5-3.83-0.56-7.5,0.27-10.03c0.75,1.66,2.19,4.17,3.35,4.81c0.08-0.86,0.17-2.02,0.35-2.85c0.18,0.83,0.72,2.37,1.37,2.52
      c0.29-1.04,0.59-2.26,1.23-3.11c0.14,0.71,0.53,2.05,0.92,2.23c0.37-0.73,0.85-1.64,1.47-2.17c0.68,2.2,1.33,4.29,2.75,6.16
      c0.48-0.09,0.79-1.72,0.92-2.55c0.13,3.17,1.49,7.26,2.43,7.22c0.17-1.09,0.3-2.18,0.43-3.27c0.14,0.75,0.49,2.38,1.13,2.19
      c0.29-1.16,0.62-2.31,1.06-3.43c-0.01,1-0.12,2.93,0.55,3.03c0.39-0.89,0.82-1.75,1.38-2.53c0.26,2.18,0.81,5.33,1.42,6.44
      c0.04,0.01,0.4,0.19,0.53,0.27c0.15-1.28,0.46-2.77,1.15-3.81c0.04,0.15,0.11,0.47,0.24,0.49c0.19-0.69,0.47-1.35,0.84-1.95
      c0.26-0.41,0.74-0.93,1.26-1.03c0.66-0.13,1.02,0.63,1.38,1.78c0.55,1.79,0.45,4.46,0.32,6.29c-0.07,0.88-0.12,2.09-0.71,2.49
      c-1.38,0.95-2.47-1.17-2.97-1.93c-0.34,0.39,0.06,1.46,0.33,1.93c0.66,8.17,0.31,17.11-0.02,17.56c-1.21,1.67-4.54,2.55-5.68,2.55
      c1.08-1.19,4.72-7.49,4.95-8.6c0.23-1.11,0.17-4.66-0.48-4.55c0.07,0.78-0.05,3.67-0.22,4.37c-0.17,0.7-4.67,7.65-5.56,8.2
      c-1.32,0.82-5.06,0.28-6.03-0.06c-0.93-0.33-1.6-1.14-2.17-1.92c-0.59-0.81-5.64-8.2-6.24-9.71
      C75.07,61.52,77.77,60.59,76.68,59.48z"/>
    <path fill="#FAE3D6" d="M75.51,51.33c-0.34,3.1-2.62,5.75-4.13,7.67c-0.27,0.34,0.21,0.64,1.01,0.8
      C73.03,59.05,76.51,54.32,75.51,51.33z"/>
    <path fill="#E3B8A3" d="M95.89,45.84c-1.18-0.48-1.54,1.58-1.57,2.35c-0.02,0.51-0.04,1.09,0.16,1.57
      c0.27,0.66,0.94,0.97,1.45,0.34c0.5-0.62,0.52-1.58,0.49-2.34c-0.02-0.61-0.07-1.41-0.48-1.92C95.92,45.85,95.9,45.85,95.89,45.84z
      "/>
    <path fill="#FAE3D6" d="M94.93,43.65c0.87-0.24,1.36,0.69,1.66,1.37c0.24,0.57,0.43,1.16,0.56,1.76
      c-0.06-0.79-0.17-1.56-0.37-2.2c-0.35-1.15-0.71-1.91-1.38-1.78c-0.52,0.1-1,0.62-1.26,1.03c-0.22,0.34-0.4,0.71-0.55,1.09
      C93.96,44.37,94.38,43.8,94.93,43.65z"/>
    <path fill="#E3B8A3" d="M74.24,40.47c0.03-0.43,0.26-1.2,0.42-1.41c0.23,0.5,0.75,1.03,1.27,1.2
      c0.24-0.79,0.68-1.76,1.23-2.14c0.11,0.57,0.46,1.57,0.97,2c0.14-0.72,0.58-1.58,1.14-1.92c0.96,1.9,1.6,4.05,3.23,5.49
      c0.05-0.22,0.2-0.67,0.41-0.78c0.74,2.44,1.57,4.64,2.78,5.17c0.03-0.54,0.2-1.25,0.51-1.61c0.19,0.48,0.69,0.97,1.19,1.01
      c0.05-0.41,0.24-0.92,0.46-1.29c0.33,0.32,0.59,0.71,0.97,0.97c0.15-0.63,0.46-1.21,0.86-1.72c0.19,0.94,0.79,1.63,1.27,2.28
      c-0.43-1.46-0.8-3.68-0.99-5.33c-0.56,0.79-0.99,1.65-1.38,2.53c-0.67-0.1-0.56-2.02-0.55-3.03c-0.44,1.11-0.77,2.27-1.06,3.43
      c-0.63,0.2-0.99-1.44-1.13-2.19c-0.13,1.09-0.26,2.18-0.43,3.27c-0.94,0.04-2.3-4.05-2.43-7.22c-0.13,0.83-0.44,2.45-0.92,2.55
      c-1.42-1.87-2.07-3.96-2.75-6.16c-0.62,0.53-1.1,1.44-1.47,2.17c-0.38-0.17-0.78-1.52-0.92-2.23c-0.64,0.85-0.95,2.08-1.23,3.11
      c-0.65-0.15-1.19-1.7-1.37-2.52c-0.18,0.83-0.27,1.99-0.35,2.85c-1.16-0.64-2.6-3.14-3.35-4.81c-0.16,0.5-0.29,1.05-0.39,1.63
      C71.14,37.98,73.26,39.91,74.24,40.47z"/>
    <path fill="#E3B8A3" d="M79.12,65.26c0.32,0.71,0.88,1.78,2.06,2.34c1.58,0.74,3.2-0.85,3.01-2.34
      C81.45,65.47,80.11,65.29,79.12,65.26z"/>
    <path fill="#E3B8A3" d="M91.88,72.37c1.1-3.21,1.77-6.58,1.22-9.9c0.04,0.93-0.01,1.91-0.11,2.4
      c-0.23,1.11-3.87,7.41-4.95,8.6C88.79,73.48,90.47,73.1,91.88,72.37z"/>
    <path fill="#E3B8A3" d="M72.3,61.21c0.13,0.33,0.47,0.94,0.94,1.71c0.07,0.11,3.76-0.2,3.62-2.68
      C76.47,60.97,74.41,61.44,72.3,61.21z"/>
    <path fill="#4275C4" d="M77.44,71.08c0.57,0.82,1.24,1.64,1.4,1.79c0.08,2.59,0.24,7.49,0.37,9.88
      c0.15,2.8,0.25,5.59,0.39,8.39c0.28,5.68,0.64,11.28,0.07,16.96c-1.23-7.72-3.3-15.58-6.4-19.73c1.12-2.28,3.46-4.59,4.3-5.18
      c-0.47-0.41-1.54,0.39-1.95,0.71c-0.11-1.8,0.04-5.49-0.07-6.88c-0.24-2.79-1.38-5.14-1.33-5.34C74.26,71.5,76.7,71.18,77.44,71.08
      z"/>
    <path fill="#6C9CD9" d="M77.76,71.52c-0.11-0.15-0.22-0.29-0.32-0.44c-0.74,0.1-3.17,0.42-3.22,0.62
      c-0.02,0.06,0.08,0.32,0.23,0.74C75.42,72.06,76.6,71.68,77.76,71.52z"/>
    <path fill="#6C9CD9" d="M73.27,88.38c0.18,0.24,0.35,0.49,0.52,0.75c0.85-1.89,2.07-3.58,3.1-5.38
      C75.83,84.72,74.16,86.56,73.27,88.38z"/>
    <path fill="#4275C4" d="M68.32,118.05c0.53-0.59,1.52-2.09,1.67-3.8c-0.04-5.45-0.08-10.93-0.61-16.33
      c-0.37-3.75-0.48-6.98-0.56-10.72c-0.06-2.56-0.12-7.82,1.02-8.12c-0.12,3.74,0.02,12.66,0.39,15.74c0.38,3.14,0.59,6.27,0.71,9.44
      c0.11,3,0.71,35.82,0.69,38.7c1.93,0.34,4.65,0.78,6.45,0.52c-0.3-4.71-1.07-8.09-1.01-12.16c0.07-4.77,1.48-16.82,2.08-20.39
      c-0.71-8.79-3.95-18.34-7.03-22.42c0.36-0.9,1.93-3.12,2.74-4.02c-0.3-0.56-0.18-4.13-0.14-5.45c0.08-2.23-0.36-4.41-0.92-6.56
      c-1.44-0.32-9.08,0.35-9.54,1.42c-0.44,1.05-0.03,25.37,0.42,33.64c-0.72,0.39-0.92-1.53-1.02-2.95c-0.1,0.22-0.56,2.24-0.53,3.08
      c-1.22-0.22-0.25-3.53,0.11-3.98c-0.19-0.52-6.05-14.13-6.25-17.49c-2.33,0.48-5.71,2.91-6.59,4.12c0.24,0.92,4.29,8.94,5.66,11.42
      c1.65,3.01,3.39,5.96,5.02,8.98c1.3,2.41,2.76,5.34,4.97,7.07C66.65,118.26,67.71,118.73,68.32,118.05z"/>
    <path fill="#6C9CD9" d="M73,143.19c-0.17-5.36-0.56-10.71-0.86-16.05c-0.32-5.53-0.64-11.06-1.05-16.58
      c0.21,10.5,0.56,30.2,0.54,32.4C72.06,143.04,72.52,143.12,73,143.19z"/>
    <path fill="#6C9CD9" d="M57.16,87.24c-0.09-0.4-0.14-0.75-0.16-1.03c-2.33,0.48-5.71,2.91-6.59,4.12
      c0.04,0.15,0.19,0.52,0.42,1.01C52.41,89.45,54.72,87.88,57.16,87.24z"/>
    <path fill="#6C9CD9" d="M64.94,74.38c0.11-0.72,5.7-1.45,9.05-1.13c-0.06-0.26-0.13-0.51-0.19-0.76
      c-1.44-0.32-9.08,0.35-9.54,1.42c-0.18,0.42-0.22,4.54-0.17,9.87C64.1,83.38,64.82,75.09,64.94,74.38z"/>
    <path fill="#2C57A2" d="M72.93,93.5c1.07,1.22,1.81,2.66,2.45,4.18c0.8,1.91,1.6,3.83,2.35,5.76
      c0.19,0.49,0.38,0.99,0.57,1.49c-1.04-5.32-2.77-10.4-4.62-13.88C73.33,91.83,73.07,92.66,72.93,93.5z"/>
    <path fill="#6C9CD9" d="M63.24,103.7c-0.12-0.34-2.69-6.32-4.5-11.34C59.78,97.77,62.28,103.82,63.24,103.7z"/>
    <path fill="#2C57A2" d="M74.23,85.24c0.23-0.29,0.44-0.55,0.61-0.75c-0.3-0.56-0.18-4.13-0.14-5.45
      c0.06-1.64-0.17-3.25-0.51-4.84C73.81,78.95,73.9,83.23,74.23,85.24z"/>
    <path fill="#2C57A2" d="M68.82,87.2c-0.02-1.03-0.05-2.5,0.01-3.9c-0.68,3.05-0.98,6.39-0.9,9.7
      c0.08,3.31,0.18,9.83,0.32,13.73c0.17,4.6,0.56,8.89-0.46,11.64c0.2-0.04,0.38-0.14,0.53-0.32c0.53-0.59,1.52-2.09,1.67-3.8
      c-0.04-5.45-0.08-10.93-0.61-16.33C69.02,94.17,68.9,90.94,68.82,87.2z"/>
    <path fill="#D60F0F" d="M78.52,223.74c1.59,0.36,5.3,0.43,7.04-0.08c-0.03,0.23-0.15,1.23-0.18,1.51
      c0.24,0.27,1.01,0.59,1.17,0.64c-4.22,0.48-8.41,1.46-11.42,2.53c-0.59-0.01-1.18-0.02-1.77-0.04c0.34-1.2,0.1-2.8-0.61-3.83
      C74.55,224.16,76.51,223.92,78.52,223.74z"/>
    <path fill="#D60F0F" d="M83.19,229.95c-0.19-0.64-0.67-1.78-1.36-2.32c2.24-0.5,4.72-0.92,7.35-1.25
      c2.31,0.46,8.49,0.74,14.01,0.18c0.03,0.14,0.07,0.3,0.1,0.48c-0.66,0.15-1.28,0.48-1.83,0.88c-0.89,0.64-2.12,2.25-2.04,3.54
      c-0.75,0.09-1.5,0.18-2.25,0.26c-1.68,0.17-7.57,0.55-13.79,0.83C83.64,231.87,83.37,230.56,83.19,229.95z"/>
    <path fill="#B00F08" d="M86.2,225.67c-0.29-0.13-0.67-0.33-0.83-0.5c0.03-0.28,0.16-1.29,0.18-1.51
      c-1.73,0.52-5.44,0.44-7.04,0.08c-0.98,0.09-1.95,0.19-2.89,0.3C78.14,225.4,82.68,225.98,86.2,225.67z"/>
    <path fill="#B00F08" d="M101.46,227.91c0.56-0.4,1.17-0.72,1.83-0.88c-0.04-0.18-0.07-0.34-0.1-0.48
      c-5.52,0.56-11.7,0.28-14.01-0.18c-1.11,0.14-2.18,0.3-3.23,0.47C92.04,229.67,101.37,227.98,101.46,227.91z"/>
    <path fill="#FFFFFF" d="M82.87,232.56c-4.4,0.19-8.92,0.32-12.19,0.27c1.33-2.12,5.19-3.81,10.47-5.05
      c0.7,0.56,1.11,1.21,1.43,2.19C82.86,230.87,83,231.65,82.87,232.56z"/>
    <path fill="#FFFFFF" d="M72.32,224.55c0.58,1.01,0.79,2.38,0.56,3.75c-0.65-0.02-1.31-0.04-1.96-0.06
      c-1.62-0.07-3.24-0.19-4.85-0.37c-0.47-0.05-0.94-0.13-1.42-0.22C65.85,226.19,68.73,225.21,72.32,224.55z"/>
    <path fill="#FFFFFF" d="M64.09,228.8c0.05-0.26,0.15-0.51,0.28-0.75c0.4,0.05,0.8,0.1,1.19,0.16
      c1.62,0.22,3.25,0.35,4.88,0.44c1.2,0.07,2.39,0.1,3.59,0.12c-0.75,0.3-1.4,0.61-1.91,0.91C69.57,229.84,63.97,229.46,64.09,228.8z
      "/>
    <path fill="#FFFFFF" d="M103.58,232.36c-6.13,2.64-33.44,2.45-33.41,1.99c0.02-0.38,0.12-0.74,0.28-1.1
      c7.89,0.61,23.83-0.76,26.76-1.05c2.13-0.21,4.33-0.39,6.47-0.76C103.66,231.76,103.63,232.07,103.58,232.36z"/>
    <path fill="#FFFFFF" d="M99.81,231.4c0.21-1.02,0.95-2.09,1.4-2.52c0.64-0.6,1.4-1.06,2.19-1.29
      c0.16,0.91,0.31,2.12,0.3,3.27C102.4,231.05,101.11,231.24,99.81,231.4z"/>
    <path fill="#4275C4" d="M104.86,224.58c-5.53,1.39-16.01,0.18-18.06-0.31c0.63-6.4,1.07-9.91,0.8-15.32
      c-0.31-6.16-0.94-12.17-0.79-16.68c2.12,5.69,4.87,9.16,4.81,8.79c-0.71-4.3-3.31-6.92-4.25-11.16c-0.54-2.43-0.52-5.97-0.36-8.44
      c0.2-3.01,0.4-5.02,0.54-8.03c0.25-5.13,0.15-9.48,0.04-14.62c-0.1-4.63-0.01-9.74-1.54-14.15c-0.91,0.18,0.09,2.91,0.26,6.47
      c0.18,3.56,0.17,6.02,0.14,8.46c-0.07,5.12,0.05,9.46-0.31,14.57c-0.39,5.39-1.66,10.9-0.06,15.98
      c-1.23,5.22,0.01,14.89,0.01,18.08c-0.01,5.52,0.14,8.3-0.38,13.81c-2.1,0.76-6.72,0.92-8.78-0.57
      c-0.52-13.12,1.11-23.19,1.47-36.29c0.18-6.57,0.3-12.24,0.53-18.81c0.23-6.48,0.54-11.99,0.46-18.48
      c-0.02-1.8-0.16-3.51-0.33-5.31c-0.17-1.73-0.85-7.14-0.93-8.08c4.21,3.04,14.43,1.86,19.93,0.62c0,1.31-0.01,4.76-0.17,9.27
      c1.39,0.16,4.03,0,6.12-0.31c-0.15,6.77-1.44,30.67-1.36,32.16c0.14,2.97,0.53,5.07,0.65,8.04c0.27,6.6,0.11,20.7,0.03,27.3
      C103.26,216.91,104.94,223.97,104.86,224.58z"/>
    <path fill="#2C57A2" d="M95.24,146.32c2.88,0.35,5.83,0.3,8.7-0.15c0.03-0.82,0.05-1.54,0.06-2.12
      c-2.09,0.31-4.72,0.47-6.12,0.31c0.15-4.51,0.17-7.95,0.17-9.27c-0.7,0.16-1.47,0.31-2.3,0.46c0.04,2.14,0.08,4.28-0.09,6.42
      C95.55,143.43,95.39,144.88,95.24,146.32z"/>
    <path fill="#2C57A2" d="M86.29,150.75c-0.22,8.75-1.09,16.64-1.6,25.37c-0.58,9.99-0.81,18.98-0.46,28.99
      c0.09,2.47,0.19,4.95,0.17,7.42c-0.02,2.39-0.02,1.76-0.21,4.14c-0.16,2-0.65,3.95-1.26,5.86c1.08-0.08,2.07-0.27,2.77-0.52
      c0.52-5.5,0.37-8.29,0.38-13.81c0.01-3.19-1.24-12.87-0.01-18.08c-1.6-5.07-0.33-10.59,0.06-15.98c0.37-5.11,0.24-9.45,0.31-14.57
      c0.04-2.45,0.04-4.91-0.14-8.46C86.31,151,86.3,150.88,86.29,150.75z"/>
    <path fill="#6E9AD3" d="M79.23,193.35c0.35-8.66,0.48-21.83-0.34-25.68c-0.2,6.14-0.32,11.36-0.49,17.49
      c-0.37,13.1-1.99,23.18-1.47,36.29c0.38,0.27,0.84,0.49,1.36,0.66C77.68,212.33,78.8,203.92,79.23,193.35z"/>
    <path fill="#6E9AD3" d="M86.84,196.09c0.13,3.84,0.54,8.3,0.77,12.86c0.28,5.41-0.17,8.92-0.8,15.32
      c0.33,0.08,0.88,0.18,1.59,0.28C89.22,220.22,89.4,202.92,86.84,196.09z"/>
    <path fill="#6E9AD3" d="M88.7,177.54c0.7-11.07-0.04-22.66-1.39-26.62c0.24,2.65,0.24,5.34,0.3,7.89
      c0.11,5.14,0.2,9.48-0.04,14.62c-0.14,3.01-0.34,5.03-0.54,8.03c-0.17,2.47-0.19,6.01,0.36,8.44c0.67,3,2.16,5.19,3.23,7.73
      c-0.06-0.42-1.76-6.28-2.11-8.31C88.11,187.07,88.33,183.39,88.7,177.54z"/>
    <path d="M82.02,44.8c-0.99-0.85-2.43-0.97-3.55-0.41c-1.49,0.75-2.16,2.37-2.44,3.88c-0.27,1.5-0.07,2.96,0.14,3.66
      c0.37,1.23,1.62,2.2,2.93,2.23c1.32,0.03,2.32-0.37,3.06-1.24c0.96-1.12,1.18-2.4,1.23-4.07C83.44,47.42,83.14,45.75,82.02,44.8z"
      />
    <path fill="#FFFFFF" d="M82.53,49.09c-0.15,2.21-0.82,3.99-3.16,4.1c-1.84,0.09-2.79-1.57-2.79-3.35
      c0-1.78,0.62-3.38,1.54-4.25c0.79-0.75,1.98-0.91,2.91-0.42C82.24,45.81,82.61,47.87,82.53,49.09z"/>
    <path d="M78.67,39.67c-2.01-1.12-3.37,1.15-2.91,1.74c1.18-1.52,3.03-1.11,6.05,2.2C81.82,42.2,80.17,40.5,78.67,39.67z"/>
    <path d="M79.99,47.49c-0.99,0.04-1.75,1.06-1.71,2.29c0.05,1.23,0.89,2.19,1.88,2.16c0.99-0.04,1.75-1.07,1.71-2.29
      C81.82,48.41,80.98,47.45,79.99,47.49z"/>
    <path d="M72.8,48.22c-0.76,0.03-1.35,0.81-1.32,1.75c0.03,0.94,0.67,1.68,1.43,1.66c0.76-0.03,1.35-0.81,1.32-1.75
      C74.2,48.94,73.56,48.2,72.8,48.22z"/>
    <path d="M94.89,45.33c-0.55,0.72-0.86,2.12-0.91,3.01c-0.03,0.64,0.08,2.2,0.76,2.49c0.23,0.1,0.89-0.16,0.74-0.56
      c-0.83,0.36-0.83-1.04-0.83-1.74c0.06-0.13,0.81-0.9,0.99-0.97c0.03-0.42-0.48-0.3-0.95,0c0.12-1.13,0.71-2.52,1.13-1.35
      C96.11,45.69,95.4,44.66,94.89,45.33z"/>
    <path d="M85.73,63.75c-0.07-0.26,0.04-1.35,0.09-1.72c-1.39,1.01-3.41,1.26-5.02,1.45c-2.02,0.24-4.63-0.02-4.7-0.91
      c2.27,0.63,7.08,0.25,9.85-1.63c0.08-0.76,0.16-1.52,0.3-2.28C87.14,59.99,87.07,62.87,85.73,63.75z"/>
    <path d="M84.19,65.21c-0.58,0-4.11,0.11-5.2-0.05c0.41,0.61,2.03,1.05,3.2,1.18C84.27,66.55,84.31,65.21,84.19,65.21z"/>
    <path d="M82.18,69.11c-0.61,0.06-1.74,0.38-1.75,0.93c1.45-0.6,2.49-0.46,3.52-0.02C84.03,69.44,82.79,69.05,82.18,69.11z"/>
    <path fill="#FFFFFF" d="M80.6,48.01c-0.39-0.07-0.76,0.19-0.83,0.58c-0.07,0.39,0.19,0.76,0.58,0.83
      c0.39,0.07,0.76-0.19,0.82-0.58C81.25,48.45,80.99,48.08,80.6,48.01z"/>
    <path fill="#FFFFFF" d="M79.69,49.28c-0.11-0.02-0.22,0.06-0.24,0.17c-0.02,0.11,0.06,0.22,0.17,0.24
      c0.11,0.02,0.22-0.06,0.24-0.17C79.88,49.41,79.81,49.3,79.69,49.28z"/>
    <path fill="#FFFFFF" d="M73.29,48.69c-0.31-0.05-0.6,0.15-0.65,0.46c-0.06,0.31,0.15,0.6,0.45,0.66
      c0.31,0.05,0.6-0.15,0.65-0.46C73.8,49.04,73.6,48.74,73.29,48.69z"/>
    <path fill="#FFFFFF" d="M72.58,49.65c-0.09-0.02-0.17,0.04-0.19,0.13c-0.02,0.09,0.04,0.17,0.13,0.19
      c0.09,0.02,0.17-0.04,0.19-0.13C72.73,49.75,72.67,49.66,72.58,49.65z"/>
    <path fill="#FAE3D6" d="M82.24,71.78c-0.87-0.08-2.12-0.78-1.61-1.26c0.46-0.44,2.71-0.75,3.22,0.16
      C84.09,71.1,83,71.85,82.24,71.78z"/>
    <path d="M110.41,125.72c-1.82,0.1-6.76,0.62-10.19,0.61C100.2,127.27,110.4,126.99,110.41,125.72z"/>
    <path d="M108.23,128.19c-2.03,0.31-4.7,0.26-6.74,0.08c-0.12,0.56,2.45,0.7,3.7,0.63C106.73,128.83,108,128.73,108.23,128.19z"/>
    <path d="M76.17,127.26c-1.7-0.42-3.59-0.83-5.35-0.86c-0.25-0.01-0.31,0.41-0.05,0.45C72.48,127.14,75.99,127.65,76.17,127.26z"/>
    <path d="M75.05,128.95c-1.41-0.23-2.91-0.36-4.35-0.32c-0.24,0.01-0.26,0.36-0.03,0.36C72.16,129.02,75.04,129.37,75.05,128.95z"/>
    <path d="M99.76,92.95c-0.21,0-0.34,0.48-0.11,0.48c1.92,0.02,3.92,0.32,5.61,0.64C105.42,93.47,101.87,92.94,99.76,92.95z"/>
    <path d="M99.49,94.91c-0.17,0.02-0.17,0.29,0,0.29c1.65,0.01,3.34,0.16,5.02,0.41C104.65,95.11,101.15,94.72,99.49,94.91z"/>
    <path d="M109.63,111.43c0.13,0.78,0.2,1.2,0.29,1.98c1.44-3.5,3.32-3.95,4.67-3.7C113.78,108.09,110.75,109.44,109.63,111.43z"/>
    <circle cx="85.68" cy="77.67" r="0.67"/>
    <circle cx="85.91" cy="85" r="0.67"/>
    <circle cx="86.04" cy="94.93" r="0.67"/>
    <circle cx="86.13" cy="105.92" r="0.67"/>
    <circle cx="86.45" cy="115.46" r="0.67"/>
    <circle cx="86.52" cy="123.82" r="0.67"/>
  </g>
  <g id="item_20_">
    <path d="M792.53,108.49c-0.62-1.13-2.18-3.28-3.14-4.4c0.94-0.66,0.6-1.66-0.12-2.42c-0.55-0.59-1.32-1.34-1.89-1.91
      c-1-1.02-1.87-2.19-2.78-3.28c-2-2.41-3.83-4.88-5.37-7.62c-1.45-2.59-2.65-5.31-3.9-7.99c-2.64-5.67-6.04-10.92-8.88-16.48
      c-1.41-2.76-2.66-5.63-3.9-8.47c-1.01-2.32-1.54-5.04-2.99-7.14c-0.06-1.12-2.14-0.97-3.18-0.96c-1.28,0.02-2.55,0.24-3.82,0.39
      c-1.14,0.13-2.21,0.11-3.34,0.06c-0.01-0.07-0.05-0.14-0.12-0.19c-0.82-0.46-1.63-0.8-2.55-0.92c-0.1-0.08-0.27-0.07-0.32,0.09
      c-0.03,0.11-0.07,0.21-0.1,0.32c-0.14-0.06-0.8-0.02-0.94,0.05c-1.36-0.72-1.97-2-2.48-3.53c-0.56-1.67-0.69-3.22-0.61-4.94
      c0.36-0.11,0.68-0.34,0.8-0.69c0.93,0.78,1,3.71,1.86,4.58c0.89,0.91,1.96,1.03,2.69,0.96c1.24-0.11,1.73-0.86,1.61-1.53
      c-1.16,0.42-2.24-0.17-1.5-1.49c0.21-0.37,0.43-0.71,0.6-1.1c0.45-1.04-0.1-2.41,0.36-3.47c0.62-1.41,1.48-2.48,1.2-4.11
      c-0.28-1.61-1.38-2.68-2.59-3.7c-1.37-1.15-1.11-3.28-2.87-4.11c0.49-1.51-0.24-2.76-1.69-3.45c-2.27-1.08-4.8,0.25-7.15-0.17
      c-1.27-0.22-2.31-0.85-3.63-0.65c-1,0.15-1.71,1.05-2.16,1.88c-0.42,0.79-0.57,1.75-0.42,2.63c0.07,0.43,0.29,0.78,0.53,1.14
      c1,1.44-0.76,2.32-1.82,2c-0.2,0.44,1.04,1.38,2.35,1c-0.15,1-0.75,1.72-1.55,2.42c-0.73,0.64-2.06,1.27-2.37,1.58
      c-0.3,0.3-0.03,0.79,0.39,1.18c0.82,0.76,2.15,1.27,1.79,2.06c-0.33,0.72-0.42,1.98-0.24,2.75c0.23,1,0.63,1.76,0.68,2.17
      c0.05,0.42-0.09,0.8-0.23,1.22c-0.68,2,0.96,2.66,2.89,2.77c1.34,0.07,2.88-0.14,3.8-0.27c0.71,0.43,1.36,0.78,1.93,1.43
      c0.52,0.59,0.8,1.33,1.27,1.95c0.25,0.32,0.61,0.52,0.97,0.71c0.39,0.21,0.74,0.41,1.02,0.67c-0.96,0.27-1.58,1.14-2.01,2.15
      c-0.34,0.1-1.19,0.3-1.24,0.4c-0.05,0.1-0.1,0.19-0.15,0.29c-0.91,0.17-1.97-0.22-2.64,0.21c-0.3,0.19-0.67,1.79-0.78,2.38
      c-0.42,2.31-0.71,4.8-0.53,7.15c-0.1,0.01-0.38,0.05-0.55,0.11c0.4,2.71-0.14,5.58-0.59,8.25c-0.22,1.31-0.37,2.6-0.35,3.99
      c-1.03,2.72-1.27,7.03-1.14,10.81c-1.05-0.18-2.03-0.79-2.92-1.33c-0.79-0.49-1.64-0.99-2.34-1.6c-0.67-0.58-1.71-2.05-2.75-1.72
      c-0.06-0.86-0.15-1.71-0.26-2.55c-0.24-1.8-1.56-2.97-2.17-4.61c-0.25-0.66-0.27-1.93-1.21-2.02c-0.64-0.06-1.05,0.27-1.44,0.72
      c-0.87,0.97-1.58,1.65-2.8,2.37c-0.97,0.58-2.4,0.88-2.61,2.2c-0.05,0.34,0.03,0.7,0.14,0.89c-1.17,0.46-1.79,1.27-1.58,2.47
      c0.09,0.53,0.37,0.79,0.8,0.91c-0.69,0.54-0.45,2.14-0.06,2.58c0.39,0.45,0.97,0.6,2.41,0.17c-0.62,0.24-1.13,0.45-1.34,0.96
      c-0.39,0.95,0.42,2.17,1.35,2.32c0.5,0.08,1-0.15,1.47-0.45c-0.16,0.66,0.35,1.24,1,1.43c0.67,0.19,1.64-0.46,2.25-0.7
      c0.12-0.05,0.26-0.11,0.4-0.18c0.38,0.36,1.12,0.49,2.37,0.52c-0.21,0.74-0.22,1.8,0.12,2.57c0.17,0.39,0.42,0.61,0.83,0.65
      c0.26,0.03,0.61-0.03,0.85-0.02c0.55,0.01,1.12,0.27,1.63,0.46c1.52,0.57,3.13,0.87,4.64,1.45c0.54,0.21,1.47,0.65,2.05,0.34
      c0.08,0.64,0.16,1.27,0.22,1.9c0.38,3.75,1.01,7.71,0.6,11.47c-0.2,1.81-0.7,3.16-2.15,4.22c-1.05,0.77-1.93,1.61-1.74,2.62
      c2.42-0.67,3.48,2.35,5.64,2.52c-0.13,1.19-0.33,2.37-0.62,3.53c-0.75,1.14-2.95,4.08-4.32,5.69c-2.46,2.91-5.13,5.62-7.47,8.64
      c-2.41,3.12-4.68,6.32-7.24,9.33c-1.09,1.29-2.27,2.54-2.79,4.18c-0.2,0.64-0.31,1.3-0.38,1.96c-1.02,2.21-2.63,4.15-4.12,6.05
      c-1.86,2.36-3.59,4.82-5.36,7.26c-1.77,2.44-3.46,4.92-4.85,7.6c-0.66,1.26-1.28,2.54-1.96,3.8c-0.37,0.68-0.77,1.34-1.18,1.99
      c-0.45,0.7-1.02,1.63-1.96,1.73c-0.02,0.25,0.19,0.78,0.25,0.85c-4.07-1.09-8.13-3.92-10.98-6.92c-1.14-1.2-2.28-2.53-3.81-3.26
      c-0.98-0.47-1.82,0.17-1.89,1c-0.33,0.1-1.11,0.38-1.36,0.52c-0.4,5.32,4.44,9.82,7.42,13.64c1.63,2.08,3.16,4.24,4.8,6.3
      c-0.17,0.19-0.78,0.63-1.14,0.86c0.81,1.97,4.17,7.94,7.28,9.42c0.85-0.69,2.08-2.02,2.51-2.61c1.3-0.1,2.77-1.23,3.33-2.46
      c1.13,0.19,2.54,0.26,3.36-0.7c0.87-1.03,0.95-2.1,1.19-3.17c0.97-4.31,2.6-8.44,4.56-12.4c2.08-4.2,3.9-8.5,5.91-12.74
      c0.98-2.05,2.09-4.03,3.1-6.07c1.17-2.36,3.33-4.05,4.55-6.36c3.03-2.2,8.69-10.6,10.42-12.92c-0.18,1.96-0.16,3.87-0.08,5.11
      c0.12,1.89,0.06,3.85,0.14,5.74c0.07,1.73,0.55,3.15,1.7,4.5c1.07,1.26,3.12,2.11,4.36,2.39c1.84,0.41,3.36,0.53,5.24,0.64
      c2.42,0.15,5.05,0.15,7.47,0.29c2.34,0.13,4.69,0.18,7.01,0.56c2.28,0.38,5.79,1.49,7.1,1.64c-0.04-0.71,0.05-2.22,0.42-3.57
      c1.09,1.78,2.97,7.84,5.89,12.41c0.79,1.24,1.21,2.68,1.52,4.11c0.16,0.71,0.3,1.45,0.86,1.97c1.15,1.06,2.27,0.22,2.87-0.95
      c0.85-1.63,0.77-3.63,0.58-5.39c-0.22-2.11-0.62-4.6-1.12-6.66c-0.56-2.32-1.09-4.23-1.53-6.57c0.42-0.17,1.11-0.24,1.77-0.36
      c-0.47-1.41-0.91-7.81-0.93-8.86c-0.58-0.41-2.2-0.19-2.99,0.56c-1.38-1.34-4.98-1.23-6.05,0.51c-0.07-0.28-0.18-0.52-0.31-0.67
      c-2.29-0.25-4.43-0.67-6.76-0.72c-2.33-0.05-4.7-0.07-6.98,0.41c-2.12,0.45-4.14,1.25-6.25,1.72c-1.48,0.33-3.95,0.89-5.36-0.05
      c-0.32-1.74,0.25-3.53,0.39-5.69c0.13-2.02,0.22-4.08,0.28-6.11c0.1-3.68,0.9-8.48,2.34-11.34c0.68-0.64,1.66-1.67,2.17-2.42
      c3.88-0.09,7.42,1.9,8.7,2.28c1.28,0.38,2.59,0.79,3.16,0.11c0.56-0.68,0.58-1.64,0.57-2.55c-0.04-2.57-1.6-6.89-2.28-9.04
      c-0.75-2.35-2.68-7.63-4.11-10c-0.56-3.08-1.45-9.97-0.86-14.74c0.28-2.22,0.77-4.41,1.19-6.6c0.44-2.31,0.72-4.64,1.1-6.96
      c0.75-4.51,2.97-13.49,4.14-17.77c0.48,0.8,5.15,10.87,6.09,13.48c1.23,3.42,2.48,6.48,4.25,9.68c0.25,2.2,1,4.41,1.88,6.43
      c1.07,2.45,2.42,5.12,3.73,7.46c1.28,2.3,2.64,4.53,3.54,7.01c0.86,2.37,1.42,4.85,2.66,7.07c0.1,0.18,1.18-0.17,1.53-0.42
      c-0.04,0.68-0.04,2.95-0.03,3.43c0.01,0.48,0.09,0.94,0.38,1.3c0.67,0.8,1.48,1.29,1.94,2.28c0.27,0.58,0.2,1.39,0.71,1.85
      c0.53,0.48,1.86,0,2.09-1.61c0.23,0.4,0.47,0.73,0.95,1.05c0.57,0.39,1.42,0.2,1.69-0.34c0.25-0.5,0.34-1.26,1.3-0.82
      c0.28,0.13,0.56,0.11,0.86,0.04c0.35-0.09,0.57-0.41,0.71-0.72c0.09-0.2,0.12-0.41,0.16-0.63c0.09-0.48,0.71-0.49,1-0.76
      c0.76-0.69,0.13-1.42,0.79-1.88C794.07,111.61,793.37,110.01,792.53,108.49z"/>
    <path fill="#B46C80" d="M746.6,47.53c0.73,0.18,1.37,0.48,2.02,0.88c-1.72,3.26-3.03,8.92-2.99,12.43
      c-0.49,0.36-2.05,1.66-2.17,2.03c0.91,0.46,2.7-0.65,4.13-0.73c-2.01,4.55-3.46,9.28-4.74,14.08c-0.66,2.5-1.26,5.01-1.93,7.5
      c-0.4,1.49-0.87,2.96-1.29,4.45c0.64-5.22,1.31-10.44,1.59-15.7c0.22-4.14,0.53-8.29,1.46-12.34
      C743.65,55.83,745.57,51.81,746.6,47.53z"/>
    <path fill="#C88898" d="M748.45,48.73c0.05-0.11,0.11-0.22,0.17-0.33c-0.64-0.39-1.28-0.7-2.02-0.88
      c-0.04,0.17-0.09,0.34-0.13,0.51C747.15,48.2,747.83,48.42,748.45,48.73z"/>
    <path fill="#C88898" d="M743.75,62.95c0.82,0.68,1.98,0.2,2.9-0.13c0.24-0.09,0.48-0.16,0.73-0.22
      c0.07-0.15,0.13-0.31,0.2-0.46C746.29,62.2,744.72,63.11,743.75,62.95z"/>
    <path fill="#C88898" d="M742.44,66.86c0.16-1.48,0.28-2.96,0.55-4.43c0.26-1.39,0.6-2.77,0.95-4.14
      c0.54-2.11,1.09-4.23,1.35-6.39c-0.93,2.72-1.98,5.41-2.62,8.22c-0.93,4.05-1.23,8.2-1.46,12.34c-0.04,0.82-0.1,1.64-0.16,2.46
      C741.65,72.26,742.13,69.58,742.44,66.86z"/>
    <path fill="#C8DCF3" d="M737.33,104.16c0.41-1.17,0.86-2.34,1.08-3.55c0.09,1.4,0.3,2.8,0.55,4.19
      C738.48,104.78,737.81,104.52,737.33,104.16z"/>
    <path fill="#9C6E63" d="M739.05,105.26c0.16,0.83,0.32,1.66,0.48,2.49c-0.3-0.02-0.76-0.14-1.07-0.28
      c-0.04-0.58-0.03-1.52-0.02-2.26C738.64,105.26,738.89,105.33,739.05,105.26z"/>
    <path fill="#85574A" d="M738.91,107.63c0.22,0.06,0.45,0.11,0.62,0.12c-0.16-0.83-0.32-1.65-0.48-2.49
      c-0.09,0.04-0.22,0.03-0.35,0.01c0.04,0.66,0.1,1.32,0.16,1.97C738.88,107.37,738.9,107.5,738.91,107.63z"/>
    <path fill="#BBBBA1" d="M738.1,107.58c-0.44-0.09-2.42-1.07-3.09-1.77c-0.18-0.19-0.12-2.1-0.02-2.07
      c0.11,0.02,0.48,0.25,0.72,0.38c0.34,0.87,1,1.88,1.08,1.61c0.1-0.32,0.29-0.81,0.4-1.13c0.22,0.11,0.73,0.42,0.92,0.51
      C738.08,105.93,738.04,106.76,738.1,107.58z"/>
    <path fill="#9F9C87" d="M737.67,104.87c-0.18-0.1-0.37-0.21-0.48-0.27c-0.11,0.32-0.3,0.81-0.4,1.13
      c-0.09,0.27-0.74-0.74-1.08-1.61c0,0,0,0,0,0c0.27,0.98,0.87,2.6,1.14,2.62C737.11,106.75,737.46,105.5,737.67,104.87z"/>
    <path fill="#C8DCF3" d="M738.39,54.9c0.28-0.29,0.55-0.58,0.83-0.85c-0.32,0.96-0.15,2.27,0.27,3.19
      c-1.15,0.49-1.37,1.73-1.47,2.86c-0.18,2.08-0.18,4.08-0.78,6.13c-0.87,3-2.59,5.63-3.85,8.46c0.35-3.46,1.27-6.7,2.42-10
      c1.1-3.17,2.18-6.32,2.6-9.67C738.42,54.97,738.4,54.93,738.39,54.9z"/>
    <path fill="#A8C4E3" d="M738.31,55.76c0.25-0.13,0.5-0.26,0.75-0.39c-0.02-0.47,0.03-0.93,0.16-1.33
      c-0.28,0.28-0.55,0.57-0.83,0.85c0.02,0.03,0.03,0.07,0.03,0.11C738.38,55.26,738.35,55.51,738.31,55.76z"/>
    <path fill="#528FDB" d="M737.3,67.86c0.74-1.81,1-4.16,1.06-6.11c0.05-1.38,0.06-3.71,1.35-4.13
      c-0.27,1.32-0.21,2.66-0.13,4c0.13,2.34,0.03,4.52-0.54,6.8c-1.06,4.27-3,8.18-3.58,12.59c-0.53,4.03-0.32,8.36,1.01,12.23
      c0.72,2.08,1.64,4.06,1.52,6.32c-0.05,0.87-0.31,1.72-0.57,2.54c-0.21,0.66-0.41,1.33-0.6,2c-0.01,0.02-0.01,0.03-0.02,0.05
      c-0.07,0.24-0.14,0.47-0.21,0.71c-1.85-3.77-1.93-7.81-2.45-11.91c-0.58-4.55-2.04-9.23-1.46-13.85
      C733.2,75.02,735.77,71.59,737.3,67.86z"/>
    <path fill="#7AABE3" d="M735.42,72.73c0.82-1.78,1.92-3.49,2.53-5.36c0.32-0.99,0.58-2.01,0.68-3.05
      c0.09-0.92,0.09-1.84,0.11-2.77c0.02-1.25-0.05-2.82,0.78-3.85c-1.11,0.57-1.12,2.73-1.17,4.05c-0.07,1.95-0.33,4.3-1.06,6.11
      c-1.52,3.73-4.1,7.16-4.61,11.24c-0.26,2.04-0.12,4.09,0.17,6.14C732.81,80.37,733.63,76.64,735.42,72.73z"/>
    <path fill="#528FDB" d="M739.64,53.65c0.56-0.51,1.17-0.95,1.91-1.21c0.09,0.72,0.24,1.84,0.34,2.55
      c-0.62,0.85-1.13,1.52-1.96,2.1C739.4,56.01,739.4,54.85,739.64,53.65z"/>
    <path fill="#3B77CE" d="M741.26,54.19c0.24,0.25,0.44,0.53,0.61,0.84c0.01-0.01,0.02-0.02,0.02-0.03
      c-0.1-0.71-0.25-1.83-0.34-2.55c-0.74,0.26-1.35,0.69-1.91,1.21c-0.02,0.12-0.04,0.24-0.06,0.36
      C740.15,53.77,740.77,53.69,741.26,54.19z"/>
    <path fill="#C8DCF3" d="M738.28,96.92c-0.16-0.7-0.37-1.4-0.61-2.1c-1.49-4.5-2.05-8.96-1.5-13.68
      c0.48-4.13,2.23-7.88,3.37-11.84c0.5-1.72,0.83-3.57,0.76-5.37c-0.08-2.14-0.52-4.26-0.17-6.39c0.68-0.37,1.43-1.07,1.87-1.85
      c0.11,0.65,0.27,1.3,0.62,1.82c0.03,0.04,0.07,0.07,0.11,0.07c-0.08,0.27-0.17,0.54-0.25,0.82c-1.23,4.14-1.6,8.48-1.87,12.77
      c-0.29,4.64-0.66,9.22-1.25,13.83C738.88,88.94,738.29,92.95,738.28,96.92z"/>
    <path fill="#A8C4E3" d="M737.02,89.61c0.01-1.72,0.19-3.43,0.34-5.14c0.3-3.33,0.84-6.56,1.62-9.81
      c0.38-1.58,0.81-3.16,1.21-4.74c0.41-1.62,0.8-3.27,0.85-4.95c0.05-1.68-0.09-3.47-0.41-5.13c-0.11-0.57-0.22-1.11-0.03-1.67
      c0.15-0.46,0.66-0.67,0.91-1.07c0.05-0.08,0.16-0.06,0.19,0.03c0.18,0.52,0.38,1.1,0.68,1.59c0.03-0.11,0.06-0.22,0.09-0.33
      c0.08-0.27,0.17-0.55,0.25-0.82c-0.04-0.01-0.09-0.03-0.11-0.07c-0.36-0.52-0.52-1.17-0.62-1.82c-0.44,0.78-1.2,1.48-1.87,1.85
      c-0.34,2.13,0.1,4.25,0.17,6.39c0.07,1.8-0.27,3.65-0.76,5.37c-1.14,3.96-2.88,7.71-3.37,11.84c-0.5,4.28-0.08,8.36,1.11,12.44
      C737.13,92.26,737.01,90.93,737.02,89.61z"/>
    <path fill="#C8DCF3" d="M745.35,47.96c0.14-0.08,0.49-0.08,0.67-0.02c-0.97,3.03-2.14,6.06-3.11,9.09
      c-0.36-0.66-0.87-4.01-0.99-5.56c0.51,0.3,1.47-0.49,1.85-0.87C744.07,50.31,745.22,48.78,745.35,47.96z"/>
    <path fill="#525659" d="M746.93,41.62c0.19-1.07,1-1.75,0.93-2.93c-0.06-1.07-0.07-1.94,0.38-2.93
      c0.6-1.33,1.24-2.95,0.57-4.4c-0.42-0.91-0.96-1.42-1.8-2.05c-1.5-1.13-1.35-3.39-2.81-4.57c-0.09,0.4-0.44,0.69-0.41,0.52
      c0.7-3.73-2.25-4.38-4.8-4.01c-1.13,0.17-2.56,0.38-3.7,0.11c-1.03-0.25-2.45-0.92-3.51-0.6c-1.32,0.39-2.23,2.2-2.16,3.6
      c0.05,0.98,0.77,1.11,0.82,2.27c0.04,0.93-0.98,1.59-1.9,1.65c0.54,0.31,1.18,0.5,1.78,0.31c0.18-1.56,0.73-3.05,2.02-3.88
      c-0.36,0.04-0.77,0.19-1.11,0.37c-0.07,0.04-0.08-0.56,1.07-0.76c1.11-0.19,2.63,0.32,3.68,0.9c0.84,0.46,1.77,1.12,2.75,1.2
      c1.06,0.09,2.53-1.28,3.1-1.01c-0.3,0.17-0.79,0.44-1.06,0.64c-0.64,1.32-0.58,2.61,0.57,3.64c0.36,0.32,0.87,0.72,0.95,1.24
      c0.27,1.73-2.13,2.61-1.89,4.26c0.25,1.73,2.43,2.11,3.3,3.47c0.81,1.26,0.83,3.19,1.69,4.02c1.18,1.15,2.88,0.96,3.22,0.23
      C747.78,43.2,746.74,42.69,746.93,41.62z"/>
    <path fill="#6E7276" d="M730.43,26.46c0.1-1.7-0.69-1.5-0.3-2.97c0.22-0.85,0.94-2.04,1.83-2.26
      c0.94-0.24,1.97,0.27,2.9,0.47c1.29,0.29,2.57,0.4,3.87,0.18c1.27-0.21,2.17-0.3,3.21,0.11c0.75,0.3,1.28,0.93,1.63,1.64
      c0.12,0.24,0.25,0.53,0.33,0.83c0.15-3.02-2.53-3.56-4.89-3.21c-1.13,0.17-2.56,0.38-3.7,0.11c-1.03-0.25-2.45-0.92-3.51-0.6
      c-1.32,0.39-2.23,2.2-2.16,3.6C729.67,25.28,730.32,25.45,730.43,26.46z"/>
    <path fill="#6E7276" d="M745.3,27.04c0.28,1.32,0.82,2.61,1.88,3.5c0.44,0.37,0.92,0.75,1.18,1.28
      c0.28,0.57,0.48,1.17,0.55,1.81c0.01,0.11,0.02,0.21,0.03,0.32c0.22-0.86,0.27-1.75-0.11-2.58c-0.42-0.91-0.96-1.42-1.8-2.05
      c-1.18-0.89-1.34-2.47-2.07-3.69C745.12,26.08,745.2,26.6,745.3,27.04z"/>
    <path fill="#6E7276" d="M740.8,34.81c0.42-0.71,2.24-2.66,1.5-3.63c0,1.56-2.14,2.43-1.91,3.99
      c0.03,0.18,0.07,0.35,0.14,0.5C740.55,35.39,740.63,35.09,740.8,34.81z"/>
    <path fill="#3F4447" d="M740.77,26.04c-0.37,0.76-0.5,1.51-0.33,2.21c0.63-0.8,1.11-1.68,1.16-2.71
      C741.31,25.69,740.98,25.89,740.77,26.04z"/>
    <path fill="#3F4447" d="M745.04,28.96c0.03,0.41-0.03,0.87-0.02,1.15c0.03,0.77,0.36,1.42,0.5,2.16
      c0.12,0.63-0.08,1.15-0.25,1.74c-0.22,0.77-0.21,1.54,0.2,2.26c0.27,0.48,0.7,0.9,0.9,1.42c0.23,0.61,0.25,1.33,0.14,2.03
      c0.11-0.27,0.21-0.55,0.28-0.83c0.17-0.72,0.04-1.35-0.12-2.05c-0.11-0.46-0.22-0.95-0.12-1.42c0.11-0.56,0.39-1.05,0.54-1.6
      c0.18-0.67,0.01-1.19-0.47-1.68c-0.34-0.35-0.67-0.65-0.88-1.11C745.43,30.35,745.36,29.59,745.04,28.96z"/>
    <path fill="#3F4447" d="M744.06,32.8c-0.18,0.25-0.37,0.5-0.53,0.77c-0.38,0.6-0.78,1.58-0.13,2.15
      c0.42,0.37,0.99,0.55,1.43,0.85c-0.42-0.69-1.06-1.26-1.2-2.08C743.54,33.89,743.81,33.35,744.06,32.8z"/>
    <path fill="#3F4447" d="M738.13,23.49c-0.72,0.1-1.34-0.15-2.02-0.39c-0.39-0.13-0.8-0.22-1.22-0.25
      c0.05,0.03,0.1,0.07,0.15,0.1c0.34,0.25,0.71,0.43,1.1,0.6c0.71,0.3,1.79,0.81,2.58,0.58c0.72-0.2,1.31-0.7,2.06-0.81
      c0.41-0.06,0.82-0.08,1.21,0.01c-0.45-0.36-1.09-0.48-1.66-0.47C739.55,22.9,738.91,23.38,738.13,23.49z"/>
    <path fill="#6E7276" d="M728.95,28.45c0.95-0.09,1.32-0.67,1.44-1.42c-0.22,0.71-1.07,1.18-1.84,1.24
      c0.12,0.07,0.25,0.13,0.38,0.19C728.93,28.46,728.94,28.45,728.95,28.45z"/>
    <path fill="#F5C4A1" d="M738.75,47.47c-0.69-1.06-1.48-2.32-2.7-2.79c1.8-0.33,3.68-1.02,4.7-2.47
      c0.44-0.63,0.43-2.05,0.38-1.94c-0.34,0.81-0.59,1.44-1.22,1.95c-1.42,1.16-3.7,1.77-5.85,2.03c-1.23,0.15-4.63,0.58-4.84-1.19
      c-0.07-0.62,0.51-1.27,0.36-1.92c-0.17-0.72-0.48-1.38-0.73-2.07c-0.31-0.87-0.18-1.68,0.09-2.54c0.11-0.36,0.36-0.76,0.17-1.14
      c-0.17-0.35-0.45-0.63-0.79-0.82c-0.22-0.12-1.88-1.15-1.44-1.47c0.42-0.3,1.23-0.78,1.64-1.09c1.05-0.81,1.81-1.39,2.2-2.72
      c0.24-0.83,0.16-1.81,0.5-2.62c0.33-0.79,0.79-1.39,1.36-1.92c1.55-0.02,3.18,1.06,4.52,1.65c0.57,0.26,1.33,0.58,1.97,0.48
      c0.46-0.07,0.84-0.26,1.21-0.48c-0.73,1.17-0.57,2.64,0.53,3.65c0.3,0.28,0.61,0.54,0.81,0.91c0.31,0.55-0.42,1.37-0.72,1.77
      c-0.49,0.64-1.14,1.39-1.11,2.26c0.07,1.73,1.68,2.22,2.82,3.17c-0.25,0.32-0.45,0.56-0.92,0.62c-0.36,0.04-0.72,0-1.05-0.14
      c-0.22-0.09,0.4,0.59,1.17,0.59c-0.54,1.63-0.06,3.84,0.47,5.37c0.39,1.12,1.42,3.07,2.71,3.33c-0.07,0.76-2.04,3.81-3.05,3.16
      C741.58,48.66,739.65,48.84,738.75,47.47z"/>
    <path fill="#D9A486" d="M734.87,26.23c0.58,0.35,1.05,0.89,1.65,1.2c0.77,0.4,1.6,0.3,2.43,0.29c0.04,0,0.09,0.03,0.1,0.08
      c0.1,0.56,0.11,1.2,0.38,1.71c0.27,0.52,0.71,0.88,1.06,1.34c0.28,0.37,0.44,0.74,0.39,1.21c-0.04,0.41-0.26,0.82-0.44,1.21
      c0.14-0.19,0.29-0.38,0.42-0.56c0.3-0.4,1.03-1.22,0.72-1.77c-0.2-0.36-0.51-0.63-0.81-0.91c-1.09-1.01-1.25-2.47-0.53-3.65
      c-0.36,0.23-0.75,0.41-1.21,0.48c-0.65,0.1-1.4-0.22-1.97-0.48c-1.33-0.59-2.97-1.67-4.52-1.65c-0.25,0.23-0.48,0.48-0.69,0.76
      C732.9,25.41,733.99,25.7,734.87,26.23z"/>
    <path fill="#D9A486" d="M739.85,35.55c-0.03,0.65,0.13,1.27,0.63,1.79c0.43,0.44,0.9,0.81,1.4,1.16
      c0.07,0.05,0.13,0.09,0.2,0.14c0.2-0.11,0.34-0.28,0.5-0.49C741.58,37.31,740.21,36.83,739.85,35.55z"/>
    <path fill="#D9A486" d="M740.29,43.88c0.38-0.58,0.53-1.23,0.58-1.89c-0.04,0.08-0.08,0.15-0.12,0.22
      c-1.02,1.45-2.9,2.15-4.7,2.47c0.47,0.18,0.87,0.48,1.23,0.83c0.35-0.07,0.7-0.16,1.04-0.28
      C739.08,44.97,739.84,44.56,740.29,43.88z"/>
    <path fill="#F9DDC9" d="M727.87,32.97c0.78-0.43,1.47-0.94,2.06-1.6c0.67-0.76,0.93-1.26,1.03-2.28
      c0.05-0.51,0.04-1.28,0.04-1.82c-0.14,0.66-0.13,1.38-0.32,2.01c-0.39,1.32-1.15,1.91-2.2,2.72c-0.41,0.31-1.22,0.79-1.64,1.09
      c-0.14,0.1-0.07,0.28,0.11,0.48C727.12,33.34,727.6,33.12,727.87,32.97z"/>
    <path fill="#D9A486" d="M731.55,38.22c-0.24,0.06-0.46,0.13-0.7,0.09c-0.52-0.08-1.04-0.27-1.45-0.59
      c0.16,1.23,1.82,1.82,2.77,1.02C732.11,38.4,731.97,38.12,731.55,38.22z"/>
    <path fill="#F9DDC9" d="M729.72,43.31c0.06-0.44,0.32-0.88,0.3-1.33c-0.02-0.4-0.16-0.77-0.36-1.12
      c-0.11-0.19-0.23-0.38-0.35-0.57c0.1,0.27,0.19,0.55,0.26,0.83c0.15,0.65-0.43,1.3-0.36,1.92c0.05,0.38,0.24,0.66,0.52,0.86
      C729.7,43.71,729.69,43.52,729.72,43.31z"/>
    <path fill="#D9A486" d="M740.69,46.25c0.6,0.4,0.68,1.34,0.7,2.07c0.32-0.35,0.58-0.77,0.55-1.19
      C741.91,46.41,741.26,46.25,740.69,46.25z"/>
    <path fill="#F9DDC9" d="M742.05,44.41c0.16,0.72,0.33,1.46,0.66,2.12c0.46,0.92,1.12,1.65,1.97,2.12
      c0.16-0.31,0.26-0.58,0.28-0.74c-1.29-0.27-2.33-2.21-2.71-3.33c-0.15-0.43-0.3-0.93-0.42-1.44
      C741.87,43.57,741.95,43.99,742.05,44.41z"/>
    <path fill="#D9A486" d="M728.88,36.66c0.14-0.2,0.29-0.4,0.46-0.59c0.34-0.4,0.63-0.88,0.17-1.34
      c-0.2-0.2-0.45-0.31-0.73-0.36c-0.32-0.06-0.64-0.07-0.96-0.11c0.22,0.15,0.41,0.27,0.48,0.3c0.34,0.19,0.62,0.47,0.79,0.82
      c0.18,0.38-0.06,0.78-0.17,1.14C728.91,36.56,728.89,36.61,728.88,36.66z"/>
    <path fill="#D9A486" d="M734.03,40.96c-0.66,0.23-1.18-0.03-1.79-0.24c0.39,0.71,1.3,1.6,2.13,1.1
      c0.58-0.35,0.75-1.14,0.45-1.71C734.73,40.5,734.44,40.82,734.03,40.96z"/>
    <path fill="#C8DCF3" d="M740.91,49.82c0.34,0.29,0.55,1.3,0.59,2.16c-0.98,0.02-2.52,1.56-3.35,2.48
      C738.48,52.7,739.5,50.05,740.91,49.82z"/>
    <path fill="#A8546E" d="M734.11,55.33c0.21-1.13,0.55-2.35,0.77-2.49c0.45-0.29,1.8-0.03,2.26-0.16
      c-0.64,1.46-0.85,3.06-1.34,4.57c-0.52,1.61-1.46,2.98-1.95,4.6c0.07,0.08,0.3,0.2,0.34,0.24c-0.11,0.01-0.53-0.01-0.64,0
      C733.55,60.38,733.72,57.5,734.11,55.33z"/>
    <path fill="#B86D84" d="M736.05,53.23c0.31,0.02,0.59,0.02,0.88-0.02c0.07-0.18,0.14-0.36,0.21-0.53
      c-0.46,0.13-1.81-0.13-2.26,0.16c-0.08,0.05-0.17,0.23-0.27,0.5C735.05,53.17,735.59,53.2,736.05,53.23z"/>
    <path fill="#B46C80" d="M732.68,69.78c0.4-2.37,0.87-4.83,0.61-7.24c0.57-0.06,2.18,0.03,2.11-0.25
      c-0.09,0-0.82-0.36-1.11-0.6c0.17-0.45,1.39-2.81,1.68-3.62c0.69-1.88,0.88-3.83,1.67-5.74c0.23-0.11,0.61-0.19,0.83-0.24
      c-0.36,1-0.75,2.43-0.9,3.21c0.09,0,0.28-0.08,0.46-0.23c-1.08,6.73-5.18,13.25-4.88,20.18c-0.25,0.61-0.47,1.23-0.66,1.87
      C732.04,74.64,732.25,72.29,732.68,69.78z"/>
    <path fill="#C88898" d="M733.29,62.54c0.01,0.14,0.03,0.27,0.04,0.41c0.46-0.1,1.48-0.33,1.9-0.52
      C734.8,62.54,733.73,62.5,733.29,62.54z"/>
    <path fill="#C88898" d="M738.35,52.43c0.04-0.12,0.08-0.23,0.12-0.34c-0.22,0.05-0.6,0.13-0.83,0.24
      c-0.05,0.12-0.1,0.24-0.14,0.36C737.77,52.56,738.06,52.47,738.35,52.43z"/>
    <path fill="#F5C4A1" d="M713.1,83.48c-0.75,0.15-1.31-0.04-1.4-0.88c-0.07-0.7,0.02-1.4,0.52-1.52
      c0.66,0,0.91-0.29,0.86-0.3c-1.31-0.11-1.85-0.2-1.85-1.22c0-0.86,0.64-1.27,1.26-1.69c0.5,0.58,1.48,0.85,2.13,0.89
      c1.58,0.1,2.6-0.99,3.15-2.32c0.18-0.08,0.36-0.16,0.54-0.26c0.87-0.47,1.48,0.03,1.53,0.89c0.07,1.18-0.35,1.51-1.5,1.95
      c-1.44,0.54-3.12,1-4.45,1.43c-0.11,0.04,0.17,0.19,0.67,0.11c0.94-0.17,3.8-0.92,4.77-1.37c1.28-0.16,1.61,0.92,1.3,2.01
      c-0.16,0.56-0.75,0.79-1.24,0.95c-0.9,0.3-1.9,0.42-2.83,0.61C715.41,83.01,714.26,83.25,713.1,83.48z"/>
    <path fill="#D9A486" d="M716.25,79.06c0.72-0.42,1.28-0.97,1.59-1.75c0.15-0.37,0.32-0.74,0.46-1.11
      c-0.17,0.09-0.34,0.17-0.52,0.25c-0.55,1.33-1.58,2.42-3.15,2.32c-0.6-0.04-1.46-0.26-1.98-0.74
      C712.89,79.59,715.15,79.7,716.25,79.06z"/>
    <path fill="#F5C4A1" d="M714.56,86.29c-1.04,0.48-2.15-1.32-1.07-1.99c0.47-0.29,0.86-0.5,1.14-0.65
      c0.7-0.16,1.7-0.44,2.39-0.58c0.89-0.19,2.52-0.3,3.44-0.88c0.44,0.95-0.33,1.3-1.19,1.72C718.3,84.4,715.47,85.87,714.56,86.29z"
      />
    <path fill="#D9A486" d="M720.59,82.72c0-0.15-0.03-0.32-0.13-0.52c-0.92,0.59-2.54,0.7-3.44,0.88
      c-0.69,0.14-1.69,0.42-2.39,0.58c-0.28,0.15-0.67,0.37-1.14,0.65c-0.11,0.07-0.2,0.15-0.27,0.24
      C713.85,84.48,719.57,83.19,720.59,82.72z"/>
    <path fill="#F5C4A1" d="M720.36,85.49c-0.34,0.5-1.07,0.87-1.59,1.13c-0.79,0.39-3.01,1.78-3.27-0.15
      c0.17-0.11,0.32-0.22,0.46-0.3c0.98-0.58,2.02-1.07,3.04-1.58c0.39-0.19,0.79-0.38,1.14-0.59
      C720.78,84.58,720.63,85.1,720.36,85.49z"/>
    <path fill="#D9A486" d="M720.46,84.39c-0.07-0.12-0.17-0.25-0.32-0.38c-0.35,0.21-0.76,0.4-1.14,0.59
      c-1.02,0.51-2.06,1-3.04,1.58c-0.14,0.08-0.29,0.19-0.46,0.3c0.02,0.15,0.05,0.28,0.1,0.39C715.81,86.73,719.64,84.89,720.46,84.39
      z"/>
    <path fill="#F5C4A1" d="M719.37,86.93c1.26-0.69,2.68-1.95,1.11-3.15c0.56-0.42,0.86-1.1,0.32-1.86
      c0.16-0.16,0.28-0.34,0.35-0.55c0.39-1.13-0.18-2.63-1.26-2.61c0.82-0.59,0.65-2.28-0.03-2.9c-0.53-0.48-1.21-0.33-1.82,0.07
      c0.23-0.68,0.54-1.65,0.14-1.92c-0.29,2.08-1.94,4.5-4.3,4.04c-0.64-0.12-1.25-0.57-1.27-1.27c-0.03-0.78,1.45-1.43,2.04-1.76
      c1-0.56,1.92-1.21,2.71-2.05c0.61-0.64,1.15-1.49,1.79-0.93c0.41,0.36,0.37,0.89,0.45,1.22c0.21,0.9,0.7,1.64,1.2,2.41
      c0.5,0.78,0.87,1.57,1.06,2.44c0.42,1.96,0.6,4.83,0.66,5.83c0.06,1,0.21,2.51,0.2,3.44C722.34,87.09,720.12,87.24,719.37,86.93z"
      />
    <path fill="#F9DDC9" d="M713.53,76.36c0.73-0.56,1.53-1.03,2.29-1.55c0.57-0.39,1.14-0.8,1.64-1.29
      c0.35-0.34,0.64-0.71,1.08-0.93c0.41-0.21,0.75-0.03,1.01,0.27c-0.04-0.27-0.12-0.57-0.38-0.8c-0.64-0.56-1.18,0.29-1.79,0.93
      c-0.79,0.84-1.71,1.48-2.71,2.05c-0.59,0.33-2.07,0.98-2.04,1.76c0.01,0.2,0.06,0.38,0.16,0.54
      C712.85,76.94,713.19,76.62,713.53,76.36z"/>
    <path fill="#F9DDC9" d="M721.9,79.27c0.09,0.24,0.18,0.48,0.27,0.72c-0.08-0.65-0.19-1.29-0.31-1.86
      c-0.19-0.87-0.55-1.66-1.06-2.44c-0.34-0.52-0.67-1.03-0.91-1.58C720.24,75.92,721.26,77.53,721.9,79.27z"/>
    <path fill="#D9A486" d="M718.96,75.57c0.01-0.44-0.26-0.92-0.59-1.23c0.07,0.42-0.14,1.09-0.32,1.6
      C718.34,75.74,718.65,75.61,718.96,75.57z"/>
    <path fill="#D9A486" d="M722.72,87.39c0.01-0.92-0.14-2.44-0.2-3.44c-0.01-0.16-0.02-0.36-0.04-0.6
      c-0.07,1.3-0.21,2.6-0.44,3.87C722.36,87.26,722.61,87.31,722.72,87.39z"/>
    <path fill="#D9A486" d="M721.42,84.65c0.15-0.31,0.3-0.6,0.26-0.97c-0.03-0.26-0.03-0.74-0.52-1.05
      c0.55-0.41,0.7-1.29,0.49-1.92c-0.1-0.3-0.32-0.98-0.67-1.26c0.31,0.53,0.39,1.28,0.17,1.92c-0.07,0.21-0.2,0.39-0.35,0.55
      c0.55,0.75,0.24,1.43-0.32,1.86c1.58,1.2,0.15,2.46-1.11,3.15c0.35,0.15,1.03,0.19,1.69,0.23c0.23-0.27,0.38-0.56,0.37-0.86
      C721.41,85.68,721.13,85.25,721.42,84.65z"/>
    <path fill="#D9A486" d="M719.89,78.77c0.24,0,0.46,0.07,0.64,0.2c0.18-0.53,0.33-1.08,0.19-1.64
      c-0.1-0.39-0.27-0.72-0.53-0.99C720.57,77.11,720.55,78.29,719.89,78.77z"/>
    <path fill="#8C394D" d="M722.71,87.78c-0.01,0.21-0.15,1.17-0.3,1.4c-0.23,0.35-0.32-0.74-0.23-1.44
      C722.39,87.73,722.61,87.76,722.71,87.78C722.7,87.78,722.7,87.78,722.71,87.78z"/>
    <path fill="#782E40" d="M722.61,88.45c0.05-0.29,0.09-0.57,0.1-0.68c0,0,0,0,0,0c-0.09-0.02-0.32-0.05-0.53-0.05
      c-0.03,0.23-0.04,0.5-0.03,0.75C722.3,88.48,722.46,88.46,722.61,88.45z"/>
    <path fill="#A8546E" d="M729.25,91.94c-1.18-0.38-2.39-0.64-3.57-1.02c-0.94-0.31-2.16-0.67-3.16-0.54
      c-1,0.13-1.13-1.66-0.96-2.71c0.12,0.01,0.35,0.04,0.43,0.04c-0.11,0.63-0.08,2.12,0.35,1.94c0.84-0.35,0.78-1.74,0.79-2.46
      c0.01-1.21-0.4-5.41-0.46-6.35c0.81-0.03,1.39,0.62,1.94,1.17c0.55,0.56,1.1,1.03,1.73,1.5c1.26,0.92,2.77,2.07,4.36,2.3
      c0.11,2.21,0.43,4.42,0.71,6.62C730.64,92.6,729.94,92.17,729.25,91.94z"/>
    <path fill="#A8546E" d="M731.23,113.39c-0.69-0.47-1.46-0.69-2.27-0.61c0.25-0.59,0.98-0.99,1.44-1.39
      c0.47-0.41,0.94-0.81,1.35-1.28c0.85-0.98,1.04-2.34,1.2-3.58c0.45-3.5-0.28-7.19-0.6-10.68c-0.36-3.91-1.15-7.77-1.18-11.71
      c-0.02-2.81-0.01-6.04,0.65-8.84c0.07,0.8,0.17,1.73,0.47,2.47c-0.05,0.18-0.09,0.37-0.13,0.55c-0.95,4.6,0.54,9.48,1.2,14.01
      c0.34,2.38,0.41,4.78,0.86,7.14c0.28,1.43,0.69,2.81,1.24,4.13c-0.19-0.11-0.41-0.16-0.63-0.26c-0.33-0.14-0.4,2.17-0.28,2.47
      c0.12,0.29,0.34,0.45,0.51,0.55c-0.33,1.2-0.66,3.86-0.75,5.76c-0.05,1.02-0.11,2.04-0.2,3.06c0,0,0,0-0.01,0
      C732.96,115.1,732.14,114,731.23,113.39z"/>
    <path fill="#914056" d="M732.04,79.1c0.04-0.26,0.08-0.52,0.14-0.78c0.04-0.19,0.09-0.37,0.13-0.55
      c-0.3-0.74-0.4-1.67-0.47-2.47c-0.13,0.54-0.23,1.1-0.32,1.67C731.62,77.69,731.8,78.4,732.04,79.1z"/>
    <path fill="#914056" d="M732.15,88.49c0.34,2.4,0.78,4.79,1.1,7.2c0.18,1.38,0.23,2.83,0.58,4.17
      c0.3,1.16,0.78,2.28,0.99,3.46c0,0.01,0,0.01,0,0.02c0.01,0,0.03,0,0.04,0c0.22,0.09,0.44,0.15,0.63,0.26
      c-0.55-1.33-0.97-2.71-1.24-4.13c-0.46-2.36-0.52-4.76-0.86-7.14c-0.46-3.17-1.33-6.51-1.48-9.81
      C731.69,84.5,731.87,86.52,732.15,88.49z"/>
    <path fill="#914056" d="M734.37,104.64c-0.17,0.57-0.32,1.21-0.25,1.82c0.06,0.62,0.32,1.17,0.63,1.7
      c0.11-0.72,0.23-1.36,0.35-1.8c-0.17-0.1-0.39-0.26-0.51-0.55c-0.07-0.16-0.08-0.97-0.01-1.61
      C734.49,104.34,734.41,104.49,734.37,104.64z"/>
    <path fill="#B86D84" d="M730.09,112.86c0.5-0.92,2.11-1.81,2.67-3.11c0.59-1.38,0.45-2.9,0.32-4.39
      c-0.02,0.39-0.06,0.78-0.11,1.17c-0.16,1.24-0.36,2.6-1.2,3.58c-0.41,0.47-0.88,0.87-1.35,1.28c-0.46,0.4-1.19,0.8-1.44,1.39
      C729.35,112.74,729.73,112.77,730.09,112.86z"/>
    <path fill="#7A4A38" d="M692.37,179.07c1.98,2.75,6.09,11.05,6.67,12.44c-0.52,1.29-3.53,1.07-5,0.72
      c-1.89-0.44-3.95-1.41-5.8-2.09c-1.63-2.21-2.79-4.04-4.41-6.56c-0.87-1.35-1.8-2.62-2.81-3.84c1.69-0.36,2.66-2.74,2.38-5.27
      C686.03,176.61,689.02,178.47,692.37,179.07z"/>
    <path fill="#8D6354" d="M683.44,175.66c1.35,1.12,2.84,2.09,4.51,2.65c0.89,0.3,1.83,0.56,2.77,0.65
      c0.4,0.04,0.82,0.06,1.23,0.02c-3.18-0.67-6.03-2.46-8.54-4.51C683.44,174.88,683.46,175.28,683.44,175.66z"/>
    <path fill="#633A29" d="M692.37,179.07c-0.03-0.01-0.07-0.01-0.1-0.02c0.86,1.32,1.33,2.91,1.81,4.38
      c0.73,2.22,1.4,4.43,1.82,6.74c0.14,0.78,0.26,1.56,0.36,2.34c1.24,0.02,2.47-0.21,2.78-0.99
      C698.46,190.12,694.35,181.82,692.37,179.07z"/>
    <path fill="#BF9678" d="M675.84,169.73c0.05-2.29,2.44-0.45,3.48,0.68c1,1.09,2.86,3.2,3.69,3.75
      c0.33,2.14-0.76,4.68-2.37,5.1c-0.71-0.85-1.38-1.61-2.07-2.5c-0.92-1.19-1.65-2.67-2.15-4.18
      C676.07,171.54,675.82,170.43,675.84,169.73z"/>
    <path fill="#D2B59E" d="M677.84,170.35c1.81,1.36,3.22,3.19,4.9,4.71c0.1,0.09,0.21,0.19,0.31,0.28
      c0.03-0.4,0.01-0.79-0.05-1.18c-0.83-0.55-2.69-2.66-3.69-3.75c-1.05-1.13-3.43-2.97-3.48-0.68c0,0.04,0,0.08,0,0.11
      C676.16,169.15,677.35,169.98,677.84,170.35z"/>
    <path fill="#8C8885" d="M682.01,183.5c-3.22-4.16-7.57-8.66-7.6-13.46c0.22-0.11,0.61-0.22,0.82-0.26
      c0.21,1.46,0.53,2.64,1.04,4.03c0.49,1.33,1.17,2.49,2.04,3.6c1.87,2.39,3.72,4.48,5.41,7.03c1.62,2.45,4.84,7.12,5.87,8.43
      C688.49,192.11,683.7,185.7,682.01,183.5z"/>
    <path fill="#8C8885" d="M692.35,199.26c-1.86-0.98-5.02-5.46-6.68-8.55c0.34-0.22,0.69-0.46,0.9-0.62
      c1.97,2.87,5.92,6.36,7.52,7.17C693.65,197.96,692.99,198.77,692.35,199.26z"/>
    <path fill="#6F6C69" d="M687.57,192.71c0.73,1.1,1.59,2.05,2.62,2.88c0.97,0.78,2.03,1.56,3.17,2.06
      c0.12,0.05,0.23,0.1,0.35,0.15c0.13-0.18,0.26-0.37,0.37-0.54c-1.59-0.81-5.54-4.29-7.52-7.17c-0.12,0.09-0.29,0.21-0.47,0.34
      C686.61,191.17,687.07,191.95,687.57,192.71z"/>
    <path fill="#BF9678" d="M694.55,196.92c-1.71-1.39-4.25-3.74-6-6.06c-0.02-0.02-0.03-0.04-0.05-0.06
      c1.93,0.95,3.88,1.73,6.01,2.21c0.73,0.16,3.02,0.39,4.14-0.13C697.9,194.71,696.45,196.52,694.55,196.92z"/>
    <path fill="#A87E5F" d="M696.34,193.2c0.1,0.9,0.16,1.8,0.2,2.7c0.93-0.79,1.65-1.9,2.11-3.03
      C698.07,193.15,697.17,193.22,696.34,193.2z"/>
    <path fill="#26548D" d="M698.69,194.29c0.27-0.46,0.59-1.38,0.71-1.87C699.76,193.22,699.51,194.15,698.69,194.29z"/>
    <path fill="#BF9678" d="M774.7,175.74c-1.24,1.22-1.93-1.17-2.06-1.83c-0.35-1.71-1.11-3.22-1.9-4.73
      c0.52-1.52,1.81-3.23,3.74-2.74c0.13,0.8,0.94,4.86,1.04,6.06C775.66,174.24,775.29,175.16,774.7,175.74z"/>
    <path fill="#8C8885" d="M774.13,148c0,1.18,0.22,5.33,0.69,7.84c-0.47,0.14-0.89,0.31-1.15,0.46
      c-0.47-1.91-0.96-5.55-1.55-7.92C772.83,147.94,773.66,147.83,774.13,148z"/>
    <path fill="#8C8885" d="M771.63,148.6c0.08,0.34,0.86,4.44,1.06,5.73c0.38,2.38,0.91,4.67,1.52,7.01
      c0.81,3.11,2.15,9.4,1.71,10.89c-0.19-1.32-1.41-7.76-1.74-9.22c-1.1-4.87-2.44-9.66-2.93-14.64
      C771.35,148.39,771.56,148.53,771.63,148.6z"/>
    <path fill="#6F6C69" d="M772.64,150.88c0.37,1.97,0.7,4.1,1.03,5.42c0.17-0.1,0.4-0.21,0.68-0.31
      C773.79,154.28,773.16,152.6,772.64,150.88z"/>
    <path fill="#A4A09E" d="M773.76,148.64c0.12-0.01,0.25-0.02,0.38-0.02c-0.01-0.25-0.01-0.46-0.01-0.62
      c-0.47-0.17-1.29-0.06-2.01,0.38c0.05,0.19,0.09,0.39,0.14,0.59C772.71,148.75,773.29,148.67,773.76,148.64z"/>
    <path fill="#A4A09E" d="M771.75,149.19c-0.06-0.31-0.1-0.52-0.12-0.59c-0.07-0.07-0.28-0.21-0.39-0.24
      c0.03,0.27,0.06,0.55,0.09,0.82C771.47,149.18,771.61,149.18,771.75,149.19z"/>
    <path fill="#BF9678" d="M770.93,148.27c0.07,1.6,0.35,3.73,0.7,5.53c-0.56-0.82-4.59-3.97-5.77-4.87
      C766.65,148.11,768.98,147.23,770.93,148.27z"/>
    <path fill="#D2B59E" d="M769.26,148.35c0.55,0.04,1.16,0.13,1.7,0.37c-0.01-0.15-0.02-0.3-0.02-0.45
      c-1.95-1.03-4.28-0.16-5.07,0.66c0.08,0.06,0.17,0.13,0.27,0.21C766.93,148.42,768.25,148.28,769.26,148.35z"/>
    <path fill="#7A4A38" d="M765.38,154.77c0.2-1,0.35-2.01,0.42-3.02c0.06-0.88,0.02-1.72-0.11-2.5
      c0.4,0.48,4.24,3.56,6.11,5.36c0.49,2.31,2.07,8.65,2.52,11.3c-2.39-0.58-3.5,2.04-3.72,3c-2.04-2.72-4.48-10.41-5.66-11.81
      C765.11,156.47,765.26,155.38,765.38,154.77z"/>
    <path fill="#633A29" d="M766,149.55c-0.15-0.14-0.26-0.25-0.32-0.31c0.14,0.79,0.17,1.62,0.11,2.5
      c-0.07,1.02-0.22,2.02-0.42,3.02c-0.12,0.61-0.27,1.71-0.44,2.32c0.35,0.41,0.8,1.37,1.33,2.58
      C766.95,156.29,767.1,152.89,766,149.55z"/>
    <path fill="#3D6699" d="M740.59,130.68c-0.54,2.33-0.64,4.74-0.7,7.13c-0.06,2.46-0.17,4.64-0.39,7.09
      c-0.12,1.3-0.67,3.44-0.28,4.85c0.46,1.63,1.32,1.25,1.18,1.01c-0.18-0.3-0.3-0.62-0.4-0.94c1.01,0.56,2.32,0.51,3.45,0.4
      c2.32-0.23,4.52-1,6.76-1.62c2.46-0.69,4.85-0.72,7.38-0.71c1.37,0.01,5.11,0.32,7.3,0.7c0.2,0.63,0.3,1.68,0.32,2.37
      c0.02,1.13-0.19,2.26-0.39,3.37c-0.4,2.14-1.32,4.18-1.17,6.37c-3.48-0.96-6.93-1.78-10.55-1.98c-4.39-0.24-8.8-0.47-13.18-0.86
      c-1.84-0.16-3.67-0.65-5.23-1.66c-1.81-1.18-2.5-2.9-2.63-4.98c-0.11-1.73-0.02-3.47-0.14-5.21c-0.14-2.1-0.31-4.2,0.04-6.29
      c0.44-0.59,0.89-1.17,1.34-1.75c2.52-3.22,7.03-9.46,8.41-10.73C741.18,128.33,740.85,129.55,740.59,130.68z"/>
    <path fill="#2B528C" d="M733.33,140.76c1.27-1.97,2.75-3.78,4.14-5.67c0.99-1.35,1.96-2.79,3.05-4.09
      c0.02-0.11,0.05-0.21,0.07-0.32c0.26-1.14,0.59-2.35,1.12-3.45c-1.38,1.27-5.89,7.51-8.41,10.73c-0.45,0.58-0.9,1.17-1.34,1.75
      c-0.18,1.11-0.22,2.22-0.19,3.34C732.32,142.32,732.83,141.54,733.33,140.76z"/>
    <path fill="#2B528C" d="M763.69,159.22c-2.51-0.88-5.12-1.13-7.78-1.39c-2.99-0.29-5.99-0.31-8.99-0.14
      c-1.45,0.08-2.89,0.28-4.34,0.39c3.5,0.27,7.01,0.45,10.51,0.65c3.62,0.2,7.07,1.02,10.55,1.98
      C763.61,160.21,763.63,159.71,763.69,159.22z"/>
    <path fill="#5C83B0" d="M740.28,150.54c1.02,0.28,4.25,0.73,7.63-0.33c3.82-1.2,8.49-1.47,12.95-1
      c1.13,0.12,2.25,0.29,3.37,0.46c0.29,0.05,0.59,0.09,0.88,0.14c-0.05-0.44-0.13-0.88-0.23-1.21c-2.19-0.38-5.93-0.69-7.3-0.7
      c-2.53-0.01-4.92,0.02-7.38,0.71c-2.23,0.62-4.44,1.39-6.76,1.62c-1.13,0.11-2.44,0.16-3.45-0.4
      C740.08,150.07,740.16,150.31,740.28,150.54z"/>
    <path fill="#3D6699" d="M740.42,127.5c-2.46,3.05-4.81,6.19-7.17,9.32c-2.16,2.87-4.28,5.77-6.44,8.63
      c-1.12,1.48-2.28,2.93-3.48,4.33c-1.41,1.64-3.34,3.45-5.33,4.4c-0.17,0.08,0.84,0.31,1.94-0.37c-1.06,1.33-2.57,3.09-3.26,4.13
      c-1.1,1.64-1.83,3.35-2.77,5.08c-2.18,4.02-3.8,8.31-5.77,12.43c-1.88,3.93-3.84,7.73-5.13,11.92c-0.51,1.68-0.79,3.41-1.21,5.11
      c-0.33,1.36-1.04,1.81-2.03,1.88c1.16-1.3-0.43-3.88-1.06-5.13c-1.84-3.68-3.81-7.75-6.18-11.25c0.63-0.26,1.09-0.6,1.48-1.22
      c0.86-1.4,1.68-2.8,2.42-4.27c1.59-3.15,3.32-6.08,5.36-8.97c1.89-2.67,3.79-5.34,5.8-7.93c1.26-1.63,2.59-3.28,3.51-5.13
      c-0.07,0.69-0.11,2.71-0.05,2.53c0.59-1.59,0.69-3.62,1.08-5.25c0.46-1.91,2.01-3.4,3.24-4.85c2.51-2.95,4.71-6.12,7.08-9.18
      c2.39-3.09,5.16-5.86,7.67-8.85c1.19-1.42,2.28-2.92,3.32-4.45c1.02-1.51,1.95-3.23,2.54-4.93c0.04-0.12-0.74,0.77-1.89,2.91
      c0.55-1.63,0.71-3.48,0.79-5.23c0.12-2.35,0.24-4.34,0.53-6.53c0.32,0.3,2.21,1.25,2.84,1.35c0.09,0.01,0.16-0.04,0.19-0.11
      c0.18,0.09,0.8,0.29,1.16,0.35c1,3.79,1.24,10.2,0.65,12.91c1.72-0.05,4.75,0.06,5.34,0.13C744.5,123.9,742.17,125.34,740.42,127.5
      z"/>
    <path fill="#2B528C" d="M739.88,109.34c-0.08-0.39-0.16-0.76-0.26-1.11c-0.36-0.06-0.98-0.26-1.16-0.35
      c-0.03,0.07-0.11,0.13-0.19,0.11c-0.63-0.1-2.52-1.05-2.84-1.35c-0.06,0.42-0.1,0.83-0.15,1.25
      C736.59,108.89,738.29,109.08,739.88,109.34z"/>
    <path fill="#2B528C" d="M739.37,122.05c0.56,0,4.63-0.05,5.87-0.02c0.14-0.24,0.27-0.49,0.38-0.76
      c-0.59-0.07-3.62-0.18-5.34-0.13c0.4-1.85,0.41-5.44,0.06-8.74C740.33,112.56,739.87,118.58,739.37,122.05z"/>
    <path fill="#5C83B0" d="M712.91,148.23c0.59-1.61,1.69-3.03,2.75-4.36c0.97-1.22,2-2.39,2.99-3.59
      c1.07-1.29,2.08-2.64,3.09-3.97c1.96-2.58,3.99-5.07,6.13-7.51c2.17-2.48,4.23-5.06,6.04-7.82c0.98-1.49,1.8-3.2,1.88-4.95
      c-0.59,1.52-1.43,3.05-2.34,4.4c-1.04,1.53-2.13,3.03-3.32,4.45c-2.51,2.99-5.28,5.76-7.67,8.85c-2.37,3.06-4.57,6.23-7.08,9.18
      c-1.23,1.45-2.79,2.94-3.24,4.85c-0.34,1.44-0.46,3.2-0.89,4.68C712.22,151.3,712.41,149.6,712.91,148.23z"/>
    <path fill="#5C83B0" d="M694.72,177.21c0.87-0.83,1.74-2.45,2.17-3.16c0.87-1.44,2.91-5.25,4.62-7.88
      c1.76-2.71,4.39-6.61,6.32-9.2c1.63-2.17,3.05-4.73,3.23-5.5c0.01-0.39,0.03-0.77,0.06-0.99c-0.92,1.85-2.25,3.5-3.51,5.13
      c-2,2.59-3.91,5.26-5.8,7.93c-2.04,2.88-3.77,5.82-5.36,8.97c-0.74,1.47-1.56,2.87-2.42,4.27c-0.39,0.62-0.85,0.96-1.48,1.22
      c0.03,0.04,0.05,0.08,0.08,0.12C692.95,178.13,693.83,178.06,694.72,177.21z"/>
    <path fill="#8C394D" d="M755.13,125.72c-1.25-0.18-2.46-0.74-3.64-1.16c-1.83-0.67-3.97-1.29-6.16-1.16
      c0.4-0.64,0.69-1.32,0.98-2.03c4.18,0.23,10.27,1.96,10.37,3.82C756.71,125.88,755.65,125.79,755.13,125.72z"/>
    <path fill="#782E40" d="M746.31,121.36c-0.11,0.27-0.22,0.53-0.33,0.79c1.25,0.06,2.5,0.24,3.71,0.52
      c1.53,0.36,3.13,0.72,4.59,1.31c0.73,0.3,1.87,0.81,2.11,1.66c0.18-0.09,0.3-0.23,0.28-0.47
      C756.58,123.32,750.49,121.59,746.31,121.36z"/>
    <path fill="#A8546E" d="M758.04,52.27c-0.67,2.34-2.55,7.91-3.13,10.05c-1.24,4.56-2.27,9.18-3.08,13.84
      c-0.38,2.19-0.65,4.39-1.05,6.57c-0.43,2.34-0.97,4.67-1.28,7.03c-0.56,4.27-0.07,9.2,0.9,13.73c-0.4-0.78-1.36-2.38-1.34-2.31
      c0.41,1.58,1.19,3.17,2.14,5.15c0.9,1.88,2.26,5.11,3.01,7.32c0.73,2.14,2.75,8.07,2.72,10.58c-1.4-2.55-12.67-4.1-15.92-3.62
      c0.58-3.76,0.19-7.58-0.51-11.29c-0.74-3.92-1.55-7.77-1.91-11.75c0.2-2.32,0.45-4.63,0.72-6.93c0.77-1.93,1.31-3.95,1.88-5.95
      c0.72-2.49,1.41-4.98,2.07-7.48c1.36-5.12,2.82-10.43,4.84-15.34c-1.27-0.36-2.68,0.75-3.97,0.77c0.63-0.51,1.47-1.08,2.07-1.42
      c-0.05-3.13,0.89-8.74,2.87-12.53c1.32,0.23,3.18,0.11,4.64-0.11c1.28-0.19,4.62-0.67,5.3-0.05
      C759.21,48.68,758.72,49.93,758.04,52.27z"/>
    <path fill="#B86D84" d="M751.96,49.23c1.21-0.1,2.49-0.24,3.67-0.47c0.91-0.18,1.92-0.46,2.85-0.25
      c0.21,0.05,0.4,0.13,0.58,0.24c0.01-0.12,0.01-0.2-0.03-0.24c-0.67-0.63-4.02-0.14-5.3,0.05c-1.46,0.22-3.32,0.34-4.64,0.11
      c-0.04,0.08-0.08,0.16-0.12,0.24C749.93,49.23,750.95,49.32,751.96,49.23z"/>
    <path fill="#914056" d="M746.86,61.88c0.02-2.04,0.16-4.08,0.42-6.11c0.24-1.83,0.78-3.6,1.05-5.43
      c-1.46,3.63-2.15,8.16-2.11,10.86c-0.6,0.34-1.44,0.92-2.07,1.42C745.02,62.61,745.95,62.1,746.86,61.88z"/>
    <path fill="#914056" d="M742.8,82.12c0.88-2.87,2.64-9.05,3.55-11.91c0.45-1.42,2.3-7,2.4-7.63
      c-0.3-0.05-0.62-0.01-0.93,0.01c-1.87,4.7-3.25,9.73-4.54,14.6c-0.67,2.5-1.35,4.99-2.07,7.48c-0.58,1.99-1.11,4.02-1.88,5.95
      c-0.07,0.59-0.14,1.18-0.21,1.78C739.78,91.62,741.44,86.54,742.8,82.12z"/>
    <path fill="#B86D84" d="M751.2,106.32c-0.85-1.77-1.56-3.23-2-4.65c0,2.13,1.11,4.24,1.92,6.14
      c0.95,2.23,1.96,4.45,2.87,6.7c1.19,2.96,2.14,6.03,2.51,9.18c0.19,0.17,0.34,0.34,0.43,0.52c0.03-2.51-1.99-8.43-2.72-10.58
      C753.46,111.43,752.11,108.2,751.2,106.32z"/>
    <path fill="#B86D84" d="M741.01,120.59c0.32-0.05,0.73-0.07,1.19-0.08c0.12-0.77,0.15-1.56,0.14-2.27
      c-0.03-2.35-0.34-4.74-0.82-7.03c-0.5-2.38-1.28-4.67-1.88-7.02c-0.1-0.38-0.2-0.76-0.31-1.15c0.36,2.09,0.77,4.17,1.17,6.26
      C741.21,113.01,741.6,116.83,741.01,120.59z"/>
    <path fill="#A8546E" d="M780.39,108.66c-0.27,0.16-0.74,0.27-1.06,0.39c-1.02-2.03-1.63-4.22-2.37-6.37
      c-0.83-2.41-2.1-4.61-3.39-6.79c-1.29-2.18-2.34-4.45-3.45-6.72c-1-2.04-1.71-4.03-2.29-6.2c1.44,2.66,2.22,3.11,2.14,2.96
      c-1.3-2.49-2.63-4.98-3.84-7.52c-1.2-2.54-2.13-5.17-3.18-7.77c-1.05-2.59-4.89-11.33-6.1-12.74c0.83-2.82,2.2-7.2,2.53-8.62
      c1.04,1.79,1.79,4.86,2.77,7.21c1.1,2.63,2.4,5.32,3.66,7.87c2.54,5.12,5.53,9.86,8.07,14.98c2.39,4.82,4.48,9.77,7.68,14.13
      c1.64,2.23,3.47,4.29,5.26,6.4c1.21,1.43,3.58,2.8,2.18,3.85c-0.06-0.06-0.25-0.28-0.31-0.33c0.2-0.16,0.4-0.5,0.09-0.6
      c-1.91-0.67-3.69,0.04-5.24,1.22c-0.81,0.62-1.52,1.37-2.18,2.15c-0.53,0.63-1.29,1.47-1.53,2.27c-0.03,0.1,0.54-0.21,0.65-0.31
      C780.44,108.29,780.41,108.48,780.39,108.66z"/>
    <path fill="#B86D84" d="M760.23,51.85c0.28,1.06,0.63,2.1,1.05,3.11c0.91,2.19,1.74,4.42,2.72,6.58
      c1,2.19,2.28,4.23,3.42,6.34c1.17,2.18,2.3,4.37,3.4,6.59c1.52,3.06,2.93,6.17,4.5,9.2c1.52,2.92,3.03,5.93,4.87,8.67
      c0.89,1.32,1.94,2.67,3,3.87c1.1,1.24,2.08,2.44,3.04,3.68c0.47,0.61,1.14,1.14,1.62,1.59c0.64,0.6,1.04,1.03,1.27,1.31
      c0.11,0.13,0.21,0.27,0.29,0.41c0.26-0.96-1.57-2.11-2.58-3.31c-1.79-2.11-3.62-4.17-5.26-6.4c-3.2-4.37-5.29-9.32-7.68-14.13
      c-2.54-5.12-5.53-9.86-8.07-14.98c-1.27-2.55-2.57-5.24-3.66-7.87c-0.98-2.34-1.73-5.41-2.77-7.21c-0.01,0.05-0.03,0.11-0.04,0.18
      C759.85,50.17,760,51.02,760.23,51.85z"/>
    <path fill="#914056" d="M759.06,50.48c-0.54,1.94-1.55,5.17-2.21,7.42c0.25,0.29,0.61,0.9,1.04,1.7
      C758.59,57.24,759.01,53.28,759.06,50.48z"/>
    <path fill="#914056" d="M779.83,108.42c0.09-0.31,0.26-0.61,0.46-0.92c-0.88-2.78-2.34-7.19-5.12-11.35
      c-2.27-3.39-4.5-7.35-5.21-10.2c-0.08,0.01-0.85-0.59-2.14-2.98c0.58,2.18,1.3,4.16,2.29,6.2c1.11,2.27,2.17,4.55,3.45,6.72
      c1.29,2.18,2.56,4.38,3.39,6.79c0.74,2.14,1.34,4.34,2.37,6.37c0.32-0.12,0.79-0.23,1.06-0.39c0.02-0.19,0.05-0.37,0.09-0.55
      C780.38,108.21,779.8,108.52,779.83,108.42z"/>
    <path fill="#F5C4A1" d="M792.18,112.86c-0.1,0.62-0.03,1.08-0.99,1.36c-0.66,0.19-0.6,0.93-0.81,1.32
      c-0.38,0.7-0.96,0.27-1.46,0.34c-0.45,0.06-0.79,0.19-1,0.6c-0.72,1.38-1.9,0.32-2.46-0.61c0-0.05,0-0.1-0.01-0.14
      c-0.08-0.78-0.49-1.6-0.97-2.21c-0.44-0.56-0.93-0.99-1.31-1.69c-0.38-0.7-0.31-1.62-0.29-2.29c0.01-0.2-0.29,0.26-0.38,0.61
      c-0.29,1.21,0.46,2.22,1.11,3.11c0.67,0.9,1.11,1.4,1.23,2.3c0.08,0.58,0.04,1.42-0.36,1.79c-0.79,0.75-1.13,0.04-1.22-0.6
      c-0.15-1.12-1-1.97-1.8-2.68c-0.48-0.42-0.62-1.01-0.65-1.62c-0.03-0.54-0.09-4.05-0.05-4.63c0.29-0.31,0.43-0.54,0.7-0.86
      c0.56-0.66,1.17-1.27,1.81-1.85c1.27-1.15,3.06-2.19,4.79-1.88c1.37,1.54,3.01,3.97,3.54,4.79c0.59,0.92,1.2,1.87,1.37,2.97
      C793.12,111.87,792.3,112.1,792.18,112.86z"/>
    <path fill="#F9DDC9" d="M792.3,112.49c0.26-0.49,0.79-0.78,0.67-1.5c-0.18-1.1-0.78-2.04-1.37-2.97
      c-0.52-0.81-2.13-3.2-3.49-4.73c0,0,0,0,0,0c0.15,0.4,0.46,0.8,0.67,1.13c0.3,0.48,0.62,0.95,0.93,1.42c0.64,0.99,1.26,1.99,1.85,3
      c0.42,0.74,1.09,1.76,0.93,2.66C792.42,111.84,792.35,112.16,792.3,112.49z"/>
    <path fill="#D9A486" d="M784.47,113.51c0.48,0.61,0.88,1.43,0.97,2.21c0,0.05,0.01,0.09,0.01,0.14
      c0.23,0.39,0.57,0.8,0.94,1.04c0.01-0.17,0.02-0.33,0.01-0.49c-0.02-1-0.39-1.84-1.03-2.6c-0.9-1.08-2.13-2.08-2.51-3.46
      c0.01,0.49,0.07,1.01,0.3,1.45C783.54,112.52,784.03,112.95,784.47,113.51z"/>
    <path fill="#D9A486" d="M781.61,108c0.67-0.84,1.36-1.67,2.14-2.41c0.74-0.71,1.55-1.29,2.41-1.83
      c0.35-0.22,0.71-0.44,1.08-0.57c-1.46,0.07-2.9,0.96-3.96,1.92c-0.64,0.58-1.25,1.19-1.81,1.85c-0.27,0.32-0.41,0.55-0.7,0.86
      c-0.01,0.2-0.02,0.73-0.01,1.38C781.01,108.78,781.3,108.39,781.61,108z"/>
    <path d="M735,30.6c-0.16-1.02-1.19-1.58-2.14-1.32c-0.89,0.24-1.47,1.25-1.5,2.01c-0.04,0.76,0.33,1.39,0.83,1.76
      c0.48,0.34,1.14,0.38,1.66,0.15C734.8,32.78,735.17,31.66,735,30.6z"/>
    <path fill="#FFFFFF" d="M733.82,32.64c-0.35,0.23-0.88,0.25-1.23,0.07c-0.72-0.38-0.98-1.2-0.61-2.09
      c0.21-0.51,0.78-0.92,1.32-0.96c0.69-0.05,1.27,0.46,1.3,1.31C734.62,31.68,734.41,32.24,733.82,32.64z"/>
    <path d="M730.5,34.03c0.19,0.67-0.12,1.35-1.04,1.27c-0.05,0,0,0.3,0.56,0.38c0.26,0.04,0.87-0.19,0.92-0.76
      C731.02,34.1,730.42,33.75,730.5,34.03z"/>
    <path d="M734.15,34.99c-1.14-0.56-2.23-0.12-2.2,0.2c0.58-0.19,0.98-0.15,1.49,0.09c-0.19,0.31-0.64,1.16-0.9,1.59
      c-0.48,0.79-0.84,1.18-1.72,1.19c-0.84,0.02-1.3-0.37-1.56-0.71c-0.15,0.43,0.57,1.25,1.48,1.19c0.5,0.79,1.82,1.73,2.76,1.34
      c1.45-0.6,0.56-3.04,0.37-4.38c0.46,0.29,0.86,0.68,1.21,1.06C735.15,36.66,735.19,35.5,734.15,34.99z"/>
    <path fill="#FFFFFF" d="M731.77,39.17c0.27-0.12,0.52-0.28,0.72-0.51c0.44-0.5,0.7-1.12,0.86-1.76
      c0.11-0.43,0.18-0.87,0.22-1.31c-0.35,0.71-0.76,1.98-1.49,2.56c-0.28,0.23-0.73,0.38-0.94,0.41
      C731.27,38.76,731.5,38.98,731.77,39.17z"/>
    <path fill="#FFFFFF" d="M732.47,39.55c0.56,0.21,1.12,0.18,1.3-0.41c0.12-0.39,0.15-0.82,0.13-1.26
      C733.54,38.54,733.09,39.2,732.47,39.55z"/>
    <path d="M734.79,39.89c-0.54,1.08-1.74,1.05-2.72,0.67c-0.08-0.03,0.94,1.04,1.98,0.67C735.32,40.78,734.84,39.8,734.79,39.89z"/>
    <path d="M782.28,96.2c-0.44,0.19-0.56,0.63-0.47,1.01c0.21,0.88,1.45,0.58,1.45-0.3C783.26,96.46,782.85,95.95,782.28,96.2z"/>
    <path fill="#873B4D" d="M782.19,97.28c-0.26-0.21-0.16-0.75,0.19-0.88c0.34-0.13,0.64,0.12,0.61,0.53
      C782.95,97.34,782.46,97.49,782.19,97.28z"/>
    <path d="M784.22,98c-0.43-0.21-1.22,0.01-1.17,0.83c0.03,0.53,0.57,0.97,1.06,0.74C784.83,99.22,784.72,98.24,784.22,98z"/>
    <path fill="#873B4D" d="M784.03,99.21c-0.39,0.2-0.66-0.14-0.68-0.39c-0.03-0.47,0.32-0.76,0.73-0.6
      C784.41,98.35,784.42,99.01,784.03,99.21z"/>
    <path d="M785.8,99.91c-0.61-0.31-1.24,0.28-1.2,0.91c0.04,0.63,0.82,1.05,1.3,0.69C786.34,101.18,786.52,100.28,785.8,99.91z"/>
    <path fill="#873B4D" d="M785.17,101.15c-0.36-0.22-0.26-0.83,0.06-0.97c0.46-0.2,0.74,0.14,0.78,0.45
      C786.05,100.94,785.72,101.5,785.17,101.15z"/>
    <path d="M741.63,91.81c-0.54-0.19-1.11-0.29-1.64-0.51c-0.08-0.03-0.12,0.06-0.08,0.12c0.38,0.58,1.1,0.92,1.76,0.59
      C741.76,91.96,741.71,91.84,741.63,91.81z"/>
    <path d="M741.28,97.95c-0.48,0.06-1.3,0.01-1.8-0.3c-0.05-0.03-0.1,0.06-0.06,0.11c0.23,0.33,0.58,0.59,0.97,0.6
      C741.34,98.37,741.37,97.94,741.28,97.95z"/>
    <path d="M742.77,104.82c-0.76,0.03-1.46-0.09-2.16-0.67c-0.06-0.05-0.18,0.03-0.15,0.12c0.31,0.83,1.62,1.4,2.37,0.79
      C742.94,104.98,742.88,104.82,742.77,104.82z"/>
    <path d="M741.8,91.29c0.13,0.21,0.29,0.49,0.26,0.74c-0.03,0.29-0.43,0.5-0.66,0.62c-0.03,0.01,0.7-0.03,0.83-0.52
      C742.4,91.5,741.77,91.25,741.8,91.29z"/>
    <path d="M741.58,97.68c0.28,0.25,0.28,0.67-0.44,1.25c-0.04,0.03,0.57-0.12,0.8-0.61C742.21,97.75,741.56,97.67,741.58,97.68z"/>
    <path d="M743.21,104.67c0.28,0.55-0.24,1.2-0.82,1.24c-0.03,0,0.76,0.21,1.07-0.44C743.76,104.81,743.19,104.63,743.21,104.67z"/>
    <path d="M741.36,46.68c-0.36-0.97-1.28-0.54-1.23-0.53c1.09,0.14,1.14,1.28,1.26,2.17C741.4,48.38,741.68,47.53,741.36,46.68z"/>
    <path d="M748.81,42.99c-1.23,0.71-3.09-0.12-3.53-1.44c-0.28-0.85,0.03-1.81-0.23-2.68c-0.38-1.24-1.72-1.93-2.61-2.77
      c-0.52-0.49-0.88-1.09-0.78-1.83c0.13-0.99,0.96-1.7,1.46-2.5c0.45-0.73,0.59-1.64-0.01-2.33c-0.26-0.3-0.64-0.48-0.96-0.68
      c-0.97-0.59-1.16-1.58-0.81-2.66c0.01-0.04-0.04-0.06-0.05-0.02c-0.48,1.05-0.4,2.21,0.63,2.88c0.4,0.26,0.82,0.47,1.11,0.86
      c0.52,0.7-0.01,1.6-0.42,2.2c-0.52,0.76-1.2,1.47-1.2,2.45c-0.01,0.7,0.35,1.26,0.83,1.75c0.54,0.55,1.2,0.98,1.77,1.51
      c0.78,0.74,0.96,1.48,1.03,2.53c0.06,0.92,0.22,1.74,0.96,2.35c0.78,0.64,1.97,1.06,2.88,0.46
      C748.89,43.04,748.86,42.97,748.81,42.99z"/>
    <path d="M746.05,38.15c-0.29-0.59-1.01-0.92-1.56-1.19c-0.75-0.38-1.55-0.77-1.87-1.61c-0.26-0.67,0.12-1.38,0.47-1.93
      c0.62-0.97,1.58-1.94,1.44-3.18c-0.07-0.6-0.43-1.06-0.91-1.39c-0.66-0.44-1.73-0.64-1.92-1.55c-0.01-0.03-0.05-0.02-0.05,0.01
      c0.24,1.42,2.12,1.35,2.58,2.66c0.45,1.27-0.83,2.53-1.43,3.52c-0.64,1.06-0.6,2.13,0.4,2.95c0.59,0.49,1.35,0.74,1.99,1.15
      c0.64,0.41,0.98,1.06,0.86,1.82c-0.07,0.46-0.22,0.9-0.23,1.37c0,0.03,0.05,0.03,0.05,0C746.04,39.93,746.47,38.99,746.05,38.15z"
      />
    <path d="M746.51,30.86c-0.49-0.5-0.62-1.12-0.85-1.76c-0.19-0.53-0.48-0.99-0.83-1.43c-0.02-0.02-0.06,0-0.04,0.03
      c0.33,0.45,0.59,0.91,0.77,1.43c0.2,0.56,0.28,1.11,0.64,1.61c0.38,0.52,0.91,0.9,1.27,1.45c0.51,0.79,0.21,1.64-0.1,2.43
      c-0.24,0.6-0.38,1.15-0.32,1.8c0.06,0.7,0.27,1.36,0.22,2.07c-0.08,1.09-1.19,2.23-0.72,3.34c0.01,0.03,0.07,0.03,0.07-0.01
      c0-0.81,0.22-1.44,0.54-2.17c0.24-0.55,0.38-1.12,0.33-1.72c-0.05-0.68-0.24-1.35-0.22-2.04c0.02-0.74,0.47-1.42,0.65-2.12
      C748.25,32.5,747.31,31.68,746.51,30.86z"/>
    <path d="M738.56,22.17c-1.34,0.13-2.55,0.16-3.85-0.27c-1.01-0.34-1.98-0.64-3.01-0.21c-1.48,0.62-1.34,2.43-1.23,3.74
      c0.05,0.66,0.1,1.35-0.07,2c-0.22,0.88-1,1.08-1.82,0.95c-0.03,0-0.04,0.03-0.01,0.04c1.16,0.24,1.86-0.27,2.02-1.43
      c0.17-1.3-0.29-2.61,0.11-3.9c0.43-1.35,1.83-1.6,3.05-1.29c0.54,0.14,1.06,0.36,1.6,0.51c0.52,0.15,1.07,0.18,1.61,0.18
      c1.18,0,2.35-0.2,3.53-0.2c0.97,0,2.14,0.19,2.53,1.22c0.01,0.04,0.07,0.02,0.06-0.02C742.57,21.68,740,22.03,738.56,22.17z"/>
    <path d="M741.07,24.79c-0.94,0.17-1.69,0.87-2.68,0.8c-0.93-0.06-1.77-0.75-2.53-1.24c-1.42-0.91-2.95-1.47-4.47-0.48
      c-0.02,0.02,0,0.05,0.02,0.04c2-1.08,3.67,0.24,5.36,1.26c0.57,0.34,1.28,0.71,1.97,0.63c0.86-0.09,1.58-0.61,2.36-0.95
      C741.12,24.85,741.11,24.79,741.07,24.79z"/>
    <path d="M741.35,23.7c-0.47-0.05-0.87,0.13-1.27,0.34c-0.74,0.4-1.35,0.78-2.23,0.64c-1.22-0.19-2.22-1.18-3.31-1.7
      c-0.72-0.35-1.56-0.64-2.37-0.5c-0.83,0.15-1.17,0.92-1.24,1.68c0,0.03,0.04,0.04,0.05,0.01c0.18-1.14,0.85-1.72,2-1.57
      c0.81,0.1,1.59,0.49,2.29,0.9c0.99,0.58,1.89,1.3,3.09,1.33c1.11,0.02,1.88-1.08,2.99-1.08C741.38,23.75,741.38,23.71,741.35,23.7z
      "/>
    <path d="M752.95,60.79c-1.6,0.11-3.63,0.06-5.06-0.03c-0.04,0-0.04,0.06-0.01,0.07c1.59,0.39,3.51,0.49,5.09,0.08
      C753.04,60.89,753.01,60.79,752.95,60.79z"/>
    <path d="M752.25,61.68c-1.18,0.12-2.53,0.1-3.79-0.03c-0.03,0-0.03,0.06,0,0.07c1.19,0.39,2.63,0.49,3.82,0.07
      C752.32,61.77,752.38,61.66,752.25,61.68z"/>
    <path d="M733,30.33c-0.52,0-0.94,0.44-0.94,0.98c0,0.54,0.42,0.98,0.94,0.98c0.52,0,0.94-0.44,0.94-0.98
      C733.95,30.77,733.52,30.33,733,30.33z"/>
    <path d="M736.25,32.55c-0.51-0.55-1.5-0.14-1.75,0.5c-0.01,0.03,0.03,0.06,0.05,0.03c0.44-0.47,1.03-0.58,1.64-0.43
      C736.24,32.67,736.28,32.59,736.25,32.55z"/>
    <path d="M735.11,28.13c-1.01-1.16-2.07-0.22-1.98-0.21c0.93,0.03,1.87,0.61,2.5,2.02C735.66,30,735.8,28.92,735.11,28.13z"/>
    <path d="M715.33,76.36c-0.35-0.45-0.62-0.98-1.03-1.38c-0.06-0.06-0.16,0.04-0.11,0.1c0.27,0.34,0.51,0.69,0.76,1.03
      c0.11,0.15,0.27,0.24,0.07,0.39c-0.13,0.09-0.26,0.18-0.39,0.26c-0.81,0.49-1.22-0.34-1.49-0.99c-0.03-0.06-0.14-0.06-0.13,0.02
      c0.07,0.45,0.36,1.06,0.78,1.26c0.56,0.27,1.09-0.32,1.52-0.61C715.34,76.43,715.35,76.39,715.33,76.36z"/>
    <path d="M719.07,77.03c-0.14-0.99-0.92-0.46-1.52-0.19c-0.05-0.04-0.12-0.01-0.12,0.05c0,0,0,0,0,0c-0.06,0.02-0.03,0.11,0.03,0.09
      c0,0,0.01,0,0.01,0c0.26,0.52,0.13,1.21,0.1,1.76c0,0.07,0.07,0.1,0.13,0.08c0.46-0.22,0.98-0.34,1.33-0.73
      C719.27,77.83,719.11,77.34,719.07,77.03z M718.4,78.35c-0.21,0.1-0.44,0.18-0.66,0.27c0.01-0.54,0.17-1.23-0.13-1.69
      c0.21-0.09,0.4-0.2,0.61-0.29c0.24-0.11,0.55-0.23,0.69,0.21c0.05,0.17,0.05,0.35,0.07,0.52C719.04,77.89,718.89,78.11,718.4,78.35
      z"/>
    <path d="M719.98,80.38c-0.26-0.67-1.06-0.58-1.6-0.28c0-0.01,0-0.01,0-0.02c-0.01-0.08-0.15-0.08-0.13,0.01
      c0,0.03,0.01,0.06,0.01,0.09c0,0,0,0,0,0c-0.04,0.02-0.02,0.07,0.02,0.08c0.09,0.5,0.24,1.01,0.19,1.52
      c-0.01,0.05,0.04,0.09,0.09,0.09c0.44-0.04,0.89-0.12,1.23-0.41C720.09,81.21,720.11,80.72,719.98,80.38z M718.62,81.7
      c-0.03-0.5-0.13-0.98-0.22-1.47c0.52-0.19,1.24-0.43,1.44,0.29C720.08,81.38,719.27,81.59,718.62,81.7z"/>
    <path d="M720.08,82.03c-0.04,0.02-0.04,0.07,0,0.09c0.15,0.09,0,0.55-0.05,0.66c-0.09,0.22-0.33,0.32-0.54,0.39
      c-0.2,0.06-0.41,0.13-0.63,0.11c-0.2-0.02-0.19-0.49-0.23-0.62c-0.02-0.06-0.1-0.03-0.1,0.03c0.01,0.16,0.01,0.59,0.18,0.68
      c0.28,0.15,0.65-0.02,0.93-0.09c0.32-0.08,0.57-0.37,0.62-0.7C720.27,82.46,720.36,81.93,720.08,82.03z"/>
    <path d="M719.92,83.91c-0.06-0.03-0.12,0.04-0.08,0.09c0.23,0.28,0.33,0.5,0.2,0.86c-0.1,0.27-0.32,0.48-0.56,0.62
      c-0.13-0.37-0.29-0.77-0.55-1.06c-0.03-0.04-0.11-0.01-0.09,0.05c0.2,0.37,0.41,0.72,0.53,1.13c0.01,0.03,0.05,0.06,0.09,0.04
      C720.03,85.42,720.72,84.31,719.92,83.91z"/>
    <path d="M784.49,116.67c-0.15-0.59-1.26-1.63-1.7-0.76c-0.03,0.05,0.05,0.1,0.09,0.05c0.42-0.59,1.03-0.03,1.31,0.41
      c0.06,0.1,0.1,0.19,0.12,0.3c0.01,0.04-0.43,0.22-0.45,0.23c-0.2,0.08-0.44,0.12-0.62,0.24c-0.08,0.05-0.03,0.19,0.07,0.16
      c0.4-0.12,0.84-0.27,1.17-0.55C784.49,116.74,784.49,116.7,784.49,116.67z"/>
    <path fill="#FFFFFF" d="M733.18,30.61c-0.2,0.07-0.3,0.29-0.23,0.49c0.07,0.2,0.29,0.3,0.49,0.23
      c0.2-0.07,0.3-0.29,0.23-0.49C733.6,30.64,733.38,30.54,733.18,30.61z"/>
    <path fill="#FFFFFF" d="M732.8,31.18c-0.07,0.02-0.1,0.1-0.08,0.17c0.03,0.07,0.1,0.1,0.17,0.08
      c0.07-0.03,0.1-0.1,0.08-0.17C732.94,31.19,732.87,31.16,732.8,31.18z"/>
    <path d="M689.02,181.98c0.44-1.53,0.46-3.78-0.74-4.86c-0.06-0.06-0.16,0.02-0.1,0.09c1.15,1.41,0.79,3.07,0.6,4.72
      c-0.24,0.01-0.44,0.24-0.43,0.48c0.01,0.26,0.23,0.45,0.48,0.45c0.26,0,0.46-0.23,0.45-0.48
      C689.27,182.2,689.17,182.05,689.02,181.98z"/>
    <path d="M691.16,182.8c-0.01-0.01-0.02-0.01-0.03-0.02c0.61-1.35,0.31-3.37-0.42-4.53c-0.04-0.06-0.12,0-0.1,0.06
      c0.28,0.8,0.5,1.53,0.54,2.38c0.03,0.71-0.17,1.34-0.32,2.01c-0.01,0-0.02,0-0.03,0c-0.27,0-0.47,0.31-0.44,0.56
      c0.02,0.13,0.09,0.26,0.19,0.34c0.11,0.09,0.23,0.1,0.36,0.1c0.27,0,0.47-0.31,0.44-0.56C691.34,183,691.27,182.88,691.16,182.8z"
      />
    <path d="M769.01,156.75c-0.11,0-0.22,0.03-0.31,0.09c-1.51-0.43-3.17,1.46-3.03,2.37c0.03,0.17,0.28,0.14,0.31-0.01
      c0.22-1.21,1.45-2.28,2.53-2.04c0.01,0.24,0.18,0.57,0.43,0.57c0.27,0,0.5-0.18,0.52-0.46
      C769.49,157.02,769.28,156.75,769.01,156.75z"/>
    <path d="M770.01,159.63c-0.02-0.15-0.09-0.28-0.21-0.37c-0.12-0.1-0.25-0.11-0.4-0.11c-0.11,0-0.25,0.08-0.34,0.16
      c-1.02-0.34-2.31,0.97-2.61,1.82c-0.04,0.12,0.13,0.24,0.21,0.13c0.76-1.08,1.6-1.76,2.27-1.63c-0.04,0.21,0.07,0.42,0.19,0.51
      c0.12,0.1,0.25,0.11,0.4,0.11c0.14,0,0.29-0.1,0.37-0.21C769.98,159.92,770.02,159.77,770.01,159.63z"/>
    <path d="M692.68,172.62c-0.08-0.78-0.61-2.22-1.57-2.29c-1.26-0.09-1.82,1.42-1.89,2.44c-0.05,0.68,0.1,1.4,0.31,2.04
      c0.18,0.54,0.43,1,0.63,1.48c-0.65-0.86-1.84-1.15-2.63-1.99c-0.6-0.64-0.75-1.54-0.58-2.38c0.21-1.04,0.98-1.68,1.82-2.23
      c0.11-0.07,0.03-0.23-0.09-0.18c-1.46,0.56-2.26,2.19-2,3.69c0.12,0.7,0.54,1.28,1.08,1.71c0.36,0.29,0.82,0.46,1.22,0.68
      c0.74,0.41,1.09,0.98,1.41,1.75c0.01,0.03,0.03,0.04,0.05,0.05c0,0.01,0,0.01,0,0.02c0.01,0.09,0.15,0.1,0.17,0.02
      c0.34-1.03-0.57-2-0.85-2.96c-0.18-0.63-0.25-1.29-0.2-1.94c0.06-0.86,0.96-2.46,1.96-1.59c1.02,0.9,0.86,2.54,0.29,3.59
      c-0.55,1-1.09,1.88-0.89,3.08c0.03,0.19,0.31,0.16,0.29-0.03C691.06,175.7,692.87,174.5,692.68,172.62z"/>
    <path d="M766.4,165.69c-0.13-0.86,0.05-1.79-0.05-2.67c-0.07-0.55-0.19-1.09-0.35-1.62c-0.08-0.28-0.42-1.15-0.56-1.5
      c-0.06-0.15-0.15,0.65-0.09,0.87c0,0,0,0.01-0.01,0.01c-0.26,1.68-2.16,2.14-2.78,3.61c-0.36,0.86-0.31,2.36,0.76,2.71
      c1.24,0.4,2.07-0.97,2.37-1.98c0.11-0.36,0.15-0.79,0.18-1.15c0.06-0.57,0.04-1.44,0.01-1.99c0.16,0.48,0.26,0.92,0.26,1.36
      c0.01,0.8-0.09,1.59-0.05,2.38c0.02,0.3,0.11,0.6,0.17,0.9c0.12,0.59-0.07,1.11-0.3,1.65c-0.21,0.51-0.37,0.98-0.32,1.53
      c0.01,0.07,0.11,0.08,0.12,0.01c0.15-0.8,0.59-1.46,0.77-2.23C766.68,166.92,766.5,166.35,766.4,165.69z M765.36,164.72
      c-0.11,0.54-0.38,1.07-0.71,1.51c-0.64,0.85-1.65,0.54-1.84-0.46c-0.39-2.04,2.09-2.74,2.66-4.43
      C765.66,162.46,765.56,163.72,765.36,164.72z"/>
  </g>
  <g id="personaje1">
    <path d="M657.28,219.73c-3.75-0.68-6.69-0.98-9.81-3.4c-2.44-1.88-5.5-4.69-6.25-6.4c-0.75-1.71-0.58-5.27-0.16-8.06
      c0.45-3.02,1.43-5.97,2.44-8.84c1.83-5.18,5.39-13.85,7.47-17.2c2.09-3.34,1.17-7.63,0.29-12.48c-0.61-3.37-1.19-6.64-1.56-10.05
      c-0.2-1.81-0.69-6.39-1.09-9.06c1.66-0.47,2.32-0.99,2.78-1.8c-0.44-0.82-1.13-2.14-1.57-4.06c-0.41-1.8-0.89-3.81-1.45-5.98
      c-0.16-0.64-0.36-1.28-0.58-1.91c4.59,0.28,6.41-4.67,4.87-9.25c-0.07-0.19-0.36-0.17-0.32,0.04c0.24,1.53-1.58,2.56-2.81,1.85
      c-1.23-0.71-1.66-3.39-1.65-5.24c1.24,0.15,3.3,0.06,4.18-0.35c3.73-1.76,1.47-19.91,1.38-22.04c-0.03-0.69-0.64-0.54-0.74-0.16
      c-0.26,0.94-1.89,0.69-2.35-1.13c-0.52-0.11-1.51-0.15-1.98-0.14c0.52-4,2.98-9.21,3.88-11.37c1.91-4.6,3.51-9.35,4.79-14.16
      c2.61-9.78,2.45-19.05,0.33-28.9c-1.39-6.44-3.26-13.46-9-17.42c-2.21-1.53-4.91-2.14-7.55-1.8c-0.54,0.07-1.15,0.18-1.76,0.35
      c-1.75-2.49-5.1-3.21-7.98-3.45c-4.46-0.38-8.64,1.34-12.17,3.96c-8.12,6.01-12.31,16.53-13.86,26.17
      c-1.79,11.1-0.43,22.56,1.36,33.56c0.98,6.01,2.31,11.92,4.7,17.54c1.57,3.68,3.86,7.02,5.88,10.48c0.18,3.01-0.2,8.35-0.65,12.02
      c-0.4,3.25-1.06,6.5-2.01,9.59c-0.29-0.03-0.62,0.12-0.99,0.05c-0.56-0.1-0.67-0.72-1.54-0.59c-0.83,0.13-0.88,0.69-1.5,0.59
      c-1.54-0.25-2.5,0.57-2.75,1.5c-0.29,1.08-0.34,2.3-0.28,3.39c-1.76-0.09-7.37-0.1-7.89,0.12c-0.57,3.07-0.64,27.25-0.49,29.78
      c1.98,1.35,10.14,1.74,14.37,1.73c-0.79,1.12-0.76,3.41-1,4.42c-0.46,1.99-1.64,2.58-0.83,4.59c0.33,0.83,1.12,0.97,1.04,0.79
      c-1.08-2.46,0.45-3.34,0.79-5.34c0.25-1.46,0.46-3.34,0.95-4.36c0.24,0.02,0.48,0.03,0.72,0.05c0.66,1.72,1.39,3.39-0.07,4.79
      c-1.86,1.8-2.11,3.3-0.49,5.6c0.18,0.26,0.7,0.06,0.59-0.19c-0.88-2.05-0.82-3.32,0.68-4.65c0.64-0.57,1-1.13,1.26-1.94
      c0.3-0.93-0.36-2.46-0.75-3.54c1.92,0.12,3.84,0.27,5.76,0.44c-1.65,1.82-3.16,5.07-3.83,6.54c-1.25,2.77-2.21,5.82-2.97,8.75
      c-0.81,3.11-1.9,7.21-2.93,10.15c-1.03,2.93-2.73,5.23-3.8,6.66c-2.14,2.86-2.57,5.39-2.76,6.94c-0.15,1.33-0.1,2.8,0.14,4.1
      c0.65,0.67,2.2,0.94,2.8,0.49c0-1.03,0.09-1.76,0.16-2.32c0.33,0.66,1.42,2.43,1.78,3.07c1.13,2.01,2.4,3.92,3.85,5.71
      c1.98,2.45,11.43,0.09,11.18-0.32c-1.37-2.24-4.41-3.94-6.83-4.21c-0.76-1.2-1.58-2.34-2.2-3.66c-0.48-1.02-1.4-4.09-1.63-5.52
      c-0.24-1.43-0.37-4.88,0.87-7.85c1.24-2.96,3.36-8.41,5.52-11.86c2.8-4.47,7.71-10.26,7.87-15.96c0.75,0.05,5.12,0.43,6.33-0.28
      c0-1.25,1.08-19.61,1.54-22.99c0.36,0.04,0.57,0.08,0.8,0.09c0.12,3.6,1.42,9.44,2.48,12.84c1.87,6.02,4.93,11.53,3.78,13.19
      c-4.46,6.44-6.15,11.51-6.96,16.82c-0.71,4.67-0.19,9.06-0.27,13.52c-0.04,2.33,0.15,5.1-0.9,7.09c-1.14,2.17-4.18,6.05-1.17,8.47
      c-0.03,0.6-0.02,2-0.02,2.46c0.71,0.67,2.71,0.46,3.37,0.06c-0.04-0.73,0.09-2.07,0.15-2.72c1.8,2.21,4.71,3.49,7.44,3.98
      c2.21,0.39,7.64,0.48,11.55-0.91C657.57,220.09,657.51,219.77,657.28,219.73z"/>
    <path fill="#984910" d="M649.38,124.18c1.28,0.45,2.77-0.03,3.24-1.17c0.17,1.4-0.14,2.78-0.62,3.94
      c-0.86,2.05-2.62,2.41-4.63,2.36h0c-0.62-1.6-1.37-3.18-2.18-4.71c0.27-2.56,0.67-5.77,0.95-6.92c0.54,0.07,1,0.12,1.32,0.15
      C647.18,120,647.78,123.62,649.38,124.18z"/>
    <path fill="#7F3800" d="M647.76,123.76c0.33,1.85,1.19,3.65,2.42,5.12c0.4-0.18,0.76-0.43,1.08-0.77
      C649.61,127.23,648.31,125.58,647.76,123.76z"/>
    <path fill="#7F3800" d="M647.39,119.37c-0.03-0.54-0.01-1.07,0.05-1.53c-0.31-0.04-0.78-0.08-1.32-0.15
      c-0.28,1.15-0.68,4.36-0.95,6.92c0.81,1.53,1.56,3.11,2.18,4.71h0c0.79,0.02,1.53-0.03,2.2-0.21
      C647.35,126.36,646.28,122.62,647.39,119.37z"/>
    <path fill="#F4F4EA" d="M650.11,95.23c0.85,1.74,2.27,1.31,2.69,1.14c0.6,7.69,1.08,15.94,0.13,17.99
      c0.15-3.37-0.27-4.69-0.21-3.94c0.09,1.15-0.13,5.44-0.98,5.28c0.38-3.92,0.44-6.38,0.29-10.35c-0.05-1.16-0.3-2.1-0.29-1.62
      c0.09,3.03-0.07,9.92-0.46,12.11c-0.74-0.29-0.86-1.3-0.84-3.8c0.02-2.24,0.3-7.2,0.3-10.13c0-0.52-0.33,0.3-0.39,1.85
      c-0.13,3.78-0.43,9.66-0.13,11.29C648.56,113.24,649.15,99.59,650.11,95.23z"/>
    <path fill="#DBDBC0" d="M650.53,109.16c-0.04,1.14-0.08,2.15-0.08,2.88c-0.03,2.49,0.1,3.5,0.84,3.8
      c0.19-1.05,0.32-3.17,0.4-5.43C651.25,110.02,650.87,109.6,650.53,109.16z"/>
    <path fill="#DBDBC0" d="M650.18,108.67c-0.4-0.59-0.73-1.23-1-1.89c-0.02,3.92,0.26,7.42,1.05,8.27
      C650.04,114.04,650.09,111.41,650.18,108.67z"/>
    <path fill="#DBDBC0" d="M652.35,110.94c-0.1-0.07-0.18-0.14-0.28-0.21c-0.05,1.54-0.16,3.09-0.34,4.97
      c0.71,0.14,0.98-2.85,1-4.5C652.6,111.11,652.47,111.03,652.35,110.94z"/>
    <path fill="#DBDBC0" d="M653.41,111.59c-0.17-0.09-0.34-0.19-0.5-0.29c0.05,0.69,0.09,1.7,0.03,3.05
      C653.18,113.82,653.33,112.85,653.41,111.59z"/>
    <path fill="#0079D7" d="M649.33,95.03c-1.24,6.91-1.59,19.43,0.73,21.68c-1.93,0.34-5.96-0.04-7.66-0.58
      c-1.99-3.75-2.55-11.79-2.8-14.38c1.9-0.53,3.41-1.52,3.98-3.5c0.34-1.2,0.23-1.43-0.34-3.05
      C644.31,95.08,647.75,94.89,649.33,95.03z"/>
    <path fill="#004AC1" d="M642.45,108.94c-0.12-2.72,0.64-4.96,1.46-7.52c0.66-2.08,1.03-4.24,1.48-6.37
      c-0.91,0.05-1.72,0.11-2.14,0.16c0.57,1.62,0.69,1.85,0.34,3.05c-0.57,1.98-2.08,2.97-3.98,3.5c0.24,2.59,0.81,10.62,2.8,14.38
      c0.53,0.17,1.28,0.32,2.12,0.44C643.35,114.19,642.57,111.62,642.45,108.94z"/>
    <path fill="#E60A0A" d="M633.17,99.22c-3.27-6.9-6.46-13.54-8.14-21.03c0.81-0.05,1.72-0.25,2.57-0.58
      c1.24,3.83,3.99,7.33,6.96,8.86c2.67,1.38,4.28-1.43,5.01-4.22c1.24,0.82,2.71,1.28,3.55,2.65c1.45,2.35,1.59,9.12,1.62,9.56
      c-0.49,0.07-1.24,0.06-1.73,0.16c-0.93-2.14-1.45-6.25-1.81-8.88c-0.02-0.15-0.25-0.17-0.27-0.01c-0.29,3.29,0.26,6.8,1.13,9.97
      c0.63,2.3,0.72,2.71-1.44,4.33c-1.05,0.79-2.44,1.16-3.56,1.38c-0.23,0.05-0.14,0.45,0.09,0.49c0.58,0.09,1.15,0.07,1.72-0.01
      c-0.18,3.3,1.11,12.28,3.26,15.72c-1.03,1.12-5.34,0.56-6.74-0.17C637.44,111.46,635.62,104.4,633.17,99.22z"/>
    <path fill="#C77100" d="M625.53,42.17c0.44-0.32,0.9-0.58,1.38-0.78c4.1,0.57,3.69,10.18-0.4,11
      c-0.56-0.17-1.09-0.44-1.6-0.8c1.58-0.01,3.06-1.77,3.24-4.05C628.33,45.44,627.36,42.48,625.53,42.17z"/>
    <path fill="#FFFFFF" d="M627.93,41.1c1.24-0.47,4.5,0.86,7.03,5.75c-1.63,1.07-2.27,2.7-3.56,4.12
      c-1.33,1.46-2.94,1.72-4.07,1.63c1.71-0.76,2.57-2.83,2.79-5.54C630.31,44.62,629.49,42.08,627.93,41.1z"/>
    <path fill="#FACFAA" d="M625.35,52.93c-0.51,0.43-0.63,1.41-0.37,1.98c0.04,0.09,0.2,0.02,0.17-0.07
      c-0.21-0.72,0.26-1.49,0.79-1.7c0.27,0.09,0.55,0.15,0.83,0.21c-0.77,1.54-0.06,2.42,0.6,2.73c0.1,0.05,0.19-0.1,0.09-0.16
      c-0.59-0.36-0.72-1.68-0.02-2.49c0.09,0.01,0.98,0.02,1.34-0.04c-0.11,1.42,0.87,1.76,1.46,1.53c0.12-0.05,0.07-0.22-0.06-0.19
      c-0.98,0.22-0.96-0.81-0.81-1.49c3.2-0.8,4.48-5.1,6.58-6.17c-1.16-2.75-3.63-5.74-5.96-6.57c-2.21-0.79-5.36,1.37-6.71,2.52
      c0.36-1.09,0.74-2.32,1.18-3.38c2.38-2.7,5.57-3.92,9.24-4.12c0.31-0.02,0.54-0.45,0.22-0.64c-2.48-1.46-5.63-0.47-7.87,1.46
      c5.31-8.07,8.83-11.94,14.8-8.76c1.45,2.14,5.86,7.51,6.78,9.99c-2.72-2.87-5.9-3.61-7.21-1.63c-0.06,0.09-0.01,0.32,0.1,0.25
      c1.47-0.93,5.8,0.18,8.61,5.09c0.49,1.23,0.75,2.56,0.72,3.63c-1.27-2.11-2.24-4.36-5.06-4.89c-3.4-0.64-5.6,4.04-6.7,6.57
      c1.09,1.03,2.7,4.2,4.66,5.39c0.01,0.66-0.51,0.95-0.85,0.96c-0.12,0-0.14,0.2-0.02,0.21c0.37,0.03,1.14-0.03,1.26-0.95
      c0.34,0.17,0.71,0.29,1.1,0.37c-0.03,1.07-1.14,1.52-1.66,1.4c-0.11-0.02-0.17,0.14-0.07,0.19c0.5,0.23,2.12,0.12,2.38-1.5
      c0.31,0.02,0.82,0.08,1.18-0.07c0.28,0.8-0.17,1.56-0.5,1.79c-0.11,0.08-0.01,0.23,0.11,0.17c0.66-0.31,1.36-0.91,1.12-2.18
      c0.74-0.29,1.49-0.8,2.14-1.43c-2.45,7.72-4.82,14.04-11.05,18.2c-5.29,3.53-9.86-5.2-11.95-9c-1.71-3.11-2.67-6.41-3.57-9.78
      C623.06,51.51,624.09,52.4,625.35,52.93z"/>
    <path fill="#C77100" d="M643.13,43.27c-0.38-0.51-1.15-0.97-1.83-0.9c0.22-0.5,0.74-0.93,1.25-1.18
      c1.24,0.17,2.15,1.48,2.63,3.05c0.31,1.03,0.39,2.12,0.3,3.19c-0.09,0.97-0.7,3.67-1.97,3.96c-0.59-0.27-1.14-0.68-1.66-1.19
      c1.06-0.02,1.81-1.03,2.13-2.14C644.44,46.44,644.16,44.63,643.13,43.27z"/>
    <path fill="#E18703" d="M629.64,44.83c-0.13-0.73-0.35-1.4-0.68-1.96c-0.54,0.26-1.07,0.56-1.59,0.87
      c0.24,0.45,0.42,0.97,0.55,1.5C628.5,45.11,629.07,44.96,629.64,44.83z"/>
    <path fill="#E18703" d="M628.11,46.22c0.05,0.46,0.08,0.9,0.05,1.32c-0.01,0.12-0.03,0.24-0.05,0.36
      c0.51,0.05,1.01,0.12,1.51,0.22c0.13-0.7,0.18-1.42,0.15-2.13C629.21,46.06,628.66,46.15,628.11,46.22z"/>
    <path fill="#E18703" d="M645.34,44.9c-0.05-0.22-0.09-0.44-0.16-0.65c-0.15-0.51-0.36-0.98-0.6-1.41
      c-0.39,0.28-0.79,0.57-1.19,0.84c0.28,0.45,0.48,0.94,0.62,1.45C644.45,45.09,644.9,45,645.34,44.9z"/>
    <path fill="#E18703" d="M644.13,45.76c0.08,0.59,0.07,1.2-0.04,1.8c0.46-0.02,0.92-0.01,1.38,0.01
      c0.01-0.05,0.01-0.1,0.02-0.14c0.06-0.63,0.04-1.28-0.04-1.91C645,45.62,644.57,45.72,644.13,45.76z"/>
    <path fill="#B55C00" d="M627.85,41.72c-0.28-0.16-0.59-0.28-0.94-0.33c-0.47,0.2-0.93,0.46-1.38,0.78
      c0.36,0.06,0.68,0.23,0.98,0.47C626.94,42.31,627.38,42,627.85,41.72z"/>
    <path fill="#B55C00" d="M627.78,49.15c-0.15,0.37-0.32,0.71-0.53,1.02c0.44,0.24,0.9,0.47,1.35,0.69
      c0.25-0.38,0.46-0.81,0.64-1.27C628.75,49.44,628.26,49.3,627.78,49.15z"/>
    <path fill="#B55C00" d="M626.84,50.67c-0.55,0.57-1.22,0.92-1.92,0.92c0.5,0.35,1.03,0.63,1.6,0.8
      c0.66-0.13,1.22-0.5,1.69-1.02C627.74,51.15,627.28,50.92,626.84,50.67z"/>
    <path fill="#B55C00" d="M643.75,41.79c-0.35-0.32-0.75-0.53-1.19-0.6c-0.52,0.26-1.03,0.69-1.25,1.18
      c0.42-0.05,0.88,0.12,1.26,0.38C642.97,42.45,643.36,42.12,643.75,41.79z"/>
    <path fill="#B55C00" d="M644.02,47.8c-0.02,0.09-0.03,0.17-0.06,0.26c-0.11,0.37-0.27,0.73-0.47,1.06
      c0.45,0.26,0.9,0.5,1.37,0.72c0.24-0.58,0.41-1.22,0.51-1.74C644.93,47.97,644.48,47.88,644.02,47.8z"/>
    <path fill="#B55C00" d="M643.09,49.62c-0.35,0.35-0.77,0.58-1.26,0.58c0.52,0.5,1.08,0.92,1.66,1.19
      c0.44-0.1,0.8-0.48,1.08-0.98C644.07,50.17,643.58,49.91,643.09,49.62z"/>
    <path fill="#FFFFFF" d="M645.79,47.64c0.28-2.55-0.69-5.84-2.55-6.76c2.08-0.45,3.9,0.69,5.22,3.57
      c0.39,0.85,0.99,1.86,1.51,2.7c-0.86,2.92-3.64,5.37-6,4.4C645.08,51.02,645.69,48.52,645.79,47.64z"/>
    <path fill="#FACFAA" d="M630.95,69.42c0.95,0.76,1.99,1.33,3.13,1.57c0.19,1.89,0.29,3.78,0.81,5.61
      c0.61,2.15,2.24,3.66,3.88,5.06c-0.23,1.47-1.36,3.33-1.76,3.7c-2.38,2.2-8.04-4.98-8.86-8c1.06-0.52,1.95-1.27,2.33-2.28
      C631,73.73,631.29,71.32,630.95,69.42z"/>
    <path fill="#984910" d="M623.76,58.92c0.57,1.22,1.21,2.42,1.91,3.61c1.02,1.73,2.3,3.86,3.88,5.57
      c0.54,2.06,0.67,4.42,0.49,5.85c-0.22,1.74-2.89,3.39-5.11,3.77c-0.82-3.83-1.3-7.67-1.34-11.58
      C623.55,63.75,623.75,61.33,623.76,58.92z"/>
    <path fill="#E60A0A" d="M643.53,117.75c-0.2-0.24-0.4-0.48-0.58-0.74c0.62,0.24,1.42,0.27,2.2,0.4
      c-0.07,0.12-0.69,4.8-0.66,5.92c-0.4-0.71-2.05-3.65-2.45-4.33C642.59,118.78,643.18,118.34,643.53,117.75z"/>
    <path fill="#984910" d="M613.85,101.38c-2.86-5.17-4.41-10.96-5.57-16.72c-1.97-9.86-3.19-20.03-2.78-30.09
      c0.43-10.35,3.36-20.56,10.09-28.6c3.14-3.76,7.52-6.88,12.46-7.54c3.25-0.43,8.05-0.15,10.38,2.51c-0.11,0.04-0.23,0.08-0.34,0.13
      c-6.5-4.39-14.2-1.72-18.85,4.23c-5.65,7.23-9.45,16.38-10.22,25.53c-0.01,0.12,0.19,0.13,0.2,0.02c0.78-7.87,3.87-15.55,8.1-22.2
      c3.81-5.99,9.97-10.78,16.88-8.82c-3.77-0.57-8.57,1.6-10.88,3.8c-0.11,0.1,0.06,0.25,0.17,0.15c1.93-1.79,9.79-5.23,13.38-2.09
      c-0.7,0.45-1.27,1.04-1.54,1.82c-0.08,0.23,0.28,0.39,0.39,0.17c0.46-0.9,2.29-1.81,3.69-2.08c1.41-0.27,4.1-0.09,5.42,0.24
      c3.18,0.79,5.57,3.36,7.27,6.02c3.78,5.9,4.85,13.99,5.62,20.79c0.15,1.37,0.22,2.74,0.23,4.1c-0.29-3.24-0.88-6.44-1.6-9.43
      c-1.88-7.76-5.89-15.55-12.96-19.71c-0.11-0.06-0.2,0.1-0.09,0.17c6.95,4.21,10.4,12.09,12.38,19.67c1,3.81,1.5,7.75,1.7,11.68
      c0.18,3.75-0.41,7.16-0.91,10.77c-0.46,2.08-1,4.16-1.59,6.2c-1.1,3.81-2.43,7.89-4.25,11.8c0.76-2.74,1.49-5.48,2.23-8.21
      c1.63-6.09,2.91-12.24,2.8-18.58c0-0.08-0.12-0.07-0.12,0c-0.21,6.67-1.72,13.25-3.52,19.65c-0.86,3.04-1.81,6.25-2.7,9.07
      c-0.89,2.83-1.89,5.47-2.03,8.25c-0.01,0-0.01,0-0.02,0c-0.49-2.19-0.2-4.5,0.04-6.71c0.32-2.97,0.68-5.94,1.04-8.91
      c0.72-5.93,1.82-11.81,1.84-17.81c0-0.13-0.21-0.12-0.21,0.01c-0.11,6.34-1.32,12.51-2.18,18.77c-0.62,4.5-1.82,10.77-1.2,14.7
      c-0.32,0.02-0.64,0.05-0.96,0.08c-0.95-5.56,2.41-19.58,2.66-27.63c0-0.13-0.2-0.12-0.21,0.01c-0.36,7.06-2.37,14.03-2.97,21.06
      c-0.33-1.56-0.9-3.06-1.9-4.33c-1.5-1.9-4.3-2.53-5.99-4.39c-1.92-2.11-2.24-5.06-2.41-7.8c1.64,0.12,3.73-0.73,6.08-2.78
      c-1.25,3.32-1.64,7.04-1.05,10.52c0.02,0.14,0.24,0.13,0.22-0.01c-0.32-3.84,0.18-7.3,1.41-11.1c0.26-0.28,0.84-1.05,1.09-1.35
      c-1.17,3.38-1.82,7.97-1.63,11.24c0.01,0.13,0.2,0.12,0.2-0.01c-0.13-4.34,1.52-9.95,2.28-12.27c0.72-1,1.36-2.01,1.89-2.89
      c2.35-3.95,4.64-12.39,5.26-14.39c1.14,1.14,2.34,0.71,2.94-0.19c0.2-0.31,0.16-0.72,0.11-0.64c-0.54,0.77-2.21,1.21-2.58-0.63
      c1.05,0.89,2.22,0.14,1.97-0.89c-0.03-0.14-0.31-0.21-0.31-0.03c0.03,1.17-1.43,0.87-1.94-0.16c0.12-3.86-1.49-7.08-3.22-9.86
      c-1.3-2.08-6.72-9.81-8.59-11.3c-0.09-0.07-0.24,0.03-0.17,0.14c0.32,0.48,0.64,0.96,0.96,1.43c-1.17-0.63-2.81-0.55-4.07-0.32
      c-3.23,0.58-5.65,3.18-7.54,5.67c-3,3.95-5.42,8.59-6.6,13.46c-1.62,2.31-4.25,2.03-4.15,0.47c0.01-0.12-0.17-0.12-0.2-0.02
      c-0.53,1.66,1.17,2.43,2.76,1.95c-0.68,0.59-1.44,0.96-2.34,0.61c-0.13-0.05-0.21,0.12-0.09,0.19c1.11,0.69,2.47,0.29,3.51-0.54
      c-0.31,2.15-0.37,4.34-0.1,6.52c0.02,0.15,0.22,0.13,0.23-0.01c0.05-0.87,0.12-1.74,0.23-2.61c0.25,1.89,0.76,3.7,1.44,5.46
      c-0.8,7.67-0.45,15.94,1.22,23.35c1.8,7.97,5.78,14.76,9.02,22.15c1.16,2.65,1.89,5.45,2.01,8.29c-0.43-1.22-0.99-2.4-1.39-3.32
      c-2.04-4.73-4.73-9.17-6.9-13.83c-4.04-8.67-5.13-18.07-5.87-27.51c-0.01-0.11-0.18-0.11-0.18,0c0.58,8.51,1.13,17.02,4.29,25.04
      c1.69,4.29,3.91,8.36,5.99,12.46c1.03,2.05,2.04,4.1,2.86,6.25c0.16,0.42,0.29,0.84,0.39,1.26c-1.15-2.66-3.11-4.98-4.82-7.28
      c-5.18-6.97-7.28-15.9-9.08-24.26c-0.02-0.11-0.19-0.07-0.17,0.05c1.34,6.72,2.97,13.7,5.76,19.97c2.54,5.74,9.66,11.97,8.04,17.47
      c-0.68,2.3-3.21,4.87-5.35,7.09c-1.62,1.69-3.06,3.68-2.59,6.1c-1.77-3.12-2.26-6.78-1.52-10.56c0.02-0.11-0.14-0.15-0.17-0.04
      c-0.93,4.17-0.48,10.2,2.9,13.31c-4.95-2.57-4.49-9.83-3.53-15.02c0.02-0.12-0.17-0.17-0.19-0.04c-0.89,4.25-1.54,8.53,0.6,12.52
      c0.52,0.96,1.36,2.27,2.4,3.15c-1.76-0.33-3.33-1.24-4.7-3.07c-2.16-2.85-2.16-6.56-1.84-9.95c0.52-5.56,1.75-10.83-1.91-15.65
      c-0.1-0.14-0.3,0-0.22,0.15c1.37,2.41,1.72,4.9,1.68,7.42C618.03,108.46,615.88,105.04,613.85,101.38z"/>
    <path fill="#7F3800" d="M628.51,66.91c0.1,2.92,0.33,5.84,0.52,8.76c0.54-0.53,0.92-1.12,0.99-1.72
      c0.18-1.43,0.05-3.79-0.49-5.85c-0.36-0.39-0.7-0.8-1.03-1.23C628.51,66.88,628.51,66.89,628.51,66.91z"/>
    <path fill="#7F3800" d="M625.69,65.44c-0.13-1.1-0.22-2.21-0.26-3.32c-0.6-1.05-1.17-2.11-1.68-3.2
      c0,2.41-0.21,4.83-0.18,7.21c0.04,3.92,0.52,7.76,1.34,11.58c0.79-0.13,1.63-0.43,2.4-0.83C626.13,73.2,625.46,69.3,625.69,65.44z"
      />
    <path fill="#7F3800" d="M638.61,70.06c-1.42,0.83-2.7,1.19-3.78,1.1c0.17,2.75,0.49,5.69,2.41,7.8
      c0.07,0.08,0.16,0.15,0.23,0.22C637.54,76.12,637.94,73.03,638.61,70.06z"/>
    <path fill="#7F3800" d="M643.58,65.2c-0.05,0.07-0.09,0.14-0.14,0.2c-0.73,2.23-2.27,7.48-2.28,11.75
      c0.21-0.81,0.4-1.62,0.59-2.43C642.46,71.57,643.02,68.38,643.58,65.2z"/>
    <path fill="#7F3800" d="M640.96,77.68c-0.19-3.27,0.46-7.86,1.63-11.24c-0.25,0.3-0.83,1.06-1.09,1.35
      c-1.22,3.81-1.72,7.27-1.41,11.1c0.01,0.15-0.2,0.15-0.22,0.01c-0.59-3.48-0.2-7.2,1.05-10.52c-0.52,0.45-1.03,0.84-1.53,1.18
      c-0.79,3.3-1.27,6.75-1.3,10.18c0.63,0.49,1.33,0.89,2.03,1.29c0.3-1.09,0.6-2.18,0.88-3.28
      C640.98,77.74,640.97,77.72,640.96,77.68z"/>
    <path fill="#7F3800" d="M643.6,83.86c1.14-8.77,3.36-17.44,3.96-26.27c-0.7,1.87-1.46,3.64-2.23,4.93
      c-0.26,0.44-0.55,0.9-0.87,1.38c-0.64,3.68-1.25,7.36-2.1,10.99c-0.5,2.17-1.09,4.32-1.68,6.47c0.96,0.56,1.88,1.16,2.55,2
      C643.36,83.52,643.49,83.69,643.6,83.86z"/>
    <path fill="#7F3800" d="M648.7,80.5c0.65-3.28,1.36-6.57,1.66-9.9c0.2-2.28,0.36-4.63-0.28-6.79
      c-0.29,4.92-1.14,9.78-1.73,14.67c-0.36,2.96-0.72,5.94-1.04,8.91c-0.24,2.22-0.54,4.52-0.04,6.71c0.01,0,0.01,0,0.02,0
      c0-0.03,0.01-0.05,0.01-0.07C647.01,89.5,647.82,84.95,648.7,80.5z"/>
    <path fill="#7F3800" d="M647.37,81.79c0.21-1.24,0.43-2.49,0.63-3.73c0.06-0.4,0.11-0.81,0.18-1.21
      c0.14-1,0.27-2.01,0.35-3.02c0.15-1.97,0.32-3.94-0.29-5.76c-0.54,8.18-3.48,20.93-2.58,26.17c0.29-0.03,0.59-0.05,0.88-0.08
      C646.12,90.02,646.67,85.9,647.37,81.79z"/>
    <path fill="#7F3800" d="M616.91,26.59c-2.34,3.01-4.09,6.47-5.22,10.11c-1.05,3.4-2.01,6.99-2.21,10.59
      c1.33-7.94,4.85-15.69,9.77-21.99c4.65-5.94,12.35-8.62,18.85-4.23c0.11-0.04,0.23-0.09,0.34-0.13c-1.54-1.76-4.18-2.48-6.74-2.63
      C625.9,18.87,620.64,21.78,616.91,26.59z"/>
    <path fill="#7F3800" d="M625.02,22.32c2.54-1.65,6.18-2.94,9.18-2.48c-1.47-0.42-2.9-0.53-4.29-0.39
      C628.07,19.97,626.39,20.88,625.02,22.32z"/>
    <path fill="#7F3800" d="M636.87,21.7c-0.33-0.29-0.71-0.51-1.11-0.69c-0.39,0.74-0.56,1.59-0.38,2.39
      C635.67,22.68,636.21,22.12,636.87,21.7z"/>
    <path fill="#7F3800" d="M619.17,31.73c-1.84,3.81-2.94,8-3.71,12.14c-1.07,5.71-2.16,11.63-2.43,17.48
      c1.57-5.43,2.98-10.94,3.96-16.51c0.52-2.95,0.95-5.91,1.45-8.86C618.68,34.56,618.87,33.13,619.17,31.73z"/>
    <path fill="#7F3800" d="M621.12,68.21c0.75,8.67,1.97,17.27,5.69,25.24c2.18,4.66,4.87,9.11,6.9,13.83
      c0.4,0.93,0.96,2.1,1.39,3.32c-0.12-2.84-0.84-5.64-2.01-8.29c-1.12-2.54-2.32-5.01-3.49-7.48c-2-3.03-3.5-6.41-4.67-9.84
      c-1-2.96-1.82-5.97-2.37-9.05C622.11,73.36,621.71,70.77,621.12,68.21z"/>
    <path fill="#7F3800" d="M622.39,87.8c1.54,5.66,3.63,11.2,7.09,15.86c1.71,2.3,3.67,4.63,4.82,7.28
      c-0.1-0.41-0.23-0.83-0.39-1.26c-0.82-2.15-1.82-4.2-2.86-6.25c-0.98-1.93-1.99-3.86-2.96-5.8c-1.37-1.6-2.6-3.31-3.61-5.17
      C623.65,90.96,622.98,89.4,622.39,87.8z"/>
    <path fill="#7F3800" d="M618.52,109.91c0.38,0.78,0.74,1.57,1.08,2.38c0.03-2.23-0.24-4.43-1.24-6.56
      C618.57,107.12,618.64,108.53,618.52,109.91z"/>
    <path fill="#7F3800" d="M624.18,130.24c-1.86-3.46-1.61-7.15-0.93-10.85c-0.87,2.1-1.36,4.38-1.18,6.65
      c0.19,2.42,0.86,5.19,2.82,6.8c0.54,0.25,1.11,0.44,1.69,0.55C625.55,132.52,624.7,131.2,624.18,130.24z"/>
    <path fill="#7F3800" d="M625.7,106.92c0.83,1.89,1.22,4.93,0.35,7.19c1.2-0.99,2.04-2.33,2.03-4.01
      c-0.01-2.21-0.97-3.99-2.11-5.82c-1.55-2.48-3.03-4.98-4.24-7.61c0.11,0.76,0.26,1.52,0.51,2.28
      C623.15,101.72,624.53,104.26,625.7,106.92z"/>
    <path fill="#7F3800" d="M618.42,100.46c0.92,1.54,1.93,3.13,3.22,4.41c-2.99-4.25-4.64-9.35-5.65-14.42
      c-1.03-5.18-1.69-10.45-2.26-15.7c-0.29,5.56-0.67,11.13,0.74,16.59C615.31,94.61,616.68,97.57,618.42,100.46z"/>
    <path fill="#7F3800" d="M624.1,121.37c-0.94,3.01-0.62,6.54,0.82,9.31c0.6,0.86,1.38,1.59,2.39,2.11
      C624.41,130.12,623.66,125.32,624.1,121.37z"/>
    <path fill="#7F3800" d="M612.85,42.02c-1.74,6.21-1.69,12.58-0.41,18.83c0.18-2.57,0.52-5.13,0.91-7.69
      c0.5-3.32,0.94-6.66,1.59-9.96c0.91-4.57,2.17-9.13,4.41-13.2C616.22,33.35,614.03,37.83,612.85,42.02z"/>
    <path fill="#7F3800" d="M644.52,26.79c3.04,2.93,5.31,6.83,7.1,10.55c1.71,3.58,3.2,7.37,3.97,11.29
      c0.14-4.28-1.21-8.63-3.11-12.39C650.53,32.4,648.08,29.05,644.52,26.79z"/>
    <path fill="#7F3800" d="M656.79,41.71c-0.61-2.26-1.37-4.47-2.38-6.57c-1.82-3.81-5.2-9.42-9.73-10.69
      c6.28,4.31,9.92,11.61,11.68,18.89c0.72,2.98,1.3,6.19,1.6,9.43c-0.02-1.36-0.08-2.73-0.23-4.1
      C657.47,46.44,657.19,44.08,656.79,41.71z"/>
    <path fill="#7F3800" d="M652.86,75.71c-0.73,2.73-1.47,5.47-2.23,8.21c1.83-3.91,3.15-7.99,4.25-11.8
      c0.38-1.34,0.74-2.68,1.07-4.04c0.09-2.1,0.01-4.26-0.45-6.28C655.12,66.5,654.09,71.12,652.86,75.71z"/>
    <path fill="#E60A0A" d="M617.86,110.52c0.6,1.11,1.18,2.23,1.67,3.39c-0.23,3.42-0.92,6.89-0.59,10.31
      c-0.08,2.53-0.42,5.21-1.42,7.25c-0.79-0.58-1.21-0.25-1.37-0.38c-0.17-0.12-0.52-0.42-0.88-0.5
      C616.69,127.46,618.39,114.1,617.86,110.52z"/>
    <path fill="#CC0D0A" d="M637.2,101.92c0.58,1.87,1.13,3.74,2.04,5.4c-0.3-2.21-0.44-4.2-0.37-5.42
      C638.32,101.98,637.77,102,637.2,101.92z"/>
    <path fill="#CC0D0A" d="M637.32,118.04c0.43-7.65-1.77-15.18-4.87-22.15c-2.56-5.74-6.07-11.43-6.96-17.75
      c-0.16,0.02-0.31,0.04-0.46,0.05c1.69,7.49,4.88,14.13,8.14,21.03c2.44,5.17,4.27,12.23,2.22,18.24
      C635.82,117.68,636.52,117.89,637.32,118.04z"/>
    <path fill="#CC0D0A" d="M630.92,84.95c0.99,1.39,3.19,4.15,5.24,3.27c1.21-0.51,1.92-1.79,2.33-3.27
      c-0.89,1.52-2.19,2.43-3.95,1.53c-2.58-1.33-5.01-4.17-6.41-7.42C628.63,81.17,629.69,83.24,630.92,84.95z"/>
    <path fill="#CC0D0A" d="M643.43,91.81c-0.37-1.79-0.86-3.97-2.15-5.4c0.36,2.62,0.87,6.25,1.72,8.22
      c0.24-0.04,0.54-0.07,0.84-0.09C643.75,93.63,643.62,92.71,643.43,91.81z"/>
    <path fill="#CC0D0A" d="M642.03,119c0.28,0.48,1.17,2.05,1.81,3.19c0.18-1.63,0.35-3.26,0.53-4.89
      c-0.52-0.06-1.02-0.13-1.43-0.29c0.18,0.26,0.38,0.5,0.58,0.74C643.18,118.34,642.59,118.78,642.03,119z"/>
    <path fill="#CC0D0A" d="M617.92,114.03c0.53,0.91,0.98,1.91,1.32,2.97c0.11-1.03,0.22-2.07,0.29-3.1
      c-0.49-1.16-1.06-2.28-1.67-3.39C617.98,111.3,617.99,112.54,617.92,114.03z"/>
    <path fill="#CC0D0A" d="M615.7,129.39c-0.14,0.49-0.28,0.89-0.42,1.2c0.36,0.07,0.71,0.38,0.88,0.5
      c0.16,0.13,0.58-0.21,1.37,0.38c0.63-1.28,0.99-2.81,1.2-4.39C618.02,128.15,617.03,128.98,615.7,129.39z"/>
    <path fill="#FACFAA" d="M609.81,131.22c0.96,0.46,1.37-0.5,2.09-0.5c0.67,0,1,0.42,1.41,0.46c0.42,0.04,0.94-0.22,1.38-0.09
      c0.46,0.14,0.77,0.39,1.17,0.67c0.3,0.22,0.75-0.29,1.33,0.33c0.66,0.71,0.47,2.19,0.21,3.55c-0.47,2.42-1.92,8.93-3.09,8.3
      c0.21-2.1,0.5-6.02,0.37-8.15c-0.01-0.2-0.33-0.18-0.34,0.01c-0.13,1.85-0.45,6.3-0.65,7.88c-0.21,1.59-1.17,2.42-1.42,1.13
      c-0.28-1.42-0.12-6.8,0.22-9.11c0.03-0.17-0.42-0.07-0.51,0.35c-0.55,2.76-0.5,7.05-0.22,8.91c-0.7,0.03-0.99-1.44-1.2-3.06
      c-0.21-1.63-0.37-3.97-0.37-5.76c0-0.46-0.5,0-0.54,0.63c-0.11,1.66,0.16,4.06,0.54,6.05c-1,0-2.46-6.42-2.13-9.89
      C608.14,132.12,609.12,130.88,609.81,131.22z"/>
    <path fill="#FF7900" d="M613.52,159.43c0.63-1,1.75-0.89,2.21-0.04c0.46,0.83,0.42,2.42-0.83,2.71
      C613.65,162.39,612.95,160.35,613.52,159.43z"/>
    <path fill="#E66600" d="M614.48,161.18c-0.53-0.32-0.9-0.79-1.11-1.31c-0.2,1,0.46,2.48,1.54,2.23
      c0.41-0.09,0.69-0.34,0.86-0.64C615.33,161.51,614.89,161.44,614.48,161.18z"/>
    <path fill="#D30507" d="M619.33,186.07c-1.73,3.75-3.95,8.57-4.82,11.11c-0.57,1.65-0.95,4.68-0.71,6.58
      c0.24,1.9,2.3,8.56,5.07,11.51c0.31,0.33,0.49-0.8,0.43-0.91c1.71,0.65,3.22,1.55,4.39,2.86c-2.32,0.63-7.42,1.15-8.41,0.03
      c-2.45-2.75-4.85-6.75-6.54-10c-0.47,0.32-0.92,3.14-0.85,3.62c-0.45,0.11-1.17-0.05-1.46-0.36c-0.25-1.06-0.22-3.03,0.11-4.61
      c0.54-2.55,1.59-4.26,4.18-7.61c4.89-6.36,4.71-19.69,11.32-28.51c0.11,0.99,1.51,4.81,4.4,4.74
      C624.39,178.54,621.07,182.3,619.33,186.07z"/>
    <path fill="#5F5F5F" d="M626.64,174.09c-1.82-0.1-3.78-2.73-4.03-5.19c0.13-0.27,0.5-0.67,0.77-0.93
      c0.32,0.03,3.51,0.33,4.77,0.42C628.12,170.37,627.52,172.26,626.64,174.09z"/>
    <path fill="#F2CC6E" d="M635.41,148.37c-0.57,6.09-0.88,16.36-0.97,18.93c-1.16,0.46-8.73-0.35-9.94-0.47
      c-2.92-0.3-5.84-0.54-8.76-0.71c-0.27-0.9-0.43-1.8-0.39-2.88c0.43-0.16,0.79-0.53,1.01-0.91c0.63-1.09,0.66-2.79-0.17-3.78
      c-1.02-1.23-3-0.58-3.51,0.8c-0.65,1.76,0.14,3.98,1.93,3.96c-0.45,0.89-0.37,1.82-0.1,2.76c-2.86-0.14-10.45-0.03-14.65-1.22
      c-0.42-2.56-0.18-25.46,0.29-28.4c1.34-0.13,5.34-0.13,7.2-0.09c0.16,1.3,1.18,6.85,2.89,7.23c0.5,1.88,1.17,1.96,1.61,1.94
      c0.17,0.66,1.59,1.19,2.35-0.77c1.66,0.46,2.79-3.92,3.75-7.51c3.13,0.21,12.59,1,18.13,1.63
      C636.31,141.92,635.69,145.32,635.41,148.37z"/>
    <path fill="#D9A845" d="M616.88,164.12c0.14-1.31,0.37-2.67-0.08-3.84c0.04,0.72-0.12,1.46-0.45,2.04
      c-0.22,0.38-0.57,0.75-1.01,0.91c-0.04,1.08,0.12,1.98,0.39,2.88c0.37,0.02,0.74,0.05,1.11,0.08
      C616.78,165.52,616.81,164.83,616.88,164.12z"/>
    <path fill="#D9A845" d="M620.14,137.39c-0.89-0.07-1.64-0.13-2.2-0.16c-0.96,3.59-2.09,7.97-3.75,7.51
      c-0.1,0.26-0.21,0.47-0.33,0.65C616.81,146.18,618.78,140.68,620.14,137.39z"/>
    <path fill="#FFECAF" d="M599.91,139.98c-0.08,2.12-0.16,4.89-0.2,7.86c11.45,6.58,23.01,12.94,34.74,18.99
      c0.06-1.56,0.16-4.65,0.31-8.08C629.21,155.64,602.21,141.63,599.91,139.98z"/>
    <path fill="#FACFAA" d="M618.57,133.64c0.66,0.69,2.13,1.71,4.29,1.46c0.82-0.1,0.04,1.38-0.46,1.59
      c-1.62-0.03-2.96-0.25-4.13-0.29C618.47,135.72,618.61,134.31,618.57,133.64z"/>
    <path fill="#E8B591" d="M648.23,42.29c0.6,0.8,1.08,1.73,1.62,2.62c0.04-1.06-0.22-2.4-0.72-3.63
      c-0.65-1.14-1.38-2.06-2.14-2.82C647.46,39.73,647.88,41,648.23,42.29z"/>
    <path fill="#E8B591" d="M636.36,27.89c4.46-2.1,7.46,3.59,9.02,6.83c0.23,0.48,0.45,0.97,0.67,1.46
      c0.53,0.39,1.07,0.86,1.59,1.4c-0.92-2.48-5.34-7.86-6.78-9.99c-5.97-3.18-9.49,0.68-14.8,8.76c1.01-0.87,2.2-1.54,3.43-1.89
      C631.21,31.71,633.41,29.28,636.36,27.89z"/>
    <path fill="#E8B591" d="M639.75,55.14c-0.46,0.6-1.35,0.84-2.04,0.57c0.23,0.71,0.83,1.29,1.66,1.02
      c0.53-0.18,0.53-0.8,0.45-1.26C639.8,55.36,639.77,55.25,639.75,55.14z"/>
    <path fill="#E8B591" d="M646.35,58.24c-2.03,2.13-3.58,4.77-4.59,7.55C643.72,63.61,645.14,61.07,646.35,58.24z"/>
    <path fill="#E8B591" d="M626.81,39.5c0.39-0.86,0.8-1.73,1.26-2.59c-1.34,0.65-2.56,1.54-3.61,2.74
      c-0.43,1.06-0.82,2.29-1.18,3.38c0.64-0.54,1.68-1.31,2.84-1.89C626.34,40.59,626.56,40.04,626.81,39.5z"/>
    <path fill="#E8B591" d="M623.32,51.54c-0.36-0.36-0.68-0.75-0.94-1.19c0.23,0.85,0.46,1.7,0.7,2.54
      C623.15,52.45,623.23,52,623.32,51.54z"/>
    <path fill="#E8B591" d="M631.07,72.01c0.91,0.96,2.09,1.56,3.27,1.57c-0.1-0.86-0.17-1.73-0.25-2.59
      c-1.14-0.24-2.18-0.81-3.13-1.57C631.1,70.23,631.12,71.14,631.07,72.01z"/>
    <path fill="#E8B591" d="M634.06,84.03c-1.16-0.4-2.21-1.13-3.17-1.97c2.04,2.53,4.68,4.64,6.12,3.31
      c0.19-0.18,0.56-0.72,0.92-1.39C636.74,84.45,635.47,84.51,634.06,84.03z"/>
    <path fill="#E8B591" d="M608.86,140.13c0.41,1.56,0.91,2.69,1.33,2.69c-0.37-1.88-0.62-4.12-0.55-5.77
      C609.13,137.84,608.92,139.04,608.86,140.13z"/>
    <path fill="#E8B591" d="M610.4,140.41c0.05,0.53,0.1,1.04,0.16,1.5c0.21,1.63,0.5,3.09,1.2,3.06
      c-0.27-1.76-0.33-5.69,0.13-8.45C611.02,137.63,610.56,138.97,610.4,140.41z"/>
    <path fill="#E8B591" d="M613.12,145.22c0.25-0.29,0.48-0.84,0.57-1.52c0.17-1.32,0.42-4.61,0.57-6.74
      C613.35,139.63,612.96,142.43,613.12,145.22z"/>
    <path fill="#E8B591" d="M619.78,136.51c0.06-0.65,0.1-1.29,0.06-1.94c-0.56-0.29-1-0.64-1.28-0.93
      c0.05,0.67-0.1,2.09-0.29,2.75C618.74,136.41,619.25,136.46,619.78,136.51z"/>
    <path fill="#939393" d="M637.13,143.52c0.1-1.58,0.19-5.35-0.09-5.41c-1.63-0.38-11.47-1.02-13.88-1.21
      c0.71-0.54,1.29-2.5,0.29-2.34c-2.06,0.34-3.96-0.62-4.92-1.63c-0.04-0.17-0.31-0.65-0.45-0.84c0.95-1.32,1.36-3.06,1.47-4.83
      c1.51,4.34,6.4,9.97,11.86,6.52c0.29-0.18,0.09-0.59-0.23-0.55c-2.9,0.41-4.7-2.47-4.02-5.08c0.47-1.79,2.22-3.34,3.53-4.56
      c1.47-1.37,3.01-3.33,3.77-4.92c1.71,0.86,3.87,1.4,6.93,0.76c2.31,4.3,4.71,9.12,6.08,13.55c0.87,2.81,1.49,6.72,2.7,9.19
      C649.03,143.54,639.74,144.56,637.13,143.52z"/>
    <path fill="#7D7D7D" d="M631.18,133.23c0.31-0.04,0.51,0.37,0.23,0.55c-0.93,0.59-1.84,0.91-2.72,1.02
      c1.8,0.35,3.63-0.01,5.42-0.96c-2.82-1.89-4.62-4.39-3.22-7.87c1.03-2.55,2.85-4.66,4.28-6.98c-0.24-0.1-0.48-0.21-0.7-0.32
      c-0.76,1.59-2.3,3.55-3.77,4.92c-1.31,1.22-3.06,2.77-3.53,4.56C626.48,130.76,628.28,133.64,631.18,133.23z"/>
    <path fill="#7D7D7D" d="M639.07,143.89c-0.06-1.5-0.13-2.99-0.21-4.49c-0.55,0.07-1.11,0.17-1.65,0.3
      c0.02,1.24-0.03,2.9-0.09,3.81C637.59,143.7,638.27,143.82,639.07,143.89z"/>
    <path fill="#7D7D7D" d="M620.19,128.73c-0.25-0.5-0.47-0.99-0.64-1.47c-0.11,1.76-0.52,3.51-1.47,4.83
      c0.13,0.19,0.4,0.67,0.45,0.84c0.49,0.51,1.23,1.01,2.11,1.33C620.29,132.43,620.12,130.58,620.19,128.73z"/>
    <path fill="#5F5F5F" d="M645.18,170.03c0.08-2-1.79-6.09-2.79-9.15c-1.44-4.42-2.88-10.5-3.69-15.49
      c2.92,0.44,5.84,0.12,8.73-0.44c0.7,4.22,1.62,11.45,2.38,15.67c0.44,2.45,1.01,6.14,1.29,8.62c0.35,3.14-0.21,5.05-1.42,7.02
      c-2.87,0.87-5.66,0.41-6.51-1.38C643.82,173.72,645.09,172.03,645.18,170.03z"/>
    <path fill="#4B4B4B" d="M623.87,172.15c0.77,1.1,1.79,1.89,2.77,1.95c0.14-0.3,0.28-0.6,0.4-0.9
      C625.97,172.88,624.91,172.55,623.87,172.15z"/>
    <path fill="#4B4B4B" d="M627.85,170.76c0.18-0.78,0.29-1.57,0.31-2.36c-1.27-0.09-4.45-0.39-4.77-0.42
      c-0.27,0.26-0.64,0.66-0.77,0.93c0.02,0.16,0.04,0.33,0.08,0.5C624.41,169.52,626.24,170,627.85,170.76z"/>
    <path fill="#4B4B4B" d="M647.94,148.28c-0.18-1.22-0.35-2.36-0.52-3.33c-2.89,0.56-5.81,0.87-8.73,0.44
      c0.13,0.83,0.29,1.69,0.45,2.56C642.02,147.15,645.09,147.34,647.94,148.28z"/>
    <path fill="#4B4B4B" d="M643.79,173.83c-0.23,0.38-0.45,0.73-0.63,1.05c0.85,1.79,3.64,2.25,6.51,1.38
      c0.24-0.38,0.44-0.76,0.62-1.15C648.01,175.64,645.55,175.29,643.79,173.83z"/>
    <path fill="#D30507" d="M637.81,215.73c-0.13-0.13-0.39,0.06-0.3,0.23c0.09,0.17,0.19,0.33,0.3,0.49
      c-0.28,0.95-0.32,1.82-0.33,2.68c-0.54,0.18-1.29,0.14-1.89-0.09c-0.09-0.51,0.11-1.48-0.17-2.36c-0.99-0.79-1.3-2.25-0.75-3.55
      c0.56-1.32,1.53-2.87,2.17-4.42c1.12-2.75,0.59-12,0.9-17.74c0.18-3.4,1.11-7.05,2.28-10.24c0.66-1.8,1.68-3.4,2.65-5.02
      c0.48,0.98,1.73,1.63,2.78,1.84c0.89,0.18,2.97,0.02,3.68-0.12c-1.55,3.03-5.58,12.84-6.41,15.33c-1.02,3.04-2.53,8.12-2.92,12.29
      c-0.22,2.3,0.15,4.4,0.81,5.69c1.61,3.11,7.87,8.42,13.17,9.32C647.73,221.06,642.45,220.11,637.81,215.73z"/>
    <path fill="#B80007" d="M615.81,217.59c1.63,0.63,5.66,0.2,7.73-0.34C621.06,216.39,618.25,216.49,615.81,217.59z"/>
    <path fill="#B80007" d="M613.77,193.48c0.4-1.38,0.64-2.79,0.79-4.21c-1,3.55-2.1,6.73-3.85,9.01
      c-1.87,2.43-2.93,4-3.58,5.64C609.6,200.67,612.62,197.42,613.77,193.48z"/>
    <path fill="#B80007" d="M609.49,208.66c1.28,2.32,2.83,4.85,4.47,6.98C612.97,213.03,611.58,210.65,609.49,208.66z"/>
    <path fill="#B80007" d="M624.41,173.85c-1.53-1.13-2.3-3.35-2.38-4.07c-2.26,3.02-3.73,6.56-4.87,10.18
      C619.19,177.48,621.61,175.33,624.41,173.85z"/>
    <path fill="#B80007" d="M637.15,207.59c0.86-1.61,1.4-3.39,1.35-5.5c-0.07-3.25-0.41-6.49-0.46-9.74
      c-0.02-1.24-0.03-2.49-0.06-3.73c-0.11,0.79-0.2,1.59-0.24,2.37C637.47,196.09,637.86,203.98,637.15,207.59z"/>
    <path fill="#B80007" d="M645.47,219.24c-2-0.71-3.72-1.65-5.51-2.75c-0.73-0.45-1.48-0.81-2.05-0.67
      c3.55,3.31,7.48,4.62,11.84,4.59C648.29,220.12,646.86,219.74,645.47,219.24z"/>
    <path fill="#B80007" d="M637,217.21c-0.1,0.67-0.12,1.34-0.12,2.02c0.22-0.01,0.43-0.04,0.61-0.1
      c0.01-0.86,0.05-1.74,0.33-2.68c-0.09-0.15-0.18-0.3-0.27-0.45C637.28,216.21,637.09,216.6,637,217.21z"/>
    <path d="M623.07,143.12c-0.01-0.26-0.39-0.29-0.4-0.02c-0.33,6.27-1.29,12.95-0.37,19.2c0.03,0.19,0.31,0.18,0.34-0.01
      C623.35,156.02,623.36,149.42,623.07,143.12z"/>
    <path d="M626.49,142.44c-0.01-0.22-0.33-0.27-0.35-0.03c-0.53,6.99-1.86,13.3-1.03,20.3c0.03,0.25,0.37,0.18,0.4-0.03
      C626.41,155.73,626.69,149.45,626.49,142.44z"/>
    <path d="M629.2,143.72c-0.46,3.57-0.63,5.29-0.79,8.89c-0.15,3.16-0.46,6.6,0.16,9.72c0.05,0.23,0.45,0.23,0.46,0
      c0.04-2.77,0.46-14.68,0.59-18.59C629.62,143.47,629.23,143.46,629.2,143.72z"/>
    <path d="M639.93,53.01c-0.25-0.32-0.54-0.6-0.81-0.9c-0.58-0.63-0.77-1.32-0.95-2.12c-0.03-0.12-0.25-0.11-0.25,0.02
      c-0.05,1.02,0.01,2.03,0.64,2.88c0.35,0.47,0.73,0.79,0.74,1.44c0.02,1.07-1.01,0.8-1.64,0.74c-0.15-0.02-0.3,0.18-0.22,0.3
      c0.76,1.29,2.3,0.87,2.75-0.27C640.43,54.4,640.38,53.62,639.93,53.01z"/>
    <path d="M638.22,61.34c0.15-0.4,0.16-0.98-0.2-1.51c-0.63-0.92-1.54,0.2-1.78,0.71c-0.43-0.57-1.12-1.17-1.75-0.52
      c-0.5,0.51-0.79,1.39-0.79,2.17c-0.67,0-1.33-0.16-1.6-1.03c-0.19-0.57-0.05-1.36,0.38-1.67c0.07-0.05-0.07-0.14-0.14-0.1
      c-1.27,0.82-0.95,2.33,0.03,3.13c0.4,0.32,0.97,0.49,1.51,0.51c0.24,0.98,1.01,1.98,2.14,1.93
      C637.6,64.88,638.39,62.59,638.22,61.34z"/>
    <path fill="#CC2957" d="M634.86,60.38c0.51-0.47,1.11,0.38,1.23,0.76c0.05,0.16,0.25,0.15,0.33,0
      c0.21-0.4,0.44-0.91,0.86-1.12c0.43-0.22,0.8,0.88,0.52,1.25c-0.12,0.16-2.23,0.95-3.57,1C634.18,61.57,634.48,60.73,634.86,60.38z
      "/>
    <path fill="#EB4F82" d="M636.95,63.94c-1.21,1.04-2.28-0.09-2.51-0.94c1.13-0.02,2.96-0.73,3.43-1.21
      C637.91,62.38,637.51,63.46,636.95,63.94z"/>
    <path fill="#FFFFFF" d="M625.11,45.74c0,0.56-0.45,1.01-1.01,1.01c-0.55,0-1-0.45-1-1.01c0-0.55,0.45-1.01,1-1.01
      C624.66,44.73,625.11,45.18,625.11,45.74z"/>
    <path fill="#FFFFFF" d="M625.72,44.09c0,0.25-0.2,0.46-0.46,0.46c-0.25,0-0.46-0.2-0.46-0.46c0-0.25,0.2-0.46,0.46-0.46
      C625.52,43.64,625.72,43.84,625.72,44.09z"/>
    <path fill="#FFFFFF" d="M641.55,45.28c0,0.51-0.41,0.92-0.91,0.92c-0.5,0-0.91-0.41-0.91-0.92c0-0.5,0.41-0.91,0.91-0.91
      C641.14,44.37,641.55,44.78,641.55,45.28z"/>
    <path fill="#FFFFFF" d="M642.1,43.73c0,0.25-0.2,0.46-0.46,0.46c-0.25,0-0.46-0.21-0.46-0.46c0-0.25,0.2-0.46,0.46-0.46
      C641.9,43.27,642.1,43.48,642.1,43.73z"/>
    <path fill="#FFE5D4" d="M611.27,131.63c0.31-0.43,1.09-0.5,1.37-0.03C612.93,132.06,610.77,132.31,611.27,131.63z"/>
    <path fill="#FFE5D4" d="M613.89,131.75c0.4-0.5,0.84-0.56,1.15-0.06C615.36,132.19,613.64,132.06,613.89,131.75z"/>
    <path fill="#FFE5D4" d="M637.8,53.75c0.53-0.5,1.2,0.16,1.13,0.54C638.77,55.1,636.84,54.66,637.8,53.75z"/>
    <path fill="#FFE5D4" d="M644.02,55.85c0.99-0.6,2.31-0.05,1.82,0.91C645.36,57.72,642.52,56.76,644.02,55.85z"/>
    <path fill="#A75E32" d="M616.9,53.89c-0.91,2.28-2.62,10.89-2.1,17.58c0.53,6.69,3.41,21.26,4.59,23.75
      c1.18,2.5-2.23-10.76-2.49-21.78C616.64,62.42,617.95,51.27,616.9,53.89z"/>
    <path fill="#A75E32" d="M654.12,51.01c0-2.93,0.79,10.76-0.26,15.75c-1.05,4.99-2.36,9.71-2.88,11.94
      c-0.53,2.23,1.31-6.69,1.96-13.38C653.59,58.62,654.12,52.58,654.12,51.01z"/>
    <path fill="#A75E32" d="M617.68,59.14c0,2.76-0.52,14.96,0.13,19.94c0.65,4.99,1.18-5.51,1.05-9.31
      C618.73,65.97,617.68,55.6,617.68,59.14z"/>
    <path fill="#A75E32" d="M621.62,34.87c1.04-2.44,2.62-6.17,5.76-8.92c3.15-2.76,5.24-2.49,5.9-2.36
      c0.65,0.13-3.41-2.36-7.73,2.49C621.23,30.93,620.83,36.7,621.62,34.87z"/>
    <path fill="#A75E32" d="M621.36,31.98c0.75-2.07,2.88-6.22,6.29-8.4c1.44-0.92-2.22-1.31-4.59,2.49
      C620.7,29.88,620.83,33.42,621.36,31.98z"/>
    <path fill="#A75E32" d="M627.52,103.49c-1.21-2.12,6.16,7.87,5.64,11.54c-0.52,3.67-2.36,6.17-4.19,7.61
      c-1.83,1.44,2.62-3.8,2.36-8C631.05,110.45,628.56,105.33,627.52,103.49z"/>
    <path fill="#FA5F57" d="M640.05,88.89c0.22,2.81,0.98,5.74,1.26,7c0.28,1.26-1.82,0.7-2.24-1.82
      C638.65,91.55,639.91,87.07,640.05,88.89z"/>
    <path fill="#FA5F57" d="M642.01,84.41c0.96,0.75,1.26,2.24,1.4,3.64C643.55,89.45,640.75,83.42,642.01,84.41z"/>
    <path fill="#64A0E2" d="M647.18,95.61c0.55-0.07,1.68-0.28,1.68,0.28c0,0.56-0.56,3.36-0.56,4.48
      C648.3,101.5,646.07,95.75,647.18,95.61z"/>
    <path fill="#FA5F57" d="M639.35,110.88c0.39,1.69,1.26,4.48,1.68,5.46c0.42,0.98-1.82-0.42-2.24-2.24
      C638.37,112.28,638.93,109.06,639.35,110.88z"/>
    <path fill="#AEAEAE" d="M643.69,125.73c1.14,2.38,3.08,7,3.64,9.8c0.56,2.8-2.38-2.94-3.08-4.48
      C643.55,129.51,641.87,121.95,643.69,125.73z"/>
    <path fill="#7C7C7C" d="M648.03,170.56c1.08-0.54,2.66-0.7,2.8-0.14c0.14,0.56-0.42,2.38-0.98,2.52
      C649.28,173.08,646.63,171.25,648.03,170.56z"/>
    <path fill="#7C7C7C" d="M647.6,151.37c0.48,2.3,1.68,8.82,1.96,10.64c0.28,1.82-2.38-2.66-2.94-5.6
      C646.07,153.47,646.91,148,647.6,151.37z"/>
    <path fill="#E63F3E" d="M616.26,191.43c0.95-1.91,2.94-6.72,4.34-8.83c1.4-2.1,2.38-3.5,3.22-4.48
      c0.84-0.98-2.8,0.7-4.76,4.63C617.1,186.66,615.29,193.39,616.26,191.43z"/>
    <path fill="#E63F3E" d="M640.75,196.19c0.77-2.5,3.64-9.8,4.89-12.61c1.26-2.8-1.96,0.56-3.22,3.64
      C641.17,190.31,639.63,199.83,640.75,196.19z"/>
    <path fill="#E63F3E" d="M641.73,213.42c1.41,1.41,4.06,3.64,5.74,4.34c1.68,0.7-1.68,0.42-3.78-0.84
      C641.59,215.66,639.77,211.46,641.73,213.42z"/>
    <path fill="#E63F3E" d="M614.03,206.98c0.96,2.48,2.52,6.02,3.22,7c0.7,0.98-1.96-0.28-3.22-2.94
      C612.77,208.38,613.05,204.45,614.03,206.98z"/>
    <path fill="#64A0E2" d="M647.78,104.56c0,1.72-0.17,7.04,0.26,8.59c0.43,1.55-1.37-1.72-1.46-4.03
      C646.5,106.79,647.78,102.93,647.78,104.56z"/>
  </g>
  <g id="item_19_">
    <path d="M373.44,84.74c-0.41-0.85-1.3-1.59-2.11-1.9c-1.3-0.5-2.51-0.33-2.5,0.11c1.25-0.1,1.72,1.11,1.1,2
      c-0.29,0.42-0.84,0.62-1.31,0.75c-1.3,0.35-2.35-0.35-3.01-1.37c-0.4-0.62-0.6-1.38-0.75-2.71c-0.15-1.33,0.02-2.82,0.18-4.48
      c0.25-2.55,0.35-5.04-1.39-7.23c-1.31-1.64-3.75-1.26-5.28-2.47c-1.18-0.93-0.79-2.62-0.43-3.87c0.33-1.15,0.6-2.24,0.56-3.46
      c-0.05-1.51-0.78-2.67-1.83-3.58c-1.13-0.98-2.41-1.68-2.96-3.23c-0.86-2.4,0.19-5.19,0.98-7.43c0.44-1.25,0.84-2,1.34-3.82
      c0.5-1.82,0.6-4.43-0.74-5.63c0.05-0.65,0.05-1.31,0.01-1.98c-0.24-3.31-2.47-6.05-5.58-6.13c-1.67-0.04-3.37,0.39-5.01,0.68
      c-0.65,0.12-1.31,0.22-1.97,0.25c-1.4,0.05-0.86-1.89-0.59-2.7c1.05-3.16-1.72-6.13-4.65-6.05c-1.77,0.05-3.05,0.7-4.76-0.32
      c-1.1-0.65-1.51-2.1-2.57-2.83c-1.16-0.8-2.72-0.56-3.99-0.3c-0.98,0.21-2.57,0.78-3.4,1.22c-0.13,0.07-0.25,0.14-0.37,0.21
      c-1.11-0.87-2.38-1.15-3.8-1.23c-1.42-0.08-3.33,0.35-3.83,1.58c-1.09-0.06-2.48,0.14-3.34,0.49c-1.17,0.47-2.15,1.23-3.19,1.93
      c-1.17,0.79-3.11,1.39-4.48,1.59c-3.93,0.55-7.84,2.33-7.26,6.54c0.34,2.43,1.2,2.7,1.44,4.11c0.18,1.05-1.03,1.73-1.71,2.22
      c-1.62,1.19-2.72,2.34-3.37,4.29c-0.3,0.89-0.39,2.41,0.61,2.95c0.55,0.3,1.24,0.4,1.86,0.26c1.28-0.29,1.61,0.06,1.79,1.13
      c0.25,1.47,0.01,2.3-0.32,3.11c-0.31,0.76-1.23,1.89-1.42,2.13c-2.67,3.42-6.32,4.86-8.46,8.05c-1.12,1.66-1.42,3.68-0.88,5.6
      c0.66,2.31,2.3,4.18,2.13,6.71c-0.13,1.96-1.53,3.6-2.48,5.23c-1.07,1.82-1.67,3.76-1.3,5.88c0.38,2.16,1.85,4.02,3.48,5.4
      c0.98,0.84,2.18,1.71,3.09,2.64c0.44,0.45,1.08,1.16,0.88,1.77c-0.12,0.36-0.55,1.09-1.77,1.15c-0.91,0.05-1.84-0.47-2.16-0.84
      c-0.32-0.37-0.32-0.85,0.29-0.84c-0.42-0.84-1.69-0.91-2.26,0.12c-0.66,1.19-0.46,2.65,0.31,4.05c0.82,1.5,2.17,2.55,3.78,3.15
      c1.12,0.42,2.92,0.77,4.18,0.52c-3.03,3.2-8.49,8.46-10.31,10c-2.38,2.01-2.79,2.95-2.77,4c0.02,1.05,0.3,1.67,0.8,2.95
      c0.46,1.19,1.14,2.21,1.89,3.29c1.45,2.07,2.94,4.23,4.13,6.5c2.01,3.85,3.44,7.93,4.96,11.97c0.03,0.08,3.29-1.58,3.5-1.69
      c-1.4,1.78-3.48,5.66-3.8,8.3c-1.46,0.93-3.33,2.61-4.13,3.58c-0.9,1.1-1.75,2.67-1.1,3.58c0.3,0.43,1.15,0.09,2.52-1.97
      c-0.69,1.16-1.39,2.65-1.53,3.97c-0.18,1.67,0.67,2.25,1.55,1.16c0.57-0.71,1.37-2.49,2.09-3.73c-0.92,2.05-1.69,3.82-1.12,5.14
      c0.42,0.98,1.53,0.1,2.16-1.07c1.78,0.19,4.29-4.99,4.97-6.29c0.31,2.15,2.32,1.46,2.28,1.15c-0.18-1.33-0.46-3.06-0.34-4.42
      c0.15-0.41,0.45-1.54,0.52-2.13c1.76,0.18,4.17,0.44,6.99,0.78c0.52,0.06,1.06,0.13,1.6,0.19c3.7,12.41,9.3,26.14,11.38,38.95
      c0.67,4.16,0.94,7.93,1.25,12.13c-2.03,4.65-2.23,18.45-2.3,21.88c-0.09,4.08-0.21,14.82-0.33,17.4c-0.12,2.57,0.55,5.22,1.21,8.06
      c0.71,3.07,1.64,8.59,2.89,10.52c1.26,1.93,4.95,2.98,7.66,2.96c0.17,0.56,0.68,2.06,1.21,2.49c1.05,0.84,6.88,2.84,9.72,3.15
      c1.6,0.18,3.46,0.23,4.49-1.22c0.5-0.71,0.16-1.74-0.18-2.37c-1.3-2.39-3.56-5.34-4.48-6.5c-1.52-6.46-3-11.57-3.63-13.99
      c-0.64-2.43-2.73-10.37-3.67-15.21c-0.6-3.07-1.05-6.17-1.3-9.28c-0.26-3.25-0.17-6.5-0.41-9.75c-0.12-1.56-0.28-4.1-0.45-5.65
      c0.81-3.32,1.02-7.94,1.05-9.89c0.03-1.95,0.59-6.02,1.2-9.42c0.65-3.6,1.33-7.16,1.73-10.8c0.75-6.97,1.64-15.36,2.87-22.29
      c0.49-0.05,0.98-0.1,1.46-0.16c-0.47,1.05-0.68,6.93-0.48,8.37c0.7,0.14,2.72,0.37,3.65,0.29c-1.44,2.06-1.31,5.55-0.28,7.51
      c-0.47,0.66-0.59,2.11-0.21,2.9c-0.66,1.03-1.26,2.96-1.39,3.84c-0.08,0.55-0.28,2.02,0.54,2.12c-0.09,1.08,0.08,2.02,0.76,2.39
      c0.04,1.15,0.57,3.06,1.93,2.44c0.16,0.47,1.67,1.79,1.92,0.32c0.31-1.81,0.82-5.48,1.51-7.59c0.5-1.51,2-4.27,2.08-8.26
      c0.03-1.56-0.24-4.45-0.61-5.98c0.22-0.03,0.42-0.06,0.59-0.09c-0.32-1.25-1.57-6.88-1.95-9.95c0.38-0.12,1.16-0.36,1.31-0.64
      c-0.56-0.95-1.14-2.07-1.63-3.06c-1.24-9.34-2.06-20.78-2.57-30.14c-0.28-5.2-0.13-10.86-0.83-15.87c1.89,2.42,4.67,3.78,7.79,3.48
      c1.7-0.16,3.37-1.05,4.59-2.23c0.96-0.93,1.83-1.4,3.18-1.26c1.16,0.12,2.28,0.55,3.42,0.8c2.02,0.45,4.09,0.51,6.07-0.11
      c1.64-0.52,3.43-1.74,4.01-3.43C374.51,88.41,374.26,86.46,373.44,84.74z M298.41,126.55c-0.44-2.69-2.43-5.94-3.8-7.88
      c-1.47-2.07-3.4-3.85-5.44-5.38c0.31,0,0.81-0.17,1.62-0.76c1.39-1.03,10.89-10.52,13.27-16.13c-1.12,5.56-2.18,12.08-3.2,17.66
      C300.09,118.23,299.03,122.36,298.41,126.55z M326.39,218.93c0.24,0.68,0.48,1.35,0.73,2.03c0.84,2.25,2.51,5.81,2.99,8.47
      c0.48,2.66,0.1,10.14-0.41,13.45c-0.3-0.57-1.13-1.64-2.22-4.57c-0.77-2.08-1.75-5.68-2.09-9.32c-0.11-1.15-0.2-2.08-0.11-3.41
      C325.39,224.23,326.32,219.47,326.39,218.93z"/>
    <path fill="#994F38" d="M355.98,38.88c0.1,0.94,0.04,2.12-0.37,3.44c-0.4,1.28-2.52,5.63-2.66,8.86
      c-0.08,1.71,0.45,3.2,1.61,4.35c1.01,1,2.29,1.62,2.97,2.97c1.14,2.25-0.45,4.46-0.56,6.7c-0.11,2.41,1.55,3.22,3.37,3.76
      c1.03,0.3,2.09,0.6,2.9,1.4c1.03,1.02,1.37,2.71,1.41,4.15c0.06,1.86-0.26,3.64-0.3,5.46c-0.11,2.49,0.59,5.74,3.09,6.13
      c1.16,0.18,2.47-0.26,3.01-1.36c0.27-0.55,0.11-1.13-0.24-1.52c0.97,0.54,0.86,1.76,0.27,2.86c-0.8,1.49-2.64,2.05-4.21,1.71
      c-1.53-0.33-2.75-1.58-3.34-2.98c-0.37-0.88-0.52-1.72-0.51-2.79c0.01-1.08,0.06-1.98-0.13-2.83c-0.38-1.71-1.41-3-3.3-3.77
      c-1.5-0.61-3.1-0.88-4.63-1.4c-2.12-0.71-3.94-1.74-3-4.34c1.12-3.11,2.73-6.1,1.2-9.41c-0.73-1.57-1.7-2.98-2.16-4.69
      c-0.71-2.59,0.28-5.65,1.22-8.01c1.41-3.54,3.24-6.91,3.62-10.71C355.73,37.36,355.89,38.01,355.98,38.88z"/>
    <path fill="#7D3B29" d="M354.09,68.59c0.44-2.96,0.51-5.68-0.48-8.55c-0.84-2.43-2.06-4.62-2.35-7.22
      c-0.16-1.38-0.14-2.76-0.05-4.14c-0.78,2.16-1.4,4.7-0.8,6.9c0.46,1.71,1.44,3.11,2.16,4.69c1.53,3.32-0.08,6.31-1.2,9.41
      c-0.94,2.6,0.88,3.63,3,4.34c0.24,0.08,0.49,0.16,0.74,0.23C353.9,72.57,353.79,70.61,354.09,68.59z"/>
    <path fill="#7D3B29" d="M366.49,87.46c-1.75-1.18-2.09-2.91-2.57-4.85c-0.3-1.22-0.82-2.17-1.54-2.98
      c0.09,0.71,0.06,1.48,0.05,2.37c-0.01,1.08,0.15,1.92,0.51,2.79c0.59,1.4,1.81,2.65,3.34,2.98c0.31,0.07,0.63,0.1,0.95,0.09
      C366.97,87.75,366.72,87.62,366.49,87.46z"/>
    <path fill="#F09CBC" d="M299.53,148.87c0,0.01,0,0.02,0.01,0.03c0.26,0.02,0.44-0.06,0.42-0.17
      c-0.04-0.22-0.14-0.83-0.19-1.74C299.3,147.55,299.4,148.19,299.53,148.87z"/>
    <path fill="#FAD1B8" d="M299.4,148.66c-0.11-0.68-0.16-1.26,0.35-1.8c-0.03-0.6-0.06-1.48,0-1.91
      c-0.35,0.75-1.09,1.76-1.5,2.47c0.2,1,0.77,1.37,1.2,1.46C299.43,148.81,299.42,148.73,299.4,148.66z"/>
    <path fill="#F09CBC" d="M289.6,151.74c-0.26,0.19-0.76,0.92-0.82,1.02c-0.08,0.81,0.13,1.08,0.35,1.02
      c0.32-0.09,0.76-0.6,1.27-1.57C290.33,151.76,289.87,151.55,289.6,151.74z"/>
    <path fill="#F09CBC" d="M291.89,153.22c-0.17,0.24-0.63,1.1-0.74,1.4c0.16,0.88,1.01,0.55,1.61-1.01
      C292.87,153,292.07,152.96,291.89,153.22z"/>
    <path fill="#F09CBC" d="M288.73,149.13c-0.16-0.03-0.33,0.05-0.46,0.13c-0.26,0.15-0.57,0.37-0.71,0.65
      c0.01,0.08,0.03,0.15,0.06,0.22c0.18,0.41,0.79-0.21,1.26-0.79C288.87,149.23,288.83,149.15,288.73,149.13z"/>
    <path fill="#F09CBC" d="M294.3,153C294.3,153,294.3,152.99,294.3,153c0.03-0.18,0.07-0.44-0.05-0.59
      c-0.12-0.16-0.28-0.14-0.42-0.05c-0.18,0.42-0.34,0.84-0.48,1.23C293.59,153.63,293.91,153.41,294.3,153z"/>
    <path fill="#FAD1B8" d="M297.46,142.02c-0.03-2.48,0.03-4.87,0.18-7.42c-1.7,1.63-4.06,6.08-4.53,8.9
      c-1.36,1.01-4.01,3.04-5.16,4.93c-0.27,0.45-0.42,0.94-0.41,1.33c0.2-0.31,0.57-0.56,0.89-0.67c0.13-0.04,0.33-0.09,0.44,0.03
      c0.04,0.04,0.06,0.09,0.07,0.14c0.24-0.29,0.42-0.56,0.49-0.65c0.45-0.63,2.56-3.17,3.19-3.08c-0.67,0.67-2.67,3.42-3.3,5.06
      c-0.3,0.76-0.47,1.51-0.54,2c0.06-0.09,0.52-0.87,0.92-0.99c0.43-0.14,0.68,0.19,0.78,0.54c0.05-0.09,0.14-0.34,0.19-0.43
      c0.57-1.17,2.05-3.56,2.83-4.76c0.34-0.51,0.85-0.73,0.81-0.65c-0.11,0.19-3.11,5.19-3.2,7.67c-0.01,0.18,0,0.33,0.01,0.46
      c0.17-0.32,0.62-1.14,0.7-1.24c0.13-0.17,0.29-0.21,0.52-0.19c0.31,0.02,0.52,0.2,0.53,0.46c0.49-1.05,1.23-2.79,1.71-3.78
      c0.72-1.48,1.87-2.53,1.56-1.97c-0.67,1.21-1.57,2.94-2.24,4.53c0.07-0.02,0.15-0.03,0.23,0c0.19,0.05,0.27,0.22,0.29,0.4
      c0.01,0.08,0.01,0.17,0,0.26c0.99-1.12,2.3-3.39,3.41-5.57c0.33-0.76,1.05-1.63,1.43-2.37c0.31-0.61,0.65-1.35,0.9-2.58
      C298.39,142.18,297.46,142.08,297.46,142.02z"/>
    <path fill="#E5B59C" d="M296.04,137.57c0.5,0.12,0.98,0.29,1.45,0.5c0.03-1.14,0.08-2.29,0.15-3.47
      c-0.65,0.63-1.41,1.68-2.11,2.89C295.68,137.5,295.85,137.52,296.04,137.57z"/>
    <path fill="#E5B59C" d="M300.02,142.93c0.05-0.18,0.09-0.36,0.13-0.57c-1.15-0.12-1.94-0.21-2.35-0.27
      c0,0.29-0.01,0.59-0.02,0.88C298.52,142.93,299.28,142.88,300.02,142.93z"/>
    <path fill="#759CE3" d="M321.81,195.69c0.15,2.03,0.31,4.07,0.53,6.09c0.5,4.61,1.51,9.07,2.85,13.46
      c-1.32-0.48-4.04-0.67-5.22,0.44C320.09,210.17,320.74,199.97,321.81,195.69z"/>
    <path fill="#4D78BF" d="M322.29,205.5c0.23,3.16,0.75,6.27,1.2,9.4c0.65,0.06,1.26,0.18,1.7,0.35
      c-1.34-4.39-2.35-8.85-2.85-13.46c-0.21-1.92-0.36-3.85-0.51-5.78C321.44,199.16,322.05,202.35,322.29,205.5z"/>
    <path fill="#FAD1B8" d="M322.64,247.94c-0.65-2.36-1.47-5.28-1.97-7.41c-0.56-2.38-1.13-4.59-1.03-7.26
      c0.1-2.68,0.28-5.21,0.29-7.82c0.02-2.93-0.02-6.23,0.03-9.15c1.36-1.51,4.11-0.99,5.34-0.67c0.28,0.9,0.57,1.8,0.88,2.7
      c-0.64,2.16-1.14,5.4-1.29,6.62c-0.15,1.22-0.16,2.53-0.03,4.06c0.11,1.39,0.29,3.38,0.6,4.74c0.82,3.56,2.41,7.46,4.21,10.29
      c-0.27,1.87-0.06,4.11,0.06,5.46C327.67,249.95,324.64,249.07,322.64,247.94C322.65,247.93,322.64,247.94,322.64,247.94z"/>
    <path fill="#E5B59C" d="M323.79,217.07c0.31,2.41,0.68,4.77,1.21,7.1c0.22-1.51,0.65-4.05,1.18-5.85
      c-0.31-0.9-0.6-1.8-0.88-2.7c-0.44-0.11-1.07-0.25-1.76-0.31C323.63,215.9,323.71,216.48,323.79,217.07z"/>
    <path fill="#FCE2D3" d="M325.62,237.27c0.51,2.18,1.25,4.25,2.29,6.24c0.47,0.91,1.03,1.74,1.63,2.53
      c0-0.68,0.04-1.36,0.13-2.01c-1.8-2.83-3.39-6.72-4.21-10.29c-0.13-0.56-0.23-1.23-0.32-1.92
      C324.82,233.61,325.22,235.56,325.62,237.27z"/>
    <path fill="#D47B9C" d="M323.93,251.81c-0.57-0.82-0.94-2.24-1.1-3.21c2.06,1.05,4.71,2.13,6.99,1.58
      c0.21,1.26,0.53,2.97,0.87,4.45C327.6,254.37,324.49,252.63,323.93,251.81z"/>
    <path fill="#B05A80" d="M330.68,254.63c-0.34-1.48-0.66-3.19-0.87-4.45c-0.13,0.03-0.25,0.05-0.38,0.08
      c-0.19,0.9-0.28,1.84-0.36,2.73c-0.04,0.45-0.07,0.91-0.1,1.36C329.54,254.48,330.11,254.58,330.68,254.63z"/>
    <path fill="#D47B9C" d="M345.09,257.53c1.33,2.86-3.27,2.28-4.38,2.1c-2.33-0.39-7.61-2.17-8.27-2.92
      c-0.66-0.75-1.48-3.64-1.6-5.09c0.59,0.82,1.75,0.86,3.27,0.84c1.82-0.03,4.65-0.32,7-1.18
      C341.76,252.1,344.11,255.41,345.09,257.53z"/>
    <path fill="#E5B0C4" d="M345.09,257.53c-0.98-2.12-3.33-5.43-3.97-6.25c-0.23,0.08-0.47,0.16-0.7,0.24
      c0.67,0.94,1.23,1.97,1.72,3.02c0.55,1.18,1.17,2.39,1.58,3.62c0.18,0.53,0.28,1.03,0.33,1.52
      C345.04,259.45,345.71,258.86,345.09,257.53z"/>
    <path fill="#FAD1B8" d="M334.66,225.73c0.6,2.68,1.27,5.35,1.97,8.01c0.32,1.21,1,3.58,1.62,5.96
      c0.63,2.37,2.02,7.83,2.61,10.87c-2.25,1.01-8,1.55-9.29,0.97c-0.61-0.28-0.76-0.76-0.91-1.32c-0.52-4.05-0.19-7.1,0.08-10.53
      c0.27-3.45,0.19-8.11-0.15-10.35c-0.34-2.23-2.43-6.89-3.25-9.21c0.44-1,1.03-1.78,1.91-2.45c1.09-0.84,2.86-1,3.82-0.64
      C333.47,219.96,334.01,222.85,334.66,225.73z"/>
    <path fill="#FCE2D3" d="M336.24,235.67c0.78,3.16,1.62,6.36,2.42,9.62c0.59,2.39,1.38,5.58,1.43,5.57
      c0.29-0.09,0.55-0.19,0.77-0.29c-0.59-3.04-1.99-8.5-2.61-10.87c-0.63-2.37-1.31-4.75-1.62-5.96c-0.7-2.66-1.37-5.33-1.97-8.01
      c-0.65-2.88-1.19-5.77-1.58-8.67c-0.18-0.07-0.4-0.11-0.62-0.14C333.31,223.22,334.72,229.5,336.24,235.67z"/>
    <path fill="#759CE3" d="M337.49,149.93c-0.84,5.62-1.23,11.11-1.85,16.85c-0.49,4.52-1.62,9.16-2.33,13.78
      c-0.34,2.21-0.57,5.15-0.6,6.6c-0.03,1.46-0.15,5.27-0.81,8.44c-0.12-0.99-1.3-11.34-1.39-12.23c-0.29-2.96-0.09-5.89-0.57-8.85
      c-0.52-3.16-1-6.33-1.39-9.5c-0.63-5.08-0.83-11.32-0.78-16.44c0.01-0.62-0.36,0.51-0.41,1.44c-0.36,6.46,0.44,12.52,1.3,18.92
      c0.43,3.2,1.16,6.42,1.2,9.66c0.02,1.48-0.02,2.95,0.07,4.42c0.09,1.56,2.31,17.72,2.4,24.34c0.04,3.14,0.29,6.25,0.7,9.34
      c-1.01-0.31-2.38-0.14-3.33,0.37c-0.95,0.51-2.13,1.68-2.45,2.78c-1.26-3.55-2.37-7.34-3.19-11c-1.44-6.45-1.59-12.83-2.16-19.38
      c-0.34-3.93-1.21-9.3-2.66-15.03c-3.16-12.47-8.49-27.89-9.54-30.99c8.2,0.94,19.12,2.01,28.58,1.23
      C338.09,145.96,337.67,148.77,337.49,149.93z"/>
    <path fill="#4D78BF" d="M327.65,147.38c3.32,0.27,6.63,0.74,9.89,1.39c0.04,0.01,0.08,0.01,0.12,0.02
      c0.2-1.34,0.48-3.16,0.62-4.13c-9.46,0.79-20.39-0.28-28.58-1.23c0.27,0.78,0.81,2.36,1.51,4.47
      C316.65,147.08,322.17,146.93,327.65,147.38z"/>
    <path fill="#A1C2F0" d="M329.93,183.03c-0.09-1.47-0.05-2.95-0.07-4.42c-0.04-3.24-0.78-6.46-1.2-9.66
      c-0.77-5.8-1.5-11.33-1.36-17.12c-0.21,1.34-0.32,2.7-0.37,4.05c-0.17,5.46-0.03,8.91,0.78,14.3c0.48,3.16,1.11,4.83,1.15,6.02
      c0.19,5.74-0.02,7.82,0.67,12.62c0.57,4.02,2.01,15.97,2.1,19.7c0.07,2.93,0.48,6.5,0.96,8.08c0.15,0.03,0.3,0.06,0.44,0.1
      c-0.41-3.09-0.65-6.19-0.7-9.34C332.24,200.74,330.02,184.59,329.93,183.03z"/>
    <path fill="#4D78BF" d="M331.9,195.61c0.37-1.79,0.57-3.78,0.68-5.44c-0.83-4.07-1.56-8.17-2.32-12.25
      c0.08,1.82,0.07,3.63,0.25,5.46C330.6,184.26,331.79,194.62,331.9,195.61z"/>
    <path fill="#F09CBC" d="M346.04,163.86c-0.01,0.08-0.03,0.16-0.04,0.24c0,0.02,0.21,0.08,0.24,0.09
      c0.3,0.07,0.37-0.2,0.44-0.44c0.07-0.24,0.15-0.49,0.23-0.73c0.04-0.13,0.08-0.26,0.12-0.4c0.02-0.06,0.06-0.17,0.06-0.24
      c0,0-0.01-0.01-0.01-0.01C346.27,162.2,346.13,163.3,346.04,163.86z"/>
    <path fill="#FAD1B8" d="M349.47,152.78c-1.19,0.11-3.27,0.26-5.45,0.26c-2.08,3.51-0.69,6.53,0.16,8.58
      c-0.21,0.14-0.54-0.54-0.7-0.82c-0.24,0.62-0.31,1.79,0,2.8c-0.27,0.48-1.05,2.08-1.24,2.86c-0.11,0.45-0.49,2.36,0.11,2.6
      c0.24-2.1,1.36-4.47,1.66-4.43c-0.94,2.45-2.03,6.06-0.86,6.39c0.13-1.7,0.67-3.54,0.98-4.69c0.13-0.46,0.4-0.73,0.5-0.69
      c-2.07,6.35-0.69,8.65,0.17,8.04c-0.56-2.08-0.35-4.52,0.41-6.57c0.28-0.76,0.72-1.79,1.07-1.71c-2.2,5.7-1.26,8.39-0.49,9.01
      c0.02-1.04,0.22-1.98,0.91-1.8c0.29-1.74,0.74-4.22,1.24-5.54c0.74-1.98,1.97-4.83,2.12-8.32
      C350.12,157.24,349.86,154.47,349.47,152.78z M346.99,163.04c-0.09,0.28-0.16,0.56-0.26,0.84c-0.05,0.15-0.12,0.38-0.3,0.41
      c-0.16,0.03-0.37-0.06-0.51-0.14c-0.02-0.01-0.03-0.03-0.02-0.05c0.13-0.64,0.21-2.03,1.19-1.82c0.01,0,0.02,0.01,0.03,0.02
      c0.02,0,0.04,0.01,0.04,0.03C347.19,162.55,347.05,162.85,346.99,163.04z"/>
    <path fill="#F09CBC" d="M345.89,174.45c0.26,0.15,0.48,0.07,0.53-0.22c0.06-0.32,0.15-0.88,0.26-1.56
      C346.08,172.52,345.9,173.44,345.89,174.45z"/>
    <path fill="#E5B59C" d="M348.29,154.14c0.5,0.06,0.99,0.15,1.48,0.27c-0.09-0.59-0.18-1.15-0.3-1.63
      c-1.19,0.11-3.27,0.26-5.45,0.26c-0.37,0.62-0.62,1.22-0.79,1.81C344.85,154.29,346.56,153.94,348.29,154.14z"/>
    <path fill="#E5B59C" d="M345.08,157.49c-0.04-0.21-0.09-0.42-0.14-0.64c-0.15,1.63-0.5,3.25-0.66,4.88
      c-0.09,0.97-0.19,2.15,0.55,2.92c0.41,0.43,1.17,0.76,1.38-0.11c0.05-0.21-0.39-0.45-0.48-0.57c-0.85-1.04-0.89-2.34-0.72-3.62
      C345.14,159.39,345.28,158.47,345.08,157.49z"/>
    <path fill="#F3B1CA" d="M349.73,152.06c-2.39,0.22-6.69,0.47-9.17,0.15c-0.31-2.84-0.06-6.37,0.27-7.87
      c2.61-0.34,5.06-0.86,7.27-1.51C348.47,145.92,349.24,150,349.73,152.06z"/>
    <path fill="#E69CB8" d="M346.53,145.22c0.61-0.16,1.21-0.35,1.81-0.55c-0.09-0.64-0.18-1.26-0.25-1.84
      c-2.2,0.66-4.66,1.18-7.27,1.51c-0.09,0.41-0.17,0.99-0.24,1.66C342.59,146.11,344.6,145.74,346.53,145.22z"/>
    <path fill="#F3B1CA" d="M349.05,141.86c-8.09,2.78-16.8,2.86-25.24,2.25c-8.63-0.62-17.19-1.98-25.83-2.57
      c0.18-4.27,0.34-8.53,0.76-12.79c0.47-4.66,1.69-9.2,2.5-13.8c0.8-4.55,2.5-14.11,3.79-20.9c0.69-3.65,1.39-6.58,1.61-7.37
      c0.02-0.07-0.31-0.14-0.58,0.78c-0.27,0.92-1.03,4.38-1.61,6.99c-1.27,3.69-11.87,16.88-15.69,18.54c-0.29-0.21-1.46-1.03-1.71-0.9
      c2.12,1.93,5.07,4.15,6.82,6.44c0.9,1.18,1.51,2.54,2.18,3.87c0.96,1.91,1.68,3.65,2.14,5.73c-0.23,1.9-0.41,3.85-0.53,5.81
      c-1.29,0.51-3.31,1.72-4.49,2.18c-0.97-4.03-2.71-8.01-4.64-11.67c-1.04-1.98-2.22-3.87-3.62-5.62c-1.39-1.74-3.13-5.21-3.13-6.48
      c-0.01-1.28,1.26-2.62,2.09-3.35c3.42-2.98,8.76-8.23,11.49-11.22c2.3-2.52,4.63-5.68,6.63-8.44c1.62-2.23,2.69-4.79,4.46-6.25
      c-0.02,0.24,0.12,0.51,0.34,0.78c0.78,0.96,1.8,1.44,2.91,1.94c2.57,1.16,5.42,1.48,8.01,2.59c0.06,0.03-0.29-0.27-0.73-0.43
      c-0.56-0.21-1.67-0.6-2.24-0.78c-1.08-0.34-2.17-0.65-3.23-1.02c-1.14-0.39-5.88-2.26-4.52-3.87c0.19,0.07,4.6,1.52,6.58,1.96
      c8.99,2.02,18.65,1.5,27.61-0.39c0.13,0.31,0,1.17-0.14,1.54c-0.2,0.51-0.84,0.65-1.39,0.85c-1.53,0.56-3.64,1.22-5.22,1.61
      c0.23,0.27,2.91-0.23,6.4-1.66c0.62,1.62,0.71,3.47,0.69,5.17c-0.03,2.43-0.42,4.86-0.8,7.26c-0.34,2.19-0.71,4.39-0.82,6.61
      c-0.1,2.23,0.18,4.51,0.41,6.73C341.41,122.24,343.96,132.8,349.05,141.86z"/>
    <path fill="#F8D5E2" d="M304.23,98.33c-1.13,6.09-2.35,12.97-3,16.63c-0.81,4.6-2.03,9.15-2.5,13.8
      c-0.43,4.25-0.58,8.52-0.76,12.79c1.55,0.1,3.09,0.24,4.64,0.39c-0.07-0.28-0.14-0.55-0.21-0.83c-1.09-4.81-1.31-9.8-0.98-14.71
      C302.06,117,303.62,107.71,304.23,98.33z"/>
    <path fill="#F8D5E2" d="M339.9,105.25c0.04-0.89,0.13-1.77,0.23-2.65c-1.44,3.26-1.27,9.4-1.02,12.24
      c0.27,3.08,1.29,10.55,2.81,16.22c0.84,3.12,2.35,7.05,4.74,11.54c0.8-0.22,1.6-0.46,2.38-0.73c-5.09-9.06-7.64-19.62-8.73-29.88
      C340.08,109.76,339.8,107.48,339.9,105.25z"/>
    <path fill="#F8D5E2" d="M314.24,85.27c4.28,0.92,9.26,1.18,14.51,0.92c4.75-0.23,11.24-1.06,12.47-2.13
      c-0.01-0.07-0.02-0.14-0.04-0.19c-8.95,1.89-18.62,2.4-27.61,0.39c-1.98-0.44-6.39-1.89-6.58-1.96c-0.07,0.08-0.12,0.17-0.16,0.25
      C308.26,83.74,312.48,84.89,314.24,85.27z"/>
    <path fill="#E69CB8" d="M308.06,85.82c3.55,1.52,6.16,1.63,7.37,1.78c-1.92-0.56-3.9-0.97-5.73-1.8
      c-1.11-0.5-2.13-0.99-2.91-1.94c-0.22-0.27-0.36-0.54-0.34-0.78c-0.06,0.05-0.11,0.1-0.16,0.15
      C306.27,84.63,306.92,85.33,308.06,85.82z"/>
    <path fill="#E69CB8" d="M305.66,89.08c-2.48,3.69-4.04,7.81-5.41,12.02c2.1-2.79,3.75-5.32,4.21-6.64
      C304.84,92.7,305.31,90.57,305.66,89.08z"/>
    <path fill="#F8D5E2" d="M285.93,108.2c1.25-1.31,6.5-6.2,7.62-7.39c2.8-2.99,5.43-5.87,7.39-8.81
      c1.41-2.11,2.5-3.78,3.29-6.19c-0.72,1.14-1.41,2.38-2.26,3.54c-2,2.76-4.33,5.92-6.63,8.44c-2.73,2.99-8.07,8.24-11.49,11.22
      c-0.84,0.73-2.1,2.07-2.09,3.35c0,0.42,0.2,1.09,0.5,1.85C281.45,111.34,284.75,109.44,285.93,108.2z"/>
    <path fill="#E69CB8" d="M313.42,109.73c2.16,2.11,4.8,3.58,7.89,4.06c2.43,0.38,4.8,0.22,7.16-0.45
      c1.07-0.3,3.89-1.3,4.98-1.54c-2.88-1.1-7.95-1.39-11.1-1.29C319.35,110.6,316.36,110.79,313.42,109.73z"/>
    <path fill="#E69CB8" d="M327.26,139.49c-3.19-0.25-6.39-0.25-9.59-0.5c-3.11-0.24-5.18,0.13-7.44,0.72
      c5.9,0.28,12.36,1.46,15.36,1.67c4.74,0.32,9.61,0.28,14.27-0.77C338.77,140.45,330.37,139.73,327.26,139.49z"/>
    <path fill="#F8D5E2" d="M287.06,112.09c2.3,4.94,7.32,7.92,10.04,12.56c-0.31-0.74-0.65-1.48-1.04-2.25
      c-0.67-1.33-1.28-2.69-2.18-3.87C292.13,116.24,289.18,114.02,287.06,112.09z"/>
    <path fill="#FAD1B8" d="M311.2,41.38c1.99-0.1,2.82-2.1,2.7-3.11c-0.31,0.25-0.89,0.5-1.29,0.55
      c-1.29,0.18-1.3-1.35-0.56-1.65c0.42-0.17,0.96-0.16,1.4-0.13c1.27,0.08,2.48,0.67,3.77,0.59c1.67-0.1,3.57-0.97,3.76-2.75
      c0.05,0.07,0.11,0.14,0.18,0.21c0.67,0.71,2.02,0.09,2.69-0.39c0.55-0.4,1.07-0.85,1.73-1.05c1.18-0.36,1.71,1.42,1.93,4.25
      c0.77-0.05,2.4-3.23,1.73-5.22c-0.3-0.88-1.13-1.57-2.03-1.66c-0.93-0.1-2.43,0.36-1.74-0.97c0.19-0.37,0.31-0.6,0.2-0.92
      c0.79,0.14,1.61,0.23,2.39,0.34c1.66,0.24,3.67,1.06,3.37,3.18c-0.12,0.83-0.86,1.6-0.79,2.47c0.14,1.7,1.65,2.71,3.39,3.31
      c1.55,0.54,6.28,1.49,7.65,0.84c1.8,4.48,2.53,9.99,0.68,14.23c-1.86,4.27-4.74,7.37-8.01,9.9c-2.64,2.05-6.21,3.09-8.59,3.39
      c-2.29,0.29-4.62,0.49-7.63-0.19c-4.88-1.11-8.4-3.98-11.02-7.25c-1.96-2.44-2.42-5.01-2.34-7.79c0.06-1.99,0.68-4.79,1.18-6.94
      c1.62-0.3,2.78-0.87,3.95-1.68C310.44,42.58,311,42.01,311.2,41.38z"/>
    <path fill="#E5B59C" d="M330.67,37.66c0.37,1.2,1.59,1.81,2.67,2.27c1.3,0.55,2.57,0.94,3.99,1.05
      c1.7,0.14,3.34,0.18,4.94-0.03c-0.18-0.57-0.39-1.12-0.6-1.67c-1.38,0.65-6.1-0.3-7.65-0.84c-1.51-0.53-2.84-1.35-3.26-2.67
      C330.78,36.39,330.48,37.04,330.67,37.66z"/>
    <path fill="#E5B59C" d="M327.2,31.02c0.9,0.09,1.74,0.78,2.03,1.66c0.2,0.59,0.19,1.29,0.07,1.98
      c0.45-0.1,1.01,0.07,1.24,0.42c0.04,0.06,0.07,0.12,0.1,0.18c-0.01-0.04-0.01-0.09-0.02-0.13c-0.07-0.87,0.67-1.64,0.79-2.47
      c0.3-2.12-1.7-2.94-3.37-3.18c-0.79-0.11-1.6-0.21-2.39-0.34c0.11,0.32,0,0.54-0.2,0.92C324.77,31.39,326.28,30.92,327.2,31.02z"/>
    <path fill="#E5B59C" d="M320.3,52.03c-0.69,0.68-0.55,1.44,0.21,1.99c0.82,0.59,1.73,0,1.83-0.35
      C321.51,53.43,320.33,53.16,320.3,52.03z"/>
    <path fill="#FCE2D3" d="M321.01,51.09c-0.82,1.32,0.14,1.95,1.15,2.49c-0.33-0.56-0.59-1.12-0.24-1.78
      c0.38-0.72,1.12-0.92,1.07-1.93c-0.02-0.4-0.08-0.8-0.14-1.2C322.35,49.56,321.57,50.18,321.01,51.09z"/>
    <path fill="#E5B59C" d="M307.97,44.96c0.82-0.37,1.74-0.74,2.46-1.29c0.5-0.38,0.93-0.76,1.51-1.02
      c0.5-0.22,1.04-0.31,1.44-0.72c0.73-0.74,1.16-1.69,1.38-2.7c0.02-0.09,0.14-0.16,0.23-0.13c0.88,0.3,1.85,0.04,2.71-0.24
      c0.86-0.28,1.43-0.82,2.18-1.29c0.63-0.39,1.45-0.07,2.09-0.5c0.52-0.35,0.97-0.81,1.49-1.16c0.7-0.46,1.67-0.63,2.07,0.32
      c0.43,1,0.26,2.13-0.06,3.14c0.93-0.2,1.56-0.87,2.03-1.66c-0.22-2.72-0.75-4.43-1.91-4.08c-0.66,0.2-1.18,0.65-1.73,1.05
      c-0.67,0.48-2.02,1.1-2.69,0.39c-0.07-0.07-0.13-0.14-0.18-0.21c-0.19,1.78-2.09,2.65-3.76,2.75c-1.29,0.08-2.5-0.51-3.77-0.59
      c-0.44-0.03-0.98-0.04-1.4,0.13c-0.74,0.3-0.73,1.83,0.56,1.65c0.4-0.06,0.98-0.3,1.29-0.55c0.12,1.01-0.71,3.01-2.7,3.11
      c-0.19,0.63-0.75,1.2-1.31,1.58c-1.17,0.81-2.33,1.38-3.95,1.68c-0.08,0.34-0.16,0.71-0.25,1.07
      C306.48,45.6,307.26,45.28,307.97,44.96z"/>
    <path fill="#FAD1B8" d="M321.65,67.98c1.7,0.02,3.26-0.09,4.22-0.31c-0.32,1.58-0.51,8.85,0.02,9.58
      c0.73,1.01,5.12,1.98,7.51,2.96c1.3,0.54,2.6,1.09,3.82,1.8c0.66,0.38,1.55,0.95,1.89,1.71c-4.26,0.78-8.55,1.26-12.9,1.27
      c-6.17,0.03-12.13-1.06-18.03-2.7c0.38-0.46,2.64-1.37,5.36-2.04c2.12-0.52,5.01-1.26,6.08-1.84c0.91-0.5,1.5-1.08,1.64-1.62
      C321.93,74.07,321.87,70.55,321.65,67.98z"/>
    <path fill="#E5B59C" d="M325.62,70.44c0.06-1.23,0.15-2.28,0.25-2.77c-0.96,0.22-2.51,0.33-4.22,0.31
      c0.06,0.71,0.11,1.5,0.13,2.32C323.03,70.59,324.38,70.62,325.62,70.44z"/>
    <path fill="#FCE2D3" d="M329.97,79.7c1.52,0.49,3.55,1.33,5.02,1.95c1.42,0.6,2.91,1.35,3.63,2.15
      c0.16-0.03,0.32-0.05,0.48-0.08c-0.35-0.76-1.23-1.33-1.89-1.71c-1.22-0.7-2.52-1.26-3.82-1.8c-2.18-0.9-6.04-1.78-7.24-2.7
      C326.49,78.6,328.62,79.27,329.97,79.7z"/>
    <path fill="#FCE2D3" d="M316.44,80.04c1.34-0.38,2.45-0.77,3.07-1.59c-1.14,0.58-3.92,1.29-5.98,1.79
      c-2.72,0.67-4.98,1.58-5.36,2.04c0.16,0.04,0.31,0.09,0.47,0.13C310.34,81.58,315.06,80.43,316.44,80.04z"/>
    <path fill="#F3B1CA" d="M345.23,112.08c0.52,7.4,1.22,18.93,2.31,25.52c-3.85-8.2-5.79-17.42-6.71-26.32
      c-0.24-2.31-0.46-4.64-0.28-6.95c0.18-2.27,0.59-4.53,0.93-6.78c0.55-3.71,1.27-8.01-0.33-11.55c0.31-0.19,0.39-0.56,0.46-0.98
      c1.25,1.29,1.62,2.65,2.07,4.55C345.01,95.25,344.73,104.9,345.23,112.08z"/>
    <path fill="#E69CB8" d="M341.59,112.24c0.27-6.09,0.9-12.22,0.38-18.31c-0.13,1.24-0.32,2.46-0.49,3.62
      c-0.34,2.25-0.75,4.51-0.93,6.78c-0.18,2.32,0.05,4.65,0.28,6.95c0.18,1.77,0.41,3.55,0.68,5.33
      C341.49,115.16,341.52,113.7,341.59,112.24z"/>
    <path fill="#994F38" d="M372.33,91.42c-1.53,1.43-3.55,2.02-5.61,2.01c-1.84,0-3.53-0.59-5.3-0.97
      c-1.79-0.38-3.17-0.32-4.47,1.04c-1.18,1.23-2.65,1.99-4.34,2.22c-3.14,0.43-6.27-1.28-8.01-3.84c-0.15-0.92-0.32-1.81-0.53-2.64
      c-0.62-2.43-1.16-3.5-2.44-4.42c0.06-0.56,0.08-1.32-0.23-1.51c-0.42,0.06-1.46,0.25-1.87,0.33c-0.46-1.55-3.53-2.89-4.86-3.43
      c-1.48-0.59-2.99-1.1-4.49-1.62c-1.11-0.39-3.46-1.15-3.77-1.82c-0.31-0.67-0.05-6.77,0.16-9.14c2.89-0.33,5.41-1.56,7.92-3.24
      c3.37-2.25,6.15-5.47,8-9c1.81-3.45,2.19-6.5,1.62-10.46c-0.43-2.99-1.57-5.69-3.19-8.46c-0.52-0.88-0.99-1.39-1.33-1.66
      c0.7-0.1,1.18-0.13,1.93,0.6c0.18-0.1-0.15-1.1-1.85-1c-0.72,0.04-1.48,0.55-2.33,0.7c-1.22,0.21-2.8-0.68-3.53-2.33
      c-0.12,0.7,0.55,1.77,1.53,2.4c0.5,0.32,1.32,0.6,1.85,0.5c0.76-0.14,1.45-0.49,2.1-0.75c0.82,1.15,1.58,2.49,2.23,4.06
      c-1.66,0.26-6.22-0.65-7.68-1.26c-1.21-0.5-2.96-1.64-2.46-3.24c0.29-0.93,0.69-1.65,0.61-2.66c-0.23-3.11-4.84-2.68-7.57-3.27
      c-0.92-0.2-1.87-0.7-2.33-1.36c-0.08,0.16-0.01,0.61,0.9,1.12c0.69,0.38,1.5,0.61,2.35,0.78c0.11,0.66-0.35,0.93-0.35,1.55
      c0,0.27,0.05,0.44,0.28,0.6c0.26,0.18,0.67,0.14,0.96,0.15c0.66,0.02,1.07,0,1.66,0.33c2.04,1.14,0.91,4.15-0.12,5.67
      c-0.06-1.72-0.37-3.81-1.62-4.21c-0.95-0.31-2.07,0.9-2.72,1.38c-1.23,0.9-3.06,0.34-2.29-1.61c0.36-0.92,1.32-1.69,1.64-2.64
      c0.54-1.63-1.02-2.16-1.84-3.29c-0.38-0.52-0.48-1.02-0.64-2.12c-0.01-0.1-0.18-0.02-0.09,0.83c0.09,0.89,0.37,1.43,0.99,2.06
      c0.32,0.33,0.69,0.61,1.01,0.94c0.94,0.95,0,2.06-0.55,2.8c-0.76,1.02-1.34,2.04-0.9,3c-0.54,2.81-3.45,2.97-5.81,2.44
      c-0.87-0.2-2.81-0.7-3.54,0.19c-0.58,0.71-0.01,1.95,0.87,1.97c0.34,0.01,0.95-0.09,1.38-0.46c-0.37,1.26-1.32,2.31-2.42,2.18
      c0-0.09-0.01-0.17-0.04-0.26c-0.14-0.55-0.39-0.83-0.93-1.02c-0.55-0.2-1.43-0.19-1.78-0.81c-0.56-0.98-0.56-2.21,0.8-2.4
      c2.75-0.37,5.22-0.18,7.5-1.97c1.41-1.11,1.38-2.59,0.65-3.78c-0.34-0.55-1.34-1.53-1.62-1.43c1.59,1.44,2.56,3.24,0.66,4.9
      c-1.21,1.05-3.04,1.42-4.57,1.67c-1.82,0.29-3.49-0.08-4.02,0.96c-0.4,0.78-0.06,2.43,0.9,2.92c0.74,0.38,1.95,0.13,1.99,1.33
      c0.03,1.08-2.31,2.79-4.81,3.33c0.06-0.25,0.11-0.49,0.17-0.71c1.18-0.63,1.83-1.51,0.41-2.9c-1.04-1.02-2.91-2.18-2.76-3.85
      c0.18-2.07,3.68-2.19,5.15-2.31c2.02-0.17,5.91,0.11,5.87-2.82c-0.03-1.93-2.82-2.88-3.5-4.13c-0.35-0.65-0.13-1.47,1.34-2.48
      c-0.34-0.06-1.26,0.75-1.51,1.08c-0.8,1.04-0.11,2.01,1.38,3.14c1.2,0.91,2.74,2.44,1.08,3.79c-1.35,1.09-3.89,0.88-5.52,1.01
      c-2.07,0.17-5.6,0.82-4.72,3.72c0.32,1.05,1.35,1.85,2.14,2.53c0.46,0.4,0.93,0.79,1.26,1.31c0.83,1.3-1.94,2.12-3.68,2.86
      c-0.12,0.05,0.95-0.06,2.16-0.55c0.21-0.09,0.42-0.18,0.62-0.27c-0.2,0.39-0.45,1.04-0.7,2.09c-0.82,3.39-1.92,8.55,0.04,12.18
      c1.95,3.61,5.12,6.17,8.73,7.96c2.85,1.41,4.77,1.82,7.01,1.99c0.21,3.2,0.07,7.4-0.24,8.55c-0.17,0.63-1.06,1.25-1.87,1.61
      c-0.82,0.36-3.96,1.37-6.45,2c-2.31,0.59-4.31,1.63-4.64,2.05c-0.17-0.05-0.7-0.17-0.82-0.21c-0.17-0.05-0.43,0.67-0.48,0.89
      c-2.01,1.03-3.36,4.27-5,6.45c-1.97,2.64-4.98,6.37-6.7,8.25c-2.41,0.31-4.52,0.01-6.46-1.52c-0.99-0.78-2.06-1.85-2.3-3.65
      c-0.29-2.14,1.18-2.98,1.84-2.25c-0.72,0.26-0.18,1.16,0.11,1.38c0.81,0.64,2.03,1.25,3.38,0.57c2.97-1.51-0.57-4.16-1.77-5.14
      c-1.44-1.17-2.79-2.29-3.74-3.93c-1.37-2.35-1.03-4.86,0.19-7.18c0.84-1.59,2.01-3.01,2.53-4.76c0.55-1.85-0.01-3.72-0.83-5.39
      c-0.5-1.02-1.03-2.03-1.33-3.13c-0.41-1.51-0.11-3.09,0.6-4.45c0.89-1.71,2.53-2.91,4.04-4.05c1.03-0.78,2.94-2.37,3.89-3.44
      c0.95-1.06,2.12-2.55,2.57-4.06c0.24-0.83,0.22-2.36-0.2-3.29c-0.19-0.41-0.65-0.82-1.28-0.7c-0.87,0.17-1.65,0.34-2.37-0.26
      c-0.8-0.66-0.26-2.14,0.05-2.89c0.8-1.96,2.44-2.85,4-4.12c0.69-0.57,1.25-1.46,0.82-2.36c-0.76-1.61-1.39-3.45-1.3-4.38
      c0.49-4.94,5.8-4.59,9.32-5.68c1.68-0.52,2.93-1.63,4.39-2.55c1.76-1.11,4.81-1.35,7.9,0.01c0.04-0.5-1.47-1.32-3.63-1.53
      c0.67-0.82,2.09-1.08,3.47-1.07c0.9,0,2.23,0.25,3.46,0.88c-1.2,0.74-2.05,1.56-1.98,1.78c2.01-1.51,5.01-2.76,7.42-2.92
      c2.31-0.15,2.84,1.28,4.2,2.8c0.93,1.05,2.44,1.4,3.73,1.28c0.82-0.08,1.59-0.33,2.42-0.36c1.71-0.05,3.88,1.94,3.89,3.82
      c0.01,1.34-0.91,2.33-0.6,3.73c0.24,1.07,0.96,1.27,1.84,1.26c3.69-0.07,7.85-2.58,10.54,1.4c2.77,4.09-0.12,10.32-1.82,14.18
      c-1.63,3.68-3,7.88-1.29,11.79c0.8,1.82,2.01,3.31,2.23,5.39c0.24,2.3-1.01,4.57-1.83,6.6c-1.22,3.01,0.76,4.34,3.26,5.27
      c1.64,0.61,3.36,0.9,4.98,1.57c2.04,0.85,2.95,2.59,2.87,4.77c-0.08,2.18,0.19,3.9,1.47,5.48c1.1,1.35,2.85,2.02,4.55,1.73
      c1.54-0.26,2.73-1.49,3.14-2.95c0.29-1.03-0.04-1.97-0.88-2.34c1.61,0.26,2.74,1.31,3.17,3.14
      C373.86,87.86,373.76,90.08,372.33,91.42z"/>
    <path fill="#BA7861" d="M293.55,42.25c-0.3-1.17,0.38-2.33,1.24-3.2c0.56-0.56,1.17-1.08,1.83-1.52
      c0.75-0.5,1.62-0.96,2.21-1.66c0.79-0.96,1.1-2.51,0.78-3.73c-0.23-0.88-0.8-1.69-0.83-2.61c-0.03-1.2,0.47-2.25,1.46-2.95
      c2.72-1.93,6.41-1.71,9.29-3.36c0.99-0.57,1.8-1.3,2.65-2.04c0.57-0.5,1.2-1.01,1.95-1.23c0.8-0.23,1.65-0.22,2.48-0.2
      c0.16,0,0.33,0.01,0.5,0.02c-2.48-0.65-4.78-0.32-6.21,0.58c-1.46,0.92-2.71,2.03-4.39,2.55c-3.52,1.1-8.83,0.75-9.32,5.68
      c-0.09,0.92,0.55,2.77,1.3,4.38c0.43,0.9-0.13,1.8-0.82,2.36c-1.56,1.27-3.2,2.15-4,4.12C293.39,40.16,292.88,41.55,293.55,42.25z"
      />
    <path fill="#BA7861" d="M321.11,19.88c0.23-0.08,0.47-0.16,0.7-0.24c1.16-0.4,2.78-0.98,4.02-1.07
      c1.05-0.08,1.91-0.18,2.87,0.31c1.12,0.56,1.46,2,2.47,2.68c1.12,0.74,2.49,1.28,3.86,1.23c0.94-0.03,2.4-0.35,3.34-0.12
      c1.73,0.44,2.7,2.13,2.36,3.74c-0.34,1.58-1.15,3.29,0.37,4.33c0.9,0.61,1.66,0.8,2.75,0.78c2.72-0.05,4.64-1.38,7.1-0.63
      c1.7,0.51,3.3,2.55,3.67,4.89c0.03,0.18,0.06,0.36,0.08,0.54c0.12-1.76-0.15-3.46-1.12-4.89c-2.69-3.97-6.86-1.47-10.54-1.4
      c-0.88,0.02-1.6-0.19-1.84-1.26c-0.31-1.4,0.61-2.39,0.6-3.73c-0.01-1.88-2.18-3.87-3.89-3.82c-0.83,0.03-1.6,0.28-2.42,0.36
      c-1.29,0.12-2.8-0.23-3.73-1.28c-1.36-1.52-1.89-2.95-4.2-2.8c-2.21,0.14-4.9,1.2-6.88,2.53
      C320.82,19.98,320.96,19.93,321.11,19.88z"/>
    <path fill="#BA7861" d="M321.29,19.19c0.25-0.18,0.52-0.37,0.82-0.56c-1.23-0.63-2.57-0.88-3.46-0.88
      c-1.38-0.01-2.8,0.25-3.47,1.07c0.37,0.04,0.72,0.09,1.05,0.16C317.51,17.93,319.84,18.5,321.29,19.19z"/>
    <path fill="#BA7861" d="M303.53,32.29c-1.29,0.86-1.61,2.24-0.96,3.52c0.66-1.65,2.09-2.34,3.89-2.48
      c1.57-0.12,3.61,0.13,5.02-0.72c-1.37,0.28-3.19-0.32-4.31-0.56C305.94,31.78,304.66,31.54,303.53,32.29z"/>
    <path fill="#7D3B29" d="M310.18,37.27c0.99-0.77,2.17-0.92,3.39-1c1.72-0.12,3.68-0.34,4.53-2.1
      c0.59-1.22,0.26-2.39-0.49-3.31c0.59,1.14,0.52,2.49-0.79,3.53c-2.28,1.8-4.75,1.6-7.5,1.97c-1.36,0.19-1.36,1.42-0.8,2.4
      c0.29,0.51,0.94,0.59,1.47,0.72C309.56,38.79,309.46,37.84,310.18,37.27z"/>
    <path fill="#BA7861" d="M321.97,31.12c0.51-0.71,1.03-1.61,0.25-2.4c-0.32-0.33-0.69-0.61-1.01-0.94
      c-0.54-0.55-0.82-1.04-0.94-1.74c-0.17,1.18,0.26,2.3,0.96,3.29C321.59,29.86,322.03,30.49,321.97,31.12z"/>
    <path fill="#BA7861" d="M327.72,32.39c0.66,0.29,1.16,0.82,1.31,1.54c0.03-0.91-0.26-1.74-1.13-2.22
      c-0.59-0.33-1-0.31-1.66-0.33c-0.27-0.01-0.63,0.01-0.89-0.12C325.7,32.12,326.94,32.06,327.72,32.39z"/>
    <path fill="#7D3B29" d="M323,28.3c-0.3-0.17-0.5-0.33-0.64-0.47c0.42,1.14,1.68,1.71,2.91,1.92
      c0.08-0.19,0.13-0.4,0.08-0.67C324.5,28.92,323.69,28.69,323,28.3z"/>
    <path fill="#7D3B29" d="M303.56,57.51c0.09,2.8,1.3,5.35,3.18,7.41c3.28,3.6,8.8,5.72,14.33,6.1c0-1.04-0.04-2.09-0.1-3.06
      c-2.25-0.17-4.16-0.58-7.01-1.99c-3.61-1.79-6.79-4.35-8.73-7.96c-0.97-1.8-1.19-3.98-1.06-6.13
      C303.74,53.73,303.5,55.63,303.56,57.51z"/>
    <path fill="#7D3B29" d="M328.8,70.83c4.39-0.81,8.6-3.47,11.41-6.52c2.64-2.86,3.93-6.01,4.43-9.02
      c0.43-2.57,0.22-6.21-0.36-8.82c0.26,3.23-0.24,5.91-1.8,8.9c-1.85,3.54-4.63,6.76-8,9c-2.52,1.68-5.03,2.91-7.92,3.24
      c-0.21,2.37-0.47,8.47-0.16,9.14c0.18,0.4,1.08,0.82,2.01,1.19C328.37,77.11,328.15,72.06,328.8,70.83z"/>
    <path fill="#BA7861" d="M285.88,79.08c0.24-1.42,2.17-4.85,2.08-6.56c-0.58,1.14-1.34,2.18-1.94,3.32
      c-1.22,2.32-1.55,4.83-0.19,7.18c0.4,0.69,0.87,1.28,1.39,1.83C286.3,83.41,285.55,81.01,285.88,79.08z"/>
    <path fill="#BA7861" d="M287.5,90.52c0.03-0.16,0.13-0.3,0.35-0.38c-0.66-0.74-2.13,0.11-1.84,2.25
      c0.13,0.97,0.51,1.72,0.97,2.33C285.77,92.22,286.41,90.3,287.5,90.52z"/>
    <path fill="#7D3B29" d="M295.67,76.34c1.22-1.98,2.1-4.85,0.26-6.77c-1.46-1.53-3.54-1.91-5.19-3.12
      c-1.27-0.94-1.84-2.26-2.4-3.66c0.05,1.91,0.57,3.88,2.01,5.23c0.78,0.72,1.61,1.16,2.61,1.52c0.57,0.2,1.19,0.41,1.67,0.8
      c2.96,2.46-1.52,6.32-2.87,8.29c-1.2,1.74-2.18,3.91-1.4,6.04c0.6,1.65,1.96,2.51,3.48,3.2c1.25,0.57,2.64,1.11,3.57,2.17
      c0.81,0.92,1.05,1.95,1.11,3.05c0.37-0.47,0.74-0.93,1.11-1.4c-1.4-4.04-9.54-5.13-7.44-10.27
      C292.96,79.53,294.61,78.07,295.67,76.34z"/>
    <path fill="#7D3B29" d="M358.66,86.23c-1-1.23-1.05-2.67-1.62-4.07c-0.75-1.84-1.91-3.57-3.83-4.31
      c-1.42-0.54-2.69-1.03-3.69-2.23c-1.77-2.13-2.31-4.88-1.96-7.58c0.2-1.59,0.93-3,1.29-4.55c0.38-1.63,0.12-3.06-0.4-4.52
      c-0.04,0.8-0.29,1.64-0.51,2.32c-0.63,1.96-1.69,3.71-2.68,5.51c-1.6,2.93-2.74,6.46-1.5,9.73c1.69,4.48,5.22,3.4,6.71,4.59
      c1.7,1.35,2.09,2.91,2.84,3.56c0.87,0.75,1.89,1.35,2.96,1.77c1.26,0.49,2.57,0.81,3.89,1.11C359.6,87.2,359.1,86.76,358.66,86.23z
      "/>
    <path fill="#BA7861" d="M352.7,62.79c-0.22-2.07-1.43-3.57-2.23-5.39c-0.95-2.16-0.95-4.41-0.49-6.61
      c-1.4,2.54-1.48,6-0.12,8.5c0.76,1.4,1.77,2.7,2.32,4.21c0.18,0.5,0.3,0.99,0.36,1.48C352.7,64.26,352.78,63.53,352.7,62.79z"/>
    <path fill="#BA7861" d="M350.82,72.35c0.43,2.02,2.49,3.28,4.38,4.12c1.87,0.83,4.09,1.27,5.43,2.95
      c0.76,0.94,0.93,2.15,0.88,3.32c-0.06,1.65,0.19,3.23,1.29,4.53c0.99,1.17,2.68,2.51,4.32,1.89c0.63-0.23,1.08-0.67,1.53-1.13
      c-0.21,0.08-0.43,0.14-0.65,0.18c-1.71,0.29-3.46-0.38-4.55-1.73c-1.27-1.57-1.54-3.3-1.47-5.48c0.08-2.18-0.83-3.92-2.87-4.77
      c-1.62-0.67-3.34-0.96-4.98-1.57C352.67,74.12,351.38,73.43,350.82,72.35z"/>
    <path fill="#BA7861" d="M371.21,84.11c0.93,0.3,1.67,0.95,2.18,1.78c-0.46-1.72-1.56-2.72-3.12-2.97
      C370.78,83.15,371.09,83.58,371.21,84.11z"/>
    <path fill="#7D3B29" d="M306.66,78.89c-0.97-0.99-1.68-2.22-1.94-3.59c-0.33-1.69-0.04-3.45-0.62-5.11
      c-0.74-2.12-2.57-3.14-4.04-4.71c-1.29-1.38-2.18-2.93-2.34-4.82c-0.36-3.97,3.32-5.14,2.82-8.97c-0.65,1.2-1.4,2.34-2.42,3.24
      c-1.35,1.2-2.95,2.14-3.25,4.08c-0.36,2.39,0.07,4.71,1.88,6.41c1.3,1.22,2.78,2.24,3.44,3.97c0.76,2,0.5,3.93,0.01,5.96
      c-0.48,1.98-0.53,4.06,0.02,6.03c0.56,2.02,1.58,3.82,2.9,5.39c1.01-1.65,2.05-3.26,3.36-3.94c0.06-0.22,0.31-0.94,0.48-0.89
      c0.12,0.03,0.65,0.16,0.82,0.21c0.2-0.26,1.04-0.76,2.2-1.24C308.73,80.47,307.58,79.82,306.66,78.89z"/>
    <path fill="#BA7861" d="M286.55,63.08c-0.02-0.7,0.08-1.41,0.35-2.14c0.79-2.13,2.13-3.74,3.79-5.24
      c2.73-2.48,6.48-5.17,6.74-8.96c-0.48,1.45-1.6,2.87-2.51,3.9c-0.95,1.06-2.86,2.65-3.89,3.44c-1.5,1.14-3.14,2.34-4.04,4.05
      c-0.71,1.36-1.01,2.94-0.6,4.45C286.44,62.74,286.49,62.91,286.55,63.08z"/>
    <path fill="#7D3B29" d="M337.19,35.66c-0.53,0.1-1.35-0.18-1.85-0.5c-0.4-0.26-0.75-0.59-1.01-0.95
      c0.25,1.17,1.04,2.23,2.22,2.61c0.72,0.23,1.41,0.17,2.16,0.14c0.5-0.01,1.29-0.07,1.92,0.11c-0.42-0.79-0.87-1.51-1.34-2.17
      C338.64,35.16,337.96,35.52,337.19,35.66z"/>
    <path fill="#7D3B29" d="M334.09,29.1c-0.43-1.58-0.94-3.43-2.68-4.02c-2.11-0.71-4.34-0.12-6.5-0.04
      c1.73,1.01,3.71,0.29,5.49,1.09c1.49,0.67,1.96,2.08,2.36,3.55c0.52,1.94,1.76,3.92,3.91,4.32c1.03,0.19,1.94,0.05,2.94-0.21
      c0.16-0.04,0.32-0.09,0.49-0.13c-1.49-0.41-3.12-0.42-4.41-1.29C334.64,31.69,334.39,30.22,334.09,29.1z"/>
    <path fill="#7D3B29" d="M308.13,41.6c-0.42-1.32-1.83-1.9-2.53-3c-0.7-1.11-0.71-2.43,0.62-3.02
      c1.31-0.58,2.8-0.59,4.21-0.68c0.96-0.06,2.18-0.12,3.03-0.59c0.41-0.23,0.75-0.67,0.96-1.17c-1.05,1.41-3.84,1.26-5.45,1.4
      c-1.47,0.12-4.96,0.24-5.15,2.31c-0.15,1.67,1.72,2.83,2.76,3.85c1.42,1.4,0.77,2.27-0.41,2.9c-0.05,0.22-0.11,0.46-0.17,0.71
      c0.34-0.07,0.67-0.17,1-0.28C307.85,43.56,308.43,42.55,308.13,41.6z"/>
    <path fill="#BA7861" d="M342.88,32.77c-1.2-0.79-2.58-1.04-3.94-1.38c-1.13-0.29-1.87-0.79-2.36-1.89
      c-0.47-1.04-0.54-2.71-1.19-3.67c-1.29-1.89-2.74-2.9-5.05-2.85c-2.79,0.07-5.18,0.78-5.57,1.34c1.22-0.31,5.67-0.72,7.19-0.24
      c1.17,0.37,2.03,1.29,2.55,2.4c0.58,1.22,0.48,2.73,1.17,3.88c1.51,2.51,4.31,1.44,6.52,2.64c1.62,0.88,1.96,2.49,2.01,4.18
      c0.3-0.76,0.66-1.61,0.33-2.42C344.23,33.96,343.61,33.26,342.88,32.77z"/>
    <path d="M343.44,35.48c-0.27-1.02-0.92-1.55-2.54-2.06c-1.92-0.6-5.45-0.43-6.1-2.73c-0.58-2.07-0.84-4.45-2.75-5.76
      c-1.61-1.1-6.01-0.05-7.86-0.21c0.02,0.22,0.26,0.34,1.76,0.26c2.09-0.11,4.74-0.66,6.17,0.5c1.64,1.32,1.58,4.11,2.37,5.91
      c1.17,2.69,6.85,1.38,8.27,3.65c0.49,0.78,0.39,1.31,0.09,2.51C342.81,37.69,343.82,36.88,343.44,35.48z"/>
    <path d="M292.46,81.8c1.05-2.72,3.68-4.52,4.82-7.24c1.48-3.54-0.82-5.44-3.74-6.93c-1.64-0.83-3.37-1.67-4.37-3.28
      c-1.65-2.68-0.12-6.23,1.83-8.22c1.9-1.94,4.67-3.39,6.61-5.3c1.96-1.92,2.34-4.16,2.17-6.58c-0.12-1.81-0.43-2.15-0.42-2.07
      c0.25,2.41,0.25,4.69-1.02,6.83c-0.81,1.37-2.12,2.39-3.4,3.31c-2.17,1.57-4.42,2.92-5.87,5.24c-1.18,1.89-1.67,4.5-0.82,6.61
      c0.63,1.57,2.09,2.58,3.5,3.38c1.55,0.87,3.47,1.5,4.63,2.91c1.73,2.1-0.39,5.2-1.73,6.87c-1.64,2.03-4.33,4.73-2.73,7.5
      c1.41,2.44,5.97,4.46,7.35,6.51c0.08,0.12,0.23-0.42-0.63-1.45C296.67,87.54,291.02,85.54,292.46,81.8z"/>
    <path d="M321.79,46.53c-0.15-0.11-0.32-0.21-0.48-0.32c-0.22-0.91-0.99-1.86-1.62-2.39c-1.17-0.97-2.53-1.48-4.07-1.41
      c-1.55,0.07-3.09,0.71-4.29,1.64c-0.68,0.52-1.84,1.44-2.28,2.37c-0.38,0.33-0.74,0.71-1.05,1.14c-0.08,0.1,0.46-0.11,0.89-0.39
      c1.2,3.24,5.53,3.37,8.87,2.03c2.11-0.85,3.02-1.95,3.52-2.62C321.43,46.64,322.15,46.78,321.79,46.53z"/>
    <path fill="#BE8AD1" d="M314.76,42.94c1.48-0.37,3.01-0.08,4.22,0.81c0.71,0.52,1.51,1.32,1.89,2.2
      c-3.13-1.72-7.67-2.14-10.81-0.27C311.5,44.25,312.52,43.5,314.76,42.94z"/>
    <path fill="#8FCF5C" d="M313.43,47.28c0.49,0.68,1.43,0.81,2.15,0.33c0.74-0.49,0.74-1.82,0.21-2.33
      c0.32,0.02,0.68,0.06,0.99,0.11c0.29,0.44,0.18,1.25,0.05,1.61c-0.29,0.77-0.76,1.33-1.63,1.64c-0.89,0.32-1.97,0.13-2.57-0.62
      c-0.31-0.39-0.74-1.63-0.2-2.42c0.41-0.18,0.85-0.23,1.24-0.27C313.12,45.66,312.95,46.61,313.43,47.28z"/>
    <path fill="#FFFFFF" d="M309.12,47.04c0.97-0.63,1.89-1.08,2.93-1.39c-0.28,0.56-0.26,1.4-0.1,1.9
      c0.46,1.38,1.74,1.83,2.94,1.63c1.15-0.19,1.89-0.88,2.33-1.93c0.16-0.37,0.28-1.25,0.1-1.78c1.21,0.23,2.4,0.6,3.57,0.98
      C318.5,49.53,311.32,51.04,309.12,47.04z"/>
    <path d="M339.41,47.06c-0.48-1.19-1.44-2.27-2.26-2.93c-1.03-0.84-2.29-1.55-3.62-1.76c-3.09-0.5-5.92,1.21-7.63,3.76
      c-0.35,0.33-0.66,0.71-0.93,1.14c-0.1,0.16,0.5-0.09,0.6-0.16c3.02,3.46,9.21,4,13.68,0.53c0.52,0.33,1.02,0.68,1.5,1.04
      C340.92,48.8,340.61,47.67,339.41,47.06z"/>
    <path fill="#BE8AD1" d="M332.98,42.72c1.41,0.09,2.68,0.82,3.79,1.64c0.84,0.61,1.39,1.3,1.86,2.1
      c-0.94-0.67-1.93-1.18-3.23-1.61c-2.88-0.96-6.13-0.99-8.47,0.48C328.58,43.73,330.58,42.56,332.98,42.72z"/>
    <path fill="#9E6AB3" d="M318.91,44.86c0.03,0.09,0.06,0.18,0.08,0.27c0.67,0.22,1.3,0.5,1.88,0.82
      c-0.38-0.88-1.18-1.68-1.89-2.2c-0.5-0.37-1.06-0.63-1.65-0.79C318.01,43.45,318.62,44.02,318.91,44.86z"/>
    <path fill="#9E6AB3" d="M335.78,43.99c0.19,0.37,0.29,0.74,0.32,1.11c0.97,0.38,1.77,0.81,2.54,1.36
      c-0.48-0.8-1.03-1.49-1.86-2.1c-0.41-0.3-0.84-0.59-1.3-0.84C335.59,43.66,335.7,43.82,335.78,43.99z"/>
    <path fill="#8FCF5C" d="M330.42,46.91c0.51,0.85,1.87,0.88,2.44,0.21c0.45-0.53,0.59-1.42-0.02-2.06
      c0.34,0.04,0.7,0.13,0.95,0.18c0.25,0.46,0.29,1.17,0.04,1.76c-0.31,0.73-0.79,1.23-1.59,1.45c-0.79,0.22-1.82-0.05-2.42-0.61
      c-0.94-0.89-0.55-2.24-0.11-2.77c0.4-0.1,0.73-0.15,1.04-0.15C330.15,45.42,330.08,46.34,330.42,46.91z"/>
    <path fill="#70A345" d="M313.16,46.22c0.05-0.38,0.23-0.72,0.53-0.9c-0.39,0.04-0.83,0.09-1.24,0.27
      c-0.16,0.23-0.23,0.51-0.25,0.78C312.5,46.29,312.83,46.24,313.16,46.22z"/>
    <path fill="#70A345" d="M316.15,46.51c0.25,0.06,0.5,0.13,0.75,0.21c0.09-0.4,0.11-0.99-0.12-1.34
      c-0.32-0.04-0.67-0.09-0.99-0.11C316.07,45.54,316.19,46.03,316.15,46.51z"/>
    <path fill="#BDE3AB" d="M315.51,47.64c-0.27,0.16-0.56,0.24-0.84,0.25c0,0.29-0.01,0.58-0.03,0.87
      c0.19-0.02,0.39-0.06,0.57-0.13c0.26-0.09,0.47-0.21,0.67-0.35C315.75,48.07,315.63,47.86,315.51,47.64z"/>
    <path fill="#BDE3AB" d="M332.7,47.27c-0.32,0.25-0.77,0.35-1.2,0.29c0,0.32,0.01,0.64,0.02,0.96c0.25,0.01,0.5,0,0.73-0.07
      c0.42-0.11,0.74-0.31,1-0.57C333.07,47.68,332.88,47.48,332.7,47.27z"/>
    <path fill="#70A345" d="M333.2,46.46c0.07,0.03,0.15,0.05,0.22,0.08c0.16,0.06,0.33,0.13,0.5,0.2
      c0.14-0.52,0.09-1.1-0.13-1.49c-0.25-0.06-0.62-0.15-0.95-0.18C333.24,45.48,333.32,46,333.2,46.46z"/>
    <path fill="#70A345" d="M330.23,45.98c0.03-0.4,0.2-0.79,0.53-1.07c-0.32,0-0.64,0.05-1.04,0.15
      c-0.18,0.22-0.35,0.58-0.43,0.99C329.6,46.03,329.92,46,330.23,45.98z"/>
    <path fill="#FFFFFF" d="M331.78,49.34c-2.23-0.04-4.18-1.09-5.87-2.47c1.04-0.9,2.11-1.47,3.36-1.74
      c-0.51,0.68-0.59,1.62-0.18,2.46c0.5,1.03,1.82,1.49,2.85,1.38c1.34-0.14,2.07-0.82,2.38-1.78c0.19-0.57,0.18-1.34,0.06-1.73
      c1.48,0.38,2.93,1.01,4.3,1.82C336.41,48.48,334.56,49.39,331.78,49.34z"/>
    <path d="M318.84,39.1c-0.27,0.01-2.49,0.74-3.78,1.23c-2.15,0.82-4.58,2.15-4.78,2.75c2.49-1.14,8.46-2.73,9.41-2.86
      C319.91,40.19,319.11,39.09,318.84,39.1z"/>
    <path d="M328.9,38.77c-0.44,0.02-0.78,0.57-0.96,1.31c2.98,0.5,8.11,2.19,9.7,3.05C336.71,40.85,329.35,38.75,328.9,38.77z"/>
    <path d="M322.76,47.63c-0.05,1.68-1.36,2.46-2.35,3.82c-0.57,0.78-0.25,1.31,0.33,1.71c0.98,0.67,1.69,0.57,1.59,0.51
      c-0.77-0.49-1.15-0.96-1.22-1.2c-0.11-0.35-0.12-0.67,0.3-1.16c0.33-0.38,0.67-0.8,0.98-1.19c0.31-0.4,0.55-0.9,0.58-1.32
      C323.03,48.1,322.76,47.57,322.76,47.63z"/>
    <path d="M328.22,57.95c-0.71,0.09-1.57,0.2-2.29,0.14c-0.6-0.05-1.19-0.34-1.55-1.26c-0.24-0.61-1.87,0.67-2.11,0.63
      c-0.25-0.03-0.51-0.89-0.98-0.83c-0.48,0.06-0.57,0.51-1.12,0.94c-0.63,0.49-1.43,0.47-2.28,0.18c-0.04-0.01-0.21,0.28-0.04,0.36
      c1.08,0.5,1.45,2.75,2.78,3.72c0.88,0.64,2,0.93,3.08,0.59c2.01-0.65,2.24-3.43,4.23-4.04C328.2,58.29,328.35,57.93,328.22,57.95z"
      />
    <path fill="#C94151" d="M321.32,56.94c0.26-0.03,0.44,1.01,1.06,0.95c0.42-0.04,1.13-0.72,1.6-0.78
      c0.3-0.04,0.32,0.4,0.5,0.7c0.34,0.57,1.27,0.65,2.04,0.7c-1.06,0.19-2.63,0.72-3.62,0.82c-0.92,0.09-1.79-0.22-2.59-0.51
      c-0.39-0.14-0.75-0.35-1.11-0.54C320.26,58.31,321.05,56.97,321.32,56.94z"/>
    <path fill="#9A293A" d="M323.8,57.59c-0.27,0.16-0.88,0.63-1.19,0.7c-0.44,0.1-0.65,0.1-0.83-0.1
      c-0.26-0.28-0.43-0.95-0.63-0.66c-0.37,0.52-0.78,1.05-1.39,1.06c0.18,0.09,0.36,0.18,0.56,0.25c0.8,0.29,1.67,0.6,2.59,0.51
      c0.61-0.06,1.44-0.29,2.23-0.5C323.79,58.54,324.14,57.38,323.8,57.59z"/>
    <path fill="#C94151" d="M323.71,61.56c-1.1,0.63-2.59-0.13-3.25-0.83c-0.64-0.68-0.92-1.5-1.55-2.17
      c0.26,0.15,0.56,0.27,0.79,0.36c0.73,0.32,1.56,0.59,2.39,0.7c0.82,0.11,1.68-0.1,2.44-0.3c0.61-0.16,1.25-0.29,1.87-0.45
      C325.42,59.69,324.9,60.88,323.71,61.56z"/>
    <path d="M347.52,159.11c-0.13,0.92-0.64,4.85-1.24,4.79c-0.64-0.06-0.92-0.63-1.05-1.65c-0.09-0.7,0.1-2.09,0.31-3.24
      c0,0,0.01-0.01,0.01-0.01c0.54-1.71-1.17-3.15-0.2-5.52c0.03-0.07-0.15-0.14-0.37,0.29c-0.89,1.79,0.24,3.22,0.33,4.83
      c-0.58,1.77-0.65,3.28-0.46,4.14c0.23,1.01,0.47,1.38,0.91,1.68c0.38,0.26,0.95,0.22,1.14-0.47
      C347.27,162.62,348.13,159.51,347.52,159.11z"/>
    <path fill="#EB9294" d="M323.21,60.99c0.02-0.41,0.78-1.03,1.12-0.78C324.74,60.49,323.17,61.69,323.21,60.99z"/>
    <path fill="#EB9294" d="M323.04,61.19c0.05,0.23-0.36,0.37-0.43,0.08C322.54,60.98,322.97,60.86,323.04,61.19z"/>
    <path fill="#FFFFFF" d="M315.14,45.36c-0.36,0-0.65,0.29-0.65,0.65c0,0.36,0.29,0.65,0.65,0.65c0.36,0,0.65-0.29,0.65-0.65
      C315.79,45.65,315.5,45.36,315.14,45.36z"/>
    <path fill="#FFFFFF" d="M314.46,46.46c-0.1,0-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18c0.1,0,0.18-0.08,0.18-0.18
      C314.64,46.54,314.56,46.46,314.46,46.46z"/>
    <path fill="#FFFFFF" d="M332.15,45.06c-0.36,0-0.65,0.29-0.65,0.65c0,0.36,0.29,0.65,0.65,0.65c0.36,0,0.65-0.29,0.65-0.65
      C332.8,45.35,332.51,45.06,332.15,45.06z"/>
    <path fill="#FFFFFF" d="M331.47,46.17c-0.1,0-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18c0.1,0,0.18-0.08,0.18-0.18
      C331.65,46.25,331.57,46.17,331.47,46.17z"/>
    <path d="M334.88,108.26c-3.76-1.32-7.72-1.78-11.69-1.83c-2.1-0.02-4.2,0.1-6.29,0.25c-1.95,0.14-3.91,0.05-5.76-0.86
      c-0.09,0.32,0.85,0.77,1.71,0.96c0.76,0.17,1.7,0.31,2.5,0.32c2.17,0.02,4.21-0.23,6.39-0.26c3.59-0.05,7.08,0.34,10.55,1.08
      C333.13,108.11,334.81,108.59,334.88,108.26z"/>
    <path d="M326.54,108.33c-3.02-0.16-6.17,0.41-9.2,0.24c-0.06,0,0.1,0.31,1.54,0.29c3.07-0.03,7.06-0.45,9.93-0.13
      C328.93,108.74,328.68,108.44,326.54,108.33z"/>
    <path fill="#FCE2D3" d="M306.95,54.32c0.4-1.81,3.89-3.02,5.64-1.95C314.33,53.44,306.37,56.91,306.95,54.32z"/>
    <path fill="#FCE2D3" d="M333.99,52.71c1.54-1.01,5.07-0.75,5.57,1.48C340.37,57.74,332.25,53.85,333.99,52.71z"/>
    <path d="M307.26,77.13c-1.1-1.25-1.47-2.61-1.55-4.24c-0.06-1.26,0-2.53-0.41-3.74c-0.7-2.08-2.73-3.34-4.12-4.91
      c-2.07-2.33-2.14-5.53-0.96-8.3c0.64-1.5,1.62-2.98,1.96-4.54c0.14-0.64,0.06-1.95-0.16-1.96c0.43,2.89-1.77,5.16-2.54,7.79
      c-0.75,2.55-0.32,5.25,1.37,7.31c1.48,1.82,3.79,3.01,4.32,5.47c0.31,1.43,0.12,2.91,0.38,4.35c0.1,0.59,0.31,1.13,0.61,1.63
      C306.43,76.44,307.12,77.27,307.26,77.13z"/>
    <path d="M357.21,88.99c-1.2-0.08-2.36-0.36-3.43-0.92c-1.22-0.64-1.86-1.78-2.52-2.93c-0.46-0.78-1.05-1.67-1.84-2.15
      c-1.21-0.73-2.64-0.85-3.88-1.51c-4.48-2.37-4.34-9-3.12-13.18c0.84-2.88,2.96-5.18,3.67-8.11c0.62-2.58,0.24-5.31,0.21-7.93
      c-0.04-2.83,0.74-5.22,1.94-7.75c0.78-1.65,1.58-3.26,1.91-5.07c0.16-0.88,0.2-1.98-0.32-2.57c-0.27-0.3-1.09-0.5-1.3-0.26
      c3.97-0.29-0.93,8.36-1.54,9.99c-0.9,2.42-1.13,4.81-1.04,7.35c0.11,2.97,0.2,5.84-1.14,8.58c-1.08,2.21-2.5,4.16-3.1,6.59
      c-0.55,2.22-0.62,4.73-0.24,6.98c0.33,1.93,1.22,3.67,2.71,4.95c0.8,0.68,1.75,1.12,2.74,1.41c0.69,0.21,1.37,0.38,2.02,0.71
      c0.73,0.36,1.28,1.07,1.72,1.73c0.79,1.19,1.31,2.38,2.5,3.23C354.33,88.97,356.48,89.38,357.21,88.99z"/>
    <path d="M346.87,83.89c-1.97-0.76-3.75-1.64-5.12-3.29c-1.56-1.88-2.06-4.49-2.11-6.86c-0.05-2.46,0.51-4.8,1.67-6.97
      c0.97-1.82,2.18-3.52,2.86-5.44c0.16-0.46,0.38-1.31,0.24-1.4c-0.63,2.79-2.47,5.02-3.77,7.5c-1.18,2.26-1.6,4.98-1.39,7.51
      c0.19,2.17,0.8,4.27,2.17,6c0.81,1.03,1.94,1.79,3.15,2.35C345.3,83.62,346.81,84.1,346.87,83.89z"/>
    <path d="M310.5,78.22c-1.44-1.06-2.48-2.29-2.96-4.03c-0.42-1.49-0.33-3.08-0.67-4.58c-0.65-2.85-3.92-4.16-5.39-6.46
      c-0.12,0.18,0.79,1.24,1.13,1.62c0.49,0.52,1.05,0.98,1.6,1.45c1.42,1.2,2.18,2.55,2.43,4.41c0.23,1.68,0.22,3.33,0.92,4.92
      c0.35,0.79,0.89,1.46,1.54,1.99C309.51,77.85,310.33,78.39,310.5,78.22z"/>
  </g>
  <g id="item_18_">
    <path d="M228.17,198.51c-1.97-1.43-4.04-1.36-4.84-2.03c-0.8-0.67-7.03-7.78-7.13-7.93c-0.11-0.15-0.68,0.26-1.05,0.63
      c-0.36-0.5-6.35-7.2-7.79-8.76c-1.26-1.36-2.52-2.61-4.07-3.63c-1.51-0.99-3.2-1.72-4.86-2.42c-2.35-0.99-5.27-1.74-7.79-2.61
      c-2.22-0.77-4.26-1.51-5.6-3.2c-0.88-3.12-2.53-6.59-2.82-7.6c0.38,0.28,1.3,0.4,1.66-0.36c0.25-0.52,0.91-0.68,1.44-0.86
      c0.65-0.23,1-0.01,1.58,0.25c0.37,0.17,0.81,0.3,1.22,0.12c0.65-0.28,0.62-2,1.53-1.65c0.7,0.27,1.47,0.73,2,0.02
      c0.83-1.12,0.54-2.17,0.41-2.86c-0.14-0.69-0.97-3.4-1.1-4.12c-0.26-1.37-0.07-2.54-0.06-3.93c0.02-2.6,0.06-5.11-0.55-7.65
      c-0.58-2.45-1.57-4.76-2.45-7.11c-0.87-2.33-1.66-4.7-2.31-7.11c-1.01-3.79-1.37-7.6-1.38-11.47c0.11-0.03,4.31-4.52,5.69-7.23
      c1.5-2.95,2.75-6.04,3.72-9.21c1.05-3.44,1.94-6.89,3.11-10.3c1.14-3.3,2.36-6.56,3.56-9.84c2.07-5.65,3.94-11.24,4.77-17.22
      c0.79-5.71,0.53-11.17-0.25-16.88c-1.49-10.87-6.4-19.59-10.06-22.81c-4.5-3.96-14.08-7.99-20.5-7.99c-4.69,0-8.66,1.79-11.95,5.22
      c-2.98-0.07-5.47,2.47-6.74,5.37c-0.88,2-1.41,4.18-1.72,5.76c-0.82,4.29-1.03,8.72-0.64,13.07c0.2,2.28,0.6,4.55,1.13,6.78
      c0.51,2.13,1.77,5.95,1.64,8.07c-1.73-0.03-3.8,0.07-4.62,0.21c-0.66,0.11-1.48,0.28-1.51,0.54c-0.07,0.78,0.71,1.57,3.17,1.63
      c-1.12,0.13-3.96,0.49-4.65,1.04c-0.6,0.49,0.07,1.99,1.38,1.67c1.36-0.33,2.73-0.6,4.1-0.9c-0.28,0.17-0.8,0.64-0.96,0.79
      c-0.73,0.06-1.65,0.21-2.22,0.67c-0.54,0.44-0.73,1.16-0.8,2.35c-0.09,1.49-0.26,3.83,0.24,3.78c0.79-0.07,1.15-0.4,1.5-0.97
      c0.11,0.81,0.29,1.61,0.43,2.28c0.08,0.4,0.57,2.13,1.24,2.77c-0.22,1.66-0.35,3.32-0.62,4.97c-0.29,1.83-0.66,3.65-0.97,5.48
      c-0.61,3.53-0.78,7.13-0.97,10.71c-0.08,1.48-0.33,4.11,1.11,5.11c1.49,1.02,3.71-0.33,4.8-1.59c1.4,4.13,3.12,4.28,3.89,3.75
      c0.77-0.53,1.84-1.09,2.83-1.59c-0.03,0.5,0,0.98,0,1.1c0,2.3,0.15,4.61,0.22,6.91c0.17,5.47-0.02,10.99-0.65,16.43
      c-0.6,5.21-2.04,10.08-3.9,14.96c-0.95,2.51-1.66,5.08-2.12,7.73c-0.48,2.74-0.76,5.11-1.41,7.82c-0.31,1.3-0.28,1.92,0.1,2.57
      c0.54,0.91,1.28,0.93,2.21,0.88c1.13-0.05,1.36,1.39,2.37,1.78c0.32,0.12,0.72,0.02,1.03-0.07c0.85-0.23,1.5-0.44,2.38-0.15
      c0.46,0.15,1.07,0.28,1.44,0.6c0.25,0.22,0.44,0.49,0.78,0.58c1.26,0.34,1.67-0.66,2.68-1.16c1.08-0.54,2.03,0.61,3.12,0.9
      c0.11,3.19,1.95,6.35,2.9,9.39c1.2,3.85,1.93,7.96,2.45,11.96c1.03,7.99-0.27,23.61-0.33,25.09c-0.22,0.04-1.05,0.12-1.23,0.44
      c-0.19,0.32-0.38,3.89-0.39,5.25c-0.02,2.63,0.37,5.37,0.08,7.99c-3.58,1.84-7.87,3.69-12.01,5.12c-2.02,0.7-4.03,1.43-5.94,2.39
      c-0.57,0.28-1.88,0.77-2.17,1.35c-0.31,0.63-0.47,1.84-0.14,2.18c0.02,0.06,0.28,1.34,0.34,1.55c0.38,0.57,10.73,1.75,15.92,0.85
      c2.78-0.48,12.9-2.7,13.95-4.54c0.27-0.46,0.06-2.04-0.43-2.7c0.24-2.61-0.16-5.65-0.59-9.59c-0.19-1.76,0.47-7.46,0.74-9.3
      c0.02-0.18-0.15-0.22-0.38-0.33c0.62-1.11,1.27-4.27,1.58-5.77c0.64-3.09,1.16-6.24,1.42-9.39c0.34-4.15,0.29-8.33-0.12-12.47
      c2.59,0.4,8.45,3.35,12.03,5.84c2.07,1.44,4.13,2.85,6.14,4.38c1.02,0.78,4.77,3.31,5.46,3.98c-0.23,0.2-0.84,1.11-0.68,1.29
      c1.53,1.61,2.98,3.24,4.38,4.98c1.13,1.41,2.54,2.68,3.49,4.22c0.69,1.11,0.61,2.15,0.52,3.39c-0.18,2.51-0.56,5.62-0.54,7.76
      c0.06,4.72,0.2,9.08,1.19,10.27c0.6,0.72,2.27,1.07,2.87,0.77c0.6,0.49,1.56,0.52,1.68,0.44c1.36-0.88,3.41-6.88,4.14-11.17
      c0.48-2.84,0.84-5.71,1.29-8.56c0.25-1.62,0.83-8.51,0.82-8.69C229.99,198.83,228.88,198.31,228.17,198.51z"/>
    <path fill="#F5B92E" d="M155.57,50.34c-0.56-2.13-1.01-4.29-1.29-6.48c-0.54-4.13-0.41-8.33,0.14-12.44
      c0.34-2.54,0.93-5.01,1.92-7.38c0.97-2.3,2.67-5.1,5.42-5.37c-0.54,0.59-1.49,1.91-1.21,2.12c2.27-3.17,6.62-6.55,12.26-7.1
      c5.74-0.56,16.53,4.02,20.88,7.85c4.46,3.93,10.33,16.79,10.67,28.07c0.15,5.06-0.32,10.15-1.35,15.1
      c-1.14,5.5-3.28,10.76-5.19,16.03c-1.06,2.91-2.09,5.83-3.04,8.78c-0.94,2.91-1.64,5.89-2.55,8.8c-0.91,2.93-2.13,5.75-3.52,8.49
      c-1.14,2.24-2.9,3.99-4.5,5.88c0.01-0.47,0.02-0.93,0.04-1.4c0.18-4.96,0.84-9.95,0.75-14.92c1.15,2.11,2.06,4.36,2.1,6.81
      c1.06-0.28,0.45-3.31-0.13-4.84c-0.39-1.04-0.9-2.05-1.46-3.05c-1.48-2.65-3.16-5.09-4.33-7.9c-2.64-6.3-3.47-13.12-3.61-19.9
      c-0.14-6.77,0.36-13.52,0.4-20.29c0.03-4.83-0.44-9.94-3.61-13.82c-1.86-2.27-4.47-3.48-7.13-4.56c-2.75-1.11-5.53-2.42-6.59-5.44
      c-0.63,0.41,0.32,2.36,0.99,3.12c-1.19,2.16-2.3,6.88-2.16,8.65c-0.06,0.06-0.11,0.13-0.16,0.2c-0.87,1.16-0.91,2.94-0.02,4.07
      c-0.51,1.9-0.55,3.93-0.32,6.02c0.26,2.42,1.27,4.8,3.93,10.04c-1.83,0.32-4.4,0.33-5.71,1.61c-0.24-0.06-0.42-0.08-0.67-0.11
      C156.69,55.11,155.95,51.78,155.57,50.34z"/>
    <path fill="#D49C26" d="M184.22,112.68c0.75-0.88,1.53-1.74,2.27-2.62c1.2-3.3,1.97-6.88,0.97-10.12
      c0.32,1.45,0.41,3.04-0.34,3.23c-0.04-2.46-0.95-4.7-2.1-6.81c0.09,4.97-0.57,9.96-0.75,14.92
      C184.24,111.75,184.23,112.21,184.22,112.68z"/>
    <path fill="#FADB96" d="M156.15,52.94c0.13-2.58-0.48-5.19-0.86-7.79c-0.54-3.68-0.59-6.8-0.26-10.5
      c0.31-3.57,1.13-7.8,2.18-10.27c0.72-1.7,1.82-3.89,3.84-4.83c0.23-0.34,0.5-0.67,0.71-0.89c-2.74,0.28-4.45,3.07-5.42,5.37
      c-1,2.37-1.58,4.84-1.92,7.38c-0.55,4.12-0.68,8.32-0.14,12.44c0.29,2.19,0.73,4.35,1.29,6.48
      C155.73,50.95,155.96,51.91,156.15,52.94z"/>
    <path fill="#FADB96" d="M167.31,16.38c4.17-1.93,8.13-1.5,11.12-0.85c5.73,1.25,10.79,3.54,14.55,7.4
      c2.45,2.51,5.06,7.71,6.27,10.98c1.54,4.18,2.52,7.32,3.23,11.67c0.72,4.46,1.2,12.69,0.79,17.85c0.85-4.55,1.24-9.21,1.1-13.83
      c-0.33-11.28-6.2-24.14-10.67-28.07c-4.35-3.83-15.14-8.42-20.88-7.85c-5.61,0.55-9.92,3.89-12.21,7.03
      C162.23,19.56,165.75,17.11,167.31,16.38z"/>
    <path fill="#FADB96" d="M179.11,79.25c-0.57-4.45-0.52-8.92-0.25-13.39c0.28-4.66,0.7-9.32,0.78-14
      c0.08-4.31,0-8.75-1.24-12.92c-0.59-1.99-1.43-3.87-2.66-5.56c-1.17-1.62-2.4-2.92-4.26-3.74c-1.81-0.8-3.97-1.7-5.77-2.54
      c-1.64-0.77-3.22-1.66-4.65-2.77c0.1,0.2,0.22,0.39,0.34,0.56c0.06,0.01,0.11,0.06,0.11,0.13v0.02c1.34,1.89,3.54,2.89,5.72,3.77
      c2.66,1.08,5.27,2.28,7.13,4.56c3.17,3.88,3.64,8.99,3.61,13.82c-0.04,6.76-0.54,13.52-0.4,20.29c0.12,5.7,0.73,11.43,2.49,16.86
      C179.65,82.66,179.33,80.96,179.11,79.25z"/>
    <path fill="#FADB96" d="M186.47,60.2c0.6-4.41,1.35-12.39,0.64-16.84c-0.63-3.91-2.18-8.21-5.04-11.05
      c-2.1-2.08-4.71-3.41-7.33-4.71c2.32,2.01,4.91,3.78,6.59,6.38c2.18,3.38,3.23,7.32,3.73,11.28c1.15,8.97-1.53,17.75-1.47,26.7
      c0.02,3.53,0.39,7.07,1.5,10.44c0.33,1,0.72,1.98,1.16,2.94C184.39,76.82,185.13,70.06,186.47,60.2z"/>
    <path fill="#FADB96" d="M185.73,22.19c-2.22-1.62-5.71-2.41-8.5-2.46c-2.89-0.05-5.59,0.75-6.86,2.16
      c3.65-0.75,6.93-1.12,11.79,0.46c1.69,0.55,3.04,1.68,4.35,2.86c2.73,2.49,4.93,5.52,6.54,8.84c1.62,3.35,2.74,6.94,3.4,10.6
      c0.76,4.17,0.88,8.47,0.89,12.7c0.9-6.67,1.17-13.41-0.98-19.91C194.49,31.76,190.65,25.77,185.73,22.19z"/>
    <path fill="#D49C26" d="M193.03,55.96c-0.08-1.72-0.28-3.43-0.69-5.1c-0.19-0.78-0.44-1.52-0.69-2.28
      c0.1,3.12-0.13,6.23-0.65,9.31c-0.59,3.56-1.64,7.06-2.07,10.64c-0.72,5.93,0.07,12.57,3.88,17.37
      c-2.25-6.41-1.28-13.18-0.44-19.76C192.78,62.77,193.18,59.36,193.03,55.96z"/>
    <path fill="#D49C26" d="M186.18,30.63c2,1.86,3.83,3.85,5.18,6.23c1.14,2.01,2.06,4.14,3.01,6.24
      c-2.01-12.19-11.4-21.8-20.04-20.76C178.95,24.27,181.11,25.91,186.18,30.63z"/>
    <path fill="#FADB96" d="M190.02,41.82c-0.22-0.15-0.47-0.33-0.74-0.49c2.3,4.11,4.25,8.43,4.48,13.21
      c0.26,5.38-0.09,10.76-0.74,16.09c1.49-5.2,2.56-10.68,2.4-16.1c-0.08-2.77-0.47-5.63-1.66-8.16c-0.55-1.17-1.25-2.27-2.15-3.2
      C191.13,42.67,190.6,42.21,190.02,41.82z"/>
    <path fill="#D49C26" d="M159.3,39.41c-0.89-1.12-0.86-2.91,0.02-4.07c0.05-0.07,0.11-0.13,0.16-0.2
      c-0.14-1.77,0.97-6.49,2.16-8.65c-0.31-0.35-0.67-0.95-0.92-1.53c-1.05,1.92-1.63,4.55-2.12,7.06c-0.57,2.88-1.56,7.44-1.47,10.37
      c0.25,8.41,3.45,10.72,4.06,13.32c0.6-0.08,1.19-0.15,1.72-0.24c-2.66-5.25-3.67-7.62-3.93-10.04
      C158.75,43.34,158.79,41.31,159.3,39.41z"/>
    <path fill="#F8D2C5" d="M176.71,70.72c-0.53,0.31-0.99,0.69-1.42,1.1c-1.06-0.09-2.01-0.09-3.04-0.52
      c-0.99-0.42-1.9-1.06-2.75-1.71c-0.24-0.18-0.51-0.4-0.82-0.6c0.02-0.01,0.03-0.01,0.05-0.03c1.05-0.94,2.28-1.71,3.15-2.83
      c0.79-1.01,1.08-2.33,1.34-3.56c0.33-1.53,0.77-4.32-0.28-5.86c1.04-1.52,1.83-3.88,1.33-4.04c-0.62,1.22-1.16,2.97-3.55,4.82
      c-0.8,0.62-1.75,0.98-2.74,1.17c-1,0.19-2.21,0.06-2.78-0.27c0.19-0.26,0.37-1.02,0.17-1.02c-0.55,0.01-1.1,0-1.66-0.02
      c0.04-0.02,0.09-0.03,0.13-0.05c0.96-0.42,1.42-1.89,0.92-2.26c-0.16-0.12-0.65,0.22-1.37,0.27c-3.34-6.63-4.67-10.01-3.58-15.45
      c1.03,0.58,2.41,0.53,3.25-0.35c-0.55,1.25-2.08,3.31-3.5,4.56c-0.19,0.17,2.74,2.56,3.83,1.34c-1.04-0.27-2.06-0.68-2.7-1.41
      c1.13-1.31,3.07-3.97,3.44-5.72c0.22-1.04,0.2-2.77-0.2-3.77c-0.08-0.2-0.23-0.39-0.31-0.37c0.05,0.4,0.07,0.81,0.07,1.03
      c-0.96-1.36-2.84-1.06-3.56-0.54c0.03-0.68,0.21-1.82,0.5-3.12c0.34-1.49,0.82-3.19,1.4-4.64c2.47,2.41,6.55,3.11,9.42,5.15
      c4.46,3.16,5.38,8.83,5.42,13.93C176.96,54.23,176.22,62.54,176.71,70.72z"/>
    <path fill="#DEB2A3" d="M176.89,45.99c-0.05-5.1-0.96-10.77-5.42-13.93c-2.87-2.04-6.96-2.74-9.42-5.15
      c-0.18,0.45-0.35,0.92-0.51,1.4c0.65,0.61,1.6,1.09,2.24,1.46c1.23,0.71,2.53,1.28,3.83,1.86c2.42,1.08,4.55,2.97,5.84,5.26
      c1.24,2.19,1.72,4.44,1.91,6.96c0.41,5.47,0.14,15.55,0.09,19.04c-0.06,3.9,0.17,8.66,0.26,8.58c0.31-0.27,0.65-0.52,1.01-0.73
      C176.22,62.54,176.96,54.23,176.89,45.99z"/>
    <path fill="#FBE7E3" d="M172.56,66.48c0.97-1.48,0.82-3.37,0.73-4.24c-0.02,0.12-0.05,0.23-0.07,0.34
      c-0.26,1.23-0.56,2.54-1.34,3.56c-0.87,1.12-2.11,1.89-3.15,2.83c-0.02,0.01-0.03,0.02-0.05,0.03c0.3,0.2,0.58,0.42,0.82,0.6
      C170.65,68.61,171.92,67.46,172.56,66.48z"/>
    <path fill="#DEB2A3" d="M163.33,45.5c-0.86,0.79-2.64-0.3-3.4-0.97c-0.07,0.11-0.15,0.22-0.23,0.33
      c-0.05,0.08-0.09,0.16-0.14,0.24c0.04,0.3,0.08,0.6,0.14,0.9c0.94,0.84,3.63,0.81,3.77-0.49C163.42,45.52,163.38,45.51,163.33,45.5
      z"/>
    <path fill="#DEB2A3" d="M164.77,55.05c0.21,0.16,0.25,0.51,0.15,0.9c1.89,0.24,3.55-0.37,4.26-1.41
      c0.35-0.5,1.67-4.12,0.29-4.11c-0.81,0.01-3.6-1.48-4.42-1.57c-0.24-0.03-0.47-0.05-0.71-0.09c0.14,1.71,0.1,3.64,0.04,4.39
      c0,0.01,0,0.02,0,0.03c0,0,0,0,0,0c-0.01,0.1-0.11,0.15-0.19,0.13c-0.68,0.37-0.95,1.3-0.48,1.96
      C164.26,55.17,164.63,54.95,164.77,55.05z"/>
    <path fill="#FBE7E3" d="M161.37,44.59c1.42-1.66,2.39-3.37,2.78-4.33c0.63-1.55,1.16-3.68-0.06-5.26
      c0.25,1.01,0.24,2.41,0.05,3.3c-0.37,1.74-2.31,4.41-3.44,5.72C160.9,44.24,161.12,44.43,161.37,44.59z"/>
    <path fill="#DEB2A3" d="M174.35,53.64c-0.2,0.86-0.75,2.13-1.4,3.08c0.54,0.8,0.68,1.94,0.65,3.06
      C174.68,58.03,174.78,55.7,174.35,53.64z"/>
    <path fill="#F5B92E" d="M162.27,84.18c-0.2,1.22-1.73,3.09-2.3,3.63c-0.54-1.03-1.03-2.08-1.25-3.22
      c-0.35-1.89-0.3-3.85-0.37-5.77c-0.04-1.22,0.09-3.26-0.61-4.45c0.27-0.16,0.52-0.35,0.74-0.6c0.64-0.73,0.98-1.79,1.26-2.69
      c0.44-1.43,1.22-7.61,0.81-8.93c3.87,0.89,3.85-0.94,3.37-1.38c-0.75-0.7-3.99-1.36-4.3-1.4c1.71-0.26,3.82,0.36,5.16-0.58
      c1.56,1.26,3.99,0.73,5.61-0.09c0.63-0.32,1.27-0.76,1.86-1.28c0.26,0.61,0.38,1.24,0.4,2.02c0.03,1.17-0.19,2.35-0.43,3.49
      c-0.26,1.23-0.6,2.34-1.42,3.33c-0.71,0.87-1.8,1.71-2.37,2.59c-0.78-0.49-1.68-0.82-2.44-0.24c-0.6,0.47-1.04,1.27-1.47,1.89
      c-0.56,0.79-1.08,1.61-1.47,2.5c-0.43,0.98-1.05,3.12,0.79,4.33c0,0-0.01,0-0.01,0.01C162.43,79.19,162.31,81.72,162.27,84.18z"/>
    <path fill="#D49C26" d="M159.45,71.99c-0.23,0.64-0.52,1.29-0.96,1.78c-0.22,0.25-0.47,0.44-0.74,0.6
      c0.69,1.19,0.56,3.23,0.61,4.45c0.07,1.91,0.01,3.88,0.37,5.77c0.21,1.14,0.7,2.19,1.25,3.22c0.57-0.54,2.1-2.41,2.3-3.63
      c0.01-0.62,0.02-1.24,0.06-1.85C159.64,79.93,159.41,75.82,159.45,71.99z"/>
    <path fill="#D49C26" d="M172.66,59.43c-0.02-0.77-0.15-1.41-0.4-2.02c-0.59,0.53-1.22,0.97-1.86,1.28
      c-1.62,0.82-4.05,1.34-5.61,0.09c-1.34,0.94-3.45,0.32-5.16,0.58c0.31,0.04,3.55,0.71,4.3,1.4c0.48,0.44,0.5,2.27-3.37,1.38
      c0.08,0.26,0.11,0.7,0.12,1.25c2.24,0.8,4.34-0.16,4.89-1.82c1,0.45,2.54,0.39,3.44-0.06c0.3-0.15,1.46,3.31,1.04,5.56
      c0.27-0.27,0.54-0.54,0.76-0.82c0.81-0.99,1.15-2.1,1.42-3.33C172.47,61.78,172.69,60.6,172.66,59.43z"/>
    <path fill="#F8D2C5" d="M163.05,91.5c-0.33,0.22-0.66,0.43-0.97,0.66c-0.25-0.83-1.54-3.32-1.79-3.78
      c0.65-0.32,1.88-2.25,2.05-2.58C162.44,87.71,162.7,89.63,163.05,91.5z"/>
    <path fill="#F8D2C5" d="M160.43,57.68c1.25-0.09,4.29,0.07,4.52,0.09c0.23,0.01-0.3,0.96-1.21,0.99
      c-1.43,0.05-4.17-0.21-5.32,0.54c-0.29,0.01-1.2,0.19-1.37,0.25c-0.03-0.03-0.69-0.16-1.05-0.21
      C157.29,59.01,159.17,57.76,160.43,57.68z"/>
    <path fill="#DB757B" d="M164.79,58.22c0.2-0.23,0.26-0.46,0.15-0.46c-0.1-0.01-0.78-0.04-1.61-0.07
      C163.62,58.32,164.2,58.32,164.79,58.22z"/>
    <path fill="#DEB2A3" d="M157.55,58.73c-0.55,0.25-1.09,0.49-1.55,0.6c0.36,0.05,1.02,0.17,1.05,0.21
      c0.17-0.05,1.08-0.24,1.37-0.25c0.08-0.05,0.16-0.09,0.25-0.13C158.26,59.09,157.87,58.96,157.55,58.73z"/>
    <path fill="#FBE7E3" d="M161.71,57.86c1,0.04,2.26,0.09,3.26,0.08c0.04-0.1,0.03-0.17-0.03-0.17
      c-0.23-0.01-3.26-0.17-4.52-0.09c-0.36,0.03-0.78,0.15-1.21,0.32C160.22,57.8,160.79,57.83,161.71,57.86z"/>
    <path fill="#E9AAB0" d="M163.47,57.92c0.52,0.01,1.04,0.02,1.5,0.02c0.04-0.1,0.03-0.17-0.03-0.17
      c-0.1-0.01-0.78-0.04-1.61-0.07C163.37,57.78,163.42,57.85,163.47,57.92z"/>
    <path fill="#F8D2C5" d="M157.6,57.18c1.06-0.77,2.97-0.91,4.74-1.22c0.73-0.12,1.74-0.38,2.06-0.47
      c0.32-0.09,0.07,1.2-0.67,1.38c-0.77,0.19-2.26,0.24-2.92,0.45c-0.22,0.01-0.44,0.03-0.67,0.05c-0.55,0.06-1.07,0.22-1.58,0.41
      C158.53,57.39,158.16,57.23,157.6,57.18z"/>
    <path fill="#DB757B" d="M162.75,56.1C162.75,56.1,162.76,56.1,162.75,56.1c0.44,0.36,1.24,0.12,1.76-0.19
      c0.06-0.26,0.02-0.45-0.11-0.42c-0.28,0.08-1.1,0.28-1.79,0.42C162.64,55.98,162.7,56.05,162.75,56.1z"/>
    <path fill="#FBE7E3" d="M160.62,56.51c0.67-0.12,3.12-0.48,3.92-0.86c-0.01-0.12-0.05-0.18-0.14-0.16
      c-0.32,0.09-1.33,0.34-2.06,0.47c-1.77,0.3-3.68,0.45-4.74,1.22c0.17,0.02,0.32,0.04,0.45,0.08
      C158.56,56.84,159.79,56.66,160.62,56.51z"/>
    <path fill="#E9AAB0" d="M162.75,56.1C162.75,56.1,162.76,56.1,162.75,56.1c0.02,0.01,0.03,0.02,0.05,0.03
      c0.71-0.14,1.39-0.31,1.74-0.47c-0.01-0.12-0.05-0.18-0.14-0.16c-0.28,0.08-1.1,0.28-1.79,0.42
      C162.64,55.98,162.7,56.05,162.75,56.1z"/>
    <path fill="#8AB02B" d="M164.56,77.94c0.98,0.72,2.24,1.24,3.1,1.62c0.27,0.12,2.47,0.94,3.45,0.78
      c-0.11,0.31-0.22,0.61-0.33,0.91c-0.82,2.16-2.05,4.09-3.44,5.92c-0.97,1.28-2.07,2.5-3.29,3.56c-0.15,0.13-0.31,0.26-0.47,0.38
      c-0.01-2-0.3-4.02-0.38-6.02c-0.02-0.41-0.03-0.84-0.04-1.27c0-0.04,0-0.07,0-0.11C163.15,81.71,163.36,79.53,164.56,77.94z"/>
    <path fill="#70941C" d="M167.69,81.28c0.78,0.39,1.73,0.79,2.68,0.95c0.14-0.32,0.28-0.65,0.41-0.98
      c0.11-0.29,0.22-0.6,0.33-0.91c-0.97,0.16-3.18-0.67-3.45-0.78c-0.87-0.38-2.13-0.9-3.1-1.62c-0.11,0.15-0.21,0.31-0.31,0.47
      C164.97,79.69,166.45,80.66,167.69,81.28z"/>
    <path fill="#E8631C" d="M167.28,78.51c-1.13-0.5-2.47-0.83-3.42-2.02c-0.95-1.18-0.47-2.84,0.31-4.16
      c0.01,1.61,0.72,3.03,2.24,3.82c0.85,0.45,1.83,0.65,2.76,0.89c0.97,0.26,1.83,0.61,2.72,1.02c-0.16,0.47-0.32,0.94-0.48,1.41
      C169.99,79.73,167.65,78.67,167.28,78.51z"/>
    <path fill="#F0B31C" d="M167.66,76.17c-0.89-0.25-1.75-0.61-2.36-1.32c-0.73-0.85-0.77-1.97-0.8-3.04c0,0,0-0.01,0.01-0.01
      c0.34-0.52,0.72-1.03,1.1-1.52c-0.1,1.21,1,2.22,2.14,2.8c1.03,0.52,2.17,0.82,3.29,1.08c0.69,0.16,1.52,0.41,2.28,0.45
      c-0.48,0.93-0.87,1.92-1.23,2.91C170.81,76.73,169.05,76.56,167.66,76.17z"/>
    <path fill="#E8631C" d="M171.78,73.84c-0.91-0.16-1.81-0.38-2.7-0.67c-0.86-0.28-1.71-0.66-2.41-1.26
      c-0.78-0.67-0.78-1.47-0.52-2.34c0.28-0.34,0.55-0.55,1.12-0.44c0.83,0.15,1.6,0.92,2.25,1.43c0.59,0.45,1.23,0.82,1.87,1.18
      c0.94,0.53,2.07,0.87,3.18,0.88c-0.37,0.46-0.7,0.97-1,1.49C173,73.96,172.35,73.94,171.78,73.84z"/>
    <path fill="#EF9252" d="M164.54,74.17c-0.24-0.56-0.36-1.18-0.37-1.84c-0.79,1.32-1.27,2.98-0.31,4.16
      c0.06,0.07,0.11,0.14,0.17,0.2C163.98,75.8,164.2,74.97,164.54,74.17z"/>
    <path fill="#F5CC5B" d="M165.18,72.89c0.25-0.45,0.5-0.92,0.76-1.37c-0.23-0.38-0.36-0.8-0.33-1.25
      c-0.39,0.49-0.76,1-1.1,1.52c0,0-0.01,0.01-0.01,0.01c0.02,0.65,0.04,1.33,0.22,1.95C164.86,73.47,165.02,73.18,165.18,72.89z"/>
    <path fill="#EF9252" d="M166.2,71.1c0.22-0.33,0.48-0.74,0.87-0.89c1.07-0.42,1.65,0.67,2.48,1.1
      c0.84,0.44,1.74,0.68,2.67,0.81c-0.28-0.11-0.55-0.24-0.81-0.39c-0.64-0.36-1.29-0.73-1.87-1.18c-0.66-0.5-1.42-1.28-2.25-1.43
      c-0.57-0.1-0.85,0.11-1.12,0.44c-0.18,0.58-0.23,1.13-0.02,1.63C166.16,71.17,166.18,71.14,166.2,71.1z"/>
    <path fill="#FFFFFF" d="M159.65,35.86c0.52-0.87,1.76-1.29,2.67-0.98c1.07,0.37,1.5,1.4,1.27,2.74
      c-0.21,1.24-1.43,2.22-2.93,1.77C159.15,38.93,158.9,37.11,159.65,35.86z"/>
    <path fill="#F8D2C5" d="M150.38,58.09c-0.03-0.33,1.92-0.56,4.27-0.54c2.35,0.02,3.49-0.07,3.51,0.43
      c-0.79,0.34-1.98,0.98-2.84,1.2c0.57-0.29,0.83-0.72,0.51-0.6C154.13,59.22,150.48,59.05,150.38,58.09z"/>
    <path fill="#DB757B" d="M152.37,57.62c-1.21,0.09-2.01,0.26-1.99,0.47c0.01,0.07,0.04,0.14,0.08,0.2
      C151.13,58.3,152.09,58.25,152.37,57.62z"/>
    <path fill="#FBE7E3" d="M154.5,57.79c1.5-0.03,3.39-0.05,3.43,0.29c0.08-0.04,0.16-0.07,0.23-0.1
      c-0.02-0.5-1.16-0.41-3.51-0.43c-2.35-0.02-4.3,0.21-4.27,0.54c0,0.02,0.01,0.05,0.02,0.07C151.48,57.84,152.73,57.83,154.5,57.79z
      "/>
    <path fill="#E9AAB0" d="M152.2,57.87c0.07-0.07,0.13-0.15,0.18-0.25c-1.21,0.09-2.01,0.26-1.99,0.47
      c0,0.02,0.01,0.05,0.02,0.07C150.94,58,151.53,57.92,152.2,57.87z"/>
    <path fill="#F8D2C5" d="M149.6,61.72c-0.71,0.09-1.52-0.71-0.82-1.04c1.64-0.77,6.33-1.21,7.34-0.74
      c-0.57,0.28-1.21,0.66-1.76,0.99c0,0,0,0-0.01,0C152.78,61.03,150.31,61.63,149.6,61.72z"/>
    <path fill="#DB757B" d="M150,60.95c0.25-0.22,0.24-0.45,0.03-0.68c-0.5,0.12-0.93,0.26-1.25,0.41
      c-0.42,0.2-0.3,0.56,0.03,0.81C149.27,61.35,149.62,61.29,150,60.95z"/>
    <path fill="#FBE7E3" d="M155.63,60.19c0.17-0.09,0.33-0.18,0.49-0.26c-1.01-0.47-5.7-0.03-7.34,0.74
      c-0.22,0.1-0.29,0.26-0.26,0.41C149.86,60.28,154.6,59.66,155.63,60.19z"/>
    <path fill="#E9AAB0" d="M150.03,60.27c-0.5,0.12-0.93,0.26-1.25,0.41c-0.22,0.1-0.29,0.26-0.26,0.41
      c0.35-0.21,0.95-0.41,1.65-0.58C150.15,60.43,150.1,60.35,150.03,60.27z"/>
    <path fill="#F8D2C5" d="M159.54,59.89c0.86,0.09,3.57,0.85,4.02,1.15c0.45,0.3-0.09,1.02-1.4,0.86
      c-1.32-0.15-5.32-0.93-5.92-0.07c-1.22,0.17-1.45,0.1-2.68,0.15C156.19,60.18,157.13,59.63,159.54,59.89z"/>
    <path fill="#DB757B" d="M162.2,61.15c0.15,0.1,0.39,0.19,0.56,0.22c0.29,0.06,0.59,0.11,0.88,0.13
      c0.11-0.17,0.08-0.34-0.09-0.46c-0.22-0.14-0.95-0.39-1.75-0.62C161.86,60.74,161.96,60.99,162.2,61.15z"/>
    <path fill="#FBE7E3" d="M156.8,60.41c0.69-0.27,1.67-0.4,2.4-0.31c0.96,0.12,3.94,0.86,4.5,1.3
      c0.04-0.13-0.01-0.26-0.14-0.35c-0.45-0.3-3.16-1.06-4.02-1.15c-2.41-0.26-3.35,0.29-5.99,2.1c0.25-0.01,0.46-0.02,0.65-0.02
      C155.02,61.37,155.79,60.81,156.8,60.41z"/>
    <path fill="#E9AAB0" d="M161.86,60.7c0.84,0.24,1.59,0.5,1.85,0.7c0.04-0.13-0.01-0.26-0.14-0.35
      c-0.22-0.14-0.95-0.39-1.75-0.62C161.83,60.51,161.84,60.61,161.86,60.7z"/>
    <path fill="#F8D2C5" d="M156.39,64.1c-0.74,0.04-2.17,0.19-3.37,0.17c-0.47-0.01-0.87-0.18-1.13-0.18
      c-0.16,0-0.35,0.13-0.33,0.31c0.04,0.48,0.03,1.56,0.01,2.45c-0.02,0.89-1.29,1.74-1.32,1.31c-0.04-0.43-0.04-1.19-0.02-1.83
      c0.04-1.29-0.05-2.67,0.87-3.39c0.78-0.61,5.58-0.45,6.46-0.76c0.29-0.1-0.07-0.33-0.32-0.41c0.96-0.06,1.88,0.03,2.78,0.19
      c0.16,1.22-0.01,4.02-0.21,5.07c-0.36,1.95-0.81,4.08-1.81,5.82c-0.42,0.74-1.52,1.21-2.35,1.03c-0.87-0.19-1.05-1.45-1.13-2.16
      c-0.2-1.65,0.07-5.13,0.17-5.32c0.1-0.18,1.49-0.14,1.76-0.03c0.18,0.07,0.41,1.51,1.13,2.16c0.34,0.31,1.21,0.51,1.42,0.14
      c0.2-0.34-0.03-2.3-0.56-3.53C158.23,64.66,157.64,64.03,156.39,64.1z"/>
    <path fill="#DB757B" d="M150.22,66.37c-0.02,0.63-0.01,1.37,0.02,1.79c0.01,0.15,0.18,0.14,0.4,0.02
      C150.68,67.34,150.69,66.27,150.22,66.37z"/>
    <path fill="#DEB2A3" d="M157.64,62.12c0.86,0.16,1.69,0.4,2.44,0.8c-0.01-0.37-0.02-0.7-0.06-0.96
      c-0.9-0.16-1.82-0.26-2.78-0.19C157.45,61.83,157.75,62.01,157.64,62.12z"/>
    <path fill="#FBE7E3" d="M150.48,66.12c0.02-0.66,0.07-1.39,0.11-1.67c0.09-0.65,0.44-1.07,0.66-1.29
      c0.42-0.41,3.53-0.57,4.5-0.8c-1.75,0.09-4.13,0.15-4.66,0.56c-0.92,0.72-0.83,2.1-0.87,3.39c-0.02,0.64-0.02,1.4,0.02,1.83
      c0.01,0.11,0.09,0.13,0.22,0.1C150.47,67.96,150.46,66.99,150.48,66.12z"/>
    <path fill="#E9AAB0" d="M150.22,66.37c-0.02,0.63-0.01,1.37,0.02,1.79c0.01,0.11,0.09,0.13,0.22,0.1
      c0.01-0.26,0-1.02,0.01-1.79C150.41,66.38,150.33,66.34,150.22,66.37z"/>
    <path fill="#DEB2A3" d="M156.16,67.27c0.17,0.57,0.51,1.54,1.09,1.84c0.52,0.27,0.89,0.12,1.1-0.27
      c-0.29-0.04-0.6-0.17-0.78-0.33c-0.72-0.65-0.94-2.09-1.13-2.16c-0.27-0.1-1.66-0.15-1.76,0.03c-0.03,0.05-0.07,0.39-0.11,0.88
      C155.21,67.09,156.11,67.09,156.16,67.27z"/>
    <path fill="#F8D2C5" d="M152.13,66.97c0.04-1.19,0.06-2.13,0.37-2.27c0.3-0.14,2.9-0.17,3.96-0.26
      c1.06-0.09,1.68,0.75,1.92,1.81c0.18,0.81,0.35,1.71,0.28,2.06c-0.03,0.15-0.72,0.1-1.13-0.48c-0.41-0.58-0.61-1.7-0.8-1.82
      c-0.41-0.24-2.42-0.02-2.5,0.23c-0.14,0.47-0.73,4.17-0.44,5.96C153.08,72.61,152.04,69.73,152.13,66.97z"/>
    <path fill="#DB757B" d="M158.45,66.6c-0.55,0.35-0.39,1.13-0.18,1.75c0.21,0.06,0.38,0.03,0.39-0.04
      C158.71,68.01,158.6,67.31,158.45,66.6z"/>
    <path fill="#FBE7E3" d="M157.52,65.12c0.49,0.58,0.73,1.68,1.06,3.26c0.05-0.01,0.08-0.03,0.08-0.06
      c0.06-0.35-0.1-1.25-0.28-2.06c-0.24-1.06-0.86-1.9-1.92-1.81c0,0-0.01,0-0.01,0C156.84,64.62,157.25,64.78,157.52,65.12z"/>
    <path fill="#E9AAB0" d="M158.45,66.6c-0.09,0.06-0.17,0.13-0.22,0.21c0.12,0.45,0.23,0.97,0.35,1.56
      c0.05-0.01,0.08-0.03,0.08-0.06C158.71,68.01,158.6,67.31,158.45,66.6z"/>
    <path fill="#F8D2C5" d="M153.56,98.56c-1.05,0.33-1.56-0.24-1.82-1.18c-0.26-0.96-0.2-2.04-0.17-3.02
      c0.04-1.51,0.14-3.02,0.22-4.53c0.17-2.99,0.55-5.91,1.07-8.87c0.45-2.53,0.63-5.33,0.86-7.8c0.09,0.01,0.18-0.01,0.28-0.05
      c-0.06,6.35,0.08,15.67,1.25,20.22c0.32,1.23,0.68,2.44,1.12,3.63C155.63,97.68,154.5,98.26,153.56,98.56z"/>
    <path fill="#DEB2A3" d="M153.48,81.53c-0.15,2.48-0.19,4.98,0,7.46c0.2,2.51,0.5,5.05,1.18,7.49
      c0.13,0.47,0.27,0.94,0.42,1.41c0.47-0.27,0.92-0.58,1.28-0.92c-0.44-1.19-0.8-2.4-1.12-3.63c-1.17-4.55-1.3-13.88-1.25-20.22
      c-0.1,0.05-0.19,0.06-0.28,0.05c-0.04,0.43-0.08,0.88-0.12,1.33C153.54,76.85,153.63,79.19,153.48,81.53z"/>
    <path fill="#F8D2C5" d="M160.65,100.29c-0.81,0.56-1.44,0.2-1.99-0.66c-1.72-2.7-2.53-6.89-3.12-9.6
      c-0.57-2.65-0.57-5.29-0.68-7.99c-0.11-2.6,0.06-5.23-0.18-7.83c0.65,0.69,1.5,0.71,2.42,0.43c0.04,0.52,0.18,1.05,0.24,1.55
      c0.12,0.98,0.12,1.97,0.14,2.96c0.02,1.75-0.01,3.5,0.19,5.23c0.41,3.54,3.84,7.62,4.45,11.12c0.59-0.44,0.18-2.14,0.13-2.5
      c1.79-0.97,3.62-2.68,4.81-3.99c1.63-1.81,3-3.82,4.02-6.04c1.75-3.79,2.29-8.61,5.65-11.35c0.15,1.96,0.42,4.28,0.75,6.23
      c-0.42,2.21-1.84,5.48-2.33,6.72c-0.99,2.5-2.17,4.94-3.65,7.19c-1.52,2.3-3.42,4.14-5.78,5.56
      C164.07,98.34,161.46,99.74,160.65,100.29z"/>
    <path fill="#FBE7E3" d="M172.06,82.85c1-2.16,2.27-7.88,4.79-10.05c-0.04-0.4-0.07-0.79-0.1-1.16
      c-3.35,2.74-3.89,7.56-5.65,11.35c-1.03,2.22-2.39,4.23-4.02,6.04c-1.19,1.31-3.02,3.02-4.81,3.99c0.01,0.08,0.04,0.22,0.07,0.4
      C164.27,92.7,169.2,89.03,172.06,82.85z"/>
    <path fill="#FBE7E3" d="M162.06,95.16c-0.62-2.78-2.86-5.88-3.89-8.78c0.27,1.5,0.54,2.21,1.09,3.61
      C159.85,91.51,161.08,93.92,162.06,95.16z"/>
    <path fill="#8AB02B" d="M157.36,148.09c0.46-2.86,1.38-5.46,2.43-8.15c0.84-2.15,1.79-4.24,2.46-6.45
      c0.77-2.52,1.23-5.12,1.59-7.72c0.66-4.83,0.83-9.76,0.77-14.63c-0.03-2.71-0.21-5.42-0.37-8.12c-0.05-0.81-0.07-1.62-0.15-2.42
      c-0.01-0.14,0.02-0.76-0.03-1.19c0.92-0.46,1.83-0.94,2.71-1.46c2.24-1.32,4.01-3.19,5.49-5.3c1.59-2.28,2.8-4.83,3.83-7.4
      c0.7-1.75,1.4-3.53,1.79-5.36c0.45,2.11,1.03,4.22,1.78,6.3c1.02,2.81,2.43,5.35,4,7.89c0.21,0.34,0.42,0.68,0.62,1.02
      c-0.3,5.25-0.78,10.48-1.12,15.72c-0.32,5.01-0.13,9.85,1.05,14.74c1.15,4.75,3.3,9.14,4.62,13.82c0.71,2.52,0.92,5.06,0.95,7.67
      c0.01,0.55-0.01,0.92-0.01,1.46c-2.25,0.37-4.43,1.23-6.69,1.5c-2.71,0.32-5.28-0.75-7.95-0.93c-1.36-0.09-2.68,0.24-4,0.47
      c-1.54,0.26-3.1,0.39-4.66,0.48c-3.13,0.18-6.25-0.02-9.35-0.4C157.2,149.1,157.28,148.6,157.36,148.09z"/>
    <path fill="#B1CC70" d="M162.25,137.94c0.75-1.99,1.48-4.38,1.89-6.48c1.18-6.06,1.07-11.51,0.47-17.62
      c-0.03,3.99-0.24,7.99-0.77,11.93c-0.36,2.61-0.82,5.2-1.59,7.72c-0.67,2.22-1.62,4.3-2.46,6.45c-1.05,2.69-1.97,5.29-2.43,8.15
      c-0.08,0.51-0.16,1.01-0.24,1.52c0.48,0.06,0.95,0.11,1.43,0.16C159.29,143.97,161.46,140.02,162.25,137.94z"/>
    <path fill="#B1CC70" d="M184.25,128.44c0.51,2.13,1.76,6.29,2.89,9.81c1.13,3.52,1.32,6.66,1.42,10.49
      c0.4-0.09,0.8-0.18,1.21-0.24c-0.01-0.55,0.01-0.92,0.01-1.46c-0.03-2.6-0.24-5.15-0.95-7.67c-1.32-4.68-3.48-9.07-4.62-13.82
      c-0.35-1.43-0.6-2.86-0.79-4.29C183.25,123.73,183.63,125.85,184.25,128.44z"/>
    <path fill="#70941C" d="M164.09,100.6c0.03,0.27,0.04,0.54,0.06,0.82c6.35-2.71,10.94-7.18,13.44-13.52
      c0.04-0.11,2.01,6.04,6.5,10.19c0.07-1,0.13-2,0.19-3c-0.2-0.34-0.41-0.69-0.62-1.02c-1.56-2.54-2.98-5.07-4-7.89
      c-0.76-2.08-1.34-4.18-1.78-6.3c-0.39,1.84-1.09,3.61-1.79,5.36c-1.04,2.57-2.24,5.12-3.83,7.4c-1.48,2.12-3.25,3.98-5.49,5.3
      c-0.88,0.52-1.8,1-2.71,1.46C164.11,99.84,164.07,100.45,164.09,100.6z"/>
    <path fill="#E8631C" d="M157.06,149.96c2.72,0.35,5.45,0.51,8.19,0.47c1.56-0.02,3.12-0.14,4.68-0.32
      c1.62-0.18,3.23-0.59,4.87-0.56c2.57,0.04,4.78,0.99,7.37,0.97c2.48-0.02,5.19-1.09,7.64-1.57c0,0.17,0.02,0.67,0.02,0.83
      c-2.53,0.13-4.9,0.95-7.4,1.35c-2.79,0.45-5.46-0.69-8.24-0.41c-1.38,0.14-2.71,0.7-4.06,0.96c-1.6,0.31-3.24,0.4-4.88,0.36
      c-2.83-0.06-5.57-0.69-8.36-1.1C156.95,150.62,157,150.29,157.06,149.96z"/>
    <path fill="#F0B31C" d="M156.82,151.32c2.75,0.57,5.59,1.02,8.39,1.15c2.88,0.14,5.5-0.45,8.28-1.12
      c2.83-0.68,5.73,0.7,8.6,0.38c2.64-0.3,5.11-1.26,7.77-1.41c0.02,0.25,0.04,0.51,0.07,0.76c0.08,0.65,0.25,1.28,0.44,1.9
      c-0.95,0.45-2.21,0.19-3.21,0.14c-1.4-0.07-2.78,0.28-4.12,0.63c-1.54,0.4-3.07,0.83-4.68,0.71c-1.44-0.11-2.66-1.1-4.06-1.41
      c-1.24-0.27-2.5,0.22-3.64,0.64c-1.65,0.62-3.33,0.8-5.09,0.79c-3.11-0.02-6.08-0.84-9.1-1.47
      C156.61,152.45,156.72,151.88,156.82,151.32z"/>
    <path fill="#E8631C" d="M156.38,153.37c2.84,0.82,5.82,1.35,8.76,1.51c1.53,0.08,3.06-0.03,4.56-0.36
      c1.69-0.37,3.02-1.29,4.82-0.87c1.31,0.3,2.38,1.17,3.74,1.36c1.49,0.21,3.04-0.22,4.47-0.59c1.65-0.42,3.24-0.79,4.96-0.67
      c0.97,0.07,1.99,0.21,2.82-0.29c0.06,0.21,0.13,0.41,0.19,0.62c-1.18,0.84-2.39,1.18-3.87,1.18c-1.4,0-2.74-0.04-4.13,0.2
      c-1.59,0.28-3.1,0.66-4.73,0.4c-1.49-0.23-2.87-0.7-4.39-0.77c-3.07-0.14-5.87,1.58-8.94,0.95c-1.35-0.28-2.64-0.77-4.01-0.92
      c-1.52-0.17-3.06-0.31-4.5-0.85C156.23,153.97,156.31,153.67,156.38,153.37z"/>
    <path fill="#8AB02B" d="M172.16,160.21c-0.12,0-0.26-0.02-0.43-0.07c-0.91-0.26-1.55-0.96-2.53-1.03
      c-1.31-0.1-1.88,1.46-3.08,1.37c-0.52-0.04-0.77-0.64-1.21-0.83c-0.41-0.18-0.97-0.34-1.42-0.46c-0.48-0.13-0.97-0.17-1.46-0.12
      c-0.73,0.07-1.09,0.66-1.95,0.33c-0.82-0.32-0.82-1.67-1.82-1.7c-0.68-0.03-1.37,0.19-1.94-0.27c-0.68-0.55-0.77-1.36-0.3-2.74
      c1.06,0.52,2.33,0.68,3.49,0.81c1.74,0.19,3.33,0.62,5.02,1.01c3.09,0.72,5.97-0.97,9.07-0.92c1.63,0.03,3.11,0.66,4.7,0.82
      c1.55,0.15,3.02-0.19,4.53-0.47c1.64-0.31,3.24-0.11,4.89-0.16c1.18-0.03,2.21-0.46,3.15-1.11c0.05,0.19,0.54,1.65,0.53,2.01
      c-0.02,0.6-0.11,1.83-1.11,1.5c-0.31-0.1-0.65-0.36-0.99-0.33c-0.69,0.06-0.97,0.95-1.21,1.49c-0.35,0.77-1.11,0.06-1.58-0.17
      c-0.4-0.19-0.7-0.07-1.12,0.03c-0.7,0.17-1.53,0.47-1.93,1.11c-0.73,1.13-1.71-0.49-2.44-0.64c-0.82-0.17-1.67,0.13-2.36,0.55
      c-1.29,0.78-2.22,0.06-3.02-1.04c-0.43-0.58-1.06-0.69-1.72-0.47C173.01,158.99,173.12,160.22,172.16,160.21
      C172.16,160.21,172.16,160.21,172.16,160.21z"/>
    <path fill="#EF9252" d="M158.39,150.62c0.03-0.16,0.06-0.33,0.09-0.49c-0.47-0.05-0.95-0.1-1.42-0.16
      c-0.05,0.33-0.11,0.66-0.17,0.99c0.47,0.07,0.94,0.14,1.41,0.22C158.33,150.99,158.36,150.81,158.39,150.62z"/>
    <path fill="#EF9252" d="M188.77,149.88c0.35-0.04,0.71-0.08,1.06-0.09c-0.01-0.17-0.02-0.67-0.02-0.83
      c-0.38,0.07-0.77,0.16-1.16,0.26C188.68,149.44,188.73,149.66,188.77,149.88z"/>
    <path fill="#F5CC5B" d="M158.23,151.6c-0.47-0.09-0.94-0.18-1.41-0.28c-0.11,0.56-0.22,1.13-0.35,1.69
      c0.51,0.11,1.01,0.22,1.52,0.33C158.06,152.75,158.14,152.18,158.23,151.6z"/>
    <path fill="#F5CC5B" d="M189.45,153.22c0.32-0.03,0.64-0.1,0.92-0.24c-0.19-0.62-0.36-1.25-0.44-1.9
      c-0.03-0.25-0.05-0.51-0.07-0.76c-0.34,0.02-0.67,0.05-1,0.09C189.03,151.35,189.23,152.29,189.45,153.22z"/>
    <path fill="#EF9252" d="M157.94,153.79c-0.52-0.13-1.04-0.26-1.55-0.41c-0.07,0.3-0.15,0.6-0.24,0.89
      c0.55,0.21,1.12,0.36,1.69,0.47C157.87,154.42,157.9,154.1,157.94,153.79z"/>
    <path fill="#EF9252" d="M189.79,154.63c0.31-0.15,0.61-0.33,0.92-0.55c-0.06-0.21-0.13-0.41-0.19-0.62
      c-0.29,0.18-0.61,0.27-0.94,0.32C189.64,154.06,189.72,154.34,189.79,154.63z"/>
    <path fill="#B1CC70" d="M190.82,158.18c0.5-0.19,0.57-1.04,0.58-1.52c0.01-0.36-0.48-1.82-0.53-2.01
      c-0.3,0.21-0.62,0.39-0.94,0.55C190.21,156.2,190.5,157.19,190.82,158.18z"/>
    <path fill="#B1CC70" d="M157.67,156.61c0.03-0.45,0.07-0.91,0.11-1.36c-0.62-0.12-1.22-0.29-1.77-0.56
      c-0.47,1.38-0.38,2.19,0.3,2.74c0.39,0.32,0.83,0.32,1.29,0.29C157.63,157.35,157.65,156.98,157.67,156.61z"/>
    <path fill="#96543B" d="M155.51,231.58c-0.22-0.23-0.46-1.48,0.57-2c0.75-0.38,1.79-0.88,3.58-1.62
      c1.75-0.72,3.44-1.35,5.23-1.97c3.75-1.29,8.13-3.39,11.13-4.79c0.28-2.51-0.05-5.18-0.13-7.7c-0.04-1.37-0.04-4.22,0.17-5.38
      c1.57-0.59,6.01-0.59,7.79,0.39c-0.06,0.45-1.03,6.98-0.55,9.91c0.59,3.63,0.78,6.66,0.38,8.73c-0.92,1.32-3.34,2.25-4.78,2.66
      c-2.03,0.59-4.77,1.21-6.86,1.57c-2.47,0.43-4.91,0.85-7.43,0.89C161.27,232.33,155.73,231.82,155.51,231.58z"/>
    <path fill="#9E948E" d="M184.16,227.83c0.41,0.38,0.35,1.39,0.19,1.73c-0.16,0.34-3.51,1.73-5.48,2.21
      c-2.72,0.67-5.67,1.54-8.4,1.91c-3.48,0.47-7.02,0.42-10.48,0.01c-0.83-0.1-4.34-0.6-4.42-0.7c-0.08-0.11-0.19-0.46-0.17-0.88
      c3.45,0.68,7.15,0.82,10.64,0.72c2.39-0.07,5.23-0.43,7.56-0.94c2.43-0.54,5.16-1.21,7.32-1.97
      C183.3,229.07,183.84,228.09,184.16,227.83z"/>
    <path fill="#F8D2C5" d="M186.16,191.62c-0.19,2.91-1.39,12.72-2.79,15.8c-1.9-0.48-4.62-0.5-5.69-0.45
      c0.27-5.51,1.41-16.53,0.49-24.62c-0.43-3.75-1.21-7.45-2.16-11.1c-0.94-3.61-2.95-6.76-3.6-10.5c0.36-0.03,0.71-0.23,1.01-0.72
      c0.41-0.66,1.24-1.46,1.86-0.42c0.18,0.3,0.34,0.55,0.59,0.79c0.51,0.48,1.61,0.98,2.32,0.69c0.93-0.38,1.88-1.31,3.04-0.75
      c0,1.23,1.86,5.39,2.3,6.74c0.86,2.62,1.4,5.26,1.85,7.97C186.31,180.49,186.52,186.12,186.16,191.62z"/>
    <path fill="#DEB2A3" d="M177.21,164.04c0.89,0.32,1.96,0.47,2.87,0.11c0.72-0.29,1.27-0.71,1.9-1.13
      c-0.41-1.13-0.74-2.16-0.75-2.68c-1.15-0.57-2.11,0.37-3.04,0.75c-0.71,0.29-1.81-0.21-2.32-0.69c-0.25-0.24-0.42-0.49-0.59-0.79
      c-0.62-1.05-1.45-0.24-1.86,0.42c-0.31,0.49-0.65,0.69-1.01,0.72c0.11,0.64,0.27,1.27,0.45,1.88
      C174.08,161.66,175.89,163.57,177.21,164.04z"/>
    <path fill="#F8D2C5" d="M205.95,189.35c-2.15-1.62-4.38-3.1-6.6-4.61c-1.96-1.33-4.32-2.54-6.42-3.63
      c-1.81-0.94-5.77-2.13-5.82-2.11c-0.12-1.14-1.07-6.93-1.51-8.66c0.94,0.93,2.35,1.61,3.53,2.02c2.02,0.69,5.2,1.55,7.72,2.47
      c1.7,0.61,3.38,1.45,4.95,2.33c1.87,1.06,3.32,2.49,4.76,4.05c1.54,1.67,7.04,7.5,8.11,8.74c-0.89,1.03-2.2,2.58-2.99,3.54
      C211.18,192.94,207.25,190.33,205.95,189.35z"/>
    <path fill="#FBE7E3" d="M194.28,175.13c2.93,0.93,6.1,2.34,8.89,4.39c2.56,1.89,8.74,8.59,11.2,10.78
      c0.11-0.12,0.21-0.24,0.3-0.35c-1.07-1.24-6.56-7.07-8.11-8.74c-1.44-1.56-2.89-2.99-4.76-4.05c-1.57-0.89-3.25-1.72-4.95-2.33
      c-2.52-0.91-5.71-1.78-7.72-2.47c-0.63-0.21-1.32-0.51-1.97-0.88C188.27,173.18,192.15,174.46,194.28,175.13z"/>
    <path fill="#DEB2A3" d="M185.78,171.16c0.48,2.28,1.22,6.85,1.33,7.85c0.02-0.01,0.71,0.19,1.65,0.5
      C188.47,177.53,187.34,173.67,185.78,171.16z"/>
    <path fill="#96543B" d="M221.96,225.94c-0.83,0.4-1.83,0.04-2.15-0.79c-0.6-1.55-0.78-6.15-0.66-10.09
      c0.12-3.94,0.45-7.2,0.45-8.74c0-1.08-0.49-1.98-1.12-2.82c-1.1-1.46-2.46-2.77-3.62-4.19c-1.15-1.4-2.32-2.93-3.73-4.09
      c0.09-0.11,3.84-4,4.81-5.7c1.3,2.06,5.09,6.05,6.2,7.02c1.95,1.7,3.18,0.96,5.59,2.77c-0.25,2.15-1.9,11.52-2.32,13.98
      C224.67,217.53,223.97,222.08,221.96,225.94z"/>
    <path fill="#AD7961" d="M156.75,230.07c0.79-0.47,4.82-1.79,5.8-2.17c2.18-0.84,4.67-1.66,6.83-2.55
      c1.9-0.78,7.3-3.04,7.64-3.79c0.42-0.92-0.92-11.06,0.03-12.73c0.23-0.4,5.17-0.28,6.68,0.55c0.06-0.47,0.11-0.79,0.12-0.87
      c-1.78-0.99-6.22-0.98-7.79-0.39c-0.22,1.16-0.22,4.01-0.17,5.38c0.08,2.52,0.41,5.19,0.13,7.7c-3,1.41-7.38,3.5-11.13,4.79
      c-1.79,0.61-3.48,1.25-5.23,1.97c-1.79,0.74-2.83,1.24-3.58,1.62c-1.03,0.52-0.78,1.77-0.57,2c0.04,0.05,0.3,0.11,0.72,0.17
      C156.04,231.16,156.03,230.5,156.75,230.07z"/>
    <path fill="#AD7961" d="M215.98,191.41c0.02-0.05,0.13-0.02,0.17,0.02c0.54,0.67,2.79,3.17,3.35,3.79
      c1.1,1.22,2.06,2.55,3.72,2.99c1.47,0.39,3.06,0.82,4.45,1.54c0.02-0.17,0.04-0.32,0.06-0.44c-2.41-1.82-3.64-1.07-5.59-2.77
      c-1.11-0.97-4.9-4.96-6.2-7.02c-0.97,1.7-4.72,5.59-4.81,5.7c0.15,0.12,0.29,0.25,0.43,0.38
      C212.63,195.17,215.57,192.5,215.98,191.41z"/>
    <path fill="#9E948E" d="M227.36,214.87c-0.58,3.73-2.54,10.66-3.81,11.76c-0.38,0.16-0.67-0.04-0.97-0.22
      c1.9-3.7,2.96-8.25,3.68-12.31c0.45-2.54,1.86-12.43,2.18-14.86c0.21-0.04,0.68,0.03,0.92,0.23
      C229.46,200.71,227.82,211.92,227.36,214.87z"/>
    <path d="M167.99,30.38c-2.16,0.62-2.09,2.5-1.93,2.35c1.79-1.68,3.71-2.02,6.52-0.27C172.34,31.42,170.08,29.78,167.99,30.38z"/>
    <path d="M162.2,31.04c1.86,0.06,2.06,1.59,1.91,1.51c-1.7-0.99-3.32-0.87-5.38,1.13C158.78,32.78,160.41,30.98,162.2,31.04z"/>
    <path d="M169.99,46.78c-0.29,0.48-1.21,0.84-2.07,0.48c-1.31-0.55-2.52-1.27-3.03-0.05c-0.26-0.6-0.67-0.4-0.96-0.18
      c-0.39,0.29-0.68,1.09-0.64,1.59c-0.25,0.04-0.6,0.08-0.45,0.18c0.44,0.3,1.4,0.49,1.94,0.56c0.12,0.82,0.18,1.98,0.1,2.8
      c-0.88-0.14-1.51,0.38-1.48,1.29c0.04,1.06,1.18,1.37,2.05,1.37c2.1,0,3.4-0.6,4.02-1.81c0.51-0.99,0.48-1.51,0.66-2.57
      C170.42,48.68,170.24,47.17,169.99,46.78z"/>
    <path fill="#FFFFFF" d="M169.8,48.85c0.07-0.27,0.11-0.82,0.07-1.13c-0.95,1.25-3.11,1.7-4.63,1.62
      c0.05,0.27,0.1,0.81,0.1,1.08C165.97,50.4,168.58,50.05,169.8,48.85z"/>
    <path fill="#FFFFFF" d="M165.4,51.21c0,0.24-0.02,0.7-0.02,0.98c1.26,0.17,3.08-0.36,4.19-1.41
      c0.06-0.24,0.11-0.67,0.12-0.86C168.46,50.84,166.87,51.32,165.4,51.21z"/>
    <path fill="#DB757B" d="M163.62,48.71c-0.03-0.53,0.2-1.25,0.56-1.49c0.32-0.21,0.54,0.07,0.73,0.91
      c0.11-0.66,0.33-1.37,1.34-1.09c0.98,0.27,2.17,1.29,3.4,0.45C167.78,49.37,164.68,48.92,163.62,48.71z"/>
    <path fill="#B8525A" d="M166.07,47.31c-0.72-0.12-0.92,0.49-1.07,1.06c-0.06,0.22-0.15,0.28-0.19,0
      c-0.09-0.55-0.38-1.2-0.68-0.68c-0.19,0.34-0.27,0.76-0.31,1.06c1.02,0.18,3.24,0.43,4.98-0.59
      C167.65,48.28,166.85,47.45,166.07,47.31z"/>
    <path fill="#DB757B" d="M168.25,53.67c-0.89,0.61-2.42,0.87-3.7,0.59c-1.07-0.23-1.08-2.07,0.26-1.82
      c0.55,0.1,1.67,0.19,2.6-0.1c0.85-0.26,1.71-0.74,2.15-1.19C169.53,52.25,169.07,53.11,168.25,53.67z"/>
    <path d="M173.94,38.87c0.29-1.02,0.38-2.49-0.16-3.56c-0.62-1.21-2.07-2.39-4.21-2.16c-1.58,0.17-2.46,1.06-3.02,2.03
      c-1.08,1.87-0.6,5.07,2.16,6.1C171.15,42.21,173.39,40.79,173.94,38.87z"/>
    <path fill="#FFFFFF" d="M169.46,40.69c-2.26-0.4-2.93-2.8-2.58-4.19c0.43-1.7,1.69-2.55,3.04-2.6
      c2.63-0.11,3.87,2.11,3.34,4.31C172.9,39.74,171.53,41.06,169.46,40.69z"/>
    <path d="M170.25,35.45c-1.06,0-1.92,0.86-1.92,1.93c0,1.06,0.86,1.93,1.92,1.93c1.06,0,1.92-0.86,1.92-1.93
      C172.17,36.32,171.31,35.45,170.25,35.45z"/>
    <path fill="#FFFFFF" d="M171,36.12c-0.39,0-0.7,0.31-0.7,0.7c0,0.39,0.31,0.7,0.7,0.7c0.39,0,0.7-0.31,0.7-0.7
      C171.7,36.43,171.38,36.12,171,36.12z"/>
    <path fill="#FFFFFF" d="M170.16,37.29c-0.13,0-0.24,0.11-0.24,0.24c0,0.13,0.11,0.24,0.24,0.24c0.13,0,0.24-0.11,0.24-0.24
      C170.4,37.4,170.29,37.29,170.16,37.29z"/>
    <path d="M162.1,35.87c-0.73,0-1.32,0.6-1.32,1.35c0,0.75,0.59,1.35,1.32,1.35c0.73,0,1.32-0.6,1.32-1.35
      C163.42,36.47,162.83,35.87,162.1,35.87z"/>
    <path fill="#FFFFFF" d="M162.6,36.31c-0.28,0-0.5,0.22-0.5,0.5c0,0.28,0.22,0.5,0.5,0.5c0.28,0,0.5-0.22,0.5-0.5
      C163.1,36.54,162.88,36.31,162.6,36.31z"/>
    <path fill="#FFFFFF" d="M162,37.15c-0.09,0-0.17,0.08-0.17,0.17c0,0.09,0.08,0.17,0.17,0.17c0.09,0,0.17-0.08,0.17-0.17
      C162.17,37.23,162.1,37.15,162,37.15z"/>
    <path d="M182.31,71.58c-0.07-3.34,0.38-6.6,0.87-9.89c1.02-6.86,1.74-13.97-0.36-20.71c-1.15-3.68-3.05-7.21-5.73-10.01
      c-1.28-1.34-2.78-2.51-4.47-3.29c-1.83-0.84-3.81-1.32-5.57-2.32c0.31,0.71,2.78,1.57,4.25,2.16c1.49,0.6,2.73,1.26,3.97,2.27
      c2.41,1.98,4.3,4.56,5.67,7.33c3.13,6.36,3.27,13.43,2.48,20.34c-0.47,4.05-1.3,8.05-1.41,12.14c-0.07,2.64,0.17,5.95,0.7,8.53
      c0.27,1.29,0.84,3.15,1.15,3.11C182.78,78.17,182.38,74.84,182.31,71.58z"/>
    <path d="M186.95,37.03c-2.22-4.69-6.28-8.29-10.99-10.23c-1.3-0.54-3.54-1.19-4-1.04c5.27,1.36,10.08,4.25,13.19,8.79
      c1.79,2.6,2.79,5.64,3.46,8.7c0.77,3.54,0.91,7.19,0.65,10.79c-0.15,2.02-0.42,4.03-0.77,6.03c-0.36,2.05-0.89,4.06-1.28,6.1
      c-0.58,3.05-0.78,6.19-0.48,9.27c0.11,1.11,0.4,3.67,0.75,3.71c-0.63-3.72-0.8-7.92-0.22-11.65c0.28-1.78,0.76-3.52,1.15-5.28
      c0.37-1.66,0.68-3.33,0.92-5.01C190.27,50.5,189.87,43.22,186.95,37.03z"/>
    <path d="M195.92,49.14c-0.6-2.6-1.63-5.46-3.37-7.51c-0.68-0.8-1.78-1.87-2.39-1.94c3.96,2.99,5.47,7.87,6,12.57
      c0.58,5.14,0.05,10.35-0.92,15.41c-0.47,2.48-1.04,4.94-1.59,7.4c-0.52,2.32-0.47,3.97-0.29,6.32c0.09,1.22,0.34,3.1,0.2,4.32
      c-0.14,1.22-0.55,2.39-1.04,3.52c-0.38,0.88-0.82,1.73-1.31,2.57c0.85-0.28,1.95-3.17,2.25-4.01c1.06-2.97,0.24-5.69,0.13-8.75
      c-0.06-1.66,0.21-3.22,0.59-4.83c0.38-1.63,0.74-3.26,1.08-4.89c0.6-2.9,1.1-5.81,1.3-8.76C196.85,56.75,196.78,52.87,195.92,49.14
      z"/>
    <path d="M197.83,62.86c0.09-0.76,0.24-1.84,0.03-2.27c-0.39,3.57-1.3,7.11-2.23,10.58c-0.48,1.81-1.02,3.83-1.06,5.82
      c-0.02,0.92,0.17,2.66,0.53,2.62c-0.7-2.93,0.21-5.94,0.95-8.77C196.72,68.26,197.49,65.55,197.83,62.86z"/>
    <path d="M199.78,51.27c-0.02-1.68-0.1-3.69-0.24-5.37c-0.22-2.55-1.21-8.82-2.39-9.45c2.14,5.16,2.4,13.56,2.31,20.34
      c-0.04,3.37-0.25,6.71-1.26,9.94c-0.69,2.2-1.66,4.4-1.97,6.7c-0.09,0.69-0.31,2.79,0.07,2.7c-0.09-3.21,1.47-6.86,2.37-9.88
      c0.93-3.12,1.13-6.44,1.16-9.68C199.84,54.8,199.8,53.03,199.78,51.27z"/>
    <path d="M158.58,66.5c-0.79,0.23-0.55,1.52-0.27,2.06c0.04,0.07,0.15,0.03,0.12-0.04c-0.23-0.49-0.54-1.68,0.18-1.91
      C158.67,66.58,158.64,66.48,158.58,66.5z"/>
    <path d="M163.81,61.5c-0.37-0.06-0.75-0.09-1.11-0.18c-0.54-0.13-0.81-0.41-0.87-0.96c-0.01-0.05-0.07-0.04-0.08,0
      c-0.24,1.08,1.37,1.14,2.04,1.25C163.86,61.62,163.88,61.51,163.81,61.5z"/>
    <path d="M164.95,58.14c-0.29,0.13-0.62,0.12-0.93,0.06c-0.39-0.08-0.6-0.27-0.71-0.63c-0.01-0.02-0.04-0.02-0.05,0
      c-0.09,0.33,0.27,0.59,0.54,0.68c0.37,0.13,0.84,0.18,1.2-0.01C165.05,58.21,165.01,58.12,164.95,58.14z"/>
    <path d="M164.64,55.81c-0.5,0.27-1.6,0.62-2,0.06c-0.02-0.03-0.06-0.01-0.05,0.03c0.25,0.72,1.68,0.29,2.12,0.01
      C164.77,55.87,164.7,55.78,164.64,55.81z"/>
    <path d="M152.29,57.45c0.08,0.83-1.45,0.73-1.98,0.79c-0.03,0-0.03,0.05,0,0.05c0.59,0.06,2.18,0.08,2.1-0.85
      C152.39,57.37,152.28,57.38,152.29,57.45z"/>
    <path d="M149.41,60.34c-0.03,0.03,0,0.09,0.05,0.07c0.18-0.08,0.35-0.15,0.54-0.08c0.29,0.12,0.11,0.42-0.05,0.56
      c-0.33,0.28-0.82,0.45-1.24,0.55c-0.05,0.01-0.04,0.09,0.02,0.08c0.36-0.06,0.69-0.2,1.01-0.37c0.21-0.11,0.52-0.3,0.53-0.57
      C150.29,60.13,149.68,60.09,149.41,60.34z"/>
    <path d="M150.6,66.64c-0.15-0.33-0.45-0.45-0.58-0.05c-0.02,0.05,0.06,0.08,0.08,0.04c0.26-0.53,0.5,0.32,0.51,0.52
      c0.04,0.45-0.04,0.89-0.01,1.34c0,0.04,0.06,0.04,0.07,0C150.76,67.92,150.85,67.18,150.6,66.64z"/>
    <path fill="#E9AAB0" d="M164.9,52.92c-0.32-0.04-0.6,0.05-0.62,0.2c-0.02,0.15,0.23,0.31,0.55,0.36
      c0.32,0.04,0.6-0.05,0.62-0.2C165.47,53.12,165.23,52.96,164.9,52.92z"/>
    <path d="M167.67,34.07c-0.32,0.12-2.28,0.48-2.28-0.88c-0.29,0-0.25,0.61,0,0.87c0.5,0.51,1.66,0.53,2.26,0.14
      C167.69,34.18,167.72,34.06,167.67,34.07z"/>
    <path d="M168.41,33.5c-0.4,0.01-0.71,0.01-1.08-0.16c-0.43-0.2-0.57-0.8-0.45-1.06c-0.29-0.06-0.37,0.4-0.21,0.75
      c0.08,0.18,0.24,0.34,0.42,0.45c0.33,0.2,1.02,0.39,1.34,0.08C168.46,33.55,168.45,33.5,168.41,33.5z"/>
    <path d="M169.86,33.25c-0.3-0.15-0.88-0.42-1.18-0.88c-0.13-0.2-0.12-0.55,0.06-0.7c-0.42-0.01-0.54,0.51-0.16,0.97
      c0.26,0.32,0.83,0.65,1.25,0.69C169.87,33.33,169.9,33.27,169.86,33.25z"/>
    <path d="M170.96,33.31c-0.18-0.19-0.52-0.51-0.63-0.76c-0.14-0.33-0.08-0.65,0.25-0.79c-0.24-0.07-0.43,0.03-0.52,0.28
      c-0.13,0.37,0.2,0.9,0.72,1.19C170.83,33.26,171,33.35,170.96,33.31z"/>
    <path d="M159.55,35.46c-0.24,0.09-0.47,0.1-0.7,0.03c-0.28-0.08-0.35-0.3-0.31-0.56c-0.24,0.05-0.17,0.46,0.05,0.63
      c0.27,0.2,0.85,0.13,0.99-0.02C159.6,35.52,159.57,35.45,159.55,35.46z"/>
    <path d="M159.9,35.22c-0.19-0.06-0.44-0.2-0.61-0.34c-0.21-0.17-0.28-0.4-0.19-0.76c-0.13-0.04-0.32,0.33-0.16,0.61
      c0.16,0.28,0.35,0.38,0.6,0.47C159.89,35.34,159.95,35.24,159.9,35.22z"/>
    <path d="M161.57,34.46c-0.29-0.02-0.63-0.06-0.85-0.33c-0.11-0.14-0.18-0.36-0.14-0.55c0.04-0.18,0.16-0.28,0.33-0.3
      c-0.3-0.26-0.69,0.25-0.46,0.75c0.24,0.52,0.67,0.53,1.11,0.51C161.61,34.53,161.61,34.46,161.57,34.46z"/>
    <path d="M162.36,34.53c-0.23-0.18-0.4-0.43-0.5-0.72c-0.05-0.14-0.07-0.29-0.03-0.44c0.05-0.22,0.23-0.32,0.44-0.3
      c-0.25-0.31-0.71-0.03-0.67,0.48c0.04,0.41,0.37,0.89,0.73,1.03C162.36,34.6,162.39,34.55,162.36,34.53z"/>
  </g>
  <g id="item_17_">
    <path fill="#CADCE5" d="M282.91,245.34c0.36-0.51,0.72-3.72,0.23-6.06c-0.5-2.34-1.56-5.18-3.84-5.24
      c-2.28-0.05-2.78,1.06-3.57,2.28c-0.77,1.22-1.22,2.34-1.38,3.06c-0.17,0.72-0.47,2.72-0.47,2.72s-4.61,0.38-6.77,0.17
      c-2.16-0.22-6.22-1.16-7.67-1.77c-1.44-0.61-3.43-2.06-3.67-1.6c-0.24,0.45-0.61,2.72-0.67,3.11c-0.05,0.39,3.56,2.39,9.52,3.39
      c5.95,1,9.9,1.22,11.9,1.28C278.51,246.73,282.35,246.12,282.91,245.34z"/>
    <path fill="#CADCE5" d="M246.58,251.24c0.39-0.92,0.45-2,0.28-3.28c-0.17-1.28-0.22-1.84-0.56-2.22
      c-0.33-0.39-6.4-2.45-9.96-2.17c-3.56,0.28-6.06,1.06-6.68,1.78c-0.61,0.72-1,1.95-1.22,2.89c-0.22,0.95-0.28,1.67,0.28,2.11
      c0.55,0.45,5.06,2.84,8.18,3C240.01,253.52,245.58,253.58,246.58,251.24z"/>
    <path fill="#DEE9EF" d="M280.91,237.13c-3.48-1.23-5.1,1.67-6.89,4c0.1-0.61,0.22-1.35,0.32-1.74
      c0.16-0.72,0.61-1.84,1.38-3.06c0.78-1.22,1.28-2.34,3.57-2.28c2.28,0.05,3.34,2.9,3.84,5.24c0.04,0.2,0.07,0.42,0.11,0.64
      C282.86,238.67,282.17,237.58,280.91,237.13z"/>
    <path fill="#DEE9EF" d="M245.14,246.65c-0.06,0.12-0.08,0.26,0.02,0.41c0.43,0.68,0.76,2.11,0.33,1.74
      c-0.57-0.49-1.38-1.08-2.07-1.38c-1.61-0.69-5.76-1.05-7.5-1.27c-2.32-0.31-3.46-0.06-5.59,0.58c0-0.69,0.64-1.23,1.09-1.69
      l0.43-0.25c0.42-0.21,0.84-0.39,1.29-0.54c0.5-0.17,1-0.33,1.5-0.5c0.53-0.07,1.1-0.13,1.7-0.18c1.66-0.13,3.85,0.25,5.77,0.73
      c0.03,0.13,0.12,0.25,0.29,0.31c0.96,0.33,2.04,1.01,2.67,1.92C245.1,246.56,245.12,246.61,245.14,246.65z"/>
    <path fill="#2579BC" d="M245.3,236.11c0,0,0.78,1.11,0.95,3.84c0.17,2.72,0.28,3.73,0.17,4.62
      c-0.12,0.89-0.45,2.11-0.89,2.28c-0.44,0.17-1-1.06-4.51-1.73c-3.51-0.67-7.84-0.61-8.85-0.33c-1,0.28-1.44,0.95-1.67,1.23
      c-0.22,0.28-1.34-0.17-0.83-0.67c0.5-0.5,3.12-3.01,4.06-3.95c0.94-0.95,2.67-3.4,3.17-4.17c0.5-0.78,1.06-1.84,1.25-1.28
      c0.2,0.56,1.09,1.62,2.92,1.51C242.9,237.33,244.74,236.94,245.3,236.11z"/>
    <path fill="#499AD6" d="M241.64,238.33c-0.29-0.05-0.58-0.1-0.88-0.15c-0.25-0.06-0.51-0.15-0.76-0.26
      c-0.68-0.28-1.74-0.8-2.62-1.48c0.34-0.51,0.63-0.87,0.76-0.5c0.2,0.56,1.09,1.62,2.92,1.51c1.83-0.11,3.67-0.5,4.22-1.34
      c0,0,0.19,0.27,0.41,0.89C244.49,237.99,243.11,238.48,241.64,238.33z"/>
    <path fill="#499AD6" d="M243.82,239.69c0,0.01-0.02,0.01-0.02,0.02c-0.06,0.23-0.14,0.45-0.22,0.68
      c-2.09-0.68-4.27-0.77-6.46-0.83c0.02-0.05,0.03-0.11,0.04-0.17c0.04-0.1,0.03-0.19,0-0.26
      C239.05,238.5,241.95,239.34,243.82,239.69z"/>
    <path fill="#499AD6" d="M244.52,242.97c-3.15-0.74-6.29-1.28-9.5-1.78c0.01-0.24,0.01-0.49,0.03-0.73
      c0.33-0.05,0.67-0.06,1.01-0.06c0.09,0,0.15-0.02,0.21-0.05c0.09,0.06,0.19,0.11,0.29,0.11c2.43,0.05,4.85,0.1,7.16,0.93
      c0.06,0.02,0.12,0.02,0.19,0.01c0.02,0.09,0.07,0.16,0.15,0.23C244.45,242.03,244.59,242.49,244.52,242.97z"/>
    <path fill="#2579BC" d="M275.36,236.34l-1.42-0.54l-0.09-0.58l-2.69-0.64l-0.47,0.64l-0.99-0.23l0.41-1.29l-2.16-0.37
      c0,0-0.22,0.3-0.37,0.48c-0.14,0.18-0.32,0.32-0.43,0.25c-0.11-0.07-1.41-0.31-1.41-0.31s-2.92,2.62-4.29,2.7
      c-1.37,0.09-3.74,0.34-4.25,0.11c-0.5-0.24-1.26-0.33-1.26-0.33s-0.34,2.54-0.18,2.65c0.17,0.1,1.41,0.79,2.74,1.32
      c1.34,0.52,5.51,1.94,8.43,1.94c2.92,0,6.78-0.16,6.78-0.16s0.09-2.2,0.56-3.19C274.73,237.82,275.36,236.34,275.36,236.34z"/>
    <path fill="#499AD6" d="M270.72,236.1c-0.03,0-0.06,0.01-0.09,0.02c0.16-0.52,0.35-1.03,0.6-1.52l2.61,0.62l0.07,0.43
      c-0.05,0.22-0.1,0.44-0.17,0.66C272.75,236.43,271.72,236.12,270.72,236.1z"/>
    <path fill="#499AD6" d="M267.16,234.08c0.11,0.05,0.27-0.09,0.41-0.26c0.15-0.18,0.37-0.48,0.37-0.48l1.89,0.32
      c-0.32,0.34-0.65,0.68-0.99,1c-0.8,0.77-1.62,1.53-2.44,2.27c-0.47,0.42-0.94,0.83-1.41,1.25c-0.16-0.15-0.32-0.29-0.49-0.43
      C265.36,236.5,266.25,235.29,267.16,234.08z"/>
    <path fill="#499AD6" d="M261.44,238.04c-2.01,0.45-3.97,0.09-5.6-1c0.05-0.43,0.1-0.78,0.1-0.78s0.75,0.09,1.26,0.33
      c0.51,0.23,2.88-0.02,4.25-0.11c1.37-0.08,4.29-2.7,4.29-2.7s0.05,0.01,0.11,0.02C264.78,235.54,263.5,237.58,261.44,238.04z"/>
    <path fill="#FDBB3C" d="M266.7,82.09c0,0-2.12-0.05-3.6,0.22c-1.46,0.27-1.98,0.3-2.76,1.05c-0.78,0.75-2.14,0.64-3.35-0.23
      c-1.2-0.87-7.57-2.17-8.34-2.17c-0.76,0-2.98-0.42-3.67-0.54c-0.69-0.11-9.95,1.14-10.93,1.3c-0.98,0.17-2.67,0.65-3.38,0.71
      c-0.71,0.06-3.05,0.49-3.05,0.49s-1.52,3.76-2.23,5.94c-0.71,2.18-0.87,5.72-0.81,6.92c0.05,1.2,0.83,4.12,0.83,4.12
      s-4.48,1.33-5.84,1.55c-1.36,0.21-3.64,1.11-4.35,1.02c-0.72-0.1-1.32-1.95-1.21-3.36c0.11-1.42,3.87-10.52,3.87-10.52
      c0.71-0.87,4.47-6.21,4.47-6.21s-2.23,1.64-3.38,2.94c-1.15,1.31-2.62,2.94-2.62,2.94s3.87-6.16,5.83-8.23
      c1.96-2.07,3.55-4.09,5.13-4.2c1.57-0.11,3.48,0.65,3.48,0.65s2.89-2.83,4.09-3.49c1.2-0.65,1.58-1.36,2.29-1.36
      c0.71,0-0.6,1.96-0.6,1.96s3.49-3.21,10.9-3.82c7.41-0.6,11.61,0.6,13.79,1.53c2.18,0.93,2.67,1.91,2.67,1.91s1.69,0.71,3,1.91
      c1.31,1.2,1.63,2.61,1.63,2.61s-0.82-0.38-1.42-0.6c-0.6-0.22-1.9-0.93-2.72-1.04c-0.82-0.11-1.96-0.22-1.96-0.22
      s0.93,0.76,1.47,1.42c0.55,0.65,0.82,1.2,0.82,1.2s-1.31-0.6-2.07-0.6c-0.76,0-2.28-0.16-2.28-0.16s3.86,1.8,4.85,2.73
      C266.21,81.39,266.7,82.09,266.7,82.09z"/>
    <path fill="#DB960A" d="M249.82,74.53c0.08-0.04,0.17-0.09,0.27-0.15c1.07-0.5,2.17-0.96,3.32-1.27
      c0.63-0.17,1.36-0.22,2-0.41c0.06,0.03,0.11,0.05,0.16,0.09c-3.27,0.85-5.3,5.15-4.64,8.54c-1.12-0.23-2.02-0.38-2.28-0.38
      c-0.76,0-2.98-0.42-3.67-0.54c-0.03-0.01-0.1-0.01-0.19-0.01C244.25,78.06,247.96,75.47,249.82,74.53z"/>
    <path fill="#DB960A" d="M264.41,82.16c-0.45,0.03-0.9,0.08-1.31,0.16c-1.46,0.27-1.98,0.3-2.76,1.05
      c-0.78,0.75-2.14,0.64-3.35-0.23c-0.29-0.21-0.91-0.45-1.67-0.7C258.42,80.29,261.42,80.63,264.41,82.16z"/>
    <path fill="#FDD780" d="M263.98,74.83c-0.36-0.27-5.33-2.72-5.61-2.43c0.22-0.23,3.07-0.1,2.72-0.16
      c-1.22-0.22-2.01-0.83-4.36-1.15c-4.25-0.59-7.77-0.56-10.19-0.05c-2.58,0.55-6.15,1.4-8.53,2.57c-1.25,0.62-2.58,1.15-3.84,1.79
      c0.83-0.89,1.79-1.71,2.79-2.45c-0.2,0.36-0.39,0.65-0.39,0.65s3.49-3.21,10.9-3.82c7.41-0.6,11.61,0.6,13.79,1.53
      c2.18,0.93,2.67,1.91,2.67,1.91s1.69,0.71,3,1.91c1.31,1.2,1.63,2.61,1.63,2.61s-0.82-0.38-1.42-0.6
      c-0.23-0.09-0.58-0.24-0.95-0.41C265.55,75.98,264.78,75.44,263.98,74.83z"/>
    <path fill="#FDD780" d="M242.36,80.63c-0.35-1.85,1.92-4.51,3.75-5.55c1.89-1.08,4.3-1.72,6.57-1.58
      c-2.73,0.86-5.42,1.57-7.34,4.03c-0.62,0.8-1.07,1.91-1.01,2.9C243.86,80.47,243.17,80.54,242.36,80.63z"/>
    <path fill="#DFDFDF" d="M285.54,151.53c-0.04-0.83-0.04-1.35-0.75-1.39c-0.71-0.04-13-0.2-15.2-0.24
      c-2.22-0.04-13.39-0.12-15.05-0.31c-1.66-0.19-4.03-0.23-4.46,0c-0.43,0.24-0.67,1.22-0.55,3c0.12,1.78,0.2,20.62,0.2,21.72
      c0,1.11,7.82,1.34,9.71,1.11c1.89-0.24,14.02-0.63,15.64-0.67c1.62-0.04,9.48,0.08,10.11-0.71c0.63-0.79,0.75-5.61,0.71-7.07
      c-0.04-1.46-0.16-8.45-0.08-9.91C285.89,155.59,285.54,151.53,285.54,151.53z"/>
    <path fill="#EEEEEE" d="M276.97,151.41c-3.99,0.07-7.9-0.09-11.89-0.2c-3.96-0.11-7.97-0.02-11.93-0.12
      c-1.03-0.03-2.45,0.08-3.63-0.06c0.07-0.8,0.27-1.28,0.55-1.44c0.43-0.23,2.8-0.19,4.46,0c1.66,0.2,12.83,0.27,15.05,0.31
      c2.21,0.04,14.5,0.2,15.2,0.24c0.71,0.04,0.71,0.55,0.75,1.37C282.93,151.45,279.62,151.36,276.97,151.41z"/>
    <path fill="#C7C7C7" d="M285.83,170.1c-1.56,0.2-3.08,0.38-3.37-1.25c-0.04-0.21-0.32-0.46-0.51-0.47
      c-2.73-0.22-2.64-1.73-2.11-2.73c-1-0.09-2.1-1.72-0.54-2.91c0.2-0.16-0.12-0.56-0.24-0.81c-1.51-3.11,4.34-3.57,6.74-2.42
      c0.01,2.65,0.08,6.42,0.11,7.45C285.91,167.59,285.9,168.83,285.83,170.1z"/>
    <path fill="#C7C7C7" d="M254.21,161.58c0.08-0.01,0.14-0.03,0.2-0.07c0.6,0.38,0.9,1.32,0.26,1.84
      c-0.69,0.58-1.59,0.04-1.7-0.78C252.88,161.87,253.65,161.62,254.21,161.58z"/>
    <path fill="#B0B0B0" d="M276.69,150.34c-0.11,0.67-0.59,1.86-1.11,2.26c-0.51,0.39-1.7,0.55-2.81,0.43
      c-1.1-0.12-10.86,0.4-11.93,0.12c-1.07-0.28-2.37-1.03-2.53-1.77c-0.16-0.75-0.08-1.58-0.08-1.58S276.81,149.63,276.69,150.34z"/>
    <path fill="#C2C2C0" d="M258.31,151.27c-0.14-0.72-0.06-1.48-0.06-1.48s18.56-0.16,18.44,0.55
      c-0.05,0.31-0.18,0.72-0.35,1.12C270.33,151.34,264.21,151.33,258.31,151.27z"/>
    <path fill="#7EC0E7" d="M253.59,221.35c0.02-0.08-0.24-1.54-0.24-1.54s-1.27,0.18-1.35,1.94c-0.08,1.75,0,5.1,0.21,5.23
      c0.22,0.13,2.63-0.69,4.38-0.69c1.76,0,6.32,0.87,7.25,0.9c0.93,0.02,1.54,0.02,1.7-0.56c0.16-0.58,1.01-3.48,1.06-4.51
      c0.05-1.03-0.06-2.55-0.45-2.76c-0.4-0.21-1.63,0.4-1.63,0.4l-0.05,0.85c0,0-1.61,0.19-2.65,0.34c-1.04,0.16-4.27,0.88-4.62,0.83
      c-0.34-0.05-1.86-0.37-2.26-0.45C254.54,221.24,253.59,221.35,253.59,221.35z"/>
    <path fill="#A9D4EF" d="M265.89,219.56c1.47,1.89-1.98,2.31-3.07,2.48c-1.33,0.21-2.67,0.45-4.01,0.55
      c-1.52,0.11-3.58-0.41-4.97,0.11c-1.09,0.41-1.55-0.47-1.76-1.53c0.29-1.22,1.27-1.36,1.27-1.36s0.26,1.46,0.24,1.54
      c0,0,0.95-0.11,1.34-0.03c0.4,0.08,1.92,0.4,2.26,0.45c0.34,0.05,3.58-0.66,4.62-0.83c1.04-0.16,2.65-0.34,2.65-0.34l0.05-0.85
      c0,0,0.76-0.37,1.28-0.43C265.81,219.41,265.83,219.49,265.89,219.56z"/>
    <path fill="#7EC0E7" d="M247.24,220.9c-0.24-0.1-1.62-0.24-2.26-0.15c-0.64,0.08-5.91,0.39-6.58-0.11
      c-0.66-0.5-3.11-0.98-3.82-0.82c-0.71,0.15-0.98,1.14-0.98,2.25c0,1.12-0.03,3.82,0.18,4.17c0.22,0.34,6.66,0.11,8.07,0.26
      c1.41,0.16,4.75-0.19,5.21-0.38c0.45-0.18,0.5-1.14,0.55-2.2s0.13-2.68-0.34-2.92C246.79,220.77,247.24,220.9,247.24,220.9z"/>
    <path fill="#A9D4EF" d="M240.53,222.57c-1.96-0.05-3.23-0.79-4.96-1.48c-0.55-0.22-1.16-0.41-1.75-0.49
      c0.16-0.41,0.41-0.7,0.77-0.78c0.71-0.17,3.16,0.31,3.82,0.82c0.67,0.5,5.94,0.19,6.58,0.11c0.64-0.08,2.02,0.05,2.26,0.15
      c0,0-0.46-0.13,0.02,0.11c0.2,0.09,0.29,0.43,0.34,0.85C245.26,222.27,242.91,222.63,240.53,222.57z"/>
    <path fill="#53A5E0" d="M260.44,183.04c0.71,1.76,1.63,5.11,1.96,7.11c0.34,2.01,0,5.68,0.08,7.35
      c0.09,1.67,0.58,5.76,1,7.68c0.42,1.92,0.83,4.67,0.83,6.76c0,2.09,0.08,7.51,0.08,7.51s0.19,1.28-0.18,1.25
      c-0.37-0.03-6.13,1.27-6.69,1.14c-0.55-0.14-3.45-0.77-3.66-0.55c-0.21,0.21-0.66-1.22-0.69-1.94c-0.03-0.72-0.4-6.02-0.59-7.7
      c-0.18-1.68-0.71-7.94-0.69-9.08c0.02-1.14,0.27-5.04,0.27-5.73c0-0.69-0.21-1.75-0.21-1.75s-1.3,2.15-1.7,3.69
      c-0.4,1.53-0.96,4.08-1.27,5.99c-0.33,1.92-0.74,3.67-0.72,5.15c0.02,1.49-0.5,5.97-0.82,7.54c-0.32,1.57-0.51,2.68-0.77,3.11
      c-0.26,0.42-1.56,0.39-3.16,0.5c-1.59,0.11-6.23-0.74-7.01-1.09c-0.77-0.34-1.3-0.13-1.43-0.96c-0.13-0.82-0.24-3.05,0-4.38
      c0.24-1.32,0.88-6.72,0.64-10.56c-0.24-3.84,0.63-12.95,0.71-15.15c0.08-2.2,0.16-4.46,0.22-4.78c0.05-0.32,8.62,0.32,11.46,0.16
      C250.96,184.14,260.03,181.99,260.44,183.04z"/>
    <path fill="#3889CC" d="M245.19,209.6c0,0.01,0,0.03,0,0.05c0,0.03,0.02,0.05,0.02,0.08c-0.59,1.07-1.81,4.72-3.52,3.55
      c-1.05-0.73-1.62-2.46-1.62-3.87c0.7,0.63,1.41,1.3,2.42,1.27C243.55,210.65,244.25,209.85,245.19,209.6z"/>
    <path fill="#3889CC" d="M252.18,196.83c0-0.69-0.21-1.75-0.21-1.75s-0.1,0.16-0.24,0.4c0.48-1.07,1.16-2.04,2.05-2.82
      c-0.01,3.72-0.09,7.43-0.67,11.1c-0.24,1.56-0.53,3.12-0.81,4.68c-0.2-2.37-0.41-5.15-0.39-5.87
      C251.94,201.42,252.18,197.52,252.18,196.83z"/>
    <path fill="#3889CC" d="M246.33,186.63c-3.33,0.01-6.6-0.33-9.76-1.16c0.03-0.7,0.06-1.19,0.08-1.33
      c0.05-0.32,8.62,0.32,11.46,0.16c2.84-0.16,11.91-2.31,12.33-1.27c0.22,0.54,0.46,1.25,0.69,2
      C256.34,186.08,251.17,186.62,246.33,186.63z"/>
    <path fill="#79C233" d="M264.46,149.91c0,0-1.27-2.76-1.64-3.99c-0.36-1.24-1.96-4.14-2.54-5.42
      c-0.58-1.26-1.35-2.54-1.67-2.87c-0.33-0.33-3.38-1.27-5.01-1.71c-1.64-0.43-3.78-0.69-4.73-1.16c-0.94-0.47-1.92-1.42-2.43-1.68
      c-0.51-0.25-1.31-0.43-1.89-0.58c-0.58-0.15-3.59,0.58-5.08,1.67c-1.49,1.09-3.93,3.34-5.52,3.89c-1.6,0.54-3.99,1.71-4.5,2.32
      c-0.51,0.62-0.62,1.57-0.69,2.73c-0.08,1.16,0.07,2.9-0.03,3.78c-0.11,0.87-0.04,2.98-0.43,4.18c-0.4,1.2-0.8,2.51-0.8,2.51
      s3.77-1.42,4.25-1.67c0.47-0.25,1.02-0.69,1.02-0.69l1.06-0.04c0,0,0.58,2.36,0.69,5.16c0.11,2.8,0.62,7.59,0.69,10.5
      c0.07,2.9,0.03,8.72-0.14,10.53c-0.18,1.81-0.4,3.41-0.58,3.99c-0.18,0.58-0.54,0.8-0.33,1.09c0.22,0.29,2.14,1.34,4.21,1.6
      c2.07,0.26,15.22-0.29,17.25-0.25c2.03,0.04,5.12-0.69,6.57-1.13c1.45-0.44,2.87-0.66,3.05-0.9c0.18-0.25-0.58-2.51-0.84-3.53
      c-0.25-1.02-0.29-3.16-0.29-3.16s-13.37,0.46-13.69-0.1c-0.33-0.56-0.36-3.21-0.33-4.55c0.03-1.35-0.07-6.17,0-7.63
      c0.07-1.45-0.38-11.91-0.16-12.47c0.23-0.56,0.42-0.75,1.18-0.75C251.86,149.58,264.46,149.91,264.46,149.91z"/>
    <path fill="#5FA812" d="M264.1,175.08c0,0,0.03,2.14,0.29,3.16c0.04,0.15,0.09,0.33,0.15,0.52
      c-1.6,0.18-3.21,0.34-4.82,0.38c-2.9,0.08-7.92,0.25-9.51-0.14c-1.6-0.38-2.38-1.36-2.54-3.04c-0.24-2.53,0.17-10.14,0.17-11.75
      c0-1.91-0.55-10.04-0.18-11.01c0.37-0.97,1.35-1.31,2.22-1.26c0.01,3.13,0.27,9.73,0.21,10.86c-0.07,1.46,0.03,6.28,0,7.63
      c-0.04,1.34,0,3.99,0.33,4.55C250.73,175.54,264.1,175.08,264.1,175.08z"/>
    <path fill="#5FA812" d="M233.86,148.05c-0.09-1.09-0.19-2.18-0.28-3.27c0.63,1.45,0.86,3.03,1.3,4.58
      c0.37,1.34,1.17,2.9,1.02,4.34c-0.52,0.33-1.02,0.67-1.5,1.06c-0.17-1.69-0.45-3-0.54-3.43
      C234.17,150.27,233.95,149.15,233.86,148.05z"/>
    <path fill="#5FA812" d="M262.52,147.97c0.29,0.66,0.65,1.29,1.01,1.92c-0.49-0.02-1.14-0.03-1.89-0.05
      c-0.5-2.07-1.01-4.14-1.43-6.24C261.12,144.98,261.83,146.44,262.52,147.97z"/>
    <path fill="#5FA812" d="M236.58,154.7c0.06-0.15,0.11-0.3,0.13-0.45c2.75-1.63,6-2.43,9.27-2.69c0,0,0.01,0,0.02,0
      c1.49,0.69-0.83,3.23-1.88,3.82c-0.35-0.27,2.59,2.94,2.65,2.95c0.02,0.38,0.05,0.75,0.07,1.1c-1.02-0.23-3.06-0.93-3.83-0.16
      c-0.87,0.87,0.12,2.9,0.25,3.85c0.17,0.77,0.37,1.77,0.23,2.55c-0.14,0.78-2.01,0.98-2.2,0.45c-0.42-1.14-0.78-1.92-1.16-1.92
      c-0.39,0-0.91,0.37-1.17,0.65c-1.12,1.26-2.39,1.51-3.8,1.25c-0.13-2.93-0.56-7.19-0.66-9.76c-0.01-0.16-0.01-0.31-0.02-0.47
      c0.41-0.37,0.85-0.71,1.3-1.03C235.98,155.07,236.45,155.08,236.58,154.7z"/>
    <path fill="#A4DC6D" d="M257.95,138.03c-1.6-0.46-3.22-0.83-4.84-1.18c-2.79-0.6-5.29-1.54-6.74-3.78
      c0.02,0.01,0.06,0.02,0.08,0.03c0.51,0.26,1.49,1.2,2.43,1.68c0.95,0.47,3.09,0.72,4.73,1.16c1.63,0.44,4.68,1.38,5.01,1.71
      c0.16,0.16,0.42,0.54,0.71,1.03C258.91,138.4,258.47,138.18,257.95,138.03z"/>
    <path fill="#A4DC6D" d="M229.88,141.63c-0.42,0.29-0.77,0.64-1.08,1.03c0.07-0.95,0.21-1.73,0.65-2.27
      c0.51-0.61,2.9-1.78,4.5-2.32c1.6-0.54,4.03-2.8,5.52-3.89c0.04-0.03,0.09-0.07,0.14-0.1
      C238.91,138.63,233.01,139.48,229.88,141.63z"/>
    <path fill="#A4DC6D" d="M233.54,147.52c0.26,1.26,0.78,2.59,0.08,3.67l-0.76,0.02c-0.24-1.88,0.04-3.86,0.36-5.74
      C233.29,146.16,233.4,146.84,233.54,147.52z"/>
    <path fill="#FAD6BA" d="M245.13,78.59c0,0,1.8-0.27,3.91,0.13c2.1,0.39,5.12,0.99,8.96,3.52c3.85,2.53,4.65,4.1,6.63,6.98
      c1.98,2.87,3.38,6.54,3.79,8.13c0.4,1.59,0.56,3.04,0.56,3.04s1.32-0.77,2.29,0.53c0.97,1.31,1.77,2.99,0.59,4.21
      c-1.18,1.23-3.25-0.11-3.25-0.11s-0.14,3.41-0.89,6.01c-0.75,2.61-2.43,6.53-5.53,9.53c-3.09,3-5.44,4.43-10.21,5.81
      c-4.78,1.38-8.95,1.5-13.74,0.8c-4.79-0.69-9.11-2.48-12.57-4.68c-3.45-2.2-6-5.97-7.07-8.25c-1.07-2.28-1.8-4.76-1.8-4.76
      s-3.11,2.95-5.06,1.35c-1.95-1.6-2.44-4.59-0.41-6.04c2.02-1.47,3.57-1.12,3.57-1.12s-0.22-0.99,0.3-1.13
      c0.51-0.14,10.19-2.67,10.19-2.67s-0.85-3.16-0.8-4.87c0.04-1.71,0.47-5.7,1.41-8.23c0.95-2.53,1.67-3.83,1.98-4.2
      c0.32-0.36,4.55-0.75,5.37-0.8c0.82-0.05,7.49-1.17,8.9-1.21c1.42-0.03,2.72-0.17,2.72-0.17S244.85,78.88,245.13,78.59z"/>
    <path fill="#FAE6CC" d="M268.88,101.37c-0.02,0.03-0.04,0.07-0.06,0.11c-1.73-6.06-5.03-12-9.82-16.15
      c-2-1.72-4.48-3.03-6.9-4.18c-2.1-1-5.14-1.54-6.2-2.63c0.72-0.04,1.87-0.04,3.15,0.19c2.1,0.39,5.12,0.99,8.96,3.52
      c3.85,2.53,4.65,4.1,6.63,6.98c1.98,2.87,3.38,6.54,3.79,8.13c0.15,0.6,0.27,1.19,0.36,1.68c-0.03,0.06-0.04,0.13-0.04,0.22
      C268.81,99.91,268.94,100.67,268.88,101.37z"/>
    <path fill="#E6BA9E" d="M240.72,81.8c-1.92,0.89-4.01,1.41-6.05,1.96c-1.96,0.52-3.86,0.84-5.87,1.05
      c-0.75,0.08-1.48,0.18-2.16,0.4c0.63-1.5,1.11-2.34,1.35-2.61c0.32-0.36,4.55-0.75,5.37-0.8c0.82-0.05,7.49-1.17,8.9-1.21
      c0.4-0.01,0.79-0.03,1.15-0.05C242.5,80.9,241.62,81.39,240.72,81.8z"/>
    <path fill="#E6BA9E" d="M214.87,103.36c-0.01-0.3,0.02-0.71,0.34-0.8c0.42-0.11,7.02-1.84,9.37-2.45
      C221.71,102.64,218.47,103.37,214.87,103.36z"/>
    <path fill="#E6BA9E" d="M233.73,120.33c-0.81,0.56-1.7,1-2.52,0.7c-1.68-0.63-1.74-2.03-1.41-3.42
      C230.74,118.91,232.33,119.98,233.73,120.33z"/>
    <path fill="#E6BA9E" d="M249.66,98.84c0.49-0.44,1.03-0.84,1.59-1.19c0.93-0.58,2.06-1.05,3.18-0.95
      c2,0.17,2.59,2.52,1.02,3.76c-1.6,0.64-3.43,1.19-5.06,0.51c-0.53-0.22-1.86-0.64-1.24-1.53
      C249.29,99.22,249.46,99.02,249.66,98.84z"/>
    <path fill="#FAD6BA" d="M244.83,127.56c0,0.26,0.34,4.75,0.34,4.75s1.34,0.47,1.3,1.03c-0.05,0.57,0.17,3.11-2.24,4.06
      c-2.41,0.95-3.88-0.18-4.05-1.3c-0.17-1.12-0.35-1.8,0.17-2.5c0.52-0.69,0.6-1.73,0.52-2.77c-0.09-1.03-0.34-3.54-0.26-3.28
      C240.69,127.82,244.83,127.56,244.83,127.56z"/>
    <path fill="#E6BA9E" d="M240.85,130.64c-0.1-1.13-0.32-3.32-0.24-3.07c0.09,0.26,4.23,0,4.23,0c0,0.15,0.12,1.78,0.22,3.09
      C243.65,130.92,242.2,130.93,240.85,130.64z"/>
    <path fill="#FAD6BA" d="M244.25,227.01c0.02,0.34,0.2,1.78,0.2,2.53c0,0.75-0.09,2.61,0,3.34c0.09,0.73,0.21,1.55,0.25,1.95
      c0.03,0.41,0.27,0.84,0.24,1.1c-0.01,0.26-0.99,1.48-3.54,1.3c-2.54-0.17-3.18-0.53-2.88-1.3c0.3-0.77,0.89-3.31,1.01-5.12
      c0.12-1.81-0.04-3.25-0.09-3.67C239.4,226.71,244.22,226.3,244.25,227.01z"/>
    <path fill="#E6BA9E" d="M240.86,229.51c-0.43,0.05-0.86,0.12-1.28,0.19c0.02-1.27-0.09-2.23-0.13-2.56
      c-0.05-0.43,4.77-0.84,4.8-0.13c0.02,0.34,0.19,1.75,0.19,2.5C243.26,229.46,242.09,229.38,240.86,229.51z"/>
    <path fill="#FAD6BA" d="M262.29,227.24c-0.05,0.57-0.1,1.58-0.26,2.27c-0.16,0.69-0.57,1.81-0.46,2.29
      c0.11,0.48,0.71,1.32,1.41,1.54c0.69,0.23,2.2,0.73,2.2,0.73s-0.71,0.99-1.39,1.46c-0.68,0.46-3.58,1.33-4.99,1.25
      c-1.42-0.09-2.72-0.68-2.72-0.68c-0.09-0.43,0.05-1.3,0.12-1.83c0.07-0.54,0.38-3.54,0.53-4.57c0.15-1.03,0.21-1.96,0.21-2.3
      c0-0.33,0.05-0.69,0.07-0.8C257.03,226.51,262.33,226.85,262.29,227.24z"/>
    <path fill="#E6BA9E" d="M256.82,229.06c0.09-0.75,0.12-1.38,0.12-1.64c0-0.33,0.05-0.69,0.07-0.8
      c0.01-0.1,5.32,0.23,5.28,0.62c-0.04,0.46-0.08,1.22-0.18,1.85C260.35,229.13,258.59,229.08,256.82,229.06z"/>
    <path fill="#FAD6BA" d="M232.58,152.03c0,0-0.38,2.59-0.48,3.6c-0.09,1.01-0.02,2.93-0.02,3.17c0,0.24,0.19-2.62,1.27-3.75
      c1.08-1.13,1.85-2.06,2.88-2.66c1.03-0.6,2.52-0.99,3.31-1.13c0.8-0.14,3.65-0.24,4.61-0.29c0.96-0.05,1.92-0.55,2.18,0.12
      c0.26,0.67-0.09,1.34-0.55,1.66c-0.46,0.32-1.06,0.51-1.42,0.6c-0.36,0.09-1.25,0.2-1.25,0.2s0.5,0.72,1.22,1.13
      c0.72,0.4,2.49,1.77,3.22,2.13c0.72,0.36,1.22,0.88,1.42,1.25c0.2,0.37,0.14,0.91-0.36,1.27c-0.5,0.36-1.71,0.27-2.02,0.05
      c-0.31-0.22-1.37-1.25-1.87-1.62c-0.5-0.39-1.18-0.7-1.44-0.75c-0.26-0.05-0.38,0.09-0.28,0.48c0.09,0.38,0.79,3.03,0.91,3.75
      c0.11,0.71,0.38,2.3,0.02,2.93c-0.36,0.62-1.2,0.89-1.68,0.55c-0.47-0.34-1.03-1.59-1.1-2.07c-0.07-0.48-0.21-1.71-0.21-1.71
      s-0.67,0.99-0.94,1.3c-0.26,0.31-1.01,0.67-1.25,0.65c-0.24-0.03-0.65-0.07-0.65-0.07s-1.06,1.3-1.9,1.08
      c-0.84-0.21-1.49-1.24-1.51-1.46c-0.02-0.21-0.88,0.82-1.42,2.01c-0.53,1.2-2.66,4.56-3.38,5.59c-0.72,1.03-3.43,3.98-3.53,1.97
      c-0.09-2.01,0.58-6.31,0.84-7.77c0.26-1.47,0.79-4.9,0.98-6.12c0.19-1.22,0.31-4.8,0.31-4.8L232.58,152.03z"/>
    <path fill="#E6BA9E" d="M228.38,156.01c0.08-1.34,0.12-2.72,0.12-2.72l4.08-1.27c0,0-0.19,1.31-0.34,2.43
      C230.89,154.8,229.66,155.45,228.38,156.01z"/>
    <path fill="#E6BA9E" d="M240.89,160.51c0.04-1.39,0.22-2.77,0.29-4.15c0.88,1.44,1.04,3.84,1.08,5.31
      c0.03,1.02-0.08,1.89-0.29,2.74c-0.39-0.5-0.77-1.39-0.82-1.77c-0.07-0.48-0.21-1.71-0.21-1.71s-0.03,0.05-0.07,0.1
      C240.88,160.86,240.88,160.69,240.89,160.51z"/>
    <path fill="#E6BA9E" d="M244.28,157.27c0.11,0.12,0.21,0.25,0.31,0.37c-0.48-0.34-1.06-0.6-1.31-0.65
      c-0.14-0.02-0.24,0.01-0.28,0.11c-0.19-0.72-0.52-1.4-0.85-2.08C242.96,155.67,243.63,156.49,244.28,157.27z"/>
    <path fill="#E6BA9E" d="M245.78,152.74c-0.46,0.32-1.06,0.51-1.42,0.6c-0.36,0.09-1.25,0.2-1.25,0.2s0.26,0.37,0.68,0.73
      c-0.12-0.06-0.25-0.12-0.38-0.16c-0.46-0.43-0.91-0.86-1.35-1.31c1.37,0.03,2.78,0.01,4.04-0.38
      C246.02,152.55,245.91,152.66,245.78,152.74z"/>
    <path fill="#E6BA9E" d="M232.04,159.39c0,0.01,0,0.02,0.01,0.03c0,0.05,0.02,0.11,0.05,0.17c0.42,0.81-1.94,3.27-2.39,3.96
      c-0.45,0.67-0.87,1.35-1.26,2.04c-0.08-1.22,0.15-2.48,0.75-3.57C229.88,160.79,230.96,160.18,232.04,159.39z"/>
    <path fill="#FAD6BA" d="M288.63,160.55c0,0,0.68-0.39,0.27-1.32c-0.42-0.93-2.2-1.03-4.2-1.08c-2-0.05-2.95,0.44-3.49,0.76
      c-0.54,0.32-0.81,0.49-0.86,0.83c-0.05,0.34,0.12,0.83,0.12,0.83s-1.17,0.81-0.95,1.84c0.22,1.03,0.9,1,1.25,1.08
      c0.34,0.07,1.32,0.24,1.32,0.24s-0.68,0.39-1,1.2c-0.31,0.8,0.17,1.32,0.95,1.51c0.78,0.19,1.52,0.24,1.52,0.24
      s-0.46,1.54,1.17,1.57c1.64,0.02,2.04-0.5,2.88-1.13c1.44-1.07,0.44-1.49,0.44-1.49s1-0.71,1.27-1.47
      c0.27-0.76-0.48-1.15-0.48-1.15s0.83-0.37,0.85-1.15C289.71,161.09,288.63,160.55,288.63,160.55z"/>
    <path fill="#90544F" d="M246.14,112.1c0,0-4.93-1.53-7.86-3.52c-2.94-1.99-5.43-4.35-6.21-5.42
      c-0.79-1.08-1.82-2.92-1.82-2.92s-0.62,1.51-0.79,3.13c-0.16,1.62,0.04,3.44,0.04,3.44s2.57,3.27,4.76,5.46
      c2.19,2.19,4.47,3.44,5.38,3.6C240.55,116.03,244.86,113.64,246.14,112.1z"/>
    <path fill="#5A3534" d="M235.67,107.62c-1.77-0.85-3.83-0.85-5.71-0.23c-0.28-0.36-0.46-0.57-0.46-0.57
      s-0.21-1.83-0.04-3.44c0.17-1.62,0.79-3.13,0.79-3.13s1.03,1.84,1.82,2.92c0.78,1.08,3.27,3.43,6.21,5.42
      c2.94,1.99,7.86,3.52,7.86,3.52c-1.16,1.38-4.76,3.46-6.13,3.74C240.27,112.58,238.79,109.1,235.67,107.62z"/>
    <path d="M246.86,99.12c-0.03-2.09,2.79-4.07,4.34-4.93c2.32-1.29,5.28-0.03,6.46,2.21c0.94,1.76-0.04,3.81-1.95,4.22
      c-0.18,0.04-0.25-0.18-0.12-0.29c1.34-1,1.95-2.24,1.05-3.79c-1.31-2.26-4.12-2.27-6.04-0.99c-0.96,0.64-3.77,2.99-2.39,4.23
      C248.59,100.13,246.88,100.11,246.86,99.12z"/>
    <path d="M229.67,116.54c0.75,1,1.44,2.04,2.54,2.68c1.14,0.66,2.61,0.69,3.53,0.58c0.08-0.01,0.09,0.13,0.04,0.18
      c-0.89,1.04-2.72,0.83-3.84,0.33c-1.53-0.68-2.73-1.93-2.75-3.64C229.19,116.39,229.52,116.35,229.67,116.54z"/>
    <path d="M218.02,104.9c0.13,0.13-0.04,0.34-0.2,0.26c-2.94-1.59-6.8-1.03-7.25,2.76c-0.17,1.48,0.93,2.57,2.28,2.76
      c1.58,0.21,3.63-1.17,4.68-2.58c0.15-0.2,0.43,0.05,0.33,0.26c-0.17,0.34-0.34,0.67-0.54,1c1.53,6.49,5.55,11.2,11.42,14.24
      c5.24,2.72,11.32,3.66,17.18,3.27c5.77-0.38,11.47-2.24,15.73-6.27c4.42-4.19,6.64-10.71,6.79-16.73c0.01-0.21,0.3,0.4,0.33,0.6
      c0.72,6.08-1.88,12.13-6.06,16.51c-4.27,4.46-10.29,6.33-16.3,6.93c-12.26,1.21-26.75-4.53-29.89-17.42
      c-0.96,1.15-2.18,1.65-3.88,1.42c-1.88-0.25-3.03-1.65-3.18-3.42C209.08,103.83,214.88,101.8,218.02,104.9z"/>
    <path d="M246.17,78.46c7.01-0.11,13.03,3.14,17.36,8.52c1.8,2.25,3.09,4.94,4.25,7.56c0.7,1.59,1.53,3.59,1.57,5.47
      c0.32-0.22,0.81-0.26,1.17-0.11c0.91,0.39,1.77,1.48,2.1,2.34c0.36,0.92,0.33,2.25-0.32,3.11c-0.33,0.43-0.82,0.68-1.36,0.7
      c-1.5,0.08-2.01-1.1-1.84-1.01c0.69,0.36,1.74,0.49,2.46-0.26c0.46-0.48,0.6-1.52,0.44-2.08c-0.21-0.74-1.39-2.65-2.7-1.87
      c-0.11,0.89-0.63,2.03-0.62,1.72c0.11-2.77-0.86-6.01-1.99-8.5c-1.12-2.45-2.37-4.86-4.04-6.97c-1.91-2.42-4.38-4.43-7.1-5.86
      c-2.97-1.56-6.97-2.2-9.85-2.58C245.58,78.62,246.05,78.46,246.17,78.46z"/>
    <path d="M244.62,128.49c0.01-0.16,0.26-0.15,0.31,0c0.38,1.13,0.89,5.88,0.16,6.5c-0.14,0.11-0.44,0.14-0.45-0.06
      C244.57,133.45,244.57,130.21,244.62,128.49z"/>
    <path d="M240.71,128.54c0.08-0.17,0.32-0.09,0.37,0.05c0.37,1.02,0.57,5.63,0.34,6.76c-0.04,0.18-0.33,0.15-0.42,0
      C240.44,134.35,240.26,129.58,240.71,128.54z"/>
    <path d="M246.17,132.62c1.34,1.13,2.67,1.67,3.92,2.15c1.4,0.52,3.11,0.68,4.56,1.05c1.13,0.29,4.01,1.21,4.49,2.01
      c0.47,0.8,2.03,3.85,2.97,5.73c0.84,1.68,1.18,3.93,2.05,5.59c0.03,0.06-0.34,0.09-0.62-0.36c-1.02-1.63-1.14-3.25-1.93-4.94
      c-0.87-1.84-2.77-5.31-3.06-5.84c-0.29-0.54-3.95-1.46-5.86-1.85c-2.04-0.41-5.51-1.19-6.61-3.15
      C245.99,132.84,246.01,132.48,246.17,132.62z"/>
    <path d="M239.87,135.51c0-0.56,0.46-1.18,0.44-1.01c-0.11,1.13,0.29,1.82,0.86,2.21c0.57,0.4,1.37,0.36,2.02,0.24
      c1.44-0.29,2.96-1.54,3.24-3.03c0.02-0.09,0.13-0.05,0.14,0.02c0.24,2.2-1.49,3.68-3.56,4.04
      C241.32,138.27,239.87,137.76,239.87,135.51z"/>
    <path d="M228.95,140.44c0.35-0.59,1.17-1.04,2.06-1.48c1.5-0.73,2.99-1.42,4.4-2.32c1.62-1.04,3.11-2.26,4.75-3.28
      c0.16-0.1,0.29,0.11,0.18,0.24c-1.66,2.05-3.99,3.78-6.3,5.05c-1.16,0.63-3.82,1.58-4.3,2.03c-0.48,0.44-0.58,1.64-0.77,2.8
      c-0.05,0.26-0.37-0.12-0.39-0.39C228.5,141.98,228.59,141.04,228.95,140.44z"/>
    <path d="M215.21,102.46c1.89-0.99,8.5-2.88,9.69-2.8c-1.14-3.09-0.66-6.91-0.04-10.02c0.8-4.01,2.45-8.3,6.04-10.62
      c0.1-0.06,0.19,0.08,0.13,0.16c-1.34,1.51-2.53,2.97-3.45,4.78c-0.89,1.75-1.33,3.65-1.79,5.54c-0.77,3.14-1.09,7.68,0.23,10.73
      c-1.59,0.27-8.6,2.46-10.71,2.61C215.11,102.85,215.04,102.56,215.21,102.46z"/>
    <path d="M213.82,98.05c0.42-2.23,1.35-4.38,2.24-6.46c1.02-2.39,2.1-4.59,3.72-6.64c1.76-2.26,3.68-4.71,6.05-6.08
      c0.11-0.06,0.55-0.21,0.46-0.14c-3.64,3.13-7.17,7.59-9.02,12.37c-1.34,3.44-3.54,7.47-2.28,11.19c0.14,0.41-0.6-0.14-0.8-0.54
      C213.71,100.88,213.54,99.53,213.82,98.05z"/>
    <path d="M222.08,79.6c1.6-2.05,4.92-5.22,7.82-3.62c0.21,0.12,0.09,0.54-0.12,0.45c-1.19-0.48-2.61-0.15-3.89,0.87
      c-1.1,0.88-2.8,2.24-3.69,3.32c-2.22,2.66-4.16,5.08-6.05,8.02c-0.04,0.08-0.17,0.03-0.15-0.06
      C216.54,86.85,220.89,81.14,222.08,79.6z"/>
    <path d="M232.74,81.62c1.1-0.31,2.22-0.54,3.35-0.71c0.32-2.56,1.78-4.89,3.66-6.59c0.06-0.06,0.16,0.03,0.1,0.1
      c-1.74,1.86-2.66,4.02-3.26,6.42c0.06-0.01,0.1-0.02,0.15-0.03c2.48-4.82,6.04-9.46,12.02-9.15c0.14,0.01,0.14,0.23,0,0.23
      c-5.69,0.16-8.71,4.26-11.18,8.8c0.43-0.05,0.87-0.11,1.3-0.15c1.51-0.16,3.91-0.53,5.6-0.33c-0.5-1.02,0.77-2.98,1.54-3.63
      c2.59-2.18,5.82-3.66,9.21-3.92c0.11-0.01,0.13,0.16,0.02,0.18c-2.51,0.48-4.76,1.41-6.84,2.87c-0.84,0.58-1.48,0.86-2.47,2.03
      c-0.74,0.88-0.99,2.02-0.25,2.96c0.13,0.17-2.06,0.35-2.93,0.39c-1.11,0.06-2.21,0.15-3.32,0.24c-0.8,0.07-5.32,0.56-6.66,0.68
      C232.57,82.03,232.54,81.68,232.74,81.62z"/>
    <path d="M234.61,74.44c1.12-0.91,2.7-1.91,4.01-2.51c2.72-1.25,5.28-1.91,8.24-2.35c2.74-0.4,5.42-0.33,8.16-0.06
      c2.49,0.25,5,0.89,7.22,2.09c1.12,0.6,1.43,1.07,1.34,1.02c-2.25-1.32-6.03-2.38-8.54-2.58c-2.7-0.21-5.72-0.3-8.4,0.11
      c-2.7,0.41-5.29,1.36-7.77,2.47c-2.62,1.16-4.39,2.64-6.75,4.57C231.98,77.31,231.68,76.8,234.61,74.44z"/>
    <path d="M236.43,71.72c0.93-0.5,1.38-0.29,1.19-0.19c-2.7,1.41-5.22,4.03-7.2,6.28c-0.14,0.16-0.2-0.11,0.11-0.68
      C231.99,74.43,233.85,73.08,236.43,71.72z"/>
    <path d="M258.01,77.59c2.22-0.4,4.23-0.27,6.28,0.42c-0.86-0.9-2.29-1.94-3.56-2.09c-0.36-0.04,0.13-0.33,0.88-0.33
      c2.54,0,4.48,0.62,6.64,1.92c-1.68-3.01-5.01-4.57-8.29-5.21c-0.11-0.02-0.08-0.19,0.03-0.19c3.66-0.05,8.39,2.99,9.13,6.31
      c-1.84-1.1-4.06-1.97-6.05-2.17c0.91,0.57,2.56,2.04,2.56,2.91c-2.28-1-5.53-1.54-8.02-1.29
      C257.48,77.89,257.87,77.61,258.01,77.59z"/>
    <path d="M258.37,78.29c3.18,0.14,8.45,2.08,8.75,3.98c-2.17,0.1-4.74,0.33-6.78,1.1c-0.24,0.09-0.05-0.27,0.12-0.43
      c1.4-1.39,3.83-1.14,5.74-1.14c-2.19-2.1-4.97-2.63-7.85-3.37C258.27,78.4,258.29,78.28,258.37,78.29z"/>
    <path d="M251.06,98.38c0.27-0.44,0.74-0.77,1.05-0.55c0.3,0.22,0.19,1.02-0.11,1.35C251.69,99.5,250.79,98.82,251.06,98.38z"/>
    <path d="M253.64,81.7c1.39-0.1,4.1,0.56,4.46,2.17c0.06,0.26,0.06,0.54-0.17,0.4c-1.68-1.02-2.94-1.59-4.41-2.11
      C253.27,82.07,253.41,81.71,253.64,81.7z"/>
    <path d="M240.08,84.49c0.47-0.74,1.02-1.39,1.73-1.89c0.6-0.42,1.63-0.85,2.39-0.71c0.14,0.02,0.26,0.28,0.14,0.35
      c-1.64,0.94-2.72,1.68-3.92,2.6C240.23,84.97,239.93,84.72,240.08,84.49z"/>
    <path d="M250.35,149.83c2.51,0.14,5.04,0.14,7.57,0.25c-0.03,1.88,0.46,2.99,2.73,3.47c4.02,0.17,10.98-0.02,12.02-0.04
      c0.88-0.02,2.03,0.03,2.84-0.41c0.95-0.54,1.18-1.44,1.29-2.42c2.56,0.07,5.3-0.05,7.87-0.19c0.33-0.01,0.34-0.47,0.01-0.52
      c-2.99-0.41-6.1-0.35-9.11-0.37c-2.8-0.02-5.61-0.14-8.41-0.16c-5.6-0.03-10.3-0.21-15.9-0.09
      C250.35,149.36,250.19,149.82,250.35,149.83z M258.51,150.11c3.09,0.14,6.19,0.24,9.27,0.26c2.87,0.03,5.78,0.2,8.69,0.29
      c-0.41,1.04-0.73,2.02-2.12,2.07c-2,0.07-9.62,0.06-11.45,0.1c-1.04,0.03-2.29,0.1-3.24-0.38
      C258.68,151.94,258.58,151.06,258.51,150.11z"/>
    <path d="M249.56,156.07c-0.01-1.87-0.12-3.93,0.37-5.74c0.04-0.12,0.22-0.12,0.22,0c0.01,1.87,0.38,9.32,0.39,11.46
      c0,2.14-0.05,4.29-0.04,6.43c0.01,1.86-0.14,4.28-0.14,6.17c0,0.23-0.49-0.04-0.53-0.47c-0.15-1.74-0.25-3.35-0.23-4.98
      c0.01-2.14-0.06-4.29-0.03-6.43C249.57,160.37,249.58,158.22,249.56,156.07z"/>
    <path d="M250.41,174.98c2.61-0.08,5.61-0.16,8.24-0.26c3.03-0.12,6.06-0.21,9.09-0.32c2.8-0.09,5.6-0.19,8.4-0.29
      c2.73-0.09,6.14-0.01,8.78-0.03c0.26,0-0.06,0.44-0.31,0.49c-2.46,0.56-5.26,0.4-7.78,0.49c-3.02,0.1-6.03,0.2-9.04,0.3l-8.44,0.27
      c-2.84,0.1-5.14,0.08-7.99-0.03C250.31,175.57,250.18,174.99,250.41,174.98z"/>
    <path d="M284.96,157.55c-0.01-2.18,0-4.37,0.3-6.52c0.03-0.2,0.38-0.2,0.4,0c0.3,2.16,0.32,4.36,0.3,6.57
      C285.63,157.6,285.29,157.58,284.96,157.55z"/>
    <path d="M284.98,168.65c0.31-0.01,0.6-0.02,0.94-0.12c0.03,1.71,0.35,3.39-0.22,4.97c-0.17,0.48-0.57,0.55-0.56,0.39
      c0.06-1.56-0.04-3.48-0.16-5.23C284.98,168.66,284.98,168.65,284.98,168.65z"/>
    <path d="M281.47,164.02c-1.25,0.51-1.38,1.6-0.46,2.34c0.53,0.44,1.29,0.56,2.06,0.57c0.03,2.05,2.31,1.7,3.69,1.03
      c0.68-0.33,1.5-0.79,1.86-1.49c0.15-0.3,0.05-0.69-0.21-0.84c0.32-0.24,0.61-0.52,0.84-0.84c0.44-0.6,0.56-1.49-0.15-1.66
      c2.32-1.73-0.59-2.44-0.41-2.31c2.42,1.73-1.79,2.36-2.64,2.39c-1.14,0.04-2.26,0.23-3.41,0.21c-0.74-0.03-1.74,0-2.38-0.46
      c-1.47-1.06,0.88-2.02,1.73-2.08c1.41-0.1,3.3-0.07,4.71-0.1c0.1,0-0.22-0.35-0.58-0.39c-1.18-0.14-3.8-0.39-5.47,0
      c-0.2-0.34-0.06-0.99,0.91-1.43c2.07-0.93,4.63-0.54,6-0.32c1.37,0.22,1.47,1.17,1.2,1.54c-0.04,0.05,0.02,0.12,0.08,0.1
      c1.12-0.41,0.18-1.71-0.78-1.98c-1.6-0.46-4.35-0.52-5.79-0.09c-0.67,0.2-2.79,0.78-2.12,2.27c-0.29,0.17-0.52,0.4-0.68,0.73
      C278.6,162.97,279.96,163.77,281.47,164.02z M283.83,166.9c0.55-0.04,1.08-0.11,1.51-0.15c0.92-0.09,1.89-0.36,2.7-0.87
      c0.26,0.11,0.29,0.41-0.03,0.71c-0.43,0.42-1.11,0.72-1.66,0.96C285.4,167.95,283.84,168.29,283.83,166.9z M282.2,164.09
      c1.93,0,4.49-0.05,6.43-0.76c0,0,0,0,0,0c1.09,0.74-0.11,1.82-0.93,2.17c-2.6,1.12-4.91,0.68-5.69,0.45
      C280.67,165.57,281.06,164.49,282.2,164.09z"/>
    <path d="M253.75,161.03c1.41,0,1.41,2.17,0,2.17C252.35,163.21,252.35,161.03,253.75,161.03z"/>
    <path d="M253.87,164c0.83,0,0.83,1.29,0,1.29C253.04,165.29,253.04,164,253.87,164z"/>
    <path d="M241.66,152.37c1.41,0.97,2.72,2.07,4.1,3.09c1.04,0.77,2.19,1.1,3.04,2.09c0.34,0.38,0.14,0.7,0.11,0.66
      c-0.81-0.86-2.23-1.35-3.12-2.06c-1.46-1.18-2.97-2.24-4.21-3.66C241.52,152.43,241.59,152.32,241.66,152.37z"/>
    <path d="M234.61,159.41c0.75-1.25,1.55,0.31,1.71,1.04c0.1,0.42-0.09,0.55-0.13,0.42c-0.14-0.51-0.51-1.3-0.86-1.35
      c-0.24-0.03-0.26,0.42-0.29,0.54c-0.19,0.86-0.03,1.87,0.42,2.62c0.24,0.41,0.66,0.63,1.13,0.68c0.63,0.06,1.39-0.39,1.69-1.37
      c0.39-1.27,0.41-2.7,0.54-4.08c0.01-0.13,0.61-0.1,0.08,4.27c0.94-0.4,1.35-1.25,1.61-2.35c0.09-0.42,0.48-3.22,0.48-4.31
      c0-0.14,0.26,0.18,0.31,0.58c0.05,0.41,0.09,2.63-0.21,4.04c0.13,1.94,0.52,4.1,2.07,4.23c2.16,0.21-1.29-9.44-1.6-10.46
      c-0.02-0.08,0.22-0.16,0.43,0.36c0.2,0.51,0.68,1.51,1.1,2.48c0.84-0.14,3.28,1.99,3.85,2.29c0.89,0.47,2.02-0.06,2.08-0.65
      c0.01-0.08,0.13-0.03,0.16,0.04c0.5,1.25-1.57,1.74-2.35,1.39c-1.45-0.65-2.41-2.03-3.54-2.39c0.99,3.52,1.81,7.24,0.31,7.65
      c-1.98,0.53-2.66-1.58-2.86-3.73c-0.38,0.94-1.19,1.97-2.22,1.79c-0.11,0.19-0.24,0.37-0.4,0.55c-1.1,1.2-2.87,0.33-3.48-0.88
      C234.13,161.8,234.01,160.42,234.61,159.41z"/>
    <path d="M239.3,151.27c1.46-0.72,2.27-0.48,3.86-0.48c0.87,0.01,3.13-0.91,3.57,0.18c0.62,1.56-1.86,2.5-3,2.55
      c-0.09,0.01-0.09-0.12-0.02-0.16c0.52-0.22,2.27-1.02,2.46-1.49c0.19-0.47-0.01-0.85-0.47-0.85c-0.46,0-2,0.37-2.78,0.38
      c-1.12,0.02-2.32-0.1-3.7,0.15C239.05,151.59,239.14,151.35,239.3,151.27z"/>
    <path d="M229.06,163.95c0.75-1.47,1.78-3.19,3.13-4.23c-0.45-0.63-0.57-2.74-0.4-3.8c0.22-1.32,0.4-2.66,0.7-3.97
      c0.04-0.14,0.23-0.1,0.24,0.04c0.1,1.02-0.11,2.05-0.27,3.07c-0.06,0.38-0.1,0.75-0.14,1.13c0.22-0.48,0.49-0.92,0.76-1.26
      c1.21-1.56,3.05-3.09,5.15-3.37c0.16-0.02,0.63,0.08,0.47,0.13c-1.57,0.61-2.89,1.42-4.17,2.72c-0.69,0.71-1.41,1.48-1.76,2.4
      c-0.41,1.06-0.33,2.2,0.15,3.52c0.08,0.21-0.28-0.07-0.45-0.26c-0.72,1.3-1.71,2.48-2.46,3.77c-0.94,1.64-1.37,3.46-2.17,5.14
      c-0.07,0.15-0.28,0.06-0.29-0.08C227.44,167.22,228.31,165.43,229.06,163.95z"/>
    <path d="M226.01,170.77c0.28-1.93,0.4-3.9,0.65-5.84c0.46-3.62,1.43-7.28,1.72-10.93c0.04-0.46,0.47-0.18,0.45,0.29
      c-0.18,5.35-1.59,10.38-1.97,15.72c-0.03,0.42-0.11,0.84-0.15,1.27c-0.1,1.04,1.01,0.75,1.51,0.28c0.87-0.8,1.5-1.94,2.11-2.95
      c1.2-1.96,2.35-4.08,3.75-5.89c0.07-0.09,0.24-0.04,0.2,0.08c-0.91,2.73-2.71,5.43-4.28,7.84c-0.61,0.94-2.52,3.56-3.82,2.2
      C225.73,172.38,225.93,171.3,226.01,170.77z"/>
    <path d="M259.89,142.47c0.66,2.31,0.97,4.23,1.74,6.51c0.15,0.46-0.53,0.34-0.68-0.12c-0.75-2.28-0.85-4.7-1.6-6.98
      C259.33,141.8,259.72,141.88,259.89,142.47z"/>
    <path d="M234.06,181.86c0.22-0.94,0.33-1.96,0.41-2.9c0.13-1.49,0.13-2.99,0.14-4.49c0.02-1.5,0.09-2.99,0.19-4.49
      c0.12-1.83-0.08-3.71,0.1-5.53c0.02-0.13,0.2-0.22,0.26-0.07c0.61,1.35,0.52,2.88,0.5,4.35c-0.03,1.73-0.13,3.46-0.12,5.18
      c0,1.68-0.08,3.36-0.19,5.05c-0.07,1.07-0.23,2.27-0.73,3.24c1.8,1.39,5.15,1.28,7.18,1.3c2.72,0.03,5.43-0.23,8.14-0.31
      c2.78-0.09,5.49-0.03,8.26-0.4c1.45-0.2,2.81-0.44,4.21-0.89c0.81-0.26,1.6-0.48,2.43-0.54c-0.58-1.69-0.93-3.9-0.76-5.65
      c0.01-0.12,0.23-0.23,0.25-0.07c0.25,2.07,1.14,4.74,1.42,5.85c0.01,0.03,0.01,0.05,0.01,0.08c0.09,0.2,0.05,0.46-0.2,0.5
      c-2.5,0.39-4.82,1.4-7.35,1.76c-3.14,0.45-6.25,0.36-9.41,0.49c-2.91,0.12-5.8,0.36-8.7,0.24c-2.02-0.09-4.79-0.18-6.32-1.8
      C233.46,182.4,233.72,181.93,234.06,181.86z"/>
    <path d="M233.31,226.33c-0.01,0.16,0.09,0.35,0.26,0.39c2.74,0.65,5.55,0.34,8.34,0.23c1.22-0.05,2.44,0.15,3.67,0.12
      c0.61-0.01,1.31-0.56,1.82-0.5c0.17,0.02,0.37-0.14,0.37-0.33c-0.03-0.89,0.24-4.87,0.22-5.14c-0.03-0.27-0.45-0.42-0.68-0.49
      c1.43-4.59,1.21-9.65,1.8-14.62c0.2-1.71,0.62-3.34,1.01-5.02c0.32-1.37,0.5-2.97,1.25-4.19c0.24-0.39,0.51-0.77,0.8-1.13
      c-0.1,3.25-0.45,5.96-0.38,9.19c0.03,1.03,0.37,2.57,0.42,3.61c0.12,2.32,0.63,8.29,0.75,11.3c-1.74,0.9-1.2,6.31-0.88,7.53
      c0.06,0.19,0.26,0.38,0.47,0.31c4.43-1.53,8.66,0.14,13.13-0.32c0.11-0.01,0.16-0.12,0.19-0.21c0.39-2.1,1.33-4.3,1.19-6.47
      c-0.1-1.4-0.85-2.08-2.06-1.32c-0.1-2.94,0.23-5.9,0.02-8.85c-0.22-3.09-0.93-6.13-1.44-9.18c-0.56-3.33-0.32-6.59-0.55-9.93
      c-0.11-1.54-0.5-3.02-0.98-4.48c-0.27-0.83-0.82-2.33-0.9-2.61c-0.09-0.28-0.31-0.4-0.3-0.32c0.17,1.46,0.85,3.33,1.15,4.75
      c0.32,1.51,0.35,2.99,0.32,4.53c-0.07,2.99-0.08,5.9,0.4,8.87c0.2,1.24,0.41,2.48,0.62,3.72c-0.14,0.57-0.35,1.16-0.71,1.53
      c-0.68,0.7-1.6,0.78-2.49,0.45c-0.1-0.04-0.17,0.12-0.07,0.17c0.94,0.43,2.06,0.71,2.93-0.05c0.23-0.2,0.42-0.43,0.57-0.68
      c0.22,1.35,0.42,2.7,0.58,4.05c0.36,3.05-0.04,6.1,0.22,9.16c-1.03,0.06-2.05,0.16-3.08,0.37c-1.49,0.32-3.21,0.8-4.74,0.51
      c-0.56-0.11-1.64-0.44-2.39-0.31c0.01-2.02-0.46-4.11-0.57-6.12c-0.11-2.12-0.26-4.34-0.69-6.43c-0.28-1.37-0.49-2.6-0.49-4.01
      c0-1.02,0.02-2.21,0.07-3.24c0.11-1.96,0.28-3.95,0-5.91c0.67-0.85,1.35-1.68,1.81-2.68c0.06-0.14-0.14-0.26-0.24-0.16
      c-1.32,1.21-2.38,2.41-3.23,4.09c-0.74,1.44-0.99,2.96-1.3,4.45c-0.33,1.61-0.44,2.42-0.78,3.68c-1.41,5.17-1.06,13.4-2.06,15.89
      c-2.03-0.34-4.19,0.21-6.23,0.1c-1.63-0.09-3.09-1.11-4.66-1.25c0.08-0.87-0.44-1.64-0.35-2.55c0.12-1.26,0.63-2.44,0.76-3.7
      c0.35-3.2,0.28-6.42,0.2-9.64c-0.16-6.26,0.87-12.81,0.58-19.08c0-0.08-0.22,0.24-0.23,0.32c-0.42,6.43-1.27,12.89-1.24,19.35
      c0.01,3.25,0.23,6.76-0.58,9.94c-0.45,1.8-0.54,3.63,0.15,5.36c0,0-0.01,0-0.02,0c-0.97,0.1-1.78,0.5-1.85,1.58
      C233.08,222.73,233.41,224.53,233.31,226.33z M253.22,220.28c0.08,0.41,0.11,1.53,0.11,1.64c0,0.23,0.27,0.41,0.45,0.23
      c0.79-0.81,1.79-0.21,2.72-0.1c0.89,0.11,1.81,0.05,2.7-0.1c1.79-0.29,4.64-0.9,5.38-1.1c0.74-0.2,1.07-0.29,1.4-0.39
      c0.06-0.03,0.07-0.14-0.01-0.14c-0.3,0.01-0.61,0.03-0.91,0.04c-0.02-0.23-0.03-0.46-0.04-0.69c0.76-0.25,1.59-0.35,1.68,0.72
      c0.06,0.72-0.01,1.45-0.14,2.17c-0.24,1.39-0.61,2.75-1.03,4.1c-0.13,0.39-6.02-0.68-6.83-0.74c-2.04-0.14-4.08-0.12-5.95,0.74
      C252.43,225.12,251.95,221.11,253.22,220.28z M233.89,221.68c-0.02-2.56,3.01-0.9,4.21-0.48c1.39,0.48,2.82,0.48,4.28,0.35
      c1.66-0.14,3.32-0.2,4.98-0.34c0.52,0.81,0.04,3.4-0.19,4.59c-0.63-0.07-1.26,0.28-1.96,0.25c-1.08-0.04-2.14-0.18-3.22-0.13
      c-2.63,0.11-5.29,0.5-7.91,0.1C234.04,224.57,233.9,223.12,233.89,221.68z"/>
    <path d="M262.04,227.7c0.04-0.13,0.24-0.12,0.25,0.01c0.13,1.21-0.45,2.49-0.55,3.7c-0.13,1.55,2.54,2.29,3.1,2.57
      c0.07,0.03,0.02,0.14-0.04,0.15c-0.91,0.12-3.36-0.65-3.59-1.76C260.91,230.96,261.64,229.01,262.04,227.7z"/>
    <path d="M256.85,227.51c0.02-0.24,0.41-0.53,0.47-0.33c0.42,1.26-0.23,7.19-0.91,8.22c-0.09,0.14-0.4,0.15-0.39-0.03
      C256.06,233.66,256.75,229.14,256.85,227.51z"/>
    <path d="M244.13,227.36c0.01-0.12,0.18-0.12,0.21-0.01c0.36,1.14,0.27,2.77,0.27,3.96c-0.02,1.53,0.11,2.96,0.29,4.2
      c0.04,0.31-0.42,0.21-0.55-0.06C243.24,233.17,243.97,229.81,244.13,227.36z"/>
    <path d="M239.27,232.36c0.24-1.6,0.22-3.42,0.16-5.08c-0.01-0.13,0.18-0.16,0.25-0.05c0.52,0.85,0.5,3.03,0.38,4.44
      c-0.11,1.34-0.42,3.42-1.08,4.17c-0.11,0.13-0.44,0.14-0.4-0.07C238.77,234.65,239.07,233.66,239.27,232.36z"/>
    <path d="M255.57,238.88c0.04,0.13,0.25,0.27,0.26,0.13c0.09-0.71,0.44-2.18,0.46-2.41c2.7,1.56,7.81-0.07,9.53-2.6
      c0.26,0.11,1.01,0.25,1.09,0.25c-0.33,0.45-2.62,3.41-2.85,3.65c-0.09,0.1-0.21,0.25-0.1,0.32c0.75,0.45,2.26,1.22,2.93,1.51
      c0.2,0.09,0.4,0.05,0.53-0.1c0.71-0.89,1.28-1.87,1.75-2.91c0.17-0.39,0.44-0.95,0.62-1.39c0.19,0.07,0.4,0.09,0.55,0.14
      c-0.09,0.3-0.91,2.53-1.03,2.8c-0.11,0.27,0.11,0.5,0.46,0.6c0.35,0.11,2.61,0.58,2.89,0.62c0.27,0.04,0.46-0.05,0.52-0.26
      c0.06-0.2,0.73-1.94,0.86-2.98c0.28,0.07,0.63,0.06,0.85,0.1c0.13,0.02,0.17-0.16,0.08-0.22c-0.23-0.15-0.55-0.3-0.87-0.37
      c0-0.2-0.01-0.56-0.03-0.75c-0.02-0.19-2.84-0.92-3.06-0.83c-0.22,0.1-0.3,0.3-0.46,0.75c-0.15-0.11-0.36-0.12-0.55-0.17
      c0.1-0.33,0.42-1.13,0.16-1.21c-0.26-0.08-2.21-0.5-2.32-0.41c-0.17,0.15-0.49,0.56-0.6,0.7c-0.1-0.09-1.16-0.27-1.46-0.37
      c-1.46,1.1-2.93,2.3-4.85,2.69c-2.34,0.47-3.76,0.18-4.98-0.37C255.47,235.58,255.44,238.41,255.57,238.88z M270.22,238.08
      c-0.02-0.17,1.03-3.12,1.17-3.22c0.14-0.1,2.1,0.34,2.14,0.44c0.04,0.1-0.77,3.2-0.97,3.38
      C272.35,238.85,270.23,238.25,270.22,238.08z M267.95,233.64c0.1-0.13,1.66,0.12,1.73,0.25c0.07,0.14-2.49,5.03-2.66,5.01
      c-0.18-0.02-2.16-0.87-2.12-1.02C264.94,237.74,267.86,233.77,267.95,233.64z"/>
    <path d="M238.15,235.94c0.72,0.7,2.52,1,3.45,1.02c1.32,0.03,2.62-0.6,3.64-1.34c0.24,0.1,0.71,1.06,0.87,1.5
      c0.4,1.12,0.57,2.24,0.65,3.3c0.14,1.77,0.07,3.99-0.62,5.67c-0.21,0.51-0.58,0.78-0.5,0.39c0.34-1.66,0.42-3.8,0.42-5.52
      c0-1.66-0.22-3.34-0.9-4.56c-0.72,0.91-2.09,1.2-3.22,1.26c-2.79,0.14-3.85-0.97-4-1.2C237.8,236.23,237.87,235.67,238.15,235.94z"
      />
    <path d="M229.83,245.66c0.61-0.79,1.34-1.3,2.26-2.07c0.95-0.79,2.04-1.88,2.83-2.87c0.01,0.02-0.19,1.96,0.04,1.95
      c0.23-0.02,3.46,0.49,5.06,0.84c1.38,0.3,4.07,0.99,4.38,1.12c0.32,0.14,0.64-2.44,0.39-2.85c-0.11-0.19-0.45-0.33-0.74-0.48
      c0.13-0.25,0.31-1.75-0.03-1.92c-1.57-0.82-5.43-1.14-7.1-0.67c-0.33,0.09-0.42,1.05-0.42,1.5c-0.34-0.04-0.81-0.02-1.15-0.08
      c0.92-1.24,1.7-2.55,2.27-3.78c0.04-0.09-0.1-0.18-0.16-0.11c-1.3,1.34-2.34,2.94-3.57,4.34c-0.85,0.98-1.76,1.86-2.7,2.72
      c-0.56,0.51-1.3,1.42-1.46,1.77C229.58,245.42,229.59,245.97,229.83,245.66z M243.5,241.23c-0.84-0.34-5.17-0.88-6.43-0.98
      c-0.07-0.41-0.02-0.97,0.14-1.01c2.46-0.68,6.37,0.42,6.46,0.58C243.75,239.97,243.7,240.8,243.5,241.23z M235.52,240.8
      c3.03-0.03,7.57,0.71,8.81,1.24c0.15,0.06-0.1,2.04-0.36,1.88c-0.25-0.15-4.4-1.42-8.51-1.76
      C235.3,242.14,235.33,240.8,235.52,240.8z"/>
    <path d="M232.34,244.32c1.85-0.6,3.83-0.43,5.73-0.2c2.41,0.28,8.79,1,7.77,4.82c-0.05,0.19-0.22,0.38-0.23,0.2
      c-0.15-3.83-4.61-3.88-7.58-4.22c-1.51-0.17-3.38-0.28-4.88-0.03c-0.81,0.13-2.79,1.12-3.05,2.81c-0.03,0.16-0.17-0.15-0.15-0.53
      C229.99,245.74,231.15,244.7,232.34,244.32z"/>
    <path d="M228.06,250.11c-0.18-0.62-0.04-2.34,0.88-3.57c0.18-0.24,0.77-0.6,0.63-0.3c-0.39,0.88-0.92,2.21-0.97,3.18
      c-0.03,0.56,6.37,3.14,8.76,3.33c2.34,0.19,4.71,0.02,7.01-0.45c3.06-0.62,2.27-4.17,1.92-6.33c-0.04-0.21,0.29,0.04,0.35,0.24
      c0.56,1.67,0.98,4.42-0.31,5.87c-1.64,1.83-5.26,1.69-7.51,1.73C234.4,253.89,228.46,251.46,228.06,250.11z"/>
    <path d="M255.52,239.35c0.07-0.06,0.25-0.05,0.23,0.05c-0.12,0.78-0.24,1.71-0.29,2.34c1.73,1.48,4.59,2.08,6.72,2.56
      c2.57,0.57,11.24,1.88,15.8,1.5c1.41-0.12,3.33-0.46,4.4-0.86c1.17-2.81,0.33-7.11-1.48-9.45c-1.5-1.93-3.4-1.44-5,0.86
      c-1.15,1.64-1.68,3.6-1.89,5.46c0.5,0.05,1.01,0.09,1.51,0.18c0.09,0.02,0.1,0.15,0.02,0.15c-0.55,0.06-7.3,0.42-9.97,0.23
      c-3.01-0.21-6.12-1.31-8.82-2.8c-0.38-0.21-0.56-0.55-0.36-0.5c1.47,0.38,3.34,1.26,4.78,1.7c1.63,0.5,3.33,0.7,5.02,0.82
      c2.45,0.17,4.9,0.03,7.34,0.19c0.06-3.3,2.83-10.12,6.93-7.79c1.63,0.92,2.4,3.18,2.89,4.87c0.57,1.97,0.78,4.98-0.31,6.83
      c-0.03,0.11-3.12,0.87-6.58,1.2c-3.02,0.29-13.05-1.35-15.65-1.99c-2.06-0.51-4.41-1.1-5.94-2.57
      C254.82,241.06,255.16,239.65,255.52,239.35z"/>
    <path d="M239.64,137.84c1.26,1.15,3.29,1.2,4.8,0.49c0.07-0.04,0.13,0.07,0.06,0.11c-1.49,0.89-3.88,1.11-5-0.5
      C239.45,137.85,239.57,137.78,239.64,137.84z"/>
    <path d="M242.81,180.66c2.72,0.03,5.42,0.33,8.13,0.49c2.81,0.17,5.51-0.17,8.3-0.21c0.12,0,0.15,0.19,0.03,0.21
      c-2.64,0.38-5.01,0.89-7.7,0.83c-2.95-0.08-5.91-0.37-8.81-0.95C242.55,180.98,242.6,180.66,242.81,180.66z"/>
    <path d="M240.01,209.11c1.35,1.68,3.59,1.04,5.17,0.1c0.15-0.08,0.32,0.09,0.24,0.24c-1.16,1.89-4.9,2.17-5.6-0.22
      C239.78,209.12,239.93,209.01,240.01,209.11z"/>
    <path d="M242.6,211.87c0.77-0.27,1.59-0.44,2.28-0.88c0.06-0.04,0.15,0.04,0.1,0.11c-0.54,0.74-1.45,1.11-2.35,1.02
      C242.49,212.1,242.46,211.91,242.6,211.87z"/>
    <path fill="#FFFFFF" d="M255.75,92c1.29-0.26,2.21-1.96,2.16-3.22c-0.05-1.16-1-2.91-2.99-2.97
      c-1.98-0.06-3.86,2.06-3.44,3.85C251.72,90.67,253.17,92.52,255.75,92z"/>
    <path fill="#FFFFFF" d="M249,89.78c-0.12-1.65-2.64-3.95-5.13-2.72c-2.48,1.22-2.08,2.9-1.74,4.1
      c0.34,1.2,2.38,2.6,4.97,1.86C249.68,92.28,249.08,90.81,249,89.78z"/>
    <path d="M253.85,88.4c0.7,0,1.27,0.57,1.27,1.27c0,0.7-0.57,1.27-1.27,1.27c-0.7,0-1.27-0.57-1.27-1.27
      C252.58,88.97,253.16,88.4,253.85,88.4z"/>
    <path d="M244.35,88.93c0.84,0,1.53,0.68,1.53,1.53c0,0.84-0.69,1.53-1.53,1.53c-0.84,0-1.53-0.69-1.53-1.53
      C242.82,89.61,243.51,88.93,244.35,88.93z"/>
    <path d="M251.55,90.58c0.8,1.81,3.05,2.2,4.56,1.56c1.26-0.52,1.92-1.21,2.08-2.44c0.16-1.23-0.19-2.39-0.68-3.27
      c-0.5-0.88-2.11-2.79-4.16-1.89C251.66,85.29,250.31,87.76,251.55,90.58z M256.54,85.91c-0.62-0.34-1.36-0.5-2.16-0.4
      c-0.92,0.11-1.69,0.55-2.25,1.16c0.15-0.42,0.35-0.82,0.69-1.14C254.14,84.25,255.49,84.84,256.54,85.91z M255.55,86.14
      c1.45,0.4,2.43,1.74,2.1,3.28c-0.18,0.87-0.43,1.43-1.23,1.91c-1.13,0.68-3.05,0.55-3.98-0.61
      C250.78,88.66,252.99,85.44,255.55,86.14z"/>
    <path d="M241.89,91.82c0.8,1.6,3.32,2.21,5.08,1.73c0.86-0.23,1.46-0.52,1.95-1.29c0.66-1.03,0.67-2.1,0.28-3.16
      c-0.86-2.3-3.78-5.81-6.33-4.01C240.84,86.52,240.91,89.85,241.89,91.82z M247.32,87.06c-0.87-0.6-1.95-0.87-3-0.55
      c-1.01,0.31-1.97,1.22-2.5,2.28c0.07-0.98,0.27-1.93,0.78-2.63C243.95,84.34,245.87,85.5,247.32,87.06z M243.86,87.46
      c2.5-1.51,4.92,1.25,4.87,2.82c-0.06,1.56-1.12,2.26-1.8,2.43c-1.27,0.33-2.96,0.2-3.97-0.74
      C241.6,90.72,242.39,88.35,243.86,87.46z"/>
    <path d="M240.85,93.39c1.97-1.01,4.36-0.53,5.99,0.91c0.12,0.1,0.09,0.46-0.07,0.38c-1.43-0.65-3.91-1.3-5.8-0.95
      C240.79,93.76,240.69,93.48,240.85,93.39z"/>
    <path fill="#FFFFFF" d="M243.84,89.38c0.34,0,0.61,0.27,0.61,0.61c0,0.34-0.27,0.61-0.61,0.61c-0.34,0-0.61-0.27-0.61-0.61
      C243.23,89.66,243.5,89.38,243.84,89.38z"/>
    <path fill="#FFFFFF" d="M244.57,90.43c0.11,0,0.21,0.1,0.21,0.22c0,0.12-0.09,0.21-0.21,0.21c-0.12,0-0.21-0.09-0.21-0.21
      C244.35,90.53,244.45,90.43,244.57,90.43z"/>
    <path fill="#FFFFFF" d="M253.4,88.8c0.28,0,0.5,0.22,0.5,0.5c0,0.28-0.22,0.5-0.5,0.5c-0.28,0-0.5-0.23-0.5-0.5
      C252.9,89.02,253.12,88.8,253.4,88.8z"/>
    <path fill="#FFFFFF" d="M254,89.65c0.1,0,0.17,0.08,0.17,0.18c0,0.09-0.08,0.17-0.17,0.17c-0.09,0-0.17-0.08-0.17-0.17
      C253.82,89.73,253.9,89.65,254,89.65z"/>
    <path d="M226.87,153.97c0.8-1.59,1.54-5.8,1.78-10.17c0-0.09,0.38,0.15,0.41,0.6c0.15,1.78,0.06,5.5-0.97,8.18
      c1.8-0.33,3.7-1.04,5.49-1.77c-0.12-0.64-0.48-5.45-0.39-7.19c0.01-0.15,0.23-0.18,0.25-0.03c0.38,2.75,1.13,7.36,1.13,7.69
      C234.58,151.6,226.59,154.54,226.87,153.97z"/>
    <path fill="#FFFFFF" d="M247.92,109.21c0,0-4.84-2.78-8.43-6.1c-3.58-3.32-5.29-5.38-5.83-6.46
      c-0.54-1.08-1.43-2.96-1.43-2.96s-1.17,2.06-1.62,3.86c-0.45,1.79-0.36,2.69-0.36,2.69s1.08,2.69,4.57,5.56
      c3.5,2.87,6.46,4.57,8.43,5.38c1.98,0.8,2.69,1.16,3.32,0.45C247.21,110.92,247.92,109.21,247.92,109.21z"/>
    <path fill="#FFFFFF" d="M240.03,116.03c0,0-1.88-0.81-3.23-1.7c-1.34-0.9-3.32-3.05-4.57-4.39
      c-1.26-1.35-2.69-3.41-2.69-3.41s-0.18,5.74,2.69,8.34C235.09,117.46,240.03,116.03,240.03,116.03z"/>
    <path d="M230.07,112.6c1.3,3.37,4.03,4.87,7.58,4.4c4.58-0.61,8.51-3.2,10.8-7.21c0.01-0.01,0.01-0.02,0.02-0.04
      c2.89,1.15,5.96,1.43,7.48-1.47c0.04-0.08-0.06-0.16-0.12-0.09c-3.58,3.9-9.8-0.56-12.99-2.91c-4.02-2.98-9.1-7.18-10.39-12.2
      c-0.06-0.23-0.28-0.07-0.83,0.99c-0.66,1.28-1.85,4.85-2.26,7.97C228.89,105.54,228.78,109.25,230.07,112.6z M230.48,100.61
      c0.38-2.11,0.86-4.48,1.68-6.52c0.46,2.62,2.79,5.59,4.86,7.6c1.92,1.86,3.98,3.6,6.17,5.12c1.07,0.74,2.63,1.75,4.33,2.54
      c-2.42,3.84-6.64,6.88-11.37,6.75c-3.49-0.1-5.16-2.98-5.76-6.1C229.8,106.92,229.92,103.68,230.48,100.61z"/>
    <path d="M230.69,100.78c2.09,3.09,4.18,5,6.75,6.78c1.29,0.89,2.65,1.63,4.02,2.37c1.55,0.84,2.77,1.25,3.83,1.73
      c0.08,0.04-0.06,0.28-0.5,0.19c-1.12-0.21-2.19-0.68-3.21-1.14c-1.57-0.71-3.08-1.57-4.51-2.52c-2.45-1.64-5.09-4.03-6.46-6.69
      C230.49,101.25,230.52,100.53,230.69,100.78z"/>
    <path d="M230.28,107.06c1.05,1.51,2.86,3.53,4.14,4.85c1.41,1.45,3.09,2.58,4.67,3.39c0.07,0.04,0.04,0.14-0.04,0.15
      c-1.67,0.08-3.38-1.51-4.55-2.55c-1.67-1.47-2.9-3.09-4.16-4.92C230.1,107.63,230.03,106.71,230.28,107.06z"/>
  </g>
  <g id="item_16_">
    <path d="M843.91,33.48c-1.54,2.04-4.42,2.92-5.81,1.84c-1.23-0.95-1.99-2.54-3.27-3.46c-1.94-1.4-5.24-1.58-6.45,0.3
      c-1.24-0.56-2.67-0.8-4.04-0.77c-1.36-0.61-2.85-0.88-4.4-0.64c-0.85,0.13-1.46,0.46-1.87,0.91c-3.49-2.83-6.16-2.59-7.63-2.42
      c-3.79-0.99-7.79-0.16-11.29,1.29c-2.39,0.99-4.68,2.53-6.53,4.34c-1.58,1.55-3.56,4.35-2.25,5.78c-1.51,1.9-3.66,5.15-4.13,7.25
      c-0.89,0.29-1.38,0.79-1.89,1.35c-1.75-1.02-3.45,0.18-4.49,1.43c-1.19,1.43-1.59,4.66,0.54,5.45c-0.5,0.41-1.15,0.84-1.7,1.15
      c0.08,1.33,1.79,2.23,2.64,2.7c0.73,0.4,1.62,0.81,2.48,0.85c0.3,2.81,2.28,5.57,4.25,7.45c1.3,1.24,2.81,2.18,4.39,3.02
      c1.26,0.68,2.41,1.58,3.71,2.17c1.14,0.52,2.72,0.03,3.84-0.29c1.3-0.36,2.58-0.9,3.76-1.56c0.91-0.51,1.76-1.06,2.56-1.65
      c2.3,5.24,1.12,9.97,1.05,10.24c-1.42,0.2-4.53,0.74-5.22,1.66c1.2,0.37,3.84,2.6,4.36,4.01c-1.57,0.69-6.51,4.68-9.93,9.45
      c-0.53-0.25-4.16-1.47-5.56-1.89c-2.66-0.8-5.35-1.45-8.07-1.99c-2.35-0.47-6.34-0.81-8.06,1.27c-0.49,0.6-0.76,1.2-0.63,2.27
      c0.44,3.45,3.52,8.32,4.46,12.68c-1.69,0.81-2.21,3.58-2.14,5.21c0.11,2.45,0.71,4,1.85,4.38c1.14,0.38,2.95,0.77,3.2,0.78
      c1.7,2.71,8.62,7.9,9.92,8.67c-0.09,3.31,0.51,7.29,0.96,10.86c0.48,3.78,0.47,7.4,0.07,11.18c-0.41,3.86-0.85,7.71-1.31,11.57
      c-0.23,1.95-0.47,3.9-0.7,5.84c-0.23,1.96-0.32,4.49-1.19,6.29c0.49,0.56,3.93,0.75,5.6,0.88c-0.58,5.88,0.67,12.04,1.69,17.81
      c1.08,6.1,2.36,12.15,3.11,18.3c0.6,4.93,0.92,13.19,0.96,16.3c0.03,3.11-0.84,12.04-1.35,12.94c-0.51,0.9-2.72,2.76-4.18,3.77
      c-1.75,1.21-3.61,2.24-5.53,3.15c-3.81,1.81-8.44,3.28-12.41,4.69c-0.38,1.15,3.55,4.43,8.91,4.09c-0.43,0.22-5.15,2.68-6.51,3.45
      c0.41,1.39,1.59,2.59,6.46,2.57c2.76-0.01,6.33-0.42,8.97-1.19c2.6-0.76,5.1-1.95,7.27-3.57c0.75-0.55,1.9-1.51,2.77-2.58
      c0,0.72,0.07,2.24,0.13,2.95c1.69,0.85,3.62,0.51,4.62-0.06c0.4-6.94-1.33-13.77-0.08-20.69c0.62-3.39,1.59-6.71,2.39-10.06
      c0.83-3.46,1.36-6.88,1.52-10.43c0.32-7.15-0.4-14.34-1.7-21.37c-0.63-3.4-1.8-6.68-2.05-10.14c-0.19-2.64,0.9-6.85,0.9-9.49
      c1.5-0.11,7.88-1.32,11.88-0.88c-0.3-1.29-2.05-16.34-3.07-21.23c-0.97-4.67-2.34-9.32-5.51-13c-1.24-1.44-2.67-2.7-3.74-4.28
      c-1.45-2.15-1.35-4.86-1.24-7.35c0.23-5.38,1.74-11.19,1.55-16.59c1.48-3.96,2.58-7.43,3.59-11.54c0.63-2.57,1.48-5.5,0.79-8.15
      c-0.24-0.91-0.86-1.84-1.88-2.32c0.01-0.36,0.08-2.97,0.23-3.56c0.32-1.35,1.17-3.34,2.16-4.77c-0.41-0.24-1.43-0.36-1.88-0.39
      c-0.18-3,0.37-4.61,1.05-7.51c0.36-1.54,0.71-3.08,1.08-4.62c0.26-1.1,0.61-2.19,0.94-3.28c0.11,1,0.52,2.01,1.01,2.63
      c0.69,0.87,1.51,1.52,1.98,2.56c0.68,1.48,0.18,2.76-1.38,3.29c1.2,0.94,2.92,0.28,3.43-1.13c0.52-1.45,0.86-3.45-2.34-6.49
      c-0.81-0.77,0.19-2,0.79-2.64c1.61-1.72,3.56-3.11,4.53-7.05c0.58,0.27,1.19,0.46,1.82,0.55c-0.11,2.07-0.88,3.65-1.87,5.51
      c-0.91,1.72-1.88,3.53-1.82,5.53c0.06,2.04,1.62,3.68,2.74,5.25c1.89,2.64,0.36,6.68,0.05,7.02c0.85,0.26,3.12-0.84,3.5-5.58
      c0.19-2.47-1.75-3.95-2.57-6.09c-0.8-2.08,0.37-4.56,1.38-6.33c0.86-1.51,1.77-3.8,0.91-5.44c1.19-0.2,1.96-0.57,2.59-1
      c2.02-1.35,3.4-3.68,4.18-5.9c0.93-2.63,1.09-5.76,0.45-8.48c-0.39-1.67-1.46-4.86-3.72-4.23c-0.65-1.11-1.44-2.15-2.4-3.01
      c0,0,0,0,0.01,0c1.24-1.2,2.81,0.17,3.8,1.01c1.06,0.89,2.02,1.84,3.36,2.32c2.16,0.77,4.46,0.18,5.9-1.44
      C843.56,34.73,844.24,33.78,843.91,33.48z"/>
    <path fill="#D9E9F3" d="M786.53,48.62c-0.19,0.38-1.57,3.55-3.09,5.04C783.3,52,784.12,49.1,786.53,48.62z"/>
    <path fill="#FFFFFF" d="M794.24,53.79c-0.41,0.26-0.96,0.46-1.36,0.51c0-1.09-0.18-2.75-0.49-3.45
      c0.24-0.03,0.45-0.03,0.7-0.04C792.55,51.79,792.96,53.6,794.24,53.79z"/>
    <path fill="#E7ABA8" d="M792.13,50.29c-0.51-1.07-1.29-1.64-2.2-2.21c0.83-1.35,3.52-3.16,5.95-1.54
      c1.1,0.73,1.16,2.41,0.86,3.65C795.35,50.03,793.53,50.08,792.13,50.29z"/>
    <path fill="#E7E7E8" d="M792.32,53.22c0.12,2.68-0.86,4.57-2.03,4.69c1.13-1.82,2.11-7.12-1-9.43
      C790.45,48.51,792.17,50.06,792.32,53.22z"/>
    <path fill="#FFFFFF" d="M790.85,54.07c-1.46-0.13-2.18-1.29-2.27-2.6c0.7-0.2,1.37-0.37,1.95-0.41
      C790.85,52.02,790.98,53.19,790.85,54.07z"/>
    <path fill="#ECBDBB" d="M790.38,50.53c-0.68,0.09-1.62,0.43-1.81,0.49c0.02-0.67,0.21-1.36,0.53-1.95
      C789.67,49.47,790.08,49.86,790.38,50.53z"/>
    <path fill="#F9E2D6" d="M787.85,51.27c-0.59,0.19-1.11,0.3-1.51-0.19c-0.14,0.8,0.93,0.81,1.53,0.61
      c0.11,1.54,1.06,2.93,2.87,3.06c-0.13,0.83-0.58,2.59-1.28,3.41c-3.22,0.6-5.7-1.04-5.92-3.94c0.74-0.69,2.69-3.16,3.38-5.72
      c0.59-0.11,1.21-0.04,1.77,0.3C788.14,49.54,787.85,50.37,787.85,51.27z"/>
    <path fill="#E7E7E8" d="M784.11,49.52c-0.12,0.15-0.31,0.43-0.43,0.62c-0.2-0.53-0.68-0.91-1.5-0.9
      C782.77,49,783.67,49.2,784.11,49.52z"/>
    <path fill="#D9E9F3" d="M779.98,51.36c0.4-0.74,0.95-1.47,1.76-1.77c0.89-0.33,1.68,0.43,1.7,0.99
      c-0.38,0.77-0.64,2.06-0.64,3.29c-0.48,0.74-1.79,1.78-1.99,1.94C779.04,55.12,779.1,52.99,779.98,51.36z"/>
    <path fill="#FFFFFF" d="M775.62,93.08c0.83-1.02,2.38-1.37,3.57-1.43c1.5-0.07,3.53,0.41,4.99,0.71
      c4.28,0.88,11.47,3.05,13.26,3.85c-0.42,0.2-0.81,0.54-1.12,0.9c-5.51-2.08-15.75-4.35-16.21-3.69c2.61,0.23,15.76,3.87,15.86,4.11
      c-0.54,0.74-1.05,2.32-1.08,2.87c-2.13-1.25-9.43-2.41-11.97-2.94C781.41,97.14,773.18,96.05,775.62,93.08z"/>
    <path fill="#FFFFFF" d="M798.2,109.76c0.05,4.08,0.1,8.16,0.43,12.23c-0.14,0.4-0.27,0.8-0.33,1.23
      c-0.48-0.25-1.15-0.65-1.65-0.83c-1.17-6.51-1.57-19.83-1.23-21.58c0.49-2.27,1.99-4.33,2.94-4.44
      C798.09,100.83,798.15,105.29,798.2,109.76z"/>
    <path fill="#6156AB" d="M777.35,101.65c-0.85-2.13-2.09-4.26-2.41-6.32c1.19,1.99,3.55,2.18,5.23,2.52
      c2.4,0.48,5.31,1.04,7.73,1.43c2.12,0.35,5.36,1.04,6.76,1.86c0,5.24,0.38,15.2,1.08,20.56c-0.45-0.18-7.51-3.55-8.34-3.73
      c0.44-0.49,0.18-1.24-0.47-1.74c3.21,0.11,3.56-1,2.59-2.01c0.47,0.1,1.5,0.29,1.85-0.35c0.53-0.97-1.5-2.74-3.48-3.39
      c0.88-0.07,1.14-0.12,1.03-0.64c-0.38-1.84-3.4-2.43-5.32-2.55c-1.18-0.07-3.08-0.19-4.33,0.26
      C779.26,106.68,778.2,103.78,777.35,101.65z"/>
    <path fill="#7D74BA" d="M781.32,99.15c2.27,0.19,6.58,1.04,8.8,1.54c1.15,0.26,3.43,0.92,4.55,1.27c0-0.28,0-0.56,0-0.82
      c-1.4-0.82-4.64-1.51-6.76-1.86c-2.42-0.39-5.32-0.96-7.73-1.43c-1.67-0.33-4.04-0.53-5.23-2.52c0.09,0.57,0.25,1.15,0.45,1.73
      C776.32,98.39,779.36,98.98,781.32,99.15z"/>
    <path fill="#4D4499" d="M788.43,118.36c0.19-0.16,0.23-0.61,0.1-0.99c1.21,0.08,2.84-0.42,2.33-2
      c1.1-0.08,1.19-0.76,0.58-1.69c-0.01,0.06-0.03,0.12-0.06,0.18c-0.35,0.64-1.38,0.45-1.85,0.35c0.97,1.02,0.62,2.12-2.59,2.01
      c0.65,0.5,0.91,1.25,0.47,1.74C787.56,118,787.93,118.15,788.43,118.36z"/>
    <path fill="#4D4499" d="M788.77,109.37c0.07,0.14,0.13,0.3,0.16,0.46c0.11,0.52-0.15,0.57-1.03,0.64
      c0.54,0.18,1.08,0.44,1.58,0.75C789.86,110.45,789.4,109.82,788.77,109.37z"/>
    <path fill="#F5CEBB" d="M779,116.75c-0.82-0.23-1.2-1.15-1.43-1.87c-0.67-2.07,0.09-5.56,1.14-6.44
      c1.05-0.88,3.89-0.84,4.95-0.72c3.27,0.35,4.68,1.53,4.68,2.03c0,0.51-3.47-0.23-4.98-0.35c-3.13-0.25-3.83,0.42-3.81,0.9
      c2.33-0.74,5.52,0.07,7.06,0.3c0.49,0.08,2.3,0.75,3.28,1.4c1.35,0.91,1.21,1.6,0.66,1.67c-0.81,0.1-3.7-0.85-5.71-1.28
      c-2.01-0.43-5.34-0.49-5.46,0.13c2.69,0,4.48,0.41,5.39,0.54c0.91,0.14,3.46,1.12,4.1,1.55c0.53,0.36,0.77,0.8,0.1,0.91
      c-0.52,0.09-2.63,0-3.74-0.37c-1.12-0.37-3.65-0.53-5.58-0.6c-0.03,0.54,3.08,0.66,5.58,1.42c1.31,0.4,2.4,0.88,1.89,1.28
      C785.97,118.15,779.82,116.98,779,116.75z"/>
    <path fill="#BF4585" d="M786.77,116.58c-0.37-0.08-0.73-0.16-1.12-0.16c0,0,0,0-0.01,0c-0.18,0.07-0.13,0.51-0.04,0.65
      c0.13,0.23,0.43,0.35,0.68,0.46c0.36-0.05,0.64-0.14,0.81-0.27C787.37,117.05,787.19,116.82,786.77,116.58z"/>
    <path fill="#BF4585" d="M788.86,114.63c-0.09-0.06-0.21-0.13-0.36-0.2c-0.24-0.08-0.48-0.12-0.7-0.07
      c-0.36,0.08-0.44,0.49-0.19,0.76c0.19,0.2,0.42,0.34,0.66,0.46c0.3,0,0.55-0.01,0.68-0.03
      C789.63,115.43,789.39,114.99,788.86,114.63z"/>
    <path fill="#BF4585" d="M786.22,108.93c0.03,0.3,0.22,0.58,0.49,0.72c0.24,0.12,0.49,0.2,0.75,0.27
      c0.53,0.03,0.88,0,0.88-0.17c0-0.29-0.47-0.8-1.48-1.25C786.58,108.57,786.18,108.52,786.22,108.93z"/>
    <path fill="#BF4585" d="M790.3,112.32c-0.54-0.21-1.17-0.42-1.43,0.05c0.12,0.61,0.73,0.93,1.26,1.15
      c0.12,0.05,0.23,0.11,0.34,0.17c0.03,0,0.05,0,0.08,0C791.03,113.62,791.2,113.07,790.3,112.32z"/>
    <path fill="#F9E2D7" d="M778.69,109.08c0.52-0.71,1.46-0.89,2.85-0.98c1.39-0.1,2.57,0.11,3.91,0.42
      c1.18,0.27,2.48,0.79,2.66,1.39c0.15-0.03,0.23-0.07,0.23-0.15c0-0.51-1.41-1.68-4.68-2.03c-1.06-0.11-3.9-0.16-4.95,0.72
      c-0.61,0.52-1.13,1.93-1.32,3.42C777.65,110.99,778.18,109.78,778.69,109.08z"/>
    <path fill="#D687AF" d="M786.85,108.5c-0.21,0.05-0.51,0.04-0.6,0.23c0.91,0.29,1.72,0.7,1.85,1.17
      c0.15-0.03,0.23-0.07,0.23-0.15C788.33,109.46,787.87,108.95,786.85,108.5z"/>
    <path fill="#F5CEBB" d="M795.99,173.43c1.48,0.12,2.65,0.19,4.13,0.29c-0.03,2.22-0.03,4.46,0.02,6.7
      c-1.19-0.2-2.78,0.13-3.91,0.49C795.99,178.42,795.78,175.64,795.99,173.43z"/>
    <path fill="#9089C4" d="M796.3,181.3c1.05-0.44,3.24-0.55,3.81-0.49c0.01,0.39,0.07,1.05,0.08,1.44
      c-0.94-0.28-2.85-0.14-3.76,0.49C796.38,182.28,796.35,181.76,796.3,181.3z"/>
    <path fill="#6156AB" d="M796.68,184.52c-0.09-0.41-0.16-1.15-0.2-1.51c1.08-0.61,2.57-0.72,3.72-0.5
      c0.02,0.58,0.05,1.16,0.08,1.74C799.18,183.96,797.68,183.96,796.68,184.52z"/>
    <path fill="#9089C4" d="M796.73,184.79c0.79-0.38,2.43-0.5,3.55-0.19c0.02,0.4,0.08,0.97,0.1,1.37
      c-1.07-0.3-2.54-0.16-3.52,0.08C796.8,185.63,796.73,185.15,796.73,184.79z"/>
    <path fill="#6156AB" d="M801.67,226.3c0.05-4.76-0.12-9.66-0.57-14.4c-0.59-6.2-3.82-23.17-4.17-25.55
      c1.07-0.27,2.5-0.23,3.45,0.01c0.14,1.86,0.36,3.98,0.61,5.82c0.87,6.52,2.44,12.92,3.02,19.49c0.51,5.78,0.57,11.81-0.16,17.56
      c-0.33,2.61-1.15,6.81-2.69,10.06c-0.21-0.14-0.68-0.39-0.88-0.55C801.02,237.05,801.61,231.06,801.67,226.3z"/>
    <path fill="#453B8F" d="M804.01,211.66c-0.58-6.57-2.15-12.97-3.02-19.49c-0.14-1.07-0.28-2.23-0.4-3.39
      c-0.38,4.05,0.67,11.14,1.53,16.74c0.86,5.6,1.3,14.73,0.91,20.94c-0.3,4.81-1.68,11.41-2.35,12.54c0.18,0.1,0.36,0.21,0.47,0.28
      c1.54-3.25,2.36-7.45,2.69-10.06C804.58,223.47,804.53,217.45,804.01,211.66z"/>
    <path fill="#9089C4" d="M808.61,181.13c-2.95-0.76-5.91-0.14-7.21,0.39c-0.02-0.43-0.07-0.92-0.1-1.35
      c2.14-0.76,5.78-0.95,7.37,0.06C808.64,180.53,808.62,180.83,808.61,181.13z"/>
    <path fill="#6156AB" d="M808.74,184.27c-1.98-1.18-4.99-0.78-7.2-0.24c-0.04-0.74-0.06-1.36-0.1-2.1
      c1.03-0.38,4.23-1.22,7.17-0.2C808.6,181.9,808.67,183.71,808.74,184.27z"/>
    <path fill="#9089C4" d="M801.53,184.47c2.93-0.84,5.51-0.64,7.27,0.32c0.03,0.23,0.07,0.46,0.11,0.69
      c-1.67-1.06-5.4-0.47-7.31,0.01C801.6,185.13,801.51,184.73,801.53,184.47z"/>
    <path fill="#F5CEBB" d="M808.74,179.6c-1-0.37-4.08-0.82-7.44,0.14c-0.14-1.98-0.13-4.02-0.46-5.97
      c1.87,0.12,3.74,0.2,5.62,0.19c1,0,2-0.04,3-0.1C809.32,175.74,808.95,177.73,808.74,179.6z"/>
    <path fill="#DDB19E" d="M796.7,174.34c0.73,0.14,1.42,0.41,2.03,0.85c0.54,0.38,0.99,0.82,1.37,1.32
      c0-0.93,0.01-1.86,0.02-2.79c-1.49-0.1-2.65-0.17-4.13-0.29c-0.03,0.28-0.04,0.56-0.06,0.86
      C796.18,174.27,796.44,174.28,796.7,174.34z"/>
    <path fill="#DDB19E" d="M801.1,175.4c0.62-0.07,1.25-0.11,1.88-0.12c1.14-0.01,2.29,0.04,3.41,0.21
      c0.89,0.14,1.87,0.33,2.81,0.64c0.1-0.76,0.19-1.53,0.24-2.27c-1,0.06-2,0.1-3,0.1c-1.88,0-3.75-0.07-5.62-0.19
      c0.09,0.54,0.16,1.09,0.21,1.64C801.06,175.4,801.08,175.4,801.1,175.4z"/>
    <path fill="#66574A" d="M799.72,242.66c0.4-0.77,0.75-1.55,1.04-2.35c-0.36-0.22-0.7-0.48-1.05-0.73
      c-0.49,0.64-1.08,1.24-1.71,1.79C798.49,241.99,799.06,242.46,799.72,242.66z"/>
    <path fill="#66574A" d="M797.72,241.61c-0.68,0.57-1.4,1.1-2.08,1.59c-1.66,1.2-3.44,2.24-5.28,3.15
      c-1.36,0.67-2.75,1.28-4.15,1.85c1.27,1.08,1.86,3.37,1.53,4.93c0.32-0.03,0.64-0.06,0.97-0.11c5.63-3.28,8.88-6.5,10.83-10.02
      C798.84,242.77,798.24,242.27,797.72,241.61z"/>
    <path fill="#66574A" d="M785.81,248.36c-2.28,0.91-4.6,1.73-6.92,2.56c0.84,1.2,4.09,2.53,8.46,2.24
      C787.72,251.7,787.11,249.29,785.81,248.36z"/>
    <path fill="#877870" d="M798.44,241.86c0.67-0.65,1.26-1.3,1.76-1.94c-0.16-0.11-0.32-0.23-0.48-0.34
      c-0.49,0.64-1.08,1.24-1.71,1.79C798.14,241.54,798.29,241.7,798.44,241.86z"/>
    <path fill="#877870" d="M797.72,241.61c-0.68,0.57-1.4,1.1-2.08,1.59c-1.66,1.2-3.44,2.24-5.28,3.15
      c-1.36,0.67-2.75,1.28-4.15,1.85c0.25,0.21,0.47,0.47,0.66,0.76c1.83-0.8,3.66-1.69,5.16-2.52c2.57-1.42,4.57-2.87,6.12-4.32
      C798.01,241.96,797.86,241.79,797.72,241.61z"/>
    <path fill="#877870" d="M785.81,248.36c-2.28,0.91-4.6,1.73-6.92,2.56c0.15,0.21,0.37,0.43,0.67,0.64
      c1.48-0.26,4.19-1.24,6.97-2.44C786.32,248.81,786.08,248.55,785.81,248.36z"/>
    <path fill="#66574A" d="M789.25,253.85c-1.14,0.62-2.3,1.22-3.45,1.81c-0.97,0.5-3.89,1.9-4.32,2.15
      c0.74,1.32,3.28,1.42,5.88,1.22c1.29-0.1,2.74-0.31,4.16-0.6C791.91,256.4,791.33,254.29,789.25,253.85z"/>
    <path fill="#66574A" d="M807.89,244.46c-0.03-0.39-0.05-0.84-0.06-1.23c-0.5,0.21-1.06,0.31-1.6,0.34
      c-1.19,0.07-2.26-0.17-3.3-0.72c-0.58-0.31-1.24-0.6-1.78-1.02c-0.48,1.11-1.06,2.29-1.79,3.31c-2.33,3.31-4.72,5.57-7.82,7.42
      c-0.6,0.36-1.21,0.7-1.82,1.04c1.93,0.64,2.52,2.72,2.18,4.76c1.09-0.23,2.16-0.51,3.11-0.83c2.23-0.73,4.44-1.72,6.29-3.18
      c0.83-0.65,3.2-2.69,3.74-4.68c0.14-0.14,0.91-0.03,1.15,0.24c0,0.68,0.07,4.13,0.17,5.31c0.24,0.1,1.45,0.41,2.22,0.06
      C808.68,251.64,808.16,248.07,807.89,244.46z"/>
    <path fill="#66574A" d="M803.4,242.66c1.06,0.5,3.05,0.75,4.41,0.18c-0.05-1.27-0.04-2.53,0.03-3.78
      c-1.3,0.9-4.71,0.49-5.42-0.45c-0.25,0.63-0.62,1.68-1.11,2.86C801.89,241.99,802.76,242.36,803.4,242.66z"/>
    <path fill="#877870" d="M802.03,241.98c0.41-1.08,0.76-2.11,1.04-2.9c-0.29-0.13-0.52-0.29-0.66-0.47
      c-0.25,0.63-0.62,1.68-1.11,2.86C801.52,241.66,801.77,241.82,802.03,241.98z"/>
    <path fill="#877870" d="M801.15,241.82c-0.48,1.11-1.06,2.29-1.79,3.31c-2.33,3.31-4.72,5.57-7.82,7.42
      c-0.6,0.36-1.21,0.7-1.82,1.04c0.39,0.13,0.72,0.32,1,0.55c4.09-2.4,6.83-4.82,9.26-8.14c0.76-1.03,1.38-2.38,1.9-3.69
      C801.64,242.16,801.38,242,801.15,241.82z"/>
    <path fill="#877870" d="M789.25,253.85c-1.14,0.62-2.3,1.22-3.45,1.81c-0.97,0.5-3.89,1.9-4.32,2.15
      c0.14,0.26,0.36,0.47,0.62,0.64c3.29-1.47,5.99-2.78,8.28-4.1C790.07,254.12,789.7,253.95,789.25,253.85z"/>
    <path fill="#6156AB" d="M810.54,192.71c1.65,7.1,2.25,14.59,1.9,21.86c-0.16,3.3-0.63,6.52-1.41,9.72
      c-0.82,3.4-2.54,10.52-3.01,13.63c-1,0.86-4.53,0.64-5.11-0.27c0.84-2.32,1.79-5.59,2.23-8.93c0.59-4.45,0.82-9.72,0.38-15.56
      c-0.51-6.69-1.94-13.29-3-19.91c-0.26-1.62-0.87-5.13-0.93-7.48c2.11-0.52,5.6-0.98,7.39,0.19
      C809.4,188.24,810.02,190.48,810.54,192.71z"/>
    <path fill="#6156AB" d="M807.1,137.02c0.99-0.28,1.95-0.62,2.88-1.05c-0.54-0.7-1.01-1.46-1.32-2.29
      c-0.03-0.07-0.05-0.14-0.07-0.21c-0.87,0.41-1.76,0.75-2.66,1.03c-2.68,0.84-5.47,1.2-8.27,0.93c-1.58-0.15-3.23-0.33-4.7-0.94
      c0.14,0.98,0.29,1.96,0.43,2.94C797.83,138.99,802.66,138.27,807.1,137.02z"/>
    <path fill="#BF2463" d="M818.08,150.83c-0.97-4.81-2.76-9.02-6.13-12.62c-0.59-0.63-1.19-1.27-1.74-1.94
      c-1.17,0.55-2.41,0.97-3.68,1.3c-4.29,1.12-8.82,1.71-13.07,0.29c0.55,3.85,0.6,7.54,0.23,11.41c-0.39,4.08-0.85,8.16-1.31,12.23
      c-0.23,2.08-0.46,4.15-0.69,6.23c-0.15,1.42-0.25,2.79-0.74,4.1c5.3,0.43,10.62,1.04,15.93,0.89c2.77-0.08,5.5-0.43,8.25-0.76
      c1.94-0.24,4.17-0.62,6.18-0.36C820.59,168.28,819.16,156.23,818.08,150.83z"/>
    <path fill="#BF2463" d="M798.55,135.14c2.6,0.17,5.35-0.29,7.8-1.18c0.71-0.26,1.42-0.56,2.11-0.89
      c-0.59-1.99-0.5-4.11-0.34-6.17c0.39-5,1.44-9.55,1.9-14.55c-0.9,2.37-1.73,4.37-2.64,6.73c-0.62,1.62-3.52,7.46-4.06,8.87
      c-0.57,1.51-1.32,4.24-3.42,3.99c-1-0.11-1.86-0.75-2.63-1.35c-0.96-0.75-1.85-1.46-2.9-2.09c-0.77-0.46-1.53-0.95-2.26-1.46
      c0.16,2.36,0.46,4.69,0.8,7.02C794.64,134.86,796.71,135.03,798.55,135.14z"/>
    <path fill="#A11A4D" d="M800.69,134.52c2.38-0.2,3.4-3.12,4.69-6.57c0.74-1.96,2.11-3.6,3.22-5.36
      c0.47-3.4,1.09-6.7,1.42-10.23c-0.9,2.37-1.73,4.37-2.64,6.73c-0.62,1.62-3.52,7.46-4.06,8.87c-0.57,1.51-1.32,4.24-3.42,3.99
      c-1-0.11-1.86-0.75-2.63-1.35c-0.96-0.75-1.85-1.46-2.9-2.09c-0.77-0.46-1.53-0.95-2.26-1.46c0.07,1.02,0.16,2.04,0.28,3.05
      C794.91,131.09,797.99,134.74,800.69,134.52z"/>
    <path fill="#D75785" d="M793.62,150.01c-0.37,3.83-0.81,7.66-1.23,11.49c-0.23,2.08-0.46,4.15-0.69,6.23
      c-0.15,1.42-0.25,2.79-0.74,4.1c0.66,0.05,1.32,0.11,1.97,0.17C793.23,169.66,794.28,156.34,793.62,150.01z"/>
    <path fill="#D75785" d="M808.46,133.08c-0.53-1.79-0.51-3.67-0.39-5.53c-0.74,2.34-0.74,4.28-0.38,5.88
      C807.95,133.32,808.21,133.2,808.46,133.08z"/>
    <path fill="#D75785" d="M818.08,150.83c-0.97-4.81-2.76-9.02-6.13-12.62c-0.59-0.63-1.19-1.27-1.74-1.94
      c-0.36,0.17-0.72,0.32-1.09,0.47c0.33,0.5,0.67,0.94,0.98,1.31c1.6,1.93,3.04,3.5,3.99,5.27c1.05,1.97,2.2,5.54,2.75,7.7
      c1.13,4.47,1.52,9.08,2.47,13.58c0.49,2.32,1.11,5.91,2.01,7C820.59,168.28,819.16,156.23,818.08,150.83z"/>
    <path fill="#877EBF" d="M809.98,135.97c-0.54-0.7-1.01-1.46-1.32-2.29c-0.03-0.07-0.05-0.14-0.07-0.21
      c-0.26,0.12-0.53,0.24-0.8,0.35c0.27,1,0.68,1.86,1.13,2.59C809.28,136.28,809.63,136.13,809.98,135.97z"/>
    <path fill="#BF2463" d="M814.2,80.66c-0.62,1.24-1.08,2.48-1.28,3.9c-1.24-0.32-3.47,0.18-3.36,0.74
      c0.98-0.24,2.14-0.29,3.11-0.01c-0.16,0.47-0.26,2.49-0.19,3.02c-0.44-0.01-1.58-0.02-1.32,0.55c3.47-0.24,3.44,3.35,3.23,4.9
      c-0.39,2.87-1.49,5.78-2.33,8.54c-1.7,5.57-3.64,11.03-5.75,16.45c-0.54,1.38-3.22,7.28-3.72,8.56c-0.51,1.28-1.32,3.9-2.98,3.18
      c-0.88-0.38-4.33-2.78-5.17-3.25c-3.58-2-10.82-7.24-11.9-9.06c1.17,0.18,2.78,0,4.13,0.05c2.3,0.88,9.5,4.51,13.16,7.18
      c0.26-0.3-0.31-1.41-0.81-1.77c0.46-1.3,5.07-12.2,6.17-15.3c1.03-2.9,1.64-5.93,2.35-8.91c0.65-2.71,2.15-6.65,2.45-7.96
      c-0.48,0.13-2.4,4.8-3.1,7.41c-0.75,2.81-1.42,5.64-2.35,8.4c-0.9,2.67-2.01,5.3-3.06,7.92c-0.59,1.48-2.07,4.99-2.2,5.27
      c-0.41-3.88-0.68-20.11-0.69-24.29c-0.25-0.03-1.16-0.43-1.45-0.57c3.05-4.25,7.95-8.14,10.69-9.59c-0.72-1.6-2.64-3.58-4.29-4.19
      C804.71,81.09,811.15,80.34,814.2,80.66z"/>
    <path fill="#D75785" d="M813.91,81.27c0.09-0.2,0.19-0.4,0.29-0.61c-3.05-0.33-9.49,0.42-10.67,1.17
      c0.3,0.11,0.6,0.26,0.91,0.45C806.8,81.55,809.99,81.18,813.91,81.27z"/>
    <path fill="#D75785" d="M806.57,86.77c-2.81,1.8-6.81,5.19-9.44,8.85c0.12,0.06,0.34,0.16,0.58,0.26
      C799.74,93.2,803.38,89.65,806.57,86.77z"/>
    <path fill="#A11A4D" d="M783.54,119.36c1.38,0,2.77-0.03,4.14-0.18c0.29-0.03,0.59-0.07,0.89-0.11
      c-0.79-0.37-1.46-0.66-1.92-0.84c-1.35-0.05-2.96,0.13-4.13-0.05C782.71,118.49,783.06,118.89,783.54,119.36z"/>
    <path fill="#D75785" d="M805.98,108.64c0.97-2.61,2.66-11.55,2.78-13.31c-0.46,1.38-0.93,2.87-1.22,4.1
      c-0.72,2.99-1.32,6.01-2.35,8.91c-0.91,2.57-4.23,10.48-5.6,13.86c0.51-0.72,2.71-4.62,3.26-5.85
      C803.96,113.82,805.01,111.25,805.98,108.64z"/>
    <path fill="#D75785" d="M811.54,88.85c1.73,0.89,2.65,2.36,2.84,4.91C814.58,92.27,814.61,88.91,811.54,88.85z"/>
    <path fill="#A11A4D" d="M798.61,98.86c0.07,6.18,0.32,18.33,0.67,21.62c0.13-0.28,1.61-3.79,2.2-5.27
      c0.04-0.09,0.07-0.18,0.11-0.28c0.68-5.14-0.19-10.3-0.6-15.43C800.21,99.25,799.41,99.05,798.61,98.86z"/>
    <path fill="#F5CEBB" d="M815.26,66.52c-0.51,1.82-0.97,3.66-1.42,5.49c-0.7,2.81-1.13,5.44-0.93,7.67
      c-1.67,0.01-3.33,0.16-4.85,0.33c1.39-4.33,0.03-8.71-1.26-10.4c1.87-1.46,4.98-4.55,4.3-5.62c-1.87,2.69-4.73,4.94-7.55,6.54
      c-0.97,0.55-1.99,0.99-3.04,1.37c-1.15,0.41-2.9,1.03-4.08,0.44c-1.15-0.58-2.19-1.4-3.34-2.01c-1.32-0.69-2.62-1.4-3.8-2.32
      c-1.21-0.95-2.23-2.14-3.1-3.4c-0.82-1.19-1.22-2.5-1.72-3.81c0.78-0.1,0.78-0.65,0.61-0.69c-2.46-0.47-4.83-1.65-5.55-2.68
      c0.78-0.39,3.04-2.26,3.35-2.72c0.43,2.9,2.47,4.86,5.9,4.53c2.55-0.25,3.71-2,4.08-4.29c1.94-0.18,3.54-1.78,4.3-3.65
      c3.37,0.52,4.05-1.48,3.22-2.44c-0.59,1.73-2.42,1.41-2.89,1.42c0.29-1.25,0.19-2.53-0.43-3.54c-1.82-2.95-6.54-1.26-7.76,1.16
      c-0.97-0.2-1.69-0.31-2.37-0.17c0.51-1.71,3.45-7.02,7.59-10.14c1.6,0.9,3.11,2.67,3.89,3.68c2.16,2.83,3.37,6.22,4.86,9.42
      c2.67,5.7,7.35,10.63,13.79,11.23C816.13,63.25,815.7,64.98,815.26,66.52z"/>
    <path fill="#DDB19E" d="M810.52,68.54c0.62-1.2,0.59-2.37,0.54-3.63c-0.56,1.39-2.79,3.54-4.26,4.69
      c0.36,0.47,0.72,1.15,1.03,1.97c0.13-0.1,0.26-0.21,0.38-0.32C809.11,70.49,809.97,69.6,810.52,68.54z"/>
    <path fill="#DDB19E" d="M788.52,59.91c1.38-0.04,2.83-0.56,3.64-1.74c0.69-1.01,0.89-2.13,0.76-3.24
      c-0.02,0-0.05,0.01-0.07,0.01c-0.37,2.29-1.52,4.04-4.08,4.29c-3.24,0.32-5.24-1.42-5.81-4.06C783.02,58.03,785.7,60,788.52,59.91z
      "/>
    <path fill="#F9E2D7" d="M780.13,58.04c1.08-0.52,2.56-2.49,2.48-3.06c-0.69,0.69-2.43,2.1-3.1,2.44
      C779.66,57.62,779.87,57.83,780.13,58.04z"/>
    <path fill="#DDB19E" d="M794,70.39c0.81-0.09,1.45-0.67,1.78-1.39c0.53-1.15,0.57-2.58-0.14-3.66
      c-0.18,0.76-0.48,1.54-1.01,2.11c-0.27,0.29-0.55,0.54-0.91,0.7c-0.29,0.13-0.82,0.16-1.02,0.44c0.01,0.02,0.01,0.04,0.01,0.07
      C792.69,69.44,793.02,70.49,794,70.39z"/>
    <path fill="#DDB19E" d="M798.69,43.74c1.86,3.58,2.82,6.91,4.74,10.42c1.73,3.17,3.83,5.51,7.12,7.16
      c1.9,0.96,4.42,1.32,6.07,1.31c0.13-0.25,0.27-0.5,0.43-0.73c-6.43-0.6-11.12-5.53-13.79-11.23c-1.5-3.2-2.7-6.59-4.86-9.42
      c-0.77-1.01-2.29-2.78-3.89-3.68c-0.21,0.15-0.41,0.32-0.61,0.48C795.83,39.37,797.69,41.83,798.69,43.74z"/>
    <path fill="#F9E2D7" d="M793.43,38.46c-3.57,3.15-6.05,7.69-6.51,9.25c0.33-0.07,0.66-0.08,1.03-0.05
      C789.19,44.44,791.62,40.86,793.43,38.46z"/>
    <path fill="#D9660A" d="M826.96,61.64c-0.8,1.39-1.49,3.35-1.42,4.99c0.1,2.29,2.24,3.74,2.79,5.87
      c0.36,1.37-0.03,4.23-0.86,4.54c0.21-1,0.35-2.34-0.36-3.99c-1.06-2.46-2.63-3.13-3.3-5.43c-0.69-2.4,1.24-4.92,2.27-6.93
      c0.67-1.31,1.52-3.09,1.12-4.62c0.34,0.02,0.69,0.01,1.05-0.02C828.88,57.2,828.66,58.68,826.96,61.64z"/>
    <path fill="#BF520D" d="M826.89,58.95c0.37,0.21,0.77,0.38,1.18,0.49c0.64-1.57,0.63-2.56,0.19-3.38
      c-0.36,0.03-0.71,0.04-1.05,0.02C827.46,56.99,827.24,58,826.89,58.95z"/>
    <path fill="#D9660A" d="M835.85,41.29c0.5,3.15,0.1,5.69-1,7.21c0.73-3.51-0.04-7.2-1.46-10.47
      c-0.19-0.44-0.4-0.88-0.63-1.31C834.21,36.04,835.49,39.03,835.85,41.29z"/>
    <path fill="#E89351" d="M835.07,38.93c0.2,0.43,0.39,0.86,0.56,1.3c-0.53-2.02-1.63-4.09-2.87-3.51
      c0.11,0.19,0.21,0.39,0.3,0.59C834.05,37.3,834.66,38.08,835.07,38.93z"/>
    <path fill="#D9660A" d="M832.53,38.03c1.56,3.41,2.21,7.51,1.73,11.91c-1.02,2.07-2.66,3.78-4.81,4.62
      c-2.39,0.94-5.9-0.45-7.67-2.25c-0.48,0.61,1.78,2.44,2.62,2.92c-0.83,2.56-3.26,5.36-4.17,6.25c-1.13,1.12-2.21,1.95-1.6,3.63
      c0.41,1.11,1.59,1.3,2.29,2.8c1.25,2.68,0.26,4.78-1.07,4.6c0.87-0.83,0.95-2.07,0.34-3.4c-0.41-0.9-1.03-1.49-1.65-2.24
      c-0.64-0.77-1.09-1.83-1.1-2.84c-0.01-0.84,0.55-1.38,0.86-2.07c0.43,0.01,0.97-0.39,0.73-0.43c-3.72-0.61-6.92-1.84-9.65-4.11
      c-2.99-2.48-4.66-6.04-6.2-9.51c-1.31-2.94-2.64-5.99-4.85-8.39c-1.59-1.73-4.43-4.05-6.54-2.34c0.72-0.31,1.5-0.19,2.28,0.16
      c-1.38,0.84-2.53,1.87-3.54,3.07c-0.45-2.34,1.47-4.42,3.13-5.76c1.77-1.43,4.3-3.01,6.42-3.83c2.73-1.06,4.93-1.32,7.84-1.13
      c3.05,0.2,5.97,1.72,8.39,3.5c0.12-0.37-0.84-1.92-4.56-3.42c1.8-0.16,5.06,1.52,5.81,2.62c-0.52,1.22-0.24,2.87,0.35,4.24
      c0.71,1.63,1.87,3.08,2.72,4.64c0.56,1.01,1.03,2.07,1.41,3.16c0.53,1.53,1.01,3.83,0.7,6.13c2.27-1.72,0-7.91-2.46-11.97
      c-0.83-1.37-3.56-5.52-1.06-6.78c1.45-0.73,3.35-0.45,4.72,0.2c1.54,0.73,3.05,2.09,4.12,3.39c2.28,2.78,3.62,6.9,3.71,10.48
      c1.43-0.98-0.35-7.95-2.92-10.98c-0.87-1.02-1.89-1.97-3.03-2.72c1.29,0.18,2.53,0.5,3.67,1.46
      C830.86,34.82,831.8,36.43,832.53,38.03z"/>
    <path fill="#E89351" d="M792.1,37.1c-0.1,0.03-0.19,0.06-0.29,0.1c0.19-0.15,0.38-0.27,0.57-0.36c1.9-2.06,5-3.99,8.26-5.38
      c3.2-1.36,6.86-1.54,10.16-0.61c1.11,0.31,3.14,0.97,4.56,1.69c-2.21-1.48-4.77-2.65-7.42-2.82c-2.92-0.19-5.12,0.07-7.84,1.13
      c-2.12,0.82-4.64,2.4-6.42,3.83c-1.66,1.34-3.58,3.42-3.13,5.76c0.12-0.14,0.24-0.28,0.37-0.42
      C790.96,39.14,791.28,37.89,792.1,37.1z"/>
    <path fill="#E89351" d="M817.49,32.68c0.03-0.09,0.06-0.17,0.1-0.26c-0.75-1.1-4.01-2.78-5.81-2.62
      c0.46,0.18,0.87,0.37,1.25,0.55C814.32,30.56,816.62,31.81,817.49,32.68z"/>
    <path fill="#E89351" d="M820.47,32.5c1.49-0.37,3.28,0.34,4.56,1.1c1.75,1.04,4.32,3.65,5.62,6.47
      c-0.62-1.71-1.49-3.33-2.57-4.64c-1.07-1.3-2.58-2.66-4.12-3.39c-1.37-0.64-3.27-0.93-4.72-0.2c-1.34,0.68-1.18,2.18-0.61,3.65
      C818.55,33.89,819.15,32.83,820.47,32.5z"/>
    <path fill="#E89351" d="M834.19,43.83c-0.29-2.08-0.85-4.03-1.66-5.8c-0.73-1.6-1.67-3.21-3.03-4.36
      c-1.13-0.96-2.37-1.28-3.67-1.46c0.34,0.22,0.67,0.47,0.99,0.72C829.67,33.47,832.64,37.82,834.19,43.83z"/>
    <path fill="#E89351" d="M806.34,51.89c-0.76-2.51-1.53-5.01-2.61-7.41c-1.68-3.73-4.37-8.04-8.74-8.24
      c1.48,0.42,2.95,1.72,3.94,2.8c2.2,2.4,3.54,5.44,4.85,8.39c0.92,2.08,1.9,4.19,3.17,6.08C806.72,52.99,806.51,52.45,806.34,51.89z
      "/>
    <path fill="#BF520D" d="M821.88,52.96c0.08,1.55,0.75,2.85,1.87,3.8c0.27-0.51,0.49-1.02,0.66-1.53
      C823.78,54.87,822.36,53.75,821.88,52.96z"/>
    <path fill="#BF520D" d="M808.74,45.09c-0.83-1.84-1.87-3.28-2.21-3.23c1.07,2.72,2.88,8.35,3.81,10.67
      c0.55,1.38,1.26,2.76,2.33,3.83c0.28,0.28,1.38,1.24,2.72,1.5c-2.27-1.76-3.33-3.91-3.95-5.54
      C810.79,50.65,809.47,46.73,808.74,45.09z"/>
    <path fill="#BF520D" d="M817.19,38.33c0.48,1.57,1.51,2.84,2.49,4.13c0.89,1.17,1.81,2.36,2.41,3.72
      c0.39,0.89,0.63,1.79,0.77,2.72c-0.03-1.7-0.4-3.3-0.8-4.45c-0.38-1.09-0.85-2.15-1.41-3.16c-0.86-1.56-2.01-3-2.72-4.64
      c-0.35-0.81-0.59-1.72-0.62-2.58C816.92,35.47,816.76,36.93,817.19,38.33z"/>
    <path fill="#BF520D" d="M817.92,41.56c-1.02-1.36-1.66-3.03-1.77-4.72c-0.56,2.53-0.57,5.26,1.41,7.17
      c0.94,0.91,2.16,1.55,2.99,2.57c0.33,0.41,0.59,0.85,0.8,1.31C821.1,45.47,819.36,43.49,817.92,41.56z"/>
    <path fill="#D9660A" d="M835.47,34.81c-1.31-0.99-3.98-3.76-5.5-1.66c0,0,0,0.01,0,0.01c-0.39-0.34-0.83-0.62-1.29-0.85
      c1.34-1.47,3.41-1.27,5.12-0.54c1.64,0.7,2.29,2.14,3.39,3.41c0.74,0.85,1.63,1.15,2.55,1.1
      C838.22,36.46,836.63,35.69,835.47,34.81z"/>
    <path fill="#E89351" d="M835.78,34.18c0.56,0.7,1.28,1.52,2.6,1.88c-0.43-0.18-0.83-0.46-1.2-0.89
      c-1.1-1.27-1.74-2.71-3.39-3.41c-1.71-0.73-3.78-0.93-5.12,0.54c0.1,0.05,0.2,0.11,0.3,0.16
      C831.24,30.64,834.17,32.14,835.78,34.18z"/>
    <path d="M798.05,118.45c-0.17-2-1.08-15.12-1.69-17.48c-0.44,0,0.14,15.39,0.95,21.98c0.07,0.6,0.36,0.51,0.34,0.34
      c-0.81-6.9-1.1-13.18-1.25-19.28c0.34,2.06,1.21,14.05,1.66,17.11C798.25,121.19,798.22,120.45,798.05,118.45z"/>
    <path d="M795.65,98.94c-0.31-0.7-11.46-3.3-14.16-3.67c-0.05,0.43,13.02,3.34,13.85,3.78C795.43,99.09,795.69,99.05,795.65,98.94z"
      />
    <path fill="#FFFFFF" d="M795.33,50.86c-0.39,0-0.7,0.31-0.7,0.7c0,0.39,0.31,0.7,0.7,0.7c0.39,0,0.7-0.31,0.7-0.7
      C796.02,51.17,795.71,50.86,795.33,50.86z"/>
    <path fill="#FFFFFF" d="M794.45,51.91c-0.11,0-0.2,0.09-0.2,0.2c0,0.11,0.09,0.2,0.2,0.2c0.11,0,0.2-0.09,0.2-0.2
      C794.65,52,794.56,51.91,794.45,51.91z"/>
    <path d="M796.31,41.11c-0.86-1.04-2.32-1.37-3.2-0.47c1,0.32,2.9,1.09,4.18,3.5C797.65,43.75,797.33,42.34,796.31,41.11z"/>
    <path fill="#D77369" d="M792.6,66.94c-0.25,0-1.06,0.32-0.64,1.24c0.42,0.92,1.5,1.32,2.62,0.46
      c1.66-1.27,1.68-2.29,1.91-3.78c0.31-2-0.53-4.47-0.75-3.26c-0.21,1.2-0.88,3.49-1.31,4.21C793.77,66.94,793.17,66.94,792.6,66.94z
      "/>
    <path d="M797.08,58.38c-0.94,0.82-1.83,1.62-2.81,2.34c-0.33-1.36-0.76-1.98-1.45-1.99c-1-0.01-1.52,1.18-1.82,2.13
      c-0.11-0.63-0.41-1.41-1.02-0.85c-0.92,0.83-1.67,3.21-1.49,4.46c0.39-0.05,1.35-0.47,2.08-0.79c0,0.7,0.6,2.69,1.39,3.34
      c-0.24,0.13-0.48,0.48-0.48,0.86c0,0.74,0.48,1.11,1.15,1.35c1.75,0.64,3.95-1.34,3.61-2.83c-0.23,0.63-0.66,1.27-1.51,1.87
      c-0.51,0.36-1.47,0.63-2.01,0.37c-0.29-0.14-0.6-0.33-0.67-0.69c-0.05-0.25,0.04-0.55,0.35-0.66c0.44,0.15,1.34,0.2,2.04-0.81
      c0.99-1.42,1.72-5.46,1.71-6.4C796.36,59.78,797.3,58.67,797.08,58.38z"/>
    <path fill="#D77369" d="M788.89,63.7c0.1-0.82,0.45-2.47,1.19-3.35c0.4-0.47,0.77,0.8,0.8,0.95
      c-0.15,0.45-0.07,0.76-0.01,0.62c0.36-0.72,0.82-2.38,1.66-2.65c0.79-0.25,1.34,0.98,1.43,1.67c-0.36,0.25-0.74,0.49-1.14,0.72
      C791.54,62.39,790.24,63.09,788.89,63.7z"/>
    <path fill="#FFFFFF" d="M791.81,66.02c0.42,0.63,1,0.94,1.67,0.42c0.61-0.47,1.03-1.35,1.37-2.4
      C794.56,64.22,792.1,65.75,791.81,66.02z"/>
    <path fill="#FFFFFF" d="M795.29,62.26c0.12-0.53,0.28-1.28,0.39-1.8c-0.67,0.68-3.74,2.58-4.67,3.02
      c0.04,0.51,0.1,0.93,0.33,1.49C792.4,64.41,794.74,62.88,795.29,62.26z"/>
    <path fill="#E5A199" d="M794.98,66.33c0.43-0.63,0.95-0.17,0.43,0.49C795,67.33,794.59,66.89,794.98,66.33z"/>
    <path d="M788.42,110.14c-0.48-0.11-0.93-0.25-1.43-0.48c-0.28-0.13-0.55-0.31-0.67-0.61c-0.25-0.59,0.62-0.55,1.01-0.38
      c0.06,0.03-0.06-0.11-0.11-0.14c-0.22-0.14-0.83-0.14-0.98,0.08c-0.18,0.27-0.1,0.47,0.08,0.72c0.4,0.57,1.41,0.81,2.06,0.92
      C788.44,110.26,788.49,110.16,788.42,110.14z"/>
    <path d="M790.8,113.81C790.81,113.81,790.81,113.81,790.8,113.81c0.02-0.03,0.04-0.04,0.06-0.06c-0.33-0.13-0.65-0.27-0.97-0.44
      c-0.34-0.18-0.82-0.45-0.93-0.84c-0.2-0.74,1.57-0.05,1.81,0.1c0.04,0.03-0.03-0.15-0.08-0.17c-0.31-0.12-1.52-0.66-1.81-0.23
      c-0.2,0.29,0.05,0.65,0.18,0.77c0.43,0.4,0.97,0.71,1.59,0.94C790.7,113.84,790.75,113.81,790.8,113.81z"/>
    <path d="M789.45,114.85c-0.69-0.46-1.96-0.91-2.03-0.13c-0.05,0.55,1.26,1.12,1.61,1.09c0.07-0.01,0.18-0.03,0.12-0.05
      c-0.4-0.12-1.1-0.37-1.39-0.67c-0.5-0.5,0.04-0.7,0.49-0.63c0.34,0.05,0.91,0.36,1.18,0.51
      C789.49,115.01,789.52,114.9,789.45,114.85z"/>
    <path d="M786.98,117.76c0.06-0.03,0.12-0.05,0.18-0.07c-0.43-0.12-0.89-0.21-1.27-0.47c-0.13-0.09-0.27-0.28-0.31-0.43
      c-0.1-0.39,0.51-0.34,0.71-0.3c0.44,0.1,0.66,0.17,0.94,0.31c0.03,0.02,0.06-0.11,0.02-0.13c-0.33-0.15-1.92-0.69-1.81,0.14
      c0.08,0.54,0.86,0.83,1.5,0.97C786.96,117.78,786.96,117.77,786.98,117.76z"/>
    <path fill="#E89351" d="M801.94,40.05c1.49,2.21,2.88,5.25,3.63,7.68c0.62,2.02,1.66,5.32,2.11,6.4
      c0.61,1.49,1.63,2.56,2.96,3.57c-1.47-2.08-2.24-4.05-3.01-6.64c-0.5-1.67-1.12-3.89-2.03-6.19
      C804.07,41.04,802.34,39.92,801.94,40.05z"/>
    <path fill="#E89351" d="M799.74,32.79c2.86-2,8.2-3.08,14.5,0C810.95,32.51,806.88,31.15,799.74,32.79z"/>
  </g>
  <g id="item_15_">
    <path d="M564.49,210.69c-0.6,0.17-2.72,0.17-3.86,0.22c-1.52,0.06-3-0.24-4.47-0.59c-0.66-0.15-1.31-0.3-1.91-0.61
      c-0.73-0.38-1.61-1.29-2.12-1.9c-0.69-0.82-0.68-1.94-1.29-2.77c-0.15-0.21-0.48,0.01-0.33,0.22c0.01,0.01,0.01,0.02,0.02,0.03
      c-0.19,1.68-0.26,2.87,0.09,4.52c0.26,1.24,1.05,2.56,2.21,3.07c1.64,0.72,5.73,1.13,8.32,1.08c1-0.02,2.57-0.24,3.22-0.4
      C564.57,212.97,564.77,211.19,564.49,210.69z"/>
    <path fill="#6B6B70" d="M564.01,213.06c-3.12,0.85-9.76,0-11.4-0.99c-0.75-0.45-1.42-1.52-1.7-3.05
      c-0.29-1.54-0.05-2.44-0.12-3.26c0.8,3.06,3.12,4.42,4.35,4.77c2.85,0.83,6.05,1.05,8.99,0.63
      C564.18,211.58,564.23,212.44,564.01,213.06z"/>
    <path d="M552.45,202.71c-0.88-0.07-2.06,0.2-2.95,0.26c-2.23,0.17-4.45,0.37-6.68,0.37c-2.22,0-11.15-0.71-11.69-1.17
      c-0.11-0.09-0.2,1.41,0.17,2.09c3.58,1.44,15.25,2.28,20.92,2.07c0.1,0,0.18-0.07,0.19-0.18
      C552.48,205.16,552.61,202.72,552.45,202.71z"/>
    <path fill="#6B6B70" d="M552.08,205.78c-7.42,0.31-19.03-1.07-20.64-1.88c-0.05-0.17-0.13-1.04-0.12-1.31
      c0.3,0.23,8.4,1.14,10.74,1.18c2.12,0.04,4.2-0.13,6.31-0.27c0.69-0.05,3.78-0.32,3.8-0.32C552.22,204,552.4,205.07,552.08,205.78z
      "/>
    <path fill="#505056" d="M552.18,203.18c-0.02,0-3.11,0.28-3.8,0.32c-2.11,0.14-4.19,0.31-6.31,0.27
      c-0.86-0.02-2.51-0.15-4.27-0.33c1.01,0.43,2.01,0.95,2.93,1.46c0.32,0.18,0.64,0.36,0.96,0.55c3.51,0.29,7.31,0.45,10.39,0.33
      C552.4,205.07,552.22,204,552.18,203.18z"/>
    <path d="M581.2,95.89c-1.33-2.18-6.25-9.61-7.6-11.41c-0.46-1.2-5.15-16.62-5.77-19.95c-0.65-3.49-0.74-12.58-0.93-17.87
      c-0.09-2.32-0.21-6.79-0.85-10.47c-1.15-6.72-2.77-14.93-9.48-18.24c-3.52-1.74-7.96-1.39-11.66-0.4
      c-4.08,1.09-8.9,3.24-11.39,6.67c-1.73,2.39-2.96,4.68-3.61,6.32c-0.72,1.81-1.29,4.32-1.6,6.33c-0.64,4.28,0.33,8.48,0.97,12.71
      c0.7,4.64,0.63,9.16,0.98,13.83c0.16,2.12,0.55,4.41,1.2,6.13c0.72,1.9,1.14,4.01,1.16,5.86c-0.75,1.04-3.81,4.4-5.68,6.46
      c-1.87,2.06-3.59,3.74-5.05,5.33c-0.54,0.59-1.05,1.42-0.84,2.29c0.25,1.02,1.59,1.74,2.38,2.2c2.99,1.71,7.35,4.7,8.68,5.72
      c1.33,1.02,3.35,2.85,3.94,3.66c1.04,1.42,2.15,3.12,1.83,5.3c-0.98,2.01-1.51,4.45-1.61,7.81c-0.34,0.08-0.66,0.19-1.02,0.29
      c-0.34-1.65-1.74-2.5-2.4-2.07c-0.57-0.6-1.61-0.85-1.75,0.17c-0.1,0.71,0.86,2.07,1.31,3.02c0.53,1.11,1.33,2.06,2.29,1.8
      c0.39-0.1,2.73-0.72,3.56-0.86c-0.58,2.86-0.9,4.99-1.01,7.9c-0.12,3.07,0.09,5.38,0.39,8.44c0.22,2.22,1.66,9.52,2.19,13.01
      c0.53,3.49,0.87,6.76,1.12,9.48c0.25,2.71,0.35,6.58,0.36,9.19c0.01,2.61,0.11,4.78,0.45,8.05c0.34,3.27,1.34,8.48,2.32,12.01
      c-0.29,0.11-0.41,0.25-0.4,0.44c0.01,0.19,1.69,10.89,1.72,12.32c-2.6,0.57-11.65,2.6-14.08,3.76c-0.68,0.33-0.84,1.28-0.03,2
      c0.62,0.55,11.06,2.09,18.98,2.06c0.06,1.35,0.36,3.55,0.94,4.55c0.71,1.23,1.84,1.64,3.83,2.06c3.9,0.81,6.24,0.72,7.72,0.51
      c1.48-0.2,2.9-0.61,2.38-2.14c-1.28-3.76-5.59-9.3-6.3-10.44c-1.12-1.82-2.34-4.14-2.77-5.99c-0.43-1.85-0.94-6.67-0.58-11.23
      c0.04-0.5-0.6-0.75-0.71-0.8c1.01-4.18,1.85-9.89,2.13-15.53c0.18-3.57,0.93-10.66,1.26-12.53c0.33-1.87,2.92-16.94,3.65-20.37
      c0.29-1.36,0.53-3.06,0.69-4.44c0.26,0.44,0.7,0.7,1.05,0.49c0.34-0.21,0.56-1.47,0.63-1.76c0.98,0,1.59-1.62,1.89-3.57
      c1.07-0.13,1.26-3.74,1.22-6.33c-0.03-2.59-0.13-4.46-0.67-6.28c0.81-1.26,4.7-3.03,7.34-4.63c0.8-0.49,5.33-3.69,6.48-4.98
      C581.88,100.16,582.7,98.36,581.2,95.89z M574.36,97.56c-2.24,1.47-4.22,4.79-5.53,6.49c-1.22,1.58-3.09,3.77-4.15,4.47
      c-1.66,1.1-2.46,2.87-2.98,3.92c0.02-0.49,0.04-1.21,0.06-1.62c0.15-0.02,0.64-0.21,0.6-0.33c-0.82-2.49-1.39-6.43-1.2-9.17
      c0.19-2.75,3.71-16.65,3.99-17.61c2.13,2.28,5.83,6.75,7.39,9.45C573.79,95.3,574.63,97.38,574.36,97.56z M534.33,93.35
      c-1.07-1-4.77-4.68-6.08-5.2c1.11-1.76,3.21-3.79,5.37-5.55c-0.07,0.55,0.3,3.32,0.96,4.85c0.67,1.56,1.59,2.82,3.27,3.45
      c0.3,2.55,0.84,5.07,1.45,7.76C537.88,96.11,535.4,94.35,534.33,93.35z"/>
    <path fill="#B33C2B" d="M538.63,79.04c-1.09,4.13-1.22,7.73-0.86,11.27c-1.41-0.77-2.38-2.33-2.81-3.81
      c-0.4-1.37-0.65-3.01-0.64-4.45C536.11,80.64,537.78,79.5,538.63,79.04z"/>
    <path fill="#7A1A0A" d="M537.89,82.68c0.17-1.17,0.41-2.38,0.74-3.64c-0.85,0.46-2.52,1.6-4.3,3.02
      c-0.01,0.61,0.04,1.26,0.12,1.91C535.5,83.21,536.67,82.8,537.89,82.68z"/>
    <path fill="#A32426" d="M531.16,64.06c-0.44-4.69-0.42-9.39-1.11-14.06c-0.67-4.5-1.57-9.03-0.95-13.59
      c0.53-3.87,2.4-8.02,4.39-10.99c2-2.96,5.38-5.19,9.27-6.5c3.9-1.32,8.82-2.31,12.7-0.73c6.96,2.84,8.83,11.54,9.93,18.14
      c0.38,2.3,0.53,5.29,0.68,7.61c0.14,2.33,0.37,7.17,0.22,9.24c0,0.32,0.01,0.63,0.01,0.95c0.05,3.62,0.21,7.26,0.77,10.84
      c0.51,3.26,1.58,6.41,2.65,9.53c0.56,1.63,2.91,8.73,3.13,9.41c-3.75-0.23-7.17-6.97-8.48-11.79c0.43-4.01,0.72-7.37,0.2-8.22
      c-0.17,1.87-2.72,27.26-6.16,37.94c-2.27-3.74-2.7-8.29-2.91-12.61c-0.23-4.8,0.57-15.44-0.25-15.44
      c-0.39,1.12-1.83,7.07-3.15,10.05c-0.99,2.24-2.48,5.36-4.86,5.89c0.85-2.81,1.36-5.86,1.9-8.68c0.7-3.66,1.18-6.44,1.45-8.68
      c0.71-5.99,0.39-11.68-0.54-17.61c-0.57-3.62-0.96-5.84-1.3-7.96c-0.44-2.77-1.5-7.58-3.11-11.54c-0.86-2.12-2.01-4.4-3.94-5.68
      c-1.27-0.84-2.71-0.84-3.76,0c-0.47-0.3-1.28-0.61-2.1-0.47c-1.3,0.23-2.27,1.35-3.04,2.46c-0.72,1.05-1.08,1.85-1.59,3.33
      c-0.51,1.48-0.6,3.17-0.73,4.64c-0.08,0.92-0.03,2.37,0.05,3.74c0.09,1.36,0.37,3.37,0.7,4.8c0.77,3.43,2.14,6.39,4.63,9.09
      c1.45,1.57,3.52,2.66,5.7,2.03c0.74-0.21,2.04-0.97,3.18-2.22c1.47,1.75,2.27,8.65,2.16,8.93c-0.85,2.07-3.58,3.57-5.38,4.11
      c-1.61-0.09-3.16,0.01-5.28,1.71c-1.58,1.28-2.22,2.06-2.99,3c0-1.36-0.33-3.45-0.91-5.02C531.56,67.58,531.38,66.36,531.16,64.06z
      "/>
    <path fill="#C94F45" d="M550.68,77.92c0.25-1.85,0.64-3.68,0.86-5.53c0.32-2.63,0.48-5.29,0.39-7.94
      c-0.08-2.64-0.25-5.02-0.62-7.64c-0.37-2.65-0.98-6.71-1.46-9.44c-0.85-4.83-1.89-8.76-3.73-13.32c-1.23-3.06-3.36-6.19-6.22-5.08
      c0.6,0.03,1.22,0.24,1.8,0.62c1.93,1.27,3.08,3.56,3.94,5.68c1.61,3.95,2.67,8.77,3.11,11.54c0.34,2.12,0.74,4.33,1.3,7.96
      c0.93,5.93,1.25,11.62,0.54,17.61c-0.27,2.24-0.74,5.03-1.45,8.68c-0.54,2.82-1.05,5.87-1.9,8.68
      C548.99,87.85,550.27,80.89,550.68,77.92z"/>
    <path fill="#C94F45" d="M533.84,74.05c0.15-3.55-1.2-5.31-1.61-7.88c-0.51-3.24-1.08-9.83-1.78-12.6
      c0.3,3.49,0.38,7,0.7,10.5c0.22,2.29,0.39,3.51,1.19,5.67c0.58,1.57,0.91,3.65,0.91,5.02C533.45,74.51,533.64,74.28,533.84,74.05z"
      />
    <path fill="#C94F45" d="M530.99,31.95c1.54-3.85,3.97-7.3,6.88-9.32c1.66-1.15,3.91-2.38,5.86-2.91
      c3.95-1.08,7.91-1.98,11.77-0.43c2.07,0.83,3.5,2.47,4.76,4.27c2.59,3.7,3.92,10.72,4.25,13.39c0.37,2.98,0.76,7.37,0.78,9.54
      c0.04,4.86,0.08,14.33,1.46,20.67c1.07,4.93,3.15,8.79,4.09,10.64c-0.48-1.44-0.91-2.74-1.11-3.32c-1.06-3.11-2.14-6.26-2.65-9.53
      c-0.56-3.58-0.73-7.22-0.77-10.84c0-0.31-0.01-0.63-0.01-0.95c0.15-2.07-0.07-6.91-0.22-9.24c-0.15-2.33-0.3-5.32-0.68-7.61
      c-1.1-6.6-2.97-15.3-9.93-18.14c-3.88-1.58-8.8-0.59-12.7,0.73c-3.89,1.31-7.27,3.54-9.27,6.5c-2,2.96-3.86,7.11-4.39,10.99
      c-0.2,1.43-0.24,2.85-0.19,4.27c0.05-0.82,0.14-1.64,0.3-2.45C529.62,36.09,530.18,33.97,530.99,31.95z"/>
    <path fill="#C94F45" d="M555.38,21.06c-1.61-0.71-3.5-0.41-5.06,0.32c-1.51,0.7-2.89,1.73-4.14,2.81
      c-1.19,1.03-2.33,2.14-3.21,3.44c1.31,0.3,2.48,1.26,3.44,2.37c1.06-1.1,1.85-2.46,2.95-3.55c1.14-1.12,2.32-2.32,3.74-3.09
      c1.23-0.67,2.52-0.99,3.86-0.48c0.25,0.1,0.55,0.24,0.85,0.42c-0.18-0.22-0.36-0.43-0.55-0.64
      C556.71,22.06,556.14,21.4,555.38,21.06z"/>
    <path fill="#7A1A0A" d="M541.56,59.2c-2.18,0.63-4.25-0.46-5.7-2.03c-2.48-2.7-3.85-5.66-4.63-9.09
      c-0.32-1.44-0.61-3.44-0.7-4.8c-0.06-1.01-0.11-2.07-0.09-2.93c-0.71,5.97,0.61,12.18,2.65,17.79c0.71,1.94,1.58,4.07,3.6,4.97
      c1.32,0.59,2.51,1.06,3.38,2.29c0.86,1.21,1.6,2.84,1.89,4.49c1.78-0.63,4.16-2.06,4.94-3.97c0.11-0.28-0.69-7.18-2.16-8.93
      C543.59,58.24,542.29,58.99,541.56,59.2z"/>
    <path fill="#7A1A0A" d="M564.84,66.75c-0.06,1.46-0.24,3.33-0.46,5.37c0.7,2.56,1.99,5.67,3.62,8.05
      C566.46,75.86,565.65,71.27,564.84,66.75z"/>
    <path fill="#F7D1B8" d="M533.54,42.88c1.57-0.38,2.49-1.91,2.16-3.57c-0.33-1.64-1.73-2.64-3.28-2.38
      c-0.39,0.06-0.78,0.25-1.14,0.51c0.27-2.33,1.08-3.99,1.61-4.99c0.45-0.84,1.44-2.1,2.65-2.63c0.56-0.25,1.29-0.2,2,0.32
      c-0.12,0.13-0.36,0.73-0.21,0.85c0.94-0.99,1.96-1.53,3.04-1.2c1.09,0.33,2.44,1.57,3.89,4.34c1.93,3.69,3.82,12.08,4.18,15.39
      c-1.32,3.5-5.83,11.58-10.72,8.52c-1.41-0.88-2.47-2.29-3.4-3.64c-1.56-2.26-2.33-5.11-2.81-7.23c-0.23-1-0.64-3.69-0.61-4.92
      C531.61,42.87,532.66,43.09,533.54,42.88z"/>
    <path fill="#DDAF99" d="M538.27,52.49c-0.15-0.45-0.33-1.13-0.81-1.33c-0.42-0.18-0.9-0.13-1.35-0.1
      c0.72,0.94,1.21,2.05,1.32,3.23c0,0.01,0.01,0.01,0.02,0.02c0.15,0.23,0.64,0.12,0.82,0.01c0.24-0.16,0.29-0.42,0.27-0.68
      C538.49,53.23,538.39,52.86,538.27,52.49z"/>
    <path fill="#DDAF99" d="M534.21,31.45c0.51-0.67,1.14-1.43,1.96-1.72c0.07-0.02,0.14-0.05,0.22-0.07
      c-0.3-0.02-0.59,0.03-0.84,0.14c-1.2,0.53-2.2,1.8-2.65,2.63c-0.54,1-1.35,2.67-1.61,4.99c0.17-0.12,0.35-0.23,0.53-0.31
      C532.2,35.07,533.04,32.97,534.21,31.45z"/>
    <path fill="#DDAF99" d="M537.47,30.84c2.56-0.82,4.34,0.63,6.64,5.5c1.16,2.46,1.95,5.86,2.58,8.41
      c0.52,2.1,1.03,4.31,1,6.5c0.31-0.65,0.57-1.24,0.76-1.75c-0.35-3.31-2.25-11.7-4.18-15.39c-1.45-2.76-2.8-4.01-3.89-4.34
      C539.35,29.46,538.37,29.93,537.47,30.84z"/>
    <path fill="#FAE5D9" d="M534.42,45.4c-0.36,0.57-0.88,1.01-1.22,1.59c0.3,0.15,0.61,0.26,0.93,0.33
      c2.41-2.68,2.71-6.35,1.68-6.93c0.01,0.31-0.36,2.59-0.55,3.16C535.04,44.2,534.79,44.83,534.42,45.4z"/>
    <path fill="#DDAF99" d="M535.58,47.86c-1.07-0.05-1.5-0.1-2.49-0.54c-0.22,0.23-0.59,0.79-0.4,0.88
      C534.57,49.04,535.79,47.87,535.58,47.86z"/>
    <path fill="#DDAF99" d="M540.18,54.09c-0.67,0.65-1.54,1.43-2.54,1.34c-0.42,0.91,0.52,1.64,1.48,1.64
      c0.81,0,1.63-0.31,2.28-0.79c1.67-1.24,2.24-3.36,1.46-5.27C541.87,51.95,541.16,53.13,540.18,54.09z"/>
    <path fill="#FFFFFF" d="M535.15,39.24c-1.45,0.01-2.94,0.17-4.21,0.72c-0.03,0.54-0.05,1.11-0.01,1.56
      c0.46,0.62,1.22,0.92,2.18,0.78c1.59-0.22,2.13-1.27,2.09-2.65C535.2,39.51,535.18,39.38,535.15,39.24z"/>
    <path fill="#F7D1B8" d="M548.6,50.65c0.73,4.57,1.39,8.9,1.61,13.53c0.09,1.94,0.1,6.71-0.68,10.63
      c-2.14-0.51-3.84-2.42-4.07-6.02c0.94-0.89,2.09-2.13,2.11-3.12c0.03-1.62-0.97-7.48-2.36-9.23
      C547.28,54.23,548.1,52.23,548.6,50.65z"/>
    <path fill="#FAE5D9" d="M547.47,67.6c0.4-0.85,0.27-1.42,0.09-2.32c0.01,0.15,0.01,0.29,0.01,0.4
      c-0.02,0.98-1.18,2.23-2.11,3.12c0.02,0.34,0.06,0.66,0.1,0.97C546.46,68.99,547.1,68.4,547.47,67.6z"/>
    <path fill="#DDAF99" d="M550.21,64.18c-0.22-4.63-0.88-8.96-1.61-13.53c-0.5,1.57-1.32,3.58-3.4,5.79
      c0.26,0.33,0.52,0.81,0.75,1.39c0.89-0.66,1.93-1.2,2.54,0.15c0.31,0.69,0.34,1.51,0.44,2.25c0.18,1.32,0.32,2.64,0.4,3.97
      c0.21,3.43,0.24,7.01-0.32,10.46c0.17,0.06,0.34,0.11,0.51,0.16C550.31,70.89,550.3,66.12,550.21,64.18z"/>
    <path fill="#F7D1B8" d="M534.96,114.53c-0.2,0.06-0.51,0.15-0.72,0.2c-0.09-0.7-0.54-1.55-1.14-2.11
      C533.45,112.37,534.8,113.35,534.96,114.53z"/>
    <path fill="#F7D1B8" d="M534.41,117c-0.47,0.02-0.93-0.23-1.45-1.27c-0.3-0.6-1.29-2.23-1.45-2.83
      c-0.15-0.6,0.17-0.84,0.63-0.59c0.85,0.48,1.52,1.37,1.8,2.5c-0.32,0.05-0.57,0.31-0.53,0.46c1.09-0.18,3.62-0.89,3.94-1.13
      c0.11-0.08-0.45-0.14-0.63-0.08c0.06-4.6,1.53-6.98,1.6-7.31c0.39-1.87-0.23-3.86-1.29-5.45c-0.56-0.83-3.59-3.81-4.5-4.36
      c0.15-1.23,0.67-2.49,1.57-3.1c1.5,0.9,3.7,3.52,4.31,4.31c0.69,0.9,1.05,1.74,1.3,2.37c0.25,0.63,0.76,2.26,0.86,3.85
      c0.09,1.45-0.25,5.51-0.52,6.38c-0.27,0.87-1.06,3.15-1.64,5.28C537.38,116.21,534.88,116.99,534.41,117z"/>
    <path fill="#FAE5D9" d="M533.75,94.18c1.64,1.5,4.74,4.43,5.94,6.35c-0.25-0.63-0.61-1.47-1.3-2.37
      c-0.61-0.79-2.81-3.41-4.31-4.31c-0.13,0.09-0.25,0.19-0.36,0.3C533.73,94.16,533.74,94.17,533.75,94.18z"/>
    <path fill="#DDAF99" d="M539.62,109.94c-0.42,1.32-0.91,2.62-1.44,3.89c-0.36,0.86-0.75,1.7-1.17,2.53
      c0.54-0.14,1.04-0.27,1.37-0.32c0.58-2.13,1.37-4.41,1.64-5.28c0.16-0.51,0.34-2.14,0.45-3.69
      C540.09,107.99,539.92,108.99,539.62,109.94z"/>
    <path fill="#51A191" d="M531.52,202.35c-0.16-0.1-0.35-0.72,0.24-0.96c2.15-0.9,12.71-3.2,14.4-3.46
      c0.03-0.01-1.36-9.89-1.9-12.78c0.49-0.35,2.82-0.92,3.98-0.79c0,0.12,0,0.23,0.01,0.36c0.1,1.37,0.75,9.4,1.11,12.07
      c0.17,1.31,0.63,5.18,0.82,7.24C544.9,204.25,532.95,203.27,531.52,202.35z"/>
    <path fill="#3B8A7D" d="M547.2,184.38c0.11,2.21,0.2,4.42,0.35,6.63c0.18,2.7,0.52,5.38,0.78,8.08
      c0.16,1.64,0.39,3.31,0.54,4.97c0.47,0,0.91-0.01,1.32-0.03c-0.19-2.06-0.64-5.93-0.82-7.24c-0.36-2.67-1.01-10.71-1.11-12.07
      c-0.01-0.12-0.01-0.24-0.01-0.36C547.96,184.33,547.59,184.34,547.2,184.38z"/>
    <path fill="#7DBFAD" d="M531.52,202.35C531.52,202.35,531.53,202.35,531.52,202.35c0.56-0.99,9.94-2.74,11.59-3.16
      c0.86-0.22,2.94-0.82,2.96-1.25c-1.93,0.32-12.2,2.55-14.31,3.44C531.18,201.63,531.37,202.24,531.52,202.35z"/>
    <path fill="#51A191" d="M554.97,182.72c-0.2,2.61-0.29,6.66,0.36,10.19c0.44,2.35,1.08,3.91,2.36,6.18
      c0.57,1.01,7.37,10.67,6.51,11.64c-1.17,1.32-6.4,0.61-9.2,0.01c-0.92-0.19-1.86-0.47-2.56-1.13c-0.68-0.64-0.88-1.67-1.03-2.54
      c-0.28-1.66-0.31-3.37-0.52-5.04c-0.26-2.12-0.63-4.23-0.92-6.35c-0.21-1.56-1.32-12.1-1.11-12.36
      C549.47,182.6,555.06,181.44,554.97,182.72z"/>
    <path fill="#7DBFAD" d="M554.11,182.98c0.09,0.11-0.09,6,0.49,9.54c0.57,3.53,1.43,5.45,2.16,6.62
      c2.38,3.8,7.19,10.45,7.41,10.77c-0.81-2.58-5.98-9.96-6.48-10.83c-1.28-2.27-1.93-3.82-2.36-6.18c-0.66-3.52-0.56-7.58-0.36-10.19
      c0.1-1.27-5.5-0.11-6.1,0.6c-0.03,0.03-0.03,0.25-0.02,0.6C549.25,183.68,553.8,182.57,554.11,182.98z"/>
    <path fill="#434343" d="M562.05,128.27c-0.18,1.7-1.14,5.82-1.51,7.71c-0.37,1.89-3.07,16.8-3.21,17.97
      c-0.14,1.17-0.85,6.22-1.04,9.09c-0.19,2.87-0.25,6.2-0.75,10.32c-0.5,4.11-1.32,7.82-1.38,8.17c-1.12-0.13-4.02,0.57-4.6,0.84
      c-0.1-0.79-1.08-7.48-1.07-10.05c0.01-4.48,0.72-11.43,0.66-13.49c-0.05-2.07-0.42-11.62-0.28-14.74
      c0.06-1.34,0.5-15.65,0.38-17.14c-0.32-4.04-1.12-4.68-1.75-4.74c0.12,0.62,0.62,1.87,0.76,4.53c0.14,2.67,0.14,6.41,0,9.54
      c-0.14,3.13-0.28,10.46-0.25,12.77c0.03,2.7,0.45,9.91,0.26,12.23c-0.2,2.31-0.6,7.15-0.65,10.69c-0.04,2.4,0.23,4.71,0.61,7.07
      c0.2,1.25,0.38,2.49,0.54,3.74c-0.49,0.22-0.48,0.41-0.49,1.15c-1.15-0.14-2.39,0.19-3.59,0.48c-0.44-2.08-1.94-8.87-2.33-14.06
      c-0.39-5.19-0.25-10.2-0.46-13.64c-0.21-3.32-0.71-7.54-1.23-11.49c-0.42-3.15-2.34-11.36-2.53-16.41
      c-0.1-2.62-0.15-6.03,0.19-8.79c0.34-2.76,1.52-6.61,2.1-8.27c0.3-0.87,0.55-1.96,0.58-2.52c4.46-0.82,7.86-0.34,12.56,0.44
      c1.18,0.19,6.07,1.39,7.81,1.16c-0.03,0.35-0.02,2.09-0.03,2.83c-0.2,1.02-0.26,2.08-0.29,3.04c-0.04,1.35-0.33,6.05,1.36,6.08
      C562.36,124.51,562.24,126.57,562.05,128.27z"/>
    <path fill="#323232" d="M548.27,183.92c0.01-0.74,0.01-0.93,0.49-1.15c-0.16-1.25-0.34-2.49-0.54-3.74
      c-0.38-2.36-0.64-4.68-0.61-7.07c0.06-3.54,0.46-8.38,0.65-10.69c0.2-2.31-0.23-9.53-0.26-12.23c-0.03-2.3,0.11-9.63,0.25-12.77
      c0.14-3.13,0.14-6.87,0-9.54c-0.1-1.92-0.38-3.1-0.58-3.85c-1.17,12.09-0.8,30.05-0.8,36.16c0,3.99-2.37,15.46,0.29,24.86
      C547.55,183.87,547.91,183.87,548.27,183.92z"/>
    <path fill="#323232" d="M561.05,116.68c0.02-0.65,0.05-1.35,0.14-2.05c-0.73,2.27-2.11,8.5-0.07,9.89
      c-0.03,1.32-0.36,8.28-0.21,9.73c0.43-2.04,1.01-4.7,1.15-5.99c0.18-1.7,0.31-3.76,0.35-5.51
      C560.72,122.73,561.01,118.03,561.05,116.68z"/>
    <path fill="#5F5F5F" d="M541.64,153.66c0.34-4.16-0.24-8.06-0.91-12.58c-0.8-5.47-1.88-11.61-1.74-16.87
      c0.17-6.31,1.32-10.91,1.49-12.66c-0.02,0.07-0.04,0.14-0.07,0.2c-0.58,1.66-1.76,5.5-2.1,8.27c-0.34,2.76-0.28,6.17-0.19,8.79
      c0.19,5.04,2.11,13.25,2.53,16.41C541.03,148.04,541.39,150.99,541.64,153.66z"/>
    <path fill="#5F5F5F" d="M555.49,159.94c-0.4,3.69-0.6,7.78-0.89,11.48c-0.29,3.76-0.7,7.05-1.42,10.13
      c0.39-0.04,0.73-0.05,0.98-0.03c0.06-0.35,0.88-4.05,1.38-8.17c0.5-4.11,0.56-7.45,0.75-10.32c0.19-2.87,0.9-7.92,1.04-9.09
      c0.06-0.5,0.58-3.47,1.19-6.87C557.07,150.44,555.91,156.07,555.49,159.94z"/>
    <path fill="#F7D1B8" d="M573.6,106.49c-2.09,1.35-6.49,3.21-7.44,4.93c0.23,0.46,0.36,1.16,0.45,1.65
      c0.23,1.28,0.35,3.64,0.29,6.2c-0.06,2.55-0.44,3.8-0.87,4.27c0.11-0.69,0.14-1.74,0.12-2.42c-0.02-0.53-0.26-0.71-0.26-0.56
      c0.01,3.98-0.92,6.46-1.61,6.56c0.13-0.61,0.23-1.22,0.32-1.83c0.16-1.2,0.27-2.43,0.29-3.67c0.02-1.12-0.12-2.62-0.41-2.49
      c0.09,3.11-0.56,8.34-0.79,9.22c-0.22,0.88-0.96,0.34-1.04-0.17c0-0.65,0.16-4.15,0.24-5.85c0.26-0.84,0.62-6.93-0.15-6.77
      c0.22,1.54,0.07,3.62,0.04,4.38c-0.03,0.76-0.09,1.47-0.15,1.88c-0.07,0.54-0.54,0.51-0.85-0.43c-0.2-0.6-0.4-3.09-0.41-4.17
      c-0.01-1.65,0.16-3.64,0.8-4.88c0.64-1.25,1.67-2.67,2.76-3.48c0.73-0.54,2.43-2.28,3.7-3.85c0.99-1.23,1.73-2.33,2.72-3.59
      c2.08,0.8,3.7,1.77,4.59,3.5C575.17,105.46,574.39,105.98,573.6,106.49z"/>
    <path fill="#FAE5D9" d="M563.25,111.38c0.69-0.99,1.76-1.98,2.33-2.35c1.8-1.18,4.48-4.95,5.88-6.63
      c0.19-0.23,0.39-0.46,0.59-0.7c-0.23-0.1-0.46-0.19-0.69-0.28c-1,1.26-1.73,2.36-2.72,3.59c-1.27,1.57-2.97,3.31-3.7,3.85
      c-1.09,0.81-2.12,2.24-2.76,3.48c-0.23,0.46-0.4,1.02-0.53,1.63C562.18,112.91,562.61,112.3,563.25,111.38z"/>
    <path fill="#51A191" d="M579.71,101.78c-1.04,0.95-2.24,1.96-3.4,2.77c-0.72-1.77-2.63-2.94-4.62-3.57
      c0.52-0.76,1.74-2.38,3.17-3.2c0.38-0.21-0.46-2.43-2.01-5.05c-1.52-2.57-5.84-7.88-7.54-9.69c0.12-0.42-0.02-1.34-0.13-1.2
      c-0.2,0.28-4.43,16-4.52,19.9c-0.07,3.04,0.09,5.47,1.09,8.35c-1.79,0.39-5.87-0.39-7.15-0.72c-1.5-0.38-9.72-1.93-13.52-0.78
      c0.08-1.11,0.48-4.49-0.71-7.86c-0.48-2.11-1.41-7.06-1.66-9.1c-0.24-2.04-0.68-5.39,0.06-10.35c0.35-2.35,1.74-6.54,3.24-8.79
      c-0.6-0.53-2.69,3.72-3.18,5.67c-2.53,1.39-8.54,5.59-11.08,9.73c-0.38-0.14-1.1-0.42-1.21-0.01c2.27,0.75,5.36,3.66,7.19,5.67
      c-1.04,0.81-1.47,2.43-1.61,3.15c-1.5-1.14-4.41-3.52-6.8-4.84c-1.14-0.63-2.82-1.27-3.6-2.38c-0.68-0.97,1.06-2.39,1.62-2.97
      c2.6-2.68,7.38-7.72,8.95-9.79c1.56-2.06,3.16-3.83,5.22-5.16c1.82-1.18,2.99-1.01,3.92-0.88c0.84-0.26,2.84-1.11,3.51-1.63
      c-0.09,3.88,1.75,5.8,4.5,6.61c-0.34,2.11-2.34,12.69-2.82,14.73c2.26-0.16,4.18-2.33,5.28-4.4c1.57-2.93,2.45-6.41,3.21-9.65
      c-0.14,4.71-0.69,10.32-0.31,15.03c0.44,5.38,2.18,10.16,3.8,11.38c1.53-3.33,4.9-19.93,5.46-29.57
      c1.57,6.67,6.03,11.38,9.18,11.62c1.6,2.23,6.79,9.8,7.95,11.98C582.36,99,580.4,101.15,579.71,101.78z"/>
    <path fill="#7DBFAD" d="M521.94,89.77c0.17-1.22,2.07-2.74,3.63-4.33c1.2-1.22,2.7-2.77,3.88-3.99
      c1.21-1.25,2.53-2.88,3.57-4.28c1.56-2.09,3.21-3.85,5.42-5.23c1.06-0.67,2.68-0.76,3.16-0.25c0.61-0.19,2.78-1.14,3.37-1.56
      c-0.03-0.33-0.03-0.68-0.02-1.05c-0.67,0.52-2.67,1.37-3.51,1.63c-0.93-0.13-2.1-0.3-3.92,0.88c-2.05,1.33-3.66,3.1-5.22,5.16
      c-1.57,2.07-6.35,7.1-8.95,9.79c-0.56,0.58-2.3,2-1.62,2.97C521.79,89.6,521.86,89.68,521.94,89.77z"/>
    <path fill="#3B8A7D" d="M546.62,92.6c2.45-0.58,4.44-2.25,5.64-4.45c1.05-1.91,2.01-3.94,2.59-6.06
      c0.1-1.97,0.21-3.91,0.27-5.72c-0.76,3.24-1.64,6.72-3.21,9.65c-1.05,1.96-2.83,4.02-4.95,4.35
      C546.81,91.12,546.68,91.86,546.62,92.6z"/>
    <path fill="#3B8A7D" d="M554.76,83.85c-0.85,4-0.83,8.41-0.14,11.75c1.1,5.37,2,7.13,3.48,8.54
      c-0.11-0.78-0.33-1.55-0.37-2.34c-1.33-1.96-2.58-5.98-2.93-10.41C554.61,89.04,554.65,86.45,554.76,83.85z"/>
    <path fill="#3B8A7D" d="M548.84,79.06c0.28-1.54,0.51-2.79,0.6-3.37c-0.14-0.04-0.28-0.09-0.42-0.14
      C549.01,76.73,548.95,77.9,548.84,79.06z"/>
    <path fill="#3B8A7D" d="M566.95,81.26c0.75,0.86,1.55,1.73,2.66,2.11c0.21,0.07,0.42,0.12,0.63,0.16
      c-2.52-1.87-5.07-5.6-6.18-10.32c-0.05,0.94-0.14,1.94-0.24,2.98C564.63,78.02,565.64,79.75,566.95,81.26z"/>
    <path fill="#7DBFAD" d="M526.79,88c0.77,0.97,3.72,3.05,6.63,5.88c0.1-0.11,0.21-0.21,0.32-0.3
      C531.97,91.64,529.03,88.87,526.79,88z"/>
    <path fill="#7DBFAD" d="M579.64,95.17c0.83,1.6,1.4,3.27,1.32,5.06c0.55-0.95,0.9-2.18,0.24-3.42
      c-1.07-2-5.51-8.51-7.48-11.31C575.03,89.09,577.89,91.81,579.64,95.17z"/>
    <path fill="#7DBFAD" d="M574.79,97.83c-1.4,0.83-2.59,2.4-3.1,3.15c0.25,0.08,0.5,0.17,0.74,0.26
      C572.8,100.82,573.95,98.84,574.79,97.83z"/>
    <path fill="#3B8A7D" d="M562.36,89.79c0.89-4.64,1.56-9.87,1.27-11.79c-0.88,7.56-2.69,16.86-4.06,21.84
      C560.09,98.57,561.46,94.43,562.36,89.79z"/>
    <path fill="#7DBFAD" d="M539.14,87.58c0.08-2.51,0.3-7.38,1.58-12.63c-0.95,2.15-1.7,4.7-1.95,6.35
      c-0.74,4.96-0.31,8.31-0.06,10.35c0.13,1.1,0.47,3.06,0.82,4.95C539.32,94.24,539.06,90.21,539.14,87.58z"/>
    <path fill="#3B8A7D" d="M540.02,75.14c-0.81,1.05-1.37,2.26-1.94,3.48c0.28-0.17,0.53-0.32,0.76-0.44
      C539.04,77.39,539.5,76.22,540.02,75.14z"/>
    <path d="M544.32,38.7c-0.43-2.15-2.41-3.34-4.43-3.01c-1.42,0.23-3.32,1.49-3.27,3.67c0.04,1.44,0.52,2.25,1.06,2.89
      c0.9,1.06,2.46,1.45,3.74,1.14C543.49,42.89,544.77,40.89,544.32,38.7z"/>
    <path fill="#FFFFFF" d="M543.67,39.08c-0.11-0.01-0.22-0.01-0.33-0.02c-1.95-0.07-4.12-0.2-6.07,0.25
      c0,0.05-0.01,0.1-0.01,0.15c-0.01,1.7,1.45,3.51,3.55,3.21c2.09-0.29,3.03-2.06,2.86-3.58C543.67,39.09,543.67,39.09,543.67,39.08z
      "/>
    <path d="M536.5,47.67c-1.08,0.13-2.39-0.26-3.03-0.64c1.09-1.24,1.84-2.57,2.26-4.15c0.2-0.74,0.48-2.35,0.08-2.48
      c-0.41,2.39-1.38,4.9-3.23,6.79C534.11,48.24,535.77,48.5,536.5,47.67z"/>
    <path d="M543.22,51.38c0.53-1.11,0.61-2.59,0.3-3.24c-1.01,1.18-3.64,1.51-5.2,1.06c-0.73-0.21-1.3,0.11-1.61,0.65
      c-0.41-1.14-1.54,0.18-1.87,0.86c-0.12-0.01-0.33-0.05-0.49-0.11c-0.11-0.04-0.15,0.28,0.46,0.5c0.71,0.26,1.71,0.46,2.16,0.5
      c0.43,0.5,0.68,1.3,0.77,2.14c-0.74,0.09-1.09,0.66-1.13,1.19c-0.09,1.01,0.78,1.39,1.73,1.46c1.41,0.09,2.48-0.45,3.21-1.01
      C542.24,54.84,542.97,53.7,543.22,51.38z"/>
    <path fill="#E85D63" d="M538.01,51.13c-1.14,0-2.18-0.09-2.89-0.31c0.77-1.23,1.25-1.37,1.31-0.41
      c0.01,0.18,0.14,0.2,0.22,0.07c0.35-0.61,0.81-1,1.35-0.95c0.48,0.05,0.93,0.18,1.39,0.21c1.28,0.08,2.51-0.18,3.6-0.73
      C541.85,50.35,539.15,51.13,538.01,51.13z"/>
    <path fill="#B53B42" d="M538.09,49.87c-0.64-0.21-1.06,0.52-1.24,0.73c-0.15,0.17-0.51,0.34-0.6-0.12
      c-0.14-0.66-0.63-0.1-0.97,0.39c0.7,0.19,1.67,0.27,2.73,0.27c0.89,0,2.73-0.48,4.04-1.32C541.01,50.1,539.39,50.3,538.09,49.87z"
      />
    <path fill="#FFFFFF" d="M537.34,51.57c1.58,0.24,4.55-0.75,5.8-2.31c0.13,0.89-0.28,1.95-1.22,2.94
      c-0.98,1.03-2.4,1.42-3.79,1.53C538.08,53.17,537.85,52.01,537.34,51.57z"/>
    <path fill="#E85D63" d="M541.31,54.93c-0.78,0.59-1.94,1.12-3.53,0.89c-1.07-0.16-0.79-1.58-0.04-1.66
      c0.75-0.08,3.35-0.01,4.99-2.33C542.62,53.04,542.32,54.17,541.31,54.93z"/>
    <path d="M542.55,41.11c-0.45,0.92-1.56,1.3-2.48,0.85c-0.92-0.45-1.3-1.56-0.85-2.48c0.45-0.92,1.56-1.3,2.48-0.85
      C542.62,39.08,543,40.19,542.55,41.11z"/>
    <path fill="#FFFFFF" d="M542.24,40.06c-0.18,0.36-0.62,0.52-0.99,0.34c-0.36-0.18-0.52-0.62-0.34-0.99
      c0.18-0.36,0.62-0.52,0.99-0.34C542.27,39.25,542.42,39.7,542.24,40.06z"/>
    <path fill="#FFFFFF" d="M540.83,40.4c-0.05,0.1-0.18,0.15-0.28,0.1c-0.11-0.05-0.15-0.18-0.1-0.28
      c0.05-0.1,0.18-0.15,0.28-0.1C540.84,40.17,540.88,40.3,540.83,40.4z"/>
    <path d="M534.79,41.06c-0.37,0.75-1.27,1.06-2.02,0.69c-0.75-0.37-1.06-1.27-0.69-2.02c0.37-0.75,1.27-1.06,2.02-0.69
      C534.85,39.4,535.16,40.31,534.79,41.06z"/>
    <path fill="#FFFFFF" d="M534.54,40.2c-0.15,0.3-0.51,0.42-0.8,0.28c-0.3-0.15-0.42-0.51-0.27-0.8
      c0.15-0.3,0.51-0.42,0.8-0.27C534.56,39.54,534.69,39.9,534.54,40.2z"/>
    <path fill="#FFFFFF" d="M533.39,40.48c-0.04,0.09-0.15,0.12-0.23,0.08c-0.09-0.04-0.12-0.15-0.08-0.23
      c0.04-0.09,0.15-0.12,0.23-0.08C533.4,40.29,533.43,40.39,533.39,40.48z"/>
    <path d="M541.62,32.68c-1.33-0.76-2.95-0.91-3.24,0.34c1.77,0.03,4.01,0.45,4.76,2.1C543.4,34.54,542.72,33.31,541.62,32.68z"/>
    <path d="M532.06,35.57c0.52-0.8,2.14-1.79,2.71-1.84C534.38,32.37,531.89,34.32,532.06,35.57z"/>
    <path fill="#F79FA1" d="M539.7,54.53c0.05-0.31,0.92-0.68,1.1-0.47C541.07,54.36,539.65,54.87,539.7,54.53z"/>
    <path d="M543.36,48.59c-0.29,0.96-1.21,2.31-2.08,2.94c-1.03,0.74-2.29,1.03-3.54,1.18c0.04,0.04,0.06,0.09,0.07,0.15
      c1.43-0.07,3.01-0.6,4.12-1.48c0.48-0.38,1.1-0.88,1.44-1.48C543.55,49.59,543.42,48.53,543.36,48.59z"/>
    <path d="M549.26,32.06c-0.68-1.74-1.67-3.38-2.96-4.7c-1.67-1.71-3.81-2.01-5.72-1.11c-0.57,0.27-1.47,0.84-1.53,1.24
      c1.06-0.94,2.5-1.54,4.03-1.38c1.2,0.13,2.26,0.88,3.11,1.69c1.89,1.82,2.94,4.48,3.66,6.95
      C550.08,34.75,549.59,32.93,549.26,32.06z"/>
    <path d="M553.77,34.31c-0.5-2.48-1.22-4.99-2.56-7.03c-1.04-1.59-2.48-2.88-4.22-3.66c-1.34-0.6-2.87-0.45-4.05,0.09
      c-0.87,0.39-2.11,1.26-2.12,1.72c1.02-0.82,1.62-1.24,2.69-1.59c1.54-0.5,2.79-0.18,3.97,0.48c1.43,0.8,2.57,2.03,3.46,3.39
      c2.07,3.17,2.59,6.95,3.29,10.59C554.48,38.31,554.05,35.66,553.77,34.31z"/>
    <path d="M558.96,33.07c-0.65-3.53-2.01-6.88-4.43-9.57c-0.93-1.03-1.95-1.97-3.29-2.42c-1.83-0.61-4.54-0.39-5.98,0.67
      c1.95-0.77,3.79-0.95,5.64-0.43c1.66,0.47,3.19,2.01,4.19,3.32c2.16,2.8,3.18,6.29,3.72,9.73c1.14,7.3,0.08,14.61-0.47,21.92
      c-0.06,0.86-0.22,2.64,0.01,2.59C559.27,50.36,560.53,41.58,558.96,33.07z"/>
    <path d="M558.96,26.87c-1.01-2.52-2.88-5.1-5.3-6.15c-0.86-0.37-2.23-0.74-2.79-0.44c4.68,0.31,7.53,4.96,8.65,9.12
      C559.77,29.31,559.28,27.67,558.96,26.87z"/>
    <path d="M552.79,76.42c0.98-4.31,1.05-8.94,0.81-13.38c-0.16-3-0.3-5.99-0.34-9c-0.04-2.98-0.2-5.91-0.56-8.87
      c-0.41-3.27-0.74-6.6-1.42-9.83c-0.17-0.82-0.42-2.14-0.72-2.38c0.71,2.9,1.14,5.87,1.52,8.83c0.38,2.98,0.77,5.96,0.88,8.96
      c0.11,3.05,0.05,6.1,0.21,9.14c0.15,2.82,0.37,5.62,0.31,8.44c-0.07,3.73-0.65,7.41-1.72,10.98
      C552.17,79.33,552.55,77.47,552.79,76.42z"/>
    <path d="M557.04,70.7c-0.16-1.56-0.41-3.12-0.59-4.68c-0.35-3.01-0.58-6.03-0.64-9.06c-0.06-3.09,0.01-6.17-0.03-9.26
      c-0.04-3.27-0.18-6.54-0.5-9.8c-0.18-1.88-0.33-3.88-0.8-5.71c-0.28-1.1-0.79-2.9-1.3-3.05c1.15,2.63,1.53,5.5,1.8,8.33
      c0.3,3.09,0.47,6.18,0.51,9.28c0.04,3.1-0.01,6.19,0.01,9.29c0.02,3.03,0.24,6.06,0.56,9.07c0.35,3.29,0.92,6.52,0.95,9.83
      c0.02,2.09-0.17,4.52-0.23,6.62c-0.03,1.15-0.04,3.08,0.13,3.11c0.07-3.12,0.31-6.24,0.34-9.36
      C557.26,73.77,557.2,72.23,557.04,70.7z"/>
    <path d="M561.05,41.87c-0.03-2.63-0.08-5.41-0.64-8c-0.15-0.7-0.41-1.99-0.68-2.03c0.76,2.74,0.98,6.06,1.04,8.87
      c0.06,2.86-0.02,5.3-0.24,8.2c-0.22,2.97-0.64,5.93-1.11,8.87c-0.47,2.94-0.75,5.87-0.83,8.85c-0.09,3.46,0.04,6.91,0.09,10.37
      c0.24-0.05,0.18-1.87,0.18-2.97c-0.02-4.83-0.18-10.03,0.61-14.79c0.49-2.95,0.93-5.91,1.2-8.89
      C560.92,47.5,561.08,44.73,561.05,41.87z"/>
    <path d="M564.12,126.7c-1.07-0.59-1.39,1.66-1.33,2.28c0,0.04,0.06,0.03,0.07,0c0.08-0.7,0.15-2.52,1.23-2.19
      C564.14,126.8,564.16,126.72,564.12,126.7z"/>
    <path d="M565.38,125.9c-0.4-0.37-1.1,0.17-1.11,0.62c0,0.05,0.08,0.06,0.09,0.01c0.08-0.44,0.56-0.77,0.98-0.58
      C565.37,125.96,565.4,125.92,565.38,125.9z"/>
    <path d="M562.85,120.44c-1.1-0.08-0.98,1.04-0.74,1.79c0.01,0.03,0.06,0.02,0.06-0.01c-0.12-0.71-0.39-1.72,0.68-1.68
      C562.9,120.54,562.91,120.44,562.85,120.44z"/>
    <path d="M531.34,112.63c-0.06-0.02-0.08,0.08-0.03,0.1c0.73,0.31,0.77,0.9,0.55,1.58c-0.01,0.04,0.04,0.06,0.06,0.03
      C532.31,113.7,532.08,112.83,531.34,112.63z"/>
    <path fill="#96D1B8" d="M535.04,38.87c-0.36-0.95-1.31-1.57-2.29-1.49c-0.73,0.06-1.31,0.44-1.63,0.8
      c-0.03,0.17-0.11,0.72-0.16,1.36C532.22,39.04,533.65,38.89,535.04,38.87z"/>
    <path fill="#65B394" d="M531.72,37.7c-0.24,0.14-0.44,0.31-0.59,0.48c-0.03,0.17-0.11,0.72-0.16,1.36
      c0.15-0.06,0.3-0.11,0.45-0.16C531.48,39.01,531.62,38.1,531.72,37.7z"/>
    <path fill="#96D1B8" d="M540.38,38.67c1.07-0.02,2.14-0.03,3.21,0.02c-0.37-1.35-1.74-2.5-3.2-2.39
      c-1.52,0.12-2.8,1.18-3.06,2.62C538.32,38.72,539.35,38.68,540.38,38.67z"/>
    <path d="M550.37,91.69c-1.29-0.25-5.1-1.14-5.97-1.5c-1.93-0.78-3.79-1.58-4.98-3.39c-0.06-0.1,0.03,1,0.98,1.84
      c0.98,0.87,2.03,1.38,3.29,1.93C545.78,91.5,551.87,91.97,550.37,91.69z"/>
    <path fill="#7DBFAD" d="M540.36,83.91c0.34,1.38,2.85,3.95,5.11,3.55C547.55,87.09,539.35,79.76,540.36,83.91z"/>
  </g>
  <g id="item_14_">
    <path d="M364.75,146.37c1.15,3.33,4.6,3.48,7.64,2.74c0.39-0.1,0.1-0.93-0.18-1.53c1.21-0.39,3.49-1.06,5.23-1.22
      c0.31,0.18,0.64,0.35,1,0.49c0.25,1.6,0.88,2.19,1.69,2.55c0.76,0.34,2.34,0.1,3.11-0.02c0.46,0.41,3.28,1.27,4.35,1.23
      c-0.62,1.94-2.94,18.2-3.36,28.1c-0.21,5.09-0.79,22.38-0.72,25.68c-1.27,0.19-1.99,0.27-2.21,0.62c-0.22,0.35-0.41,3.87,0.25,4.33
      c0.66,0.46,9.1,0.5,9.74,0.37c0.64-0.13,0.83-3.89,0.11-4.6c-0.43-0.42-1.28-0.49-2.09-0.64c-0.01-10.13,0.36-19.97,1.02-30.11
      c0.67-10.28,2.82-23.21,3.53-25.7c0.53,0.64,1.34,1.12,2.48,1.32c0.29,0.05,0.57-0.08,0.71-0.32c0.72-1.27,0.64-2.7,1.34-3.98
      c0.76-1.38,1.97-2.79,2.46-4.29c0.28-0.86,0.42-1.9-0.41-2.56c-0.89-0.71-2.2-1-3.56-1.04c1.16-6.23,0.89-13.67,4.35-19.28
      c1.59-2.57,3.83-4.76,5.35-7.36c0.2-0.35,0.4-0.7,0.59-1.06c-0.86,3.98-1.68,7.96-2.63,11.92c-0.87,3.6-2.15,7.31-2.39,11.01
      c-0.22,3.41,0.07,6.82,0.17,10.23c0,0.16,0.07,0.28,0.16,0.37c-0.44,0.91-0.81,1.87-0.99,2.98c-0.48,2.94-0.14,5.99,0.14,8.93
      c0.03,0.32,0.26,0.72,0.68,0.68c1.79-0.14,3.58-0.2,5.36-0.21c-1.54,2.98-0.76,6.94-1.04,10.06c-0.4,4.33-1.68,8.21-3.35,12.25
      c-1.63,3.94-3.34,7.99-3.72,12.23c-0.34,3.82-0.06,7.61,0.36,11.41c-2.58,0.61-4.84,1.65-5.93,3.48c-0.7,1.18,1.39,1.33,1.86,1.4
      c-4.2,2.27-8.89,7.19-10.46,10.77c7.66,2.42,20.95,1.75,33.53,0.28c4.6-0.53,14.4-2.16,18.81-3.53c2.23-0.7,4.14-1.53,5.78-2.94
      c1.94,0.85,5.25,1.41,7.44,1.61c0.42,0.04,0.61-1.89,0.46-2.47c7.41,3.08,16.3,3.41,24.26,3.57c8.47,0.16,18.52,0.16,25.36-2.11
      c-1.08-3.08-17.36-6.88-21.09-7.31c0.59-8.83,0.19-17.23,0.01-26.06c-0.1-4.49-0.18-8.96-0.61-13.43c-0.38-4-1.29-7.93-1.67-11.92
      c5.92,0.05,11.04,0.99,10.93,0.44c-0.7-3.51-0.66-7.99-0.75-11.57c-0.06-2.67,0.21-5.42-0.35-8.05c0.49,0.03,0.97-0.04,1.4-0.28
      c3.17-1.75,2.09-6.44,1.92-9.26c-0.38-6.15-0.52-12.34-1.33-18.46c-0.92-6.9-2.49-13.86-6.29-19.93
      c-3.31-5.28-7.78-9.83-12.81-13.74c-2.29-1.77-4.7-3.93-7.6-4.79c-3.08-0.92-6.58-1.22-9.81-1.31c-6.57-0.17-14.04,1.28-20.21,3.38
      c-6.23,2.11-11.88,5.47-15.37,10.85c-1.02,1.58-1.91,3.22-2.72,4.91c-7.51,5.2-11.9,14.2-16.04,21.74
      c-2.46,4.49-5.08,8.94-7.13,13.61c-0.98,2.23-1.97,4.53-2.53,6.89c-0.62,2.61-0.42,5.49-1.11,8.07c-4.84-0.43-8.76-0.15-11.06,1.51
      c-1.22,0.88-3,2.7-2.9,4.46c-0.89,0.16-3.24,0.52-3.29,0.54c-0.26-0.72-0.41-1.62-0.79-1.59
      C367.47,140.89,363.96,144.11,364.75,146.37z M387.64,147.14c0.22-0.79,0.23-1.59,0.11-2.37c0.75,0.24,1.44,0.33,1.61,1.04
      c0.17,0.72-0.09,1.46-0.41,2.05C388.56,147.55,388.08,147.29,387.64,147.14z"/>
    <path fill="#C7E3FA" d="M443.63,68.73c-4.13,0.71-8.32,1.57-12.22,3.02c0.33,3.5,0.71,6.95,1.8,10.34
      c0.35,1.08,0.79,2.13,1.27,3.16c1.45-3.39,3.48-6.52,5.27-9.75c1.39-2.5,2.89-4.95,4.99-6.95
      C444.36,68.61,443.99,68.67,443.63,68.73z"/>
    <path fill="#FFFFFF" d="M430.29,73.42c-2.18,2.48-3.57,5.41-3.83,8.67c-0.06,0.76,0.13,2.87,0.27,3.19
      c0.14,0.32,5.38,0.66,6.81,0.74c-0.44-0.88-0.85-1.78-1.22-2.71C431.07,80.16,430.56,76.77,430.29,73.42z"/>
    <path fill="#BD9881" d="M434.12,98.72c0.4,0.37,4.52,1.39,5.97,2.1c1.11,0.54,2.21,1.13,3.27,1.77
      c-2.47-2.85-4.15-6.42-5.95-9.57c-1-1.75-2.02-3.48-2.97-5.24c-0.26,1.58-0.35,3.17-0.48,4.77
      C433.82,94.45,433.73,98.35,434.12,98.72z"/>
    <path fill="#C7E3FA" d="M445.68,68.54c-2.21,2.13-3.78,4.73-5.24,7.36c-1.9,3.41-4.01,6.72-5.49,10.31
      c0.71,1.41,1.5,2.78,2.29,4.14c2.65,4.56,4.93,10.17,9.08,13.84c-0.75-4.46-1.09-8.77-1.05-13.22c-0.22,0.1-0.49,0.16-0.79,0.13
      c-0.83-0.08-1.45-0.52-1.76-1.26c-0.38-0.92,0.23-2.1,1.36-2.18c0.49-0.04,0.93,0.11,1.27,0.42c0.01-0.28,0.25-4.96,0.32-7.02
      c-0.96,0.9-2.39,0.34-2.9-0.48c-0.6-0.97-0.09-2.72,1.21-2.91c0.67-0.1,1.33,0.03,1.78,0.62
      C445.83,75.06,445.83,71.81,445.68,68.54z"/>
    <path fill="#6889A1" d="M445.37,89.39c-0.07-0.74-0.49-1.18-1.13-1.15c-0.63,0.03-1.1,0.28-1.07,1.15
      c0.03,0.86,0.84,1.1,1.31,1.03C444.98,90.35,445.44,90.13,445.37,89.39z"/>
    <path fill="#6889A1" d="M445.51,78.98c-0.23-0.52-0.88-0.73-1.41-0.63c-0.82,0.15-1,1.46-0.55,2.01
      c0.34,0.4,1.02,0.51,1.47,0.22C445.64,80.18,445.74,79.5,445.51,78.98z"/>
    <path fill="#49657C" d="M443.76,90.3c-0.31-0.15-0.56-0.43-0.58-0.91c-0.03-0.87,0.43-1.11,1.07-1.15
      c0.32-0.02,0.58,0.08,0.78,0.28C444.57,89.1,444.16,89.69,443.76,90.3z"/>
    <path fill="#49657C" d="M443.85,80.59c-0.12-0.06-0.23-0.14-0.31-0.24c-0.45-0.54-0.26-1.85,0.55-2.01
      c0.47-0.09,1.02,0.06,1.31,0.46C444.84,79.37,444.32,79.96,443.85,80.59z"/>
    <path fill="#C7E3FA" d="M446.2,86.31c-0.07,1.07-0.34,7.48-0.3,9.34c0.07,3.06,1.04,6.04,1.07,9.1
      c0.36,0.29,0.72,0.56,1.11,0.82c0.01-0.01,0.01-0.02,0.02-0.03c5.32-4.47,7.86-10.98,9.84-17.21c0.38-1.19,0.79-2.39,1.21-3.58
      c-3.17-6-7.49-11.33-12.86-15.78C447.15,74.67,446.54,80.6,446.2,86.31z"/>
    <path fill="#EBC3A6" d="M432.93,131.1c-1.78-2.87-1.46-6.72-2.26-9.61c-0.12-0.42,0.25-1.5,0.4-1.08
      c1.26,3.42,1.06,10.47,4.09,11.75c-0.17-1.72-0.21-3.46-0.45-5.17c-0.38-2.69-0.96-6.11-1.09-7.01c-0.13-0.9,0.43-1.68,0.57-1.17
      c0.75,2.65,1.14,5.42,1.54,8.13c0.21,1.45,0.41,5.46,0.51,5.92c0.1,0.46,2.05,0.12,1.92-2.75c-0.13-2.87-2.51-6.5-1.26-9.24
      c1.24-2.72,4.09-1.85,6.51-1.07c2.34,0.76,4.69,1.05,7.17,0.8c2.39-0.23,3.27-2.4,5.34-3.06c0.02-1.18,0.12-2.36,0.41-3.51
      c-0.05-0.02-0.09-0.04-0.13-0.07c-1.92-1.55-4.32-0.92-6.58-0.76c-2.59,0.18-5.05,0.15-7.58,0.77c-1.91,0.47-3.76,1.21-5.58,1.9
      c-2.31,0.87-5.35,1.31-7.07,3.03C427.5,120.81,430.23,131.23,432.93,131.1z"/>
    <path fill="#EBC3A6" d="M428.18,123.66c-0.06,0.52-0.16,1.35-0.29,1.88c-0.33,1.27-1.06,2.69-1.13,3.77
      c-0.07,1.08,0.57,1.25,1.2,0.65c0.63-0.6,1-1.96,1.24-2.84C428.74,126.02,428.38,124.84,428.18,123.66z"/>
    <path fill="#EBC3A6" d="M440.58,124.67c1.03,0.46,2.32-1.07,2.85-1.66c0.44-0.49,0.88-0.95,1.41-1.31
      c-0.48-0.12-2.76-0.86-3.71-1.07C440.29,121.41,439.28,124.09,440.58,124.67z"/>
    <path fill="#BD9881" d="M455.76,97.27c1.14-1.21,2.3-2.38,3.51-3.49c1.32-1.22,3.72-2.79,3.79-3.5
      c0.07-0.71-0.98-5.37-2.07-7.29c-0.01,0-0.02,0-0.03,0c0,0-0.01,0-0.01,0c-0.81,2.45-1.69,4.87-2.42,7.29
      C457.82,92.64,456.91,95.01,455.76,97.27z"/>
    <path fill="#C7E3FA" d="M462.62,69.24c-4.79-1.87-10.63-1.68-15.82-0.99c5.19,4.52,9.47,9.78,12.63,15.69
      C461.14,79.15,462.85,74.33,462.62,69.24z"/>
    <path fill="#A7CAE8" d="M443.98,77.68c-1.3,0.19-1.81,1.94-1.21,2.91c0.29,0.46,0.87,0.84,1.5,0.92
      c-2.48,2.54-5.09,5.05-7.89,7.32c-0.49-0.87-0.97-1.74-1.42-2.63c1.47-3.6,3.58-6.9,5.49-10.31c1.47-2.63,3.03-5.23,5.24-7.36
      c0.15,3.27,0.15,6.52,0.08,9.76C445.31,77.71,444.65,77.59,443.98,77.68z"/>
    <path fill="#A7CAE8" d="M445.53,84.31c-0.09,1.84-0.18,3.62-0.18,3.79c-0.19-0.18-0.42-0.3-0.67-0.36
      c0.07-0.47,0.16-0.94,0.26-1.39C445.08,85.68,445.31,85,445.53,84.31z"/>
    <path fill="#A7CAE8" d="M445.26,90.98c-0.03,3.48,0.17,6.88,0.62,10.33c-0.82-2.4-1.56-4.81-1.59-7.32
      c-0.01-0.95,0-1.92,0.04-2.91c0.04,0.01,0.08,0.02,0.13,0.02C444.78,91.14,445.04,91.08,445.26,90.98z"/>
    <path fill="#A7CAE8" d="M446.64,79.03c0.15-3.38,0.15-6.76-0.35-10.07c1.74,1.44,3.36,2.99,4.87,4.61
      C449.81,75.49,448.25,77.3,446.64,79.03z"/>
    <path fill="#A7CAE8" d="M456.15,82.88c-0.82-1.89-1.94-3.73-2.61-5.67c-0.2-0.58-0.39-1.16-0.64-1.7
      c2.41,2.86,4.51,5.94,6.24,9.23c-0.38,1.06-0.74,2.13-1.09,3.19C457.51,86.23,456.87,84.54,456.15,82.88z"/>
    <path fill="#A7CAE8" d="M451.59,72.94c-1.5-1.64-3.09-3.21-4.79-4.69c2.42-0.32,4.99-0.53,7.52-0.47
      C453.64,69.58,452.7,71.3,451.59,72.94z"/>
    <path fill="#BD9881" d="M463.33,69.54c0.28,4.31-0.79,8.51-2.12,12.65c0.01,0.01,0.02,0.01,0.02,0.02
      c1.83,0.03,3.84,0.59,5.64,0.88C467.63,81.91,465.91,71.32,463.33,69.54z"/>
    <path fill="#BD9881" d="M489.55,131.32c0.52-1.35,0.36-3.02,0.28-4.43c-0.8-14.14-0.63-29.69-9.15-42
      c-3.78-5.45-9.14-9.75-14.55-13.74c-0.37-0.28-0.75-0.52-1.15-0.76c2.42,3.87,3.26,11.71,2.52,13.42c-1.94-0.16-3.8-0.63-5.73-0.77
      c0.95,1.7,2.38,6.84,2.05,7.74c-0.33,0.9-2.47,2.36-3.38,3.08c-1.24,0.97-2.31,2.15-3.36,3.29c-1.23,1.34-2.38,2.84-3.72,4.11
      c-1.29,1.8-2.87,3.68-4.63,5.09c-0.25,1.43,0.02,3.88-0.01,5.74c0.89-0.03,1.78-0.07,2.67-0.15c1.95-0.18,3.85-0.14,5.32,1.14
      c0.81-1.47,1.73-1.75,2.14-1.72c1.13,0.09,17.47,2.54,21.22,6.59c0.34-0.44,1.14-0.48,1.71-0.2c0.15-0.26,0.41-0.47,0.75-0.51
      c-0.04-9.14-1.3-18.19-4.49-26.86c-0.05-0.14,0.75,0.28,1.21,1.42c3.57,8.79,4.4,16.53,4.48,25.87c2.52,2.77,1.95,3.88,1.85,3.64
      c-0.33-0.78-2.12-3.49-3.1-2.92c1.81,2.4,1.23,3.27,1.18,3.13c-0.25-0.69-1.83-3.35-2.93-2.75c0.77,1.25,0.55,1.88,0.43,1.67
      c-2.56-4.71-10.04-5.71-14.95-6.88c-2.1-0.5-7.33-1.76-7.93-1.17c-1.65,1.62-1.18,6.42-1.06,8.48c0.02,0.43-0.01,1.16,0,1.59
      c1.07,0.27,3.75,0.41,5.32,0.73c4.1,0.85,8.07,2.23,11.93,3.77c3.17,1.26,6.35,2.64,9.35,4.22
      C485.37,132,488.47,134.11,489.55,131.32z"/>
    <path fill="#BD9881" d="M487.34,152.9c-0.42-2.92-0.22-5.94-0.39-8.88c-0.2-3.31,0.38-6.81-0.38-10.06
      c-0.86-0.23-1.69-0.68-2.33-1.01c-2.76-1.44-5.54-2.78-8.42-4.02c-6.08-2.6-12.91-4.69-19.57-5.44c-0.43-0.05-0.15-3.27-0.3-5.1
      c-1.45,1.12-1.67,2.14-3.39,3.02c-1.13,0.57-2.51,0.67-3.83,0.66c1.04,2.62,0.75,5.46,1.3,8.18c0.61,3.06,1.52,6.05,2.17,9.1
      c1.05,4.92,2.32,10.08,2.64,15.17c4.83-1.68,10.48-1.94,15.58-2.32C476.02,151.81,481.77,152.18,487.34,152.9z"/>
    <path fill="#95867C" d="M478.81,174.85c-0.07-3.79-0.25-7.57-0.68-11.35c-0.39-3.42-1.14-6.78-1.37-10.22
      c-2.07-0.04-4.15-0.01-6.24,0.13c-5.06,0.34-10.68,0.74-15.49,2.26c-0.05,0.02-0.09,0.02-0.13,0.03c0.02,0.76,0.03,1.52,0,2.28
      c-0.01,0.22-0.19,0.34-0.39,0.35c-0.13,0.08-0.28,0.12-0.46,0.09c-1.63-0.26-3.35-0.39-5.08-0.45c0.1,8.22-1.58,16.34-2.68,24.47
      c-0.56,4.1-0.75,8.2-0.85,12.32c-0.05,1.99,0,3.98,0.19,5.96c0.12,1.33,0.5,2.6,0.86,3.87c9.59-1.35,29.39-0.55,32.34-0.14
      c0.07-0.34,0.22-5.84,0.26-7.73C479.23,189.43,478.95,182.15,478.81,174.85z"/>
    <path fill="#A2663F" d="M444.71,210.37c1.09,0.85,3.89,1.06,5.75,1.3c0.03-0.5,0.03-1.02-0.03-1.52
      c-0.56-0.26-2.3-2.08-1.04-1.54c7.37,3.14,17.55,3.88,25.55,4.22c8.15,0.35,15.33-0.01,23.39-1.21c-1.73-2.08-11.66-4.56-20.08-6.1
      c-6.98-0.67-25.57-0.92-31.45,0.22c0.12,0.44,0.32,1.27,0.38,1.72c0.03,0.19-0.51,0.39-0.99,0.44
      C445.83,208.83,445.13,209.58,444.71,210.37z"/>
    <path fill="#A2663F" d="M388.15,216.55c18.71,2.77,39.38-1.26,44.49-2.44c4.63-1.08,9.22-2.21,12-6.03
      c-3.69,0.36-6.86,0.71-10.61,0.71c-4.52-0.01-9.05-0.36-13.57-0.54c-7.63-0.31-15.01-0.05-22.61-1.01
      C395.46,208.15,389.07,213.24,388.15,216.55z"/>
    <path fill="#904E21" d="M462.15,205.56c-5.64,0.08-11.85,0.51-16.59,3.51c0.25-0.37,0.48-0.75,0.65-1.17
      c0.47-0.05,1.01-0.25,0.99-0.44c-0.06-0.45-0.26-1.28-0.38-1.72c5.88-1.14,24.47-0.89,31.45-0.22c0.39,0.07,0.79,0.15,1.19,0.22
      C473.67,205.62,467.91,205.48,462.15,205.56z"/>
    <path fill="#904E21" d="M412.82,209.33c-5.73-0.55-12.14-1.24-18.03-0.24c1.22-0.9,2.34-1.58,3.07-1.86
      c7.6,0.96,14.97,0.7,22.61,1.01c4.52,0.18,9.04,0.53,13.57,0.54c3.75,0.01,6.92-0.34,10.61-0.71c-2.06,2.83-5.11,4.19-8.43,5.14
      C428.73,210.75,420.81,210.09,412.82,209.33z"/>
    <path fill="#95867C" d="M405.27,178c-1.56,3.91-3.27,7.73-4.05,11.87c-0.74,3.91,0.31,7.78,0.42,11.73
      c5.61-0.68,7.83,0.22,7.12,0.27c-5.88,0.42-13.11,2.41-12.36,3.31c0.75,0.9,15.42,1.12,23.2,1.37c8.36,0.27,17.6,1.89,25.88,0.14
      c-0.51-1.71-1.38-3.26-1.69-5.08c-0.32-1.95-0.31-3.95-0.29-5.91c0.05-4.16,0.33-8.31,0.9-12.44c1.18-8.46,3.32-16.75,3.57-25.31
      c-2-0.05-4.01-0.02-5.9-0.04c-4.03-0.03-8.02-0.13-12.04-0.43c-7-0.53-13.93-1.38-20.92-1.46c-0.01,0.01-0.01,0.02-0.01,0.03
      c-1.55,2.64-0.75,6.43-0.88,9.35C408.05,169.78,406.91,173.89,405.27,178z"/>
    <path fill="#847267" d="M448.86,162.6c0.09-1.54,0.14-3.08,0.12-4.63c1.73,0.06,3.45,0.19,5.08,0.45
      c0.18,0.03,0.33-0.01,0.46-0.09c0.2-0.01,0.38-0.13,0.39-0.35c0.02-0.76,0.02-1.52,0-2.28c0.04-0.01,0.09-0.01,0.13-0.03
      c4.81-1.52,10.43-1.92,15.49-2.26c2.09-0.14,4.16-0.16,6.24-0.13c0.08,1.28,0.24,2.54,0.42,3.8
      C467.63,154.52,457.63,157.53,448.86,162.6z"/>
    <path fill="#847267" d="M473.14,180.06c-1.93,2.88-3.99,6.16-7.57,6.88c-2.38,0.47-4.69,0.02-6.68-1.31
      c-1.5-1.01-2.86-2.51-2.45-3.96C461.73,184.37,469.21,184.22,473.14,180.06z"/>
    <path fill="#847267" d="M445.44,194.76c0.1-4.13,0.3-8.22,0.85-12.32c0.22-1.61,0.46-3.23,0.7-4.84
      c0.23,3.33,0.5,6.65,0.55,9.93c0.07,5.65,0.17,11.27,0.92,16.83c-0.7,0.07-1.36,0.15-1.97,0.24c-0.36-1.27-0.74-2.54-0.86-3.87
      C445.44,198.74,445.39,196.76,445.44,194.76z"/>
    <path fill="#847267" d="M422.07,158.63c-4.67,0.39-9.31,1.01-13.93,1.73c0.03-1.55,0.23-3.06,0.97-4.31
      c0.01-0.01,0.01-0.02,0.01-0.03c6.99,0.07,13.91,0.93,20.92,1.46c4.02,0.3,8.01,0.4,12.04,0.43c1.89,0.01,3.9-0.01,5.9,0.04
      c-0.03,0.9-0.08,1.8-0.14,2.7C439.66,157.97,430.62,157.92,422.07,158.63z"/>
    <path fill="#847267" d="M409.16,181.66c2.15,1.72,4.5,3.19,7.39,3.47c4.61,0.44,8.41-1.34,12.49-2.89
      c-0.01,2.26-0.34,5.63-2.32,7.09c-2.35,1.73-5.22,2.58-8.21,2.26c-0.03,0-0.05,0-0.08,0C412.82,190.97,408.4,187.08,409.16,181.66z
      "/>
    <path fill="#BD9881" d="M406.53,119.66c-0.97,4.15-2.29,8.31-2.88,12.52c-0.44,3.14-0.18,6.36-0.16,9.54
      c0.33-0.61,0.67-1.23,0.98-1.91c0.1-0.22,0.44-0.08,0.4,0.14c-0.4,2.36-1.72,4.44-2.03,6.84c-0.35,2.67-0.13,5.38,0.13,8.05
      c8.44-0.89,16.96,0.27,25.36,1.03c4.86,0.44,9.71,0.56,14.59,0.59c2.05,0.02,4.09,0.09,6.14,0.24c1.61,0.12,3.23-0.02,4.81,0.26
      c-0.9-5.82-1.94-11.57-3.11-17.36c-0.56-2.75-1.36-5.45-1.97-8.19c-0.68-3.01-0.5-7.48-0.97-9.36c-0.6,0.01-1.28-0.07-1.77-0.15
      c-1.48,0.58-2.19,2.13-3.45,3.01c-0.76,0.53-2.09,0.92-2.84,0.16c-1.04-1.06-0.56-3.48,0.38-4.78c-0.55-0.02-1.46,0.1-1.89,0.39
      c-2.04,1.39,0.48,6.73,0.72,8.52c0.3,2.25-0.1,4.32-2.44,4.86c-0.8,0.19-1.19-0.31-1.26-1.01c-0.44-0.07-1.4-0.63-1.9-1.31
      c-1.61,0.63-2.85-1.05-3.78-3.74c-0.35,1.57-1.48,3.34-2.93,3.06c-0.95-0.19-0.61-1.87-0.5-2.4c0.25-1.2,0.92-2.3,1.18-3.5
      c0.25-1.15,0.33-2.27,0.66-3.38c-0.02-0.84,0.05-1.66,0.23-2.45c0.39-1.69,2.52-2.51,4.02-3.12c3.06-1.23,6.16-2.46,9.35-3.36
      c2.03-0.58,4.12-0.68,6.22-0.76c-0.02-1.9-0.22-3.76,0.11-5.64c-2.29-1.76-6.63-4.46-9.14-5.29c-1.63-0.54-5.06-1.23-5.52-1.88
      c-0.46-0.65-0.37-3.6-0.33-4.93c0.08-2.51,0.17-5.13,0.72-7.6c-2.44,0.03-7.59-0.38-7.66-0.72c-1.14-5.03,0.45-10,4.01-13.75
      c-2.63,1.09-5.11,2.46-7.33,4.27c-6.11,4.98-8.35,12.91-10.73,19.84C409.36,103.95,408.33,111.93,406.53,119.66z"/>
    <path fill="#BD9881" d="M399.21,119.48c1.22-3.06,3.83-5.55,5.75-8.2c1.65-2.28,2.52-4.91,3.62-7.43
      c0.4-1.64,0.82-3.27,1.28-4.89c1.13-3.99,2.4-8.08,4.1-11.97c-2.73,2.94-5.36,5.9-7.62,9.21c-2.83,4.15-5.18,8.58-7.49,12.99
      c-2.38,4.52-4.82,9.01-6.7,13.74c-1.01,2.55-1.76,5.16-2.23,7.84c-0.31,1.77-0.44,3.58-0.98,5.31c0.73,0.21,4.21,1.35,4.91,2.01
      c0.65-0.13,1.37-0.23,2.08-0.27C396.9,131.69,396.88,125.33,399.21,119.48z"/>
    <path fill="#9A7762" d="M434.06,91.33c0.09-1.19,0.19-2.37,0.38-3.55c0.43,0.8,0.87,1.59,1.33,2.37
      C435.24,90.6,434.65,90.98,434.06,91.33z"/>
    <path fill="#9A7762" d="M463.82,90.78c0.14-0.39-0.04-1.57-0.38-2.93c1.07,1.93,1.06,4.46,1.36,6.56
      c-4.28,1.26-8.19,3.99-11.51,6.95c0.02-0.03,0.05-0.06,0.07-0.1c1.35-1.27,2.49-2.76,3.72-4.11c1.05-1.15,2.13-2.32,3.36-3.29
      C461.35,93.14,463.49,91.67,463.82,90.78z"/>
    <path fill="#9A7762" d="M479.8,95.92c1.9,6.95,2.7,14.11,2.73,21.32c-0.34,0.04-0.6,0.25-0.75,0.51
      c-0.57-0.27-1.37-0.24-1.71,0.2c-0.36-0.39-0.84-0.77-1.42-1.13c2.17-4.49,1.62-9.54,1.4-14.34
      C479.95,100.32,479.97,98.11,479.8,95.92z"/>
    <path fill="#9A7762" d="M464.98,70.39c0.21,0.12,0.41,0.25,0.6,0.38c1.77,1.96,2.48,4.75,2.84,7.21
      c0.42,2.86,0.46,5.75,0.31,8.63c-1.93-0.33-3.84-0.56-5.81-0.6c-0.37-1.21-0.8-2.33-1.16-2.97c1.93,0.14,3.79,0.61,5.73,0.77
      C468.23,82.1,467.4,74.26,464.98,70.39z"/>
    <path fill="#9A7762" d="M479.12,133.08c-2.76-1.22-5.53-2.4-8.27-3.63c-2.79-1.25-5.62-2.52-8.59-3.38
      c-2.89-0.84-5.85-0.86-8.8-1.35c0.16-0.75,0.32-1.48,0.36-2.25c0.01-0.22-0.26-0.43-0.49-0.35c-1.4,0.51-2.85,0.69-4.31,0.81
      c-0.09-0.29-0.18-0.58-0.29-0.86c1.31,0.01,2.7-0.09,3.83-0.66c1.73-0.88,1.94-1.9,3.39-3.02c0.15,1.83-0.13,5.05,0.3,5.1
      c6.66,0.75,13.49,2.83,19.57,5.44c2.88,1.23,5.66,2.57,8.42,4.02c0.64,0.34,1.48,0.78,2.33,1.01c0.11,0.48,0.19,0.97,0.25,1.46
      C484.13,135.15,481.58,134.17,479.12,133.08z"/>
    <path fill="#9A7762" d="M450.65,132.42c1.39,2.76,2.7,5.53,3.73,8.42c1.5,4.21,2.6,8.5,3.42,12.85
      c-1.01,0.23-2,0.51-2.96,0.84c-0.32-5.09-1.6-10.25-2.64-15.17c-0.53-2.51-1.24-4.99-1.81-7.49
      C450.47,132.06,450.55,132.24,450.65,132.42z"/>
    <path fill="#9A7762" d="M438.79,101.19c1.06,0.35,2.45,1.04,3.87,1.84c-0.16-0.04-0.31-0.08-0.45-0.11
      c-1.58-0.41-3.16-0.85-4.75-1.25c-1.35-0.34-2.71-0.67-4.07-0.98c-1.08-0.25-2.4-0.16-2.34-1.42c0.09-2.18,0.15-4.36,0.5-6.52
      c0.05-0.28-0.17-0.49-0.47-0.44c-3.89,0.62-9.18,1.3-11.35-2.64c-1.6-2.91-1.48-6.47-0.42-9.58c0.98-1.28,2.09-2.46,3.37-3.51
      c2.22-1.81,4.7-3.18,7.33-4.27c-3.56,3.75-5.16,8.72-4.01,13.75c0.07,0.34,5.22,0.75,7.66,0.72c-0.56,2.47-0.64,5.09-0.72,7.6
      c-0.04,1.33-0.14,4.28,0.33,4.93C433.73,99.95,437.15,100.65,438.79,101.19z"/>
    <path fill="#9A7762" d="M442.38,125.06c-1.01,1.37-2.48,1.74-4.14,1.58c-0.68-2.24-1.39-4.98,0-5.93
      c0.43-0.29,1.34-0.41,1.89-0.39c-0.95,1.3-1.43,3.72-0.38,4.78C440.43,125.79,441.61,125.52,442.38,125.06z"/>
    <path fill="#9A7762" d="M426.65,131.1c0.09,0.02,0.17,0.02,0.25,0.03c-0.03,0.02-0.06,0.05-0.09,0.08
      c-2.05,0.38-2.84-0.03-2.36-1.22c0.22-0.69,0.53-1.35,0.8-2.03c0.56-1.4,0.92-2.85,1.37-4.28c0.38-1.19,0.82-2.27,1.42-3.19
      c-0.04,0.44-0.06,0.88-0.05,1.33c-0.34,1.12-0.41,2.23-0.66,3.38c-0.26,1.2-0.93,2.3-1.18,3.5
      C426.04,129.22,425.71,130.91,426.65,131.1z"/>
    <path fill="#9A7762" d="M446.03,121.92c0.49,0.07,1.18,0.16,1.77,0.15c0.07,0.27,0.12,0.6,0.17,0.97
      c-1.38,0.11-2.75,0.25-4.07,0.65C444.53,122.98,445.14,122.27,446.03,121.92z"/>
    <path fill="#9A7762" d="M435.26,133.09c0.06,0.66,0.42,1.13,1.12,1.03c-1.15,1.01-3.23,0.56-4.45-0.48
      c-1.25-1.07-2.26-2.32-3.22-3.61c0.41-0.58,0.72-1.3,0.87-1.99c0.94,2.68,2.17,4.37,3.78,3.74
      C433.86,132.45,434.82,133.01,435.26,133.09z"/>
    <path fill="#9A7762" d="M405.79,107.36c0.65-3.19,1.55-6.39,2.04-9.6c0.22-1.42,0.36-2.85,0.49-4.27
      c1.76-2.26,3.67-4.39,5.64-6.51c-1.7,3.89-2.97,7.98-4.1,11.97c-0.46,1.62-0.88,3.26-1.28,4.89c-1.1,2.51-1.97,5.13-3.6,7.41
      C405.26,109.95,405.53,108.65,405.79,107.36z"/>
    <path fill="#9A7762" d="M396.17,136.14c-0.08,0.56-0.15,1.11-0.24,1.67c-0.72,0.04-1.43,0.14-2.08,0.27
      c-0.7-0.66-4.18-1.8-4.91-2.01c0.15-0.49,0.27-0.98,0.37-1.48C391.47,135.53,393.87,136.19,396.17,136.14z"/>
    <path fill="#B30000" d="M382.91,208.35c0.47,0.28,6.63,0.51,7.52,0.08c0.6-0.29,0.36-2.45,0.17-2.65
      c-0.45-0.47-6.81-0.69-7.71-0.27C381.98,205.93,382.45,208.06,382.91,208.35z"/>
    <path fill="#B3B7BE" d="M385.81,174.85c-0.4,5.12-1.02,25.52-0.88,29.36c0.34,0.02,2.61,0.07,3.22,0.17
      c-0.01-9.76,0.23-19.34,0.91-29.09c0.74-10.53,2.41-23.37,4.17-27.8c-0.46-1.45-0.03-3.23,1.01-4.59c-2.28-0.59-5.77-1.61-7.39-0.7
      c0.3,0.54,0.55,1.11,0.72,1.69c1.07-0.04,2.5,0.39,2.82,1.34c0.28,0.81-0.29,2.42-0.71,3.4c0.52,0.73,0.45,1.73-1.09,2.04
      C387.61,156.74,386.58,164.95,385.81,174.85z"/>
    <path fill="#EBC3A6" d="M378.38,145.96c0.09-1.74,1-3.01,1.62-4.42c0.23-0.52,1.31-1.35,1.16-0.83
      c-0.46,1.6-1.5,3.75-1.62,5.42c-0.11,1.59,0.45,2.76,3,2.45c-1.44-2.31-0.49-5.28,0.64-7.26c0.28-0.49,1.19-0.8,1.07-0.35
      c-0.57,2.15-1.49,4.04-0.86,6.31c0.35,1.25,1.48,1.76,2.67,2.1c1.33,0.37,3.82,1.08,2.69-0.71c-0.34-0.54-2.26-1.28-2.92-1.36
      c-0.54-0.07,0.51-0.37,1.06-0.35c0.1-1.57-0.43-3.95-0.8-4.53c-1.45-2.34,6.12-1.22,8.66-0.12c1.43-1.05,3.15-1.04,2.62-0.74
      c-1.08,0.62-2.09,1.46-2.7,2.52c-1.05,1.83-0.72,4.31,1.21,4.69c0.49,0.1,0.67-2.63,1.43-3.72c0.9-1.3,4.76-5.22,1.46-5.92
      c-2.08-0.44-4.89,0.06-6.94-0.25c-0.24-0.04,0.64-0.46,0.65-0.46c-2.32-1.05-4.79-1.49-7.25-1.7c-2.21-0.18-5.04,0.12-6.6,0.9
      c-1.08,0.53-2.41,1.73-3.17,2.6c-0.38,0.43-1.4,2.53,0.42,2.44c0.8-1.75,2.12-2.84,3.22-3.55c0.3-0.19,1.36-0.47,1.11-0.2
      c-1.19,1.22-2.15,2.1-3.03,3.63C376.16,144.33,377.56,145.5,378.38,145.96z"/>
    <path fill="#E5AF8C" d="M454.57,117.84c-0.32-1.71-0.05-3.11,0.43-4.57c0.42,0.16,0.83,0.38,1.21,0.69
      c0.04,0.03,0.09,0.05,0.13,0.07c-0.28,1.16-0.39,2.33-0.41,3.51c-0.48,0.15-0.9,0.39-1.29,0.67
      C454.62,118.1,454.6,117.97,454.57,117.84z"/>
    <path fill="#E5AF8C" d="M430.86,122.26c0.57,2.78,0.45,6.22,2.07,8.84c-0.2,0.01-0.4-0.05-0.6-0.14
      C431.05,128.16,430.34,125.32,430.86,122.26z"/>
    <path fill="#E5AF8C" d="M435.16,132.16c-0.11-0.04-0.21-0.1-0.3-0.15c-0.62-3.75-2.05-7.68-1.14-11.4
      c0.22,1.36,0.68,4.12,0.99,6.38C434.96,128.7,434.99,130.44,435.16,132.16z"/>
    <path fill="#E5AF8C" d="M427.52,126.66c0.14-0.38,0.27-0.76,0.36-1.13c0.14-0.53,0.23-1.36,0.29-1.88
      c0.2,1.19,0.56,2.37,1.02,3.46c-0.16,0.56-0.36,1.31-0.65,1.94C428.23,128.24,427.94,127.43,427.52,126.66z"/>
    <path fill="#E5AF8C" d="M443.43,123c-0.06,0.06-0.12,0.13-0.19,0.21c-0.88-0.61-1.93-0.96-3.04-1
      c0.23-0.66,0.59-1.27,0.92-1.58c0.95,0.21,3.23,0.95,3.71,1.07C444.31,122.05,443.86,122.51,443.43,123z"/>
    <path fill="#E5AF8C" d="M381.37,148.61c-1.04-2.37-0.53-5.77,1.73-7.11c-1.08,1.97-1.94,4.83-0.54,7.08
      C382.1,148.64,381.71,148.65,381.37,148.61z"/>
    <path fill="#E5AF8C" d="M380.58,140.86c-0.24,0.2-0.47,0.47-0.57,0.68c-0.63,1.41-1.54,2.68-1.62,4.42
      c-0.26-0.15-0.58-0.36-0.86-0.65C377.78,143.48,379.02,141.26,380.58,140.86z"/>
    <path fill="#E5AF8C" d="M396.06,141.56c0.03,0.02,0.06,0.03,0.09,0.05c-0.43,0.14-0.92,0.37-1.37,0.71
      c-1.15-0.49-3.31-0.99-5.21-1.18c1.11-0.28,2.36-0.18,3.47-0.12C394,141.08,395.18,141.08,396.06,141.56z"/>
    <path fill="#E5AF8C" d="M375.86,140.94c0.76-1.15,2.19-2.12,3.69-2.02c-0.17,0.06-0.34,0.12-0.43,0.18
      c-1.09,0.71-2.41,1.8-3.22,3.55c-0.36,0.02-0.6-0.05-0.77-0.17C375.3,141.9,375.61,141.32,375.86,140.94z"/>
    <path fill="#B3B7BE" d="M376.54,145.72c-0.7-0.63-0.99-1.1-0.95-2.04c-0.84,0.15-1.46-0.37-1.76-1.11
      c-1.19,0.19-2.52,0.5-3.1,0.54c0.11,0.49,0.96,2.89,1.21,3.6C371.98,146.68,374.97,145.83,376.54,145.72z"/>
    <path fill="#8F97A1" d="M388.09,153.97c0.17-1.16,0.34-2.26,0.5-3.29c0.46-0.09,0.79-0.25,1.01-0.44
      C389.66,150.48,389.37,153.39,388.09,153.97z"/>
    <path fill="#8F97A1" d="M388.87,178.27c-1.13,0.92-2.26,1.84-3.39,2.76c0.05-1.09,0.09-2.1,0.14-3.01
      c0.22-0.19,0.44-0.39,0.66-0.57c0.94-0.8,1.87-1.59,2.79-2.39c0,0.08-0.01,0.16-0.02,0.23
      C388.99,176.29,388.93,177.28,388.87,178.27z"/>
    <path fill="#8F97A1" d="M388.16,202.12c0,0.76-0.01,1.51-0.01,2.26c-0.61-0.11-2.89-0.16-3.22-0.17
      c-0.02-0.39-0.02-0.95-0.02-1.65C386,202.47,387.09,202.32,388.16,202.12z"/>
    <path fill="#8F97A1" d="M393.23,147.5c-0.25,0.62-0.49,1.4-0.73,2.32c-0.02-0.21-0.07-4.75-0.6-5.74
      c-0.53-0.99-2.08-1.26-4.79-1.34c-0.08-0.18-0.16-0.35-0.26-0.53c1.63-0.91,5.11,0.1,7.39,0.7
      C393.19,144.26,392.76,146.05,393.23,147.5z"/>
    <path fill="#8F97A1" d="M375.58,145.85c-0.73-1.08-1.5-2.14-2.27-3.19c0.18-0.03,0.36-0.06,0.53-0.09
      c0.3,0.74,0.91,1.26,1.76,1.11c-0.05,0.95,0.25,1.41,0.95,2.04C376.26,145.74,375.93,145.79,375.58,145.85z"/>
    <path fill="#8F97A1" d="M372.34,145.12c0.18,0.44,0.36,0.89,0.53,1.33c-0.54,0.14-0.92,0.25-0.93,0.26
      c-0.24-0.71-1.1-3.11-1.21-3.6c0.16-0.01,0.38-0.05,0.64-0.09C371.69,143.72,372.01,144.42,372.34,145.12z"/>
    <path fill="#B30000" d="M368.33,148.21c1,0.29,2.62,0.34,3.02,0.06c-0.02-0.59-1.92-6.12-2.22-6.33
      c-1.33,0.46-2.76,1.45-3.02,3.06C365.87,146.55,367.02,147.83,368.33,148.21z"/>
    <path fill="#8D0000" d="M384.39,207.36c1.94-0.11,3.86-0.35,5.8-0.52c0.09-0.01,0.33-0.03,0.61-0.07
      c0.05,0.68-0.02,1.48-0.37,1.65c-0.88,0.43-7.05,0.2-7.52-0.08c-0.17-0.11-0.35-0.48-0.45-0.92
      C383.1,207.42,383.74,207.4,384.39,207.36z"/>
    <path fill="#8D0000" d="M369.28,147.19c-0.12-1.64-0.46-3.29-1.05-4.85c0.3-0.16,0.6-0.3,0.9-0.4
      c0.3,0.22,2.2,5.74,2.22,6.33c-0.28,0.2-1.19,0.23-2.05,0.12C369.31,148,369.31,147.6,369.28,147.19z"/>
    <path d="M409.27,181.4c2.21,1.62,5.37,2.89,8.46,3.31c2.56,0.35,5.32,0.12,7.67-0.91c1.3-0.56,2.45-1.25,3.83-1.64
      c0.43-0.12,0.29,0.72-0.11,0.89c-2.09,0.86-3.38,2.08-5.49,2.89c-1.67,0.64-3.59,0.62-5.36,0.55c-3.4-0.12-6.32-1.11-7.99-2.6
      C409.02,182.77,408.52,180.85,409.27,181.4z"/>
    <path d="M457.88,181.07c2.89,1.41,5.56,2.37,8.85,1.69c2.57-0.53,6.34-2.89,7.98-4.79c0.39-0.45,0.27,0.9-0.08,1.37
      c-4.16,5.63-10.09,7.3-15.98,3.55C457.76,182.33,456.92,180.6,457.88,181.07z"/>
    <path d="M408.96,133.46c3.32,0.99,6.47,1.95,9.93,2.4c3.73,0.49,7.5,0.57,11.24,0.91c0.27,0.02,0.3,0.38,0.01,0.41
      c-7.03,0.62-15.51,0.76-21.79-2.74C407.74,134.07,408.36,133.28,408.96,133.46z"/>
    <path d="M462.47,132.96c2.26-0.1,4.58,0.34,6.85,0.42c3.45,0.13,6.87-0.11,10.2-0.96c0.26-0.07,0.44,0.31,0.18,0.42
      c-4.94,2.12-12.25,2.63-17.41,1.12C461.66,133.79,461.86,132.99,462.47,132.96z"/>
    <path d="M466.56,137.94c4.12,0.2,8.1-0.39,12.11-1.25c0.17-0.04,0.2,0.2,0.05,0.24c-4.05,1.11-7.98,2.33-12.26,1.72
      C466.01,138.58,466.12,137.91,466.56,137.94z"/>
    <path d="M410.41,138.48c3.71,0.57,7.34,1.32,11.12,1.56c2.58,0.16,5.16-0.09,7.73-0.19c0.14,0,0.14,0.19,0.02,0.22
      c-6.13,1.41-12.97,0.48-19.01-0.95C409.83,139.01,409.96,138.41,410.41,138.48z"/>
    <path d="M395.09,146.58c0.5-0.69,1.63-0.77,2.07,0.04c0.03,0.06-0.05,0.14-0.11,0.1c-0.51-0.27-1.04-0.63-1.55-0.14
      c-0.26,0.24-0.37,0.64-0.44,0.96c-0.14,0.57-0.13,1.17-0.24,1.74c-0.03,0.13-0.25,0.09-0.24-0.03
      C394.61,148.4,394.56,147.32,395.09,146.58z"/>
    <path d="M385.73,149.88c0.75-1.17,2.27-0.85,3.26-0.14c0.07,0.05,0,0.15-0.07,0.1c-0.99-0.6-2.22-0.86-2.94,0.22
      C385.87,150.22,385.63,150.04,385.73,149.88z"/>
    <path d="M383.15,148.62c0.28-0.63,1.08-0.76,1.73-0.76c0.09,0,0.09,0.13,0,0.14c-0.63,0.06-1.16,0.26-1.55,0.74
      C383.25,148.84,383.11,148.72,383.15,148.62z"/>
    <path d="M385.07,147.14c0.08,0.01,0.09,0.12,0,0.13c-0.38,0.03-0.75,0.09-1.12,0.19c-0.46,0.13-0.7,0.43-0.92,0.8
      c-0.07,0.12-0.26,0.03-0.23-0.09C383.03,147.21,384.2,147.07,385.07,147.14z"/>
    <path d="M379.64,148.38c0.42-0.66,1.5-1.02,2.24-0.68c0.1,0.05,0.04,0.17-0.06,0.15c-0.43-0.11-0.8-0.1-1.2,0.08
      c-0.33,0.15-0.54,0.42-0.83,0.6C379.7,148.59,379.58,148.47,379.64,148.38z"/>
    <path d="M379.06,147.67c0.36-0.72,1.43-0.96,2.2-0.79c0.06,0.01,0.04,0.11-0.02,0.1c-0.77-0.07-1.54,0.19-1.98,0.79
      C379.18,147.88,379,147.79,379.06,147.67z"/>
    <path d="M398.37,141.13c0.67-0.33,1.8-0.41,2.29,0.25c0.08,0.11-0.11,0.22-0.19,0.11c-0.39-0.53-1.48-0.49-2.05-0.26
      C398.35,141.26,398.3,141.16,398.37,141.13z"/>
    <path d="M398.02,140.35c0.76-0.49,1.96-0.8,2.71-0.13c0.13,0.12-0.08,0.29-0.21,0.17c-0.63-0.57-1.74-0.28-2.41,0.09
      C398.02,140.52,397.95,140.4,398.02,140.35z"/>
    <path d="M375.67,143.99c0.65-0.49,2.56-1,3.05-0.08c0.02,0.05-0.03,0.1-0.08,0.07c-0.82-0.49-2.01-0.29-2.79,0.21
      C375.71,144.29,375.52,144.1,375.67,143.99z"/>
    <path d="M376.05,143.13c0.7-0.57,1.55-0.72,2.34-0.23c0.05,0.03,0.01,0.1-0.05,0.08c-0.78-0.34-1.46-0.15-2.11,0.33
      C376.1,143.41,375.92,143.24,376.05,143.13z"/>
    <path d="M374.06,141.73c0.5-0.6,1.4-0.55,2.03-0.16c0.06,0.04,0.01,0.11-0.05,0.08c-0.58-0.27-1.36-0.27-1.82,0.2
      C374.13,141.95,373.98,141.83,374.06,141.73z"/>
    <path d="M374.14,140.88c0.65-0.47,1.52-0.39,2.22-0.05c0.05,0.03,0.01,0.11-0.04,0.09c-0.67-0.24-1.44-0.23-2.07,0.09
      C374.15,141.06,374.05,140.95,374.14,140.88z"/>
    <path d="M435.4,130.46c0.71-0.55,1.33-0.19,1.59,0.55c0.3,0.87,0.17,1.83,0.16,2.73c0,0.18-0.3,0.14-0.3-0.03
      c0.01-0.67,0.05-1.34-0.02-2.01c-0.04-0.35-0.1-0.71-0.28-1.02c-0.24-0.41-0.72-0.25-1.09-0.12
      C435.41,130.57,435.35,130.5,435.4,130.46z"/>
    <path d="M433.14,130.28c0.41-0.43,1.19-0.42,1.64-0.05c0.51,0.43,0.68,1.13,0.6,1.74c-0.01,0.14-0.21,0.14-0.23,0
      c-0.05-0.4-0.1-0.8-0.27-1.18c-0.11-0.23-0.28-0.41-0.51-0.54c-0.39-0.22-0.77-0.05-1.11,0.16
      C433.18,130.49,433.06,130.36,433.14,130.28z"/>
    <path d="M431.61,129.55c0.49-0.06,0.97,0.2,1.29,0.52c0.09,0.09-0.04,0.23-0.14,0.15c-0.25-0.22-0.5-0.38-0.84-0.45
      c-0.22-0.05-0.84-0.03-0.91,0.26c-0.01,0.05-0.1,0.04-0.1-0.01C430.94,129.65,431.25,129.59,431.61,129.55z"/>
    <path d="M426.7,128.44c0.54-0.04,0.83,0.54,0.91,0.95c0.11,0.51-0.05,1.12-0.37,1.53c-0.09,0.11-0.31,0.03-0.24-0.11
      c0.19-0.38,0.29-0.78,0.29-1.2c-0.01-0.42-0.2-0.63-0.44-0.88c-0.01,0-0.02,0-0.03-0.01c-0.08-0.08-0.08-0.08-0.15-0.18
      C426.63,128.51,426.64,128.45,426.7,128.44z"/>
    <path d="M440.24,121.26c1.17-0.28,2.16,0.16,3.08,0.81c0.05,0.04,0.01,0.14-0.05,0.1c-0.46-0.2-0.89-0.44-1.36-0.6
      c-0.54-0.18-1.06-0.14-1.61-0.08C440.14,121.51,440.08,121.3,440.24,121.26z"/>
    <path d="M436.94,126.73c0.04,0.04-0.01,0.11-0.07,0.08c-0.44-0.26-1.05-0.4-1.38,0.06c-0.07,0.1-0.27,0.04-0.22-0.09
      C435.51,126.14,436.57,126.27,436.94,126.73z"/>
    <path d="M435.1,126.03c0.37-0.53,1.17-0.53,1.62-0.09c0.04,0.04-0.02,0.11-0.07,0.08c-0.45-0.33-1-0.25-1.37,0.13
      C435.19,126.23,435.03,126.13,435.1,126.03z"/>
    <path d="M435.22,125.3c0.34-0.38,0.89-0.25,1.25,0.03c0.05,0.04-0.02,0.11-0.07,0.08c-0.32-0.18-0.73-0.25-1.02,0.02
      C435.29,125.53,435.14,125.4,435.22,125.3z"/>
    <path d="M434.17,126.94c0.09,0.06-0.01,0.17-0.1,0.12c-0.67-0.36-1.49-0.35-2,0.23c-0.08,0.09-0.25-0.01-0.21-0.12
      C432.21,126.32,433.54,126.53,434.17,126.94z"/>
    <path d="M431.82,126.04c0.54-0.87,1.78-0.92,2.44-0.18c0.05,0.05-0.03,0.14-0.09,0.09c-0.76-0.63-1.53-0.38-2.15,0.24
      C431.92,126.29,431.75,126.15,431.82,126.04z"/>
    <path d="M430.93,125.81c0.05,0.04-0.02,0.11-0.07,0.08c-0.47-0.23-1.32,0.05-1.47,0.54c-0.03,0.1-0.18,0.09-0.2-0.02
      C429.09,125.7,430.45,125.44,430.93,125.81z"/>
    <path d="M430.86,125.17c0.05,0.03,0.01,0.1-0.05,0.07c-0.54-0.25-1.22-0.3-1.53,0.27c-0.06,0.11-0.25,0.04-0.21-0.08
      C429.32,124.69,430.3,124.81,430.86,125.17z"/>
    <path d="M427.12,126.41c0.42-0.39,1.1-0.04,1.48,0.25c0.07,0.06-0.01,0.16-0.09,0.11c-0.32-0.19-0.87-0.44-1.21-0.16
      C427.17,126.72,426.99,126.53,427.12,126.41z"/>
    <path d="M427.46,125.63c0.57-0.14,1.04,0.21,1.35,0.63c0.05,0.07-0.06,0.16-0.12,0.09c-0.28-0.32-0.71-0.62-1.17-0.49
      C427.36,125.9,427.3,125.67,427.46,125.63z"/>
    <path d="M442.49,114.91c2.56-0.45,5.21-0.51,7.79-0.75c1.18-0.1,2.36-0.07,3.53-0.18c0.97-0.09,1.93-0.06,2.87,0.19
      c0.26,0.07,0.13,0.43-0.13,0.37c-2.16-0.55-5.01-0.25-7.2-0.04c-2.28,0.22-4.57,0.29-6.85,0.53
      C442.42,115.04,442.4,114.92,442.49,114.91z"/>
    <path d="M445.99,115.16c1.74-0.03,3.47-0.02,5.21-0.02c1.09-0.01,2.18,0.04,3.26-0.15c0.71-0.12,1.36-0.23,2.07-0.15
      c0.24,0.02,0.15,0.36-0.08,0.33c-0.93-0.12-1.84,0.04-2.76,0.13c-0.81,0.07-1.65,0.01-2.47,0c-1.75-0.01-3.49,0-5.23-0.03
      C445.91,115.27,445.92,115.16,445.99,115.16z"/>
    <path d="M463.64,186.55c3.31,0.16,6.88-0.61,9.15-3c0.05-0.06,0.16,0,0.11,0.07c-2.02,2.66-5.88,4.02-9.32,3.48
      C463.21,187.04,463.26,186.53,463.64,186.55z"/>
    <g>
      <g>
        <path d="M412.76,50.79c-1.25-1.28-1.32-3-1.21-4.71c0.01-0.07,0.12-0.07,0.13,0c0.1,1.28,0.2,2.56,0.87,3.6
          c-0.23-0.66-0.33-1.4-0.41-1.96c-0.19-1.35-0.07-2.67,0.16-4c0.01-0.08,0.14-0.06,0.13,0.02c-0.11,1.18,0.02,2.39,0.21,3.56
          c0.11,0.67,0.29,1.32,0.51,1.96c0.02,0.05,0.04,0.1,0.07,0.15c-0.43-2.37-0.26-4.78-0.11-7.18c0-0.07,0.13-0.07,0.13,0
          c0.02,1.31,0.05,2.61,0.15,3.91c0.24-6.6,2.28-14.21,4.88-19.6c1.68-3.47,4.62-7.45,9.71-10c2.66-1.34,5.83-1.84,8.8-1.1
          c2.92,0.74,5.4,2.71,7.19,4.93c2.44,3.04,3.36,6.25,3.76,9.16c0-0.14-0.01-0.28-0.01-0.42c-0.02-0.6-0.1-1.19-0.14-1.78
          c-0.04-0.64,0.09-1.23-0.08-1.86c-0.01-0.05,0.07-0.08,0.09-0.03c0.39,0.98,0.17,2.24,0.32,3.29c0.02,0.11,0.03,0.22,0.05,0.34
          c0-0.02,0.01-0.03,0.01-0.05c0.2-1.19,0.79-2.14,1.54-3.11c0.06-0.07,0.17,0.01,0.13,0.08c-0.21,0.46-0.49,0.89-0.75,1.33
          c-0.43,0.74-0.39,1.68-0.46,2.49c-0.02,0.15-0.03,0.3-0.04,0.45c0.68-1.95,1.78-3.74,3.24-5.42c0.03-0.04,0.1-0.01,0.07,0.04
          c-0.78,1.51-1.86,2.85-2.54,4.42c-0.29,0.66-0.43,1.35-0.55,2.04c0.23-0.58,0.46-1.14,0.69-1.69c0.72-1.72,1.76-3.36,3.23-4.61
          c0.07-0.06,0.19,0.02,0.12,0.1c-1.35,1.34-2.04,3.01-2.69,4.72c-0.21,0.57-0.39,1.16-0.57,1.76c1.3-2.19,3.07-4.73,4.78-6.38
          c0.4,1.96-0.21,6.63-0.47,8.1c0.99-1.57,1.23-3.45,1.28-5.25c0-0.09,0.15-0.1,0.15-0.01c0.04,1.9,0.03,3.74-0.86,5.43
          c0.09,0.02,0.19,0.03,0.28,0.04c0.81-1.05,1.18-2.06,1.25-3.41c0-0.07,0.13-0.07,0.13,0c0.04,1.18-0.07,2.48-0.77,3.49
          c1.53,0.2,3.44,0.34,5.06,0.43c-0.61,1.07-1.88,3-2.59,4.01c1.22-0.94,2.47-2.11,2.96-3.52c0.04-0.12,0.24-0.06,0.2,0.06
          c-0.43,1.42-1.35,2.61-2.48,3.62c0.14,0.03,0.28,0.06,0.42,0.1c1.01-0.75,1.98-1.44,2.45-2.62c0.03-0.07,0.15-0.04,0.13,0.03
          c-0.26,1.02-1.03,2.03-1.94,2.73c1.82,0.42,3.99,0.96,5.32,1.52c-0.62,2.73-5.37,4.27-7.6,4.52c0.65,0.72,2.07,1.55,3.63,2.31
          c-0.89,1.75-4.52,2.46-7.75,2.22c0.05,0.27,0.07,0.54,0.08,0.82c0.74,0.27,1.42,0.68,2.2,0.86c1.44,0.33,3.14-0.07,4.36-0.81
          c0.05-0.03,0.1,0.04,0.05,0.07c-2.07,1.46-4.43,1.6-6.62,0.6c-0.12,2.08-1.19,4.11-3.23,5.13c4.98,3.95,1.96,11.22-0.37,15.46
          c-1.25,2.27-2.73,4.91-4.39,6.5c-1.65,1.59-4.08,3.52-6.25,4.6c-2.46,4.05-6.93,7.15-9.16,8.09c-3.54,1.49-8.88,0.65-9.5-3.82
          c-0.23-1.68,0.1-4.23,2.6-5.53c-0.12-0.31-0.74-2.19-1.88-4.14c-2.59-4.43-6.06-8.94-6.39-14.12c-3.32,1.33-6.58,3-10.18,3.6
          c-1.85,0.31-5.11,0.29-6.16-0.38C397.87,62.35,406.56,54.01,412.76,50.79z"/>
        <path fill="#EBC3A6" d="M449.01,54.4c-1.36,0.49-2.94,0.52-4.31-0.23c-0.36-0.2-0.15,1.09,0.44,1.47
          c0.81,0.52,1.96,0.99,2.99,0.54c1.08,0.64,1.9,2.11,2.25,3.24c0.71,2.69-0.12,5.58-1.14,8.1c-1.01,2.5-1.78,4.96-4.03,7.52
          c-2.25,2.56-4.71,4.59-5.85,5.23c0.37-0.97,0.33-3.57,0-3.13c-0.34,0.46-0.95,2.36-2.17,4.22c-0.91,1.38-2.07,2.71-3.25,3.91
          c-2.17,2.18-5.12,4.24-8.46,4.31c-1.16-0.57-2.53-1.2-1.96-2.64c0.39-0.99,1.62-1.61,2.65-1.87c0.27-0.07-0.3-0.17-0.56-0.1
          c-1.37,0.35-2.28,0.94-2.63,2.26c-0.28,1.07,0.52,1.74,1.43,2.25c-1.15-0.19-4.02-1.78-2.71-5.59c0.35-1.02,1.77-1.79,3.03-2.28
          c1.55-0.61,3.17-0.61,2.78-0.82c-0.8-0.44-2.25-0.54-3.27-0.35c-0.13-0.34-1.13-2.74-1.48-3.39c-0.75-1.41-1.58-2.78-2.4-4.16
          c-1.56-2.63-3.2-5.38-3.85-8.35c-0.17-0.77-0.28-1.55-0.31-2.33c1.09-0.41,1.77-0.71,2.62-0.95c0.86-0.24,4.23-0.88,3.66-1.35
          c-0.87-0.72-4.59-0.05-5.35,0.17c-1.62,0.47-3.17,1.09-4.72,1.74c-3.8,1.61-9.16,3.97-12.23,3.01c0.22-1.8,5.86-7.99,10.15-10.8
          c2.18-1.42,7.3-3.88,8.36-4.29c1.07-0.4-2.9-0.35-3.61-0.02c-0.12-6.59,1.47-14.99,3.15-19.37c1.98-5.19,4.48-8.73,8.02-10.94
          c3.54-2.21,8.09-4.58,13.74-0.6c5.39,3.81,6.02,10.21,6.56,16.68c-1.39,0.06-2.84-0.27-3.51-0.41c-0.46,2.65,2.26,5.58,3.03,6.48
          c-0.84,0.06-2.9,0.13-4.69,0.19c0.94,1.52,3.85,3.64,5.62,4.1c-0.46,0.63-0.79,1.52-0.74,2.35c0.06,0.76,1.1,1.59,1.1,1.01
          c0-0.98,0.08-2.52,1.44-2.78c1.36-0.27,2.09,0.87,2.43,2.35C451.76,51.21,450.37,53.91,449.01,54.4z"/>
        <path fill="#E5AF8C" d="M441.78,21.54c0.85,2.1,1.53,4.41,1.48,6.67c-0.05,2.46-0.53,4.89-0.6,7.34
          c-0.06,2.09,0.08,4.44,1.56,6.09c0.78-0.03,1.44-0.06,1.83-0.09c-0.77-0.9-3.49-3.83-3.03-6.48c0.67,0.14,2.12,0.47,3.51,0.41
          c-0.52-6.18-1.12-12.3-5.88-16.16C441.1,20.03,441.47,20.78,441.78,21.54z"/>
        <path fill="#E5AF8C" d="M445.52,54.51c1.15,0.37,2.39,0.28,3.49-0.11c1.36-0.48,2.76-3.19,2.2-5.63
          c-0.02-0.06-0.03-0.12-0.05-0.17C450.98,51.34,448.45,54.27,445.52,54.51z"/>
        <path fill="#E5AF8C" d="M449.24,67.51c1.02-2.51,1.85-5.39,1.15-8.07c-0.4,0.13-0.89,0.56-1.19,0.84
          c-0.5,0.46-1,0.96-1.39,1.5c-1.77,2.46-2.94,5.65-3.73,8.49c-0.44,1.56-0.79,3.15-0.92,4.76c-0.06,0.73-0.2,1.54-0.2,2.32
          c0.73-0.7,1.5-1.49,2.24-2.33C447.46,72.47,448.23,70.01,449.24,67.51z"/>
        <path fill="#E5AF8C" d="M446.33,61.55c-1.42,2.33-2.56,4.82-3.28,7.42c-0.85,3.03-1.22,6.16-1.03,9.26
          c0.18-0.17,0.37-0.33,0.56-0.51c-0.04-0.78,0.06-1.59,0.12-2.36c0.17-2.12,0.61-4.21,1.29-6.24c0.69-2.09,1.53-4.13,2.6-6.08
          c0.54-0.98,1.06-1.82,1.87-2.62c0.38-0.38,1.15-1.04,1.81-1.31c-0.05-0.14-0.11-0.28-0.17-0.42
          C448.7,58.68,446.89,60.64,446.33,61.55z"/>
        <path fill="#E5AF8C" d="M427.5,89.28c2.5-0.65,4.71-2.29,6.43-4.02c0.63-0.64,1.25-1.32,1.84-2.02
          C432.65,80.64,427.63,85.83,427.5,89.28z"/>
        <path fill="#E5AF8C" d="M436.92,77.93c-0.39,0.84-0.93,1.7-1.83,2.14c-0.9,0.44-1.9,0.44-2.88,0.32
          C432.81,84.41,439.77,80.17,436.92,77.93z"/>
        <path fill="#E5AF8C" d="M423.96,53.15c-2.25-1.37-4.6,1.52-5.5,3.41c1.12-0.65,2.56-1.18,3.82-0.91
          c1.39,0.29,1.79,1.36,1.74,2.55C426.14,57.66,425.46,54.05,423.96,53.15z"/>
        <path fill="#E5AF8C" d="M420.94,59.57c-1.66-1.34-4.05-1.94-6.17-1.22c-1.7,0.58-3.09,1.77-4.36,2.93
          c-1.05,0.95-2.07,1.87-3.21,2.6c1.82-0.63,3.64-1.42,5.2-2.08c1.54-0.65,3.1-1.28,4.72-1.74
          C417.63,59.92,419.51,59.55,420.94,59.57z"/>
        <path fill="#978D82" d="M454.28,43.81c1.02,1.01,2.45,2.09,4.18,2.98c-1.07,0.63-3.13,0.98-6.04,0.54
          c-0.14-1.07-1.69-3.41-3.93-2.49c-1.64-0.38-3.52-1.2-4.46-2.22c0.65,0.01,2.83,0.04,4.35-0.36c-1.42-1.17-3.6-4.24-3.89-5.76
          c0.46,0.18,2.12,0.33,3.35,0.36c1.05-2.31,3.61-6.56,5.23-8.43c-0.12,1.55-0.63,4.19-1.04,5.88c1.78,0.38,4.4,0.67,6.09,0.76
          c-0.45,0.74-1.45,2.63-2.35,3.68c1.57,0.24,5.88,1.48,6.77,1.8C461.81,41.75,457.73,43.59,454.28,43.81z"/>
        <path fill="#B5A99A" d="M449.91,36.96c0,0,1.64-6.02,2.07-7.1c-1.46,2.04-3.26,5.15-4.13,7.01
          C447.92,36.89,449.91,36.96,449.91,36.96z"/>
        <path fill="#79716A" d="M456.29,34.93c0.09,1.04,0.11,2.09,0,3.15c0.74-1.06,1.47-2.41,1.83-3.01
          C457.59,35.04,456.96,34.99,456.29,34.93z"/>
        <path fill="#79716A" d="M462.47,40.65c-1.85,0.56-3.72,1.08-5.61,1.49c-2.65,0.57-5.57,1.27-8.3,1.18
          c-0.22-0.01-0.23-0.31-0.05-0.38c1.23-0.48,2.36-1.13,3.45-1.85c-2.86,0.06-5.43-1.6-7.16-3.72c0.7,1.62,2.41,3.9,3.59,4.87
          c-1.52,0.41-3.69,0.38-4.35,0.36c0.94,1.01,2.82,1.84,4.46,2.22c2.24-0.92,3.79,1.42,3.93,2.49c2.91,0.44,4.97,0.09,6.04-0.54
          c-1.73-0.88-3.17-1.96-4.18-2.98C457.64,43.59,461.59,41.85,462.47,40.65z"/>
        <path d="M427.29,36.65c1.99,1.01,2.96,1.15,4.81,1.36c1.49,0.17,3.35,0.91,4.14,1.98c0.79,1.07,0.9,2.31-0.06,3.33
          c1.27-0.46,1.64-2.4,0.59-3.38c-0.19-0.18,0.39-0.07,0.68,0.32c0.73,0.98,0.53,2.13-0.49,3.09c1.16,0.04,2.08,0.75,1.65,0.73
          c-1.87-0.1-6.74,0.87-8.96,2.07c-0.36,0.19-0.28-0.39,0.23-0.94c-0.63-0.1-1.22-0.44-1.62-0.94c-0.62-0.77-0.55-1.57-0.46-2.48
          c0.01-0.07,0.11-0.06,0.11,0.01c-0.01,0.71-0.09,1.42,0.27,2.07c0.4,0.71,1.23,1.05,2.05,1.04c0.09-0.07,0.2-0.14,0.31-0.2
          c-1.89-0.34-2.88-2.88-1.37-4.94c0.58-0.79,1.43-1.1,2.37-1.28c-1.6-0.09-2.26-0.4-3.27-0.74
          C427.25,37.39,426.93,36.47,427.29,36.65z"/>
        <path fill="#FFFFFF" d="M429.82,43.25c-1.3-1.38-0.61-3.76,1.62-4.2c1.71-0.33,3.44,0.37,4.26,1.32
          c0.86,1.01,0.29,2.32-0.74,3.02C433.93,44.1,431.12,44.63,429.82,43.25z"/>
        <path d="M416.95,42.4c1.11-0.23,1.88-1.47,2.6-1.98c1.14-0.8,2.71-1.5,3.85-2.3c0.29-0.2,0.11,0.38-0.31,0.66
          c-0.73,0.47-1.42,0.95-2.15,1.39c0.15-0.03,0.29-0.06,0.44-0.08c3.84-0.45,5.42,3.62,3.44,6.05c0.45-0.1,0.84-0.28,1.13-0.69
          c0.4-0.56,0.39-1.33,0.34-1.97c-0.01-0.07,0.1-0.09,0.12-0.01c0.2,1.22-0.18,2.51-1.36,2.97c0.49,0.35,0.9,0.84,0.46,0.8
          c-3.7-0.34-7.13,0.31-8.01,0.52c-0.67,0.16,0.34-0.54,1.03-0.92c-0.81-0.34-1.05-0.86-1.22-1.53c-0.07-0.25,0.12-0.72,0.16-0.47
          c0.13,0.78,0.68,1.52,1.5,1.73c-1.19-0.93-1.5-2.21-1.19-3.39c-0.69,0.01-0.84-0.25-1.03-0.53
          C416.6,42.4,416.72,42.46,416.95,42.4z"/>
        <path fill="#FFFFFF" d="M423.47,45.83c-1.01,0.46-2.71,0.38-3.48-0.09c-0.77-0.48-2.03-1.7-0.44-3.75
          c1.07-1.38,4.05-2,5.2,0.55C425.42,44.02,424.49,45.37,423.47,45.83z"/>
        <path d="M436.73,54.78c-1.48-0.24-3.04-0.16-4.53,0.16c-0.49,0.11,0.8-1.9,5.16-1.04c2.4,0.47,3.75,1.6,4.51,3.47
          c0.04,0.1-0.1,0.2-0.18,0.11c-0.98-1.19-2.26-1.96-3.68-2.4c1.3,3.07,1.79,9.26,0.66,15.12c-0.39,2.03-0.85,4.11-2.07,5.85
          c-1.11,1.58-2.85,2.72-4.97,2.28c-2.61-0.54-4.7-2.61-6.16-4.57c-1.62-2.19-4.19-8.98-4.15-9.39
          C427.46,62.99,433.38,59.77,436.73,54.78z"/>
        <path fill="#FFFFFF" d="M422.85,65.3c10.94-3.11,14.46-9.15,14.46-9.15c0.56,2.19,0.85,4.43,0.62,4.78
          c-4.36,6.48-10.65,8.71-12.85,8.92C424.3,68.35,423.46,66.86,422.85,65.3z"/>
        <path fill="#FFFFFF" d="M433.96,76.39c-1.92,0.9-3.77,0.09-5.3-1.32c2.47-1.31,5.24-1.98,7.94-2.75
          C436.18,73.88,435.42,75.56,433.96,76.39z"/>
        <path d="M438.79,24.61c-1.06-0.49-2.15-0.81-3.97-0.89c-1.77-0.08-3.16,0.02-4.87,0.42c-1.76,0.44-4.25,1.21-5.8,2.08
          c-1.54,0.87-2.45,1.41-3.17,1.99c-0.16,0.13,0.4-0.7,0.55-0.83c3.03-2.41,8.24-4.56,12.8-4.37c1.56,0.06,3.21,0.44,4.33,1.08
          C438.99,24.27,439.4,24.89,438.79,24.61z"/>
        <path d="M418.36,38.06c0.05,0.57,0.33,1.36,0.43,0.69c0.06-0.39,0.36-1.83,1.29-3.02c0.93-1.19,2.13-1.9,3.47-2.22
          c0.9-0.21-1.07-1.46-1.5-1.27C419.13,33.53,418.31,37.5,418.36,38.06z"/>
        <path d="M429.85,31.3c0.69-0.41,1.89-0.08,2.54,0.25c1.59,0.8,2.78,1.97,3.24,3.39c0.53,1.65,0.16,2.79,0.03,2.27
          c-0.15-0.64-0.58-1.52-1.57-2.59c-1-1.09-2.3-1.75-3.93-1.78C429.51,32.82,429.33,31.61,429.85,31.3z"/>
        <path d="M418.43,55.98c-0.43,0.32-0.66,1.19-0.24,0.87c1.87-1.39,6.26-1.65,5.64,1.54c-0.09,0.5,0.59,0.16,0.69-0.34
          C425.27,54.16,420.91,54.14,418.43,55.98z"/>
        <path d="M436.75,21.26c-1.79-1.13-4.86-0.35-6.63,0.47c-0.73,0.34-0.95,0.7-0.7,0.59c1.64-0.68,5.46-1.63,7.56-0.41
          C437.22,22.04,436.98,21.4,436.75,21.26z"/>
        <path d="M448.74,50.84c-0.3-0.51-1.04-0.47-1.3-0.21c-0.19,0.19-0.08,0.38,0.12,0.21c0.11-0.1,0.37-0.12,0.5-0.06
          c0.12,0.06,0.21,0.23,0.24,0.32c0.09,0.24,0.07,0.56-0.04,0.8c-0.1,0.2-0.31,0.36-0.53,0.42c-0.41,0.12-0.63-0.43-0.69-0.19
          c-0.07,0.34,0.25,0.59,0.63,0.64c0.36,0.04,0.78-0.12,0.99-0.44C448.92,51.93,449.02,51.32,448.74,50.84z"/>
        <path d="M436.5,78.08c-0.7,1.77-3.45,2.46-4.89,1.49c-0.22-0.15-0.11,0.29,0.08,0.48c1.65,1.74,4.67,0.86,5.06-1.62
          C436.8,78.07,436.59,77.85,436.5,78.08z"/>
        <path fill="#B5A99A" d="M452.1,34.57c0,0,0.87,0.27,1.55,0.36c0.68,0.09,2.02,0.07,2.02,0.07s-1.88,1.09-2.4,1.02
          C452.73,35.95,452.1,34.57,452.1,34.57z"/>
        <path fill="#B5A99A" d="M452.49,38.2c0,0,2.86,0.54,5.06,1.13c2.2,0.6,3.83,1.11,3.83,1.11s-4.6,0.03-5.38-0.13
          C455.23,40.17,452.49,38.2,452.49,38.2z"/>
        <path fill="#F2D8C2" d="M425.9,20.33c1.66-1.3,5.23-3.37,8.17-3.07c2.95,0.3-4.57,4.65-7.29,5.68
          C424.05,23.96,422,23.38,425.9,20.33z"/>
        <path fill="#F2D8C2" d="M432.85,46.46c1.8-1.06,6.01-1.57,8.66-0.72C444.15,46.6,431.2,47.43,432.85,46.46z"/>
        <path fill="#F2D8C2" d="M404.61,59.53c2.73-2.71,5.88-5.48,9.69-7.34c3.82-1.87,0.41,2.84-0.92,3.85
          C412.05,57.06,401.7,62.42,404.61,59.53z"/>
        <path fill="#F2D8C2" d="M423.03,83.21c0.85-0.83,3.17-1.86,5-1.91c1.82-0.05,0.13,1.91-0.9,2.36
          C426.1,84.13,421.36,84.83,423.03,83.21z"/>
        <path d="M432.35,41.7c0,0.81,0.7,1.46,1.57,1.46c0.87,0,1.57-0.65,1.57-1.46c0-0.81-0.7-1.46-1.57-1.46
          C433.05,40.24,432.35,40.89,432.35,41.7z"/>
        <path d="M422.17,43.46c0,0.74,0.65,1.34,1.44,1.34c0.79,0,1.44-0.6,1.44-1.34c0-0.74-0.65-1.34-1.44-1.34
          C422.82,42.12,422.17,42.72,422.17,43.46z"/>
        <path fill="#FFFFFF" d="M435.03,41.39c-0.04,0.28-0.32,0.47-0.62,0.43c-0.3-0.04-0.5-0.3-0.46-0.57
          c0.04-0.28,0.32-0.47,0.62-0.43C434.86,40.86,435.07,41.12,435.03,41.39z"/>
        <path fill="#FFFFFF" d="M434.08,40.77c-0.02,0.12-0.14,0.2-0.27,0.19c-0.13-0.02-0.22-0.13-0.21-0.25
          c0.02-0.12,0.14-0.21,0.27-0.19C434.01,40.53,434.1,40.65,434.08,40.77z"/>
        <path fill="#FFFFFF" d="M424.68,43.14c-0.04,0.22-0.26,0.38-0.5,0.35c-0.24-0.03-0.41-0.24-0.37-0.47
          c0.03-0.22,0.26-0.37,0.5-0.34C424.55,42.71,424.72,42.92,424.68,43.14z"/>
        <path fill="#FFFFFF" d="M423.91,42.59c-0.02,0.1-0.12,0.16-0.22,0.15c-0.1-0.01-0.18-0.1-0.16-0.2
          c0.01-0.1,0.11-0.17,0.22-0.15C423.85,42.4,423.93,42.49,423.91,42.59z"/>
      </g>
      <path d="M452.19,48.11c1.99,1.78,6.58,2.29,8.43,0.05c0.06-0.07,0.16,0.02,0.11,0.08c-1.86,2.64-6.87,2.56-9.06,0.33
        C451.38,48.3,451.89,47.85,452.19,48.11z"/>
      <path d="M419.07,58.64c0.29-0.24,0.59-0.38,0.99-0.36c0.38,0.02,0.74,0.18,1.02,0.41c0.67,0.55,0.34,1.58-0.55,1.69
        c-0.37,0.05-0.54-0.4-0.32-0.63c0.08-0.08,0.17-0.15,0.26-0.22c0.04-0.03,0.06-0.06,0.07-0.08c0.01-0.02,0.01-0.05,0.01-0.06
        c0,0-0.02-0.06-0.02-0.07c0.03,0.08-0.02-0.04-0.03-0.04c-0.02-0.03-0.05-0.07-0.07-0.1c-0.22-0.3-0.86-0.53-1.21-0.33
        C419.08,58.92,418.94,58.75,419.07,58.64z"/>
      <path d="M439.26,43.11c1.3-0.21,2.62,0.22,3.53,1.09c0.04,0.04-0.02,0.1-0.07,0.07c-1.13-0.61-2.25-0.64-3.48-0.87
        C439.08,43.38,439.1,43.14,439.26,43.11z"/>
      <path d="M439.81,43.95c1.35,0.14,2.39,0.8,3.47,1.51c0.07,0.04-0.01,0.14-0.08,0.1c-1.07-0.62-2.22-1.03-3.45-1.26
        C439.53,44.25,439.57,43.93,439.81,43.95z"/>
      <path d="M433.72,50.64c2.66,0.94,4.81,2.87,7.44,3.91c0.06,0.02,0.03,0.11-0.03,0.09c-2.65-0.83-5.24-2.22-7.63-3.57
        C433.22,50.91,433.41,50.53,433.72,50.64z"/>
      <path d="M421.1,29.92c2.4-1.86,5.43-3.11,8.41-3.93c1.53-0.42,3.13-0.71,4.71-0.9c1.66-0.19,3.19-0.14,4.67,0.65
        c0.05,0.03,0.01,0.11-0.05,0.09c-2.82-1.26-6.4-0.27-9.22,0.55c-3.01,0.88-5.56,2.46-8.27,3.91
        C421.11,30.42,420.9,30.07,421.1,29.92z"/>
      <path d="M426.33,29.49c1.49-0.97,3.23-1.51,4.97-1.93c1.71-0.42,3.93-0.94,5.66-0.39c0.06,0.02,0.03,0.1-0.03,0.08
        c-1.74-0.43-3.77,0.43-5.43,0.87c-1.69,0.45-3.29,1.07-4.82,1.87C426.31,30.19,425.99,29.71,426.33,29.49z"/>
      <path d="M417.36,30.86c1.61-5.51,6.67-8.66,11.96-10.94c0.07-0.03,0.11,0.06,0.05,0.09c-5.13,2.42-9.87,5.5-11.52,10.92
        C417.77,31.21,417.27,31.14,417.36,30.86z"/>
      <path d="M431.7,45.66c1.83-0.64,3.65-1,5.6-0.95c0.08,0,0.08,0.12,0,0.12c-1.87,0.11-3.71,0.52-5.46,1.15
        C431.62,46.06,431.47,45.73,431.7,45.66z"/>
      <path d="M417.89,48.23c1.75-0.65,3.59-0.76,5.46-0.71c0.07,0,0.06,0.09-0.01,0.09c-1.84,0.07-3.62,0.38-5.37,0.88
        C417.79,48.55,417.72,48.29,417.89,48.23z"/>
    </g>
    <path fill="#F2D8C2" d="M388.93,137.71c-1.9-0.52-6.36-0.78-7.56-0.04c-1.2,0.75,3.76,1.12,4.6,1.12
      C386.81,138.79,391.09,138.3,388.93,137.71z"/>
    <path fill="#F2D8C2" d="M449.57,117.26c-1.66-0.02-7.82,0.75-6.96,1.12c2.44,1.04,6.12,1.16,7.6,0.86
      C451.69,118.93,452.93,117.3,449.57,117.26z"/>
    <path fill="#D5BDAC" d="M475.27,117.13c-2.46-1.53-9.48-3.21-12.21-3.47c-2.73-0.27,2.16,2,5.75,3.21
      C472.4,118.07,477.86,118.73,475.27,117.13z"/>
    <path fill="#D5BDAC" d="M473.26,135.19c-2.9,0.39-7.9,0.27-10.92-0.27c-3.02-0.53,0.57,1.87,4.74,2.14
      C471.25,137.33,478.29,134.52,473.26,135.19z"/>
    <path fill="#D5BDAC" d="M413.79,98.4c-1.17,3.65-4.6,16.05-5.03,18.86c-0.43,2.81,6.75-6.82,7.04-8.56
      C416.09,106.96,415.08,94.39,413.79,98.4z"/>
    <path fill="#D5BDAC" d="M398.56,112.98c-1.76,1.45-4.45,5.49-5.17,9.5C392.67,126.49,401,110.98,398.56,112.98z"/>
    <path fill="#D5BDAC" d="M481.17,89.04c-0.38-3.23-5.32-9.23-7.9-10.16C470.68,77.94,481.66,93.19,481.17,89.04z"/>
    <path fill="#D5BDAC" d="M460.77,85.56c-0.89,1.79-2.3,6.95-2.59,8.02c-0.29,1.07,2.3-2.94,2.73-3.88
      C461.34,88.77,461.63,83.82,460.77,85.56z"/>
    <path fill="#A89C94" d="M426.4,181.17c-3.16,2.16-10.17,2.49-13.2,0.66C410.16,180.01,431.76,177.51,426.4,181.17z"/>
    <path fill="#A89C94" d="M420.87,202.61c-4.68-0.84-12.67-0.5-19.27,1.16C398.04,204.66,433.9,204.94,420.87,202.61z"/>
    <path fill="#A89C94" d="M471.73,178.35c-2.59,2.58-7.32,3.32-8.92,1C461.2,177.02,476.9,173.19,471.73,178.35z"/>
    <path fill="#A89C94" d="M443.35,177.35c-0.51,3.85-2.32,14.62-2.5,17.45c-0.18,2.82-3.03-2.33-1.96-10.8
      C439.49,179.27,444.25,170.7,443.35,177.35z"/>
    <path fill="#A89C94" d="M414.62,200.45c-3.58-0.15-9.96-0.39-11.06-0.16c-1.61,0.33,2.5-1.83,5.89-1.99
      C412.17,198.15,418.55,200.61,414.62,200.45z"/>
    <path fill="#B48362" d="M408.56,211.08c-8.21-2-14.28,0-16.42,2.66C390,216.4,413.37,212.25,408.56,211.08z"/>
    <path fill="#B48362" d="M488.33,208.59c-5.1-1.36-14.27-2.33-18.74-0.33C465.13,210.25,494.57,210.25,488.33,208.59z"/>
  </g>
  <g id="item_13_">
    <path d="M347.23,198.58c0.17-0.5,0.35-0.99,0.55-1.48c-0.02-0.03-0.04-0.06-0.06-0.09c0.56-1.51,1.12-3.01,1.78-4.48
      c0.37-0.79,0.79-1.57,1.35-2.27c0.28-0.33,0.59-0.63,0.94-0.89c0.33-0.23,0.69-0.42,1.07-0.58c0.54-0.2,1.1-0.33,1.67-0.41
      c1.46-0.17,2.93-0.06,4.38,0.12c2.49,0.32,4.91,0.89,7.33,1.58c1.75,0.5,3.51,0.87,5.32,0.37c2.34-0.65,4.76-2.03,6.54-3.6
      c0.92-0.82,1.43-1.81,2.17-2.73c0.1-0.12,0.22-0.23,0.35-0.33c0.16-0.11,0.34-0.2,0.52-0.27c0.64-0.23,1.31-0.35,1.99-0.43
      c2.01-0.24,4.02-0.3,6.03-0.06c3.87,0.53,7.63,1.72,11.45,2.47c3.02,0.6,6.09,1.08,9.18,1.23c0.58,0.03,1.15,0.04,1.73,0.05
      c0.65,1.32,1.46,2.58,2.48,3.65c0.52,0.55,1.13,1.1,1.85,1.45c0.18,1.36,0.27,2.74,0.09,4.11c-0.09,0.55-0.25,1.12-0.65,2.05
      c-1.11-0.3-3-0.67-3.38-0.76c-0.38-0.09-0.59,0.55-0.18,0.62c0.27,0.04,0.53,0.09,0.79,0.15c-0.52,0.21-0.95,0.58-1.07,1.1
      c-0.14,0.66,0.51,1.18,1.17,1.4c-0.69,0.79-0.58,2.11,0.77,2.51c0.08,0.02,0.15,0.03,0.24,0.04c-0.37,0.98-0.02,2.2,1.44,2.07
      c1.15-0.11,1.45-0.88,1.23-1.57c0.2,0.08,0.42,0.11,0.66,0.1c1.33-0.09,1.52-1.04,1.1-1.86c0.19,0.04,0.41,0.05,0.65,0
      c1.12-0.21,1.4-1.3,0.85-1.91c0.06,0.02,0.33,0.1,0.39,0.11c0.42,0.12,0.69-0.47,0.27-0.59c-1.34-0.37-2.95-0.87-4.28-1.25
      c0.97-1.81,0.97-3.99,0.67-6.06c0.79,0.17,1.3-0.07,1.73-0.54c2.76,4.57,5.26,8.84,8.85,12.84c-3.92,3.65-4.76,10.03-5.03,14.2
      c-0.34-0.02-0.69-0.03-1.03-0.03c-1.76,0-3.52,0.27-5.14,0.97c-2.21,0.95-3.98,2.71-5.26,4.6c-2.64,3.92-3.32,8.71-3.23,13.26
      c0.18,9.49,6.5,32.75,9.89,39.79c-2.84,1.97-6.06,5.91-7.17,9.19c-1.68,4.97,0.75,9.49,5,12.54c4.39,3.15,9.92,4.89,15.38,5.88
      c1.23,15.35,2.83,45.46,3.4,57.2c0.01,0.21,1.51,1.04,2.02,1.07c0.19,5.86,0.19,12.46,0.77,18.03
      c-5.37,3.7-17.49,13.07-22.09,17.74c-1.33,1.36-1.61,2.54-0.28,2.93c5.17,1.52,12.7,2.27,27.07,1.75
      c14.37-0.52,26.73-4.03,30.28-6.37c0.3-0.2,1.12-0.78-0.14-1.5c-1.25-0.71-14.85-7.7-23.02-11.56c-0.07-0.58-0.33-1.57-0.39-2.01
      c3.85-2.64,8.26-4.81,11.4-8.21c3.36-3.64,4.71-8.37,7.81-12.17c1.73-2.05,3.86-3.71,6.06-5.3c7.33-5.26,15.09-9.94,22.86-14.6
      c0.41,0.8,0.8,1.61,1.18,2.42c0.48,1.07,0.94,2.15,1.41,3.23c1.33,3.06,2.74,6.14,4.81,8.86c2.16,2.84,4.58,5.57,6.93,8.27
      c2.28,2.64,4.74,5.07,7.44,7.35c2.38,2.01,4.81,3.98,6.81,6.35c1.12,1.37,2.06,2.83,2.79,4.41c0.71,1.61,1.21,3.28,1.47,5
      c0.27,2.01,0.19,4.02-0.08,6.02c-0.53,3.5-1.67,7.75-2.1,10.07c-0.44,2.32-0.21,3.73-0.01,4.77c-0.43,0.07-0.38,0.84-0.31,1.28
      c0.57,4.09,4.41,5.94,8.32,6.8c4.02,0.87,8.2,0.91,12.31,0.81c0.41-0.01,0.83-0.41,0.66-0.81c-0.98-2.33-3.96-5.44-4.35-5.99
      c-0.38-0.55-1.1-0.85-1.81-0.96c-2.12-1.9-3.55-6.63-3.71-12.1c-0.07-2.67-1.2-6.38-1.66-8.05c-1.9-6.42-5.31-14.59-9.2-21.71
      c-6.1-11.15-13.8-21.69-21.41-31.97c1.36-0.84,3.23-2.17,4.57-3.02c0.25-0.16,0.52-6.45-4.53-14.13
      c-5.05-7.67-13.15-13.81-21.18-18.81c1.16-0.77,2.93-2.14,3.77-3.02c-3.27-1.82-10.57-7.65-13.5-9.65
      c-2.99-2.04-9.73-6.04-13.08-7.02c-0.54-1.11-1.56-2.96-2.18-4.04c-3.4-6.03-7.47-11.79-11.35-17.57
      c-3.3-4.9-6.71-9.75-10.53-14.32c0.79-0.47,1.52-1.04,2.19-1.73c4.52-4.7,1.79-11.12-1.8-15.53c-2.24-2.78-5.44-5.23-8.69-6.71
      c-2.7-6.18,1.25-11.55,1.96-11.88c2.29,2.04,5.23,4.1,8.46,4c3.94-0.13,6.93-3.32,8.95-6.14c2.75-3.84,4.67-8.2,6.27-12.55
      c0.07-0.19,0.15-0.39,0.21-0.59c0.16,1.85,0.51,3.69,0.93,5.44c-0.87,0.11-2.01,0.27-2.87,0.41c-0.31,0.05-0.03,0.32,0.15,0.48
      c-1.22,1.52,0.28,2.76,1.43,2.74c-0.08,0.99,0.71,1.92,1.67,1.97c0.07,1.01,1.55,1.87,2.58,1.25c1.1-0.67,1.15-1.87,0.42-2.49
      c0.86-0.4,0.88-1.35,0.32-1.98c1.22-0.24,1.38-1.5,0.75-2.19c0.66-0.06,0.59-0.67,0.16-0.65c-0.43,0.03-2.63,0.22-3.94,0.37
      c-0.28-1.19-0.52-2.4-0.71-3.62c-0.17-1.17-0.26-2.35-0.27-3.53c0.8-2.35,1.51-4.74,2.08-7.15c4.05-0.57,8.01-1.75,12.12-2.29
      c1.89-0.23,3.78-0.28,5.68-0.06c1.24,0.16,2.46,0.42,3.63,0.86c0.62,0.26,1.21,0.56,1.76,0.94c0.4,0.3,0.76,0.64,1.09,1.01
      c0.34,0.43,0.61,0.88,0.84,1.35c0.35,0.81,0.55,1.65,0.69,2.51c0.59,4.33-0.34,9.15,2.74,12.84c1.44,1.73,3.65,3.11,6,3.4
      c2.23,0.27,4.23-0.71,6.01-1.89c3.4-2.26,3.9-4.59,5.28-5c2.33-0.69,4.88,1.16,10.51,1.11c2.76-0.03,6.06-1.22,5.21-1.41
      c-0.79-0.18-1.56-0.41-2.32-0.7c-1.06-0.43-2.05-0.96-2.97-1.61c-0.99-0.73-1.86-1.57-2.64-2.49c-1.36-1.66-2.39-3.52-3.27-5.44
      c-2.32-5.15-3.64-10.73-6.7-15.59c-2.99-4.75-7.95-8.72-13.08-11.39c-2.62-1.36-5.78-2.7-8.86-2.46c-2.56,0.19-4.61,1.48-6.91,2.4
      c-0.82,0.32-1.66,0.55-2.55,0.67c-0.55,0.06-1.11,0.08-1.66,0.02c-0.59-0.09-1.16-0.25-1.71-0.45c-1.12-0.45-2.13-1.08-3.1-1.77
      c-2.97-2.16-5.49-4.8-8.3-7.13c-2.6-2.15-5.76-4.49-9.4-4.6c-0.08,0-0.16,0-0.25,0c-3.76,0-6.67,2.49-8.84,5.19
      c-1.53-1.31-3.14-2.55-4.83-3.68c-4.15-2.76-9.1-5.03-14.26-5.03c-0.79,0-1.58,0.05-2.38,0.16c-3.09,0.43-6.01,1.76-8.37,3.65
      c-1.84,1.47-2.57,3.49-3.97,5.24c-0.35,0.4-0.74,0.76-1.17,1.09c-0.6,0.4-1.25,0.73-1.94,1.01c-1.07,0.4-2.18,0.7-3.33,0.86
      c-1.08,0.12-2.16,0.11-3.24-0.01c-1.86-0.25-3.63-0.82-5.36-1.5c-0.56-0.22-1.11-0.45-1.66-0.68c-2.95-1.25-5.88-2.37-9.02-3.17
      c-2.45-0.62-5.15-1.2-7.67-0.57c-2.56,0.63-4.79,2.53-5.96,4.72c-1.35,2.53-1.06,5.47-2.16,8.08c-0.27,0.58-0.61,1.12-1.01,1.63
      c-0.44,0.5-0.93,0.95-1.48,1.36c-1.04,0.71-2.19,1.25-3.37,1.72c-3.89,1.52-7.61,2.94-10.38,6.05
      C338.83,180.78,341.62,191.17,347.23,198.58z M442.19,361.26c0-0.55-0.38-13.53-0.43-18.09c-0.03-3.49-0.37-7.32-0.62-10.8
      c-0.31-4.2-1.69-18.77-2.42-26.54c1.93,0.12,3.88,0.15,5.82,0.08c1.29,0.97,2.58,1.94,3.9,2.87c2.92,2.05,6.23,4.53,9.81,5.72
      c0.58,0.3,1.16,0.6,1.73,0.91c9.27,4.98,18.05,11.22,27.38,16.1c-5.06,1.59-8.95,4.42-12.07,8.45c-3.55,4.59-6.29,10.6-9.83,15.21
      c-1.61,2.08-3.42,3.89-7.16,3.59c-0.88-0.32-5.23-1.12-7.21,0.44c-1.91,1.5-3.87,3.54-5.91,5.17c-1.31,1.05-2.54,1.91-4.14,2.62
      c0.12,0.87,0.77,1.95,1.39,2.41c0.3-0.28,5.05-3.63,6.47-4.85c0.75,2.33,0.48,4.68,0.13,7.26c-0.12,0.8-0.3,1.59-0.62,2.35
      c-0.2,0.43-0.45,0.82-0.74,1.2c-0.42,0.49-0.9,0.91-1.42,1.3c-1.17,0.82-2.94,1.77-4.48,2.86c-0.52-5.59-0.98-11.34-1.74-16.74
      C440.72,362.6,442.19,361.9,442.19,361.26z M433.09,257.71c-1.12-1.36-2.47-3.13-3.22-5.01c-0.49-1.21-1.15-3.7-0.98-6.11
      c1.18-0.05,2.55-0.23,3.69-0.42c3.88,4.94,7.4,10.11,11.07,15.18c3.57,4.95,7.89,9.65,10.83,14.98c-0.4-0.19-2.97-1.82-3.36-2.01
      C448.99,271.41,438.36,264,433.09,257.71z"/>
    <path fill="#E0669C" d="M457.35,199.08c-1.35,0-1.35,1.95,0,1.95C458.69,201.03,458.69,199.08,457.35,199.08z"/>
    <path fill="#E0669C" d="M460.6,199.71c0-0.48-0.43-0.87-0.94-0.87h-0.02c-0.52,0-0.95,0.4-0.95,0.87
      c0,0.48,0.43,0.88,0.95,0.88h0.02C460.17,200.59,460.6,200.19,460.6,199.71z"/>
    <path fill="#E0669C" d="M459.77,202.13c0.13-0.39-0.03-1-0.7-1.15c-0.67-0.14-1.11,0.25-1.19,0.71
      c-0.08,0.46,0.31,0.85,0.79,0.98C459.16,202.8,459.62,202.59,459.77,202.13z"/>
    <path fill="#E0669C" d="M459.13,204.9c0.66-0.47,0.53-1.36,0.14-1.6c-0.28,0.03-0.81,0.05-1.13-0.22
      c-0.06,0.25-0.56,0.83-0.97,0.89C457.25,204.94,458.47,205.38,459.13,204.9z"/>
    <path fill="#B5427A" d="M458.25,204.72c0.76,0,1.19-0.48,1.29-1.02c0.12,0.37,0.04,0.88-0.41,1.2
      c-0.58,0.41-1.58,0.14-1.88-0.59C457.47,204.55,457.8,204.72,458.25,204.72z"/>
    <path fill="#E0669C" d="M456.06,200.65c0-0.59-0.53-1.08-1.16-1.08c-0.64,0-1.16,0.49-1.16,1.08v0.02
      c0,0.59,0.53,1.08,1.16,1.08c0.32,0,0.46-0.05,0.75-0.26c0.29-0.2,0.41-0.6,0.41-0.83V200.65z"/>
    <path fill="#E0669C" d="M456.18,201.64c-0.83,0.46-0.67,1.43-0.22,1.79c0.46,0.35,1.07,0.07,1.43-0.35
      C457.87,202.5,457.01,201.18,456.18,201.64z"/>
    <path fill="#E0669C" d="M418.41,201.35c1.16,0,1.16-1.68,0-1.68C417.24,199.67,417.24,201.35,418.41,201.35z"/>
    <path fill="#E0669C" d="M416.51,203.22c0.37,0.1,0.89,0.05,1.14-0.5c0.26-0.55-0.12-1.36-0.91-1.38
      c-0.61-0.02-0.97,0.35-1.06,0.81C415.59,202.62,416.15,203.12,416.51,203.22z"/>
    <path fill="#E0669C" d="M414,203.77c0,0.24,0.16,0.86,0.95,0.88c0.71,0.02,0.98-0.63,0.82-1.11
      c-0.08-0.29-0.41-0.65-0.95-0.62c-0.53,0.03-0.82,0.63-0.82,0.84V203.77z"/>
    <path fill="#E0669C" d="M412.77,201.61c0,0.55,0.49,1.01,1.08,1.01c0.59,0,1.08-0.46,1.08-1.01v-0.01c0-0.55-0.49-1-1.08-1
      c-0.15,0-0.3,0.03-0.42,0.09c-0.01,0-0.02,0-0.03,0c-0.06,0.03-0.11,0.06-0.16,0.09c-0.01,0.02-0.03,0.03-0.04,0.04
      c-0.26,0.19-0.43,0.47-0.43,0.79V201.61z"/>
    <path fill="#E0669C" d="M413.09,198.25c-0.66,0-1.06,0.42-1.07,1.05c-0.02,0.63,0.61,0.8,1.07,0.8
      C414.36,200.1,414.36,198.25,413.09,198.25z"/>
    <path fill="#E0669C" d="M414.53,199.83c0.01,0.5,0.44,0.97,1.1,1c0.55,0.02,1.04-0.22,1.26-0.83
      c0.24-0.69-0.41-1.31-1.22-1.35C414.84,198.6,414.52,199.44,414.53,199.83z"/>
    <path fill="#B5427A" d="M456.44,199.6c0,0.03-0.01,0.06-0.01,0.09v0.02c0,0.43,0.38,0.79,0.84,0.79
      c0.46,0,0.84-0.36,0.84-0.79v-0.02c0-0.15-0.05-0.29-0.13-0.41c0.65,0.5,0.44,1.75-0.64,1.75
      C456.45,201.03,456.15,200.18,456.44,199.6z"/>
    <path fill="#B5427A" d="M458.79,199.35c0.04,0.36,0.3,0.69,0.81,0.69c0.58,0,0.85-0.45,0.81-0.86
      c0.12,0.15,0.2,0.33,0.2,0.53c0,0.48-0.43,0.88-0.94,0.88h-0.02c-0.52,0-0.95-0.4-0.95-0.88
      C458.7,199.58,458.73,199.46,458.79,199.35z"/>
    <path fill="#B5427A" d="M457.88,201.67c0.18,0.25,0.49,0.42,0.83,0.42h0.05c0.43,0,0.8-0.26,0.95-0.61
      c0.1,0.22,0.11,0.47,0.05,0.66c-0.15,0.46-0.61,0.67-1.1,0.54c-0.48-0.13-0.87-0.53-0.79-0.98
      C457.88,201.68,457.88,201.67,457.88,201.67z"/>
    <path fill="#B5427A" d="M458.33,203.65c-0.22,0.38-0.79,0.57-1.14,0.41c-0.01-0.04-0.02-0.07-0.02-0.1
      c0.42-0.06,0.91-0.63,0.97-0.89c0.31,0.28,0.85,0.26,1.13,0.22c0.03,0.01,0.04,0.03,0.07,0.05
      C459.05,203.69,458.65,203.79,458.33,203.65z"/>
    <path fill="#B5427A" d="M453.77,200.38c0.08,0.4,0.4,0.75,0.96,0.75c0.82,0,1.13-0.73,0.93-1.29
      c0.24,0.2,0.39,0.49,0.39,0.8v0.02c0,0.22-0.12,0.63-0.41,0.83c-0.3,0.2-0.43,0.26-0.75,0.26c-0.64,0-1.16-0.5-1.16-1.08v-0.02
      C453.73,200.56,453.74,200.47,453.77,200.38z"/>
    <path fill="#B5427A" d="M456.33,202.97h0.03c0.52,0,0.96-0.38,1.01-0.86c0.19,0.33,0.23,0.72,0.02,0.97
      c-0.36,0.43-0.97,0.7-1.43,0.35c-0.21-0.16-0.35-0.45-0.37-0.76C455.78,202.85,456.04,202.97,456.33,202.97z"/>
    <path fill="#B5427A" d="M418.54,200.72c0.29,0,0.54-0.11,0.73-0.29c0.04,0.45-0.24,0.93-0.86,0.93
      c-0.76,0-1.02-0.71-0.79-1.2C417.78,200.48,418.13,200.72,418.54,200.72z"/>
    <path fill="#B5427A" d="M415.68,202.16c0.03-0.16,0.09-0.3,0.18-0.42c0,0.07,0,0.14,0.03,0.2c0.16,0.53,0.71,0.75,1.26,0.66
      c0.01,0,0.01,0,0.02,0c0.23-0.04,0.43-0.19,0.57-0.37c0.02,0.17,0,0.34-0.07,0.49c-0.26,0.54-0.77,0.6-1.14,0.5
      C416.15,203.12,415.59,202.62,415.68,202.16z"/>
    <path fill="#B5427A" d="M414.95,204.06c0.4,0,0.67-0.18,0.83-0.42c0.1,0.47-0.17,1.03-0.83,1.01
      c-0.79-0.01-0.95-0.64-0.95-0.88v-0.02c0-0.05,0.02-0.14,0.06-0.23C414.2,203.83,414.49,204.06,414.95,204.06z"/>
    <path fill="#B5427A" d="M412.85,201.23c0.04,0.44,0.36,0.85,0.98,0.85c0.65,0,0.97-0.46,0.99-0.93
      c0.07,0.13,0.11,0.28,0.11,0.43v0.01c0,0.55-0.49,1.01-1.08,1.01c-0.59,0-1.08-0.46-1.08-1.01v-0.01
      C412.77,201.46,412.8,201.35,412.85,201.23z"/>
    <path fill="#B5427A" d="M413.01,199.58c0.47,0,0.87-0.31,0.98-0.72c0.18,0.53-0.12,1.24-0.9,1.24
      c-0.46,0-1.09-0.16-1.07-0.8c0-0.11,0.02-0.22,0.05-0.32C412.22,199.33,412.59,199.58,413.01,199.58z"/>
    <path fill="#B5427A" d="M414.74,199.15c-0.01,0.03-0.01,0.06-0.01,0.09c0,0.52,0.46,0.95,1.02,0.95h0.02
      c0.55,0,1-0.42,1.02-0.92c0.14,0.21,0.18,0.47,0.09,0.74c-0.21,0.61-0.71,0.85-1.26,0.83c-0.66-0.03-1.09-0.5-1.1-1
      C414.52,199.66,414.59,199.39,414.74,199.15z"/>
    <path fill="#FF7A8F" d="M461.34,281.39c-2.78-6.1-6.53-11.67-10.24-17.3c-3.99-6.04-11.57-16.7-14.04-19.34
      c-0.98,0.47-2.22,0.93-3.32,1.18c3.52,4.5,6.75,9.18,10.07,13.81c2.14,2.99,4.36,5.93,6.63,8.84c2.14,2.76,3.9,5.48,5.44,8.58
      c1.04,2.1,2.51,6.83,2.99,10.28c0.42,0.4,1.11,1,1.56,1.38c1.15,0.02,2.79,0.15,3.78,0.59
      C463.53,286.82,462.46,283.86,461.34,281.39z"/>
    <path fill="#FF7A8F" d="M449.66,273.82c-3.79-3.67-10.52-9.12-14.59-12.55c3.51,4.4,9.13,10.4,12.73,14.26
      C448.44,274.97,449.05,274.4,449.66,273.82z"/>
    <path fill="#73CCCF" d="M448.71,276.49c0.56,0.59,6.46,7.21,9.01,9.8c-0.38-2.38-1.59-5.92-2.45-8.33
      c-1.67-1.07-3.02-2.08-4.89-3C450.02,275.28,449.07,276.17,448.71,276.49z"/>
    <path fill="#FFD6C2" d="M464.01,291.78c-0.33,0.3-0.65,0.6-0.97,0.9c1.7,1.62,3.31,3.33,4.76,5.15
      c2.04,2.57,5.79,7.66,6.23,8.15c0.48-0.79-1.11-6.72-2.87-8.68c-1.75-1.97-4.82-5.29-6.18-6.29c-1.36-1-2.51-1.45-3.91-1.41
      C461.23,289.72,463.84,291.63,464.01,291.78z"/>
    <path fill="#E5BAA8" d="M463.16,289.97c1.76,2.22,3.55,4.41,5.3,6.64c0.21,0.26,0.41,0.53,0.62,0.79
      c1.36,1.91,2.66,3.86,3.89,5.84c0.13,0.2,0.35,0.23,0.53,0.16c0.15,0.25,0.31,0.49,0.45,0.75c0.18,0.91,0.22,1.61,0.08,1.84
      c-0.44-0.49-4.18-5.58-6.23-8.15c-1.45-1.82-3.06-3.53-4.76-5.15c0.32-0.3,0.65-0.6,0.97-0.9c-0.17-0.15-2.78-2.06-2.94-2.18
      C461.81,289.58,462.49,289.7,463.16,289.97z"/>
    <path fill="#73CCCF" d="M458.18,295.92c-4.83-5.1-9.8-10.09-14.66-15.18c-4.77,3.51-10.59,6.35-16.5,7.29
      c0.85,1.53,3.37,4.82,7.03,8.09c4.38,3.92,9.46,7.51,14.12,10.86c3.17,2.28,6.49,4.63,10.19,6.13c0.06,0.01,0.12,0.03,0.18,0.06
      c0.01,0.01,0.02,0.01,0.03,0.02c0.7,0.26,1.43,0.49,2.18,0.6c0.38,0.04,0.75,0.03,1.14,0c0.74-0.11,1.45-0.32,2.14-0.58
      c1.64-0.65,3.14-1.56,4.59-2.53c-0.77-1.57-1.36-3.23-2.08-4.86c-1.68-3.68-4.01-7.04-6.44-10.31c-0.2,0.16-0.89,0.83-1.21,1.12
      C458.36,297.07,457.63,296.42,458.18,295.92z"/>
    <path fill="#FFD6C2" d="M482.61,315.03c0.01,0.01,0.03,0.02,0.04,0.03c-1.46-1.04-2.93-2.07-4.4-3.1
      c1.09,1.36,2.16,2.72,3.08,4.19c0.8,1.27,1.44,2.62,1.88,4.04c0.4,1.3,0.6,2.65,1.05,3.94c-0.29,0.23-3.41-0.9-3.81-1.27
      c-0.4-0.37-1.26-2.55-2.32-3.34c-2.56-1.82-4.73-2.85-6.93-5.06c-0.8-0.81-1.43-1.69-1.97-2.61c-2.14,1.48-4.64,2.95-7.22,3.19
      c9.16,5.05,17.73,11.08,27.07,15.84c3.2-0.73,6.54-0.87,9.83-0.89c-0.53-0.77-1.1-1.52-1.7-2.25c0,0,0,0,0,0l-0.01-0.01
      c-0.03-0.04-0.06-0.07-0.09-0.11c0.02,0.03,0.05,0.05,0.07,0.08C493.08,322.8,487.86,318.81,482.61,315.03z"/>
    <path fill="#E5BAA8" d="M478.89,322.28c-1.34-0.75-2.25-1.91-3.39-2.88c-2.39-2-5.04-3.7-7.53-5.58
      c-0.23-0.17-0.5-0.14-0.67,0.08c-0.89,1.18-2.24,1.75-3.72,2.01c-0.52-0.29-1.05-0.59-1.57-0.88c2.58-0.24,5.08-1.71,7.22-3.19
      c0.54,0.92,1.17,1.8,1.97,2.61c2.2,2.22,4.37,3.25,6.93,5.06c1.05,0.79,1.9,2.93,2.3,3.33
      C479.89,322.71,479.36,322.54,478.89,322.28z"/>
    <path fill="#E5BAA8" d="M493.39,328.59c1.17-1.8,0.16-3.61-1.08-5.13c-1.88-2.29-4.62-4.14-7.02-5.92
      c-1.89-1.4-3.82-2.75-5.81-4.02c-0.4-0.52-0.81-1.04-1.23-1.56c1.47,1.03,2.94,2.06,4.4,3.1c-0.02-0.01-0.03-0.02-0.04-0.03
      c5.24,3.78,10.46,7.77,14.57,12.66c-0.02-0.02-0.05-0.05-0.07-0.08c0.03,0.03,0.06,0.07,0.09,0.11l0.01,0.01c0,0,0,0,0,0
      c0.6,0.73,1.17,1.48,1.7,2.25c-2.72,0.01-5.48,0.12-8.16,0.57C491.81,330.07,492.81,329.48,493.39,328.59z"/>
    <path fill="#FFD6C2" d="M465.68,301.85c1.49,2.54,2.43,5.23,3.62,7.88c0.52,1.13,1.15,2.2,1.94,3.18
      c0.93,1.1,2.02,2.03,3.2,2.89c1.51,1.08,3.46,2.26,4.45,3.19c1,0.93,2.04,2.74,2.12,2.93c0.08,0.18,1.49,0.68,1.89,0.63
      c-0.27-1.06-0.67-2.95-1.11-3.96c-1.1-2.35-4.65-7.07-6.56-9.55c-1.92-2.49-3.77-5.03-4.83-6.6c-1.19-1.76-5.05-6.58-7.93-9.25
      c-0.47,0.45-1.15,1.09-1.61,1.55C462.57,297.05,464.23,299.39,465.68,301.85z"/>
    <path fill="#73CCCF" d="M487.01,297.09c-3.88-2.28-7.21-5.24-10.83-7.86c-4.34-3.09-9.08-5.55-13.91-7.86
      c1.14,2.59,2.08,5.25,2.77,7.97c0.73,0.25,0.68,0.88,0.25,1.03c1.08,0.85,6.55,6.07,7.39,7.32c1.28,1.9,2.55,5.62,2.51,7.97
      C476.6,304.52,484.58,298.79,487.01,297.09z"/>
    <path fill="#A8E3E5" d="M484.16,296.92c-1.51-0.92-2.85-2.04-4.24-3.11c-2.45-1.89-4.8-3.9-7.22-5.83
      c-2.04-1.63-4.14-3.21-6.36-4.61c3.41,1.74,6.73,3.63,9.84,5.85c3.61,2.62,6.95,5.58,10.83,7.86c-0.27,0.19-0.61,0.43-1,0.7
      C485.36,297.57,484.75,297.28,484.16,296.92z"/>
    <path fill="#2EAFB9" d="M453.57,276.83c0.55,0.37,1.11,0.74,1.7,1.12c0.86,2.41,2.08,5.95,2.45,8.33
      c-0.65-0.66-1.53-1.59-2.48-2.62C455.26,281.33,454.63,279,453.57,276.83z"/>
    <path fill="#2EAFB9" d="M449.04,276.85c-0.16-0.18-0.27-0.31-0.33-0.36c0.24-0.21,0.71-0.65,1.12-1.03
      C449.62,275.95,449.35,276.41,449.04,276.85z"/>
    <path fill="#2EAFB9" d="M457.88,298.36c-2.45-3.46-5.34-6.64-8.45-9.6c-2.46-2.35-5.14-4.5-7.68-6.77
      c0.6-0.4,1.2-0.81,1.77-1.24c4.85,5.09,9.82,10.08,14.66,15.18c-0.54,0.5,0.18,1.15,0.7,0.7c0.32-0.29,1.01-0.96,1.21-1.12
      c2.43,3.27,4.76,6.64,6.44,10.31c0.73,1.62,1.32,3.29,2.08,4.86c-1.42,0.96-2.92,1.86-4.53,2.51
      C463.25,307.93,461.11,302.91,457.88,298.36z"/>
    <path fill="#2EAFB9" d="M434.59,285.83c-0.58,2.34-0.03,4.7,1.4,6.88c2.08,3.17,4.97,5.97,7.71,8.64
      c3.06,2.98,6.26,5.83,9.54,8.59c0.67,0.57,1.37,1.16,2.09,1.72c-2.52-1.37-4.87-3.04-7.16-4.68c-4.65-3.35-9.73-6.94-14.12-10.86
      c-3.67-3.28-6.18-6.56-7.03-8.09C429.58,287.63,432.13,286.86,434.59,285.83z"/>
    <path fill="#2EAFB9" d="M474.93,300.33c0.93,1.22,1.68,2.5,2.24,3.84c-0.92,0.68-1.64,1.22-1.97,1.49
      c0.04-2.17-1.05-5.51-2.23-7.51C473.67,298.84,474.33,299.56,474.93,300.33z"/>
    <path fill="#FF5480" d="M529.26,412.91c0.79,0.77,2.42,1.39,3.25,1.72c1.75,0.67,3.61,1.05,5.48,1.29c0,0,0.01,0,0.01,0
      c0.01,0,0.02,0,0.03,0c-0.01,0-0.01,0-0.02,0c0.01,0,0.01,0,0.02,0c2.73,0.33,5.48,0.38,8.23,0.33c-0.98-1.79-2.84-4.81-3.75-5.36
      c-0.42-0.25-1.85-0.11-4.34-0.18c-2.48-0.07-7.61-0.5-10.22-0.88C527.92,410.88,528.47,412.13,529.26,412.91z"/>
    <path fill="#FFA1AB" d="M538.06,410.7c0.04,0,0.08,0.01,0.12,0.01c2.48,0.07,3.92-0.07,4.34,0.18
      c0.52,0.32,1.35,1.44,2.15,2.68C542.8,411.93,540.57,411.19,538.06,410.7z"/>
    <path fill="#FFD6C2" d="M501.04,348.98c1.22,2.66,2.62,5.23,4.5,7.54l0,0c0,0.01,0.01,0.01,0.01,0.01
      c2.28,2.75,4.56,5.51,6.93,8.19c2.28,2.58,4.81,4.88,7.47,7.11c2.38,2,4.56,4.05,6.44,6.48c2.11,2.71,3.46,5.91,3.94,9.22
      c0.84,5.75-1.74,13.85-2.21,16.6c-0.27,1.57-0.4,3.23,0.26,4.7c2.62,0.63,9.14,0.98,12.2,1.07c-3.32-2.93-2.96-9.48-3.71-14.43
      c-0.75-4.96-3.62-12.81-6.65-19.5c-5.48-12-13.01-23.09-20.88-33.83c-1.39-1.9-2.74-3.79-4.14-5.69c-1.68,1.03-3.53,2.19-5.23,3.2
      c1.01,1.72,3.37,2.97,5.32,3.48c0.85,0.22-3.65,0.91-6.45-2.81c-0.46,0.28-0.92,0.56-1.38,0.83
      C498.78,343.71,499.86,346.37,501.04,348.98z"/>
    <path fill="#FFE9DE" d="M535.32,391.17c-1.58-6.31-4.91-12.31-7.81-18.17c-3.13-6.33-6.64-12.48-10.34-18.54
      c-1.39-2.27-2.66-4.61-3.95-6.93c6.43,9.06,12.43,18.44,16.99,28.44c3.03,6.69,5.9,14.54,6.65,19.5c0.25,1.61,0.38,3.39,0.55,5.16
      C536.47,397.62,536.12,394.39,535.32,391.17z"/>
    <path fill="#E5BAA8" d="M530.33,387.53c-0.13-0.92-0.34-1.83-0.61-2.73c1.65,3.12,2.43,6.55,1.72,10.37
      c-0.81,4.32-2.82,9.69-1.46,13.95c-0.61-0.09-1.16-0.19-1.59-0.29c-0.66-1.46-0.54-3.12-0.26-4.7
      C528.59,401.38,531.17,393.28,530.33,387.53z"/>
    <path fill="#E5BAA8" d="M499.93,346.49c-0.78-1.8-1.57-3.59-2.46-5.33c0.46-0.28,0.92-0.55,1.38-0.83
      c0.92,1.22,2.03,1.97,3.06,2.42C501.05,343.96,500.37,345.25,499.93,346.49z"/>
    <path fill="#E5BAA8" d="M502.5,341.97c-1.05-0.61-1.99-1.39-2.52-2.32c1.69-1.02,3.54-2.17,5.23-3.2
      c0.47,0.63,0.92,1.26,1.38,1.89C505.03,339.26,503.64,340.55,502.5,341.97z"/>
    <path fill="#FFD6C2" d="M444.6,381.27c0.7-0.59,6.35-4.03,7.74-6.91c0.78-1.62,0.57-3.52,0.58-5.29
      c0.01-1.87-0.32-3.74-0.09-5.61c0.07-0.39,0.16-0.77,0.3-1.14c0.11-0.21,0.22-0.4,0.37-0.59c0.22-0.24,0.45-0.46,0.71-0.66
      c0.29-0.19,0.6-0.36,0.92-0.49c0.65-0.24,1.33-0.36,2.02-0.46c2.06-0.24,4.14-0.31,5.97-1.33c0.08-0.05,0.15-0.1,0.2-0.17
      c0.27-0.16,0.48-0.33,0.7-0.52c5.56-4.84,8.48-13.29,12.39-17.97c3.91-4.68,8.58-6.89,11.65-7.92c3.07-1.04,6.28-1.36,11.24-1.62
      c0.91,0.78,2.68,1.93,2.23,1.06c-1.46-2.81-6.55-8.53-12.14-13.14c-4.16-3.43-8.71-6.42-13.13-9.54c-0.49-0.62-0.84-1.22-1.24-1.74
      c2.74-2.2,6.86-5.46,8.8-6.67c2.26,1.41,4.48,2.86,6.63,4.41c4.54,3.29,8.85,6.93,12.4,11.17c1.84,2.23,3.44,4.63,4.65,7.21
      c0.84,1.87,1.45,3.82,1.76,5.84c0.14,1.04,0.19,2.09,0.16,3.14c-7.59,4.82-15.41,9.31-23.06,14.05c-3.27,2.02-6.52,4.1-9.7,6.26
      c-2.85,1.94-5.81,3.88-8.31,6.22c-1.86,1.74-3.34,3.74-4.57,5.91c-1.44,2.55-2.6,5.22-4.5,7.53c-1.18,1.39-2.55,2.6-4.05,3.68
      c-2.64,1.88-5.24,3.44-7.89,5.3c-0.23,0.16,0.63,4.42-0.33,3.93C446.03,384.73,443.91,381.86,444.6,381.27z"/>
    <path fill="#FFE9DE" d="M501.64,316.41c-3.34-3.89-10.92-10.43-14.53-13.73c1.13,0.75,2.24,1.51,3.34,2.29
      c4.54,3.29,8.85,6.93,12.4,11.17c1.84,2.23,3.44,4.63,4.65,7.21c0.72,1.6,1.27,3.26,1.6,4.96
      C506.3,322.51,504.15,319.34,501.64,316.41z"/>
    <path fill="#FFE9DE" d="M488.07,332.22c1.64-0.55,3.32-0.9,5.28-1.15c-8.73,2.82-14.71,7.3-21,15.37
      c1.34-2.36,2.64-4.58,4.07-6.3C480.32,335.47,485,333.26,488.07,332.22z"/>
    <path fill="#E5BAA8" d="M484.2,301.54c-1.58,1.09-3.26,2.18-4.41,3.7c-1.37,1.82-1.59,3.64-1.13,5.41
      c-0.8-0.56-1.6-1.12-2.4-1.68c-0.49-0.62-0.84-1.22-1.24-1.74c2.74-2.2,6.86-5.46,8.8-6.67c0.32,0.19,0.63,0.39,0.94,0.59
      C484.57,301.28,484.39,301.41,484.2,301.54z"/>
    <path fill="#E5BAA8" d="M455.97,362.52c0.41,0.04,0.8,0.14,1.18,0.27c-0.23,0.05-0.45,0.11-0.67,0.23
      c-0.83,0.46-1.34,1.22-1.78,2C453.94,363.99,454.22,362.35,455.97,362.52z"/>
    <path fill="#E5BAA8" d="M447.22,381.48c0.02-0.03,0.04-0.05,0.07-0.07c-0.04,0.58,0.33,2.81,0.09,3.58
      C446.6,383.9,446.24,382.68,447.22,381.48z"/>
    <path fill="#E5BAA8" d="M452.48,377.42c2.87-1.92,5.39-4.23,7.54-6.83c0.79-0.97,1.53-1.98,2.21-3
      c-0.87,1.63-1.77,3.24-2.97,4.7c-1.18,1.39-2.55,2.6-4.05,3.68c-2.56,1.83-5.1,3.36-7.67,5.15
      C448.91,379.61,450.77,378.57,452.48,377.42z"/>
    <path fill="#E5BAA8" d="M472.29,353.38c5.08-4.6,11.2-7.92,17.24-11.28c6.58-3.66,12.89-7.66,19.88-10.6
      c0.01,0.27,0,0.55-0.01,0.83c-7.59,4.82-15.41,9.31-23.06,14.05c-3.27,2.02-6.52,4.1-9.7,6.26c-2.85,1.94-5.81,3.88-8.31,6.22
      c-0.78,0.73-1.49,1.5-2.14,2.31C467.9,358.37,469.75,355.68,472.29,353.38z"/>
    <path fill="#FF5480" d="M448.34,385.65c-0.11,0.95-0.39,1.34-1.16,0.95c-0.77-0.39-5.65-4.61-2.86-6.17
      c1.29-0.72,6.91-4.16,7.68-7.73c0.67-3.12-0.5-6.1,0-9.23c0.44-2.74,2.02-3.38,4.81-4.16c-2.16-0.78-4.57-0.7-6.52,1.12
      c-2.97,2.77-5.61,5.36-8.56,6.72c0.12,0.56,0.5,1.15,0.86,1.38c0.92-0.36,6.93-5.18,6.93-5.18c0.7,3.72,1.09,6.44,0.05,10.14
      c-0.96,3.41-4.52,4.65-7.28,6.58c-0.85,0.62-3.07,2.37-2.72,4c0.35,1.62,10.61,8.67,11.41,8.42
      C451.79,392.26,449.69,388.36,448.34,385.65z"/>
    <path fill="#FFA1AB" d="M449.45,389.22c-0.42-0.67-0.95-1.36-0.92-2.18c0.02-0.3,0.04-0.59,0.06-0.89
      c0.77,1.52,1.68,3.3,2.18,4.59C450.29,390.29,449.83,389.81,449.45,389.22z"/>
    <path fill="#FFA1AB" d="M450.13,360.58c0.05-0.05,0.1-0.09,0.15-0.14c1.95-1.82,4.36-1.9,6.52-1.12
      c-0.47,0.13-0.9,0.26-1.3,0.39C453.59,359.31,451.8,359.76,450.13,360.58z"/>
    <path fill="#BD2167" d="M545.48,414.9c0.29,0.48,0.56,0.95,0.78,1.35c-2.75,0.05-5.5,0-8.23-0.33c-0.01,0-0.01,0-0.02,0
      c0.01,0,0.01,0,0.02,0c-0.01,0-0.01,0-0.03,0c0,0-0.01,0-0.01,0c-1.87-0.24-3.73-0.62-5.48-1.29c-0.83-0.33-2.46-0.95-3.25-1.72
      c-0.62-0.61-1.08-1.5-1.24-2.37C532.14,414.66,539.55,415.56,545.48,414.9z"/>
    <path fill="#BD2167" d="M447.65,364.83c-1.84,1.45-4.48,3.47-5.07,3.7c-0.34-0.22-0.7-0.76-0.84-1.3
      C443.72,366.47,445.74,365.71,447.65,364.83z"/>
    <path fill="#BD2167" d="M449.57,373.49c0.76-2.69,0.75-4.87,0.42-7.3c0.34,1.07,0.66,2.14,0.71,3.28
      c0.1,2.26-0.14,5.19-1.92,6.93c-1.14,1.1-2.57,1.66-4.06,2.14C446.84,377.28,448.88,375.95,449.57,373.49z"/>
    <path fill="#BD2167" d="M441.74,384.99c1.77,1.73,3.52,3.49,5.5,5c1.06,0.81,2.2,1.56,3.64,1.5c0.05,0,0.1-0.02,0.14-0.03
      c0.17,0.58,0.19,0.97-0.04,1.04c-0.8,0.24-11.06-6.8-11.41-8.42c-0.09-0.4-0.02-0.8,0.15-1.2
      C440.33,383.62,441.05,384.3,441.74,384.99z"/>
    <path fill="#C4C9CC" d="M415.58,400.96c3.25,0.95,12.22,2.19,28.19,1.17c17.23-1.1,28.01-5.43,27-5.91
      c-1.01-0.48-14.02-7.35-21.52-10.92c1.29,2.53,2.89,5.63,3.32,7.93c0.09,0.5-0.3,0.87-0.84,0.78c-2.93-0.46-9.56-5.2-12.03-7.65
      c-2.29-2.26-0.95-4.39,1.09-6.08c-0.43-4.36-0.78-12.69-1.61-17.23c-0.54,0.08-1.52,0.08-2.07,0.09
      c-0.01,6.35,0.95,21.19,0.95,21.9c0,0.71-1.25,0-1.39-2.92C430.92,386.34,412.22,399.99,415.58,400.96z"/>
    <path fill="#E3E6E6" d="M423.17,393.56c-2.61,2.32-5.33,4.49-7.97,6.75c0.62-2.95,16.3-14.39,21.45-18.19
      c0.01,0.13,0.02,0.25,0.02,0.37C432.48,386.49,427.51,389.7,423.17,393.56z"/>
    <path fill="#E3E6E6" d="M437.69,378.57c-0.19-3.6-0.42-8.07-0.53-11.69c0.53,1.97,1.1,3.9,1.17,6
      C438.39,374.77,438.36,376.78,437.69,378.57z"/>
    <path fill="#C4C9CC" d="M434.22,342.13c0.5,6.62,0.44,19.49,0.91,19.66c1.78,0.69,5.23,0.35,6.18-0.61
      c-0.53-6.88-0.69-16.42-1-23.17c-0.19-4.02-1.64-22.72-2.49-32.25c-1.54-0.11-3.06-0.28-4.58-0.49c-0.51-0.07-1.03-0.15-1.55-0.24
      C432.72,317.4,433.28,329.68,434.22,342.13z"/>
    <path fill="#A1A9AE" d="M437.13,365.93c-0.03-1.04-0.04-1.98-0.04-2.79c0.56-0.01,1.53-0.01,2.07-0.09
      c0.17,0.9,0.31,1.95,0.44,3.09C438.79,365.97,437.97,365.91,437.13,365.93z"/>
    <path fill="#A1A9AE" d="M443.46,400.85c6.58-0.5,21.12-2.16,26.03-5.28c0.67,0.35,1.11,0.57,1.27,0.65
      c1.01,0.48-9.77,4.81-27,5.91c-15.97,1.02-24.94-0.23-28.19-1.17c-0.62-0.17-0.49-0.79,0.17-1.69
      C423.64,400.8,435.01,401.49,443.46,400.85z"/>
    <path fill="#A1A9AE" d="M447.94,392.42c-3.44-0.14-7.1-1.05-9.68-2.85c-2.71-1.89-2.67-4.13-1.48-6.27
      c0.36,1.92,1.26,2.35,1.26,1.75c0-0.2-0.07-1.46-0.18-3.33c0.81-1.01,1.8-1.97,2.78-2.83c0.04,0.5,0.08,0.97,0.13,1.39
      c-2.04,1.69-3.38,3.83-1.09,6.08C441.35,388.02,444.9,390.71,447.94,392.42z"/>
    <path fill="#A1A9AE" d="M439.82,330.7c0.17,2.36,0.31,4.38,0.4,5.81c-1.84,2.62-3.8,5.17-5.87,7.65
      c-0.04-0.72-0.08-1.4-0.13-2.02c-0.08-1.12-0.17-2.23-0.24-3.34C436.06,336.18,438.02,333.48,439.82,330.7z"/>
    <path fill="#A1A9AE" d="M431.99,308.96c-0.1-1.31-0.2-2.62-0.31-3.93c0.52,0.09,1.04,0.17,1.55,0.24
      c1.51,0.22,3.04,0.38,4.58,0.49c0.08,0.91,0.16,1.9,0.26,2.95C436.1,308.37,434.05,308.42,431.99,308.96z"/>
    <path fill="#C40A26" d="M411.69,285.58c-0.5,0.92-0.74,1.94-0.9,2.97c-0.1,0.79-0.11,1.59-0.01,2.38
      c0.12,0.76,0.34,1.51,0.65,2.22c0.38,0.83,0.88,1.6,1.47,2.32c0.84,1.01,1.84,1.89,2.93,2.68c1.86,1.32,3.94,2.36,6.09,3.21
      c3.99,1.56,8.24,2.51,12.52,3.05c2.9,0.36,5.84,0.53,8.76,0.49c-1.61-1.23-3.23-2.46-4.88-3.66c-4.38-3.14-9.92-8.4-12.71-13.09
      c-2.63-1.89-6.07-7.01-7.61-9.8C415.43,280.22,413.63,282.01,411.69,285.58z"/>
    <path fill="#E17573" d="M410.79,288.55c0.13-0.81,0.3-1.61,0.62-2.37c0.02,3.49,0.94,6.82,3.96,9.67
      c4.37,4.13,9.81,6.16,15.44,7.98c-3.04-0.57-6.03-1.37-8.88-2.48c-2.15-0.85-4.23-1.89-6.09-3.21c-1.08-0.79-2.09-1.67-2.93-2.68
      c-0.59-0.72-1.09-1.49-1.47-2.32c-0.31-0.71-0.53-1.46-0.65-2.22C410.68,290.14,410.69,289.34,410.79,288.55z"/>
    <path fill="#9C0A26" d="M430.19,298.05c-3.65-3.1-7.61-5.93-10.8-9.45c-1.94-2.13-3.66-4.39-5.23-6.74
      c1.15-1.38,2.37-2.43,3.85-3.5c1.54,2.79,4.98,7.91,7.61,9.8c2.78,4.69,8.33,9.95,12.71,13.09c1.65,1.19,3.27,2.43,4.88,3.66
      c-1.16,0.02-2.33-0.01-3.49-0.05C436.25,302.98,433.14,300.55,430.19,298.05z"/>
    <path fill="#FF7A8F" d="M413.42,241.89c-2.87-5.09-4.1-10.28-3.35-14.31c-0.58,0.71-1.04,2.34-1.43,5.29
      c-0.33,2.56-0.17,7.25,0.35,10.91c1.07,7.24,3.98,17.25,5.22,21.62c2.48,8.75,6.28,16.44,11.73,21.63
      c8.14-1.17,14.83-5.83,16.67-7.24c-4.45-4.69-8.77-9.46-12.78-14.49c-1.49-1.87-2.94-3.76-4.46-5.61c0,0,0,0.01,0,0.01
      C421.42,254.92,416.32,247.01,413.42,241.89z"/>
    <path fill="#FF7A8F" d="M411.31,226.11c-0.69,2.31-0.6,5.96,0.44,9.36c1.04,3.4,4.48,9.01,6.82,12.78
      c2.34,3.76,6.85,9.85,7.79,10.98c2.04,2.47,3.95,5.01,5.98,7.49c0.19,0.23,0.38,0.46,0.57,0.7c-0.01-0.01-0.01-0.02-0.02-0.03
      c8.1,9.73,17.3,18.58,26.04,27.8c0.22-0.22,2.68-2.62,3.58-3.44c-3.77-2.88-6.86-6.37-9.84-9.7c-4.59-5.13-9.94-10.33-14.27-15.42
      c-9-10.59-15.44-19.37-22.06-31.4c-0.84-1.52-0.92-3.75-0.56-3.1c2.46,4.44,4.95,8.85,7.75,13.1c2.57,0.06,5.14,0.09,7.7-0.21
      c1.49-0.2,2.95-0.51,4.34-1.04c0.81-0.33,1.57-0.73,2.28-1.22c0.61-0.45,1.16-0.96,1.65-1.53c0.39-0.49,0.72-0.99,0.99-1.54
      c0.23-0.56,0.4-1.13,0.5-1.72c0.1-0.76,0.09-1.51-0.01-2.27c-0.18-1.13-0.54-2.22-1-3.28c-0.6-1.3-1.32-2.76-2.17-3.93
      c-2.47,2.8-7.38,3.2-10.57,2.84c-5.88-0.66-10.43-4.58-12.72-9.7C413.69,222.15,412,223.8,411.31,226.11z"/>
    <path fill="#FFA6B0" d="M459.07,278.24c-2.31-4.1-5.39-9.18-7.98-13.14c-3.16-4.83-5.86-8.62-8.79-13.64
      c3.02,4.13,6.51,9.14,8.8,12.62c3.72,5.63,7.46,11.2,10.24,17.3c0.78,1.73,1.53,3.69,2.16,5.6
      C462.08,284.05,460.68,281.11,459.07,278.24z"/>
    <path fill="#FFA6B0" d="M462.16,292.05c-1.64-0.98-7.05-5.86-9.39-8.33c-4.66-4.9-12.18-13.11-16.52-18.26
      c-4.57-5.43-7.28-8.83-11.23-14.66c-1.72-2.54-4.09-6.28-5.68-8.89c-1.68-2.76-3.18-4.79-3.33-7.4c0.09,0.24,0.19,0.47,0.31,0.69
      c6.62,12.04,13.06,20.81,22.06,31.4c4.32,5.09,9.68,10.29,14.27,15.42c2.99,3.34,6.07,6.82,9.84,9.7
      C462.41,291.82,462.28,291.93,462.16,292.05z"/>
    <path fill="#FFA6B0" d="M427.6,232.42c-5.47-0.67-8.88-3.38-11.24-7.62c2.53,3.5,6.3,6,10.87,6.51
      c2.64,0.29,6.46,0.07,9.1-1.61C434.5,231.85,431.27,232.87,427.6,232.42z"/>
    <path fill="#FFA6B0" d="M440.48,239.66c-0.27,0.55-0.6,1.06-0.99,1.54c0,0,0,0.01-0.01,0.01c0.29-1.14,0.43-2.32,0.36-3.53
      c-0.15-2.98-1.04-5.84-1.85-8.71c0.03-0.04,0.05-0.08,0.08-0.11c0.73,1.09,1.36,2.37,1.9,3.53c0.46,1.06,0.82,2.15,1,3.28
      c0.09,0.76,0.1,1.51,0.01,2.27C440.89,238.54,440.72,239.1,440.48,239.66z"/>
    <path fill="#FF7A8F" d="M427.97,246.64c-1.12,0.02-2.49-0.08-3.58-0.11c1.37,2.56,3.7,5.96,5.13,7.92
      C428.48,251.91,427.83,248.71,427.97,246.64z"/>
    <path fill="#CC5C78" d="M434.78,247.27c-0.35-0.44-0.69-0.9-1.04-1.34c1.1-0.25,2.34-0.71,3.32-1.18
      c0.37,0.4,0.87,0.98,1.44,1.71C437.33,246.93,436.1,247.24,434.78,247.27z"/>
    <path fill="#CC5C78" d="M462.21,288.94c-0.62-0.07-1.26-0.11-1.78-0.12c-0.45-0.38-1.13-0.98-1.56-1.38
      c-0.1-0.72-0.25-1.51-0.42-2.3C459.71,286.4,460.96,287.67,462.21,288.94z"/>
    <path fill="#CC5C78" d="M441.66,268.23c1.65,1.57,3.47,2.97,5.11,4.56c0.66,0.64,1.27,1.29,1.94,1.92
      c-0.3,0.27-0.59,0.55-0.9,0.82c-3.61-3.86-9.22-9.86-12.73-14.26c0.44,0.37,0.92,0.77,1.42,1.19
      C438.15,264.44,439.77,266.43,441.66,268.23z"/>
    <path fill="#CC5C78" d="M420.86,258.8c-2.94-4.53-5.52-9.23-7.85-14.06c-2.42-4.99-4.15-10.6-3.49-16.11
      c0.17-0.46,0.35-0.81,0.55-1.05c-0.75,4.02,0.48,9.22,3.35,14.31c2.9,5.13,8,13.04,11.96,17.81c0,0,0,0,0-0.01
      c1.53,1.85,2.97,3.74,4.46,5.61c4.01,5.03,8.33,9.81,12.78,14.49c-0.46,0.35-1.22,0.9-2.22,1.55
      C432.92,274.63,426.22,267.06,420.86,258.8z"/>
    <path fill="#CC5C78" d="M435.56,243.96c-1.39,0.53-2.85,0.84-4.34,1.04c-2.56,0.3-5.13,0.27-7.7,0.21
      c-1.32-2-2.57-4.05-3.78-6.11c4.94,3.98,12.89,5.59,18.74,3.13c-0.2,0.18-0.41,0.35-0.63,0.51
      C437.13,243.23,436.37,243.63,435.56,243.96z"/>
    <path fill="#CC5C78" d="M427.62,251.73c-1.15-1.69-2.38-3.61-3.23-5.21c1.09,0.03,2.46,0.13,3.58,0.11
      c-0.01,0.12-0.02,0.25-0.02,0.38C426.94,248.51,427,250.18,427.62,251.73z"/>
    <path fill="#FFD6C2" d="M415.3,221.1c0.32,1.66,2.12,4.46,5.29,6.51c3.18,2.04,5.71,2.91,9.28,2.87
      c-1.47-2.73-4.93-4.49-5.9-5.15c-0.58-0.39,4.56,1.35,6,2.97c0.46-1.88,3.36-3.25,2.98-2.79c-0.53,0.66-2.42,2.54-1.68,4.88
      c2.5-0.25,5.22-1.54,6.07-2.76c-0.47-0.7-2.24-2.77-4.91-4.64c-2.67-1.88-6.71-3.23-9.67-3.5
      C419.8,219.21,416.75,219.48,415.3,221.1z"/>
    <path fill="#E5BAA8" d="M426.92,226.36c1.46,0.27,2.96,0.34,4.26,0.12c-0.55,0.49-1.05,1.11-1.22,1.81
      C429.37,227.62,428.13,226.92,426.92,226.36z"/>
    <path fill="#FFD6C2" d="M423.52,218.77c0.84,0.09,3.13,0.58,4.34,0.99c-0.91-3.03-0.54-9.21,2.9-11.98
      c-0.65-0.61-2.22-1.98-2.82-2.62C424.25,209.41,423.48,216.74,423.52,218.77z"/>
    <path fill="#E5BAA8" d="M429.32,209.39c-0.88-0.4-1.81-0.7-2.81-0.89c-0.22-0.04-0.43-0.07-0.64-0.09
      c0.56-1.17,1.24-2.29,2.07-3.25c0.61,0.65,2.18,2.02,2.82,2.62C430.2,208.23,429.73,208.79,429.32,209.39z"/>
    <path fill="#FFD6C2" d="M427.68,202.74c2.66,2.95,4.08,4.47,6.52,6.25c0.93,0.66,1.91,1.25,2.98,1.68
      c0.62,0.24,1.25,0.41,1.91,0.5c0.45,0.04,0.91,0.05,1.37,0c0.51-0.07,1.01-0.21,1.49-0.39c0.7-0.29,1.35-0.66,1.97-1.09
      c1.06-0.78,1.98-1.71,2.81-2.7c2.17-2.64,3.78-5.66,5.17-8.72c2-4.45,3.62-9.11,4.77-13.84c-1.28,0.13-3.23,0.23-4.54,0.17
      c-1.36,0.64-1.18,4.44-4.76,5.7c-1.55,0.54-3.58,0.48-5-0.3c-1.21-0.66-2.15-1.49-2.82-2.64c0.03,0.69,0.36,1.4,0.68,2.03
      c0.31,0.57,0.92,1.23,1.56,2.02c0.65,0.78,2.05,1.5,2.27,2.37c0.34,1.29-1.82,1.88-3.22,1.53c-0.63-0.16-0.8-0.54-0.63-0.53
      c0.82,0.02,2.07-0.05,2.85-0.52c0.42-0.25,0.19-0.74-0.12-1.01c-0.31-0.28-2.71-2.38-3.36-3.86c-0.79-1.84-1.11-3.94-0.93-4.98
      c0.19-1.05,0.61-1.75,0.59-1.43c-0.03,0.54-0.03,1.44-0.03,1.98c1.53-0.98,3.37-1.46,5.14-1.55c0.45-0.02,0.91-0.02,1.37-0.01
      c-1.03-0.25-2.04-0.53-3.04-0.82c-2.52-0.74-4.95-1.75-7.04-3.27c-0.96-0.7-1.79-1.53-2.5-2.43c-1.05,1.18-2.17,2.31-3.37,3.36
      c1.34,0.12,2.63,0.46,3.72,1.03c0.39,0.2,0.04,0.75-0.34,0.55c-0.21-0.11-0.41-0.2-0.62-0.29c-0.74-0.28-1.51-0.46-2.3-0.56
      c-0.41-0.05-0.82-0.07-1.23-0.08c-1.59,1.29-3.31,2.43-5.2,3.35c-2.21,1.07-4.56,1.76-6.98,2.15
      C420.08,191.89,425.03,199.78,427.68,202.74z"/>
    <path fill="#E5BAA8" d="M444.36,183.42c-0.96,0.05-1.95,0.22-2.89,0.52c-0.74-0.27-1.48-0.54-2.23-0.77
      c0-0.06,0.01-0.13,0.01-0.18c0.01-0.15-0.08-0.08-0.19,0.13c-1.52-0.46-3.08-0.77-4.69-0.76c-3.09,0.02-6.74,0.47-10.01,1.6
      c1.67-0.86,3.22-1.89,4.64-3.05c0.41,0.01,0.82,0.03,1.23,0.08c0.79,0.1,1.56,0.27,2.3,0.56c0.21,0.09,0.42,0.18,0.62,0.29
      c0.39,0.21,0.73-0.35,0.34-0.55c-1.09-0.57-2.37-0.91-3.72-1.03c1.2-1.04,2.32-2.17,3.37-3.36c0.71,0.9,1.54,1.73,2.5,2.43
      c2.09,1.52,4.52,2.53,7.04,3.27c1,0.29,2.01,0.57,3.04,0.82C445.27,183.4,444.82,183.4,444.36,183.42z"/>
    <path fill="#E5BAA8" d="M417.79,187.99c-0.34-0.55-0.65-1.08-0.95-1.6c1.54-0.25,3.05-0.62,4.51-1.13
      C419.99,186.01,418.77,186.91,417.79,187.99z"/>
    <path fill="#E5BAA8" d="M450.77,186.79c0.45-1.01,0.75-1.9,1.35-2.18c1.3,0.06,3.26-0.04,4.54-0.17
      c-0.26,1.09-0.56,2.17-0.87,3.24C454.11,187.39,452.43,187.12,450.77,186.79z"/>
    <path fill="#FFFFFF" d="M446,188.17c-2.81,1.47-5.52-0.32-5.67-2.69c-0.23,0.14-0.7,0.43-0.91,0.59
      c0.41,0.72,0.96,1.82,1.96,2.54c1.6,1.16,2.56,1.54,4.45,1.38c1.89-0.16,2.6-1.03,3.3-1.96c0.75-0.99,1.2-2.55,1.91-3.45
      c-1.31,0-2.62-0.13-3.82-0.25C447.83,185.58,447.41,187.44,446,188.17z"/>
    <path fill="#8FC2CC" d="M444.08,187.22c-1.64,0.08-2.39-1.15-2.25-2.4c-0.29,0.09-0.57,0.19-0.85,0.31
      c-0.25,1.35,0.82,2.67,2.25,2.97c0.96,0.2,1.7,0.04,2.22-0.22c0.63-0.31,1.13-0.73,1.36-1.36c0.33-0.92,0.21-1.68-0.22-2.15
      c-0.33-0.02-0.74,0.02-1.07,0.02C446.45,185.51,445.41,187.15,444.08,187.22z"/>
    <path fill="#FFD6C2" d="M415.71,191.04c-0.09-0.49-0.18-1.03-0.29-1.48c-0.11-0.45,0.63-0.6,0.66-0.17
      c0.03,0.43,0.29,1.35,0.41,2.06c0.69,0.21,1.33-0.07,1.47-0.45c-0.88-1.48-1.75-2.95-2.6-4.41c-1.01,0.11-2.04,0.17-3.06,0.19
      C413.48,189.01,414.85,190.53,415.71,191.04z"/>
    <path fill="#E5BAA8" d="M413.41,188.6c-0.37-0.53-0.75-1.14-1.11-1.82c1.03-0.02,2.05-0.08,3.06-0.19
      c0.23,0.39,0.46,0.78,0.69,1.17C415.11,187.93,414.21,188.2,413.41,188.6z"/>
    <path fill="#A66989" d="M344.91,192.19c0.55,1.21,1.19,2.37,1.91,3.49c0.03-0.09,0.06-0.18,0.1-0.27
      c0.59-1.65,1.19-3.33,2.1-4.86c1.79-2.99,4.95-3.69,8.42-3.46c2.16,0.15,4.32,0.52,6.42,1.03c1.68,0.4,3.32,1.04,5.03,1.29
      c0.48,0.05,0.96,0.06,1.44,0.01c0.64-0.09,1.25-0.27,1.85-0.5c1.47-0.6,2.87-1.41,4.15-2.31c0.6-0.44,1.15-0.92,1.64-1.47
      c0.7-0.88,1.11-1.95,2.15-2.54c2.03-1.15,5.31-1.07,7.56-0.95c3.49,0.17,6.87,1.24,10.25,2.01c2.72,0.62,5.47,1.15,8.24,1.51
      c3.17,0.38,6.38,0.52,9.55,0.14c2.04-0.27,4.02-0.76,5.92-1.49c1.79-0.72,3.46-1.65,5.02-2.74c-0.88,0.14-1.74,0.35-2.57,0.66
      c-0.61,0.24-1.17,0.53-1.71,0.9c-0.06,0.04-0.11,0.09-0.17,0.13c-0.34,0.26-0.82-0.19-0.48-0.45c1.58-1.21,3.79-1.92,6.03-2.06
      c1.95-1.54,6.96-6.8,7.57-7.71c0.6-0.91-0.64,2.57-1.31,3.37c0.78,1.26,1.78,1.94,2.64,2.57c1.19,0.84,2.5,1.5,3.86,2.06
      c3.36,1.3,7.13,2.18,10.73,2.66c2.13,0.25,4.26,0.2,6.38-0.05c6.18-0.81,12.15-3.26,18.49-2.44c4.48,0.58,8.08,2.64,8.96,6.97
      c0.55,2.71,0.28,5.49,0.65,8.23c0.15,1.02,0.4,2.02,0.82,2.97c0.29,0.63,0.66,1.23,1.1,1.79c0.46,0.54,0.99,1.02,1.57,1.45
      c0.62,0.43,1.27,0.8,1.97,1.09c0.5,0.18,1.01,0.32,1.55,0.41c0.48,0.05,0.95,0.05,1.43,0.01c0.51-0.08,1-0.21,1.48-0.38
      c0.66-0.27,2.37-0.88,4.18-2.46c1.82-1.57,1.44-3.67,4.32-4.14c2.77-0.45,6.88,2.51,12.02,1.05c-7.45-3.55-10.11-12.77-12.82-19.5
      c-0.26-0.65-0.53-1.29-0.82-1.93c-0.97-2.13-2.11-4.21-3.63-6.06c-1.89-2.24-4.19-4.2-6.6-5.94c-2.14-1.52-4.42-2.88-6.9-3.87
      c-1.25-0.49-2.55-0.88-3.9-1.07c-0.71-0.07-1.42-0.08-2.13-0.01c-0.79,0.11-1.54,0.32-2.28,0.6c-2.35,0.94-4.43,2.22-7.05,2.46
      c-1.65,0.15-3.21-0.31-4.64-1.02c-3.3-1.65-5.94-4.4-8.61-6.79c-1.34-1.2-2.69-2.37-4.15-3.44c-1.13-0.8-2.33-1.53-3.63-2.07
      c-0.71-0.27-1.44-0.48-2.2-0.58c-0.6-0.07-1.2-0.07-1.8-0.01c-0.59,0.09-1.15,0.24-1.7,0.44c-0.74,0.3-1.43,0.69-2.07,1.15
      c-1.09,0.81-1.94,1.53-3.17,3.15c1.91,1.72,5,6.99,4.44,6.36c-2.93-3.28-6.16-6.36-9.78-8.98c-2.31-1.65-4.79-3.12-7.48-4.19
      c-1.65-0.64-3.36-1.11-5.13-1.35c-1.34-0.16-2.69-0.17-4.04-0.02c-1.28,0.17-2.52,0.49-3.72,0.94c-1.31,0.53-2.51,1.21-3.64,2.01
      c-0.68,0.49-1.3,1.03-1.84,1.65c-1.04,1.3-1.66,2.84-2.77,4.1c-1.57,1.79-4.18,2.71-6.55,3.1c-5.32,0.89-10.04-1.67-14.76-3.55
      c-2.83-1.11-5.9-2.04-8.95-2.44c-0.92-0.11-1.85-0.14-2.78-0.04c-0.61,0.09-1.21,0.23-1.78,0.45c-0.54,0.22-1.02,0.49-1.49,0.82
      c-0.64,0.48-1.21,1.04-1.73,1.64c-0.45,0.56-0.86,1.13-1.16,1.76c-1.28,2.97-0.79,6.32-2.79,9.07c-2,2.77-5.54,3.78-8.69,4.99
      c-1.54,0.61-2.97,1.35-4.31,2.29c-1.1,0.81-2.1,1.72-2.97,2.74c-0.79,0.97-1.44,2.02-1.97,3.14c-0.54,1.23-0.9,2.5-1.1,3.82
      c-0.21,1.59-0.19,3.19,0.03,4.78C343.46,188.29,344.07,190.28,344.91,192.19z"/>
    <path fill="#C78AA6" d="M499.22,172.93c-3.61-5.83-10.33-10.96-17.6-11.84c-4.96-0.6-9.85,3.7-15.01,2.45
      c-4.3-1.05-7.51-4.26-10.43-7.2c-3.1-3.11-6.45-6.48-10.5-7.94c0.39-0.01,0.78,0,1.17,0.05c0.75,0.1,1.49,0.31,2.2,0.58
      c1.31,0.53,2.5,1.26,3.63,2.07c1.46,1.06,2.81,2.24,4.15,3.44c2.67,2.39,5.31,5.14,8.61,6.79c1.43,0.72,2.99,1.18,4.64,1.02
      c2.61-0.24,4.69-1.52,7.05-2.46c0.74-0.28,1.49-0.49,2.28-0.6c0.71-0.07,1.42-0.07,2.13,0.01c1.35,0.19,2.65,0.58,3.9,1.07
      c2.47,0.99,4.76,2.35,6.9,3.87c2.41,1.74,4.71,3.7,6.6,5.94c1.51,1.85,2.66,3.93,3.63,6.06c0.29,0.64,0.56,1.28,0.82,1.93
      c1.45,3.6,2.89,7.92,5.06,11.7C504.41,185.27,502.38,178.05,499.22,172.93z"/>
    <path fill="#C78AA6" d="M433.07,151.61c-3.32-2.1-7.01-4.36-11.01-5.06c-5-0.88-8.3,0.1-10.78,1.8
      c-2.5,1.7-4.18,4.13-5.96,6.13c-3.31,3.73-9.34,4.65-14.18,2.92c-6.73-2.4-12.31-4.57-17.33-5.23c-4.63-0.62-6.54,1.85-9.38,4.78
      c0.14-0.52,0.3-1.03,0.52-1.53c0.31-0.63,0.71-1.21,1.16-1.76c0.51-0.6,1.08-1.16,1.73-1.64c0.47-0.33,0.95-0.6,1.49-0.82
      c0.57-0.22,1.17-0.36,1.78-0.45c0.93-0.1,1.85-0.06,2.78,0.04c3.05,0.41,6.12,1.34,8.95,2.44c4.71,1.88,9.44,4.44,14.76,3.55
      c2.37-0.4,4.98-1.31,6.55-3.1c1.11-1.26,1.73-2.8,2.77-4.1c0.54-0.62,1.16-1.16,1.84-1.65c1.13-0.79,2.34-1.47,3.64-2.01
      c1.2-0.46,2.44-0.77,3.72-0.94c1.34-0.15,2.69-0.13,4.04,0.02c1.77,0.24,3.48,0.71,5.13,1.35c2.69,1.08,5.16,2.54,7.48,4.19
      c2.2,1.6,4.26,3.37,6.2,5.25C437.06,154.31,435.11,152.9,433.07,151.61z"/>
    <path fill="#C78AA6" d="M372.62,169.8c3.18-2.49,6.86-5.05,11.24-5.03c0.62,0,1.23,0.05,1.83,0.13
      c-3.91,0.68-7.5,2.73-10.92,5.23c-5.16,3.76-10.43,7.99-16.97,7.83C363.51,176.92,368.21,173.26,372.62,169.8z"/>
    <path fill="#C78AA6" d="M492.86,176.53c-2.02-4.46-4.42-7.89-8.15-8.83c9.62-0.86,11.47,15.95,20.68,22.45
      c-2.03-0.23-4.92-1.76-6.45-3.93C496.64,182.96,494.27,179.64,492.86,176.53z"/>
    <path fill="#C78AA6" d="M422.19,163.22c2.64-1.28,5.28-2.34,8.32-1.72c1.7,0.34,3.26,0.92,4.63,1.81
      c-3.53-0.51-7.11,0.1-10.68,0.88c-3.02,0.66-6.07,1.42-9.2,1.38c-0.62-0.01-1.24-0.03-1.85-0.06c0.76-0.06,1.53-0.14,2.29-0.24
      C418.04,164.95,420.1,164.23,422.19,163.22z"/>
    <path fill="#85456F" d="M442,177.9c4.24,1.95,8.94,2.84,13.66,2.84c5.82-0.01,11.32-1.63,17.08-2.01
      c5.49-0.36,10.3,1.01,12.59,5.89c1.51,3.23,2.15,6.73,3.61,9.97c1.12,2.47,2.92,4.46,5.89,4.85c3.35,0.44,4.89-2.8,7.03-4.49
      c2.77-2.19,5.77-0.79,8.39,0.91c1.23,0.79,2.53,1.58,3.91,2.16c-4.2,0.36-7.6-1.8-9.99-1.41c-2.88,0.47-2.5,2.57-4.32,4.14
      c-1.81,1.57-3.53,2.18-4.18,2.46c-0.48,0.17-0.97,0.3-1.48,0.38c-0.48,0.05-0.95,0.05-1.43-0.01c-0.53-0.08-1.05-0.22-1.55-0.41
      c-0.7-0.29-1.35-0.67-1.97-1.09c-0.58-0.43-1.1-0.91-1.57-1.45c-0.44-0.56-0.81-1.15-1.1-1.79c-0.41-0.95-0.66-1.96-0.82-2.97
      c-0.37-2.73-0.1-5.52-0.65-8.23c-0.88-4.33-4.48-6.39-8.96-6.97c-6.34-0.82-12.31,1.63-18.49,2.44c-2.12,0.26-4.26,0.3-6.38,0.05
      c-3.6-0.48-7.37-1.36-10.73-2.66c-1.36-0.55-2.67-1.21-3.86-2.06c-0.86-0.64-1.85-1.31-2.64-2.57c0.39-0.47,0.99-1.89,1.29-2.77
      C437.22,175.05,439.45,176.72,442,177.9z"/>
    <path fill="#85456F" d="M346.82,195.68c-0.06-0.09-0.11-0.18-0.17-0.27c0.22-1.75,0.67-3.44,1.47-5.02
      c2.24-4.43,6.77-6.42,11.86-5.1c3.64,0.94,7.78,2.83,11.6,1.51c4.47-1.55,7.1-6.21,12.15-6.4c4.7-0.18,8.99,1.06,13.32,2.58
      c1.04,0.37,2.13,0.76,3.25,1.17c-0.79-0.16-1.59-0.33-2.37-0.51c-3.38-0.76-6.76-1.83-10.25-2.01c-2.25-0.11-5.54-0.2-7.56,0.95
      c-1.05,0.59-1.46,1.66-2.15,2.54c-0.48,0.55-1.04,1.03-1.64,1.47c-1.28,0.9-2.68,1.71-4.15,2.31c-0.6,0.23-1.21,0.41-1.85,0.5
      c-0.49,0.05-0.96,0.04-1.44-0.01c-1.72-0.25-3.36-0.89-5.03-1.29c-2.09-0.5-4.26-0.88-6.42-1.03c-3.47-0.23-6.63,0.47-8.42,3.46
      c-0.91,1.52-1.5,3.21-2.1,4.86C346.89,195.5,346.86,195.59,346.82,195.68z"/>
    <path fill="#85456F" d="M357.67,169.58c-2.46,0.72-5.05,1.04-7.58,1.57c1.07-0.66,2.18-1.21,3.37-1.68
      c3.15-1.21,6.69-2.22,8.69-4.99c0.41-0.57,0.72-1.16,0.95-1.77C362.69,165.82,361.6,168.44,357.67,169.58z"/>
    <path fill="#85456F" d="M441.35,157.41c-0.95-1.49-2.26-3.34-3.26-4.23c1.23-1.62,2.09-2.34,3.17-3.15
      c0.06-0.04,0.12-0.08,0.17-0.12C440.34,152.02,440.45,154.86,441.35,157.41z"/>
    <path fill="#85456F" d="M408.88,173.9c4.34,0.99,9.11,2.23,13.55,1.01c1.99-0.55,3.72-1.44,5.28-2.57
      c-1.78,2.12-4.11,3.81-6.89,4.76c-4.97,1.7-10.4,1.17-15.54,0.45c-4.55-0.64-9.12-1.6-13.74-1.6c-2.02,0-3.99,0.18-5.91,0.54
      c2.32-1.16,4.76-2.11,7.33-2.71C398.39,172.49,403.45,172.67,408.88,173.9z"/>
    <path fill="#85456F" d="M354.65,179.56c2.18-0.52,4.42-0.49,6.66-0.5c4.62-0.02,8.08-1.16,11.53-4.06
      c4.5-3.78,8.69-7.3,15.07-7.86c1.51-0.14,3.01-0.15,4.5-0.09c-1.64,0.38-3.24,0.89-4.76,1.53c-2.9,1.21-5.26,2.79-7.5,4.89
      c-2.23,2.08-4.36,4.44-7.03,6.06c-2.81,1.69-5.72,2.13-9,1.64c-2.33-0.34-4.64-0.89-7-1.08c-1.85-0.15-3.5,0.02-4.91,0.52
      C352.91,180.15,353.72,179.79,354.65,179.56z"/>
    <path fill="#85456F" d="M409.84,156.7c-5.31,2.56-10.41,5.68-16.55,3.57c-2.47-0.85-4.77-1.97-7.21-2.69
      c0.43,0.02,0.87,0.06,1.32,0.14c2.31,0.39,4.56,1.05,6.87,1.4c5.05,0.74,9.19-1.09,13.32-3.58c5.05-3.07,9.99-5.55,16.18-3.79
      c5.38,1.53,10.24,4.79,13.65,8.97c-3.93-2.17-7.55-4.94-12.03-6.04C419.88,153.3,414.82,154.3,409.84,156.7z"/>
    <path fill="#85456F" d="M450.6,169.02c3.09,2.14,6.1,4.28,10.16,3.37c4.66-1.05,8.76-3.6,13.72-3.44
      c3.49,0.11,6.45,1.32,8.99,3.19c-2.48-1.45-5.3-2.31-8.41-2.2c-2.76,0.1-5.54,0.65-8.04,1.76c-2.24,1-4.34,2.51-6.72,3.21
      c-5.87,1.72-10.06-2.85-14.27-5.81c-1.76-1.23-3.77-2.55-6-2.84C443.61,165.6,447.51,166.87,450.6,169.02z"/>
    <path d="M417,200.84c0,0.01-0.01,0.01-0.01,0.02c0.01,0,0.01,0.01,0.02,0.01C417.01,200.86,417,200.85,417,200.84z"/>
    <path d="M414.25,202.51c0.01,0,0.01-0.01,0.01-0.01C414.26,202.51,414.25,202.51,414.25,202.51
      C414.25,202.51,414.25,202.51,414.25,202.51z"/>
    <path d="M420.57,187.71c0.68-1.2,1.19-2.74,3.45-3.98c1.17-0.64,2.4-0.83,3.74-0.72c1.43,0.13,3.06,0.63,4.33,1.22
      c1.34,0.62,3.16,1.74,4.17,2.47c0.56,0.39-0.44,0.56-1.11,0.35c-0.14,0.12-0.31,0.4-0.44,0.53c-0.81,0.93-1.35,2.02-2.19,2.93
      c-0.99,1.07-2.37,1.56-3.85,1.72c-3.44,0.36-6.03-1.28-7.43-4.06C420.75,188.58,420.35,188.1,420.57,187.71z"/>
    <path fill="#D4889E" d="M428.33,184.59c1.82,0.08,4.32,0.7,6.17,1.39c-0.62-0.37-1.42-0.83-2.06-1.14
      c-1.25-0.62-2.57-1.1-3.95-1.34c-1.34-0.24-3.01-0.12-4.2,0.49c-1.19,0.61-2.21,1.8-2.8,2.96
      C423.68,185.04,426.02,184.49,428.33,184.59z"/>
    <path fill="#CB6E8A" d="M424.89,185.01c-1.16,0.37-2.3,0.97-3.4,1.93c0.58-1.15,1.61-2.35,2.8-2.96
      c0.32-0.17,0.69-0.28,1.08-0.38C425.22,184.08,425.06,184.55,424.89,185.01z"/>
    <path fill="#CB6E8A" d="M432.27,184.75c0.06,0.03,0.12,0.05,0.17,0.08c0.64,0.31,1.43,0.77,2.06,1.14
      c-0.73-0.27-1.56-0.53-2.42-0.76C432.15,185.07,432.22,184.91,432.27,184.75z"/>
    <path fill="#8FC2CC" d="M426.95,189.66c1.54-0.19,2.16-0.82,2.59-1.76c0.43-0.94,0.11-1.9-0.2-2.29
      c-0.41-0.04-0.93-0.06-1.34-0.06c0.42,0.4,0.62,0.84,0.63,1.4c0.01,1.11-1.03,1.87-2.47,1.86c-1.21-0.01-2.32-1.05-2.17-2.54
      c-0.27,0.04-0.49,0.24-0.64,0.32c-0.22,0.75-0.03,1.22,0.46,1.97C424.29,189.31,425.42,189.85,426.95,189.66z"/>
    <path fill="#59A5B3" d="M441.88,185.68c-0.28,0.14-0.58,0.26-0.87,0.39c-0.08-0.31-0.09-0.63-0.04-0.95
      c0.28-0.12,0.57-0.22,0.85-0.31C441.8,185.11,441.82,185.41,441.88,185.68z"/>
    <path fill="#59A5B3" d="M441.91,187.46c-0.33-0.27-0.59-0.61-0.76-0.99c0.29-0.14,0.58-0.26,0.87-0.4
      c0.11,0.25,0.28,0.48,0.49,0.67C442.32,186.98,442.12,187.22,441.91,187.46z"/>
    <path fill="#59A5B3" d="M445.82,185c-0.05-0.22-0.15-0.43-0.32-0.63c0.33,0.01,0.74-0.04,1.07-0.02
      c0.17,0.17,0.28,0.4,0.35,0.65C446.55,185,446.19,184.99,445.82,185z"/>
    <path fill="#59A5B3" d="M428.43,186.14c-0.1-0.21-0.24-0.41-0.44-0.59c0.41,0,0.94,0.02,1.34,0.06
      c0.13,0.16,0.25,0.42,0.33,0.74C429.26,186.27,428.85,186.2,428.43,186.14z"/>
    <path fill="#59A5B3" d="M424.11,187.35c-0.23,0.15-0.48,0.29-0.72,0.43c-0.14-0.37-0.16-0.73-0.03-1.19
      c0.15-0.08,0.36-0.29,0.64-0.32C423.94,186.67,423.99,187.03,424.11,187.35z"/>
    <path fill="#59A5B3" d="M424.33,189.11c-0.22-0.16-0.4-0.35-0.53-0.55c-0.09-0.15-0.18-0.28-0.24-0.41
      c0.24-0.14,0.48-0.28,0.72-0.42c0.13,0.23,0.3,0.42,0.5,0.58C424.64,188.58,424.49,188.84,424.33,189.11z"/>
    <path fill="#FFFFFF" d="M425.87,191.23c2.39,0.67,4.25,0.13,5.65-0.99c1.26-1.01,1.84-2.5,2.7-3.44
      c-1.22-0.48-2.93-1.03-4.25-1.16c0.21,0.37,0.36,1.22,0.26,1.72c-0.18,0.89-0.61,1.78-1.61,2.3c-1.14,0.6-2.46,0.64-3.73,0.25
      c-1.36-0.41-2.22-1.79-2.04-3.06c-0.33,0.18-0.72,0.44-1,0.81C422.28,188.67,423.48,190.56,425.87,191.23z"/>
    <path d="M438.73,200.26c0.04-1.1,0.24-2.87,1.69-2.77c0.91,0.06,1.73,0.72,2.47,1.32c0.15-0.69,0.53-1.46,1.54-1.31
      c1.1,0.15,2.45,1.92,2.63,2.18c0.18,0.26-0.32,0.48-0.84,0.67c1.71,1.89-0.19,3.77-2.39,4.24c-2.94,0.63-5.61-0.76-5.53-3.33
      C437.48,201.14,437.44,200.22,438.73,200.26z"/>
    <path fill="#B83B6E" d="M440.51,198.15c-0.94,0.06-1.08,1.51-1.02,2.28c1.36,0.11,2.53,0.09,3.47-0.19
      C442.74,199.77,441.45,198.1,440.51,198.15z"/>
    <path fill="#B83B6E" d="M444.1,198.17c-0.78,0.18-0.56,1.49-0.4,1.97c0.56-0.1,1.87-0.43,2.41-0.59
      C445.84,199.1,444.89,197.99,444.1,198.17z"/>
    <path fill="#E6528C" d="M441.41,203.73c1.72,0.44,4.37-0.15,4.81-1.67c0.23-0.78-0.28-1.33-0.62-1.6
      c-1.15,0.39-4.49,0.91-6.46,0.85C439.1,201.74,439.17,203.15,441.41,203.73z"/>
    <path d="M474.47,313.92c1.74,1.2,3.46,2.48,4.95,3.95c1.2,1.18,2.37,3.18,2.56,4.83c0.02,0.14-0.19,0.17-0.26,0.07
      c-0.47-0.64-0.64-1.47-0.91-2.2c-0.35-0.95-0.9-1.79-1.58-2.56c-1.38-1.56-3.24-2.65-4.82-4.02
      C474.37,313.95,474.43,313.89,474.47,313.92z"/>
    <path d="M476.33,313.33c1.66,1.45,3.28,2.98,4.59,4.72c0.97,1.29,2.35,3.48,1.89,5.12c-0.03,0.09-0.19,0.15-0.23,0.03
      c-0.3-0.91-0.26-1.88-0.61-2.8c-0.4-1.06-1.07-2.03-1.79-2.92c-1.17-1.48-2.61-2.73-3.93-4.09
      C476.21,313.34,476.28,313.29,476.33,313.33z"/>
    <path d="M477.38,312.73c1.01,0.81,1.84,1.89,2.66,2.86c0.86,1.01,1.62,2.07,2.21,3.24c0.03,0.06-0.07,0.12-0.11,0.06
      c-0.75-1.07-1.5-2.13-2.31-3.16c-0.8-1-1.76-1.91-2.5-2.94C477.29,312.75,477.34,312.69,477.38,312.73z"/>
    <path d="M471.78,300.05c1.14,2.02,2.13,4.32,2.08,6.56c0,0.07-0.1,0.06-0.11,0c-0.43-2.22-1.33-5.09-2.33-6.94
      C471.39,299.62,471.55,299.66,471.78,300.05z"/>
    <path d="M470.58,299.77c0.69,1.19,1.5,2.9,1.68,4.26c0.01,0.07-0.31-0.2-0.33-0.26c-0.3-0.98-0.97-2.91-1.45-3.95
      C470.45,299.75,470.55,299.71,470.58,299.77z"/>
    <path d="M437.85,171.3c2.34-0.09,4.02,1.08,5.76,2.42c0.96,0.74,1.88,1.51,2.89,2.19c1.27,0.85,2.76,1.45,4.22,1.94
      c3.05,1.04,6.34,1.29,9.53,0.77c3.01-0.49,5.62-1.94,8.48-2.87c4.74-1.56,9.55-1.39,13.79,1.27c4.51,2.83,7.08,7.18,8.94,11.82
      c0.93,2.33,2.06,4.85,4.56,6.05c2.25,1.07,3.76-1.37,5.39-2.38c0.05-0.03,0.12,0.04,0.07,0.07c-1.37,0.93-2.46,2.71-4.29,2.87
      c-2.09,0.19-3.84-1.91-4.76-3.36c-1.17-1.88-1.81-4.01-2.78-5.98c-1.15-2.34-2.55-4.52-4.5-6.35c-2.07-1.96-4.58-3.54-7.45-4.22
      c-2.6-0.62-5.33-0.26-7.86,0.43c-2.87,0.79-5.46,2.23-8.37,2.92c-2.75,0.65-5.62,0.64-8.39,0.11c-2.92-0.56-5.72-1.76-8.03-3.5
      c-2.03-1.52-4.4-3.98-7.21-4.03C437.74,171.45,437.74,171.3,437.85,171.3z"/>
    <path d="M452.46,173.65c1.56,1.13,3.2,2.44,5.09,3.05c1.55,0.5,3.22,0.26,4.71-0.29c2.94-1.08,5.37-3.17,8.08-4.64
      c3.02-1.63,6.19-1.94,9.48-0.82c3.49,1.19,6.42,3.74,8.59,6.49c0.03,0.04-0.03,0.09-0.06,0.05c-2.4-2.96-5.71-5.45-9.56-6.52
      c-3.85-1.07-7.14,0.37-10.27,2.34c-2.42,1.51-4.78,3.34-7.69,3.95c-3.49,0.73-6.06-1.47-8.47-3.47
      C452.26,173.72,452.38,173.59,452.46,173.65z"/>
    <path d="M359.19,183.04c2.5,0.42,4.78,1.6,7.2,2.28c3.02,0.85,5.9,0.33,8.5-1.28c2.09-1.29,3.74-3.14,5.42-4.85
      c1.74-1.78,3.58-3.35,5.83-4.55c6.41-3.42,14.32-3.12,21.32-1.81c6.53,1.23,14.79,3.94,20.58-0.68c0.04-0.04,0.1,0.03,0.06,0.07
      c-3.52,2.96-8.59,3.05-13.05,2.51c-2.41-0.29-4.73-0.91-7.1-1.39c-2.68-0.54-5.41-0.89-8.16-0.99c-5.46-0.19-11.04,0.85-15.51,3.91
      c-4.06,2.77-6.49,7.25-11.24,9.2c-3.1,1.27-6.18,0.56-9.19-0.54c-3-1.09-5.8-2.08-9.02-1.22c-5.72,1.53-10.45,7.82-7.25,13.16
      c0.08,0.14-0.13,0.28-0.22,0.14C343,190.21,351.47,181.75,359.19,183.04z"/>
    <path d="M371.12,182.67c2.34-0.35,3.9-1.85,5.62-3.2c1.56-1.23,3.08-2.49,4.61-3.74c2.97-2.41,6.08-4.7,9.79-6.03
      c3.98-1.43,8.25-1.42,12.4-0.7c4.89,0.85,9.55,2.67,14.48,3.35c0.06,0.01,0.05,0.1-0.01,0.08c-4.59-0.53-8.94-1.98-13.45-2.89
      c-4.73-0.95-9.54-0.96-14.04,0.83c-3.69,1.46-6.8,3.94-9.8,6.37c-1.57,1.27-3.21,2.49-4.73,3.81c-1.41,1.22-2.93,2.05-4.86,2.22
      C371.07,182.77,371.05,182.68,371.12,182.67z"/>
    <path d="M348.07,178.21c1-1.21,2.41-2.03,3.89-2.63c3.56-1.43,7.5-1.74,11.07-3.18c3.45-1.4,5.55-4.22,7.63-7.01
      c1.82-2.42,3.72-5.04,6.71-6.28c2.57-1.06,5.47-0.53,8.02,0.3c3.67,1.18,6.85,3.34,10.75,3.93c2.39,0.36,4.77,0.56,7.18,0.34
      c2.27-0.2,4.26-1,6.39-1.69c3.84-1.24,7.84-1.35,11.86-1.33c0.05,0,0.05,0.07,0,0.07c-2.71,0.01-5.41,0.1-8.07,0.59
      c-2.98,0.55-5.65,1.99-8.61,2.56c-3.02,0.59-6.15,0.32-9.17-0.14c-2.6-0.39-4.97-1.53-7.31-2.59c-3.85-1.75-8.42-3.29-12.31-0.83
      c-2.88,1.82-4.62,4.93-6.64,7.47c-1.3,1.63-2.81,3.16-4.67,4.24c-2.22,1.28-4.91,1.84-7.41,2.41c-2.42,0.54-4.94,1.06-7.06,2.32
      c-2.54,1.52-3.76,4.25-4.25,6.94c-0.01,0.07-0.13,0.06-0.12-0.01C346.21,181.68,346.72,179.83,348.07,178.21z"/>
    <path d="M367.72,164.14c3.14-3.25,6.6-6.44,11.49-6.96c5.76-0.61,10.87,3.02,16.21,4.35c3,0.74,5.73,0.48,8.49-0.82
      c2.47-1.15,4.66-2.74,7.13-3.9c4.51-2.12,10.03-3.06,14.95-1.78c4.5,1.16,8.07,4.27,11.75,6.77c0.08,0.05-0.02,0.15-0.09,0.1
      c-4.54-2.8-8.61-6.28-14.22-6.95c-5.75-0.68-11.27,1.47-16.01,4.27c-2.11,1.25-4.25,2.5-6.73,2.98c-2.59,0.5-5.25-0.18-7.67-1
      c-5.42-1.82-10.68-5.01-16.6-3.05c-3.42,1.14-6.05,3.76-8.57,6.08C367.76,164.33,367.64,164.22,367.72,164.14z"/>
    <path d="M438.77,154.35c1.64-3.28,6.89-3.27,9.94-2.05c4.29,1.71,6.76,5.74,9.77,8.82c4.07,4.14,10.02,6.29,15.82,4
      c2.54-1.01,5.09-2.05,7.89-2.23c2.66-0.17,5.2,0.7,7.44,1.97c5.83,3.31,9.39,9.6,11.33,15.5c0.01,0.05-0.07,0.07-0.08,0.02
      c-1.56-4.64-4.01-9.11-7.69-12.6c-1.84-1.75-4.07-3.14-6.54-3.98c-3.13-1.06-6.28-0.56-9.3,0.52c-1.32,0.47-2.58,1.1-3.92,1.52
      c-1.3,0.41-2.66,0.61-4.03,0.65c-2.45,0.07-4.84-0.65-6.94-1.78c-4.19-2.24-6.56-6.24-9.89-9.35c-1.88-1.77-4.26-3.15-6.97-3.37
      c-2.34-0.19-5.37,0.4-6.46,2.57C439.02,154.79,438.65,154.58,438.77,154.35z"/>
    <path d="M459.71,164.33c2.02,1.19,4.05,2.36,6.34,3.03c2.11,0.62,4.33,0.69,6.5,0.34c4.01-0.63,7.6-3.78,11.84-2.96
      c0.05,0.01,0.02,0.08-0.02,0.07c-2.32-0.39-4.44,0.44-6.5,1.32c-2.05,0.88-4.03,1.73-6.29,2.03c-4.33,0.57-8.72-1.13-11.96-3.72
      C459.54,164.39,459.63,164.28,459.71,164.33z"/>
    <path d="M443.93,159.65c4.66-2.22,8.28,2.85,10.54,5.89c0.03,0.04-0.02,0.1-0.06,0.06c-2.48-2.98-5.74-7.3-10.41-5.8
      C443.91,159.82,443.84,159.69,443.93,159.65z"/>
    <path d="M442.24,159.47c2.46-2.31,5.59-3.17,8.56-1.12c2.6,1.81,4.19,4.73,5.56,7.39c0.03,0.05-0.05,0.08-0.07,0.04
      c-1.37-2.44-2.88-4.94-5.13-6.77c-1.06-0.87-2.39-1.47-3.82-1.51c-1.85-0.06-3.61,1.03-4.98,2.08
      C442.28,159.64,442.16,159.54,442.24,159.47z"/>
    <path d="M456.9,362.5c1-0.12,1.99,0.34,2.62,1.05c0.04,0.05-0.03,0.12-0.08,0.1c-0.76-0.25-1.5-0.66-2.33-0.59
      c-0.89,0.07-1.38,1.09-1.75,1.72c-0.06,0.11-0.28,0.08-0.27-0.06C455.18,363.73,455.71,362.65,456.9,362.5z"/>
    <path fill="#FFFFFF" d="M442.84,184.53c0.42,0,0.76,0.31,0.76,0.7c0,0.39-0.34,0.7-0.76,0.7c-0.41,0-0.75-0.31-0.75-0.7
      C442.09,184.85,442.43,184.53,442.84,184.53z"/>
    <path fill="#FFFFFF" d="M443.84,185.7c0.17,0,0.31,0.13,0.31,0.29c0,0.16-0.14,0.29-0.31,0.29c-0.17,0-0.31-0.13-0.31-0.29
      C443.53,185.83,443.66,185.7,443.84,185.7z"/>
    <path fill="#FFFFFF" d="M425.47,185.67c0.51-0.02,0.93,0.34,0.95,0.81c0.02,0.47-0.37,0.86-0.87,0.89
      c-0.51,0.02-0.93-0.34-0.95-0.81C424.57,186.09,424.97,185.69,425.47,185.67z"/>
    <path fill="#FFFFFF" d="M426.74,187.03c0.2-0.01,0.38,0.14,0.39,0.33c0.01,0.19-0.15,0.36-0.36,0.37
      c-0.21,0.01-0.39-0.14-0.39-0.33C426.38,187.2,426.54,187.03,426.74,187.03z"/>
    <path fill="#FFB0C4" d="M440.85,202.13c-0.88-0.44-1.28,0.15-0.36,0.6C441.3,203.12,441.76,202.59,440.85,202.13z"/>
    <path fill="#FFE9DE" d="M432.82,193.59c-1.61-0.19-4.02,0-5.01,0.24c-0.68,0.16,1.88,2.4,2.91,2.54
      C431.75,196.51,433.99,193.73,432.82,193.59z"/>
  </g>
  <g id="personaje2">
    <path d="M281.13,239.79c-1.61,5.05-0.17,12.14,0.77,20.85c0.95,8.72,4.75,42.14,6.09,43.27c1.34,1.13,6.34-2.33,7.68-3.47
      c0.55,8.31,1.34,21.52,1.07,31.32c-0.18,6.69-1.13,12.03-0.52,13.86c1.51,4.49,9.79,5.66,14.57,5.91
      c1.54,11.22,7.23,39.13,10.9,48.09c-3.53,1.84-6.92,3.56-10.14,5.87c-0.37,1.27,0.27,2.25,0.8,3.24c-1.23,0.94-2.87,1.68-4.46,1.55
      c-4.29-2.62-9.59-2.45-14.27-0.4c-3.11,0.38-5.25,1.35-5.8,2.82c1.59,3.31,1.76,14.11,1.76,14.11s3.47,0.45,4.74,1.57
      c-0.27,4.54-2.84,8.27-3.39,12.59c-1.12,5.46-0.41,12.26,4.88,15.78c7.53,6.78,18.99,5.82,29.01,4.72l1.38-0.83
      c6.34,0.51,12.72-1.29,18.29-2.56c1.69-1.49,3.81-3.66,5.5-4.12c0.18,1.49,0.72,2.79,1.85,3.8c4.45-0.08,17.11-0.87,19.24-2.12
      c-0.37-3.59-2.64-6.17-5.25-9.03c-0.66-3.13-0.97-6.27-3.37-8.8c1.18-0.31,2.22-0.38,3.67-1.61c-0.1-2.68-5.22-13.67-6.16-17.02
      c3.49-1.2,15.96-4.95,17.48-7.98c1.51-3.03-7.9-44.96-8.07-56.14c1.03-0.03,3.02-0.31,4.05-0.38c-0.15-0.96-1.35-4.7-1.08-6.19
      c2.31,2.81,6.4,5.15,9.61,6.38c-1.2,1.31-1.93,2.44-2.37,4.35c-0.44,1.9,0.71,4.29,2.19,4.02c2.32-0.43,1.59-3.28,3.55-2.62
      c-0.03,1.22,0.57,2.76,1.49,3.68c0.92,0.91,2.98,0.54,2.98,0.54s0.78,2.1,2.04,2.88c1.25,0.78,2.61-0.19,3.43-1.35
      c0.65-1.64,1.41-3.53,3.17-4.39c1.4,5.64,0.79,18.51,0.79,18.51s-2.38-0.06-3.81,0.05c-3.23-2.46-6.78-4.15-10.98-4.08
      c0.69-1.15,0.67-3.04-2.06-3.4c-2.74-0.36-3.78,1.08-3.94,3.04c-0.16,1.96,1,3.29,2.69,3.17c12.02,15.11,23.49,49.88,24.81,59.78
      c0.64,4.75-0.24,12.72-0.29,15.44c-0.05,2.71,5.25,2.04,5.79,0.66c-1.4-4.18-1.77-8.55-2.65-12.77
      c0.02-10.47,7.84-64.06,7.84-64.06c3.63-2.02,5.79-5.55,3.07-6.97c-2.73-1.42-4.24,2.46-5.56,3.91c-2.32-0.4-5.71,2.38-7.18,5.05
      c-1.05-0.04-2.64,0.08-3.64,0.39c-0.37-7.55,2.19-14.46,1.95-21.9c0.72-0.78,2.11-1.5,2.93-2.66c0.55-1.26,0.3-2.71-0.57-3.62
      c-5.21-4.14-10.26-8.05-15.35-12.32c0.53-7.5,0.55-13.95,1.2-21.56c0.64-7.62-0.82-45.32,2.59-67.08
      c0.13-4.65,2.02-8.71,1.75-13.45c-1.74-1.95-32.98-8.21-38.9-9.85c0.16-3.08-0.58-8.57-1.93-9.77c-1.35-1.19-3.53,1.68-5.32,3.2
      c-0.37-4.37,1.32-9.42,2-13.75c4.25,0.21,11.04,1,14.96-1.43c0.84-0.91-5.75-4.62-16.23-8.68c-0.28-1.72-0.92-4.55-2.45-7.7
      c-1.44-7.19-5.83-17.39-8.41-18.42c-3.71-1.49-3.83,7.57-5.95,7.28c-2.12-0.29-4.51-10.76-10.57-10.1
      c-4.43,0.47-4.06,10.52-3.74,14.31c-0.58,1.4-1.41,3.78-1.85,6.01c-11.71-3.15-24.1-6.43-25.46-2.64
      c-1.36,3.79,15.25,11.64,22.64,15.13c0.61,6.25,0.79,14.11,3.76,20.69c-3.36,0.51-3.33,1.42-3.03,4.29
      c-7.32,1.89-16.48,3.6-22.31,5.19C289.17,233.44,282.74,234.74,281.13,239.79z M332.58,432.3c-0.12,2.52,0.84,6.27,1.43,8.29
      c-0.77,0.7-1.92,2.15-2.42,3.34c-3.65,0.27-10.69,0.71-17.86,2.86c-1.52-0.86-4.52-1.86-4.79-2.55c-0.26-0.69,3.07-8.71,3.13-17.08
      c0.71-0.91,6.12-3.7,7.48-3.07c1.21,3.45,3.7,9.56,4.4,13.17C326.95,436.65,332.58,432.3,332.58,432.3z"/>
    <path fill="#736795" d="M323.42,182.79c8.57,0.35,16.39,2.95,23.89,6.19c0.32-0.88-3.67-14.13-6.14-15.2
      c-2.47-1.05-1.56,8.09-5.29,7.44c-3.73-0.65-7.2-10.72-10.31-10.33C322.46,171.29,323.42,182.79,323.42,182.79z"/>
    <path fill="#9F6790" d="M321.63,189.51c9.01,1.7,26.35,6.68,28.41,7.75c-0.09-1.68-0.84-4.2-1.68-5.94
      c-3.54-3.04-20.85-7.11-25.41-6.3L321.63,189.51z"/>
    <path fill="#6F4A81" d="M296.39,187.58c0.1,1.8,12.83,8.96,19.77,11.75c0.14-1.48,1.68-3.46,3.36-3.83
      c11.9,1.08,23.05,2.63,33.6,7.97c0.96,0.54,0.79,1.53,1,2.49c3.73,0.71,12.6,0.94,12.07-0.03c-1.43-2.58-21.98-8.98-28.33-10.81
      C330.9,193.12,296.14,183.07,296.39,187.58z"/>
    <path fill="#EAC19A" d="M320.17,217.25c1.07,4.8,12.01,20.87,15.24,32.73c0.84,0.55,3.17,0.48,4.06,0.17
      c5.53-6.8,9.57-16.47,9.67-26.13c-0.59-0.95-1.52-2.11-2.74-2.67c0.27,4.75-2.11,9.58-5.31,11.82c-3.21,2.24-6.25,2.4-7.41,1.05
      c4.95-1.19,11.31-4.05,11.49-13.35c-0.65-1.54-4.15-1.5-5.41-1.73c3.03-2.74,9.64,2.52,9.64,2.52c0.31-5.64,1.19-10.92,2.44-16.26
      c-9.3-5.92-20.78-6.67-31.9-7.81C318.01,198.85,319.09,212.45,320.17,217.25z"/>
    <path fill="#DEA47B" d="M330.22,205.14c-3.7-0.57-7.53-1.04-11.34-1.19c-0.02-3.23,0.27-5.84,1.07-6.36
      c11.12,1.14,22.6,1.89,31.9,7.81c-0.31,1.33-0.6,2.67-0.85,4.03C344.15,207.45,337.01,206.18,330.22,205.14z"/>
    <path d="M323.28,209.56c1.51,1.5,5.63,3.4,7.27,1.67c0.89-0.93-0.2-3.26-0.47-3.99c-0.27-0.74-1.54-0.88-1.63-0.38
      c0.59,0.69,1.7,2.91,0.59,3.64c-1.12,0.73-4.51-0.83-6.72-3.55C321.73,207.72,322.23,208.52,323.28,209.56z"/>
    <path d="M335.06,208.7c1.3,1.37,2.2,2.75,4.98,3.06c2.79,0.31,4.31-0.52,4.84-1.71c0.33-0.75-0.06-1.3-0.47-1.52
      c-0.72,1.35-1.82,2.27-4.42,1.88C337.39,210.01,336.58,207.98,335.06,208.7z"/>
    <path d="M331.39,213.68c-0.1,1.3-1.33,8.83-3.85,13.18c-0.19,0.87,0.91,1.4,1.7,1.46c2.89,0.03,6.23-0.83,8.43-2.82
      c0.74-1.03,0.37-2.1,0.37-2.1c-2.22,1.63-5.1,3.55-8.04,2.91c1.31-4.62,2.9-9.8,2.59-14.91
      C332.58,211.39,331.49,212.38,331.39,213.68z"/>
    <path fill="#9F6790" d="M351.26,224.87c-0.8,12.6-6.72,22.91-10.16,26.59c-0.42,0.45-2.55,0.96-3.3,1.14
      c-0.03,7.23,0.46,25.31,0.46,25.31s6.68,15.58,6.42,17.43c-0.26,1.85-4.22,3.72-5.72,4.57c2.24,13.57,2.08,27.63,2.65,41.01
      c0.11,2.7,0.38,11.73,2.41,12.44c8.65-0.14,18.06-0.59,26.82-0.99c0,0-0.85-6.9-0.42-10.09c0.74-5.52,2.64-9.8,3.82-13.96
      c1.17-4.15,4.18-20.98,6.5-33.37c2.33-12.39,8.26-28.97,9.82-30.67c0.38-0.15,0.82,0.3,0.85,0.67c-2.03,7.02-5.23,15.18-7.29,23.23
      c-2.77,10.85-4.22,21.25-4.88,26.07c-1.14,8.4-6.28,25.87-6.28,25.87c5.44-1.06,11.43-1.45,17-0.76
      c0.79-2.99,2.97-30.04,2.06-40.73c0.94-15.22,1.38-39.77,2.04-45.74c0.66-5.97,2.58-12.46,1.57-14.05
      c-1.01-1.59-35.52-8.76-36.94-8.41c-2.04,10.45-7.02,17.14-12.34,21.47c-1.42,1.16-2.32,0.62-2.76,0.17
      c4.18-3.36,8.37-8.46,11.37-14.75c3-6.29,1.74-16.54,0.98-17.05C355.19,219.76,351.26,224.87,351.26,224.87z"/>
    <path fill="#9F6790" d="M318.45,224.64c2.22,9.91,5.27,19.12,13.02,25.39c0.3,0.47-1.43,1.01-3.76-1.15
      c-2.33-2.15-7.53-9.58-10.04-20.82c-4.04,1.06-29.38,5.63-33.07,9.58c-3.69,3.95-2.27,9.99-1.74,14.97
      c0.53,4.97,5.01,49.02,6.4,49.2c1.39,0.17,16.87-12.15,17.15-12.82c0.29-0.67-3.07-13.34-1.88-19.15
      c-5.12-0.11-10.98,3.67-13.76,8.02c-0.65,0.05-1-1.99,2.29-4.88c-1.12-0.17-3.4,0.1-4.42,0.26c0.23-1.97,4.65-1.23,6.17-1.83
      c2.08-1.74,5.63-2.97,8.59-3.19c-1.8-0.71-2.12-3.37-1.91-4.76c0.21-1.38,2.38-3.61,7.25-4.22c3.77-0.48,6.6,0.94,7.4,2.23
      c0.81,1.3,0.6,2.47,0.22,2.76c-0.38,0.29,0.08,1.49,0.27,1.4c0.34-0.15,1.85,0.03,2.36,0.02c1.1-0.02,1.38,1.33,2.08,2.56
      c2.67,1.54,9.87,1.15,10.98,1.84c1.1,0.69,3.29,3.93,4,4.8c0.55-4.59,0.15-22.73,0.15-22.73s-2.29-0.34-2.43-1.05
      c-1.33-6.75-8.4-20.34-13.07-27.45C320.08,223.8,318.26,223.82,318.45,224.64z"/>
    <path d="M328.13,256.68c-1.17,1.27-0.27,2.96,1.42,3.27c1.7,0.31,3.75-0.75,2.67-2.52C331.15,255.66,329.29,255.41,328.13,256.68z"
      />
    <path fill="#FFFFFF" d="M308.38,260.57c0.32,0.71,2.84,1.46,2.29,2.71c-1.06,1.43-3.81-0.08-4.73,1.57
      c2.9,0.15,8.14,0.07,9.14-2C316.09,260.78,311.29,259.71,308.38,260.57z"/>
    <path fill="#FFFFFF" d="M302.4,263.65c-0.18,1.18,2.17,1.65,2.43,1.63c-0.03-0.36,2.49-3.22,2.12-4.42
      C304.61,261.04,302.58,262.48,302.4,263.65z"/>
    <path d="M327.91,265.77c-0.19,1.07,0.65,1.88,2.04,2.35c1.2,0.41,3.07-0.49,2.81-1.79c-0.25-1.3-1.11-1.9-2.45-2.17
      C329.5,263.98,328.09,264.71,327.91,265.77z"/>
    <path fill="#D2B348" d="M302.33,265.27c0.19,0.57,1.44,1.66,2.05,2.05c2.34,1.51,7.65,1.06,11.01-0.94
      c0.33-0.19,0.07-0.78-0.2-1.59C311.3,266.42,304.57,266.4,302.33,265.27z"/>
    <path fill="#93026C" d="M329.56,265.64c-0.35,0.1-0.48,0.94,0.35,1.32c0.84,0.39,1.7,0.1,1.54-0.79
      C331.3,265.29,330.27,265.43,329.56,265.64z"/>
    <path fill="#EAC19A" d="M311.96,272.24c-0.41,1.38-0.53,3.09-0.14,4.53c2.41,0.55,4.95-0.02,6.59,2.18
      c-1.03,5.89,4.49,9.61,7.05,19.44c-2.03,0.66-4.46,2.44-5.37,3.75c-2.13-1.8-5.96-5.44-7.82-4.66c-1.85,0.78,5.28,6.74,7.89,8.13
      c4.91-2.43,21.63-9.14,22.02-10.33c0.39-1.19-0.87-3.75-1.93-7.22c-1.05-3.47-4.97-12.87-8.63-15.96
      c-1.49-1.25-10.06-1.65-10.88-2.3c-0.81-0.65-1.05-2.41-2.64-2.54C315.39,267.83,312.91,269.6,311.96,272.24z"/>
    <path fill="#D2B348" d="M307.67,282.44c0.78-0.06,3.09-0.52,3.43-1.28c-1.36-3.69-1.16-7.92-0.39-11.28
      c-1.53,0.48-3.16,0.44-4.42,0.33C306.29,270.22,305.14,276.3,307.67,282.44z"/>
    <path fill="#9F6790" d="M312.16,283.09c-0.34,0.53-2.92,2.06-4.56,1.32c-0.07,2.18,0.94,4.9,0.58,5.75
      c-0.36,0.85-7.19,5.78-10.22,8.69c1.47,15.78,1.32,29.93,0.36,44.91c0.18,2.06,2.01,3.64,4.02,4.1
      c11.44,3.44,37.37-1.25,37.37-1.25c-0.63-15.23,0.35-30.91-2.62-45.85c0,0-15.31,7.07-16.62,7.03c-1.3-0.03-9.64-7.11-10.28-9.13
      c-0.63-2.02,2.29-3.06,4.35-2.16c2.05,0.9,4.5,3.13,5.19,3.92c0.9-0.84,2.88-2.24,3.89-2.42c-0.67-3.69-5.04-10.35-6.06-13.37
      c-1.02-3.02-0.36-4.76-0.48-4.94c-0.39-0.55-3.31-1.37-4.97-0.6C312.61,280.39,312.5,282.55,312.16,283.09z"/>
    <path d="M330.07,309.53c-0.28,1.24,0.41,3.12,1.82,3.62c1.41,0.49,3.5,0.27,3.86-1.71c0.16-1.23-0.84-2.14-1.92-2.55
      C332.61,308.49,330.88,308.13,330.07,309.53z"/>
    <path fill="#93026C" d="M331.59,310.62c-0.01,0.82,1.31,1.18,1.87,1.14c0.56-0.04,0.91-0.59,0.37-1.38
      C333.28,309.59,331.61,309.79,331.59,310.62z"/>
    <path d="M330.48,320.62c-0.13,1.6,1.56,4.07,3.52,3.93c1.97-0.14,2.7-3.28,1.42-4.41C334.02,318.89,330.92,318.02,330.48,320.62z"
      />
    <path fill="#93026C" d="M332.45,322.06c0.42,0.34,0.85,0.79,1.37,0.64c0.35-0.52,0.69-1.15,0.26-1.61
      C333.05,319.82,331.69,320.9,332.45,322.06z"/>
    <path d="M330.88,331.73c-0.93,1.42-0.19,2.98,0.34,3.74c0.53,0.76,2.59,1.15,3.71,0.46c1.75-0.98,1.92-2.92,0.68-4.32
      C334.38,330.21,331.81,330.31,330.88,331.73z"/>
    <path fill="#93026C" d="M331.5,333.26c-0.03,0.68,0.7,1.58,1.52,1.72c0.82,0.14,1.88-0.54,1.82-1.29
      c-0.06-0.74-0.45-1.54-1.42-1.54C332.45,332.14,331.52,332.59,331.5,333.26z"/>
    <path fill="#EAC19A" d="M372.12,342.62c-0.49,3.58,3.25,5.87,6.04,7.87c5.27,3.28,13.66,3.79,16.98,3.74
      c3.33-0.05,4.99-1.72,4.81-3c-0.18-1.28-15.36-5.37-15.36-5.37c0.64-1.76,12.37,2.01,16.2,3.46c0,0-7.74-6.85-10.71-8.22
      C384.58,340.8,376.05,340.99,372.12,342.62z"/>
    <path fill="#736795" d="M313.41,351.45c0,0,3.78,27.66,10.19,47.38c0,0,17.84-7.01,20.98-8.06
      c-1.68-5.87-5.33-30.22-6.72-35.86c0.35-0.52,0.98-0.81,1.53-0.48c0.13,1.27,5.31,27.13,7.4,37.05
      c8.73,4.01,13.78,9.64,12.23,10.28c-3.27-3.06-7.13-6.69-11.43-7.96c5.82,5.4,6.54,9.05,5.87,8.86c-3.57-4.11-5.06-7.38-9.49-8.51
      c-9.81,1.94-26.99,9.75-30.52,12.25c3.22,8.56,10,19.7,11.57,27.82c13.24-9.53,49.73-21.19,50.45-24.13
      c0.72-2.94-7.9-40.61-7.93-54.91c0,0-17.1,1.13-25.04,0.37c-2.75-1.51-1.75-5.13-3.71-6.69
      C330.47,349.95,322.1,351.78,313.41,351.45z"/>
    <path fill="#5E5085" d="M351.99,360.24c-3.82-0.03-7.65-0.23-11.49-0.42c-0.65-2.98-1.08-5.02-1.13-5.38
      c-0.55-0.33-0.77,3.69-0.43,5.31c-4.64-0.22-9.28-0.39-13.92-0.18c-3.45,0.16-6.86,0.49-10.21,0.97c-0.92-5.5-1.41-9.08-1.41-9.08
      c8.69,0.33,17.07-1.5,25.37-2.6c1.97,1.56,0.96,5.18,3.71,6.69c7.94,0.76,25.04-0.37,25.04-0.37c0,1.77,0.12,3.89,0.31,6.24
      C362.69,360.39,357.28,360.27,351.99,360.24z"/>
    <path fill="#EAC19A" d="M401.72,351.65c-0.1,0.68-0.67,1.62-0.67,1.62c1.76,0.6,3.31,2.01,4.11,2.93
      c0.98-0.8,1.9-2.3,0.79-3.07l-4.48-3.38C401.47,349.76,401.82,350.97,401.72,351.65z"/>
    <path fill="#AA011E" d="M380.68,359.93c-0.01,0.91-0.21,2.02,0.84,2.13c1.05,0.12,1.26-1.3,1.75-2.26
      c1.04-2.02,8.08-3.69,8.08-3.69l-6.81-0.99C382.75,355.63,380.7,358.21,380.68,359.93z"/>
    <path fill="#AA011E" d="M395.8,356.18c2.11,0.36,4.38,0.91,4.84,3.84c0.45,2.94,0.62,13.9-0.43,21.15
      c0.24,0.27,1.61,0.8,1.92-0.08c0.12-6.36,1.74-16.76,1.75-20.13c0.02-3.37-0.65-4.74-3.29-6.42
      C399.75,355.37,397.74,355.79,395.8,356.18z"/>
    <path fill="#E2AA80" d="M390.93,363.98c0.05,1.5,0.97,2.85,2.59,2.06c1.62-0.79,2-4.94,4.34-6.24
      c-0.24-1.2-2.51-1.55-3.33-1.85C392,359.11,390.89,362.49,390.93,363.98z"/>
    <path fill="#E0A47A" d="M387.26,359.61c0,0-1.11,3,0.78,3.54c1.9,0.54,1.7-3,2.92-4.18
      C389.89,358.79,387.26,359.61,387.26,359.61z"/>
    <path fill="#FFFFFF" d="M379.77,375.75c-0.53,1.24,0.18,2.16,1.7,1.98c1.52-0.19,2.29-2.42,1.11-3.01
      C381.4,374.12,380.31,374.51,379.77,375.75z"/>
    <path fill="#FFFFFF" d="M416.55,376.57c0.52-0.04,2.05-1.43,2.46-2.28c0.41-0.86-0.4-1.85-1.29-0.96
      C416.83,374.23,416.55,376.57,416.55,376.57z"/>
    <path fill="#C0001F" d="M384.01,379.27c14.05,21.62,23.28,58.09,23.28,58.09c0.18-5.18,7.44-55.46,7.81-59.76
      c-1.82-1.33-6.05,0.75-7.62,4.4c-1.66,11.36-2.23,25.05-1.1,36.82c-0.1,0.38-0.98,0.08-1.22-1.85c-0.23-1.92-0.37-24.81,0.48-35.03
      c-2.23,1.38-5.97,2.28-9.03,0.56c0,0,3.11,15.05,3.96,21.95l-0.26,0.02c-2.56-7.02-3.73-14.64-6.3-21.66
      c-1.29-2.83-4.95-4.28-8-4.54C385.25,378.45,384.62,378.73,384.01,379.27z"/>
    <path fill="#8E4E82" d="M295.83,410.55c0.57,2.03,1.66,4.14,2.19,9.5c0.54,5.36-5.96,17.58-3.43,28.88
      c3.33,5.26,10.83,6.74,16.94,7.52c3.18,0.38,8.09,0.37,11.22,0.14c0,0-7.55-7.32-15.26-10.72c-0.82-0.67,0.81-7.52,1.55-9.64
      c-0.92-1.52-3.41-2.89-5.38-2.87c-0.6,0.65-0.5,1.71-1.67,1.8c-1.18-0.04-2.21-0.16-2.69-1.11c-0.48-0.94-0.71-2.24,0.24-3.11
      c0.95-0.88,3.08-0.5,4.09,0.99c1.93-0.07,4.1,0.25,5.61,2.59c0.26-1.61,0.65-3.73-1.01-4.83c-0.25-0.39-2.76-1.71-2.76-1.71
      s-1.95,0.22-2.65-0.22c-0.71-0.44-0.9-1.77-0.24-2.57c0.66-0.8,2.41-1,3.18-0.46c0.77,0.54,0.73,1.51,0.68,2.35l3.31,2.01
      c1.57-3.17-1.14-5.78-1.02-8.97c-0.41-3.27,0.72-8.6-4.09-9.6C301.94,409.85,298.5,409.5,295.83,410.55z"/>
    <path fill="#AA90AD" d="M311.95,424.16c1.85-1.36,4.47-2.16,6.46-2.8l-5.09-10.5c-1.07-0.29-2.87,0.92-3.89,1.24
      C309.42,412.09,310.16,419.91,311.95,424.16z"/>
    <path fill="#602A63" d="M289.77,413.83c1.22,3.57,1.68,7.52,1.37,11.44c1.08,0.41,3.11,0.66,4.17,0.83
      c1.25-3.88,1.19-9.26-0.71-13.03C293.67,411.32,290.45,412.56,289.77,413.83z"/>
    <path fill="#736795" d="M334.47,430.42c0.06,2.39,0.5,6.84,1.66,9.69c2.26,0.32,27.64-5.33,27.64-5.33
      c-1.98-4.86-5.57-14.88-5.57-14.88C350.58,422.9,335.23,429.59,334.47,430.42z"/>
    <path fill="#818381" d="M300.43,433.21c0.45,0.57,1.05,0.97,1.71,0.25c0.66-0.73-0.4-1.77-1.12-1.79
      C300.29,431.65,299.97,432.65,300.43,433.21z"/>
    <path fill="#814C82" d="M338.66,442.12c0.23,1.09,1.46,1.72,2.05,2.54c1.54-0.35,4.38-0.1,4.76,2.49
      c0.38,2.6-3.01,3.52-4.63,2.44c-1.62-1.08-0.96-3.75-0.96-3.75s-1.66-3.13-4.71-2.97c-1.04,0.7-1.6,1.54-1.8,2.25
      c0.43,0.87,2.08,2.69,2.08,2.69s2.78,0.55,3.24,2.24c0.47,1.69-2.19,2.5-3.52,2.23c-0.95-0.42-2.24-0.9-2.17-2.03
      c0.07-1.13,1.36-1.98,1.36-1.98s-1.56-2.28-2.53-2.36c-0.98-0.08-13.19,0.25-15.49,2.5c3.09,2.46,6.16,6.15,9.71,7.72
      c4.28-0.98,13.98-1.57,21.61-3.34c7.97-1.85,14.52-4.34,16.28-4.96c0.08-3.68-0.7-7.46-3.4-9.83
      C353.63,439.64,345.65,440.87,338.66,442.12z"/>
    <path fill="#818381" d="M342.09,448.45c0.63,0.4,1.56-0.02,1.66-0.73c0.1-0.71-0.37-1.47-1.49-1.47
      C341.14,446.27,341.45,448.06,342.09,448.45z"/>
    <path fill="#818381" d="M335.32,450.82c0.52,0.41,1.55,0.18,1.65-0.5c0.1-0.68-0.49-0.94-1.29-0.88
      C334.89,449.5,334.8,450.4,335.32,450.82z"/>
    <path fill="#602A63" d="M301.73,458.51c6.49,2.21,14.25,2.38,21.23,1.25c0.49-0.4,0.58-0.9,0.67-1.39l-0.41-0.22
      c-9.76,0.96-20.14,0.87-28-5.15C296.6,455.35,299.38,457.1,301.73,458.51z"/>
    <path fill="#602A63" d="M352.22,453.26c0.17,1.18,0.41,2.43,0.88,3.04c4.98,0,10.72-0.87,15.52-1.47
      c0.08-2.2-1.51-4.1-2.95-5.71C361.58,450.84,356.6,452.33,352.22,453.26z"/>
    <path fill="#602A63" d="M327.69,458.2l0.04,0.49c3.34,0.73,6.96-0.1,9.8-0.76c2.84-0.66,4.8-2.23,4.8-2.23
      C339.09,456.18,330.64,457.37,327.69,458.2z"/>
    <path fill="#FFFFFF" d="M407.06,453.61c-0.06,1.12,0.87,1.72,2.05,1.25c1.18-0.47-1.06-9.01-1.06-9.01
      S407.12,452.49,407.06,453.61z"/>
    <path fill="#93026C" d="M330.37,257.11c-0.54-0.28-1.67,0.2-1.63,0.72c0.04,0.52,1.05,0.97,1.77,0.85
      C331.21,258.54,331.38,257.64,330.37,257.11z"/>
  </g>
  <g id="item_12_">
    <path d="M485.03,292.56c1.26,1.35,2.68,2.54,4.05,3.79c1.65,1.51,3.02,3.06,4.75,4.49c0.43,0.73,0.78,3.52,2.27,2.64
      c1.42-0.84,3.27-1.85,5.2-2.88c1.3,7.63,7.95,5.57,8.21,4.55c0.13-0.51-0.28-1.16-0.57-1.67c-0.4-0.7-0.75-1.41-0.97-2.18
      c-0.36-1.25-0.44-2.54,0.52-3.44c1.06-0.15,2.09-0.16,3.06,0.07c0.12,0.36,0.37,2.11,0.6,2.14c3.15,0.43,7.13-2.47,9.44-5.1
      c1.5,2.63,3.65,4.59,6.43,6.2c2.6,1.5,5.72,3.14,8.63,4.08c-2.14,1.7-0.94,3.2-0.72,3.22c-2.22,2.02-2.8,5.16-4.54,7.92
      c-2.05,3.25-6.5,7.7-6.77,11.75c-1.76,0.59-3.08,1.66-2.49,3.85c0.07,0.26,0.24,0.53,0.47,0.71c-3.59,2.68-6.54,8.69-5.86,12.63
      c2.17,0.95,9.95,1,13.68,0.64c0.15-0.26,0.27-0.82,0.34-1.22c0.42,0.39,0.99,0.74,1.69,1.03c5.08,1.45,25.22,2.28,33.06-0.04
      c0.01,0,0.09-0.48,0.1-0.49c10.44,0.56,21.6-0.86,24.63-1.82c0.07-0.51,0.19-2.59,0.11-3.15c0.86-0.83,1.41-3.16,0.52-5.15
      c-1.25-2.79-4.13-4.19-7.2-4.48c-4.49-0.43-8.81,1.72-13.42,1.29c-3.17-0.29-5.98-1.01-8.76-2.24c0.12-0.12,0.23-0.27,0.27-0.42
      c0.79-2.47-1.31-3.73-3.74-4.3c0.82-3.45,1.21-7.12,2.82-10.33c1.41-2.82,2.94-5.64,3.37-8.71c3.52-1.89,3.79-4.43,3.03-5.64
      c-1.39-2.2-3.94-2.26-4.44-2.15c-1.01-0.87-2.2-1.4-3.45-2.08c-1.88-1.02-3.48-2.08-4.97-3.59c-1.32-1.33-2.63-2.6-4.22-3.57
      c2.69-1.19,6.43-1.2,7.17-3.54c0.41-1.31-0.54-2-1.83-2.34c-0.27-3.38-1.24-6.95-1.84-10.31c-0.73-4.16-3.79-12.41-3.98-14.55
      c1.4,2.53,3.14,4.83,4.94,6.41c6.34,5.56,21.26,2.63,22.61,2.24c0.68-0.19,1.75-2.06,1.9-3.39c0.97,0.47,2.2,1.1,3.54,1.69
      c-5.87,1.73-6.33,10.94-5.2,16.91c0.44,2.29,6.82,2.65,6.53-0.22c-0.23-2.2-1.52-8.97,0.86-10.41c-0.72,2.5,0.05,4.61,1.86,4.91
      c1.6,0.26,3.36-0.72,5.5-2.18c-0.25,2.12,1,4.3,3.38,4.06c1.86,4.91,2.13,10.63,2.27,17.32c0.14,6.18,0.11,12.45-0.43,18.61
      c-0.54,6.23-1.32,13.6-1.9,19.82c-0.1,1.07-0.76,5.27-0.86,7.39c-0.04,0.86,1.85,2.17,3.49,2.41c1.56,0.23,3.82-0.36,3.97-1.43
      c1.23-8.52,1.72-24.71,1.78-37.72c0.03-6.26-0.26-11.95-0.66-18.2c-0.2-3.12-0.57-6.17-1.58-9.06c0.84,0.38,1.7,0.19,2.34-0.39
      c1.85,0.58,3.43-0.5,3.39-2.31c-0.04-1.97-0.71-4.37-2.36-5.71c-0.32-4-2.54-5.97-3.94-5.36c-0.06-0.51-0.15-1-0.32-1.47
      c-0.68-2.01-2.78-2.99-4.73-1.83c-1.03-1.74-2.71-3.19-4.94-4.18c-4.28-1.9-9.91-3.52-14.16-0.99c-0.05-0.1-0.57-0.81-0.98-0.9
      c-2.16,0.48-4.14,1.97-6.23,2.67c-5.03,1.69-8.52,1.23-10.85,0.08c-0.01-0.38-0.37-1.78-1.78-1.68c-0.3-0.75-1.14-1.25-2.27-1.02
      c-4.14-3.97-4.09-9.78-4.96-14.95c-0.76-4.53-1.71-8.26-2.46-10.11c-0.23-2.58-0.11-6.25,0.04-8.43c1.2-1.03,4.1-2.68,6.18-1.9
      c1.19,0.45,2.48,0.7,3.75,0.36c2.05-0.54,2.64-2.02,3-3.71c3.19,0.89,6.92,1.27,8.46,2.76c2.6,2.5,6.01,9.96,11.3,7.92
      c3.38-1.29,2.65-4.14,1.06-6.52c0.32-1.6,1.19-4.01,2.79-6.01c0.55,0.41,1.22,0.71,2.07,0.91c0.28,0.07,0.61-0.12,0.71-0.36
      c0.88-2.05,1.02-3.99,1-6.17c-0.01-1.7-0.29-3.96-0.76-5.95c2.62,2.03,5.58,4.35,8.95,5.15c2.52,0.6,4.75,0.26,6.25-1.87
      c1.82-2.59,0.46-6.75-0.68-9.33c-1.71-3.87-4.37-6.85-7.37-10.14c0.95,0.42,2.17,0.6,3.42-0.13c2.32-1.35,2.81-4.49,1.54-6.45
      c-0.56-0.88-2.85-1.98-4.78-1.73c1-2.42,0.88-5.29-0.23-7.68c0.23-0.87,0.68-2.65,0.89-3.98c0.46,0.49,0.88,1.02,1.25,1.61
      c0.16,0.26,0.7,0.2,0.59-0.15c-0.34-1.16-0.9-2.32-1.63-3.39c0.1-1.36-0.09-3.1-0.27-4.26c3.4-0.81,5.02-4.93,4.65-8.17
      c-0.55-4.85-4.66-8.5-9.13-10.7c-7.69-3.78-16.53-3.28-24.73-0.81c-0.26-0.38-0.48-0.67-0.58-0.79c-1.61-1.79-3.82-3-6.4-2.78
      c-0.6,0.05-1.04,1.27-0.54,1.48c1.69,0.69,3.93,1.51,5.21,2.82c-3.61,1.23-6.35,2.59-9.02,4.02c-4.04,2.18-10.04,5.48-9.65,10.6
      c0.33,4.28,4.37,6.38,8.49,7.1c1.73,0.3,3.5,0.36,5.28,0.27c0.89,4.66,2.67,10.53,3.11,13.78c-1.57-1.06-3.76-1.38-5.5-0.27
      c-3.71,2.35-2.59,8.88-0.04,11.07c2.08,1.79,5.24,2.23,7.38,0.15c0.09,1.61,0.09,4.64,0.02,5.2c-0.09,0.56-13.59,12.93-17.52,15.81
      c-0.46-0.63-1.06-1.11-1.55-1.04c-0.49,0.07-5.07,3.42-6.97,5.36c-2.82,2.88-5.68,5.69-8.64,8.45
      c-6.99,6.54-15.22,15.38-19.95,21.88c-4.72,6.49-4.36,13.74-4.45,20.61c-0.06,4.55-0.38,9.08,0.44,13.59
      c0.09,0.49,0.21,0.99,0.34,1.5c-1.93,1.91-2.05,4.36-0.06,6.36c1.17,1.17,2.94,2.6,4.79,3.39c-0.51,0.27-0.95,0.36-0.88,0.55
      c0.06,0.17,0.22,0.72,0.23,0.9c-3.27-2.24-9.73,1.11-12.91,4.46c0.03-2.86-0.96-5.94-2.9-8.02c-0.55-0.59-1.57-1.53-2.51-0.95
      c-0.52,0.32-0.85,0.81-0.89,1.32c-1.77-1.72-4.73-2.19-6.03-0.23c-0.37,0.57,0.57,1.97,1.02,2.51
      C483.46,289.44,484.05,291.5,485.03,292.56z M548.18,305.57c-0.17-1.28-1.34-2.26-2.61-2.69c-0.61-0.21-1.25-0.25-1.9-0.2
      c-0.65-1.45-1.93-2.65-3.11-3.73c-1.87-1.69-3.78-3.34-5.46-5.2c0.75-0.2,1.5-0.42,2.24-0.67c0.61-0.2,1.22-0.42,1.83-0.63
      c4.9,3.92,11.43,8.66,17.93,10.18c-2.07,1.32-1.51,2.47-1.19,2.78c-7.41,4.6-6.12,17.51-11.82,23.46
      c-0.43,0.44,0.38,1.26,1.39,1.43c-0.95,0.88-1.72,1.95-2.17,2.98c-0.02,0.04-0.03,0.08-0.04,0.12c-2.91,0.5-6.26,0.77-7.88-1.27
      c2.89-0.38,4.94-1.12,4.58-1.57c-2.13-2.66-4.01-4.9-1.38-8.1c1.39-1.69,2.69-3.32,3.58-5.29c1.13-2.49,1.77-4.85,1.34-7.51
      c0.83-0.34,2-0.67,2.92-1.32C547.31,307.72,548.33,306.69,548.18,305.57z"/>
    <path fill="#FFE8CC" d="M486.36,286.54c-0.65,0.07-1.16,0.67-0.65,1.34c0.32,0.43,1.31,1.54,1.64,1.96
      c0.27-0.28,0.38-0.52,0.31-0.99C487.53,288.01,487.19,287.27,486.36,286.54z"/>
    <path fill="#FFE8CC" d="M490.81,287.63c0.42,0.51,0.88,1.15,1.26,1.75c0.31-0.45,0.35-1.54-0.71-2.6
      C491.1,286.87,490.82,287.27,490.81,287.63z"/>
    <path fill="#FFD1A6" d="M494.02,288.76c-0.37-0.65-0.96-1.43-1.47-1.99c-0.34-0.38-0.83-0.33-0.92-0.11
      c0.34,0.31,0.71,0.86,0.87,1.31c0.2,0.56,0.18,1.22-0.3,1.6c1.29,2.1,2.23,4.67,2.95,6.44c0.05-0.07,0.19-0.28,0.24-0.35
      C495.55,293.43,495.28,290.94,494.02,288.76z"/>
    <path fill="#FFD1A6" d="M558.27,177.46c-3.08,0.61-2.85,4-2.29,5.89c0.78,2.63,3.11,4.51,4.33,3.2
      c0.56-0.6,1.05-2.68-0.36-3.38c-1.41-0.7-0.88,0.82-0.65,1.16c0.23,0.33-1.26,0.09-1.21-1.28c0.03-0.95,0.5-1.35,1.14-1.37
      c1.08-0.03,2.21,0.61,2.66,1.68c0.39,0.93,0.34,2.35-0.07,3.26c0.59,0.02,1.25-1.04,1.25-1.4c0-1.06-0.46-4.82-0.89-5.77
      C561.74,178.5,559.92,177.13,558.27,177.46z"/>
    <path fill="#787A30" d="M597.11,155.29c1.31-2.78,0.33-5.91-1.51-8.2c-3.39-4.18-9.46-6.36-14.81-7.09
      c-4.51-0.61-9.47,0-14.27,1.38c0.55,1.09,0.37,2.46-0.63,2.56c-0.21,0.03-0.47-0.89-1.24-2c-5.02,1.74-10.37,4.49-14.03,7.26
      c-2.05,1.54-3.8,3.85-3.42,6.39c0.38,2.45,3.7,3.53,5.84,4.03c5.71,1.31,12.21-0.56,17.76-1.79c7.58-1.69,16.98-4.57,23.24,0.97
      C595.57,158.31,596.53,156.52,597.11,155.29z"/>
    <path fill="#5E6112" d="M562.71,142.67c0.65-0.26,1.29-0.51,1.94-0.73c0.54,0.77,0.83,1.45,1.03,1.78
      c-0.37,0.2-0.72,0.43-1.08,0.66C563.98,143.8,563.34,143.23,562.71,142.67z"/>
    <path fill="#5E6112" d="M565.85,143.93c0.01,0,0.03,0.01,0.04,0.01c0.17-0.01,0.3-0.08,0.42-0.16
      c0.69-0.22,1.37-0.47,2-0.77c0.07,0.03,0.16,0.05,0.26,0.02c0.52-0.15,3.2-1.29,2.34-0.18c-0.38,0.49-0.83,0.93-1.31,1.33
      c-1.75,1.48-4.05,2.49-6.34,2.98c-1.24,0.27-2.51,0.48-3.79,0.44c-1.35-0.04-0.32-0.93,0.49-1.49c1.4-0.54,2.81-1.08,4.21-1.62
      C564.68,144.3,565.26,144.12,565.85,143.93z"/>
    <path fill="#5E6112" d="M560.1,158.05c3.57-0.47,6.96-1.49,10.39-2.52c3.16-0.95,6.61-2.15,9.97-2.18
      c4.43-0.05,8.88,1.58,12.17,4.37c-6.21-4.05-14.79-1.46-21.83,0.1c-5.55,1.23-12.04,3.1-17.76,1.79c-2.15-0.5-5.47-1.58-5.84-4.03
      c-0.09-0.63-0.06-1.24,0.08-1.83C549.82,157.43,555.85,158.62,560.1,158.05z"/>
    <path fill="#FFD1A6" d="M594.97,180.99c1.43-0.61,2.23,0.71,2.02,1.46c-0.15,0.54-0.45,0.93-0.56,0.5
      c-0.11-0.42-0.52-0.8-0.91-0.65c-0.39,0.15-0.61,0.66-0.68,1.02c-0.34,1.73,1.54,1.31,2.42,0.76c1.28-0.8,1.51-2.61,0.68-3.93
      c-0.51-0.81-2.51-1.96-4.22-1.11c-1.11,1.43-2.79,2.3-3.91,2.39c1.05,1.03,2.66,2.63,3.66,3.7
      C593.14,184.52,592.99,181.83,594.97,180.99z"/>
    <path fill="#D6A787" d="M561.87,183.35c-0.08-0.18-0.18-0.34-0.29-0.5c-0.14-1.68-0.61-3.5-1.44-5.14
      c0.96,0.41,1.76,1.15,2.02,1.73c0.43,0.95,0.89,4.7,0.89,5.77c0,0.36-0.66,1.42-1.25,1.4C562.22,185.7,562.27,184.28,561.87,183.35
      z"/>
    <path fill="#D6A787" d="M559.94,183.16c0.56,0.28,0.82,0.77,0.89,1.31c-0.12,0.42-0.37,0.85-0.89,0.87
      c-0.54,0.02-1.08-0.23-1.48-0.56c-0.37-0.32-0.78-0.77-0.77-1.27c0.01-0.31,0.17-0.53,0.4-0.71c-0.01,0.08-0.02,0.16-0.02,0.24
      c-0.05,1.37,1.44,1.62,1.21,1.28C559.06,183.99,558.53,182.46,559.94,183.16z"/>
    <path fill="#D6A787" d="M593.14,184.79c-1-1.04-2.4-2.43-3.34-3.36c1.12-0.09,2.8-0.96,3.91-2.39
      c0.15-0.07,0.29-0.12,0.44-0.17C593.07,180.6,592.71,182.82,593.14,184.79z"/>
    <path fill="#D6A787" d="M595.51,182.3c0.4-0.15,0.81,0.23,0.91,0.65c0.02,0.05,0.03,0.08,0.05,0.11
      c-0.13,0.54-0.54,1.03-0.97,1.49c-0.49-0.07-0.84-0.4-0.67-1.23C594.9,182.96,595.12,182.45,595.51,182.3z"/>
    <path fill="#CCD4D1" d="M581.73,170.35c0.43-0.69,1.33-1.12,2.69-1.1c1.82,0.03,3.65,1.77,4.24,3.54
      c0.81,2.44-1.09,4.5-3.18,4.18c0.35,0.41,0.63,0.73,0.87,1c4.36,0.26,5.17-3.97,3.53-6.97c-1.55-2.82-6.49-4.99-8.38-1.86
      C581.49,169.62,581.58,170.04,581.73,170.35z"/>
    <path fill="#FFFFFF" d="M584.22,167.21c2.28-0.07,4.48,1.5,5.69,3.11c1.44,1.91,1.56,4.3,0.56,6.36
      c-0.49,1.01-2.31,2.12-3.61,1.9c0.34,0.39,0.6,0.67,0.99,1.04c3.32,0.35,5.39-2.23,5.36-5.1c-0.02-2.44-0.77-4.81-2.34-6.69
      c-1.21-1.45-3.93-3.42-6.7-2.38c-1.58,0.59-2.4,1.85-2.65,2.93C582.08,167.7,583.05,167.25,584.22,167.21z"/>
    <path fill="#FFD1A6" d="M547.4,218.48c1.69-1.08,3.77-1.89,5.81-1.57c1.79,0.28,4.03,1.91,5.38-0.1
      c0.34-0.51,0.58-1.07,0.83-1.63c-2.48-0.81-4.43-2.06-4.35-4.59c0.13-4.53,6.82-6.97,9.4-10.06c0.32-0.38,1.01,0.4,0.09,1.6
      c-1.47,1.92-3.01,3.06-5.06,4.89c-1.3,1.16-3.1,2.91-1.79,4.67c1.59,2.15,5.39,2.33,7.89,2.85c3.65,0.76,5.69,2.78,7.64,5.63
      c1.04,1.52,2.11,2.86,3.85,3.73c2.62,1.31,3.47-0.15,3.16-1.84c-0.32-1.78-1.89-3.86-2.58-4.88c-0.33-0.49,1.24-0.53,2.4,0.96
      c0.06-1.87,1.99-3.89,3.23-5.19c-0.46-0.53-0.83-1.16-1.14-1.94c-1.1-2.78-1.92-4.92-4.97-5.21c-0.52-0.05,0.26,1.82-0.18,1.64
      c-0.88-0.35-1.36-0.93-1.71-1.82c-0.24-0.61-0.21-1.55,0.66-1.58c4.38-0.15,6.7,1.44,7.27,5.46c0.31,2.2,1.29,3.37,2.23,3.56
      c0.66,0.13,1.11-2.82,1.15-4.14c0.1-3.04-0.52-6.11-0.82-7.81c-2.76-2.16-7.01-5.3-9.19-8.39c-0.67,5.75-1.68,10.56-3.74,16.03
      c-0.43,1.14-1.15,1.62-1.1,1.38c1.32-6.41,2.82-12.58,3.83-19c-1.01-1.89-1.51-4.02-0.43-5.83c0.97-1.62,3.62-2.69,6.01-0.88
      c1.31,0.99,0.92,1.86,0.61,1.69c-3.03-1.69-5.11-0.11-4.54,2.71c0.3,1.5,2.15,3.92,3.73,5.2c0.78-1.74,0.12-3.65-0.95-2.96
      c-0.57,0.36,0.16-1.35,1.54-0.78c0.56,0.23,0.77,0.91,0.83,1.43c0.18,1.74-0.37,2.87-0.51,3.14c1.19,1.06,2.47,2.05,3.7,3.05
      c3.48,2.81,6.96,6.21,11.52,7.4c5.48,1.43,4.2-5.33,3-8.27c-1.34-3.28-3.55-6.38-5.96-9.02c-4.82-5.27-10.16-10.97-12.44-13.72
      c-0.18,2.32-1.39,5.56-4.4,7.02c0.79-0.14,1.58-0.28,2.36-0.43c0.17-0.03,0.25,0.19,0.1,0.27c-2.92,1.4-6.28,1.84-9.32,2.98
      c-0.24,0.09-0.43-0.23-0.22-0.38c0.85-0.6,1.79-1.03,2.76-1.37c-0.37-0.05-0.75-0.12-1.15-0.23c-4.73-1.32-7.52-6.66-8.19-10.84
      c-0.41-2.59,0.38-4.76,1.98-6.2c-2.82,0.23-4.49-0.99-3.29-1.49c1.77-0.74,4.38-1.23,6.17-1.63c2.38-0.54,4.7-0.82,7.15-0.75
      c0.42,0.01,0.6,0.55,0.16,0.7c-1.24,0.41-2.44,0.87-3.64,1.31c2.91,0.32,5.83,2.41,7.66,5.04c0.21-1.67,0.86-3.27,2.88-4.34
      c2.91-1.54,7.97-0.25,10.54,4.14c0.1-0.86,0.42-2.47,0.22-3.59c-2.45-1.83-5.36-3.06-8.52-3.88c-0.5-0.13-0.51-0.77-0.14-1.05
      c2.14-1.66,4.42-0.53,6.4,0.64c0.55,0.32,1.66,1.17,2.15,1.6c-0.04-0.6,0-1.29-0.25-1.81c-5.71-3.95-13.57-1.69-20-0.12
      c-4.25,1.04-8.89,2.39-13.48,2.78c2.56,5.3,4.89,19.03,5.19,21.31c0.31,2.29,0.7,7.14-0.05,9.38
      c-0.48,1.43-13.16,12.69-18.13,17.38C547.06,212.12,547.37,217.32,547.4,218.48z"/>
    <path fill="#D6A787" d="M574.59,188.22c0.12,0.99,0.49,1.99,0.99,2.92c-0.1,0.63-0.21,1.26-0.31,1.89
      C574.59,191.48,574.29,189.86,574.59,188.22z"/>
    <path fill="#D6A787" d="M576.33,194.95c0.1-0.72,0.19-1.46,0.28-2.21c2.18,3.08,6.43,6.23,9.19,8.39
      c0.12,0.67,0.28,1.56,0.43,2.56c-1.58-1.02-3.16-2.06-4.64-3.22C579.72,199.01,577.72,197.1,576.33,194.95z"/>
    <path fill="#D6A787" d="M576.68,213.64c1.4,0.63,2.51,0.39,3.28-0.38c-0.33,1.28-1.07,2.54-2.43,2.86
      c-1.41,0.34-2.76,0.01-3.25-1.39c-0.51-1.45-0.48-3.31,0.14-4.84C574.7,211.38,575.2,212.98,576.68,213.64z"/>
    <path fill="#D6A787" d="M581.68,174.3c0.38,0.46,0.86,1.01,1.39,1.62c-0.52,2.45-2.64,4.1-5.16,5.05
      C580.47,179.39,581.52,176.45,581.68,174.3z"/>
    <path fill="#D6A787" d="M574.22,163.76c-0.68-0.27-1.38-0.45-2.08-0.52c1.01-0.38,2.03-0.75,3.06-1.11
      C575.02,162.73,574.69,163.31,574.22,163.76z"/>
    <path fill="#D6A787" d="M586.98,161.23c2.37,0.79,4.56,1.84,6.46,3.27c0.07,0.37,0.07,0.78,0.05,1.2
      C591.23,164.33,589.12,162.77,586.98,161.23z"/>
    <path fill="#D6A787" d="M589.49,159.31c-1.05-0.47-2.14-0.74-3.22-0.47c-0.14,0-0.28-0.01-0.42-0.01
      c-3.19,0.12-6.41,0.57-9.5,1.29c-4.07,0.94-8.1,1.95-12.18,2.7c-0.15-0.08-0.34-0.07-0.47,0.09c-1.23,0.22-2.48,0.41-3.73,0.57
      c-0.12-0.29-0.24-0.57-0.37-0.83c4.59-0.39,9.22-1.74,13.48-2.78c6.44-1.57,14.29-3.83,20,0.12c0.12,0.24,0.16,0.52,0.2,0.81
      C592.03,160.22,590.78,159.68,589.49,159.31z"/>
    <path fill="#D6A787" d="M563.09,174.72c-0.17-0.8-0.34-1.6-0.54-2.41c-0.36-2.49-0.14-5,0.87-7.33
      c0.59,0.14,1.33,0.2,2.18,0.13c-1.6,1.44-2.4,3.61-1.98,6.2c0.67,4.18,3.47,9.52,8.19,10.84c0.4,0.11,0.78,0.18,1.15,0.23
      c-0.56,0.2-1.11,0.43-1.63,0.7C567.04,183.08,564.11,178.14,563.09,174.72z"/>
    <path fill="#D6A787" d="M547.77,209.63c0.15,0.61,0.3,1.21,0.42,1.82c0.35,1.66,0.61,3.32,0.84,5
      c0.05,0.37,0.1,0.73,0.14,1.1c-0.62,0.27-1.22,0.58-1.77,0.94c-0.03-1.16-0.35-6.37-0.8-7.76
      C546.95,210.39,547.35,210.02,547.77,209.63z"/>
    <path fill="#D6A787" d="M566.62,214.7c2.57,0.3,5.09,1.45,6.79,3.3c1.68,1.83,2.61,4.09,4.05,6.07
      c0.01,0.01,0.01,0.02,0.02,0.02c-0.13-0.05-0.26-0.11-0.39-0.18c-1.74-0.87-2.81-2.21-3.85-3.73c-1.89-2.76-3.89-4.74-7.34-5.55
      C566.13,214.65,566.38,214.67,566.62,214.7z"/>
    <path fill="#D6A787" d="M553.19,210.71c0.75-2.71,2.82-4.05,5.1-5.24c-1.75,1.45-3.15,3.07-3.21,5.11
      c-0.07,2.54,1.87,3.78,4.35,4.59c-0.26,0.55-0.49,1.11-0.83,1.63c-0.25,0.36-0.51,0.61-0.81,0.76
      C554.8,216.04,552.33,213.82,553.19,210.71z"/>
    <path fill="#D6A787" d="M570.35,198.93c0.09-1.05,0.12-2.39,0.84-3.26c0.53-0.64,1.26-1.01,2.01-1.37
      c-0.38,2.04-1.02,4.03-1.35,6.09c-0.22,1.38-0.42,2.77-0.61,4.16C570.12,202.99,570.2,200.68,570.35,198.93z"/>
    <path fill="#CCD4D1" d="M572.1,179.9c1.19,0.8,2.88,0.61,3.86-0.05c0.8-0.66,2.9-3.59,0.64-7
      c-2.14-3.23-7.43-4.26-9.11-3.12c-0.96,0.65-1.49,1.28-1.74,2.26c0.15,0.69,0.41,1.37,0.79,2c0.49-1.52,1.6-2.43,3.38-2.41
      c2.18,0.03,3.91,1.68,4.64,3.36C575.66,177.49,574.36,179.79,572.1,179.9z"/>
    <path fill="#A6B5B0" d="M588.05,176.03c0.56,0.23,1.12,0.48,1.66,0.75c-0.7,0.79-1.8,1.28-3.37,1.19
      c-0.24-0.27-0.51-0.6-0.87-1C586.47,177.13,587.42,176.74,588.05,176.03z"/>
    <path fill="#A6B5B0" d="M588.83,173.91c0.61,0.01,1.22,0.03,1.83,0.07c0,0.8-0.16,1.58-0.52,2.22
      c-0.55-0.26-1.1-0.52-1.66-0.76C588.71,174.99,588.83,174.47,588.83,173.91z"/>
    <path fill="#A6B5B0" d="M583,167.86v1.58c-0.59,0.18-1.02,0.49-1.27,0.91c-0.14-0.31-0.23-0.73-0.23-1.21
      C581.88,168.5,582.41,168.09,583,167.86z"/>
    <path fill="#A6B5B0" d="M589.99,171.25c0.32,0.65,0.53,1.33,0.61,2.02c-0.62-0.04-1.24-0.05-1.86-0.07
      c-0.03-0.14-0.05-0.27-0.09-0.41c-0.09-0.28-0.22-0.56-0.37-0.83C588.85,171.71,589.43,171.49,589.99,171.25z"/>
    <path fill="#A6B5B0" d="M574.78,177.86c0.79,0.1,1.59,0.13,2.36,0.31c-0.37,0.83-0.87,1.43-1.18,1.68
      c-0.97,0.66-2.67,0.85-3.86,0.05C573.41,179.83,574.4,179.02,574.78,177.86z"/>
    <path fill="#A6B5B0" d="M574.89,176.08c0.91-0.1,1.81-0.21,2.72-0.21c0.01,0.6-0.07,1.15-0.22,1.65
      c-0.81-0.16-1.65-0.18-2.46-0.31C574.97,176.86,574.96,176.48,574.89,176.08z"/>
    <path fill="#A6B5B0" d="M576.81,173.2c0.39,0.68,0.63,1.34,0.73,1.97c-0.94,0.01-1.88,0.12-2.82,0.23
      c-0.05-0.16-0.1-0.31-0.17-0.47c-0.08-0.18-0.18-0.36-0.28-0.54C575.12,174,575.96,173.59,576.81,173.2z"/>
    <path fill="#A6B5B0" d="M566.88,170.18c0.23,0.71,0.49,1.41,0.78,2.1c-0.52,0.41-0.89,0.99-1.12,1.71
      c-0.37-0.64-0.63-1.31-0.79-2C565.93,171.24,566.3,170.69,566.88,170.18z"/>
    <path fill="#FFFFFF" d="M576.47,171.98c1.58,1.9,2,4.64,1.1,6.77c1.61-1.55,2.42-4.01,2.26-5.84
      c-0.44-4.91-5.31-8.22-10.03-7.66c-2.89,0.34-4.36,2.95-4.19,5.67C567.49,167.27,573.9,168.9,576.47,171.98z"/>
    <path fill="#FFD1A6" d="M605.2,269.99c0.1,0.77,0.11,1.81,0.06,2.82c0.3-0.23,0.66-0.29,0.97-0.2
      C606.01,271.74,605.64,270.83,605.2,269.99z"/>
    <path fill="#FFE8CC" d="M606.2,275.37c0.29-0.67,0.29-1.51,0.11-2.41c-0.42-0.12-0.9,0.09-1.1,0.52
      c-0.11,1.14-0.3,2.14-0.61,2.57C605.1,276.13,605.84,276.19,606.2,275.37z"/>
    <path fill="#FFE8CC" d="M601.9,275.4c0.01,0.23,0.1,0.5,0.14,0.75c0.26,0.19,0.53,0.25,0.81,0.08
      c0.76-0.44,1.19-1.15,1.34-2.69c0.03-0.25,0.05-0.61,0.06-1.04c-0.21-0.28-0.48-0.53-0.84-0.65c-0.97-0.34-1.33,1.11-1.44,1.69
      C601.85,274.16,601.86,274.78,601.9,275.4z"/>
    <path fill="#FFD1A6" d="M602.15,271.96c0.68-0.88,1.54-0.52,2.1,0.13c0.03-2.83-0.43-7.86-3.11-7.69
      c0.1,1.99-0.43,3.78-0.91,5.69c0.1,1.14,0.17,2.27,0.39,3.39c0.07,0.36,0.34,1.14,0.71,1.8c0.01-0.23,0.02-0.44,0.03-0.6
      C601.38,273.73,601.55,272.75,602.15,271.96z"/>
    <path fill="#8F8F8C" d="M596.26,340.71c0.36,1.86,4.26,1.34,4.39,0.88c0.13-0.46,0.33-4.32,0.52-6.31
      c-1.15,0.45-3.18,0.43-4.32-0.18C596.56,337.32,596.16,340.2,596.26,340.71z"/>
    <path fill="#6F6F6B" d="M600.28,335.5c0.33-0.05,0.64-0.12,0.9-0.22c-0.2,2-0.4,5.86-0.52,6.31
      c-0.04,0.14-0.46,0.29-1.02,0.38c0.21-1.55,0.39-3.09,0.43-4.64C600.13,336.72,600.21,336.11,600.28,335.5z"/>
    <path fill="#6F6F6B" d="M596.85,335.1c0.31,0.16,0.68,0.28,1.08,0.36c-0.31,2.11-0.38,4.25-0.46,6.38
      c-0.6-0.18-1.09-0.53-1.21-1.13C596.16,340.2,596.56,337.32,596.85,335.1z"/>
    <path fill="#A67040" d="M599.28,290.23c0.43,6.12,0.24,12.42-0.02,18.54c-0.38,9.21-1.52,16.29-2.18,25.01
      c0.81,0.43,2.91,0.64,4.09,0.32c0.9-10.12,0.65-17.55,0.78-27.68c0.08-5.95-0.02-11.9-0.1-17.84c-0.07-4.98-0.53-10.04-2.76-14.62
      c-0.58,1.49-1.23,2.93-2.46,3.62C598.54,281.29,598.97,285.81,599.28,290.23z"/>
    <path fill="#FFE8CC" d="M596.34,275.75c0.4-0.48,1.06-1.43,1.64-2.63c-0.32-0.67-0.95-1.12-1.77-0.84
      c-0.68,0.23-1.07,1.02-1.35,1.58c-0.32,0.63-0.44,1.41-0.73,2.07C594.7,276.6,595.54,276.73,596.34,275.75z"/>
    <path fill="#FFD1A6" d="M593.68,275.17c0.13-0.36,0.29-0.71,0.41-1.03c0.31-0.77,0.74-1.55,1.41-2.11
      c0.94-0.77,2.33-0.29,2.67,0.69c0.18-0.41,0.35-0.85,0.5-1.3c0.62-1.97,1.24-6.12,1.15-7.66c-0.15-2.43-1.19-2.86-3.41-2.87
      c0.92,1.95,1.07,4.21,0.28,6.57c-0.4,1.21-1.57,3.25-3.08,5.08C593.32,273.47,593.39,274.43,593.68,275.17z"/>
    <path fill="#FFE8CC" d="M589.09,274.21c0.82-0.47,1.55-0.99,2.21-1.56c-0.07-0.87-0.67-1.58-1.81-1.38
      c-0.54,0.1-1.06,0.39-1.52,0.64c-0.62,0.33-1.29,1.01-1.98,1.34C586.2,274.44,587.21,275.29,589.09,274.21z"/>
    <path fill="#A67040" d="M578.78,281.48c0.18,1.1,4.11,0.84,3.92,0.24c-0.82-2.6-1.86-13.66,3.29-12.17
      c1.2-0.9,2.72-1.57,3.94-2.65c-1.49,0.2-3.37-0.26-5.23-0.95C577.74,266.86,578.11,277.39,578.78,281.48z"/>
    <path fill="#7A4219" d="M598.2,279.69c-0.2,0.07-0.4,0.15-0.59,0.24c-0.27-0.81-0.58-1.6-0.97-2.34
      c1.23-0.69,1.88-2.12,2.46-3.62c0.88,1.8,1.47,3.67,1.89,5.59C600.15,279.34,599.22,279.35,598.2,279.69z"/>
    <path fill="#7A4219" d="M598.09,281.52c-0.08-0.3-0.16-0.6-0.25-0.9c0.1-0.04,0.18-0.09,0.28-0.13
      c1.12-0.47,2.13-0.47,3.02-0.17c0.07,0.39,0.12,0.77,0.18,1.15C600.27,281.21,599.14,281.23,598.09,281.52z"/>
    <path fill="#7A4219" d="M598.58,283.86c-0.1-0.56-0.2-1.13-0.33-1.68c1.03-0.29,2.17-0.28,3.19,0.06
      c0.03,0.27,0.06,0.53,0.09,0.8C600.51,283.03,599.49,283.32,598.58,283.86z"/>
    <path fill="#7A4219" d="M600.09,315.56c-0.61,5.65-1.2,11.49-2.91,16.99c0.66-8.16,1.71-15.01,2.08-23.77
      c0.14-3.25,0.25-6.55,0.27-9.85c0.26,1.78,0.5,3.56,0.67,5.34C600.54,308.04,600.5,311.79,600.09,315.56z"/>
    <path fill="#7A4219" d="M584.76,269.44c-0.18-0.29-0.42-0.56-0.74-0.85c-0.72-0.62-1.85-0.82-2.79-0.66
      c-0.11,0.02-0.22,0.05-0.32,0.07c0.92-1.08,2.15-1.84,3.81-2.06c1.86,0.69,3.74,1.15,5.23,0.95c-1.21,1.08-2.74,1.75-3.94,2.65
      C585.53,269.41,585.12,269.39,584.76,269.44z"/>
    <path fill="#7A4219" d="M583.45,270.15c-0.84-0.84-2.25-1.11-3.56-0.6c0.12-0.23,0.24-0.46,0.38-0.67
      c0.58-0.22,1.2-0.36,1.8-0.29c0.95,0.1,1.56,0.53,1.9,1.13C583.77,269.84,583.6,269.98,583.45,270.15z"/>
    <path fill="#7A4219" d="M582.31,272.78c-0.63,3.1-0.09,7.44,0.39,8.94c0.09,0.27-0.69,0.48-1.57,0.51
      C580.54,279.01,580.63,275.39,582.31,272.78z"/>
    <path fill="#FFE8CC" d="M590.97,264.84c0.4-0.32,0.93-2.31-0.2-3.23c-1.82-0.57-2.98-0.6-3.49-0.42
      c-0.6,0.21-0.54,1-0.5,1.47C586.89,263.88,588.49,264.98,590.97,264.84z"/>
    <path fill="#FFD1A6" d="M578.98,261.42c2.12,1.06,5.32,3.03,8.43,3.33c-0.95-0.5-1.32-1.5-1.29-2.55
      c0.07-2.12,2.2-1.84,3.91-1.2c-1.18-1.48-6.17-2.57-10.24-2.76c-0.2-0.01-0.16-0.31,0-0.38c2.44-0.97,6.36,0.07,8.67,0.94
      c2.34,0.88,4.73,2.35,4.35,4.99c-0.21,1.49-0.87,2.35-1.8,2.79c-0.77,1.67-2.17,2.82-4.03,3.97c-0.59,0.37-0.94,1.08-1.03,1.81
      c0.21-0.13,0.42-0.25,0.62-0.38c0.96-0.62,2.02-1.08,3.18-1.24c1.18-0.17,1.79,0.77,1.8,1.69c2-1.77,3.3-3.93,3.94-6.13
      c2.34-7.99-11.53-13.89-18.04-10.18C578.04,257.44,578.69,259.72,578.98,261.42z"/>
    <path fill="#D6A787" d="M605.23,270.31c0.16,0.76,0.31,1.53,0.45,2.3c-0.14,0.04-0.29,0.1-0.42,0.2
      C605.31,271.93,605.3,271.04,605.23,270.31z"/>
    <path fill="#D6A787" d="M601.14,264.4c0.01,0,0.02,0.01,0.03,0c0.19,2.79,0.12,5.6,0.54,8.37c-0.24,0.6-0.34,1.26-0.35,1.91
      c0,0.15-0.02,0.36-0.03,0.6c-0.37-0.66-0.64-1.45-0.71-1.8c-0.22-1.12-0.29-2.25-0.39-3.39
      C600.71,268.18,601.25,266.4,601.14,264.4z"/>
    <path fill="#D6A787" d="M596.4,260.9c0.21,0,0.41,0.01,0.6,0.02c0.7,0.94,0.74,2.5,0.72,3.49c-0.04,1.67-0.35,3.31-1,4.86
      c-0.49,1.16-1.1,2.26-1.77,3.33c-0.19,0.24-0.36,0.5-0.5,0.76c-0.3,0.45-0.6,0.88-0.92,1.32c-0.15-0.65-0.16-1.4,0.07-2.13
      c1.51-1.84,2.68-3.88,3.08-5.08C597.47,265.11,597.32,262.85,596.4,260.9z"/>
    <path fill="#D6A787" d="M585.74,262.34c0.05-0.51,0.28-0.84,0.62-1.06c-0.13,0.24-0.21,0.53-0.22,0.92
      c-0.04,1.05,0.34,2.05,1.29,2.55c-0.31-0.03-0.61-0.07-0.91-0.13C586.02,263.96,585.66,263.14,585.74,262.34z"/>
    <path fill="#D6A787" d="M578.42,255.68c0.82,2.03,1.25,4.05,1.27,6.11c-0.24-0.12-0.48-0.25-0.7-0.36
      c-0.3-1.7-0.94-3.98-1.53-5.29C577.76,255.96,578.08,255.81,578.42,255.68z"/>
    <path fill="#D6A787" d="M593.54,265.41c-0.98,2.32-3.19,3.42-5.46,4.43c1.32-0.93,2.32-1.93,2.94-3.25
      c0.94-0.43,1.59-1.3,1.8-2.79c0.11-0.78-0.02-1.46-0.32-2.05C593.52,262.76,594.14,264,593.54,265.41z"/>
    <path fill="#B0822B" d="M554.53,262.53c2.65,2.4,6.19,3.55,9.82,3.95c4.08,0.44,11.23-0.59,11.99-0.67
      c0.76-0.07,1.27-2.2,0.94-4.59c-0.21-1.46-0.86-4.66-1.64-5.71c-4.94,2.58-11.99,5.62-17.21,2.63c-0.18,0.61-0.49,1.19-0.95,1.67
      c-0.13,0.14-0.48,0.05-0.38-0.14c0.43-0.8,1.12-2.72,0.27-3.18c-0.85-0.45-1.6,0.53-1.9,1.13c-0.12,0.24-0.52-0.92,0.99-2.06
      c0.01,0,0.02-0.01,0.03-0.02c-0.42-0.47-2.3-0.93-3.8,1.13c-0.12,0.16-0.52-1.22,1-2.12c-3.72-2.48-4.37-8.8-4.78-12.61
      c-0.34-3.11-1.05-5.97-1.62-6.56c0,5.34,0.68,12.08,1.46,16.21C549.58,255.09,551.76,260.03,554.53,262.53z"/>
    <path fill="#B0822B" d="M548.72,259.11c-2.29-6.44-2.79-17.83-3.11-27.49c-0.16-4.68,0.09-9.21,0-13.9
      c-0.08-3.66-0.56-7.82-1.09-8.38c-0.52-0.56-4.41,2.75-4.69,3.32c-0.79,1.61-0.51,8.7-0.47,11.33c0.05,3.73,0.37,7.44,0.62,11.15
      c0.55,8.21,0.69,16.18,3.46,24.11c1.96,5.58,6.35,10.03,8.97,15.27C551.97,269.79,550.28,263.5,548.72,259.11z"/>
    <path fill="#C7A64D" d="M548.72,259.11c0.95,2.66,1.95,6.03,2.68,9.33c-0.82-1.71-1.7-3.39-2.36-5.16
      c-0.76-2.04-1.21-4.2-1.68-6.32c-1.03-4.69-1.82-9.41-2.43-14.16c-0.58-4.58-0.6-9.09-0.56-13.7c0.03-4.59,0.06-9.31-0.21-13.9
      c-0.04-0.64-0.11-3.92-0.97-5.41c0.63-0.4,1.15-0.63,1.32-0.45c0.53,0.56,1.01,4.72,1.09,8.38c0.09,4.68-0.16,9.21,0,13.9
      C545.92,241.28,546.42,252.67,548.72,259.11z"/>
    <path fill="#754720" d="M553.41,325.57c-0.63-0.03,0.55-1.03,3.05-0.87c0.69-2.75,1.18-5.54,2.08-8.24
      c1.18-3.55,3.7-7.33,4.24-10.4c-0.88,0.13-1.63-0.68-1.22-0.79c2.1-0.58,3.32-1.83,3.71-2.23c0.58-0.6,0.66-1.42,0.3-2.14
      c-0.38-0.77-2.25-1.41-4.8-0.87c-0.88,0.18,0.13-1.09,0.66-1.39c-2.38-0.68-4.71-1.76-6.61-3.16c-2.45-1.78-4.35-4.58-7.06-5.93
      c-2.15,0.63-4.25,1.37-6.36,2.12c3.89,3.81,10.96,8.07,16.64,10.57c0.97-0.37,2.25,0.01,1.61,0.13c-1.16,0.22-3.54,1.51-2.48,2.37
      c1.51-0.93,2.17-0.4,2.03-0.29c-2.53,2.03-4.4,3.79-5.75,6.71c-1.02,2.23-1.56,4.68-2.16,7.02c-0.9,3.52-1.97,6.9-4.06,9.87
      c4.13-0.74,8.55-0.67,12.66-0.03C560.35,326.67,557.19,325.74,553.41,325.57z"/>
    <path fill="#9E9E9E" d="M565.78,344.13c8.45,0.5,21.37-0.67,23.38-1.45c0.03-0.58,0.03-1.19-0.03-1.8
      c-3.39,1.48-18.04,2.07-23.18,1.44c-0.11,0.24-0.4,0.55-0.49,0.61C565.63,343.24,565.71,343.8,565.78,344.13z"/>
    <path fill="#9E9E9E" d="M533.32,344.94c5.98,0.9,25.11,1.61,31.39-0.15c0-0.59,0-1.24-0.09-1.75
      c-10.27,2.48-23.01,0.77-33.69-0.13C531.35,343.58,532.45,344.52,533.32,344.94z"/>
    <path fill="#9E9E9E" d="M518.12,344.36c2.86,0.51,7.68,0.76,11.15,0.48c0.08-0.49,0.03-1.58,0-2.09
      c-2.87,0.07-8.26-0.26-10.67-0.87C518.29,342.53,518.09,343.71,518.12,344.36z"/>
    <path fill="#7D7D7D" d="M577.3,343.52c3.87-0.02,8.18,0.04,11.87-1.33c0,0.17,0,0.33-0.01,0.49
      c-2.01,0.78-14.93,1.95-23.38,1.45c-0.07-0.33-0.15-0.89-0.31-1.19c0.03-0.02,0.07-0.06,0.12-0.12
      C569.22,343.97,573.65,343.54,577.3,343.52z"/>
    <path fill="#7D7D7D" d="M548.99,345.34c4.96-0.13,10.91-0.21,15.56-2.28c0.02,0,0.04-0.01,0.07-0.02
      c0.09,0.51,0.09,1.16,0.09,1.75c-6.28,1.76-25.42,1.05-31.39,0.15c-0.86-0.42-1.97-1.35-2.38-2.03c0.44,0.04,0.89,0.08,1.34,0.12
      C537.58,344.93,543.34,345.49,548.99,345.34z"/>
    <path fill="#7D7D7D" d="M522.09,343.69c2.18,0.37,4.27,0.08,6.2-0.78c0.14-0.05,0.29-0.1,0.43-0.15c0.19,0,0.37,0,0.55-0.01
      c0.03,0.52,0.08,1.6,0,2.09c-3.47,0.28-8.29,0.03-11.15-0.48c-0.03-0.49,0.08-1.3,0.28-1.94
      C519.47,343.14,520.96,343.5,522.09,343.69z"/>
    <path fill="#AB8A52" d="M573.29,333.64c-4.19,0.13-10.55-0.52-13.44-3.79c-3.97-0.37-7.94-0.65-11.86-0.07
      c-1.27,0.8-2.34,2.25-2.53,3.2c3.85-0.53,8.56-0.7,12.21,0.25c3.61,0.94,6.02,2.22,7.87,5.27c0.44,0.72,0.79,1.9,0.72,2.66
      c3.1,0.22,7.34,0.36,10.84,0c-0.71-1.98,1.12-6.8,4.15-8.89C578.59,332.55,575.79,333.56,573.29,333.64z"/>
    <path fill="#734D29" d="M551.97,342.81c3.97-0.04,11.05-0.49,12.12-1.22c1.07-0.74-1.56-6.34-8.5-7.16
      C554.28,335.55,551.1,339.85,551.97,342.81z"/>
    <path fill="#734D29" d="M582.42,332.19c-3.59,2.65-4.71,6.69-4.23,8.93c3.82,0.09,8.88-0.48,11.15-1.27
      C590.96,335.32,587.26,331.88,582.42,332.19z"/>
    <path fill="#5C3917" d="M553.21,337.5c0.52,1.35,1.38,2.52,2.85,3.12c2.4,0.99,5.66,0.92,8.17-0.15
      c0.14,0.54,0.09,0.96-0.14,1.13c-1.07,0.74-8.15,1.19-12.12,1.22C551.49,341.2,552.23,339.18,553.21,337.5z"/>
    <path fill="#5C3917" d="M579.24,335.86c0.19,0.79,0.58,1.52,1.28,2.1c2.19,1.85,5.92,2.18,8.98,1.33
      c-0.05,0.19-0.09,0.37-0.16,0.56c-2.27,0.79-7.33,1.36-11.15,1.27C577.91,339.79,578.2,337.8,579.24,335.86z"/>
    <path fill="#AB8A52" d="M519.12,340.63c2.46,0.55,6.57,0.87,9.94,0.98c0.02-0.32,0.43-3.25,0.63-3.59
      c0.35-0.63,1.09,2.54,1.12,2.96c3.99,0.38,7.98,0.82,11.98,1.18c3.77,0.34,6.41,0.59,8.04,0.59c-0.76-1.23,0.5-5.9,3.5-8.43
      c-3.22-0.44-6.12-0.24-9.33,0.27c-3.22,0.51-9.8,1.52-10.78-2.34c-3.29,0.23-6.71-0.11-9.96,0.45
      C521.74,334.68,519.84,337.8,519.12,340.63z"/>
    <path fill="#855E33" d="M547.31,332.78c-0.63,0.06-1.26,0.13-1.86,0.21c0.19-0.95,1.26-2.4,2.53-3.2
      c3.92-0.58,7.9-0.3,11.86,0.07c0.09,0.1,0.19,0.19,0.29,0.29C555.65,330.2,551.04,330.57,547.31,332.78z"/>
    <path fill="#855E33" d="M565.88,336.8c1.14,1.35,1.87,2.87,2.28,4.48c-0.66-0.04-1.31-0.07-1.9-0.11
      c0.07-0.76-0.28-1.94-0.72-2.66c-1.85-3.05-4.26-4.33-7.87-5.27c-0.18-0.05-0.37-0.09-0.56-0.13
      C560.43,333.21,563.9,334.46,565.88,336.8z"/>
    <path fill="#855E33" d="M522.07,334.9c0.66-0.82,1.39-1.57,2.19-2.21c3.25-0.56,6.67-0.21,9.96-0.45
      c0.05,0.22,0.14,0.43,0.23,0.63C530.26,333.1,526.12,333.84,522.07,334.9z"/>
    <path fill="#855E33" d="M540.17,340.84c1.85,0.38,3.66,0.85,5.48,1.31c0.68,0.17,1.38,0.29,2.07,0.44
      c-1.35-0.1-2.99-0.25-4.92-0.43c-4-0.36-7.99-0.8-11.98-1.18c0-0.02-0.01-0.05-0.01-0.08C533.6,339.35,537.05,340.2,540.17,340.84z
      "/>
    <path fill="#754720" d="M529.74,300.3c2.43,1.5,5.56,2.59,7.51,4.66c1.17-0.34,1.78,0.11,1.55,0.21
      c-2.6,1.14-2.87,2.33-1.96,2.61c1.78-1.4,2.62-0.67,2.38-0.48c-3.34,2.56-4.12,5.52-5.96,9c-1.21,2.28-2.64,4.5-4.24,6.55
      c-1.23,1.56-2.3,3.26-2.64,4.88c0.67-0.12,2.86,0.5,2.36,0.57c-1.96,0.31-4.45,0.62-4.37,2.31c4.29-0.6,8.8,0.53,12.94-0.58
      c-1.98-2.52-3.01-4.25-0.71-7.55c1.42-2.03,3.05-3.77,4.32-5.91c1.26-2.14,1.3-4.36,1.61-6.76c-0.82,0.17-1.77-0.68-1.28-0.81
      c2.35-0.63,4.71-1.56,5.11-3.07c0.4-1.5-1.69-2.19-5.38-1.75c-0.2,0.02,1.1-1.27,1.56-1.36c-1.32-1.2-2.53-2.51-3.95-3.63
      c-1.96-1.53-3.88-3.12-5.47-4.99c-1.52,0.32-6.81,1.64-9.8-0.14C524.85,296.72,527,298.6,529.74,300.3z"/>
    <path fill="#593300" d="M555.07,324.75c0.57-1.19,1.3-2.27,2.15-3.28c-0.25,1.08-0.49,2.16-0.76,3.24
      C555.94,324.67,555.48,324.7,555.07,324.75z"/>
    <path fill="#593300" d="M552.7,317.93c-0.59,2.08-1.7,3.86-3.06,5.5c0.67-1.69,1.18-3.45,1.64-5.26
      c0.53-2.05,1-4.19,1.8-6.18c-0.02,0.19-0.04,0.38-0.05,0.57C552.98,314.37,553.2,316.16,552.7,317.93z"/>
    <path fill="#593300" d="M556.5,301.53c-2.75-1.3-5.71-2.95-8.42-4.71C551.53,296.66,554.95,298.62,556.5,301.53z"/>
    <path fill="#593300" d="M558.95,327.88c-3.7-0.49-7.58-0.51-11.28,0.09C550.87,325.87,555.72,325.83,558.95,327.88z"/>
    <path fill="#593300" d="M547.75,289.55c0.57,0.28,1.1,0.64,1.61,1.03c-2.11,0.7-4.05,1.9-5.26,3.43
      c-1-0.79-1.92-1.57-2.7-2.34C543.5,290.93,545.6,290.19,547.75,289.55z"/>
    <path fill="#593300" d="M527.71,298.92c-1.8-1.36-3.26-2.89-4.39-4.85c2.99,1.78,8.28,0.46,9.8,0.14
      c0.7,0.82,1.47,1.58,2.28,2.32C532.96,297.57,530.33,298.24,527.71,298.92z"/>
    <path fill="#593300" d="M533.24,326.63c-0.82-3.51,2.75-4.91,5.22-6.59c-0.63,0.8-1.26,1.6-1.86,2.46
      c-2.3,3.3-1.27,5.02,0.71,7.55c-0.26,0.07-0.52,0.13-0.78,0.18C534.93,329.34,533.61,328.23,533.24,326.63z"/>
    <path fill="#593300" d="M528.6,328.14c-0.49-0.2-1.75-0.49-2.23-0.4c0.24-1.16,0.86-2.35,1.63-3.5
      C528.49,325.5,528.77,326.8,528.6,328.14z"/>
    <path fill="#593300" d="M536.84,307.78c-0.76-0.23-0.69-1.09,0.84-2.03c0.34-0.03,0.7,0.01,0.94,0.25
      c0.25,0.26,0.31,0.61,0.28,0.95C538.52,306.88,537.82,307,536.84,307.78z"/>
    <path fill="#593300" d="M536.31,304.11c-1.48-1.19-3.35-2.05-5.08-2.97C533.54,300.84,535.91,301.75,536.31,304.11z"/>
    <path fill="#B0822B" d="M513.39,298.22c2.7-0.8,6.72-2.5,10.5-9.51c2.5-4.63,3.83-11.34,4.67-16.43
      c2.23-13.54,1.41-28.67,7.72-41.3c0.44-0.88,0.37,0.77-0.07,2.37c-1.91,6.82-3.04,13.53-3.82,20.61
      c-0.82,7.42-1.49,14.87-2.92,22.21c-1.05,5.38-3.03,13.12-5.94,16.28c1.75,1.06,6.43,0.91,9.03,0.43c2.32-0.42,4.56-1.17,6.77-2.01
      c0.09-0.05,0.17-0.08,0.27-0.1c1.57-0.6,3.13-1.24,4.67-1.84c2.08-0.79,4.17-1.36,6.33-1.92c1.27-0.33,3.63-0.53,4.54-1.43
      c1.86-1.83-3.13-1.76-4.16-1.89c-0.53-0.06,0.45-1.01,2.65-0.94c-0.33-1.92-0.59-4.22-1.1-6.43c-3.76-5.9-8.36-11.18-10.75-17.79
      c-2.77-7.67-2.73-15.69-3.26-23.66c-0.31-4.65-0.67-9.3-0.58-13.96c0.03-1.72-0.06-4.28,0.47-6.47c-0.53,0.54-1.06,1.11-1.58,1.69
      c-2.64,2.91-5.66,5.53-8.52,8.24c-6.44,6.12-13.08,12.03-18.04,19.24c-4.33,6.29-4.23,14.7-4.36,21.96
      c-0.06,3.6-0.47,7.21,0.22,10.76c0.78,3.99,2.69,6.85,3.26,7.58c0.14,0.18-2.19-0.57-3.64-3.94c-2.63,3.33,2.55,6.35,5.66,7.93
      c11.54-5.79,11.49-22,12.65-32.6c0.66-5.96,1.62-11.83,3.55-17.55c1.6-4.76,3.48-8.76,6.83-11.56c2.09-1.75,3.14-1.67,2.43-0.96
      c-4.38,4.4-6.64,8.5-8.57,16.58c-1.55,6.45-2.18,13.07-2.81,19.64c-0.97,10.04-3.14,23.72-14.26,28.44
      C511.78,290.94,513.49,296.64,513.39,298.22z"/>
    <path fill="#8A5907" d="M552.58,253.57c0.34,0.36,0.7,0.7,1.11,0.97c-0.71,0.42-1,0.95-1.09,1.38
      C552.31,255.19,552.28,254.3,552.58,253.57z"/>
    <path fill="#8A5907" d="M557.48,259.81c0.46-0.48,0.76-1.06,0.94-1.67c0.55,0.31,1.12,0.55,1.7,0.74
      c-0.17,0.36-0.38,0.71-0.69,0.96C558.85,260.3,557.96,260.34,557.48,259.81z"/>
    <path fill="#8A5907" d="M555.33,257.32c-0.98,0.34-1.25-1.47-0.89-2.04c0.95-0.32,1.78-0.03,2.04,0.26
      c-0.01,0.01-0.02,0.01-0.03,0.02C555.56,256.23,555.34,256.92,555.33,257.32z"/>
    <path fill="#8A5907" d="M549.39,245.72c-0.64-3.37-1.36-6.72-1.92-10.09c0.53,0.94,1.13,3.52,1.43,6.3
      c0.13,1.19,0.28,2.61,0.54,4.09C549.42,245.92,549.4,245.82,549.39,245.72z"/>
    <path fill="#8A5907" d="M548.96,247.47c0.61,2.98,1.3,5.99,2.62,8.78c1.39,2.94,3.39,5.63,6.11,7.62
      c0.96,0.7,1.98,1.37,3.06,1.92c-2.31-0.63-4.47-1.67-6.23-3.26c-2.77-2.5-4.95-7.44-5.78-10.96c-0.58-3.08-1.11-7.6-1.34-11.92
      C547.9,242.26,548.43,244.87,548.96,247.47z"/>
    <path fill="#8A5907" d="M539.83,212.66c0.08-0.17,0.48-0.57,1-1.04c0.04,2.41,0.02,4.83,0.17,7.22
      c0.09,1.45,0.46,2.84,0.7,4.27c0.31,1.82-0.01,3.43-0.33,5.22c-0.49,2.83,0.91,5.58,0.73,8.43c-0.18,2.93-0.54,5.56,0.1,8.46
      c0.61,2.76,0.11,5.39,1.1,8.09c0.98,2.68,0.91,5.92,2.46,8.37c0.96,1.51,1.75,3.03,2.47,4.65c0.54,1.2,1.33,2.26,1.77,3.51
      c0.21,0.58,0.43,1.12,0.67,1.65c-2.53-4.01-5.67-7.76-7.24-12.24c-2.77-7.93-2.92-15.9-3.46-24.11c-0.25-3.72-0.57-7.43-0.62-11.15
      C539.32,221.37,539.04,214.27,539.83,212.66z"/>
    <path fill="#8A5907" d="M532.39,253.95c0.6-5.38,1.39-10.54,2.57-15.7c-0.11,1.27-0.2,2.54-0.35,3.81
      c-0.43,3.65-0.96,7.31-1.16,10.98c-0.31,5.58-0.36,11.17-0.95,16.74c-0.85,7.9-2.51,16.35-6.86,23.3
      c-0.86-0.13-1.61-0.33-2.11-0.63c2.91-3.16,4.9-10.9,5.94-16.28C530.9,268.82,531.57,261.37,532.39,253.95z"/>
    <path fill="#8A5907" d="M519.73,278.84c-1.75,3.82-4.35,7.06-8.33,9.05c-0.51-0.26-1.07-0.56-1.65-0.89
      C514.41,285.86,517.55,282.77,519.73,278.84z"/>
    <path fill="#8A5907" d="M508.28,283.36c-1.28,0.08-2.36-0.46-3.25-1.3c-0.06-0.68,0.13-1.38,0.7-2.09
      C506.51,281.74,507.52,282.79,508.28,283.36z"/>
    <path fill="#8A5907" d="M543.8,266.61c-2.8-5.45-4.69-11.02-5.23-17.05c-0.52-5.77-1.03-11.58-1.42-17.37
      c-0.15-2.27-0.29-4.59-0.34-6.93c0.01-0.02,0.03-0.03,0.04-0.04c0.32-0.32,0.28-0.51-0.04-0.47c-0.04-3.11,0.09-6.23,0.59-9.25
      c0.33-0.36,0.66-0.72,1-1.07c-0.53,2.19-0.43,4.75-0.47,6.47c-0.09,4.66,0.27,9.31,0.58,13.96c0.53,7.97,0.49,16,3.26,23.66
      c2.24,6.2,6.42,11.23,10.03,16.7C548.54,272.9,545.73,270.34,543.8,266.61z"/>
    <path fill="#8A5907" d="M542.78,285.49c-1.6,0.39-3.15,0.94-4.81,1.37c-2.53,0.66-5.1,1.16-7.68,1.57
      c1.98-1.38,3.99-2.8,6.23-3.74c2.9-1.21,5.55-1.17,8,0.15C543.95,285.07,543.37,285.29,542.78,285.49z"/>
    <path fill="#FFE8CC" d="M506.83,304.89c0.25,0.12,1.5,0.36,1.62-0.24c0.07-0.32-1.12-1.18-1.66-2.75
      c-0.65,0.4-1.05,1.2-0.76,2C506.21,304.39,506.44,304.71,506.83,304.89z"/>
    <path fill="#FFD1A6" d="M505.95,304.71c-0.13-0.26-0.3-0.76-0.37-1.04c-0.22-0.84,0.21-1.86,1.07-2.1
      c-0.32-0.75-0.35-2.43,0.12-3.32c-1.27,0.36-2.56,0.91-3.82,1.51C502.97,304.21,505.6,304.97,505.95,304.71z"/>
    <path fill="#FFD1A6" d="M494.65,299.74c0.3,0.32,0.5,2.87,1.9,2.06c1.58-0.92,3.07-1.53,5.49-2.77
      c2.42-1.23,6.36-3.02,9.14-2.4c-0.19-1.22-1.06-3.91-1.54-5.46c-1.04-0.23-2.11-0.43-3.25-0.38c-1.61,0.07-3.1,0.8-4.45,1.54
      c-2.68,1.48-4.88,3.77-6.61,5.99c-0.62,0.78-1-0.44-0.72-1.12c-1.11-2.83-2.44-6.11-4.42-8.77c-0.75-1.01-2.22-2.16-3.27-1.83
      c1.62,1.64,1.47,3.29,0.67,3.54C489.15,292.19,492.93,297.88,494.65,299.74z"/>
    <path fill="#FFD1A6" d="M492.28,298.29c-1.33-1.87-3.41-4.62-4.88-6.39c-0.09,0.54-0.56,0.85-0.75,1.01
      C487.3,293.61,491.22,297.29,492.28,298.29z"/>
    <path fill="#D6A787" d="M492.49,287.98c-0.04-0.1-0.09-0.21-0.14-0.32c1.57,2.12,2.35,5.19,2.48,7.59
      c-0.68-1.7-1.53-3.86-2.64-5.67C492.68,289.19,492.7,288.53,492.49,287.98z"/>
    <path fill="#D6A787" d="M503.21,301.89c-0.15-0.59-0.25-1.3-0.25-2.13c1.26-0.6,2.55-1.15,3.82-1.51
      c-0.15,0.28-0.24,0.64-0.3,1.03C505.16,299.71,504.03,300.69,503.21,301.89z"/>
    <path fill="#D6A787" d="M505.26,297.56c-1.19,0.46-2.31,1-3.22,1.46c-1.78,0.91-3.06,1.48-4.24,2.09
      C499.88,299.15,502.32,297.72,505.26,297.56z"/>
    <path fill="#D6A787" d="M509.66,296.51c-0.66-1.81-1.12-3.66-0.94-5.52c0.31,0.05,0.62,0.12,0.92,0.18
      c0.48,1.54,1.35,4.24,1.54,5.46C510.7,296.52,510.19,296.48,509.66,296.51z"/>
    <path fill="#D6A787" d="M487.95,292.56c1.4,1.75,3.16,4.08,4.33,5.73c-0.59-0.56-2.06-1.94-3.37-3.18
      C488.65,294.24,488.32,293.39,487.95,292.56z"/>
    <path fill="#FFE8CC" d="M486.15,292.47c0.52-0.09,0.84-0.83,0.87-1.03c-0.44-0.52-0.97-1.1-1.41-1.61
      C484.33,290.18,484.98,291.64,486.15,292.47z"/>
    <path d="M574.95,208.68c0.29,0.7,0.66,1.73,1.44,2.89c0.79,1.16,1.81,1.32,2.35,1.26c0.6-0.06,1.25-0.57,1.6-0.97
      c0.5-0.55,0.41,1.71-0.87,2.5c-1.02,0.63-2.79,0.36-3.87-1.11C573.83,210.85,574.65,207.99,574.95,208.68z"/>
    <path d="M573.22,193.85c0.04-0.1,0.18-0.05,0.16,0.05c-0.89,3.71-1.25,7.51-2.22,11.19c-0.06,0.24-0.41,0.21-0.41-0.04
      C570.69,201.23,571.69,197.38,573.22,193.85z"/>
    <path d="M581.83,259.8c0.05-0.05,0.15,0.02,0.1,0.07c-0.87,1.08-0.9,2.48,0.05,3.51c0.1,0.1,0.19,0.2,0.29,0.3
      c-0.58-1.04-0.4-1.98,0.33-2.91c0.05-0.06,0.15-0.01,0.11,0.06c-0.42,0.71-0.42,1.54-0.08,2.28c0.32,0.7,1.03,1.14,1.6,1.66
      c0.07,0.06,0.04,0.18-0.07,0.18c-0.5-0.05-0.95-0.27-1.32-0.58c0.06,0.09,0.13,0.19,0.19,0.3c0.06,0.12-0.05,0.26-0.19,0.19
      c-0.83-0.38-1.76-1.06-2.05-1.92C580.42,261.81,580.97,260.58,581.83,259.8z"/>
    <path d="M593.85,265.19c0.9,0.24,1.4,0.96,1.58,1.78c0.12-1.12-0.05-2.16-1.23-2.92c-0.07-0.05-0.01-0.16,0.07-0.12
      c0.77,0.37,1.25,0.98,1.47,1.67c0.01-1.11-0.4-2.11-1.54-2.74c-0.07-0.04-0.02-0.15,0.06-0.12c1.59,0.55,2.27,2.32,1.61,3.72
      c0,0.51-0.12,1.03-0.39,1.52c-0.06,0.63-0.29,1.24-0.68,1.67c-0.06,0.07-0.21,0.02-0.17-0.08c0.66-1.56,0.91-3.3-0.85-4.26
      C593.73,265.27,593.78,265.17,593.85,265.19z"/>
    <path d="M597.74,262.41c1.04,0.27,1.77,1.04,2.14,1.96c-0.1-1.07-0.55-2.09-1.73-2.49c-0.09-0.03-0.05-0.14,0.04-0.12
      c1.68,0.25,2.25,2.15,1.94,3.55c0.11,0.81-0.02,1.64-0.41,2.31c-0.05,0.09-0.19,0.01-0.16-0.08c0.54-1.75,0.14-4.23-1.86-5.02
      C597.63,262.5,597.66,262.39,597.74,262.41z"/>
    <path d="M602.54,266.41c0.83-0.11,1.62,0.19,1.72,1.04c0.01,0.08-0.11,0.11-0.14,0.03c-0.24-0.71-0.8-0.97-1.57-0.94
      C602.48,266.54,602.47,266.42,602.54,266.41z"/>
    <path d="M601.79,265.76c0.98-0.51,2.37-0.1,2.57,1c0.02,0.09-0.12,0.13-0.16,0.05c-0.42-0.77-1.45-1.22-2.34-0.92
      C601.76,265.92,601.7,265.81,601.79,265.76z"/>
    <path d="M601.79,265.04c0.34-0.2,0.73-0.28,1.13-0.18c0.43,0.11,0.65,0.38,0.85,0.74c0.06,0.11-0.11,0.18-0.19,0.1
      c-0.44-0.43-1.12-0.68-1.74-0.53C601.75,265.18,601.72,265.08,601.79,265.04z"/>
    <path d="M597.51,267.79c1.02-0.12,1.64,0.53,1.89,1.37c0.14-0.82-0.21-1.78-1.22-1.83c-0.09,0-0.08-0.12,0.01-0.12
      c1.31-0.12,1.88,1.39,1.29,2.32c0.12,0.74,0,1.55-0.37,2.06c-0.05,0.08-0.18,0.02-0.15-0.07c0.52-1.32,0.22-3.19-1.47-3.55
      C597.39,267.95,597.41,267.8,597.51,267.79z"/>
    <path d="M505.75,300.22c0.32-0.18,1.06-0.25,1.21,0.16c0.02,0.05,0,0.11-0.06,0.13c-0.15,0.04-0.18,0.04-0.33-0.01
      c-0.22-0.07-0.44-0.04-0.65,0.04c-0.43,0.17-0.74,0.52-0.91,0.91c-0.03,0.08-0.17,0.05-0.14-0.04
      C505.04,300.94,505.28,300.48,505.75,300.22z"/>
    <path d="M505.08,299.91c0.27-0.35,0.7-0.65,1.18-0.66c0.57-0.01,0.9,0.22,1.19,0.66c0.05,0.08-0.07,0.14-0.14,0.08
      c-0.68-0.56-1.44-0.5-2.12,0C505.12,300.03,505.03,299.97,505.08,299.91z"/>
    <path d="M489.98,294.3c0.16-0.2,0.47-0.26,0.67-0.44c0.23-0.2,0.43-0.45,0.55-0.72c0.03-0.08,0.16-0.04,0.13,0.04
      c-0.1,0.32-0.21,0.62-0.46,0.87c-0.19,0.2-0.55,0.47-0.85,0.38C489.97,294.41,489.95,294.35,489.98,294.3z"/>
    <path d="M489.7,293.49c0.59-0.34,0.97-0.73,1.18-1.36c0.03-0.08,0.15-0.04,0.13,0.04c-0.15,0.66-0.41,1.26-1.05,1.63
      c-0.08,0.05-0.17,0.06-0.26,0c-0.06-0.04-0.08-0.06-0.08-0.13C489.62,293.61,489.64,293.53,489.7,293.49z"/>
    <path d="M489.16,292.86c0.14-0.19,0.38-0.22,0.56-0.37c0.23-0.19,0.35-0.46,0.44-0.72c0.03-0.08,0.18-0.06,0.17,0.03
      c-0.05,0.32-0.12,0.64-0.33,0.91c-0.17,0.22-0.49,0.46-0.79,0.33C489.16,293.02,489.12,292.92,489.16,292.86z"/>
    <path d="M492.66,291.27c0.13-0.16,0.35-0.22,0.51-0.36c0.19-0.16,0.35-0.36,0.47-0.57c0.03-0.06,0.14-0.02,0.12,0.05
      c-0.14,0.37-0.5,1.16-1.05,1.02C492.64,291.39,492.62,291.31,492.66,291.27z"/>
    <path d="M493.66,291.81c0.17-0.2,0.28-0.5,0.36-0.75c0.02-0.07,0.16-0.06,0.14,0.02c-0.08,0.4-0.29,1.43-0.92,1.35
      c-0.07-0.01-0.12-0.08-0.09-0.15C493.25,292.08,493.5,291.99,493.66,291.81z"/>
    <path d="M569.02,334.15c0.4-0.79,1.22-1.45,2.16-1.15c0.11,0.03,0.08,0.16,0,0.21c-0.7,0.33-1.44,0.55-1.85,1.24
      c-0.4,0.7-0.37,1.73,0.02,2.42c0.04,0.07-0.07,0.12-0.11,0.06C568.62,336.03,568.52,335.12,569.02,334.15z"/>
    <path d="M566.15,332.8c0.35-0.35,0.76-0.6,1.27-0.69c0.56-0.09,0.86,0.16,1.15,0.53c0.08,0.1-0.05,0.26-0.18,0.22
      c-0.68-0.25-1.33-0.09-1.86,0.36c-0.51,0.44-1.07,1.38-1,2.04c0.01,0.09-0.13,0.14-0.16,0.05
      C565.04,334.42,565.47,333.48,566.15,332.8z"/>
    <path d="M563.17,331.61c0.68-0.47,1.53-0.37,2.12,0.15c0.11,0.09,0.03,0.27-0.12,0.27c-0.51-0.03-1.14-0.09-1.61,0.14
      c-0.56,0.26-0.96,0.96-1.11,1.51c-0.02,0.09-0.19,0.07-0.19-0.02C562.23,332.82,562.43,332.12,563.17,331.61z"/>
    <path d="M542.4,334.92c0.71-1.09,1.9-1.5,3.18-1.15c0.14,0.04,0.14,0.24-0.02,0.25c-1.06,0.1-2.05,0.5-2.64,1.35
      c-0.52,0.77-0.86,1.91-0.96,2.8c-0.01,0.09-0.16,0.1-0.17,0.01C541.7,337.01,541.72,335.98,542.4,334.92z"/>
    <path d="M539.23,335.5c0.3-0.71,1.15-1.39,1.99-1.13c0.11,0.03,0.15,0.16,0.05,0.23c-0.28,0.2-0.6,0.3-0.91,0.45
      c-0.5,0.24-0.75,0.75-0.9,1.23c-0.21,0.67-0.22,1.39-0.35,2.07c-0.02,0.09-0.19,0.11-0.19,0
      C538.86,337.38,538.85,336.41,539.23,335.5z"/>
    <path d="M535.87,335.52c0.32-0.7,1.38-1.75,2.25-1.16c0.1,0.07,0.11,0.19,0,0.26c-0.26,0.17-0.52,0.22-0.83,0.31
      c-0.56,0.18-0.88,0.74-1.09,1.21c-0.32,0.71-0.22,1.46-0.31,2.21c-0.01,0.09-0.13,0.1-0.18,0.03
      C535.24,337.56,535.5,336.31,535.87,335.52z"/>
    <path d="M569.1,335.55c0.49,0,0.89,0.37,0.89,0.83c0,0.46-0.4,0.83-0.89,0.83c-0.49,0-0.89-0.37-0.89-0.83
      C568.21,335.93,568.61,335.55,569.1,335.55z"/>
    <path d="M565.37,334.7c0.49,0,0.89,0.37,0.89,0.83c0,0.46-0.4,0.83-0.89,0.83c-0.49,0-0.89-0.37-0.89-0.83
      C564.48,335.07,564.88,334.7,565.37,334.7z"/>
    <path d="M562.33,333.15c0.49,0,0.89,0.37,0.89,0.83c0,0.46-0.4,0.83-0.89,0.83c-0.49,0-0.89-0.37-0.89-0.83
      C561.44,333.52,561.84,333.15,562.33,333.15z"/>
    <path d="M541.91,337.53c0.49,0,0.89,0.37,0.89,0.83c0,0.45-0.4,0.83-0.89,0.83c-0.49,0-0.89-0.37-0.89-0.83
      C541.03,337.9,541.42,337.53,541.91,337.53z"/>
    <path d="M539.05,337.48c0.49,0,0.89,0.37,0.89,0.83c0,0.46-0.4,0.83-0.89,0.83c-0.49,0-0.89-0.37-0.89-0.83
      C538.16,337.85,538.55,337.48,539.05,337.48z"/>
    <path d="M536.01,337.53c0.49,0,0.89,0.37,0.89,0.83c0,0.45-0.4,0.83-0.89,0.83c-0.49,0-0.89-0.37-0.89-0.83
      C535.11,337.9,535.52,337.53,536.01,337.53z"/>
    <path d="M540.54,292.66c0.56-0.52,1.53-0.63,2.26-0.88c0.84-0.29,1.69-0.52,2.55-0.75c0.07-0.02,0.11,0.08,0.04,0.11
      c-0.93,0.37-1.79,0.87-2.72,1.25c-0.61,0.26-1.39,0.66-2.07,0.49C540.52,292.85,540.46,292.73,540.54,292.66z"/>
    <path d="M542.42,292.9c0.51-0.18,1.01-0.33,1.54-0.44c0.08-0.02,0.11,0.09,0.03,0.11c-0.53,0.16-1.03,0.45-1.49,0.73
      c-0.28,0.16-0.59,0.43-0.91,0.25c-0.04-0.02-0.05-0.07-0.04-0.1C541.6,293.09,542.12,293.01,542.42,292.9z"/>
    <path d="M522.4,293.5c0.03-0.09,0.13-0.13,0.22-0.11c0.64,0.15,1.03,0.84,1.56,1.19c0.61,0.41,1.35,0.65,2.06,0.88
      c1.43,0.46,3.29,0.49,4.75,0.09c0.08-0.02,0.1,0.08,0.03,0.11c-1.58,0.57-3.23,0.47-4.88,0.24c-0.36-0.05-0.99-0.19-1.64-0.41
      c0.56,0.43,1.14,0.84,1.86,1.14c1.5,0.63,3.2,0.51,4.71-0.02c0.11-0.04,0.17,0.09,0.07,0.14c-2.46,1.27-7.82,0.8-8.59-2.32
      c-0.01-0.04,0-0.07,0.02-0.1C522.39,294.09,522.31,293.81,522.4,293.5z"/>
    <path d="M531.3,288.57c4.27-1.99,8.93-4.12,13.8-4.21c0.13,0,0.19,0.18,0.05,0.22c-4.6,1.32-8.99,3.46-13.73,4.27
      C531.25,288.88,531.13,288.65,531.3,288.57z"/>
    <path d="M534.64,286.19c2.11-1.66,5.45-2.07,8.11-2.29c0.07-0.01,0.09,0.11,0.01,0.12c-1.3,0.18-2.56,0.48-3.8,0.87
      c-1.42,0.45-2.77,1.17-4.25,1.46C534.61,286.37,534.56,286.25,534.64,286.19z"/>
    <path d="M525.98,269.76c0.6,0.3,1.27,0.37,1.93,0.2c0.57-0.15,0.73-0.47,0.74-0.91c-0.86,0.28-2.01,0.05-2.57-0.49
      c-0.09-0.08-0.04-0.26,0.1-0.28c0.75-0.08,1.52,0.15,2.26,0c0.39-0.08,0.55-0.34,0.6-0.69c0.08-0.63-0.79-1.05-1.33-1.2
      c-0.07-0.02-0.06-0.13,0.02-0.12c0.73,0.09,1.35,0.3,1.74,0.92c0.27,0.42,0.23,1.03-0.09,1.41c-0.01,0.01-0.02,0.02-0.04,0.03
      c0.91,1.87-2.59,2.98-3.5,1.27C525.81,269.82,525.91,269.72,525.98,269.76z"/>
    <path d="M552.25,214.79c0.85-0.77,2.11-0.96,3.2-0.6c0.08,0.03,0.05,0.15-0.04,0.13c-1.12-0.29-2.21,0.13-2.99,0.9
      c-0.29,0.29-0.49,0.65-0.62,1.02c-0.12,0.37,0.03,0.72-0.11,1.08c-0.06,0.16-0.29,0.16-0.39,0.04
      C550.66,216.62,551.68,215.31,552.25,214.79z"/>
    <path d="M550.1,215.68c0.29-1.06,1.12-1.82,2.1-2.35c0.08-0.04,0.14,0.06,0.06,0.11c-0.81,0.48-1.33,1.25-1.61,2.09
      c-0.32,0.97,0.12,1.66,0.24,2.58c0.01,0.12-0.16,0.19-0.25,0.13C549.79,217.7,549.88,216.52,550.1,215.68z"/>
    <path d="M501.17,298.15c1.8-1.15,3.08-2.1,5.28-2.47c1.23-0.21,2.41-0.55,3.62-0.85c0.68-0.17,0.96-0.4,1.64-0.22
      c0.11,0.03,0.16,0.2,0.02,0.24c-0.29,0.07-0.65,0.42-0.98,0.51c-1.18,0.35-2.36,0.22-3.56,0.47c-1.5,0.32-2.74,0.46-4.06,1.26
      c-0.64,0.38-1.23,0.82-1.87,1.19C501.19,298.32,501.1,298.2,501.17,298.15z"/>
    <path d="M502.81,295.92c1.28-0.97,2.67-1.34,4.29-1.6c1.68-0.27,2.73-0.95,4.04-1.85c0.09-0.06,0.25,0.02,0.24,0.13
      c-0.1,1.16-1.28,1.67-2.38,1.9c-2.16,0.46-4.18,0.24-6.08,1.52C502.83,296.07,502.73,295.98,502.81,295.92z"/>
    <path d="M569.1,183.95c0.09-0.01,0.15,0.09,0.07,0.15c-0.99,0.67-1.75,1.59-2.61,2.39c-0.09,0.08-0.29,0.02-0.24-0.11
      C566.75,185.11,567.66,184.17,569.1,183.95z"/>
    <path d="M566.85,183.59c0.75-0.37,1.38-0.35,2.17-0.19c0.08,0.01,0.07,0.14-0.01,0.14c-0.63,0.04-1.34,0.26-1.84,0.6
      c-0.45,0.3-0.74,0.78-1.27,0.95c-0.11,0.03-0.21-0.06-0.21-0.16C565.7,184.33,566.32,183.85,566.85,183.59z"/>
    <path d="M561.19,145.51c2.29-1.48,4.97-1.99,7.55-2.85c0.07-0.02,0.11,0.08,0.05,0.12c-1.12,0.6-2.15,1.36-3.36,1.83
      c-1.34,0.51-2.69,0.87-4.08,1.24C561.16,145.89,561.03,145.61,561.19,145.51z"/>
    <path d="M550.68,150.53c0.06-0.06,0.17,0.03,0.12,0.09c-1.74,2.19-2.08,4.18-0.16,6.37c1.15,1.32,2.67,2.25,4.32,2.91
      c0.62,0.25,1.27,0.42,1.92,0.54c-3.72-0.9-7.13-4.01-6.35-7.77c0.02-0.1,0.17-0.09,0.17,0.01c-0.11,4.38,4.12,6.99,8.38,7.27
      c3.64,0.24,7.27-1.34,10.48-2.74c0.22-0.09,0.41,0.2,0.22,0.34c-1.69,1.14-3.61,2.04-5.62,2.6c-3.36,1.79-7.45,1.68-10.91-0.11
      c-1.62-0.83-3.55-2.36-4.19-4.04C548.23,153.87,549.06,152.12,550.68,150.53z"/>
    <path d="M541.97,252.13c0.74-0.23,1.56-0.15,2.32-0.14c0.09,0,0.1,0.13,0.01,0.15c-0.67,0.1-1.37,0.15-2.02,0.34
      c-0.27,0.08-0.48,0.2-0.69,0.38c-0.22,0.19-0.22,0.37-0.52,0.43c-0.07,0.01-0.16-0.01-0.19-0.08
      C540.64,252.64,541.51,252.28,541.97,252.13z"/>
    <path d="M541.71,250.71c0.76-0.23,1.56-0.02,2.32,0.06c0.09,0.01,0.09,0.13,0,0.13c-0.6-0.01-1.21,0.08-1.79,0.27
      c-0.65,0.21-0.96,0.62-1.33,1.13c-0.08,0.1-0.25,0.1-0.29-0.03C540.37,251.51,540.93,250.95,541.71,250.71z"/>
    <path d="M543.52,247.25c0.09,0.02,0.06,0.16-0.03,0.15c-1.1-0.16-3.04-0.02-3.12,1.34c-0.01,0.13-0.21,0.12-0.25,0.02
      C539.33,246.8,542.27,246.92,543.52,247.25z"/>
    <path d="M540.54,242.75c0.82-0.15,1.75,0.06,2.54,0.25c0.09,0.02,0.05,0.15-0.05,0.14c-0.65-0.12-1.31-0.17-1.98-0.06
      c-0.73,0.12-1.08,0.55-1.59,0.98c-0.09,0.08-0.22,0.04-0.24-0.08C539.1,243.24,539.87,242.87,540.54,242.75z"/>
    <path d="M541,239.79c0.92-0.04,1.94,0.17,2.78,0.51c0.09,0.03,0.05,0.16-0.04,0.13c-0.8-0.26-1.65-0.36-2.49-0.26
      c-0.34,0.04-0.66,0.14-0.97,0.28c-0.3,0.14-0.4,0.39-0.75,0.41c-0.09,0-0.15-0.07-0.17-0.13
      C539.15,240.01,540.51,239.81,541,239.79z"/>
    <path d="M540.67,238.72c0.93-0.18,1.94,0.18,2.77,0.53c0.07,0.03,0.02,0.13-0.05,0.11c-0.72-0.21-1.5-0.27-2.24-0.13
      c-0.69,0.13-1.1,0.59-1.71,0.84c-0.1,0.04-0.22,0-0.23-0.11C539.19,239.2,539.97,238.85,540.67,238.72z"/>
    <path d="M539.12,235.28c0.39-0.57,1.28-0.69,1.95-0.72c0.87-0.04,1.68,0.3,2.36,0.75c0.07,0.05,0,0.15-0.07,0.11
      c-0.69-0.39-1.45-0.52-2.25-0.45c-0.67,0.05-1.18,0.46-1.81,0.56C539.17,235.55,539.04,235.41,539.12,235.28z"/>
    <path d="M540.61,230.99c0.79-0.11,1.72,0.1,2.4,0.47c0.09,0.04,0.01,0.15-0.08,0.12c-0.72-0.33-1.49-0.29-2.24-0.12
      c-0.6,0.13-0.91,0.49-1.42,0.76c-0.11,0.06-0.23-0.01-0.24-0.13C538.96,231.37,540,231.07,540.61,230.99z"/>
    <path d="M540.86,226.1c1.22-0.01,2.44,0.44,3.47,1.02c0.09,0.05,0.01,0.16-0.08,0.12c-0.77-0.34-1.58-0.49-2.42-0.6
      c-0.68-0.08-1.37-0.05-2.01,0.18c-0.45,0.16-0.77,0.55-1.16,0.81c-0.1,0.06-0.23-0.01-0.23-0.11
      C538.39,226.37,539.88,226.1,540.86,226.1z"/>
    <path d="M540.12,224.8c0.97-0.09,2.01,0.13,2.89,0.53c0.08,0.03,0.02,0.15-0.06,0.12c-0.72-0.26-1.48-0.31-2.25-0.23
      c-0.48,0.05-0.95,0.18-1.35,0.44c-0.25,0.17-0.33,0.43-0.64,0.52c-0.06,0.02-0.15-0.01-0.18-0.07
      C538.09,225.34,539.55,224.85,540.12,224.8z"/>
    <path d="M540.86,219.6c1.04,0.01,2.08,0.32,3.02,0.71c0.09,0.04,0.05,0.18-0.05,0.14c-0.78-0.27-1.63-0.36-2.46-0.38
      c-1.04-0.02-1.7,0.21-2.54,0.74c-0.08,0.05-0.21,0-0.2-0.09C538.67,219.75,540.03,219.6,540.86,219.6z"/>
    <path d="M540.2,218.73c0.79-0.18,1.61,0.01,2.35,0.28c0.08,0.03,0.05,0.15-0.04,0.12c-0.72-0.18-1.43-0.16-2.15,0.01
      c-0.57,0.13-0.95,0.56-1.48,0.71c-0.1,0.03-0.22-0.06-0.19-0.16C538.88,219.13,539.62,218.86,540.2,218.73z"/>
    <path d="M539.86,217.71c0.84-0.22,1.74,0.01,2.58,0.06c0.11,0.01,0.09,0.15-0.01,0.15c-0.6,0.02-1.21,0.03-1.81,0.1
      c-0.39,0.05-0.76,0.11-1.1,0.31c-0.28,0.16-0.35,0.45-0.61,0.62c-0.08,0.05-0.2,0.01-0.22-0.08
      C538.54,218.26,539.33,217.85,539.86,217.71z"/>
    <path d="M543.6,213.12c0.08,0.02,0.06,0.14-0.02,0.13c-2.38-0.19-4.04,1.51-4.91,3.41c-0.05,0.1-0.23,0.06-0.2-0.05
      C538.82,214.38,541.16,212.66,543.6,213.12z"/>
    <path d="M542.86,258.99c0.6-0.52,1.53-0.45,2.29-0.53c0.1-0.01,0.13,0.12,0.03,0.14c-0.54,0.15-1.12,0.24-1.59,0.51
      c-0.24,0.14-0.64,0.57-0.81,0.12C542.74,259.16,542.79,259.05,542.86,258.99z"/>
    <path d="M545.04,260.89c0.79-0.3,1.65-0.35,2.48-0.45c0.09-0.01,0.12,0.11,0.03,0.14c-0.67,0.19-1.35,0.33-2.01,0.54
      c-0.31,0.1-0.61,0.23-0.88,0.41c-0.23,0.16-0.32,0.4-0.6,0.49c-0.09,0.03-0.16-0.03-0.17-0.11
      C543.79,261.39,544.63,261.04,545.04,260.89z"/>
    <path d="M545.66,261.97c0.61-0.27,1.28-0.43,1.95-0.48c0.1-0.01,0.13,0.12,0.03,0.15c-0.61,0.18-1.21,0.41-1.76,0.72
      c-0.26,0.15-0.5,0.33-0.72,0.53c-0.2,0.18-0.27,0.4-0.53,0.5c-0.15,0.06-0.27-0.05-0.3-0.17
      C544.17,262.63,545.21,262.16,545.66,261.97z"/>
    <path d="M546.93,265.51c0.35-0.46,0.85-0.75,1.34-1.06c0.1-0.06,0.21,0.05,0.12,0.12c-0.41,0.32-0.71,0.76-1,1.17
      c-0.15,0.2-0.27,0.42-0.37,0.64c-0.08,0.18-0.11,0.26-0.31,0.32c-0.07,0.02-0.13-0.02-0.18-0.07
      C546.27,266.31,546.73,265.78,546.93,265.51z"/>
    <path d="M548.09,266.82c0.33-0.52,0.74-1,1.16-1.46c0.06-0.06,0.17,0.03,0.12,0.09c-0.35,0.44-0.63,0.9-0.89,1.39
      c-0.2,0.36-0.31,0.99-0.69,1.2c-0.06,0.03-0.15,0.03-0.17-0.05C547.49,267.59,547.88,267.15,548.09,266.82z"/>
    <path d="M549.59,266.93c0.04-0.07,0.17-0.01,0.14,0.06c-0.14,0.34-0.29,0.68-0.42,1.03c-0.17,0.45-0.24,0.91-0.37,1.38
      c-0.07,0.25-0.5,0.23-0.5-0.04C548.43,268.5,549.17,267.64,549.59,266.93z"/>
    <path fill="#FFFFFF" d="M568.28,173.68c0.64,0,1.16,0.49,1.16,1.08c0,0.59-0.52,1.08-1.16,1.08c-0.64,0-1.16-0.48-1.16-1.08
      C567.12,174.17,567.64,173.68,568.28,173.68z"/>
    <path fill="#FFFFFF" d="M582.92,171.07c0.5,0,0.91,0.38,0.91,0.85c0,0.47-0.41,0.85-0.91,0.85c-0.51,0-0.92-0.38-0.92-0.85
      C582,171.45,582.41,171.07,582.92,171.07z"/>
    <path fill="#FFFFFF" d="M569.93,172.71c0.27,0,0.49,0.2,0.49,0.45c0,0.25-0.22,0.45-0.49,0.45c-0.27,0-0.49-0.2-0.49-0.45
      C569.44,172.92,569.66,172.71,569.93,172.71z"/>
    <path fill="#FFFFFF" d="M583.98,170.33c0.19,0,0.34,0.14,0.34,0.31c0,0.17-0.15,0.31-0.34,0.31c-0.18,0-0.33-0.14-0.33-0.31
      C583.65,170.47,583.8,170.33,583.98,170.33z"/>
    <path fill="#999C47" d="M569.21,146.57c-3.38,1.48-8.15,2.5-9.63,2.67c-1.48,0.17,3.06,2.85,6.86,1.64
      C570.22,149.67,572.54,145.11,569.21,146.57z"/>
    <path fill="#999C47" d="M592.18,146.14c-2.32-3.45-10.1-5.78-15.38-4.92C571.52,142.09,594.19,149.14,592.18,146.14z"/>
    <path fill="#FFE4C9" d="M592.96,193.5c2.73,4.11,6.73,8.19,6.4,5.35c-0.4-3.45-5.83-10.07-8.93-12.26
      C588.89,185.51,590.3,189.51,592.96,193.5z"/>
    <path fill="#FFE4C9" d="M578.94,211.89c-0.94,0.6-2.63-1.14-3.2-2.8C575.18,207.43,580.45,210.93,578.94,211.89z"/>
    <path fill="#FFE4C9" d="M562.96,205.77c-1.91,1.51-5.83,4.29-3.2,5.78C562.39,213.03,564.84,204.28,562.96,205.77z"/>
    <path fill="#FFE4C9" d="M572.27,184.66c-1.31,0.06-4.23,0.96-4.99,2.63C566.53,188.95,574.15,184.57,572.27,184.66z"/>
    <path fill="#FFE4C9" d="M572.08,186.32c-1.22,1.05-3.29,2.28-4.23,2.54c-0.94,0.26,0.66,1.23,2.45,0
      C572.08,187.64,573.3,185.27,572.08,186.32z"/>
    <path fill="#FFE4C9" d="M570.48,195.34c-1.13,2.45-1.22,6.04-1.13,8.06c0.09,2.02-1.69-3.07-1.13-5.52
      C568.79,195.43,571.41,193.32,570.48,195.34z"/>
    <path fill="#FFE4C9" d="M589.14,258.28c2.32,1.17,4.64,2.55,3.95,1.53c-0.92-1.37-4.46-3.58-7.34-3.73
      C584.28,255.99,586.71,257.05,589.14,258.28z"/>
    <path fill="#FFE4C9" d="M504.58,291.7c-1.62,0.24-4.68,2.28-6.36,4.64C496.53,298.7,508.94,291.06,504.58,291.7z"/>
    <path fill="#BE9663" d="M601.18,286.8c-0.06-0.66-0.67,5.48-0.64,11.74c0.03,5.61,0.7,11.33,0.85,12.32
      c0.51,3.41,0.36-9.04,0.36-13.64C601.75,292.62,601.49,289.99,601.18,286.8z"/>
    <path fill="#BE9663" d="M579.37,272.44c-0.27,1.56-0.69,5.37-0.28,6.9c0.41,1.54,1.09-2.63,1.09-4.21
      C580.19,273.08,579.78,270.02,579.37,272.44z"/>
    <path fill="#8F663D" d="M558.22,297.94c-2.03-0.89-5.2-3.13-6.45-4.48c-1.25-1.35-1.58,0.8,1.18,3.07
      C555.72,298.8,560.32,298.86,558.22,297.94z"/>
    <path fill="#8F663D" d="M540.24,301.68c-0.94-1.11-2.9-3.06-3.95-3.8c-1.05-0.74,0.46,2.08,0.92,2.57
      C537.68,300.94,540.97,302.54,540.24,301.68z"/>
    <path fill="#8F663D" d="M545.12,304.99c-0.73-0.51-2.47-0.55-3.42,0C538.8,306.64,546.9,306.21,545.12,304.99z"/>
    <path fill="#8F663D" d="M564.86,301c-0.76-0.64-2.1-0.8-3.03,0c-0.92,0.8,0.92,2.7,1.51,2.51
      C563.95,303.33,565.59,301.62,564.86,301z"/>
    <path fill="#8F663D" d="M537.22,311.18c-0.85,1.4-2.57,4.66-4.01,7.11c-1.45,2.45,2.83-0.61,3.62-2.51
      C537.61,313.88,538.8,308.61,537.22,311.18z"/>
    <path fill="#8F663D" d="M560.13,310.93c-0.75,1.83-2.37,5.64-2.9,7.17c-0.53,1.54-0.66-1.96,0-3.92
      C557.89,312.22,560.85,309.16,560.13,310.93z"/>
    <path fill="#C7A64D" d="M532.23,232.83c-1.56,3.19-4.27,12.26-4.52,18.57c-0.34,8.47,2.51-4.44,3.38-8.88
      C531.98,238.08,534.11,228.98,532.23,232.83z"/>
    <path fill="#C7A64D" d="M526.84,227.11c-3.19,3.34-12.54,12.38-14.67,15.18c-2.13,2.81,6.02-0.7,8.9-4.2
      C523.95,234.58,530.97,222.79,526.84,227.11z"/>
    <path fill="#C7A64D" d="M517.05,244.51c-3.28-0.17-6.39-0.35-8.03,4.56C507.4,253.97,519.31,244.62,517.05,244.51z"/>
    <path fill="#C7A64D" d="M573.37,257.82c-2.38,1.29-7.03,2.34-9.28,2.34c-2.26,0,1.63,2.92,4.26,2.45
      C570.98,262.14,576.37,256.19,573.37,257.82z"/>
    <path fill="#C7A64D" d="M527.46,274.99c-0.46,3.29-2.26,9.11-3.76,12.03c-1.51,2.92,0-5.25,0.5-7.35
      C524.7,277.56,528.09,270.55,527.46,274.99z"/>
    <path fill="#C7A64D" d="M542.76,282.46c-3.38,0.27-6.52,0.93-8.9,2.8c-2.38,1.87-0.63-3.15,3.51-3.97
      C541.51,280.48,545.65,282.23,542.76,282.46z"/>
    <path fill="#947446" d="M588.6,336.34c-0.46-1.71-2.59-2.88-4.48-2.97C582.22,333.28,589.2,338.57,588.6,336.34z"/>
    <path fill="#947446" d="M561.79,338.19c-0.47-1.52-3.69-3.53-5.68-2.69C554.12,336.34,562.59,340.79,561.79,338.19z"/>
    <path fill="#C2A876" d="M577.74,333.93c-2,0.35-4.68,0.83-5.78,0.65c-1.1-0.19,0.6,2.04,2.49,1.58
      C576.34,335.69,579.33,333.65,577.74,333.93z"/>
    <path fill="#C2A876" d="M551.43,334.67c-1.92,0-5.88,0.37-7.18,0.93c-1.29,0.56,2.29,1.67,3.89,1.21
      C549.74,336.34,553.52,334.67,551.43,334.67z"/>
    <path fill="#C2A876" d="M527.71,334.58c-2.15-0.16-6.28,2.13-6.78,3.9C520.44,340.24,530.21,334.76,527.71,334.58z"/>
  </g>
  <g id="item_11_">
    <g id="item_31_">
      <path d="M53.51,457.7c5.17,2.61,15.8,3.29,21.68,3.72c0.58-0.75,1.6-3.15,1.83-4.54
        c6.89,1.37,14.84,0.54,21.92-0.18c0.28,0.59,0.63,1.09,0.95,1.36c6.22-0.89,14.02-4.05,19.79-6.34c-0.45-0.89-1.44-2.84-1.65-3.21
        c6.79,0.35,17.75-3.05,23.45-5.48c11.2-4.77,21.4-11.75,26.72-18.56c-1.72-2.15-22.97,3.42-33.68,6.54
        c0.28-0.32,0.3-0.84-0.03-1.09c-4.56-3.62-9.41-6.74-12.3-11.67c-1.99-3.39-5.78-12.38-6.52-16.17
        c7.06-3.54,15.33-8.21,20.26-11.03c-3.65-4.86-7.62-16.82-10.5-23.7c-2.88-6.88-21.42-50.21-23.86-59.25
        c-1.33-4.91-6.82-22.41-13.04-39.14c-6.22-16.73-16.54-42.45-16.83-43.65c2.38-1.05,9.42-0.43,11.87-2.19
        c-4.05-3.68-11.04-2.96-15.36-5.74c1.76-0.16,3.5-1.39,4.06-2.6c2.42-0.44,4.39-1.81,6.01-3.58c1.83-2.01,3.79-6.08,4.29-8.69
        c-0.23-0.12-1.29-0.57-1.44-0.86c0.66-0.18,1.45-0.29,1.83-0.48c0.27-2.2,0.1-5.88-0.37-8.19c4.28,0.08,4.53,7.65,9.23,8.57
        c1.69,0.33,6.17-1.59,5.1-3.88c-1.07-2.29-8.04-13.65-12.14-16.14c-0.62-1.21-0.88-3.29-0.51-5.17
        c7.31-0.04,17.31,0.07,16.44-2.12c-0.48-1.21-8.48-2.25-15.7-3.35c-4.37-6.15-2.94-15.6-7.68-20.4c-4.74-4.8-7.5,4.33-12.31,3.5
        c-4.8-0.83-11.53-7.32-16.85-5.55c-5.77,1.92-5.35,11.55-3.94,20.32c-3.42,0.07-11.17,0.16-12.01,1.15
        c-0.84,0.98,0.83,4.3,2.59,5.48c1.57,1.04,4.88,0.81,7.74,0.79c-1.27,1.15-1.98,3-2.47,4.38c-0.72,2.05-1.1,4.23-1.17,6.35
        c0,0.03,0.05,0.03,0.06,0c0.46-2.08,1.01-4.15,1.92-6.13c0.61-1.34,1.37-2.39,2.33-3.42c-0.05,0.17-0.09,0.34-0.14,0.53
        c-2.85,4.51-4.46,9.48-3.74,14.6c0.01,0.09,0.14,0.07,0.15-0.01c0.08-3.09,0.69-6.16,1.83-9.1c-0.97,3.29-1.13,6.86-0.68,9.79
        c0.01,0.05,0.1,0.05,0.1-0.01c-0.08-2.76,0.21-5.45,1.02-8.16c0.06-0.2,0.13-0.39,0.2-0.58c-0.5,4.3-0.61,8.89,0.2,10.79
        c1.13,0.94,3.08,1.71,5.17,1.37c-0.57,1.9-0.69,6.15-0.26,10.59c-0.98-0.02-10.39-1.26-13.15-0.77c-0.9,3.47-0.01,7.19,1.03,9.92
        c-6.52,7.49-10.79,17.92-13.87,28.52c-8.73,30.08-7.33,59.54,1.84,90.46c4.84,16.33,17.16,48.82,19.82,58.25
        c2.66,9.44-0.01,27.32-6.92,38.5c5.24-0.31,19.85-3.32,31.06-9.55c-1.74,5.35-6.18,21-10.97,25.01c0.35,0.63,0.9,1.22,1.56,1.77
        C56,451.59,54.16,455.14,53.51,457.7z M97.58,444.59c-0.63-0.21-4.01-1.15-5.11-1.36c0.56-0.3,2.54-3.12,2.62-4.4
        C95.97,440.57,97.08,442.72,97.58,444.59z"/>
      <path fill="#9E6D94" d="M87,414.58c0,0.01,0,0.01,0,0.02
        c1.02,5.26,4.84,16.03,6.67,21.1c-1.13,2.83-2.2,4.79-3.38,6.22c-2.7,0.21-22.97,2.11-28.04,3.34c5.8-7.44,7.73-23.52,7.55-23.78
        C74.46,419.64,80.97,417.05,87,414.58z"/>
      <path fill="#9E6D94" d="M131.52,430.41c-6.02,2.28-11.08,5.88-16.81,8.79
        c-4.74,2.41-9.6,4.6-14.4,6.9c-0.32-2.75-1.69-5.17-2.85-7.92c-2.47-5.85-6.63-17.81-7.84-24.01c-0.05-0.23-0.15-0.41-0.29-0.55
        c8.6-3.55,17.16-7.19,22.45-9.65c0.42-0.19,0.86-0.4,1.3-0.62c0.19,4.88,4.41,14.78,7.27,18.9
        C123.26,426.46,126.97,428.28,131.52,430.41z"/>
      <path fill="#864C7D" d="M69.26,425.81c0.43-2.53,0.6-4.25,0.54-4.33
        c4.66-1.83,11.17-4.42,17.2-6.89c0,0.01,0,0.01,0,0.02c0.78,4.02,3.19,11.25,5.1,16.7C86.79,426.17,77.41,424.92,69.26,425.81z"/>
      <path fill="#864C7D" d="M91.04,419.82c-0.61-2.12-1.11-4.07-1.42-5.65
        c-0.05-0.23-0.15-0.41-0.29-0.55c8.6-3.55,17.16-7.19,22.45-9.65c0.42-0.19,0.86-0.4,1.3-0.62c0.04,1.15,0.32,2.58,0.74,4.15
        C109.65,409.29,93.92,417.03,91.04,419.82z"/>
      <path fill="#695347" d="M101.78,454.54c-0.42-1.38-0.22-4.26,0.61-6.03
        c4.59-2.2,23.06-11.74,26.54-13.81c15.5-4.67,30.22-8.65,34.5-8.54c-6.07,5.65-13.7,10.4-21.52,13.63
        c-7.61,3.14-18.33,7.09-26.7,5.91c-0.19,1.03,0.35,3.8,0.83,4.61C111.39,452.08,106.7,453.6,101.78,454.54z"/>
      <path fill="#695347" d="M73.25,458.31c-3.09-0.15-13.29-1.23-16.18-2.21
        c0.34-2.03,2.94-6.26,4.22-7.99c5.68-1.5,20.16-3.37,28.26-3.88c3.64,0.76,6,1.58,7.97,2.52c0.19,1.34-0.34,2.88-0.39,3.22
        c0.83,0.28,1.5,0.26,2.24,0.29c-0.51,1.63-0.96,2.86-0.94,4.53c-7.02,1.1-16.89,1.52-24.11-0.49
        C74.25,455.55,74.02,457.49,73.25,458.31z"/>
      <path fill="#53382B" d="M125.39,440.24c-3,1.95-6.06,3.74-9.09,5.56
        c-0.36-0.03-0.73-0.06-1.09-0.11c-0.04,0.2-0.04,0.48-0.03,0.78c-3.91,2.36-7.72,4.77-11.2,7.6c-0.73,0.17-1.47,0.32-2.21,0.47
        c-0.42-1.38-0.22-4.26,0.61-6.03c4.59-2.2,23.06-11.74,26.54-13.81c4.01-1.21,7.97-2.37,11.7-3.43
        C135.4,434.09,130.28,437.07,125.39,440.24z"/>
      <path fill="#53382B" d="M60.92,448.62c0.13-0.19,0.25-0.37,0.37-0.52
        c5.68-1.5,20.16-3.37,28.26-3.88c3.64,0.76,6,1.58,7.97,2.52c0.19,1.34-0.34,2.88-0.39,3.22c0.83,0.28,1.5,0.26,2.24,0.29
        c-0.51,1.63-0.96,2.86-0.94,4.53c-0.69,0.1-1.4,0.2-2.13,0.29C86.07,448.82,73.4,447.16,60.92,448.62z"/>
      <path fill="#53382B" d="M62.48,456.72c3.87,0.3,7.66,0.08,11.5,0.03
        c-0.16,0.63-0.39,1.2-0.72,1.56c-2.98-0.15-12.57-1.16-15.83-2.11C59.1,456.4,60.78,456.59,62.48,456.72z"/>
      <path fill="#008ACC" d="M45.71,163.63c11.4-0.77,23.45,1.65,34.76,2.26
        c-1.93-4.65-2.04-11.74-4.37-13.87c-2.33-2.14-4.67,2.33-9.2,3.74c-1.32,0.41-7.54-2-11.13-3.98c-3.59-1.98-7.15-2.83-9.04,0.17
        C44.84,154.95,44.98,159.83,45.71,163.63z"/>
      <path fill="#9E6D94" d="M45.89,165.16c0.09,1.29,0.21,2.66,0.44,3.55
        c11.58,1.74,28.19,3.1,36.13,2.96c-0.44-0.91-1.22-3.21-1.66-4.11C69.78,166.52,51.88,164.42,45.89,165.16z"/>
      <path fill="#864C7D" d="M63.46,168.39c4.1,0.39,8.22,0.68,12.35,0.83
        c1.93,0.07,3.85,0.12,5.76,0.24c0.32,0.85,0.65,1.73,0.89,2.21c-7.94,0.15-24.55-1.21-36.13-2.96c-0.2-0.75-0.31-1.85-0.4-2.96
        C51.7,166.93,57.55,167.82,63.46,168.39z"/>
      <path fill="#008ACC" d="M36.77,174.42c0.59,0.2,31.43-0.08,36.4,0.22
        c4.97,0.3,18.36,0.26,19.64,0.04c1.82-0.32-7.75-1.74-14.93-1.76c-7.19-0.02-19.56-1.03-22.18-1.4
        c-5.45-0.76-21.07-1.67-21.41-0.42C33.96,172.35,35.84,174.11,36.77,174.42z"/>
      <path fill="#F2D5A5" d="M60.26,189.37c-1.62,1.06-5.3,1.37-6.92,1.23
        c-0.14-3.3-0.1-9.56-1.23-11.2c-1.13-1.65-3.71-1.51-5.27,0.5c-1.56,2.01-0.74,4.28,0.35,5.38c1.1,1.09,2.11,1.27,3.52,1.09
        c-1.29,6.17-2.22,12.25-1.71,18.37c0.21,2.54,1.15,6.83,1.47,7.66c1.35,0.82,4.42,2.22,8.32,3.17c3.62,0.88,9.64,1.76,12.19-2.19
        c5.08-0.84,8-5.16,9.45-9.98c-2.65-0.48-10.06-3.53-8.82-5.84c1.68,1.05,7.53,3.46,9.76,2.67c0.49-1.09-0.03-6.1-0.14-8.75
        c6.37-1.59,7.97,8.18,10.69,8.4c1.47,0.12,2.82-0.64,3-1.51c0.18-0.87-2.46-4.45-4.74-7.99c-2.28-3.54-3.9-5.76-6.83-7.45
        c-1.01-2.24-1.5-4.29-0.99-6.63c-7.66-0.46-16.3-0.43-24.56-0.47C56.85,179.5,59.55,188.02,60.26,189.37z"/>
      <path fill="#EBB886" d="M69.2,178.2c-3.82,0.08-7.71,0.04-11.58,0.18
        c-0.05-0.98,0-1.87,0.17-2.58c8.26,0.04,16.9,0,24.56,0.47c-0.18,0.85-0.24,1.65-0.18,2.44C77.88,178.34,73.62,178.11,69.2,178.2z
        "/>
      <path fill="#EBB886" d="M59.26,190.78c-2.37,0.6-4.77,0.81-7.14,0.25
        c0.3-2.38,0.77-4.75,0.94-7.13c0.18,2.3,0.21,4.91,0.29,6.68c1.24,0.12,3.71-0.05,5.52-0.61
        C58.98,190.25,59.12,190.51,59.26,190.78z"/>
      <path fill="#EBB886" d="M76.69,190.88c-0.56-1.86,0.33-4.32,3.01-4.43
        c1.59-0.07,2.87,0.46,3.91,1.35c-1.19-0.45-2.57-0.67-3.64,0.06c-0.8,0.55-1.45,1.38-0.87,2.23c0.46,0.66,1.25,1.16,2.15,1.54
        c0.03,0.71,0.09,1.55,0.14,2.44C79.3,193.63,77.23,192.7,76.69,190.88z"/>
      <path fill="#EBB886" d="M89.64,195.82c0.72,1.13,1.4,2.45,2.51,3.32
        c0.31,0.24,0.63,0.43,0.96,0.6c-0.38,0.09-0.79,0.14-1.21,0.1c-1.77-0.14-3.07-4.33-5.52-6.79
        C87.72,193.64,88.78,194.48,89.64,195.82z"/>
      <path fill="#EBB886" d="M73.89,198.66c1.6-0.07,3.18-0.02,4.74,0.2
        c0.96,0.26,1.91,0.62,2.83,0.99c-0.03,0.14-0.06,0.26-0.1,0.35C79.77,200.77,76.34,199.7,73.89,198.66z"/>
      <path fill="#EBB886" d="M75.84,193.04c0.02-0.01,0.04-0.02,0.06-0.03
        c-1.24,1.54-2.46,3.11-3.26,4.86c-0.03,0.06-0.05,0.12-0.08,0.18c-0.38-0.19-0.71-0.37-0.95-0.52c-0.29,0.55-0.09,1.14,0.41,1.73
        c-0.48,1.09-0.97,2.16-1.68,3.16c0.05-1.52,0.31-3.1,0.87-4.55C72.55,196.13,74.16,194.58,75.84,193.04z"/>
      <path fill="#EBB886" d="M71.24,183.96c-1.55,0.11-3.1,0.1-4.64,0.21
        C67.95,183.18,69.64,183.09,71.24,183.96z"/>
      <path fill="#EBB886" d="M66.93,212.45c0.63-2.68,4.42-1.35,5.46,0.55
        c-0.46,0.15-0.92,0.27-1.42,0.36c-0.02,0.03-0.04,0.05-0.06,0.08c-0.34-0.74-0.84-1.4-1.8-1.44c-0.17-0.01-0.3,0.06-0.4,0.15
        c-0.61-0.06-1.22,0.09-1.79,0.33C66.93,212.46,66.93,212.45,66.93,212.45z"/>
      <path fill="#EBB886" d="M68.38,182.16c0.92-0.01,1.72,0.24,2.42,0.66
        c-1.78-0.6-3.61-0.24-5.05,0.96C66.28,182.88,67.12,182.17,68.38,182.16z"/>
      <path fill="#EBB886" d="M79.56,205.74c-0.34,0.78-0.72,1.53-1.15,2.24
        c-2.42-0.3-4.91-1.5-4.15-3.85C75.79,205.28,77.57,206.09,79.56,205.74z"/>
      <path fill="#EBB886" d="M68.7,215.39c-2.95,1.51-7.13,0.84-9.91,0.16
        c-3.9-0.95-6.96-2.35-8.32-3.17c-0.07-0.18-0.17-0.54-0.28-0.99C55.47,214.42,62.05,215.59,68.7,215.39z"/>
      <path fill="#EBB886" d="M50.5,180.45c0.01,0.06,0.04,0.12,0.09,0.17
        c0.32,0.35,0.54,0.79,0.67,1.26c-0.08,0.43-0.31,0.87-0.67,1.26c-0.34,0.37-0.84,0.8-1.41,0.85c-0.69,0.05-1.02-0.49-1.23-0.98
        c-0.04-0.11-0.12-0.17-0.2-0.21c-0.12-0.65,0-1.33,0.51-1.83C48.92,180.32,49.81,180.07,50.5,180.45z"/>
      <path d="M72.56,182.32c1.58,0.73,7.91,1.04,8.23,0.87
        C80.25,182.51,73.32,181.84,72.56,182.32z"/>
      <path fill="#008ACC" d="M69.61,224.52c3.41,9.39,25.27,63.59,29.27,81.06
        c3.83,16.7,19.62,50.26,23.63,62.22c0.03,0.09,9.99,22.46,10.02,22.55c0,0-4.44,2.58-10.71,5.87c-3.68,1.93-7.99,4.11-12.39,6.14
        c-24.65,11.41-61.06,26.42-68.42,25.8c2.99-5.69,4.01-10.83,4.7-16.56c0.97-7.97,0.6-16.5-2.19-24.24
        c-4.79-13.29-15.31-42.73-17.39-50.74c4.18-1.28,14.78-2.61,19.76-3.04c-1.3-3.42-5.45-11.35-9.77-15.34
        c-4.45-0.38-10.09,0.1-15.13,1.03c-8.36-36.9-1.65-81.41,15.52-102.91c-1.05-2.49-2.01-6.93-1.18-8.78
        c1.75-0.22,9.29,0.53,11.79,1.04c0.02,0.3,0.29,1.12,0.26,1.52c-2.14-0.63-7.74-1.48-9.39-1.45c-0.44,0.27-0.75,0.52-0.75,0.82
        c9.27,2.23,17.89,8.92,27.91,8.59c4.84,2.47,10.88,2.79,14.66,4.52C76.49,223.74,72.52,222.74,69.61,224.52z"/>
      <path fill="#006AA7" d="M42.31,209.16c-0.06-0.4-0.09-0.79-0.11-1.19
        c1.99,0.21,3.89,0.46,4.92,0.67c0.02,0.3,0.29,1.12,0.26,1.52C46.28,209.84,44.24,209.45,42.31,209.16z"/>
      <path fill="#006AA7" d="M72.57,326.52c2.9-1.67,6.1-2.58,9.41-3.09
        c-2.31,1.83-4.27,4.02-6.94,5.45c-2.91,1.57-5.89,3.04-8.9,4.42C67.61,330.63,69.66,328.2,72.57,326.52z"/>
      <path fill="#006AA7" d="M86.64,319.45c-1.11,1-2.31,1.93-3.39,2.96c0,0,0,0-0.01,0
        c-1.76-4.63-3.52-9.27-5.24-13.92c-1.66-6.04-3.36-12.07-5.38-18.01c-0.02-0.08-0.07-0.13-0.12-0.17
        c-0.79-2.96-1.75-5.87-3.13-8.65c-1.14-4.62-2.09-9.3-4.04-13.69c-0.04-0.09-0.11-0.15-0.2-0.2c-0.12-0.36-0.23-0.71-0.35-1.07
        c-1.05-3.98-2.09-7.97-3.16-11.94c-1.1-4.13-1.73-8.58-2.99-12.77c5.43,11.95,9.75,24.28,13.65,36.74
        c2.19,6.98,4.19,14.09,6.89,20.92c2.54,6.44,5.77,12.7,7.17,19.47C86.36,319.31,86.49,319.41,86.64,319.45z"/>
      <path fill="#006AA7" d="M88.15,333.15c-1.21,3.09-2.65,5.62-5.66,7.7
        c-3.55,2.45-8.28,4.5-12.85,4.64c-0.59-0.1-1.17-0.2-1.76-0.31c3.09-0.82,5.87-2.32,8.32-4.54c2.17-1.96,4.1-4.1,6.12-6.15
        c1.7-1.46,3.49-2.85,5.11-4.39c0.92-0.52,1.89-0.95,2.92-1.27C89.34,330.13,88.74,331.65,88.15,333.15z"/>
      <path fill="#006AA7" d="M81.01,342.82c2.45-1.45,5.06-3.3,6.37-5.7
        c0.74-1.34,1.23-2.9,1.83-4.41c-0.73,4.09-0.36,8.49-4.85,11.21c-3.38,2.05-7.36,2.36-11.36,2.01
        C75.87,345.35,78.66,344.21,81.01,342.82z"/>
      <path fill="#006AA7" d="M132.53,390.36l-10.71,5.87c-1-8.79-9.42-44.47-12.43-52.78
        c-2.95-8.16-5.48-16.6-6.66-25.2C108.89,335.57,129.5,381.15,132.53,390.36z"/>
      <path fill="#006AA7" d="M47.71,223.09c-3.96-1.96-6.79-5.15-9.98-7.92
        c8.21,3.42,16.57,6.52,25.37,8.69C58.07,225.1,52.13,225.27,47.71,223.09z"/>
      <path fill="#006AA7" d="M27.9,342.66c-0.1-0.33-0.2-0.66-0.3-0.97
        c4.92-1.71,9.58-3.95,14.3-6.08C37.78,338.69,32.88,340.87,27.9,342.66z"/>
      <path fill="#006AA7" d="M27.35,340.89c-0.51-1.7-0.92-3.14-1.21-4.25
        c3.7-1.13,12.4-2.3,17.82-2.85C38.44,336.2,33.07,338.89,27.35,340.89z"/>
      <path fill="#006AA7" d="M48.92,305.48c-3.04-11.49-6.43-22.88-9.88-34.27
        c-2.03-6.69-11.93-32.37,1.32-35.17c-2.34,2.74-1.88,8.02-1.69,10.69c0.36,5.04,1.42,9.96,2.5,14.89
        c1.24,5.66,2.37,11.31,3.97,16.89c1.85,6.45,3.94,12.84,5.93,19.25c3.82,12.3,9.47,23.95,13.75,36.09
        c-0.01,0.02-0.02,0.03-0.03,0.05c-0.43,0.19-0.86,0.39-1.3,0.58c-0.23,0.1-0.31,0.3-0.29,0.48c-0.97,0.24-2.44,0.58-3.81,1.02
        C54.18,326.43,51.66,315.83,48.92,305.48z"/>
      <path d="M61.2,334.64c-1.65-2.22-1.95-5.06-2.7-7.61
        c-0.01-0.04,0.06-0.07,0.08-0.03c1.07,2.48,1.63,5.09,3.26,7.32c0.24-0.13,0.48-0.26,0.69-0.41
        c-3.32-7.61-15.19-41.68-18.74-54.22c-4.92-17.37-6.39-28.84-6.56-35.17c-0.16-6.33,1.72-8.33,3.68-9.87
        c1.96-1.54,4.31-1.89,5.44-0.84c-1.78,0.59-4.1,1.82-6.01,4.3c-1.43,1.85-1,10.46,0.61,18.84c0.54,2.8,2.63,12.59,6.04,23.45
        c6.77,21.61,17.03,49.82,18.68,52.26c5.48-2.39,13.02-7.47,16.1-9.9c-0.25-0.74-6.71-21.78-12.95-42.32
        c-1.31,2.66-5.55,4.11-8.79,3.76c-0.04-0.01-0.03-0.06,0.01-0.06c4.04-0.15,7.94-2.02,8.18-5.66c-0.01-0.03-0.02-0.05-0.02-0.08
        c-1.44,2.27-6.43,3.13-8.77,3.52c-0.02,0-0.03-0.03-0.01-0.04c3.17-0.85,7.74-1.94,8.27-5.15c-4.95-16.35-9.51-31.56-10.35-34.96
        c-0.87-3.51-0.11-4.1,0.7-4.41c1.96,11.1,25.08,82.79,25.55,84.01c0.18-0.15,0.36-0.3,0.53-0.45c-1.91-5.07-2.6-10.49-4.42-15.59
        c0-0.01,0.01-0.01,0.02,0c1.9,4.97,3.25,10.11,4.94,15.14c2.25-1.92,4.11-3.57,5.1-3.3c-5.01,8.57-28.03,20.69-34.11,21.17
        C55.24,336.55,58.67,335.8,61.2,334.64z"/>
      <path fill="#009CDD" d="M42.5,331.98c-0.8-2.74-5.16-10.22-7.24-11.86
        c-4.35-0.73-12.42,0.51-13.79,0.91c0.57,2.93,2.72,10.5,3.84,13.29C29.31,333.26,38.77,332.21,42.5,331.98z"/>
      <path fill="#0072B8" d="M33.05,323.61c2.43,0.56,3.78,2.47,2.97,4.65
        c-0.01,0.04-0.03,0.09-0.04,0.13c-1.21,1.18-3.31,1.86-5.03,1.96c-1.15,0.07-2.86,0.02-3.26-1.18c-0.3-0.91-0.17-1.92,0.28-2.81
        C28.86,324.58,30.72,323.07,33.05,323.61z"/>
      <path fill="#0072B8" d="M26.44,334.06c-0.4,0.09-0.79,0.18-1.12,0.26
        c-1.02-2.53-2.87-8.96-3.64-12.33C23.72,325.91,25.58,329.84,26.44,334.06z"/>
      <path d="M29.17,329.93c1.17,0.14,2.69,0.14,4.01-0.2
        c-3.01-0.04-6.04-2.61-5.99-4.68c0.06-2.24,3.13-2.75,5.07-1.67c2.16,1.21,3.55,3.26,3.44,4.71c0.21-0.37,0.34-0.81,0.34-1.32
        c0-0.06,0.1-0.09,0.13-0.03c1.29,3.62-4.23,4.89-7.24,4.25C28.18,330.83,28.4,329.83,29.17,329.93z"/>
      <path fill="#608577" d="M29.39,324.12c-1.06,0.56-0.93,2.95,2.23,3.96
        c2.28,0.72,3-1.04,1.12-2.96C31.93,324.3,30.45,323.56,29.39,324.12z"/>
      <path d="M66.61,345.14c0.5,1.08,3.04,0.87,5.49-0.39
        c2.62-1.35,6.67-4.44,10.15-7.91c3.48-3.47,7.17-6.79,9.43-7.97c-1.15-0.98-4.13,0.84-6.72,3.38
        C82.35,334.78,73.14,343.58,66.61,345.14z"/>
    </g>
    <path d="M76.56,177.1c2.12,0.46,2.69,2.64,2.91,4.31c0.01,0.09-0.17,0.13-0.19,0.04
      c-0.3-1.25-0.69-2.62-2.09-3.21c-0.53-0.22-1.29-0.17-1.79,0.15c-0.55,0.34-0.62,1.12-0.81,1.63c-0.16,0.44-0.86,0.57-1.05,0.09
      C72.88,178.44,74.56,176.66,76.56,177.1z"/>
    <path d="M68.95,185.29c3-0.93,6.03-0.25,8.79,0.75c0.06,0.02,0.02,0.11-0.04,0.09
      c-2.83-0.6-5.71-0.62-8.62-0.23C68.67,185.96,68.56,185.41,68.95,185.29z"/>
    <path d="M70.16,202.46c0-2.15,0.52-4.18,1.79-6.07c1.27-1.88,3.15-3.06,5.06-4.39
      c0.02-0.01,0.04,0.01,0.03,0.03c-1.61,1.37-3.21,2.97-4.38,4.65c-1.23,1.76-1.61,3.82-1.92,5.8
      C70.68,202.77,70.16,202.75,70.16,202.46z"/>
    <path d="M74.08,204.05c1.6,1.15,3.85,1.8,5.95,1.34c0.8-0.17,1.22,0.88,0.41,1.07
      c-2.36,0.54-5.07-0.76-6.44-2.34C73.96,204.07,74.04,204.01,74.08,204.05z"/>
    <path d="M78.18,188.49C78.18,188.49,78.19,188.49,78.18,188.49
      c-0.38,1.08,0.4,1.97,1.26,2.59c0.2,0.14,0.39,0.24,0.59,0.33c-1.25-0.77-1.88-2.11-0.77-3.48c1.28-1.59,3.93-0.72,5.29,0.11
      c0.05,0.03,0,0.1-0.06,0.08c-1.15-0.44-2.55-0.48-3.71,0.09c-0.66,0.33-0.98,1.02-1.01,1.65c-0.05,1.09,1.68,1.4,2.57,1.55
      c0.39,0.06,0.36,0.45,0.09,0.6c0.04,0.12-0.01,0.27-0.17,0.33c-1.18,0.42-2.65-0.26-3.49-0.91
      C77.75,190.63,77.72,189.56,78.18,188.49z"/>
    <path d="M47.55,181.63c0.14-1.25,1.18-1.65,2.38-1.2c0.03,0.01,0.01,0.06-0.02,0.05
      c-1.1-0.29-1.74,0.32-1.72,1.21c0.88-0.56,1.67,0.01,1.99,0.3c0.04,0.04,0,0.11-0.06,0.09c-0.34-0.14-1.55-0.57-1.79,0.3
      c0.17,0.87,0.66,1.58,1.52,1.81c0.3,0.08,0.02,0.41-0.3,0.38C47.97,184.43,47.42,182.82,47.55,181.63z"/>
    <path d="M53.78,164.43c0.02-0.04,0.08-0.01,0.07,0.03
      c-0.76,1.93-0.72,3.88-0.69,5.87c0.01,0.2-0.35,0.22-0.35,0.03C52.88,168.34,52.73,166.32,53.78,164.43z"/>
    <path d="M51.98,165.6c0.02-0.06,0.12-0.05,0.1,0.01c-0.32,1.5-0.37,2.97-0.24,4.47
      c0.01,0.21-0.33,0.23-0.37,0.03C51.19,168.62,51.56,167.06,51.98,165.6z"/>
    <path d="M48.89,164.12c0.01-0.04,0.08-0.03,0.07,0.01
      c-0.27,1.68-0.24,3.39,0.28,5.01c0.06,0.17-0.23,0.27-0.3,0.1C48.2,167.59,48.47,165.85,48.89,164.12z"/>
    <path d="M47.87,165.25c0.01-0.05,0.1-0.04,0.1,0c-0.05,0.66-0.05,1.32,0.02,1.98
      c0.08,0.77,0.42,1.48,0.51,2.24c0.02,0.13-0.2,0.21-0.27,0.09C47.49,168.39,47.73,166.57,47.87,165.25z"/>
    <path d="M51.66,149.35c1.92,0.22,3.49,1.42,4.91,2.51c0,0,0.01,0,0.01,0
      c0.67,0.34,1.27,0.76,1.92,1.12c0.09-0.13,0.25-0.22,0.47-0.14c3.59,1.27,8.98,4.6,13.01,2.56c0.05-0.03,0.13,0.03,0.08,0.07
      c-3.58,2.9-8.89,0.41-12.45-1.48c1.72,1.1,3.54,2.09,5.45,2.88c0.02,0.01,0.01,0.04-0.02,0.03c-2.5-0.89-4.94-1.95-7.06-3.41
      c-2.11-1.45-3.84-2.99-6.47-3.74C51.25,149.67,51.41,149.32,51.66,149.35z"/>
    <path d="M47.18,154.31c0.05-0.06,0.15,0,0.12,0.07c-1.52,3-0.89,6.3-0.2,9.33
      c0.1,0.43-0.64,0.69-0.75,0.25C45.58,160.84,45.01,157.21,47.18,154.31z"/>
    <path d="M80.38,169.09c-0.03-0.08,0.11-0.13,0.15-0.05
      c0.49,1.14,0.99,2.26,1.19,3.48c0.05,0.3-0.48,0.34-0.53,0.04C80.98,171.39,80.75,170.23,80.38,169.09z"/>
    <path d="M80.08,170.76c-0.1-0.71-0.28-1.41-0.48-2.1c-0.01-0.03,0.05-0.07,0.07-0.03
      c0.33,0.58,0.54,1.17,0.71,1.81c0.15,0.62,0.38,1.37,0.2,2c-0.03,0.13-0.26,0.17-0.31,0.03C80.11,171.94,80.17,171.33,80.08,170.76
      z"/>
    <path d="M72.66,199.05c-0.12-0.13-0.24-0.26-0.37-0.38
      c1.54-0.04,3.09-0.06,4.62,0.03c1.87,0.11,3.47,0.88,5.29,1.15c0.06,0.01,0.05,0.09-0.01,0.1c-1.46,0.2-2.77-0.48-4.16-0.71
      c-1.73-0.29-3.54-0.13-5.31-0.15C72.7,199.08,72.68,199.07,72.66,199.05z"/>
    <path d="M78.79,204.8c0.43-0.75,0.55-1.55,0.48-2.36c-0.01-0.09,0.15-0.12,0.18-0.04
      c0.28,0.87,0,1.69-0.58,2.44C78.84,204.88,78.77,204.84,78.79,204.8z"/>
    <path d="M78.32,204.48c0.37-0.81,0.54-1.61,0.36-2.45
      c-0.05-0.21,0.29-0.34,0.36-0.12c0.3,0.92-0.05,1.8-0.59,2.63C78.41,204.61,78.29,204.56,78.32,204.48z"/>
    <path d="M66.77,212.09c1.25-0.55,2.88-0.75,3.84,0.27c0.84,0.89,0.84,2.26,0.16,3.27
      c-0.03,0.04-0.11,0.03-0.09-0.02c0.27-0.99,0.33-1.87-0.31-2.73c-0.77-1.02-2.31-0.82-3.44-0.39
      C66.63,212.61,66.49,212.21,66.77,212.09z"/>
    <path d="M24.99,338.21c2.87-0.84,5.91-1.3,8.83-1.99c2.67-0.63,5.33-1.13,8.06-1.41
      c0.08-0.01,0.1,0.1,0.03,0.12c-2.94,0.6-5.86,1.33-8.77,2.05c-2.63,0.66-5.3,1.59-8.01,1.9C24.7,338.92,24.57,338.33,24.99,338.21z
      "/>
    <path d="M25.15,337.42c2.36-0.87,5.06-1.27,7.55-1.56c0.1-0.01,0.1,0.11,0.01,0.13
      c-2.53,0.44-4.89,1.36-7.39,1.89C24.98,337.95,24.8,337.55,25.15,337.42z"/>
    <path d="M29.57,324.74c0.98-0.28,2.05,0.14,2.64,0.79c0,0.01-0.01,0.02-0.02,0.01
      c-0.58-0.46-1.48-0.67-2.27-0.49c-1.02,0.23-0.74,0.95-0.69,1.62c0.01,0.23-0.33,0.34-0.48,0.15
      C28.16,326.1,28.57,325.03,29.57,324.74z"/>
    <path fill="#FFEBC5" d="M70.76,186.73c-1.53-0.25-4.81,0.15-5.5,0.37
      c-0.69,0.23,1.76,3.17,2.54,3.49C68.59,190.91,72.89,187.09,70.76,186.73z"/>
    <path fill="#FFEBC5" d="M90.35,191.71c1.06,1.68,2.9,4.72,3.36,5.34
      c0.46,0.62-2.61-1.35-3.2-2.31C89.92,193.78,89.24,189.96,90.35,191.71z"/>
    <path fill="#72B3EE" d="M59.16,218.41c-3.72-0.76-11.39-3.96-14.84-5.69
      c-3.45-1.73,1.86,4.23,5.16,5.57C52.78,219.63,64.93,219.59,59.16,218.41z"/>
    <path fill="#72B3EE" d="M44.55,236.68c-4.2,1.61-4.53,8.77-2.6,16.51
      C43.89,260.93,47.03,235.73,44.55,236.68z"/>
    <path fill="#72B3EE" d="M21.29,262.21c-0.8,4.29-2.74,16.78-2.54,26.63
      c0.2,9.86,4.53-9.43,4.46-12.93C23.15,272.4,22.14,257.74,21.29,262.21z"/>
    <path fill="#72B3EE" d="M58.69,155.7c-1.9-1.31-4.55-3.58-6.41-4.05
      c-1.86-0.46,0.59,3.19,1.98,4.04C55.65,156.55,62.17,158.1,58.69,155.7z"/>
    <path fill="#72B3EE" d="M48.37,171.66c-2.91-0.65-8.05-0.71-9.43-0.35
      C32.89,172.84,56.02,173.37,48.37,171.66z"/>
    <path d="M78.03,182.89c0,0-2.96-0.44-5.03-0.55c-0.79,0.72-0.5,2.7,1.56,3.03
      C77.71,185.87,78.03,182.89,78.03,182.89z"/>
    <path fill="#FFFFFF" d="M73.03,183.61c0.07,0.2,0.32,0.3,0.55,0.22
      c0.22-0.07,0.35-0.3,0.27-0.49c-0.07-0.2-0.32-0.3-0.55-0.22C73.08,183.19,72.96,183.41,73.03,183.61z"/>
    <path fill="#FFFFFF" d="M73.97,183.17c0.05,0.14,0.22,0.21,0.37,0.15
      c0.15-0.05,0.24-0.2,0.19-0.33c-0.05-0.14-0.21-0.2-0.37-0.15C74,182.89,73.91,183.03,73.97,183.17z"/>
  </g>
  <g id="item_9_">
    <path fill="#FFA953" d="M551.23,385.67c-0.53,3.56-2.31,6.29-4.9,8.91c-1.34,1.35-2.91,2.9-3.69,4.11
      c-0.77,1.2-2.88-1.81-5.52-6.08c-2.64-4.27-4.06-10.1-2.29-10.95c1.85-0.9,4.02-3.21,5.53-5.42c1.51-2.21,5.22-4.31,7.59-4.11
      C550.32,372.32,551.71,382.43,551.23,385.67z"/>
    <path fill="#EB9124" d="M546.33,394.57c-0.26,0.27-0.54,0.54-0.81,0.82c-2.25-2.01-2.6-5.39-1.89-8.12
      c0.28-1.06,0.65-2.3,0.35-3.39c-0.33-1.18-0.93-2.24-1.35-3.39c-0.57-1.57-0.59-2.98-0.27-4.6c0.13-0.65,0.2-1.32,0.38-1.95
      c1.67-1.16,3.71-1.95,5.21-1.82c2.38,0.2,3.77,10.31,3.28,13.54C550.7,389.23,548.92,391.96,546.33,394.57z"/>
    <path d="M539.91,377.75c-1.71,2.03-3.74,5.03-6.54,5.85c-0.71,0.21-1.43-1.6-0.8-1.7c2.89-0.46,5.35-3.8,7.78-6.35
      c1.99-2.08,4.4-3.66,7.36-3.76c0.26-2.49,5.52-4.2,11.53-4.16c5.84,0.04,11.06,2.52,16.41,4.3c3.19,1.06,6.55,1.59,9.92,1.54
      c3.21-0.05,6.06-1.17,9.02-2.17c4.85-1.63,9.44-1,15.35,3.46c1.94-0.18,4.17-0.11,5.18,0.11c0.11-0.91,1.21-8.64,7.6-13.75
      c-4.04,1.73-13.34,6.13-17.4,7.86c-1.14,0.49-5.77-3.77-4.72-4.42c4.79-2.98,13.78-5.22,19.17-7.27c-2.65-3.44-3.96-5.27-6.03-6.64
      c-0.47-0.31-2.67-1.09-2.42-1.52c2.4-4.29,8.12-10.78,13.47-10.52c6.82,0.33,9.84,7.63,12.58,12.19
      c3.78-1.37,10.39-3.73,13.96-5.41c0.87-0.41,1.16,5.5,0.2,5.7c-3.24,0.69-7.22,2.18-11.3,3.44c4.62-0.39,7.77,0.56,10.95,1.48
      c9.79,2.85,18.05,10.13,24.35,18.61c3.92,5.28,7.16,10.61,10.42,16.25c2.91,5.05,5.74,9.83,6.47,15.59
      c0.88,6.9-3.66,9.7-7.32,10.48c-2.67,8.67-11.9,17-20.96,19.51c-1.28,0.36-2.57,0.59-3.87,0.74c4.64,1.69,12.58,3.52,18.64,4.28
      c4.2-0.47,9.45-0.72,12.53,1.51c4.88,3.55-10.4,11.11-13.08,12.56c-2.67,1.44-5.07,3.39-7.38,4.8c0.9,1.33,2.04,3.09,3.38,4.55
      c0.33-0.21,0.58-0.55,0.84-0.82c0.37-0.37,0.91-0.62,1.39-0.84c1.13-0.5,2.47-0.44,3.36,0.38c0.99-0.02,2,0.24,2.94,0.85
      c1.1,0.01,1.95,0.28,2.19,0.96c0.35,0.99-2.9,3.98-6.25,5.16c-3.35,1.19-5.76,2.91-9.29,0.62c-3.44-2.23-5.1-6.27-6.72-9.64
      c0.11,6.8-3.49,42.88-5.58,45.62c-2.12,0.66-5.1,1.13-8.2,1.22c2.22,11.54,4.23,23.41,4.02,35.17c-0.79,0.51-1.82,0.76-3.1,1.03
      c12.09,1.13,25.52,3.62,31.82,10.02c1.27,1.29-7.32,3.38-14.08,3.39c0.48,0.61,0.57,1.56-0.18,1.62
      c-12.73,1.15-28.93,0.01-44.61-1.99c-7.4-0.94-31.93-5.04-34.34-8.42c-2.66-0.03-8.4-0.31-10.08-1.16
      c5.79-13.44,5.47-29.67,5.75-43.91c-2.08-0.29-9.3-1.04-11.21-1.84c-0.94-5.82-0.78-13.19-0.18-19.58
      c2.15-23.17-1.89-46.68,1.67-69.8c1.43-9.33,6.26-15.16,12.77-22.44c3.04-3.4,8.25-7.45,12.24-9.83
      c-7.6-4.85-15.11,2.89-19.67,4.53c-4.21,1.51-9.32,0.79-13.55-0.13c-2.66-0.58-5.09-1.34-7.88-1.17c-2.74,0.17-4.91,1.79-6.53,3.99
      c-0.2,0.27-0.57,0.28-0.86,0.22c-0.07,1.69-1.25,3.33-2.29,4.65c-0.96,1.23-2.09,2.35-3.14,3.5c-1.07,1.16-1.77,2.45-2.68,3.69
      c-0.37,0.5-1.25,0.23-1.1-0.41c0.49-2.03,2.79-3.77,4.25-5.24c1.99-2,3.51-3.86,4.18-6.4c-1.49,1.81-3.24,3.34-5.07,4.87
      c-1.91,1.59-3.09,3.55-4.25,5.65c-0.07,0.13-0.25,0.09-0.25-0.05c-0.03-1.54,1.4-3.5,2.47-4.64c2.09-2.23,4.75-3.91,6.72-6.24
      c-0.91,0.46-1.71,1.09-2.62,1.56c-1.39,0.71-2.66,1.26-3.83,2.3c-1.86,1.66-2.88,3.84-3.91,5.99c-0.09,0.18-0.41,0.09-0.4-0.1
      c0.37-5.5,5.83-8.62,10.7-10.54c-0.42-0.65-0.89-2.51-0.93-2.68c-2.68,1.21-5.22,2.44-7.55,4.19c-1.98,1.49-4.55,4.16-4.58,6.68
      c-0.01,0.18-0.32,0.26-0.36,0.06c-0.45-2.07,1.06-3.95,2.31-5.55c2.35-2.99,5.96-5.58,9.97-6.2c-0.13-0.47-0.26-0.93-0.38-1.4
      c-1.54,0.4-3.39,1.53-4.97,2.48c-0.12,0.07,0.23-0.35,0.68-0.79c1.12-1.08,2.15-2.32,4-2.72c-0.14-0.57-0.4-2.02-0.47-2.4
      c-1.11,0.65-2.26,1.74-3.16,2.93c-0.99,1.28-1.64,2.62-2.86,3.76c-0.96,0.9-2.2,1.55-3.39,2.14c-1.03,0.51-3.42,1.17-3.72,2.36
      c-0.15,0.58-1.47-1.11-0.92-1.59c0.61-0.52,1.54-0.92,2.13-1.15c3.28-1.3,6.23-2.93,8.33-5.81c0.95-1.29,2.23-2.16,3.51-3.12
      c-0.07-0.39-0.13-0.79-0.19-1.2c-2.69,1.37-4.23,3.71-6.36,5.72c-1.92,1.81-4.65,3.62-7.23,4.42c-0.7,0.22-0.67-1.41-0.17-1.61
      c3.4-1.34,6.34-2.95,8.97-5.38c1.49-1.37,2.84-2.79,4.71-3.69c-0.03-0.31-0.06-0.61-0.08-0.92c-5.39,1.91-8.18,7.5-13.63,9.49
      c-0.17,0.06-0.26-0.15-0.1-0.23c2.89-1.58,5.31-3.52,7.6-5.79c2-1.99,3.27-3.94,6.17-4.86c0.05-0.46,0.12-0.91,0.26-1.34
      C544.12,372.95,541.94,375.33,539.91,377.75z"/>
    <path fill="#9F9A8E" d="M681.3,452.36c1.23-0.53,7.75-4.23,9.01-5.85c2.3-2.95-2.12-5.35-14-2.93
      c4.01,0.22,8.63,4.18,3.39,7.71C680.3,451.47,681.03,451.98,681.3,452.36z"/>
    <path fill="#FFFFD2" d="M670.8,445.05c-4.33,0.51-7.63,2.5-7.51,4.44c0.12,1.94,6.02,2.89,11.29,1.57
      c5.27-1.32,6.84-3.15,4.8-4.98C677.97,444.82,675.13,444.54,670.8,445.05z"/>
    <path fill="#E6C558" d="M664.15,447.66c0.58,0.96,1.68,1.46,2.95,1.61c2.4,0.28,5.08,0.24,7.44-0.3
      c1.25-0.29,3.3-0.8,3.2-2.39c-0.03-0.54-0.22-1.08-0.53-1.51c0.92,0.2,1.65,0.54,2.17,1.01c2.05,1.83,0.47,3.66-4.8,4.98
      c-5.27,1.32-11.17,0.37-11.29-1.57C663.25,448.87,663.57,448.24,664.15,447.66z"/>
    <path fill="#D2A732" d="M667.8,451.07c2.84,0,5.66-0.2,8.36-1.06c1.21-0.39,2.99-1.12,3.14-2.51
      c0.07-0.6-0.05-1.19-0.31-1.71c0.13,0.09,0.26,0.18,0.38,0.29c2.05,1.83,0.47,3.66-4.8,4.98c-4.22,1.06-8.84,0.65-10.58-0.54
      C665.16,450.97,666.46,451.07,667.8,451.07z"/>
    <path fill="#88582F" d="M675.71,455.32c0.8-0.46,3.37-1.8,4.23-2.2c-0.27-0.51-0.98-0.85-1.9-1.1
      c-0.63,0.27-2.27,0.98-4.02,1.27C674.11,453.91,674.96,454.81,675.71,455.32z"/>
    <path fill="#654416" d="M674.49,454.18c-0.24-0.31-0.43-0.62-0.47-0.88c1.75-0.28,3.39-1,4.02-1.27
      c0.32,0.09,0.62,0.19,0.88,0.3C677.55,453.22,676.06,453.77,674.49,454.18z"/>
    <path fill="#F2D4B1" d="M675.78,464.36c-0.36,0.32-0.55,0.77-0.87,1.11c0.15,0.14,0.29,0.29,0.44,0.42
      c1.04-1.2,2.45-2.02,3.94-2.23C678.04,463.14,676.93,463.35,675.78,464.36z"/>
    <path fill="#F2D4B1" d="M675.89,466.31c0.08,0.06,0.16,0.14,0.25,0.2c1.38-0.9,3.55-1.68,5.41-1.95
      C679.53,463.9,676.98,464.54,675.89,466.31z"/>
    <path fill="#F2D4B1" d="M671.14,470.33c3.39,1,8-1.38,9.09-1.94c2.12-1.1,3.43-2.33,2.37-2.27c-1.06,0.06-4.8,0.9-6.61,2.21
      c-2.72-1.74-4.89-4.68-6.32-7.17c-1.45,0.72-0.69-0.73,0-1.53c1.37-1.57,2.84-2.36,4.83-3.4c-0.72-0.47-1.83-1.58-2.32-2.33
      c-2.2,0.28-8.48,4.2-8.52,5.51C663.63,460.41,668.1,469.44,671.14,470.33z"/>
    <path fill="#FFFFFF" d="M670.22,453.46c-2.56,0.03-4.85-0.09-7.16-0.93c-0.04,1.92-0.06,3.3-0.15,5.18
      C665.2,455.79,667.23,454.4,670.22,453.46z"/>
    <path fill="#9F9A8E" d="M662.8,444.6c3.24-1.24,6.79-1.89,10.29-2.38c-3.77-0.39-7.53-0.92-11.29-1.7
      C662.26,441.85,662.58,443.21,662.8,444.6z"/>
    <path fill="#FF8B00" d="M614.43,392.33c-1.38-5.06-2.51-10.39-2.5-15.68c-2.28,0.31-4.47,1.1-6.58,2.12
      c-0.33,0.44-0.59,0.86-0.77,1.18c-2.57,4.8-1.53,11.34-0.88,16.47c1.28,10.01,4.87,19.69,11.33,27.83
      c6.05,7.62,14.38,13.85,23.8,17.45c4.42,1.69,9.1,2.44,13.85,2.59c2.08,0.07,4.27,0.02,6.25-0.61c0.25-0.08,0.46-0.18,0.66-0.29
      c-0.28-2.29-0.76-4.28-1.7-6.01c-6.01,0.15-12.01-1.69-17.14-4.63C626.15,424.35,618.47,407.15,614.43,392.33z"/>
    <path fill="#867CBD" d="M627.37,341.26c-5.79-3.08-10.3,3.29-12.69,7.54c1.81,1.28,3.4,2.78,4.79,4.43
      c4.25-2.73,10.33-4.58,14.37-5.69C632.13,345.05,630.16,342.73,627.37,341.26z"/>
    <path fill="#695CAD" d="M622.67,347.85c2.95-1.1,6.06-1.99,9.1-3.02c0.75,0.85,1.43,1.77,2.07,2.7
      c-4.04,1.1-10.11,2.96-14.37,5.69c-0.84-1.01-1.77-1.95-2.77-2.83C618.67,349.39,620.75,348.57,622.67,347.85z"/>
    <path fill="#FFBD47" d="M634.02,348.21c-4.36,1.36-9.36,3.57-13.72,5.92c0.42,0.57,0.82,1.16,1.18,1.76
      c1.52-0.49,12.39-3.81,14.6-4.51C635.4,350.33,634.73,349.26,634.02,348.21z"/>
    <path fill="#DEAC18" d="M624.11,352.19c1.11,0.76,2.51,1.32,3.93,1.66c-3.01,0.93-5.85,1.8-6.57,2.03
      c-0.36-0.6-0.77-1.18-1.18-1.76C621.52,353.46,622.81,352.81,624.11,352.19z"/>
    <path fill="#F2D4B1" d="M683.14,418.82c-3.37,0.82-7.11,0.87-10.54,0.21c-10.05-1.93-17.67-9.63-23.33-17.19
      c-5.85-7.81-10.58-16.86-3.02-24.92c1.47-1.56,4.12-2.53,2.55-1.06c-8.71,8.21-2.98,17.45,1.76,23.98
      c4.89,6.75,11.49,12.73,19.68,16.23c2.35,1,5.73,1.06,8.13,0.91c3.01-0.19,7.38-2.08,9.53-4.05c5.37-4.92,0.81-13.68-1.92-18.76
      c-5.21-9.69-11.66-20.1-20.03-27.77c-15.23-13.97-40.91-14.81-47.27,4.57c-2.32,7.06,0.47,16.07,2.48,22.94
      c2.43,8.32,6.14,16.4,11.24,23.59c4.14,5.84,9.62,11.03,16.59,13.86C662.28,436.75,677.59,430.86,683.14,418.82z"/>
    <path fill="#FFEDD6" d="M679.19,424.77c-3.89,4.29-9.29,7.17-15.16,8.19C665.14,430.3,676.2,424.84,679.19,424.77z"/>
    <path fill="#FFEDD6" d="M665.94,366.4c8.37,7.67,14.81,18.08,20.03,27.77c2.44,4.53,6.3,11.98,3.31,17.03
      c-0.33-2.66-0.79-5.31-1.82-7.71c-1.53-5.54-5.04-12.03-7.79-16.82c-4.97-8.64-10.92-15.15-17.05-20.7
      c-2.62-2.37-10.06-7.45-10.77-7.88C656.92,359.8,661.82,362.61,665.94,366.4z"/>
    <path fill="#B6DBDE" d="M660.83,548.06c7.47,1.54,14.8,3.7,17.18,6.38c-1.73,0.88-7.41,1.61-11.97,1.3
      c-1.12-1.58-3.88-3.27-7.27-4.79C659.09,549.7,659.83,548.75,660.83,548.06z"/>
    <path fill="#73B3B9" d="M657.7,550.63c-5.93-2.56-13.11-4.51-17.44-4.52c1.37-0.18,2.74-0.36,4.11-0.55
      c3.44,0.31,9.31,1.04,15.42,2.26C658.92,548.54,658.04,549.48,657.7,550.63z"/>
    <path fill="#B6DBDE" d="M650.4,550.2c7.02,2.08,12.36,5.3,12.92,7c-4.44,0.56-11.01,0.44-17.65,0.05
      C646.11,554.41,647.92,551.38,650.4,550.2z"/>
    <path fill="#97C3CA" d="M675.96,552.87c0.86,0.5,1.56,1.03,2.05,1.57c-1.73,0.88-7.41,1.61-11.97,1.3
      c-0.43-0.6-1.09-1.22-1.93-1.83C668.06,554.51,672.06,553.82,675.96,552.87z"/>
    <path fill="#97C3CA" d="M662.4,556C662.41,556,662.41,556,662.4,556c0.48,0.45,0.8,0.86,0.91,1.2
      c-4.44,0.56-11.01,0.44-17.65,0.05c0.11-0.71,0.31-1.43,0.58-2.14C651.96,555.89,660.96,556.01,662.4,556z"/>
    <path fill="#73B3B9" d="M638.21,546.37c-2.53,0.61-3.35,2.01-2.71,1.96c4.79-0.32,9.28,0.24,13.62,1.44
      c-2.42,1.44-4.15,4.57-4.56,7.41c-8.3-0.5-16.13-1.36-20.34-1.79c-7.84-0.81-26.41-4.63-29.84-6.3
      C608.16,549.07,637.33,546.48,638.21,546.37z"/>
    <path fill="#56868B" d="M653.29,548.09c1.63,0.48,3.17,1.15,4.76,1.71c-0.15,0.26-0.26,0.54-0.35,0.83
      c-5.93-2.56-13.11-4.51-17.44-4.52c1.26-0.16,2.52-0.33,3.77-0.5C647.19,546.13,650.2,547.18,653.29,548.09z"/>
    <path fill="#56868B" d="M638.21,546.37c-1.13,0.27-1.91,0.7-2.38,1.09c-1.18-0.04-6.7,0.79-10.4,1.16
      c-3.02,0.3-10.95,0.83-13.45,1.83c-1.65,0.66,16.52,3.51,33.09,4.68c-0.24,0.69-0.42,1.38-0.52,2.06
      c-8.3-0.5-16.13-1.36-20.34-1.79c-7.84-0.81-26.41-4.63-29.84-6.3C608.16,549.07,637.33,546.48,638.21,546.37z"/>
    <path fill="#8B6F65" d="M582.96,545.85c16.9,0.47,33.84-0.31,50.64-2.11c1.19-9.11-0.34-18.59-1.98-27.62
      c-0.29,0.3-3.56,2.17-3.09,1.28c0.92-1.78,3.6-5.31,4.18-7.25c0.46-1.51,0.91,3.46,0.12,4.53c2.82,9.2,4,19.27,3.42,28.84
      c3.14-0.11,10.1-1.05,13.94-2c0.07-11.08-1.57-22.12-3.78-33.04c-11.43,0.18-47.15-2.71-59.38-3.91
      C587.45,518.44,587.95,532.62,582.96,545.85z"/>
    <path fill="#AC978D" d="M634.01,536.91c0,2.29-0.12,4.57-0.41,6.83c-0.56,0.06-1.12,0.11-1.69,0.17
      C632.09,543,633.31,537.85,634.01,536.91z"/>
    <path fill="#AC978D" d="M631.62,526.76c-0.25-2.57-1.07-7.82-1.65-9.64c0.75-0.38,1.52-0.86,1.66-1
      c1.04,5.71,2.03,11.6,2.32,17.46c-0.64,0.09-1.28,0.18-1.91,0.29c-0.03-0.16-0.06-0.28-0.1-0.34c0.29-2.19-0.88-5.32-1.69-7.36
      C630.75,525.99,631.12,526.47,631.62,526.76z"/>
    <path fill="#AC978D" d="M647.57,514.54c0.58,3.26,1.1,6.52,1.51,9.8C645.78,521.33,647.5,514.11,647.57,514.54z"/>
    <path fill="#74584E" d="M646.42,508.48c0.35,1.73,0.69,3.47,1,5.21c-4.24-0.68-11.19-1.84-14.17-2.15
      c-0.07-1.23-0.3-2.16-0.53-1.38c-0.13,0.43-0.36,0.94-0.66,1.48c-14.91-1.6-29.93-2.03-44.86-1.01c-0.05-2.02-0.11-4.04-0.17-6.06
      C599.26,505.77,634.98,508.66,646.42,508.48z"/>
    <path fill="#74584E" d="M629.8,534.86c1.63-0.87,3.12-2.06,4.01-3.4c0.13,1.74,0.21,3.49,0.21,5.22
      C632.68,536.63,630.84,535.75,629.8,534.86z"/>
    <path fill="#74584E" d="M600.24,536.3c3,0.35,12.41,1.4,11.18,2.9c-0.9,1.1-13.32,1.5-18.49,1.77
      c-2.81,0.14-5.62,0.16-8.41,0.09c0.45-1.6,0.83-3.21,1.15-4.83C590.5,535.8,595.43,535.74,600.24,536.3z"/>
    <path fill="#74584E" d="M646.7,532.94c1.47-0.86,2.43-2.11,2.93-3.52c0.21,2.32,0.37,4.64,0.47,6.96
      C648.51,535.63,647.14,534.37,646.7,532.94z"/>
    <path fill="#74584E" d="M633.09,514.06c1.89,4.43,4.33,8.56,5.58,13.27c1.39,5.22,1.98,10.48,1.66,15.84
      c-1.6,0.19-3.04,0.31-4.07,0.35c0.58-9.57-0.6-19.64-3.42-28.84C632.94,514.54,633.03,514.32,633.09,514.06z"/>
    <path fill="#9F9A8E" d="M585.71,395.08c-5.35,7.95-6.94,17.55-7.73,26.74c-1.2,13.96-0.56,28.14-0.59,42.14
      c-0.01,5.67-0.13,11.45-0.66,17.1c-0.45,4.77-1.64,13.78-0.72,18.54c3.63,0.78,70.05,9.17,79.89,5.67
      c1.17-4.17,4.01-29.65,4.42-35.5c0.41-5.8,0.66-11.44,0.57-17.86c-0.04-2.92-0.25-5.75-0.45-8.03c-1.37,0.71-3.97,1.36-5.43,1.46
      c-2.39,0.15-4.83,0-7.2-0.24c-12.03-1.21-22.79-8.09-30.76-16.23c-4.68-4.78-8.16-10.27-10.63-16.16c0,0.02,0,0.04,0.01,0.06
      c0.84,12.42-0.67,64.71-1.61,78.36c-0.03,0.4-12.59,5.44-18.24,4.4c-3.28-0.6-7.12-8.53-6.48-12.52c0.16-0.98,1.59-0.82,2.16-0.28
      c0.36,0.35,0.53,0.52,0.86,0.92c0.98-0.44,2,0.53,2.58,1.29c0.64,0.85,1.2,1.79,1.59,2.62c-0.04-0.93-0.04-1.42,0.18-2.37
      c0.32-1.35,2.06-1.72,2.26-0.96c0.16,0.61,1.23,5.24,1.82,6.27c1.23-0.18,2.16-0.32,3.26-0.46c-2.55-12.04-4.97-71.17-3.84-76.8
      c0.52-2.59,2.26-4.32,2.13-2.93c-0.82,8.35,2.57,73.53,3.82,79.31c1.43,0.14,5.07-0.09,5.95-0.34c0.02-3.35,2.11-75.44,2.05-80.29
      c-1.14-3.38-2.09-7.26-2.74-11.71c-0.83-5.59-1.42-11.71,0.58-17.13c-1.95,1.13-3.82,2.39-5.58,3.55
      C592.6,386.74,588.69,390.65,585.71,395.08z"/>
    <path fill="#B5B1A8" d="M578.64,415.83c1-7.27,2.88-14.52,7.07-20.75c2.84-4.23,6.55-7.98,10.86-10.95
      C589.44,392.7,583.39,403.15,578.64,415.83z"/>
    <path fill="#B5B1A8" d="M653.29,504.38c-0.69-8.82,0.5-17.79,2.24-26.43c0.86-4.27,1.82-8.51,2.65-12.78
      c0.37-1.88,0.69-4.05,1.28-6.12c-0.11,3.51-0.33,6.84-0.59,10.37c-0.41,5.85-2.95,30.42-4.12,34.58
      C654.36,504.15,653.86,504.27,653.29,504.38z"/>
    <path fill="#B5B1A8" d="M597.27,489.64c-0.13-0.01-0.25-0.01-0.36-0.02c-1.21-5.56-4.39-66.1-3.9-78.11
      c3.37,2.25,2.47,20,2.9,29.24C596.63,456.08,597,474.29,597.27,489.64z"/>
    <path fill="#7C7468" d="M681.2,452.24c-0.32-0.36-0.96-0.79-1.5-0.95c3.52-2.37,2.58-4.94,0.37-6.43
      c1.42-0.23,2.86-0.39,4.31-0.41c1.41-0.02,3.85-0.15,4.85,1.1C688.25,448.49,684.23,450.86,681.2,452.24z"/>
    <path fill="#7C7468" d="M667.33,443.25c-1.55,0.36-3.07,0.79-4.54,1.35c-0.14-0.88-0.33-1.74-0.56-2.59
      C663.91,442.54,665.61,442.97,667.33,443.25z"/>
    <path fill="#7C7468" d="M600.34,399.84c-1.03-4.49-3.36-13.34-0.18-18.1c0.85-0.54,1.72-1.09,2.6-1.6
      c-2.01,5.42-1.42,11.55-0.58,17.13c0.66,4.45,1.61,8.33,2.74,11.71c0.01,0.73-0.03,2.98-0.1,6.28
      C602.87,410.24,601.51,404.93,600.34,399.84z"/>
    <path fill="#7C7468" d="M577.38,466.34c0.01-0.8,0.01-1.59,0.01-2.38c0.03-14-0.6-28.18,0.59-42.14
      c0.04-0.42,0.07-0.84,0.12-1.25c0.22,1.67,1.5,17.08,1.64,23.17C579.88,450.21,577.73,465.25,577.38,466.34z"/>
    <path fill="#7C7468" d="M588.58,432.38c0.07-5.32,0.44-10.79,2.14-15.86c-0.4,13.91,1.79,62.7,4.08,73.52
      c-0.56,0.08-1.08,0.15-1.62,0.22c0.02-0.12-2.92-17.95-3.32-24.39C589.36,457.78,588.48,440.49,588.58,432.38z"/>
    <path fill="#7C7468" d="M587.24,496.77c-2.98-0.31-4.75-1.34-6.2-3.75c-1.29-2.13-2.71-4.69-2.83-7.19
      c-0.1-1.92,0.75-2.64,1.86-2.62c-0.5,4.04,3.27,11.73,6.5,12.33c4.22,0.78,12.29-1.84,16.12-3.38
      C599.72,496.08,591.68,497.22,587.24,496.77z"/>
    <path fill="#7C7468" d="M637.29,445.79c-5.85-1.65-11.2-4.86-15.73-8.63c-4.56-3.8-9.31-8.15-12.42-13.1
      c-0.92-1.47-1.76-3.01-2.52-4.59c-0.04-2.79-0.1-5.09-0.21-6.7c0-0.02,0-0.04-0.01-0.06c2.47,5.89,5.94,11.38,10.63,16.16
      c7.97,8.14,18.73,15.02,30.76,16.23c2.37,0.24,4.81,0.39,7.2,0.24c1.46-0.09,3.33-0.18,4.69-0.9c0.01,0.07,0.01,0.15,0.02,0.22
      C655.02,449.64,642.96,447.38,637.29,445.79z"/>
    <path fill="#7C7468" d="M606.65,474.88c-0.24,1.53-0.78,2.99-1.25,4.47c0.34-8.4,0.68-19.29,0.93-29.94
      C606.63,452.93,608.43,463.66,606.65,474.88z"/>
    <path fill="#7C7468" d="M621.08,500.22c6.91,0.73,14.28,3.29,21.57,4.49c-17.84-0.31-46.64-3.23-59.22-4.72
      C595.99,499.54,608.58,498.89,621.08,500.22z"/>
    <path fill="#F2D4B1" d="M587.08,491.49c-0.24-0.16-1.35-2.17-2.52-3.92c-1.17-1.75-2.35-3.95-3.19-3.85
      c-0.84,0.09,2.46,9.83,5.9,10.4c2.67,0.44,7.03-0.78,9.59-1.33c2.55-0.55,3.88-1.73,3.24-1.74c-2.21-0.03-6.82,0.52-9.03,0.61
      c-0.8-1.21-1.49-4.69-2.03-6.75c-0.15-0.58-1.53,0.96,0,6.73C588.6,492.01,587.53,491.81,587.08,491.49z"/>
    <path fill="#F2D4B1" d="M587.76,490.62c-0.1-0.31-0.17-0.62-0.23-0.92c-0.4-1-0.77-2.01-1.35-2.96
      c-0.18-0.3-1.78-2.95-2.51-2.41C585.38,486.43,586.76,488.92,587.76,490.62z"/>
    <path fill="#E5BB95" d="M679.08,463.7c-1.41,0.26-2.74,1.05-3.73,2.19c-0.11-0.1-0.22-0.21-0.34-0.32
      C675.94,464.39,677.5,463.53,679.08,463.7z"/>
    <path fill="#E5BB95" d="M681.09,464.64c-1.75,0.32-3.68,1.04-4.95,1.87c-0.03-0.02-0.06-0.05-0.08-0.07
      C677.36,465.22,679.24,464.37,681.09,464.64z"/>
    <path fill="#E5BB95" d="M669.14,455.92c-0.33-0.08-0.68-0.16-1.04-0.26c1.57-0.92,3.17-1.65,4.08-1.77
      c0.46,0.69,1.43,1.68,2.15,2.21C672.6,456.34,670.88,456.32,669.14,455.92z"/>
    <path fill="#E5BB95" d="M674.83,469.16c2.19-0.59,4.4-1.21,6.66-1.5c-0.37,0.24-0.79,0.49-1.26,0.73
      c-1.07,0.56-5.61,2.89-8.99,1.96C672.4,469.88,673.6,469.49,674.83,469.16z"/>
    <path fill="#E5BB95" d="M626.32,363.96c-1.59,0.48-3.2,0.9-4.82,1.28c5.02-7.29,13.84-9.97,23.02-8.84
      C638.7,359.41,632.63,362.06,626.32,363.96z"/>
    <path fill="#E5BB95" d="M655.71,414.61c-5.92-4.71-10.55-11.66-13.06-18.49c-2.01-5.44-2.42-11.47,1.17-15.86
      c-3.82,7.16,0.39,14.83,5.45,21.58c5.66,7.55,13.28,15.26,23.33,17.19c3.43,0.66,7.17,0.61,10.54-0.21
      c-0.56,1.22-1.23,2.37-1.98,3.46C672.01,422.65,662.69,420.16,655.71,414.61z"/>
    <path fill="#E5BB95" d="M656.85,420.82c-1,0.92-1.55,2.24-1.36,3.78c0.23,1.9,1.3,3.31,2.84,4.11
      c-3.26,1.69-6.28-2.15-5.07-5.15C653.92,421.92,655.28,421.16,656.85,420.82z"/>
    <path fill="#E5BB95" d="M633.71,408.59c1.73,3.79,1.5,7.43,0.17,10.91c-0.51-0.66-1.01-1.32-1.49-1.99
      c-3.85-5.42-6.9-11.35-9.21-17.49C627.74,401.31,631.92,404.66,633.71,408.59z"/>
    <path fill="#E5BB95" d="M646.05,365.33c-0.02,0.01-0.05,0.02-0.07,0.03c-2.25,1.74-6.03,1.59-8.89,1.8
      C638.21,364.11,645.04,361.47,646.05,365.33z"/>
    <path fill="#E5BB95" d="M597.22,492.71c-1.35-0.27-2.72-0.64-3.97-1.2c2.31-0.19,5.24-0.49,6.87-0.46
      C600.72,491.06,599.54,492.13,597.22,492.71z"/>
    <path fill="#E5BB95" d="M588.98,492.87c1.54,0.12,2.99,0.42,4.47,0.69c-2.15,0.46-4.5,0.84-6.18,0.56
      c-0.68-0.11-1.36-0.59-2-1.28C586.5,492.79,587.74,492.78,588.98,492.87z"/>
    <path fill="#E5BB95" d="M585.67,486.73c0.79,0.83,1.27,1.78,1.78,2.76c0.03,0.07,0.05,0.14,0.09,0.21
      c0.06,0.3,0.13,0.61,0.23,0.92c-0.74-1.25-1.68-2.92-2.8-4.55C585.21,486.28,585.45,486.49,585.67,486.73z"/>
    <path fill="#FF8B00" d="M572.3,383.33c-0.46-1.14-0.87-2.29-1.22-3.45c-0.61-2.07-1.03-4.15-1.22-6.3
      c-0.06-0.62-0.13-1.34-0.09-2.03c3.04,1.08,6.05,2.24,9.26,2.82c4.89,0.87,9.54,0.61,14.15-1.2c2.74-1.07,5.51-2.06,8.51-1.19
      c1.71,0.5,3.25,1.28,4.78,2.11c-2.8,1-5.45,2.45-8.1,3.95c-6.16-3.08-13.8,4.16-19.84,5.09
      C576.37,383.46,574.32,383.49,572.3,383.33z"/>
    <path fill="#FFCF2B" d="M565.23,370.67c0.01-0.18,0.01-0.38,0.02-0.58c1.34,0.36,2.64,0.8,3.95,1.25
      c-0.06,0.68-0.01,1.37,0.05,1.97c0.17,1.94,0.46,3.85,0.99,5.73c0.4,1.44,0.89,2.85,1.44,4.23c-1.36-0.14-2.72-0.36-4.09-0.64
      c-0.63-1.66-1.19-3.35-1.59-5.07C565.47,375.31,565.12,372.99,565.23,370.67z"/>
    <path fill="#FF8B00" d="M560.02,369.21c1.1,0.07,2.16,0.2,3.09,0.38c0.53,0.1,1.05,0.23,1.58,0.36
      c-0.11,1.6-0.06,3.22,0.18,4.8c0.39,2.66,1.14,5.23,2.08,7.75c-0.55-0.12-1.1-0.25-1.66-0.38c-1.26-0.3-2.64-0.63-4.02-0.79
      c-0.43-1.63-0.79-3.28-1.07-4.87C559.83,374.36,559.53,371.56,560.02,369.21z"/>
    <path fill="#FF8B00" d="M548.57,372.07c1.06-1.33,3.08-2.14,5.42-2.56c-0.59,2.96-0.04,6.32,0.64,8.97
      c0.31,1.21,0.69,2.4,1.13,3.57c-2.24,0.91-3.92,2.46-5.13,4.61C548.75,381.71,547.72,373.13,548.57,372.07z"/>
    <path fill="#FFCF2B" d="M560.63,381.27c-0.86-0.06-1.7-0.05-2.52,0.1c-0.64,0.12-1.24,0.29-1.81,0.49
      c-0.32-0.85-0.61-1.72-0.86-2.6c-0.78-2.82-1.56-6.63-0.86-9.85c1.58-0.24,3.26-0.3,4.87-0.24c-0.42,1.97-0.32,4.19-0.05,6.06
      C559.69,377.21,560.1,379.25,560.63,381.27z"/>
    <path fill="#FFB429" d="M572.06,374.32c-0.73-0.16-1.46-0.35-2.17-0.56c-0.01-0.06-0.02-0.12-0.02-0.18
      c-0.06-0.62-0.13-1.34-0.09-2.03c3.04,1.08,6.05,2.24,9.26,2.82c0.66,0.12,1.31,0.21,1.95,0.29
      C578.01,374.96,575.01,374.95,572.06,374.32z"/>
    <path fill="#FFF854" d="M569.28,373.59c-1.36-0.42-2.7-0.9-4.05-1.35c-0.02-0.52-0.02-1.04,0.01-1.57
      c0.01-0.18,0.01-0.38,0.02-0.58c1.34,0.36,2.64,0.8,3.95,1.25c-0.06,0.68-0.01,1.37,0.05,1.97
      C569.26,373.41,569.27,373.5,569.28,373.59z"/>
    <path fill="#FFB429" d="M564.64,372.05c-1.14-0.37-2.29-0.72-3.46-0.99c-0.45-0.1-0.91-0.2-1.37-0.29
      c0.04-0.53,0.1-1.06,0.21-1.56c1.1,0.07,2.16,0.2,3.09,0.38c0.53,0.1,1.05,0.23,1.58,0.36
      C564.64,370.64,564.63,371.34,564.64,372.05z"/>
    <path fill="#FFB429" d="M551.75,370.09c0.7-0.24,1.45-0.43,2.24-0.57c-0.04,0.18-0.05,0.36-0.08,0.54
      C553.18,370.03,552.46,370.04,551.75,370.09z"/>
    <path fill="#FFF854" d="M554.48,370.08c0.03-0.22,0.06-0.45,0.11-0.67c1.58-0.24,3.26-0.3,4.87-0.24
      c-0.1,0.48-0.17,0.98-0.22,1.48C557.7,370.37,556.09,370.16,554.48,370.08z"/>
    <path fill="#CF690C" d="M640.06,437.22c-4.24-1.77-8.11-4.11-11.33-7.25c-3.28-3.2-6.05-6.81-8.7-10.47
      c-5.38-7.46-9.06-15.48-10.65-24.38c-0.68-3.82-1.02-7.7-1.04-11.58c-0.01-1.98,0.02-4.15,0.49-6.17c1.01-0.32,5.13-1.09,6.18-1.23
      c-0.01,5.28,0.61,10.85,2.15,15.86c4.02,13.04,9.97,30.45,24.58,38.84c5.13,2.95,9.26,4.71,15.27,4.56
      c0.5,0.92,1.74,3.91,2.02,4.99C652.57,440.79,645.98,439.69,640.06,437.22z"/>
    <path fill="#CF690C" d="M571.57,380.73c5.77,0.55,10.09-3.22,15.34-4.69c4.85-1.36,9.45-0.65,14.08,0.54
      c-0.88,0.47-1.75,0.96-2.62,1.46c-6.16-3.08-13.8,4.16-19.84,5.09c-2.17,0.33-4.21,0.36-6.23,0.21c-0.35-0.87-0.66-1.75-0.95-2.63
      C571.43,380.71,571.5,380.72,571.57,380.73z"/>
    <path fill="#D6A602" d="M570.73,380.62c0.29,0.9,0.61,1.79,0.96,2.66c-1.36-0.14-2.72-0.36-4.09-0.64
      c-0.4-1.04-0.76-2.1-1.07-3.17C567.88,379.97,569.27,380.39,570.73,380.62z"/>
    <path fill="#CF690C" d="M565.87,379.23c0.32,1.1,0.68,2.19,1.08,3.27c-0.55-0.12-1.1-0.25-1.66-0.38
      c-1.26-0.3-2.64-0.63-4.02-0.79c-0.36-1.35-0.65-2.7-0.9-4.03C562.23,377.78,564.03,378.54,565.87,379.23z"/>
    <path fill="#CF690C" d="M554.29,377.04c0.11,0.5,0.22,0.99,0.34,1.44c0.31,1.21,0.69,2.4,1.13,3.57
      c-2.24,0.91-3.92,2.46-5.13,4.61c-0.72-1.88-1.31-4.28-1.72-6.6C550.31,378.59,552.11,377.5,554.29,377.04z"/>
    <path fill="#D6A602" d="M559.72,377.14c0.25,1.37,0.54,2.75,0.91,4.13c-0.86-0.06-1.7-0.05-2.52,0.1
      c-0.64,0.12-1.24,0.29-1.81,0.49c-0.32-0.85-0.61-1.72-0.86-2.6c-0.2-0.72-0.4-1.5-0.57-2.32
      C556.58,376.67,558.18,376.79,559.72,377.14z"/>
    <path d="M657.25,419.77c2.52-1.39,4.75-0.44,3.15,0c-3.46,0.95-5.59,6.16-1.97,8.72c0.9,0.63-1.25,0.88-2.67-0.44
      C653.56,426,654.03,421.56,657.25,419.77z"/>
    <path d="M636.55,366.69c0.33-1.8,1.39-3.35,3.8-4.16c2.22-0.75,5.41-0.52,6.64,1.58c0.59,1.01-0.93,2.85-1.63,1.89
      c-1.01-1.37-2.51-1.93-4.14-1.74c-1.68,0.2-3.53,2.11-3.76,3.44C637.33,368.48,636.35,367.8,636.55,366.69z"/>
    <path d="M663.29,422.24c1.6,0,2.94,1.22,2.96,2.71c0.01,1.48-1.32,2.75-2.91,2.75c-1.6,0-2.94-1.22-2.96-2.71
      C660.36,423.52,661.69,422.24,663.29,422.24z"/>
    <path d="M546.91,385.97c0.2-0.07,0.3,0.18,0.15,0.29c-1.58,1.2-3.23,2.29-4.78,3.51c-2.38,1.87-3.48,4.22-3.77,7.05
      c-0.01,0.14-0.26,0.15-0.27,0C537.99,391.69,542,387.74,546.91,385.97z"/>
    <path d="M535.37,391.35c0.43-1.6,1.51-2.49,2.8-3.25c0.67-0.4,1.52-0.71,1.05-0.33c-1.16,0.94-2.83,3.37-3.24,4.92
      C535.82,393.29,535.1,392.37,535.37,391.35z"/>
    <path d="M644.98,376.11c1.07-1.58-0.27-2.87-1.8-2.68c-1.38,0.18-2.83,1.07-2.45,2.38C641.49,378.4,644.04,377.5,644.98,376.11z"/>
    <path d="M592.8,515.24c2.05-1.16,3.74-2.76,4.93-4.71c0.51-0.84,0.87-1.75,1.14-2.68c0.32-1.09,0.24-2.23,0.56-3.31
      c0.11-0.37,0.64-0.4,0.73,0c0.51,2.05-0.68,4.6-1.8,6.33c-1.29,2-3.28,3.44-5.41,4.6C592.78,515.57,592.64,515.33,592.8,515.24z"/>
    <path d="M593.87,512.28c1.85-2.02,3.82-5.42,2.35-8.04c-0.25-0.44,0.54-0.83,0.81-0.41c1.8,2.73-0.88,6.71-3.02,8.58
      C593.93,512.49,593.8,512.36,593.87,512.28z"/>
    <path d="M603.48,448.34c-1.3,0.59-3.16,0.68-4.32,0.62c-0.65-0.03-0.65-0.88-0.01-0.94c1.4-0.13,3.02-0.18,4.25-0.88
      c0.8-0.46,0.23-1.34-0.36-1.73c-1.04-0.69-2.5-0.59-3.69-0.52c-0.13,0.01-0.2-0.19-0.07-0.24c1.38-0.57,3.31-0.45,4.58,0.32
      c0.82,0.51,1.37,1.57,0.82,2.42c-0.08,0.12-0.18,0.23-0.29,0.34c0.1,0.08,0.19,0.18,0.27,0.29c0.42,0.59,0.08,1.42-0.31,1.92
      c-0.51,0.64-1.52,0.85-2.3,1.01c-0.94,0.19-1.98,0.23-2.9-0.06c-0.15-0.05-0.13-0.24,0.03-0.25c1.4-0.13,2.99-0.1,4.19-0.93
      c0.31-0.22,0.45-0.61,0.46-0.96C603.86,448.47,603.69,448.4,603.48,448.34z"/>
    <path d="M666.06,444.78c0.42-0.17,0.91,0.37,0.49,0.64c-0.89,0.59-1.92,1.07-2.52,1.95c-0.79,1.14,0.87,1.74,1.57,1.94
      c1.5,0.44,3.15,0.49,4.7,0.49c1.74,0,8.46-0.24,7.97-3c-0.01-0.09,0.12-0.11,0.14-0.03c0.99,3.47-7.45,3.67-9.41,3.66
      c-1.6,0-4.14,0.01-5.36-1.15C661.63,447.38,664.26,445.53,666.06,444.78z"/>
    <path d="M669.28,446.55c0.3,0.07,0.84,0.29,1.16,0.57c0.28-0.63,1.32-0.78,1.97-0.32c1.25-0.72,2.36-0.88,3.81-0.96
      c0.92-0.05-0.72,0.61-1.4,0.84c-0.46,0.16-1.65,0.38-2.18,0.4c0.16,0.28,0.08,0.51-0.03,0.67c-0.22,0.33-0.65,0.42-1.05,0.42
      c-0.37-0.01-0.85-0.22-1.14-0.6c-0.64,0.01-1.45-0.15-1.71-0.28c-0.27-0.13-0.89-0.61-1.09-0.78
      C667.36,446.3,668.98,446.48,669.28,446.55z"/>
    <path d="M680.72,446.16c-0.07-0.04-0.12-0.12-0.13-0.22c-0.02-0.6,0.2-1.21,0.68-1.63c0.44-0.38,0.95-0.48,1.55-0.49
      c0.07,0,0.08,0.11,0.01,0.12c-0.47,0.07-1.09,0.21-1.38,0.6c-0.19,0.25-0.21,0.56-0.22,0.87c1.04-1.03,2.88-1.02,4.37-0.86
      c0.1,0.01,0.1,0.15,0,0.16c-0.86,0.11-1.61,0.08-2.41,0.46c-0.67,0.32-1.27,0.82-1.56,1.47c-0.26,0.57-1.22,0.3-1-0.31
      C680.67,446.27,680.7,446.22,680.72,446.16z"/>
    <path d="M636.93,377.21c1.96-1.14,4.19-0.74,5.63,0.44c0.53,0.44,0.46,0.71,0.31,0.61c-1.97-1.27-4.63-1.04-6.71-0.19
      C635.79,378.22,636.3,377.58,636.93,377.21z"/>
    <path d="M617.49,353.83c0.69-0.99,1.86-1.53,2.94-2.07c1.31-0.65,2.59-1.28,3.96-1.8c0.06-0.02,0.1,0.07,0.04,0.09
      c-2.31,0.91-4.93,2.25-6.59,4.02C617.68,354.24,617.35,354.03,617.49,353.83z"/>
    <path d="M616.97,352.73c0.93-0.93,2.52-1.41,3.74-1.92c0.09-0.04,0.15,0.08,0.07,0.13c-0.67,0.37-1.34,0.75-2,1.15
      c-0.53,0.31-1.02,0.7-1.58,0.96C617,353.14,616.83,352.86,616.97,352.73z"/>
    <path d="M630.06,525.78c2.18,0.74,3.93,1.94,4.52,4.11c0.42,1.55-0.29,3.3-1.6,4.29c-0.41,0.31-1.95,0.74-1.6,0.28
      c0.7-0.9,1.57-2.54,1.61-3.69c0.07-1.95-1.27-3.84-3.07-4.56C629.64,526.11,629.76,525.68,630.06,525.78z"/>
    <path d="M646.66,532.63c1.85-0.93,3.04-4.25,0.65-7.32c-0.08-0.11,0.07-0.24,0.19-0.18c3.64,1.8,4.47,6.83,0.15,7.96
      C647.12,533.23,646.15,532.89,646.66,532.63z"/>
    <path d="M635.84,549.88c0.32-1.32,1.05-2.14,2.08-2.7c0.43,0.04,0.86,0.06,1.3,0.06c0.11,0.01,0.22,0.02,0.33,0.02
      c-0.89,0.34-1.93,0.89-2.64,1.69c-0.77,0.86-1.03,3.08,0.27,3.19c0.21,0.02,0.13,0.33-0.08,0.3
      C635.64,552.27,635.59,550.87,635.84,549.88z"/>
    <path d="M634.7,545.56c0.71-0.01,1.43-0.04,2.14-0.06c-1.08,0.46-2.12,0.99-2.98,1.79c-1.37,1.26-1.52,2.72-0.99,4.38
      c0.03,0.09-0.11,0.17-0.17,0.08C631.14,549.27,632.45,546.87,634.7,545.56z"/>
    <path d="M629.86,545.76c0.64,0,1.27-0.01,1.9-0.03c-0.67,0.27-1.31,0.6-1.89,1.02c-1.48,1.08-1.53,2.5-0.76,3.97
      c0.05,0.1-0.09,0.17-0.17,0.1C626.9,548.98,628,547.02,629.86,545.76z"/>
    <path d="M649.78,546.07c0.36-0.42,1.32-0.92,1.83-0.44c0.06,0.05,0.06,0.17-0.04,0.19c-0.31,0.07-0.6-0.01-0.92,0.09
      c-0.36,0.13-0.62,0.46-0.8,0.76c-0.32,0.52-0.43,1.13-0.47,1.72c-0.01,0.21-0.35,0.19-0.38,0
      C648.87,547.59,649.24,546.7,649.78,546.07z"/>
    <path d="M648.78,545.15c0.13-0.05,0.2,0.13,0.08,0.19c-1.08,0.54-1.48,1.32-1.42,2.43c0.01,0.17-0.27,0.18-0.31,0.03
      C646.82,546.58,647.64,545.62,648.78,545.15z"/>
    <path d="M647.39,544.86c0.15-0.03,0.23,0.18,0.08,0.22c-1.23,0.28-2.14,1.28-2.36,2.43c-0.04,0.2-0.35,0.18-0.34-0.03
      C644.8,546.21,646.11,545.09,647.39,544.86z"/>
    <path d="M637.03,551.37c0.53,0,0.97,0.4,0.97,0.9c0,0.5-0.43,0.9-0.97,0.9c-0.53,0-0.96-0.4-0.96-0.9
      C636.07,551.78,636.5,551.37,637.03,551.37z"/>
    <path d="M632.84,550.87c0.53-0.04,1,0.33,1.04,0.82c0.05,0.5-0.35,0.93-0.88,0.97c-0.53,0.04-1-0.33-1.04-0.82
      C631.91,551.34,632.3,550.91,632.84,550.87z"/>
    <path d="M629.23,550.3c0.53,0,0.96,0.4,0.96,0.9c0,0.49-0.43,0.9-0.96,0.9c-0.53,0-0.97-0.4-0.97-0.9
      C628.27,550.71,628.7,550.3,629.23,550.3z"/>
    <path fill="#FFFFFF" d="M644.46,376.03c0.35-0.47-0.22-1.4-0.98-0.65C642.74,376.12,643.97,376.71,644.46,376.03z"/>
    <path fill="#FFEDD6" d="M634.67,381.9c-3.09-0.36-9.28,0.18-10.05,1.44c-0.77,1.26,5.41,3.2,6.57,3.06
      C632.73,386.22,637.76,382.26,634.67,381.9z"/>
    <path fill="#FFEDD6" d="M672.54,467.27c-0.83-1.03-2.59-3.42-3.45-4.42c-0.86-1-2.37,0-0.86,2.41
      C669.74,467.68,673.84,468.88,672.54,467.27z"/>
  </g>
  <g id="item_8_">
    <path d="M142.69,307.62c3.97-0.73,7.21-0.15,8.85,1.31c5.01-0.62,5.73,5.6,3.41,8.85
      c-1.81,2.53-4.85,4.48-8.4,5.4c-2.46,0.64-6.02,0.91-8.84,1.39c-3.97,0.68-7.18,2.02-10.44,2.42c-3.26,0.39-6.78,0.49-10.22,0.15
      c4.09,4.53,7.73,12.4,6.87,18.17c1.97,0.04,19.08,9.52,18.54,10.75c-0.54,1.23-12.81,2.84-16.8,3.14c0.28,1.08-0.56,1.7-0.5,2.75
      c0.06,0.99,0.95,2.91,0.72,3.68c-0.6,1.99-3.49,3.29-5.75,3.86c-0.86,3.06-1.07,5.38-0.66,9.21c1.27,0.77,2.48,1.97,2.48,3.29
      c0,1.32-0.64,2.14-0.66,2.78c-0.05,1.82,3.47,4.64,3.09,6.28c-0.2,0.88-3.99,2.34-5.19,2.68c-1.76,0.49-5.47,1.43-5.97,0.87
      c-0.76-0.86-2.1-3.76-3.37-5.35c-1.73-2.16-3.61-3.19-4.64-4.06c-1.57,0.18-2.27,4.73-2.27,4.73s1.55,0.47,1.24,2.55
      c0.6-0.04,2.25,0.23,2.32,2.04c1.33-0.77,2.57,0.5,2.3,1.49c3.17-1.32,3.28,1.37,3.01,1.84c2.26,0.66,5.97,2.27,4.78,3.64
      c0.86,0.47,2.1,1.27,1.62,2.52c1.88,1.64,19.53,15.97,23.49,19.12c3.96,3.15,9.48,7.07,14.07,10.73c1.61,1.27,5.41,3.91,7.42,5.29
      c2.01,1.38,6.03,4.08,5.84,6.29c-0.19,2.21-2.7,3.92-4.71,5.69c-2.01,1.77-6.13,4.74-9.2,7.82c-2.93,2.95-7.48,8.73-8.34,9.52
      c-0.88,0.17-1.7-0.11-2.32-0.28c0.09,4.15-1,9.45-1.69,11.33c3.13,1.81,6.27,5.29,7.21,5.42c0.93,0.13,2.26-1.01,4.35-0.85
      c2.2,0.16,3.26,1.52,3.2,3.06c-2.27,0.88-6.56,2.38-7.85,2.16c-1.29-0.21-3.14-1.12-3.87-1.7c-1.44,0.28-7.39,0.67-10.09-0.1
      c0.07,0.21,0.31,0.99,0.25,1.38c1.32-0.44,2.56-0.55,3.78-0.04c1.23,0.51,1.92,1.42,1.37,2.26c1.25-0.41,1.98,0.75,1.41,1.77
      c1.59-0.51,2.64,0.92,1.66,1.68c2.07,0.48,0.93,2.51-0.39,2.94c1.87,0.24,1.36,3-0.51,2.71c0.91,0.84-0.78,2.32-2.06,1.02
      c0.28,0.86-0.83,1.44-1.93,0.88c0.16,1.03-1.74,2.01-2.85,0.49c-0.05,0.53-2.03,1.46-2.91-0.45c0.09,0.79-2.31,1.16-2.66-0.41
      c-0.32,0.41-1.7,0.78-2.38-0.27c-1.08-1.67-0.64-4.11-0.64-4.11s-1,0.4-2.05,0.76c-0.11,0.77-1.06,15.98-1.43,20.28
      c-0.37,4.31-0.3,12.39,0.37,17.05c0.68,4.67,1.16,10.09,1.45,13.48c0.29,3.39,1.16,7.8,1.84,12.46c0.67,4.66,3,11.09,3.18,16.15
      c1.52,3.16,2.75,8.69,3.05,11.65c2.26,4.46,4.71,11.65,5.93,16.48c4.16,4.78,9.5,16.99,9.83,17.27c5.23,1.39,13.61,5.26,18.62,6.28
      c5.01,1.02,9.98-0.44,12.05-2.31c2.07-1.88,3.6-5.99,4.64-6.69c1.04-0.7,5.91,1.78,5.91,3.14c0,5.04-3.79,8.71-6.85,10.91
      c-3.06,2.2-8.46,3.85-13.65,4.58c-6.89,0.97-14.79,0.6-22.99,0.16c-10.91-0.6-18.62-2.42-24.64-4.48c0.31,0.75,0.14,1.87,0.22,2.47
      c-3.08,0.85-11.04-0.75-14.59-1.18c2.78,1.85,5.71,2.98,8.43,4.79c2.75,1.83,6.6,2.66,10.68,3.05c4.07,0.39,6.57-0.36,9.91-1.24
      c2.49-0.66,6.76-2.27,8.11-2.06c1.34,0.22,3,2.55,2.76,3.59c-0.66,2.93-21.03,10.58-24.53,11.47c-4.22,1.08-8.12,2.09-12.82,1.03
      c-10.56-2.39-19.58-6.4-29.17-9.67c-0.18,0.59-0.88,1.65-1.38,2.01c-4.97-0.65-10.94-1.23-16.46-0.93
      c-1.56-1.23-2.63-4.45-2.32-6.33c-2.32-4.35-1.54-8.03,0.11-10.34c-2.63-0.56-7.97-2.25-8.78-4.32c0.82-1.48,6.3-10.8,9.45-18.32
      c1.4-3.33,4.89-15.64,6.52-19.29c0.49-6.03,2.89-17.64,4.75-23.15c-0.52-4.65,0.83-6.68,1.6-8.8c0.77-2.12,0.68-7.97,0.5-11.57
      c-0.18-3.61-0.67-9.63,0-13.53c-0.22-3.34-0.64-6.2,0.99-11.58c0.68-4.5,1.72-8.86,0.83-10.7c0.09,1.07-1.61,2.29-2.98,1.28
      c-0.15,0.97-2.15,1.99-3.6,0.88c-0.73,1.17-2.49,1.33-4.03,0.87c-0.33,1.1-1.02,1.33-2.32,1.03c0.42,1.34-1.42,1.58-2.51,1.06
      c0.01,1-2.42,1.66-3.32-0.19c0.44,2.39-3.39,1.88-3.7-0.44c-0.54,1.69-3.43,1.37-3.26-0.24c-1.39,0.08-2.17-1.12-1.27-2.53
      c-1.8,0.11-3.83-2.35-1.6-4.95c-2.05-0.63-2.35-3.72,0.27-3.83c-0.09-1.75,1.33-2.3,2.84-1.54c-0.47-0.89-0.26-1.78-0.16-2.31
      c-2.88,0.68-8.32-0.61-7.52-3.14c-3.09-0.41-5.19-1.59-6.19-2.42c-1.77-1.46-1.26-3.88,0.5-4.68c-0.79-0.52-1.67-1.88-1.94-3.19
      c-0.26-1.32,1.26-2.14,3.2-2.06c1.94,0.08,4.59,3.37,5.48,3.66c0.89,0.29,6.22-3.53,6.14-4.32c-0.08-0.79-1.19-2.87-1.99-6.12
      c-0.79-3.25-0.89-6.12-1.11-9.26c-0.76,0.43-1.37,0.9-1.99,0.82c-0.87-0.47-3.56-3.1-7.52-6.53c-3.96-3.44-9.84-8.1-12.49-9.88
      c-2.64-1.78-6.81-3.47-7.95-4.79c-1.15-1.32-1.27-3.91,1.66-8.9c1.1-1.88,7.47-11.17,13.48-16.88
      c6.61-6.27,15.44-11.45,19.25-13.48c4.35-2.31,9.56-4.66,14.72-6.91c-0.36-0.55-1.18-2.77,0.73-3.71
      c-1.21-0.66-1.53-2.45,0.13-2.85c-0.9-1.09-1.18-3.74,1.83-3.56c-0.76-1.79,0.58-2.55,1.87-2.14c-0.48-1.12,0.07-2.81,2.05-2.06
      c-0.59-1.42,1.61-2.81,2.9-0.86c0.92-2.72,3.43-2.18,3.92-0.75c0.58-1.22,2.43-1.33,2.99,0.33c0.32-0.89,2.27-1.7,3.28-0.81
      c1.26-0.72,2.89,0.64,2.77,1.6c1-0.69,3.28-0.86,4.4,1.24c0.15-0.65,0.44-3.54,0.82-5.51c0.38-1.97,1.53-4.19,1.33-5.18
      c-0.21-0.99-7.58-9.53-9.19-11.36c-4.31-1.44-8.81-4.6-9.09-8.11c-0.28-3.52,1.87-4.17,3.59-4.17c-1.1-3.09-1.07-6.22-2.76-9.11
      c-2.83,0.94-1.02,3.08,0.22,2.98c-2.24,1.39-4.64-0.21-4.58-2.52c-0.8,0.52-1.65,1.69-2.16,2.52c-0.69-1.86-0.55-5.24,0.33-6.95
      c-0.99,0.3-1.95,1.16-2.54,1.85c-0.21-3.18,1.64-7.09,3.7-9.62c1.81-2.22,5.64-3.64,9.3-4.26c1.83-0.31,3.66-0.3,5.2-0.81
      c1.36-0.45,2.42-1.68,3.3-2.25c2.02-1.3,3.95-1.9,6.36-2.17c2.4-0.27,4.07-0.19,5.07-0.35c1.01-0.15,1.82-0.86,2.98-1.08
      c2.15-0.41,3.12,0.49,5.03,0.36c1.24-0.08,3.19-0.5,4.09-0.57c4.48-0.34,5.26,1.61,9.45,1.24c3.22-0.29,5.7-1.56,7.46-1.86
      c2.19-0.37,5.7-0.34,7.4-0.97c1.76-0.65,5.86-2.26,5.19-6.33c-0.45-2.76-4.72-1.5-6.35-0.2c-1.49,1.18-2.97,1.83-3.98,1.59
      c-1-0.23-1.33-1.15-1.22-2.11c0.19-1.5,2.09-2.43,1.81-3.28c-0.28-0.86-1.63-0.75-1.85,0.54c-0.85-1.64,1.91-2.5,2.69-0.86
      c0.36,0.76-0.36,1.75-0.8,2.38c-0.45,0.63,0.06,1.14,0.55,1.01c0.56-0.15,1.3-1.45,2.03-2.17
      C139.35,308.95,140.67,307.98,142.69,307.62z M58.2,457.2c0.52-1.16,0-2.96,0.16-4.37c0.19-1.56,1.29-2.73,1.43-4.53
      c0.51-6.12,0.13-16.48,2.16-25.31c-0.08,0-4.71,3.11-8.23,5.2c-3.53,2.09-8.17,4.3-11.17,5.45c-3.68,1.42-7.76,2.63-9.44,5.66
      C39.3,440.64,53.15,451.71,58.2,457.2z M132.02,445.14c1.07,7.51,1.98,14.48,2.14,21.58c1.18-1.37,2.07-2.53,3.08-4.19
      c-0.52-0.49-0.95-1.43-1.15-2.25c3.54-4.37,16.04-12.93,17.36-13.76c1.32-0.83,2.41-1.96,1.89-2.48c-0.52-0.52-3.78-2.39-5.87-3.43
      c-3.25-1.6-15.82-8.56-22.16-15.96C128.7,429.4,130.96,437.63,132.02,445.14z"/>
    <path fill="#EDCA83" d="M139.5,355.69c0.16-0.61-3.35-2.54-6.24-4.25
      c-4.01-2.38-7.87-4.08-8.22-4.25c-0.59-0.28-1.76-0.65-2.59-0.18c0.7-2.57-0.37-8.09-2.44-12.43c-1.25-2.63-3.62-6.13-5.63-7.71
      c-1.43-0.06-3.82,0.87-5.43,1.04c-1.46,0.15-2.96,0.18-4.34,0.4c-2.67,0.43-4.44,1.59-6.41,1.85c-1.76,0.24-4.04,0.15-5.47,0.98
      c-0.92,0.53-1.12,2.48-1.6,4.12c-0.61,2.09-1.66,3.23-1.87,4.94c-0.61,4.94,1.99,11.16,0.27,15.85c0.72,0.4,1.94,1.65,1.66,2.61
      c-2.58-2.06-4.12-2.72-5.56-3.36c-1.44-0.65-4.23-1.73-5.01,0.31c-0.79,2.04,0.99,4.03,2.96,5.8c1.96,1.77,4.12,2.79,6.89,3.09
      c1.28,0.14,2.65-0.24,3.72-0.81c0.64,2.01-2.14,2.04-4.26,2c-0.04,0.46,4.56,5.5,6.54,8c0.66,0.84,1.68,2.19,1.75,2.94
      c0.13,1.25-0.87,3.11-1.24,4.63c-0.44,1.79-0.49,3.46-0.77,5.09c-0.77,4.43-2.01,8.35-4.31,10.85c2.02,0.86,4.01,1.28,6.27,1.14
      c0.95-1.32,2.25-5.38,2.94-7.7c0.7-2.31,1.81-5.73,2.85-7.05c-1.13-1.07-4.38-3.03-3.93-4.17c2.66,1.88,6.74,4.14,9.54,6.86
      c2.86,2.78,4.45,6.14,4.65,6.39c0.38,0.5,3.13-0.28,4.89-0.79c0.73-0.22,3.08-1,3.21-1.81c0.11-0.69-1.12-2.05-1.54-2.82
      c-0.46-0.85-0.93-1.66-1.03-2.6c-0.11-0.94,0.74-1.41,0.76-2.38c0.03-0.96-0.94-1.61-1.58-2.26c0.05,0.52,0,1.14-0.08,1.47
      c-2.68-0.54-5.15-1.8-7.47-2.94c-0.75-0.37-2.45-1.44-4.12-3.28c-1.89-2.09-2.76-4.95-3.86-7.57c-0.31-0.73-1.39-3.42-1.96-5.68
      c-0.56-2.26-1.58-5.94-1.52-9.58c0.02-1.31,0.92-3.69,2.54-2.55c-0.33,0.2-0.72,0.67-0.72,1.68c0,1.01,1.35,2.29,2.48,3.52
      c1.12,1.23,4.15,3.78,5.95,4.89c2.75,1.7,7.83,3.43,12.27,4.56c-0.87,0.85-1.85,1.61-2.06,3.05c1.26-0.49,4.01-1.63,4.25-3.16
      c0.09-0.59-0.52-1.55-0.52-2.21c0-1.84,0.38-3.92-0.21-5.48c-1.35-0.09-3.18,0.28-3.01-0.99c0.16-1.27,5.97-0.05,6.35,0.38
      c0.38,0.42-1.78,0.47-1.78,0.47s0.09,0.52,0.11,0.75C129.39,357.41,139.36,356.25,139.5,355.69z"/>
    <path fill="#4FAAA2" d="M71.76,464.91c0.46-4.24,2.58-16.18,3.73-23.51
      c1.16-7.34,3.52-23.66,3.96-28.39c0.28-0.19,0.74-0.5,1.03-0.88c0.05,1.92,2.41,1.97,2.73,0.78c0.41,0.53,2.76,0.77,2.85-1.12
      c1.52,1.4,2.38,0.74,2.91-0.19c1.38,0.71,2.1-0.81,2.1-0.81s0.71,0.3,1.33,0.15c0.76-0.18,1.51-0.88,0.62-2.02
      c1.78-0.53,1.11-2.07,0.28-2.47c1.48-1.04,0.83-3.1-0.54-3.34c1.24-0.8,0.39-2.72-0.82-3.03c0.5-0.28,0.64-1.03,0.62-1.62
      c1.74,0.59,3.65,0.92,5.6,0.81c0.24,1.11,1.32,1.73,2.96,1.66c-0.14,1.11,1.74,3.85,4.11,2.39c0.1,1.04,1.29,2.36,2.74,1.42
      c0.38,0.57,1.83,0.66,2.1-0.19c0.43,0.61,1.24,0.57,1.67,0.19c0.81,2.08,1.83,6.01,3.82,6.39c2.01,9.69,3.1,21.02,4.3,30.85
      c0.81,6.57,1.77,13.21,2.19,19.67c0.29,4.53,0.9,7.86,2.22,13.46c1.33,5.6,3.35,13.01,5.76,19.33c-2.94,1.84-10.56,3.68-15.81,4.65
      c-5.26,0.97-13.6,2.34-18.16,2.57c-4.56,0.23-12.86,0.05-16.72-0.46c0.46-1.51-1.02-3.31-3.52-2.86
      c-0.89-3.17-3.15-15.65-3.72-19.64C71.53,474.69,71.31,469.16,71.76,464.91z"/>
    <path d="M107.87,335.98c5.16-0.83,8.1,6.15,9.17,8.23c1.3,0.19,1.98,0.25,2.73-0.24
      c0.97,1.47-1.36,1.42-3.01,1.37c-0.17,2.99-1.68,4.12-3.87,4.22c-3.95,0.18-5.76-2.64-5.97-4.99c-1.62-0.27-4.34-0.65-5.53-1.03
      c0.76-0.54,3.13-0.48,4.86-0.26c2.88,0.36,6.38,0.75,9.28,0.62c-0.78-2.06-2.93-6.16-6.6-6.5c-2.91-0.27-2.84,2-2.63,3.71
      C104.78,339.65,104.99,336.44,107.87,335.98z"/>
    <path fill="#FFFFFF" d="M115.46,345.2c0.11,2.38-1.23,3.27-3.06,3.21
      c-2.26-0.07-4.1-1.94-3.71-3.76C110.63,344.97,113.32,345.11,115.46,345.2z"/>
    <path d="M111.02,348.56c1.42-0.32,1.77-2.07,0.61-2.79
      c-1.17-0.72-2.52-0.26-2.93,0.82C108.29,347.68,109.73,348.86,111.02,348.56z"/>
    <path d="M119.72,353.17c-1.69,0.36-2.74,1.67-2.37,3.14
      c0.38,1.47,2.69,2.46,3.17,2.17c0.49-0.28-1.38-0.34-1.93-1.97c-0.55-1.62,0.86-2.71,2.45-2.94
      C121.11,353.13,120.63,352.98,119.72,353.17z"/>
    <path fill="#FFFFFF" d="M101.61,353.61c-0.11,0.05-0.36,0.54-0.33,1.37
      c0.02,0.83,0.28,3.11,0.76,5.24c0.49,2.13,1.1,4.06,1.88,6.12c0.54-1.28,1.03-2.64,1.82-3.77
      C103.53,360.19,102.61,357.07,101.61,353.61z"/>
    <path fill="#FFFFFF" d="M106.84,359.08c0,0-3.5-3.1-4.33-4.49
      c0.47,2.56,1.86,5.4,3.42,7.38C106.38,360.53,106.84,359.08,106.84,359.08z"/>
    <path d="M89.57,362.26c0.94,0.05,0.76-1.28,0.33-1.37c-0.43-0.09-1.1-0.13-1.8-0.09
      c-0.7,0.04-0.97-0.45-1.13-0.99c-0.16-0.54-0.29-1.79-0.75-2.04c-0.79-0.45-1.63-0.2-1.61,0.54c0.03,0.74,1.26,1.8,1.74,2.24
      C87.27,361.4,88.63,362.22,89.57,362.26z"/>
    <path fill="#FFFFFF" d="M107.43,359.57c-0.39,0.82-0.71,1.82-1.02,2.98
      c0.84,1.32,1.84,2.6,3.4,3.92c-0.03-1.43,0.92-4.71,0.92-4.71S108.73,360.6,107.43,359.57z"/>
    <path fill="#FFFFFF" d="M111.39,362.05c-0.48,1.27-0.96,3.48-1.02,4.99
      c0.92,1.04,1.92,2,3.34,2.6c0.31-1.91,0.56-4.13,1.17-6.05C113.79,363.14,112.64,362.67,111.39,362.05z"/>
    <path fill="#FFFFFF" d="M106.13,363.14c-0.89,1.18-1.35,2.51-1.81,3.89
      c0.79,2.46,1.86,4.81,3.34,6.55c0.59-2.01,1.05-4.38,1.96-6.36C108.22,365.92,107.14,364.58,106.13,363.14z"/>
    <path fill="#FFFFFF" d="M115.72,363.85c-0.72,1.71-1.18,4.21-1.4,6.12
      c0.99,0.64,2.72,1.56,4,1.39c0.36-2.26,1.17-4.31,1.82-5.92C118.76,364.98,117.04,364.49,115.72,363.85z"/>
    <path fill="#FFFFFF" d="M110.04,367.62c-0.86,2.3-1.26,3.95-1.86,6.75
      c0.86,1.36,2.7,2.74,4.64,3.65c0.14-3.15,0.79-7.64,0.79-7.64S111.31,369.39,110.04,367.62z"/>
    <path fill="#FFFFFF" d="M114.17,370.71c-0.31,2.32-0.43,5.26-0.72,7.62
      c0.96,0.61,2.94,1.44,4.51,1.58c-0.06-1.96,0.01-5.57,0.29-7.83C116.92,371.98,115.52,371.43,114.17,370.71z"/>
    <path fill="#F9E68E" d="M77.71,386.82c-0.5-0.72-1.19-1.55-1.95-1.32
      c-0.75,0.22-0.62,0.94-0.44,2.04c-1.51-1.16-2.77,0.17-1.88,1.82c-2.01-0.61-2.64,0.44-2.07,1.93c-2.58,0-3.02,1.55-1.45,3.42
      c-1.45,0.11-1.76,1.71-0.13,2.49c-0.63,0.33-1.54,0.86-1.38,1.99c0.25,1.77,1.89,2.93,3.78,3.26c-2.26,2.59-0.82,4.18,1.01,5.13
      c-1.45,2.15,0.63,2.76,1.82,2.21c-1.38,2.26-0.25,3.04,1.19,1.77c0.06,0.55,0.25,1.33,1.39,1.38c1.13,0.06,2.49-1.06,3.03-1.9
      c0.43,2.3,2.08,2.36,2.47,1.1c0.95,0.94,2.58,0.3,2.6-1.56c1.7,2.09,2.78,1.16,2.92-0.03c1.04,1.26,1.94,0.33,2.13-0.51
      c1.78,1.07,2.49-0.52,1.24-1.41c1.95-0.21,1.4-1.7,0.11-2.22c1.49-0.68,1.52-2.5-0.27-3.13c1.01-1.12,0.47-2.67-1.05-3.15
      c0.63-0.3,0.82-1.51,0.48-2.46c-0.47-1.31-1.51-1.43-2.24-1.82c1.4,0.28,2.94,0.04,3.32-1.58c0.38-1.61-0.82-2.46-2.15-3.5
      c2.18,1.38,4.42,0.9,4.45-2.15c-0.56-2.43-3-2.51-4.51-1.63c-0.5-1.71-1.76-2.43-2.64-1.55c-0.69-1.66-2.33-0.22-3.02,0.89
      c-0.75-1.88-2.26-1.88-3.08-0.17C80.42,383.4,78.41,384.72,77.71,386.82z"/>
    <path d="M117.79,387.86c-0.71,0.14-0.87,0.87-0.46,1.66
      c0.41,0.78,1.81,1.14,2.04,0.91C119.6,390.21,118.5,387.72,117.79,387.86z"/>
    <path fill="#F9E68E" d="M102.24,390.79c-0.32,1.13-1.89,5.79-3.07,8.13
      c0.11,0.99,1.13,1.41,2.53,1.23c-0.1,0.48,0.08,2.03,1.45,2.74c1.18,0.61,1.99-0.09,2.57-0.71c0.06,0.61,0.06,1.79,1.03,2.17
      c0.97,0.38,0.7-1.18,0.78-1.83c0.57,0.63,0.51,1.97,1.59,1.97c1.07,0,0.7-1.42,0.7-1.42s0.32,1.15,1.02,1.28
      c0.54,0.09,0.75-0.52,0.92-0.85c1.09,2.19,2.29,4.68,2.85,5.67c0.59,1.04,1.72,1.32,1.94,0.38c0.22-0.95-2.04-4.44-3.61-6.69
      c1.79,0.42,3.07,4.28,3.82,3.14c0.75-1.13-1.51-2.93-1.51-2.93s0.91,0.24,1.77,1.09c0.71-0.96,0-1.94-1.52-2.55
      c0.28-0.24,0.71-0.57,0.23-1.18c-0.49-0.62-1.88-1.32-4.57-2.31c0.7-1.99-1.18-2.13-2.91-1.28c0.33-0.94-0.27-2.6-2.42-1.51
      c0.32-1.27-0.86-2.46-2.31-2.41C103.64,391.69,102.99,390.79,102.24,390.79z"/>
    <path fill="#B1336B" d="M30.21,440.04c1.67-4.2,8.01-6.21,11.28-7.6
      c3.26-1.4,7.23-3.41,11.02-5.58c3.79-2.17,9.97-6.64,12.52-8.44c-2.12,7.24-3.01,17.2-3.27,21.21c-0.26,4.01-0.21,8.02-0.52,9.66
      c-0.31,1.64-1.08,2.97-1.3,3.87c-0.21,0.89-0.09,1.55-0.01,2.7c0.17,2.4-0.25,4.68-1.55,6.46c2.12,2.94,7.18,12.33,8.49,14.34
      c1.32,2.01,2.64,5.65,0.62,8.21c-2.03,2.55-9.54,10.67-13.94,13.48c-0.26,0.81-0.49,2.2,0,3.42c0.59-0.32,1.17-0.15,1.8,0.36
      c-0.18-1.1,0.73-2.5,2.49-1.8c-0.23-1.34,1.88-2.04,3.15-0.97c0.44-0.79,2.39-1.49,3.37-0.82c0.72-2.05,3.12-1.31,3.7-0.68
      c0.58-1.16,2.04-1.49,3.43-0.39c0.4-1.1,1.77-0.96,2.46-0.43c-0.73-4.44-3.23-17.15-3.58-21.86c-0.34-4.71-0.12-8.7,0.58-13.51
      c0.7-4.81,1.86-12.99,3.15-20.26c1.28-7.26,3.65-22.32,4.41-27.97c-0.95,0.53-2.38,0.24-2.75-1c-0.74,0.81-3.13-0.07-1.55-2.15
      c-1.71,0.75-2.97-0.8-1.96-2.46c-1.91-1.24-2.57-3.26-1.32-5.08c-0.69-0.11-1.51-0.5-1.89-1.1c-7.91,2.98-15.06,6.88-21.59,11.24
      c-3.5,2.33-8.79,6.36-11.71,9.27c-4.07,4.05-9.51,10.29-11.58,13.56c-2.07,3.28-4.74,7.08-4.01,9.55
      c0.73,2.46,5.02,3.25,10.41,7.05c3.97,2.81,14.81,11.93,16.75,14.25c2.11-1.86,7.23-5.96,10.3-7.22c0.13-0.7-1.71-2.36-2.44-3.17
      C49.85,450.36,35.04,440.81,30.21,440.04z"/>
    <path fill="#B1336B" d="M117.73,405.15c0.43,1.23-0.19,2.03-1.45,1.94
      c0.81,1.27,1.72,3.07,0.38,3.97c1.83,8.5,3.79,25.69,4.49,31.08c0.69,5.38,1.45,10.66,1.7,14.37c0.26,3.72,0.89,7.6,1.58,12.43
      c0.69,4.83,3.35,16.54,5.87,22.59c-0.05-0.96,0.46-2.54,2.05-2.04c0.26-1.07,1.69-1.22,2.54-0.28c0.25-0.72-0.55-2.67-0.74-5.06
      c-0.19-2.39-0.82-8.49-0.88-11.54c-0.06-3.05-0.38-7.49-0.82-13.2c-0.44-5.71-1.52-13.26-2.21-17.42
      c-0.69-4.16-2.29-9.53-3.11-13.4c-0.82-3.87-2.52-10.93-1.76-11.54c1.14,0.95,2.26,6.3,2.26,6.3s5.53,5.13,9.49,8.17
      c3.69,2.83,8.11,5.52,10.68,6.96c2.06,1.14,5.6,2.76,7.73,4.14c2.14,1.38,4.47,5.38,3.52,5.3c-1.38-0.11-1.65-2.27-2.32-2.87
      c-0.82,0.88,0.75,3.37,0.12,3.48c-0.63,0.11-1.01-1.55-1.19-2.1c-3.55,2.05-7.73,5.3-10.37,7.4c-2.64,2.1-5.47,4.25-7.42,6.63
      c1.02,2.12,5.25,6.26,7.92,6.57c1.24-1.37,7.12-7.55,8.71-8.99c1.44-1.31,7.58-6.52,9.26-8.02c1.2-1.07,3.89-3.35,3.98-4.52
      c0.17-1.99-3.85-4.42-6.05-6.07c-2.2-1.66-4.37-3.02-5.91-4.21c-4.58-3.52-9-6.82-13.64-10.29c-4.56-3.41-8.76-7.16-13.12-10.74
      C127.53,412.93,120.31,407.04,117.73,405.15z"/>
    <path fill="#EDCA83" d="M54.95,487.07c0.73,1.04-2.89,2.97-4.71,3.82
      c-1.83,0.84-3.71,1.57-5.44,1.17c-1.74-0.4-2.84-1.17-4.25-2.45c-0.51,0.03-1.69,0.64-1.42,1.97c0.28,1.33,3.48,2.41,4.8,2.77
      c1.33,0.36,3.8,0.61,5.26,0.2c1.46-0.4,5.8-3.09,8.04-5.22c1.51,1.08-3.35,3.94-4.97,4.86c-2.06,1.17-3.89,2.05-6.04,1.57
      c-0.08,0.71,1.97,2.29,5.18,2.01c1.99-0.18,4.2-2.21,5.57-3.42c1.37-1.21,3.97-3.82,6.06-6.26c1.39-1.62,4.06-3.93,4.12-6.44
      c0.06-2.61-1.97-5-3.09-7.01c-1.49-2.68-6.69-11.75-7.4-12.92c-2.12,0.16-6.11,3.64-6.24,4.66c-0.9,7.11,3.24,15.94,3.33,16.72
      c0.09,0.78-6.16,5.64-7.84,5.56c-1.5-0.08-3.98-3.93-5.57-4.08c-1.58-0.15-2.03,0.77-1.5,1.78c0.53,1,3.3,3.09,4.58,4.02
      c1.28,0.93,2.83,1.17,4.94,0.36C50.47,489.93,53.62,487.76,54.95,487.07z"/>
    <path fill="#EDCA83" d="M143.84,486.5c-0.97-0.83-3.08-1.6-3.33-2.82
      c2.2,0.61,7.61,4.25,8.55,4.47c0.94,0.22,6.34-1.21,7.04-1.82c-0.15-1.06-1.45-1.44-2.58-1.38c-1.13,0.05-3.58,1.27-4.02,1.11
      c-0.44-0.17-2.26-1.88-3.65-2.93c-1.38-1.05-2.45-2.09-5.26-2.74c1.28-1.73,2.74-8.7,2.7-12.32c-1.85-1.24-4.09-3.4-5.18-4.54
      c-1.19,1.76-2.45,3.47-3.7,4.88c0.05,3.41,0.19,6.61,0.39,10.63c0.11,2.23,0.2,5.85,1.05,7.01
      C136.97,487.57,141.71,486.8,143.84,486.5z"/>
    <path fill="#F9E68E" d="M135.26,490.38c-1.39-1.46-2.22-0.98-2.45-0.14
      c-1.34-0.73-2.1,0.99-1.82,2.46c0.21,0.75,0.65,1.71,0.97,2.27c-0.78,1.1-2.68,1.71-3.14,1.95c-0.11,0.83-0.49,2.86,0.21,3.86
      c0.74,1.06,1.66,0.35,1.92-0.06c0.7,2.03,2.33,1.37,2.57,0.24c0.99,1.97,2.13,1.55,2.82,0.77c1.2,1.56,2.73,0.81,2.17-0.71
      c1.46,1.16,2.45,0.53,2.22-0.61c1.97,1.38,2.64,0.18,1.71-0.63c2.38,0.37,1.99-2.03,0.21-2.21c0.67-0.35,1.85-0.81,1.94-1.68
      c0.09-0.88-1.02-1.08-1.53-1.06c1.55-0.97,0.48-2.03-1.29-1.4c0.81-1.57-0.33-2.13-1.55-1.73c0.44-0.65,0.65-1.58-0.76-2.22
      C138.04,488.85,136.07,488.93,135.26,490.38z"/>
    <path fill="#8BB099" d="M116.02,500.29c0.1,0.84-1.11,11.15-0.92,17.93
      c0.95,0.9,6.26,1.95,7.82,1.87c0.69-4.52,1.25-16.19,1.59-21.72C122.35,498.9,118.87,499.58,116.02,500.29z"/>
    <path fill="#F9E68E" d="M72.14,498.91c0,0-2.77-2.52-3.89,0.2
      c-1.61-1.69-3.05-1.11-3.59,0.51c-1.43-1.22-3.18,0-3.49,0.79c-1.08-1.3-3-0.94-2.64,0.98c-1.12-0.79-2.74-0.51-2.69,2
      c-1.12-1.22-2.02-1.22-2.73-0.71c-0.31-0.43-1.3-1.1-2.2-0.67c-0.89,0.43-0.58,1.34-0.04,1.81c-0.85-0.28-1.97-0.24-2.19,0.74
      c-0.23,0.99,0.85,1.62,2.06,1.46c-2.34,2.83-1.35,4.92,1.16,5c-0.71,1.38-0.71,2.56,1.21,2.48c-0.4,1.85,2.02,1.3,2.6-0.75
      c0.14,0.67,0.14,2.09,1.34,2.6c1.56,0.66,1.79-1.14,1.79-2.04c1.39,2.52,3.05,1.57,3.09,0.75c1.35,0.59,2.29-0.08,1.66-1.26
      c2.29,0.55,2.72-0.74,2.69-1.38c2.37,0.75,4.21,0.19,4.74-1.22c1.03,1.06,3.41,1.53,3.54-0.98c1.17,1.65,2.64,0.63,2.69-0.86
      c3.45,1.1,4.3-1.69,1.61-2.36c0.94-0.24,2.27-0.77,1.7-2.05c-0.4-0.9-1.07-0.75-1.88-0.47c0.49-0.28,0.72-1.73-0.72-1.89
      c1.12-1.3-1.12-3.39-2.8-2.24c-0.21-0.64-0.65-1.77-2.08-1.69C71.66,497.73,72.14,498.91,72.14,498.91z"/>
    <path fill="#D68327" d="M80.64,503.01c1.58,0.9,1.29,2.85-0.1,3.07
      c2.47,1.82,0.03,3.82-1.43,3.49c1.3,5.16-0.28,9.51,0.01,11.46c4.15,2.71,17.15,5.94,24.17,3.57c-0.04-4.41,0.92-18.23,3.34-20.34
      c0.37,1.52-1.4,9.82-1.67,13.68c-0.27,3.86-0.47,7.33-0.39,9.27c5.9-1.09,14.3-1.09,18.33-0.13c0.28-2,0.24-3.87,0.07-5.74
      c-1.79-0.13-5.64-0.97-9.22-2.16c-0.09-5.17,0.29-12.12,0.9-18.67c-5.93,1.27-13.63,2.53-19.1,2.87
      C90.08,503.7,84.86,503.47,80.64,503.01z"/>
    <path fill="#BA4182" d="M77.81,532.05c6.76,2.27,17.32,2.37,25.04,0.42
      c0.29-2.04,0.29-4.66,0.31-6.41c-8.03,1.4-19.22-1.14-24.45-4.07C77.72,524.61,77.24,528.82,77.81,532.05z"/>
    <path fill="#BA4182" d="M104.78,528.28c-0.18,2.53-0.35,5.37-0.33,7.51
      c8.28-1.91,15.69-0.99,19.2-0.3c-0.42-2.23-0.61-4.83-0.67-7.16C120.13,527.07,111.33,527.05,104.78,528.28z"/>
    <path fill="#D68327" d="M77.94,533.19c-0.44,1.99-0.44,4.84-0.08,7.51
      c2.53-0.35,13.31-0.19,14.23,0.86c0.91,3.12,0.82,7.53,0.38,9.49c2.53,0.93,7.26,2.06,10.28,1.09c0.15-4.36,0.49-15,0.02-18.84
      C91.8,536.56,78.03,533.19,77.94,533.19z"/>
    <path fill="#9875A7" d="M104.78,536.79c-0.02,2.98-0.09,5.99-0.18,7.75
      c5.76-0.92,13.89-1.84,19.48-2.22c0.26-1.83-0.27-4.39-0.52-5.76C120.24,535.72,111.32,535.16,104.78,536.79z"/>
    <path fill="#3A8AB4" d="M78.05,541.88c0.29,4.92,0.48,11.45-0.89,16.07
      c1.69,1.05,5.77,1.5,7.93,1.48c-0.39-2.2-0.39-5.51-0.47-8.27c2.21,0.13,4.52-0.04,6.45-0.46c0.77-1.4,0.58-5.26,0.19-8.14
      C87.51,540.95,80.57,541.29,78.05,541.88z"/>
    <path fill="#8BB099" d="M102.51,563.71c0.46-0.55,0.42-7.76,0.26-10.4
      c-3.49,0.02-7.93-0.32-11.59-1.68c-2.31,0.49-4.15,0.59-5.4,0.51c-0.19,1.44,0,5.26,0.67,7.46
      C90.99,561.55,98.99,563.58,102.51,563.71z"/>
    <path fill="#9875A7" d="M129.06,576.47c0.43-2.79-0.79-7.98-2.1-10.84
      c-5.76,1.55-16.84,1.2-22.8,0.48c-0.52,2.38-0.96,8.14-0.88,11.51C113.77,574.93,125.04,575.78,129.06,576.47z"/>
    <path fill="#8BB099" d="M74.16,588.33c0-0.37-0.65-0.37-0.65,0c0,0.4,0,0.8-0.05,1.2
      c-0.68-0.22-1.27-0.44-1.73-0.63c0.23-6.18,3.56-19.52,4.28-21.1c0.33-0.05,0.72-0.07,1.16-0.06c-0.02,0.49,0.11,0.95,0.51,1.3
      c0.3,0.26,0.76-0.15,0.46-0.41c-0.26-0.23-0.34-0.54-0.33-0.87c0.63,0.03,1.34,0.11,2.07,0.21c0.02,0.61,0.19,1.22,0.49,1.74
      c0.2,0.33,0.76,0.04,0.57-0.29c-0.23-0.4-0.37-0.88-0.41-1.35c0.32,0.05,0.64,0.11,0.96,0.17c-0.02,0.29-0.01,0.59,0.05,0.88
      c0.07,0.36,0.7,0.21,0.63-0.16c-0.04-0.21-0.05-0.41-0.05-0.61c0.43,0.08,0.86,0.17,1.27,0.27c-0.03,0.35-0.01,0.72,0.06,1.07
      c0.07,0.37,0.7,0.21,0.63-0.15c-0.05-0.25-0.07-0.51-0.05-0.77c0.65,0.16,1.27,0.33,1.81,0.51c-0.05,0.28-0.07,0.57-0.07,0.87
      c0,0.37,0.65,0.37,0.66,0c0-0.22,0.01-0.44,0.04-0.66c0.64,0.23,1.14,0.45,1.4,0.65c-0.01,0.25-0.04,0.52-0.06,0.8
      c-0.56,0.11-1.09,0.33-1.52,0.68c-0.31,0.25,0.15,0.66,0.46,0.41c0.29-0.23,0.63-0.38,1-0.48c-0.06,0.56-0.13,1.18-0.22,1.84
      c-0.35,0.05-0.7,0.14-1.05,0.29c-0.38,0.16-0.05,0.66,0.33,0.5c0.22-0.09,0.43-0.15,0.64-0.19c-0.05,0.39-0.11,0.79-0.17,1.21
      c-0.28-0.02-0.56,0-0.85,0.04c-0.42,0.06-0.24,0.61,0.18,0.55c0.2-0.03,0.39-0.03,0.58-0.03c-0.09,0.61-0.19,1.25-0.29,1.89
      c-0.53-0.08-1.07-0.09-1.6,0c-0.42,0.06-0.24,0.62,0.17,0.56c0.44-0.07,0.9-0.06,1.34,0.01c-0.07,0.46-0.15,0.92-0.23,1.38
      c-0.3-0.01-0.6-0.01-0.9-0.01c-0.42,0-0.42,0.57,0,0.57c0.27,0,0.54,0,0.81,0c-0.08,0.5-0.17,1.01-0.26,1.52
      c-0.43-0.07-0.85-0.1-1.2-0.1c-0.42,0-0.42,0.57,0,0.58c0.31,0.01,0.7,0.03,1.1,0.09c-0.14,0.8-0.29,1.59-0.43,2.37
      c-0.55-0.07-1.1-0.06-1.66,0.08c-0.4,0.11-0.23,0.66,0.17,0.56c0.46-0.12,0.93-0.13,1.38-0.07c-0.09,0.45-0.17,0.88-0.25,1.31
      c-0.41-0.06-0.83-0.06-1.25-0.01c-0.41,0.05-0.24,0.61,0.17,0.56c0.32-0.04,0.65-0.03,0.97,0.01c-0.09,0.44-0.17,0.87-0.25,1.29
      c-0.48-0.08-0.98-0.09-1.49-0.02c-0.42,0.06-0.24,0.61,0.17,0.55c0.41-0.06,0.82-0.04,1.21,0.02c-0.13,0.65-0.25,1.25-0.36,1.81
      c-0.42-0.01-0.83,0.11-1.16,0.43c-0.29,0.27,0.17,0.68,0.46,0.41c0.17-0.16,0.37-0.24,0.58-0.26c-0.07,0.32-0.13,0.63-0.19,0.89
      c-0.59-0.11-1.2-0.23-1.83-0.35c-0.01-0.3-0.02-0.6-0.01-0.91c0.01-0.37-0.64-0.37-0.65,0c-0.01,0.26,0,0.52,0.01,0.77
      c-0.48-0.1-0.95-0.21-1.44-0.32c0.01-0.34-0.02-0.7-0.09-1.05c-0.07-0.37-0.7-0.21-0.63,0.15c0.05,0.25,0.08,0.51,0.08,0.76
      c-0.4-0.09-0.81-0.19-1.21-0.29c0-0.27-0.02-0.55-0.09-0.83c-0.09-0.36-0.72-0.21-0.63,0.15c0.04,0.17,0.06,0.34,0.08,0.51
      c-0.5-0.13-0.99-0.26-1.47-0.39c0.09-0.33,0.13-0.68,0.12-1.04c-0.01-0.37-0.67-0.37-0.66,0c0.01,0.3-0.02,0.59-0.09,0.87
      c-0.56-0.16-1.1-0.31-1.6-0.47C74.16,589.27,74.16,588.79,74.16,588.33z"/>
    <path fill="#3A8AB4" d="M132.5,588.74c0.39-2.76-1.43-8.28-3.61-10.97
      c-1.85-0.94-8.82-1.07-12.49-0.92c-3.67,0.16-10.05,0.77-12.93,1.99c-0.61,3-0.61,8.52-0.7,11.65
      C112.29,584.91,127.22,586.6,132.5,588.74z"/>
    <path fill="#9875A7" d="M133.14,590.38c2.21,3.97,4.75,11.95,5.53,15.48
      c-2.31,1.99-9.11,5.66-13.37,7.14c-4.46,1.55-8.73,2.69-12.93,3.23c-2.73,0.35-5.76,0.38-9.09-0.23
      c-1.22-2.61-1.13-19.03-0.64-23.91c3.35-2.1,9.29-3.72,13.84-4.1C121.02,587.59,129.37,588.33,133.14,590.38z"/>
    <path fill="#3A8AB4" d="M103.55,617.21c-0.04,2.26,0.35,5.07,1.31,8.06
      c6.2,0.77,17.38,1.23,24.81,0.31c7.42-0.92,17.21-3.38,17.38-3.45c-1.05-3.3-4.41-10.95-7.59-14.98
      c-3.51,2.39-9.27,5.92-17.66,8.15C114.72,617.18,108.88,617.98,103.55,617.21z"/>
    <path fill="#D68327" d="M62.37,615.81c0.76,0.69,1.71,1.3,2.81,1.83
      c-0.05,0.45,0.02,0.89,0.3,1.24c0.25,0.33,0.85,0.02,0.6-0.31c-0.14-0.18-0.2-0.39-0.22-0.62c0.77,0.34,1.62,0.65,2.52,0.92
      c-0.01,0.58,0.18,1.16,0.57,1.57c0.29,0.3,0.78-0.13,0.49-0.44c-0.22-0.23-0.34-0.57-0.37-0.92c1.09,0.31,2.26,0.58,3.5,0.81
      c0.17,0.5,0.48,0.99,0.77,1.38c0.25,0.33,0.85,0.03,0.6-0.31c-0.19-0.26-0.43-0.59-0.59-0.94c0.77,0.13,1.57,0.25,2.38,0.36
      c-0.09,0.7,0.09,1.36,0.87,1.75c0.39,0.2,0.74-0.33,0.35-0.53c-0.49-0.25-0.61-0.67-0.55-1.13c1.38,0.17,2.82,0.29,4.3,0.38
      c0.01,0.36,0.09,0.72,0.28,1.07c0.19,0.36,0.8,0.05,0.61-0.31c-0.13-0.23-0.18-0.48-0.2-0.72c1.34,0.07,2.7,0.13,4.09,0.15
      c0.03,0.39,0.17,0.76,0.52,1.09c0.31,0.29,0.8-0.15,0.49-0.43c-0.2-0.19-0.29-0.42-0.32-0.66c0.63,0.01,1.26,0,1.89,0
      c0.09,0.35,0.23,0.72,0.43,1.07c0.21,0.35,0.81,0.04,0.6-0.31c-0.15-0.25-0.26-0.51-0.32-0.76c0.72-0.01,1.44-0.02,2.16-0.04
      c0.08,0.31,0.2,0.61,0.37,0.88c0.22,0.34,0.82,0.03,0.6-0.31c-0.12-0.18-0.21-0.38-0.27-0.59c0.73-0.02,1.45-0.06,2.18-0.1
      c0.03,0.56,0.29,1.08,0.92,1.35c0.4,0.18,0.75-0.35,0.35-0.53c-0.4-0.18-0.57-0.5-0.59-0.86c0.74-0.04,1.49-0.09,2.22-0.14
      c0.14,0.4,0.37,0.78,0.72,1.12c0.31,0.29,0.8-0.14,0.49-0.43c-0.23-0.22-0.4-0.47-0.51-0.74c0.53-0.04,1.05-0.08,1.57-0.13
      c0.12,0.44,0.39,0.84,0.88,1.11c0.37,0.22,0.72-0.31,0.35-0.53c-0.29-0.17-0.45-0.4-0.54-0.66c0.85-0.08,1.71-0.16,2.54-0.26
      c0.36,3.92,1.22,7.22,2.01,10.75c-8.3,1.03-17.93,0.48-26.18,0.24c-6.87-0.21-13.08-1.3-18.14-3.05c-1.44-0.5-2.92-0.79-3.93-1.77
      C57.4,624.14,60.67,618.52,62.37,615.81z"/>
    <path fill="#AE296B" d="M183.44,630.4c-0.95,0.06-1.52-0.92-1.82-1.99
      c-1.19,0.5-1,3.07-0.75,4.25c-1.14,0.37-1.26-2.18-1.08-3.34c-1.06-0.1-2.75,0.59-3.12,1.45c-0.26,0.61-0.43,1.88-0.44,3.25
      c-0.91-0.24-0.6-2.18-0.51-3.17c-1.21,0.05-1.94,0.16-2.66,0.68c-0.73,0.53-2.01,2.89-2.23,3.61c-0.69-0.73,0.34-2.6,0.9-3.73
      c-1.3-0.75-3.31-0.59-4.1-0.11c-0.79,0.48-2.58,2.75-2.45,4.4c-0.72-0.59-0.72-2.63,1.93-4.97c-0.8-0.54-2.39-1.08-3.47-0.68
      c-1.99,1.09-4.03,2.87-4.12,5.54c-1.41-0.32-0.62-4.13,3.48-5.88c-0.95-1.02-2.67-1.35-3.73-1.04c-2.99,0.86-5.38,3.81-5.66,5
      c-1.42-1.67,2.37-4.46,4.89-5.54c-0.64-0.82-2.59-1.53-3.75-1.39c-4.46,0.54-6.29,5.04-6.29,6.71c-1.35-1.61,0.49-5.58,4.7-7.25
      c-0.14-0.86-2.32-1.34-3.8-1.46c-3.47,1.41-5.6,4.17-5.97,7.58c-1.94-1.17,1.04-5.85,4.31-7.69c-0.78-0.7-4.31-0.4-5.77,0.61
      c-2.57,1.77-3.73,4.03-4.14,6.4c-1.3-1.47,0.54-4.74,2-6.03c-1.44-0.13-3.66,0.27-4.7,0.94c-1.31,0.85-1.28,3.41-1.41,4.97
      c-0.85-1.12-0.85-2.85-0.17-4.73c-1.25-0.08-2.43,0.15-3.12,0.91c-0.76,0.84-0.96,2.29-1.08,3.48c-0.63-0.15-0.99-2.08,0.39-4.07
      c-2.06,0-3,0.4-3.77,0.92c-0.77,0.51-0.43,2.42-0.74,3.27c-0.93-0.54-0.31-2.63,0.06-3.65c-0.55-0.16-2.64-0.17-3.35,0.22
      c-0.4,1.01-0.37,1.43-0.43,2.74c-0.73-0.48-0.61-1.79-0.3-2.84c-0.85-0.16-2.38-0.05-3.3-0.05c-0.2,0.8-0.16,1.73-0.13,2.69
      c-0.96-0.17-0.72-2.69-0.72-2.69s-4.52-0.38-7.4-0.64c-0.36,0.59-0.7,1.9-0.49,2.9c5.57,0.12,12,2.09,15.81,3.03
      c3.81,0.94,8.62,2.24,14.8,3.28c6.18,1.04,16.26,1.36,24.02,0.99c7.76-0.38,13.13-1.45,16.8-3.22c3.66-1.77,5.56-4.03,6.78-5.53
      c1.22-1.5,2.2-3.81,2.08-4.88c-0.12-1.07-2.39-2.2-3.3-1.99c-0.92,0.22-3.3,4.94-3.54,6.15C182.6,628.94,182.9,629.37,183.44,630.4
      z"/>
    <path fill="#9E6E5F" d="M107.26,630.99c0,0.68,0.44,2.6,0.11,2.98
      c4,0.76,11.38,1.73,14.2,1.3c-0.12-0.7-0.49-1.66-0.73-2.09C118.65,632.36,111.04,631.26,107.26,630.99z"/>
    <path fill="#A7006E" d="M67.73,632.06c-1.04,2.15-0.86,8.43-0.86,8.43
      s1.1,0.06,2.08,0.32c-0.31-1.18-0.05-6.38,0.43-8.53C69.2,632.09,67.86,632.06,67.73,632.06z"/>
    <path fill="#AE296B" d="M101.61,639.67c3.13-1.35,10.01-2.94,11.96,0.12
      c-7.17,2.58-13.28,6.72-12.99,9.38c1.97-2.64,10.59-8.94,14.25-8.51c1.78,0.21,3.79,1.82,3.76,2.52c-3.99,1.36-7.72,3.54-8.62,7.23
      c1.46-1.6,3.21-3.64,4.8-4.66c1.59-1.02,4.03-2.04,5.62-2.15c1.59-0.11,2.99,0.23,3.48,0.93c-1.87,1.11-4.44,3.02-3.86,5.54
      c0.94-2.44,3.56-4.75,6.62-5.34c0.68-0.13,2.79-0.14,3.24,0.43c-1.04,1.07-2.39,2.36-1.75,3.67c1.01-1.62,2.61-3.4,3.34-3.83
      c0.74-0.43,4.01-1.45,5.07-0.86c-1.4,2.25-1.4,4.51-1.4,5.48c-1.89,0.91-4.61,1.79-8.06,2.79c-3.73,1.07-8.55,2.46-13.01,2.25
      c-4.46-0.22-10.17-2.51-14.49-3.91c-2.74-0.89-5.44-1.89-8.11-2.71c-4.11-1.26-6.86-2.07-9.55-3.41c0-0.81,0.12-2.15,0.3-2.9
      c-0.88,0.2-1.95,1.97-2.08,2.52c-2.57-1.07-7.64-2.52-10.2-3.22c-0.25-0.91-0.4-7.72,0.37-8.8c1.34,0.12,3.06,0.22,4.15,0.32
      c-0.74,1.57-1.71,7.46-0.63,8.03c0.2-2.34,0.87-5.93,1.6-8.03c1.04,0,2.69,0,4.09,0.11c-1.41,2.13-3.18,6.09-2.02,6.76
      c0.58-2.59,2.02-5.2,3.66-6.87c1.29,0.11,3.06,0.16,4.36,0.23c-2.04,1.97-3.6,5.26-3.35,7.01c0.94-1.98,2.47-4.83,5.65-6.81
      c3.97,0.11,9.29,0.27,13.78-0.43c1.98,1.18,5.04,2.63,7.08,4.52c-3.81,0.25-6.93,1.44-9.43,2.83c-2.57,1.43-5.32,3.54-6.01,4.97
      C95.59,643.01,98.29,641.1,101.61,639.67z"/>
    <path d="M100.97,633.61c-6.1,0.21-9.82,2.47-12.09,6.05
      c2.77-2.99,6.77-4.91,12.22-5.54C101.1,633.98,100.97,633.77,100.97,633.61z"/>
    <path fill="#A7006E" d="M66.81,632.01c-1.28-0.11-2.14,0.16-2.32,1.93
      c-0.18,1.77,1.08,5.57,1.96,6.39C66.39,638.98,65.78,635.01,66.81,632.01z"/>
    <path fill="#A7006E" d="M63.94,633.51c-1.46,1.39-1.96,7.3,1.77,6.87
      C64.92,638.44,63.58,635.76,63.94,633.51z"/>
    <path fill="#AE296B" d="M141.36,643.06c-0.79,0.97-1.72,2.57-1.35,3.97
      c1.22-0.16,2.32-0.7,3.93-1.49c1.07-0.53,2.79-1.73,2.79-2.32c0-0.59-0.67-1.89-1.16-1.93
      C144.94,641.23,142.15,642.09,141.36,643.06z"/>
    <path fill="#9E6E5F" d="M63.76,642.1c-0.12,1.61,0.48,3.2,1.47,3.92
      c5.87-0.54,14.54,0.97,14.54,0.97s0.49-0.7,0.67-1.24c-0.29-0.95-8.6-3.1-10.38-3.38C67.37,641.93,64.49,641.61,63.76,642.1z"/>
    <path fill="#AE296B" d="M136.23,648.71c1.06,0.14,2.25-0.47,2.96-1.02
      c-0.4-1.73,0.04-3.31,0.58-4.42C137.78,642.1,135.63,647.05,136.23,648.71z"/>
    <path fill="#B57E32" d="M75.36,337.63c-0.67,1.12-2.33,4.83-1.56,6.54
      c0.64-1.18,1.44-2.02,2.71-2.26c-0.56,1.21-0.79,3.52,1.17,4.24c-1.33-1.64,0.18-3.99,2.37-3.97c0.44,0.47,0.92,1.41,1.46,2.51
      c3.16,0.81,1.22-2.61,3.42-2.96c1.09-0.17,1.42,0.51,1.63,1.33c-0.38-0.32-0.92-0.84-1.53-0.45c-0.61,0.38-0.47,1.65-0.9,2.27
      c-0.43,0.62-1.33,0.78-2.26,0.55c0.18,0.86,0.92,1.93,2.01,1.84c1.1-0.09,0.57-3.43,3.01-1.9c-0.69,0.07-1.3,0.04-1.62,0.75
      c-0.23,0.5-0.11,1.52-0.92,1.85c-0.81,0.33-1.4-0.07-1.77-0.27c0.6,2.77,1.95,5.8,1.95,5.8s2.37,0.92,3.52,1.81
      c1.64-3.59-0.71-11.95-0.23-15.53c0.35-2.56,1.34-4.55,1.84-5.77c0.7-1.71,0.49-3.74,2.31-4.52c1.32-0.57,3.12-0.49,4.89-0.57
      c2.39-0.11,4.84-1.8,7.59-2.26c1.55-0.26,3.26,0.06,4.78-0.14c1.51-0.2,3.39-0.96,4.68-1.07c1.83-0.15,2.85,0.38,4.98,0.54
      c3.3,0.23,6.55,0.19,9.87-0.42c3.32-0.61,5.98-1.5,8.08-1.88c1.77-0.32,4.37-0.38,6.9-0.85c2.27-0.41,4.54-1.19,6.55-2.3
      c0.87-0.48,2.37-1.71,2.92-2.35c1.11-1.28,2.32-3.67,1.92-6.13c-0.15-0.93-1.39-2.55-3.08-2.46c1.05,1.61,1.14,4.57-0.51,5.76
      c2.13-4.92-1.51-8.79-8.76-7.29c-2,0.41-3.14,1.22-4.04,2.12c-0.84,0.85-1.56,2.28-2.49,2.44c-0.94,0.16-1.34-0.59-0.99-1.65
      c-0.86,0.87-0.82,2.46,0.44,2.73c1.21,0.26,2.45-1.73,4.71-2.58c2.26-0.86,6.55-1.34,6.26,2.43c-0.3,3.76-3.28,5.73-6.43,6.89
      c-1.9,0.7-4.74,0.59-7.18,1.05c-2.45,0.46-6.33,2.14-10.32,1.78c-2.65-0.24-3.97-1.55-7.46-1.25c-0.95,0.08-2.16,0.48-3.34,0.57
      c-1.41,0.1-3.03-0.37-4.38-0.37c-1.35,0-2.19,1-3.49,1.19c-1.31,0.2-3.82-0.03-6.54,0.54c-4.43,0.92-5.48,3.34-7.31,3.99
      c-1.83,0.65-3.53,0.46-5.54,0.77c-2.01,0.31-5.72,1.08-8.08,2.88c-2.36,1.81-4.04,4.83-4.42,7.9
      C72.87,338.93,74.34,337.92,75.36,337.63z"/>
    <path fill="#BA4182" d="M104.25,564.96c7.31,1.05,20.39,0.54,22.71-1.23
      c-1.47-6.46-2.1-13.41-2.79-20.26c-7.16,0.54-13.26,1.4-19.57,2.31C104.34,550.91,104.17,562.43,104.25,564.96z"/>
    <path d="M99.09,398.06c0,0-0.89,0.28-1.97,0.28c-1.08,0-2.79-0.33-3.8-0.73
      c-1.01-0.39-1.71-0.83-1.71-0.83l-0.17,1.11c0,0,0.42,0.5,1.75,0.89c1.33,0.39,2.09,0.56,3.36,0.61c1.27,0.06,2.41,0,2.41,0
      L99.09,398.06z"/>
    <path fill="#D68327" d="M68.03,600.22c0.38-1.78,1.78-7.2,2.83-9.86
      c0.55,0.24,1.22,0.49,1.97,0.74c-0.07,0.19-0.15,0.38-0.27,0.56c-0.22,0.35,0.39,0.66,0.6,0.31c0.14-0.22,0.24-0.43,0.32-0.66
      c0.49,0.15,1,0.31,1.52,0.46c-0.21,0.28-0.49,0.53-0.85,0.73c-0.37,0.22-0.02,0.75,0.35,0.53c0.51-0.29,0.89-0.66,1.17-1.07
      c0.61,0.17,1.24,0.34,1.87,0.5c-0.17,0.67-0.55,1.29-1.03,1.89c-0.26,0.32,0.34,0.63,0.6,0.31c0.52-0.64,0.9-1.31,1.1-2.02
      c0.4,0.1,0.79,0.2,1.18,0.29c-0.12,0.18-0.28,0.34-0.52,0.48c-0.38,0.21-0.02,0.75,0.35,0.53c0.41-0.23,0.68-0.53,0.85-0.85
      c0.62,0.15,1.22,0.28,1.79,0.4c-0.02,0.39-0.1,0.77-0.31,1.15c-0.2,0.36,0.41,0.67,0.6,0.31c0.24-0.44,0.33-0.87,0.37-1.32
      c0.96,0.2,1.81,0.36,2.45,0.46c0.13-0.11,0.34-0.79,0.6-1.87c0.54,0.26,1.02,0.73,1.17,1.13c0.15,0.37,0.82,0.21,0.68-0.16
      c-0.25-0.63-0.93-1.27-1.7-1.58c0.13-0.55,0.27-1.18,0.41-1.87c0.44,0.17,0.84,0.39,1.22,0.66c0.35,0.25,0.84-0.18,0.5-0.43
      c-0.49-0.36-1.02-0.64-1.58-0.83c0.09-0.44,0.18-0.9,0.28-1.37c0.31,0.12,0.61,0.27,0.88,0.46c0.35,0.24,0.85-0.19,0.49-0.44
      c-0.38-0.27-0.8-0.48-1.25-0.63c0.09-0.45,0.18-0.91,0.27-1.39c0.3,0.1,0.6,0.23,0.87,0.38c0.39,0.21,0.74-0.33,0.36-0.53
      c-0.35-0.19-0.73-0.34-1.11-0.46c0.16-0.82,0.31-1.67,0.47-2.52c0.44,0.15,0.82,0.38,1,0.7c0.2,0.36,0.8,0.05,0.6-0.31
      c-0.27-0.49-0.84-0.81-1.49-1.01c0.1-0.58,0.21-1.17,0.31-1.75c0.28,0.05,0.56,0.13,0.83,0.25c0.4,0.18,0.75-0.34,0.35-0.53
      c-0.35-0.16-0.71-0.27-1.08-0.33c0.08-0.44,0.15-0.88,0.22-1.31c0.38,0.14,0.75,0.33,1.08,0.54c0.36,0.23,0.71-0.3,0.35-0.53
      c-0.4-0.26-0.86-0.48-1.33-0.63c0.12-0.71,0.23-1.41,0.34-2.07c0.36,0.12,0.71,0.28,1.01,0.5c0.35,0.25,0.84-0.19,0.49-0.44
      c-0.43-0.31-0.91-0.53-1.41-0.67c0.08-0.53,0.16-1.03,0.23-1.51c0.52,0.06,1.03,0.23,1.52,0.48c0.39,0.2,0.74-0.33,0.35-0.53
      c-0.59-0.3-1.18-0.48-1.79-0.55c0.11-0.78,0.21-1.48,0.27-2.06c0.43,0.02,0.86,0.12,1.23,0.28c0.4,0.18,0.76-0.36,0.35-0.53
      c-0.47-0.21-0.99-0.32-1.52-0.36c0.06-0.61,0.09-1.05,0.06-1.25c-0.52-0.33-1.36-0.69-2.38-1.04c0.17-0.34,0.42-0.64,0.84-0.86
      c0.39-0.21,0.03-0.73-0.35-0.53c-0.57,0.3-0.92,0.71-1.13,1.18c-0.62-0.19-1.3-0.38-1.99-0.56c0.12-0.27,0.29-0.53,0.55-0.76
      c0.32-0.28-0.18-0.71-0.5-0.43c-0.34,0.3-0.58,0.64-0.73,1.02c-0.46-0.11-0.92-0.22-1.4-0.31c0.09-0.29,0.21-0.57,0.37-0.84
      c0.21-0.35-0.39-0.66-0.6-0.31c-0.2,0.32-0.34,0.67-0.44,1.01c-0.37-0.07-0.74-0.15-1.11-0.22c0.09-0.42,0.27-0.82,0.57-1.16
      c0.27-0.32-0.34-0.62-0.61-0.31c-0.34,0.39-0.54,0.86-0.65,1.35c-0.71-0.11-1.4-0.21-2.04-0.27c0.19-0.45,0.46-0.88,0.67-1.16
      c0.25-0.33-0.35-0.64-0.6-0.31c-0.28,0.37-0.58,0.88-0.78,1.41c-0.43-0.04-0.83-0.06-1.2-0.06c-0.86-1.71-0.18-5.24,0.89-6.68
      c2.75,0.98,7.33,1.52,8.97,1.27c4.44,2.2,13.7,4.07,16.6,4.5c-0.48,5.68-1.19,17.46-1.45,22.63c-0.35,7.08-0.35,13.68-0.1,19.67
      C95.04,607.07,75.06,605.12,68.03,600.22z"/>
    <path fill="#3A8AB4" d="M67.83,601.64c7.04,3.75,23.26,6.11,33.1,6.61
      c0.18,2.98,0.45,7.05,1.2,11.18c-0.82,0.11-1.64,0.2-2.47,0.29c0.04-0.26,0.12-0.53,0.23-0.75c0.17-0.37-0.51-0.52-0.68-0.16
      c-0.15,0.31-0.23,0.65-0.26,0.99c-0.56,0.06-1.11,0.1-1.67,0.14c-0.01-0.22,0-0.44,0.03-0.67c0.06-0.39-0.61-0.55-0.67-0.16
      c-0.05,0.3-0.06,0.59-0.04,0.88c-0.65,0.04-1.3,0.08-1.95,0.12c0.12-0.33,0.29-0.65,0.47-0.87c0.26-0.33-0.35-0.63-0.6-0.31
      c-0.26,0.33-0.48,0.76-0.6,1.21c-0.79,0.04-1.57,0.07-2.36,0.09c0.02-0.29,0.1-0.58,0.24-0.84c0.21-0.35-0.39-0.66-0.6-0.31
      c-0.21,0.36-0.31,0.75-0.33,1.16c-0.72,0.02-1.44,0.02-2.16,0.02c0.04-0.33,0.17-0.66,0.4-0.99c0.24-0.34-0.36-0.64-0.6-0.31
      c-0.31,0.43-0.46,0.86-0.5,1.29c-0.58-0.01-1.16-0.01-1.73-0.02c0.11-0.36,0.26-0.71,0.37-1.01c0.13-0.38-0.55-0.54-0.67-0.16
      c-0.13,0.37-0.28,0.76-0.38,1.15c-1.39-0.05-2.75-0.12-4.07-0.21c0.09-0.29,0.22-0.59,0.36-0.86c0.19-0.36-0.42-0.67-0.6-0.31
      c-0.19,0.36-0.35,0.73-0.45,1.11c-1.42-0.11-2.79-0.26-4.1-0.43c0.13-0.29,0.29-0.57,0.42-0.77c0.22-0.35-0.38-0.66-0.6-0.31
      c-0.18,0.29-0.37,0.63-0.52,0.99c-0.99-0.15-1.96-0.3-2.87-0.48c0.07-0.26,0.25-0.48,0.62-0.64c0.4-0.17,0.05-0.7-0.35-0.53
      c-0.58,0.25-0.86,0.62-0.95,1.04c-1.12-0.23-2.18-0.49-3.16-0.77c0.16-0.26,0.39-0.48,0.72-0.56c0.44-0.1,0.25-0.7-0.18-0.59
      c-0.58,0.14-0.97,0.5-1.21,0.95c-0.92-0.29-1.77-0.59-2.53-0.92c0.16-0.34,0.38-0.65,0.6-0.87c0.29-0.3-0.2-0.74-0.49-0.44
      c-0.27,0.27-0.52,0.63-0.72,1.03c-1.22-0.59-2.19-1.24-2.83-1.95C64.51,610.92,66.78,605.32,67.83,601.64z"/>
  </g>
  <g id="item_7_">
    <path d="M262.86,481.35c3.18-0.67,6.4-3.4,6.97-4.52c2.62,0.01,16.23,0.49,20.19,0.72c0.52-8.9-0.36-17.93-0.08-26.88
      c0.31-9.87,0.9-18.45,4.71-28.11c0.57-1.46,1.64-1.95,2.38-2.07c1.4-3.07,5.08-6.9,6.64-8.13c-1.53-1.49-2.9-2.95-4.07-4.26
      c-3.55-3.94-6.14-8.34-8.48-12.82c-1.65-3.15-3.19-6.74-3.91-11.11c-3.64,1.96-9.42-2.1-7.61-6.66c-2.48-1.49-5.99-4.32-3.75-15.33
      c1.49-7.35,6.36-13.34,12.58-18.59c8.92-7.53,28.94-16.22,38.5-5.6c10.54,1.11,19,6.37,23.47,15.06
      c2.53,4.92,6.51,18.25-0.89,21.88c1.17,2.17,1.28,3,1.29,4.28c0.54-0.49,1.33-0.77,2.26-0.69c2.03,0.17,3.39,1.86,3.6,3.34
      c0.22,1.58-0.36,4.09-1.76,5.31c-1.17,1.03-2.55,1.06-3.71,0.16c0.31,8.02-4.35,16.27-8.94,23.06c-7.57,11.21-13.07,13.94-15.89,14
      c-3.45,0.07-11.82-3.1-16.95-7.02c-0.68,0.24-2.25,1.04-2.87,1.59c-0.12,1.55-1.79,2.09-2.64,1.86c-0.37,0.59-0.65,1.32-0.75,1.87
      c2.54,2.44,4.41,5.48,6.3,9.81c1.88,4.33,15,36.09,16.68,46.49c5.07,0.11,9.88,0.4,14.77,0.61c0.4,1.69,0.23,7.81-0.04,10.38
      c1.53,0.08,5.04,0.1,5.07,0.28c1.22,8.36,2.27,15.59,2.04,24.01c-0.13,4.83-0.09,12.62-2.7,14.01c-1.19,0.63-3.05,0.68-4.57,0.8
      c1.78,3.2,3,7.52,2.65,8.82c-2.1-0.21-8.19,0.45-10.3,0.81c0.25,5.4-0.59,16.66-1.05,21.32c-0.41,4.13-0.82,7.8-1.05,11.93
      c-0.31,5.57-2.15,12.66-6.69,17.22c0.8,6.9-0.79,14.93-2.9,21.58c-1.59,5.01-2.78,9.82-2.43,11.44c0.97,0.67,1.66,1.92,1.58,2.51
      c-0.08,0.58-0.73,1.03-1.02,1.12c1.88,0.22,3.8,1.4,5.16,2.24c2.56,1.59,4.65,3.49,6.65,5.5c7.27,0.92,17.54,5.05,21.52,7.35
      c0.62,0.36,0.24,1.09-0.33,1.29c-2.36,0.82-5.8,1.34-9.56,1.35c1.69,0.95,2.52,1.61,2.3,2.46c-0.76,2.82-17.01,0.35-25.12-1.15
      c-7.22-1.33-13.1-2.97-19.76-5.13c-0.1,0.79-0.49,1.58-0.75,1.88c-3.2-0.28-8.96-1.22-10.82-1.77c0.8-3.35,1.29-9.07,5.3-10.96
      c-0.47-0.06-1.57-0.26-2.02-0.36c-0.53-0.12,2.11-10.16,4.69-12.84c3.76-6.94,6.55-17.99,7.95-22.38c1.3-4.07,3.18-9.58,6.85-13.22
      c1.33-6.01,1.65-11.98,1.14-18.24c-0.34-4.22-1.81-13.96-2.51-18.62c-5.19,0.86-19.32,0.39-22.72-0.82
      c1.32-2.73,1.37-6.52,1.22-9.51c-3.51,0.37-18.17,0.35-20.1,0.22c-0.28-1.17-0.41-2.33-0.36-5.65c-3.25-0.25-6.79-1.45-8.5-2.2
      C259.09,517.88,261.96,484.72,262.86,481.35z"/>
    <path fill="#F5F5EB" d="M270.5,477.98c-0.47,0.91-2.51,2.79-4.05,3.5c5.13,0.5,19.59,1.26,26.85,1.45
      c-0.06-0.55-0.12-1.07-0.18-1.58c-3.53,0.03-18.35-0.43-22.22-0.74c-0.2-0.02-0.19-0.29,0.01-0.29c7.76,0.08,15.3,0.35,22.12,0.23
      c-0.04-0.32-0.08-0.63-0.12-0.96c-0.61,0.12-4.94-0.17-6.47-0.09c-0.12,0.01-0.14-0.16-0.02-0.17c1.01-0.08,5.32-0.31,6.42-0.27
      c-0.03-0.2-0.05-0.4-0.08-0.61C287.08,478.28,274.8,478.16,270.5,477.98z"/>
    <path fill="#6E5252" d="M348.65,353.52c-3.29-7.16-12.01-13.3-22.59-14.74c-6.18-5.88-14.39-4.33-19.13-2.92
      c-7.76,2.31-14.57,6.89-19.85,11.78c-4.94,4.58-8.52,10.55-9.71,16.56c-0.49,2.45-0.58,6.1,0.34,6.98
      c-0.1-7.06,5.59-14.42,10.35-19.74c0.14-0.15,0.44,0,0.3,0.16c-2.6,3.18-5.24,6.4-7.04,9.96c-2.58,5.11-3.17,9.95-1.6,11.34
      c0.23-2.9,0.69-5.89,1.87-8.46c2.03-4.42,6.15-8.72,9.99-12.18c0.15-0.14,0.44,0.08,0.3,0.21c-6.97,6.87-10.6,13.63-9.74,15.69
      c0.82-1.6,1.87-3.18,3.32-4.91c4.07-4.84,8.56-9.35,13.51-13.61c0.19-0.16,0.53,0.11,0.35,0.27c-4.74,4.35-9.05,8.97-12.79,13.93
      c-2.59,3.45-4.12,6.09-3.85,7.34c1.31-1.73,11.36-10.99,14.32-14.11c0.14-0.15,0.44,0.01,0.31,0.16
      c-2.61,2.89-11.62,12.12-13.19,14.02c4.48-1.11,11.73-6.42,16.02-9.68c4.29-3.26,15.98-11.26,22.87-22.04
      c0.2-0.31,0.7,0.01,0.66,0.29c-0.08,0.55-0.23,1.11-0.4,1.67c6.8,1.5,11.74,5.82,15.42,10.43c4.11,5.15,7.01,11.81,6.54,17.71
      c0.28,0.27,0.69,0.62,0.97,0.89c1.27-1.77,1.44-5.13,0.6-8.05c-0.05-0.17,0.26-0.24,0.34-0.06c1.42,3.25,0.95,6.48-0.25,8.44
      c1.54-0.46,2.3-3.05,1.87-6.74c-0.26-2.21-1.7-4.85-2.49-6.96c-0.07-0.18,0.25-0.23,0.35-0.06c2.74,4.42,4.62,11.13,0.5,14.88
      c0.63,0.59,1.19,1.23,1.69,1.89C351.88,371.72,353.98,365.11,348.65,353.52z"/>
    <path fill="#FFCFAB" d="M321.24,378.43c0.32-0.12,1.03-0.09,1.36,0.13c1.1-7.26,8.26-11.49,15.78-10.46
      c-1.01-1.37-3.73-3.33-7.95-4.72c-0.68-0.22-0.45-1.23,0.34-1.18c6.15,0.37,9.74,3.26,13.4,6.6c-0.34-4.12-1.77-8.02-5.95-14.22
      c-2.6-3.86-6.97-7.7-11.35-9.9c-4.18,7.06-11.71,12.65-20.22,16.44c2.56-0.7,5.31-1.32,7.84-1c0.55,0.07,0.61,0.65,0.13,0.84
      c-2.85,1.11-5.26,2.8-8.1,3.98c1.54,0.08,3.13,0.37,4.75,0.91C317,367.74,321.01,373.31,321.24,378.43z"/>
    <path fill="#8C7072" d="M320.8,382.25c0.53-0.25,1.61-0.33,2.07-0.01c-0.26-0.74-0.36-2.3-0.34-2.88
      c-0.44-0.2-1.1-0.22-1.31-0.04C321.16,379.89,320.83,382.03,320.8,382.25z"/>
    <path fill="#8C7072" d="M324.1,381.82c0.87,4.44,5.62,8.78,12.98,8.59c9.3-0.24,15.16-9.53,10.46-16.18
      c-3.41-4.81-11.31-6.61-17.11-4.06C325.09,372.51,323.23,377.38,324.1,381.82z"/>
    <path fill="#6E5252" d="M315.15,354.81c-1.69,1.24-3.58,2.31-5.51,3.33c0.72-0.26,1.41-0.53,2.04-0.82
      c6.4-2.95,11.26-7.7,14.83-12.88c-0.54-0.31-1.04-0.65-1.6-0.94C323.9,347.67,318.61,352.26,315.15,354.81z"/>
    <path fill="#6E5252" d="M323.99,343.08c-0.36-0.17-0.71-0.36-1.08-0.52c-3.42,5.84-11.15,12.48-16.31,16.01
      C315.45,355.03,322.62,347.41,323.99,343.08z"/>
    <path fill="#5D423D" d="M347.98,367.01c0.26,0.2,0.52,0.42,0.77,0.63c-0.25,1.75-0.91,2.91-1.89,3.2
      C347.48,369.84,347.9,368.51,347.98,367.01z"/>
    <path fill="#5D423D" d="M351.24,370.41c-0.58,1.61-1.46,2.76-2.45,3.44c-0.5-0.66-1.06-1.3-1.69-1.89
      c1.18-1.08,1.86-2.4,2.17-3.84C350.02,368.83,350.68,369.6,351.24,370.41z"/>
    <path fill="#5D423D" d="M344.98,365.11c0.81,0.41,1.59,0.87,2.31,1.38c-0.05,1.6-0.41,3.06-1.1,4.03
      c-0.28-0.27-0.68-0.62-0.97-0.89C345.34,368.15,345.24,366.64,344.98,365.11z"/>
    <path fill="#5D423D" d="M280.62,363.08c0.14-0.05,0.26-0.14,0.33-0.29c1.82-3.83,4.55-6.39,8.38-8.43
      c-3.11,3.03-6.1,6.5-7.73,10.06c-1.18,2.57-1.64,5.56-1.87,8.46C278.32,371.64,278.66,367.59,280.62,363.08z"/>
    <path fill="#5D423D" d="M314.74,340.99c-3.71,4.77-8.36,8.94-14.3,11.88C303.94,348.06,309.12,344.22,314.74,340.99z"/>
    <path fill="#5D423D" d="M345.23,353.97c-4.35-3.03-7.4-7.05-11.27-10.44C339.36,345.46,343.17,349.23,345.23,353.97z"/>
    <path fill="#5D423D" d="M313.64,340.41c-4.71,2.74-9.07,5.92-12.42,9.72C302.56,345.01,307.87,342.12,313.64,340.41z"/>
    <path fill="#5D423D" d="M288.05,361.9c2.27-1.65,4.95-2.92,7.52-3.38c-3.93,3.89-11.74,11.14-12.89,12.66
      c-0.27-1.25,1.25-3.89,3.85-7.34C287.02,363.18,287.54,362.54,288.05,361.9z"/>
    <path fill="#5D423D" d="M293.65,354.51c-2.9,2.79-5.63,5.68-8.18,8.72c-1.45,1.72-2.5,3.31-3.32,4.91
      c-0.44-1.04,0.3-3.31,2.02-6.18C286.46,358.85,289.77,356.29,293.65,354.51z"/>
    <path fill="#5D423D" d="M280.2,356.53c1.9-1.56,4.09-2.76,6.3-3.31c-4.35,5.12-8.87,11.66-8.78,17.95
      c-0.92-0.88-0.83-4.53-0.34-6.98C277.88,361.59,278.87,359.01,280.2,356.53z"/>
    <path fill="#5D423D" d="M322.43,349.32c-0.55,0.21-1.11,0.42-1.66,0.62c1.96-2.03,3.62-4.28,4.14-6.42
      c0.56,0.29,1.06,0.63,1.6,0.94c-1.14,1.65-2.44,3.25-3.85,4.77C322.58,349.25,322.51,349.29,322.43,349.32z"/>
    <path fill="#5D423D" d="M316.76,351.43c-0.66,0.21-1.33,0.4-2,0.58c3.25-2.98,6.31-6.33,8.15-9.46
      c0.37,0.16,0.72,0.35,1.08,0.52c-0.66,2.09-2.68,4.93-5.58,7.72C317.85,351,317.3,351.22,316.76,351.43z"/>
    <path fill="#8C7072" d="M314.39,368.45c-7.59-4.79-15.19-2.42-19.05,3.71c-2.99,4.74-3.14,10.64,0.73,15.08
      c4.59,5.28,13.37,5.55,18.19,2.43c4.29-2.78,6.31-8.26,5.82-11.94C319.58,374.05,317.68,370.53,314.39,368.45z"/>
    <path fill="#FFCFAB" d="M297.51,367.42c-2.46,1.12-2.94,0.34-3.16,0.17c-2.17,1.81-4.93,3.38-7.91,4.22
      c2.31,0.75,4.76,1.35,6.09,2.2C293.69,370.94,295.97,368.52,297.51,367.42z"/>
    <path fill="#8C7072" d="M283.09,372.92c-1.77,0.52-3.04,2.64-1.85,3.19c3.44-2.65,9.15-0.38,10.39,0.77
      c0.15-0.7,0.18-1.26,0.41-1.94C288.73,373.19,285.32,372.27,283.09,372.92z"/>
    <path fill="#7D6663" d="M320.8,382.25c0.53-0.25,1.61-0.33,2.07-0.01c-0.26-0.74-0.36-2.3-0.34-2.88
      c-0.44-0.2-1.1-0.22-1.31-0.04C321.16,379.89,320.83,382.03,320.8,382.25z"/>
    <path fill="#7D6663" d="M344.11,388.2c-1.94,1.31-4.34,2.14-7.03,2.21c-3.43,0.09-6.28-0.82-8.44-2.25
      C333.49,389.66,339.34,389.69,344.11,388.2z"/>
    <path fill="#7D6663" d="M301.35,366.91c1.25-0.53,2.58-0.86,3.97-0.96c-0.99,0.13-2.02,0.34-3.1,0.65
      C301.91,366.69,301.65,366.81,301.35,366.91z"/>
    <path fill="#7D6663" d="M315.96,388.32c-0.52,0.5-1.09,0.96-1.71,1.36c-4.25,2.75-11.56,2.86-16.38-0.76
      C303.61,390.57,310.14,390.26,315.96,388.32z"/>
    <path fill="#7D6663" d="M283.79,375.03c-0.91,0.17-1.79,0.5-2.55,1.08c-1.2-0.55,0.08-2.68,1.85-3.19
      c0.72-0.21,1.57-0.24,2.49-0.14C285.01,373.56,284.42,374.3,283.79,375.03z"/>
    <path fill="#7D6663" d="M289.37,373.73c0.89,0.34,1.78,0.74,2.68,1.21c-0.23,0.67-0.26,1.24-0.41,1.94
      c-0.5-0.46-1.72-1.1-3.23-1.53C288.74,374.82,289.05,374.28,289.37,373.73z"/>
    <path fill="#FFCFAB" d="M326.38,422.89c1.76,0.13,5.35-2.44,7.88-5.08c2.52-2.65,6.97-9.19,9.14-12.86
      c3.64-6.16,5.61-12.01,6.59-19.36c0.03-0.25,0.84-0.35,0.91-0.14c0.28,0.94,1.58,1.26,2.63,0.81c1.95-0.82,2.78-5.91-0.42-6.59
      c-1.21-0.25-1.94,0.51-2.5,1.24c-1.14,6.32-5.84,9.7-11.61,10.51c-7.09,0.99-14-2.86-15.89-8.23c-0.72-0.36-1.85-0.32-2.64,0.23
      c-3.15,11.31-16.91,11.27-23.42,6.71c-4.53-3.16-5.75-7.55-5.4-11.83c-1.3-1.14-5.48-3.19-7.96-2c-2.48,1.18-3.47,3.07-1.24,5.51
      c1.03,1.12,4.93,1.92,5.84-1.03c0.48-1.54-2.76-1.82-3.98-1.41c-0.16,0.05-0.29-0.21-0.17-0.31c1.11-0.98,2.52-1.14,4.02-0.61
      c1.56,0.56,2.35,2.87,0.58,4.36c1.98,7.73,8.08,18.73,10.6,21.98C305.58,412.92,318.24,422.27,326.38,422.89z"/>
    <path fill="#FFCFAB" d="M305,419.57c0.88-0.49,0.27-1.86-0.26-2.61c-0.12-0.17,0.14-0.31,0.34-0.23
      c0.67,0.3,1.11,0.82,1.32,1.4c0.2-0.44,1.79-1.35,2.09-1.48c-1.45-1.16-2.79-2.37-4.06-3.58c-2.34,2.06-4.9,5.53-5.65,7.33
      c1.12,0.07,2.3,0.64,3.34,1.41c0.26-0.52,0.61-0.96,0.91-1.39c-0.32-0.23-0.57-0.52-0.67-0.86c-0.06-0.18,0.18-0.49,0.35-0.34
      C303.24,419.69,304.24,420.01,305,419.57z"/>
    <path fill="#E6AD8D" d="M303.2,414.27c0.41-0.42,0.82-0.84,1.23-1.2c1.27,1.21,2.61,2.42,4.06,3.58
      c-0.19,0.08-0.87,0.47-1.4,0.85C306.04,416.26,304.7,415.18,303.2,414.27z"/>
    <path fill="#80A8DA" d="M308.75,434.97c-1.03-2.58-4.11-8.72-6.6-11.12c-1.81-1.75-4.61-3.01-5.71-1.53
      c-2.8,3.76-5.28,18.67-5.18,28.33c0.1,9.66,0.34,22.39-0.05,26.94c0.33,0.03,1.17,0.12,1.5,0.08c-1.51-12.71,0.88-24.95,3.55-37.36
      c0.03-0.16,0.34-0.1,0.34,0.05c-0.06,5.27-1.46,10.69-1.99,15.97c-0.71,7.18-1.03,14.27-0.31,21.47c0.64,6.43,1.3,12.87,1.97,19.3
      c0.7,6.71,1.59,19.72,1.54,19.81c2.61,1.56,6.15,1.33,7.94,0.36c-2.61-8.61-13.06-50.7-5.67-74.8c0.06-0.21,0.47-0.13,0.44,0.08
      c-1.42,11.79-1.86,23.34-0.72,35.1c6.28,0.33,21.58,0.91,24.74,0.98C322.88,469.77,310.68,439.79,308.75,434.97z"/>
    <path fill="#F5F5EB" d="M287.47,493c1.45,0.02,5.98-0.25,6.79-0.22c-0.01-0.12-0.02-0.25-0.04-0.38
      c-3.2-0.22-6.43-0.18-9.63-0.31c-0.22-0.01-0.2-0.28,0.01-0.28c3.18,0.01,6.37-0.02,9.55-0.11c-0.05-0.47-0.23-0.67-0.28-1.14
      c-4.18,0.06-11.85-0.02-15.58-0.29c-0.14,0.89-1.91,2.61-3.46,3.65c1.7-0.3,14.84,0.94,19.53,0.72c0.01-0.25,0.02-0.83-0.06-1.36
      c-0.7,0.04-5.28,0.02-6.86-0.02C287.24,493.27,287.26,493,287.47,493z"/>
    <path fill="#FFCFAB" d="M297.08,533.93c0.92,3.26,4.14,0.92,5.06-0.1c0.12-0.14,0.63-0.37,1.03,0.13
      c1.17,1.51,3.19,3.4,4.55,1.44c0.95-1.39,1.77-1.26,2.57-0.85c3.41,1.75,4.02-1.81,4.36-3.05c0.47-1.7,3.21,1.84,4.93,2.54
      c1.43,0.58,4.05,0.42,5.37-0.28c2.77-1.47,0.21-2.92-1.22-2.71c-1.44,0.22-5.59-0.38-6.28-1.23c-0.69-0.84-7.62-8.07-12.23-10.84
      c-1.71,0.9-3.7,1.07-6.22,0.41c-6.16,1.37-8.46,11.69-7.92,13.45c0.56,1.83,2.46,2.97,4.25,0.94
      C295.76,533.28,296.9,533.27,297.08,533.93z"/>
    <path fill="#70A65B" d="M333.11,485.51c-10.82,0.38-22.01-0.26-32.45-1.04c0.25,1.66,0.59,3.63,0.88,5.29
      c7.32,0.22,26.47,0.55,31.59,0.2C332.96,488.62,333.05,487,333.11,485.51z"/>
    <path fill="#F5F5EB" d="M299.94,478.68c0.03,0.32,0.09,0.87,0.17,1.48c3.84,0.28,7.1,0.7,10.55,0.91
      c0.15,0.01,0.17,0.21,0.01,0.21c-3.42,0.11-7.08-0.18-10.5-0.58c0.06,0.45,0.11,0.9,0.16,1.3c1.27,0.21,8.38,0.83,10.67,0.91
      c0.16,0,0.14,0.19-0.02,0.19c-2.28,0.01-9.35-0.46-10.6-0.64c0.04,0.35,0.08,0.62,0.1,0.74c7.76,0.67,23.63,1.48,32.73,1.28
      c0.5-0.07,1.45-0.39,2.01-0.65c-3.36,0-6.41-0.23-10.4-0.44c-0.19-0.01-0.2-0.28,0-0.28c1.95,0.03,9.45,0.15,11.25,0.19
      c0.29-0.18,0.58-0.37,0.87-0.57c-1.18,0.16-9.26-0.21-12.07-0.63c-0.14-0.02-0.14-0.19,0.01-0.18c4.28,0.13,8.68-0.13,12.94,0.16
      c0.73-0.57,1.4-1.17,1.96-1.74C332.94,480.13,306.33,479.28,299.94,478.68z"/>
    <path fill="#EBE3D9" d="M340.08,481.17c-1.36,1.89-3.78,3.61-5.61,4.07c-0.2,1.31-0.39,3.25-0.34,4.89
      c1.91-0.07,3.67,0.01,5.71-0.11C339.8,488.72,340.17,483.21,340.08,481.17z"/>
    <path fill="#F5F5EB" d="M301.72,490.89c0.07,0.38,0.14,0.76,0.2,1.14c5.45,0.19,9.02,0.28,13.6,0.39
      c1.84,0.04,3.31,0.68,2.43,0.64c-5.03-0.22-11.31-0.1-15.86-0.1c0.03,0.19,0.07,0.38,0.1,0.57c7.68,0.25,16.9,0.64,24.8,0.24
      c1.12-0.06-0.21,0.49-1.34,0.55c-7.89,0.4-15.99-0.05-23.34-0.1c0.03,0.14,0.05,0.29,0.08,0.43c11.91,0.93,27.4,1.08,37.61,1.06
      c2.2-0.59,4.05-3.28,4.72-4.84C332.88,491.26,316.87,491.35,301.72,490.89z"/>
    <path fill="#DBDBC1" d="M288.66,478.36c1.56,0.03,2.97,0.07,4.11,0.11c0.02,0.21,0.05,0.41,0.08,0.61
      c-0.69-0.02-2.63,0.06-4.24,0.14C288.62,478.93,288.64,478.65,288.66,478.36z"/>
    <path fill="#DBDBC1" d="M288.56,481.29c2.01,0.04,3.66,0.07,4.56,0.06c0.06,0.51,0.12,1.03,0.18,1.58
      c-1.36-0.04-2.98-0.09-4.73-0.16C288.57,482.28,288.56,481.79,288.56,481.29z"/>
    <path fill="#DBDBC1" d="M288.59,479.53c1.8,0.05,3.91,0.15,4.32,0.07c0.05,0.32,0.08,0.64,0.12,0.96
      c-1.46,0.02-2.96,0.02-4.47,0.02C288.57,480.23,288.57,479.88,288.59,479.53z"/>
    <path fill="#DBDBC1" d="M289.65,490.59c1.57,0.01,3.04,0.01,4.22-0.01c0.04,0.47,0.23,0.67,0.28,1.14
      c-1.5,0.04-3,0.06-4.5,0.07C289.66,491.38,289.66,490.99,289.65,490.59z"/>
    <path fill="#DBDBC1" d="M289.64,492.22c1.53,0.03,3.06,0.08,4.58,0.18c0.01,0.12,0.03,0.25,0.04,0.38
      c-0.55-0.02-2.81,0.1-4.66,0.17C289.62,492.71,289.63,492.47,289.64,492.22z"/>
    <path fill="#DBDBC1" d="M289.58,493.3c1.92,0.01,4.25,0.01,4.73-0.01c0.08,0.53,0.07,1.11,0.06,1.36
      c-1.19,0.05-2.93,0.02-4.88-0.07C289.53,494.16,289.56,493.73,289.58,493.3z"/>
    <path fill="#EBE3D9" d="M342.53,526.36c-0.27-7.95-0.16-14.85,0.57-23.04c0.09-1.07,0.39-1.68,0.35-0.75
      c-0.46,9.5-0.65,21.09,0.1,24.13c1.54-0.13,1.86-15.4,0.91-22.49c-0.1-0.81,0.33,0.06,0.39,0.71c0.84,9.37,0.7,17.09,0.2,20.89
      c1.19-0.4,1.67-3.13,1.87-5.92c0.21-2.79,0.24-7.56,0.1-10.69c-0.28-6.4-1.05-11.79-1.74-17.98c-0.52,1.71-2.07,4.46-4.03,5.27
      C341.22,499.6,339.9,526.23,342.53,526.36z"/>
    <path fill="#9C2114" d="M341.89,528.05c-3.68-2.61-1.88-22.43-1.97-31.26c-1.92,0.22-27.56-0.34-37.22-0.74
      c0.83,6.37,4.41,20.48,4.89,21.33c-0.42,0.57-1.04,0.88-1.52,0.98c5.49,2.94,11.18,8.54,12.7,10.83
      C323.05,529.71,337.35,528.39,341.89,528.05z"/>
    <path fill="#4569BA" d="M317.43,622.92c-1.11-0.19-3.3,0.14-4.97,0.88c-0.33,0.15,0.65-1.85,4.73-1.84
      c-0.13-2.5,0.47-4.89,1.41-7.72c1.28-3.88,2.26-7.55,2.8-11.54c0.55-4.1,1.18-8.24,1.83-12.59c-0.61,0.75-1.13,0.39-1,0.19
      c3.45-5.44,5.03-7.56,6.19-14.02c0.74-4.04,1.6-12.59,1.95-16.67c0.43-4.93,1.28-16.29,1-20.49c-3.04,0.72-4.97,1.3-7.99,2.07
      c1.86,9.55,0.57,19.34,1.17,28.75c0.24,3.73,0.32,7.45-1.17,11.02c-0.57,1.35-1.75,2.43-2.95,3.42c-2.65,2.19-2.88,4.04-3.86,7.24
      c-0.98,3.2-3.94,14.71-6.07,22.04c-0.72,2.47-1.88,6.83-2.03,7.47c0.9,0.88,1.06,2.02-0.27,3.7c0.42,0.3,0.97,0.87,1.25,1.32
      c0.01,0,8.54-1.3,9.14-1.45C319.17,624.57,318.55,623.11,317.43,622.92z"/>
    <path fill="#918891" d="M350.25,641.41c-0.94-0.96-6.54-3.11-10.02-4.37c-2.74-1-8.62-2.24-8.63-2.25
      c-0.85-0.19-1.7-0.39-2.55-0.6c-0.21-0.05-0.23-0.35-0.01-0.4c0.27-0.06,0.55-0.08,0.82-0.12c-3.21-3.16-7.48-6.38-11.79-7.34
      c-2.57,0.13-6.35,0.65-8.4,0.98c0.07,0.47-0.36,0.96-2.1,1.54c2.84,0.44,5.69,1.86,8.07,2.93c2.77,1.24,5.69,2.64,7.71,4.67
      c3.73,0.46,7.32,1.78,10.74,3.18c0.99,0.41,3.64,1.36,6.11,2.5C343.39,642.35,348.8,642.04,350.25,641.41z"/>
    <path fill="#6E637A" d="M309.67,627.3c2.05-0.32,5.83-0.85,8.4-0.98c0.5,0.11,1.01,0.26,1.51,0.43
      c-4.25,2.67,4.16,8.03,7.39,9.28c6.42,2.48,13.34,3.42,20.33,3.78c1.44,0.64,2.58,1.22,2.96,1.61c-1.45,0.64-6.86,0.94-10.07,0.72
      c-2.47-1.14-5.12-2.09-6.11-2.5c-3.42-1.4-7-2.72-10.74-3.18c-2.02-2.03-4.94-3.43-7.71-4.67c-2.37-1.07-5.22-2.49-8.07-2.93
      C309.31,628.26,309.74,627.77,309.67,627.3z"/>
    <path fill="#9C949C" d="M291.08,638.44c2.26,0.73,4.71,1.29,7.06,1.35c0.33-0.85,0.69-2.6,0.64-4.05
      c-0.01-0.22,0.41-0.25,0.51-0.05c0.31,0.58,0.52,1.18,0.67,1.78c6.07,2.62,18.59,5.53,23.62,6.46c5.04,0.93,21,2.64,19.22,1.27
      c-2.3-1.76-15.98-7.02-25.09-8.12c-0.34-0.04-0.35-0.74,2.83-0.79c-3.11-2.37-9.33-5.03-15.45-7.01c-3.12,0.35-6.39,0.15-9.53-0.19
      C292.57,631.5,291.21,635.69,291.08,638.44z"/>
    <path fill="#898089" d="M293.79,630.96c0.52-0.69,1.1-1.34,1.78-1.88c3.14,0.34,6.41,0.54,9.53,0.19
      c1.66,0.54,3.34,1.13,4.95,1.75C304.56,632,299.16,632.1,293.79,630.96z"/>
    <path fill="#898089" d="M298.51,638.44c-0.11,0.54-0.24,1.01-0.37,1.35c-2.35-0.06-4.8-0.62-7.06-1.35
      c0.02-0.49,0.1-1.04,0.21-1.6C293.63,637.56,296.04,638.05,298.51,638.44z"/>
    <path fill="#898089" d="M336.43,645.47c-4.53-0.36-10.2-1.06-12.85-1.55c-5.04-0.92-17.55-3.84-23.62-6.46
      c-0.11-0.43-0.26-0.85-0.45-1.28C311.44,640.17,323.7,644.2,336.43,645.47z"/>
    <path fill="#4569BA" d="M315.05,561.7c0.45,5.17,0.83,11.2-0.96,16.4c1.45-1.09,2.52-0.46,2.22-0.16
      c-2.58,2.52-4.4,4.14-5.94,7.27c-1.54,3.13-3.06,7.34-4.11,10.84c-1.86,6.15-3.81,13.46-7.53,19.12c3.82,0.14,4.31,1.39,3.89,1.33
      c-6.79-1.1-6.93,6.48-8.14,10.68c4.14,0.7,12.36,0.75,13.16-0.04c0.8-0.79-1.59-2.56-5.19-1.94c-0.25,0.04-0.42-0.27-0.2-0.38
      c1.35-0.71,3.32-0.83,4.53-0.65c0.99-0.26,1.36-1.56,0.68-1.88c-0.68-0.32-3.65-0.44-6.27-0.16c-0.36,0.04,0.14-0.88,1.86-1.1
      c1.72-0.22,3.18-0.57,3.32-0.94c1.2-3.15,2.1-7.19,3.03-10.4c1.83-6.36,4.46-15.04,5.42-18.56c1.03-3.74,1.36-6.04,4.31-8.12
      c2.96-2.07,3.76-7,3.54-11.09c-0.52-9.62,0.74-20.6-0.6-30.42c-0.51,0.13-6.91,1.41-9.94,1.7
      C313.2,547.51,314.75,558.24,315.05,561.7z"/>
    <path fill="#2C55A6" d="M308.45,621.15c0.15-0.64,1.31-5,2.03-7.47c2.13-7.33,5.09-18.84,6.07-22.04
      c0.98-3.2,1.22-5.05,3.86-7.24c1.21-0.99,2.39-2.07,2.95-3.42c1.49-3.57,1.41-7.29,1.17-11.02c-0.27-4.14-0.16-8.36-0.12-12.6
      c0.02-0.05,0.04-0.1,0.06-0.15c1.48,7.71,3.65,16.43,0.82,23.99c-2.54,6.77-7.67,12.75-9.43,19.75c-1.6,6.36-5.51,13.81-2.92,20.32
      c0.19,0.49,0.44,0.95,0.75,1.38c-1.15,0.56-1.44,1.25-1.24,1.16c0.53-0.24,1.1-0.42,1.68-0.57c0.57,0.69,1.25,1.31,2.01,1.89
      c-2.71,0.43-6.72,1.05-6.73,1.05c-0.28-0.45-0.83-1.02-1.25-1.32C309.51,623.16,309.35,622.03,308.45,621.15z"/>
    <path fill="#2C55A6" d="M324.41,552.33c-0.07-3.72-0.31-7.45-1.04-11.13c3.03-0.77,4.95-1.35,7.99-2.07
      c0.08,1.29,0.06,3.26-0.02,5.5C328.48,546.36,326.16,549.22,324.41,552.33z"/>
    <path fill="#2C55A6" d="M312.97,547.26c-0.29-1.6-0.58-3.02-0.84-4.07c3.03-0.29,9.43-1.57,9.94-1.7
      c0.11,0.83,0.18,1.68,0.26,2.53C319.32,544.17,315.92,545.48,312.97,547.26z"/>
    <path fill="#2C55A6" d="M298.73,615.17c0.74-1.12,1.39-2.33,2-3.56c0.43,1.28,0.64,2.61,0.7,3.95
      C300.81,615.37,299.94,615.21,298.73,615.17z"/>
    <path fill="#2C55A6" d="M314.7,558.53c2.97,5.96,3.05,12.77,0.91,18.96c-0.4,0.01-0.92,0.16-1.52,0.61
      c1.79-5.19,1.41-11.23,0.96-16.4C314.99,560.94,314.86,559.84,314.7,558.53z"/>
    <path fill="#2C55A6" d="M303.35,620.97c0.47-1.59,1.84-2.97,3.81-3.23c-0.25,0.81-0.52,1.6-0.8,2.33
      C306.24,620.43,304.94,620.75,303.35,620.97z"/>
    <path fill="#80A8DA" d="M289.9,541.01c10.65,1.46,25.08,0.1,32.33-1.34c7.24-1.45,14.64-4.28,19.97-3.21
      c-0.23-2.06-2.25-6.04-2.8-7.23c-2.55,0.38-11.75,1.16-13.53,1.3c1.21,0.36,2.9,2.06,1.33,3.52c-1.38,1.29-4.77,2.27-6.74,1.83
      c-2.46-0.55-3.15-1.57-5.15-2.86c-0.84,1.6-1.74,3.68-3.96,3.06c-1.11-0.31-2.17-0.61-2.85,0.27c-2.01,2.6-4.49-0.03-4.84-0.4
      c-1.04-1.09-1.63-0.32-2.4,0.29c-1.98,1.61-4.78-0.11-5.17-0.79c-0.39-0.67-1.98,0.92-3.15,0.67c-1.76-0.37-2.45-1.94-2.86-3.37
      c-0.08,0.01,0.2,3.04,0.2,4.5C290.29,538.48,290.28,539.79,289.9,541.01z"/>
    <path fill="#6595D2" d="M305.14,477.9c-2.06-0.09-3.91-0.18-5.32-0.25c-0.95-9.75-0.8-19.37,0.09-29.09
      c-0.03,1.58-0.05,3.16,0.11,4.73c0.36,3.41,0.67,6.82,1.19,10.21C301.97,468.41,303.07,473.29,305.14,477.9z"/>
    <path fill="#6595D2" d="M323.85,475.59c0.28,1.07,0.52,2.09,0.7,3.04c-1.3-0.03-4.66-0.14-8.59-0.29
      C318.67,477.67,321.24,476.58,323.85,475.59z"/>
    <path fill="#6595D2" d="M291.31,453.19c0.97-3.53,2.37-6.92,4.34-9.94c-2.4,11.41-4.32,22.71-2.92,34.41
      c-0.33,0.04-1.17-0.05-1.5-0.08C291.58,473.43,291.42,462.43,291.31,453.19z"/>
    <path fill="#6595D2" d="M312,537.37c-5.64,1.26-11.44,2.52-17.07,4.11c-1.73-0.1-3.42-0.25-5.02-0.47
      c0.38-1.22,0.39-2.53,0.39-3.76c0-1.46-0.29-4.48-0.2-4.5c0.41,1.43,1.1,2.99,2.86,3.37c1.17,0.25,2.76-1.34,3.15-0.67
      c0.39,0.68,3.19,2.4,5.17,0.79c0.76-0.62,1.36-1.38,2.4-0.29c0.35,0.36,2.82,2.99,4.84,0.4c0.68-0.88,1.74-0.58,2.85-0.27
      c2.22,0.62,3.12-1.46,3.96-3.06c1.88,1.21,2.62,2.18,4.74,2.75C317.35,536.25,314.66,536.78,312,537.37z"/>
    <path fill="#6595D2" d="M331.98,533.99c-1.94,0.22-3.87,0.48-5.8,0.76c0.4-0.22,0.76-0.45,1.03-0.7
      c1.57-1.45-0.12-3.16-1.33-3.52c1.78-0.15,10.98-0.92,13.53-1.3c0.31,0.68,1.11,2.27,1.77,3.89
      C338.12,533.51,335.04,533.64,331.98,533.99z"/>
    <path fill="#9C2114" d="M270.74,531.52c3.35,0.41,16.19,0.31,18.74,0.2c-0.63-2.92,2.42-11.49,8.28-13.51
      c-0.46-0.08-1.36-0.6-1.66-1.02c0.57-5.88-1.25-17.96-1.75-21.29c-6.31-0.24-15.58-0.46-20.66-0.75
      C272.84,502.24,270.38,525.95,270.74,531.52z"/>
    <path fill="#792114" d="M307.59,517.39c-0.48-0.85-4.07-14.96-4.89-21.33c0.56,0.02,1.17,0.05,1.83,0.07
      c0.61,7.95,1.45,15.95,3.92,23.66c-0.79-0.52-1.58-1-2.38-1.42C306.56,518.27,307.18,517.95,307.59,517.39z"/>
    <path fill="#792114" d="M286.53,531.79c0.3-1.58,1.07-7.6,5.93-12.07c-0.91-4.71-1.06-20.24-0.91-23.92
      c0.97,0.03,1.91,0.06,2.79,0.1c0.5,3.33,2.32,15.41,1.75,21.29c0.3,0.43,1.2,0.94,1.66,1.02c-5.86,2.02-8.91,10.59-8.28,13.51
      C288.92,531.74,287.86,531.77,286.53,531.79z"/>
    <path fill="#70A65B" d="M261.35,524.36c0.4,0.19,5.94,1.48,7.01,1.48c0.05-1.23,2.72-25.36,3.48-31.43
      c1.26-0.61,4.74-3.06,5.97-5.06c1.99,0.01,11.7,0.28,16.09,0.22c-0.18-1.85-0.39-3.61-0.57-5.46c-8.14-0.15-23.37-1.03-29.09-1.65
      C263.82,486.16,260.97,515.42,261.35,524.36z"/>
    <path fill="#5D9146" d="M302.07,484.57c0.1,1.74,0.31,3.48,0.51,5.22c-0.37-0.01-0.72-0.02-1.05-0.03
      c-0.29-1.66-0.63-3.63-0.88-5.29C301.13,484.5,301.6,484.54,302.07,484.57z"/>
    <path fill="#5D9146" d="M290.8,484.06c0.89,0.03,1.76,0.05,2.54,0.06c0.18,1.85,0.39,3.61,0.57,5.46
      c-0.79,0.01-1.77,0.01-2.83,0C290.96,487.74,290.86,485.9,290.8,484.06z"/>
    <path fill="#5D9146" d="M267.52,495.11c1.98-1.27,4.1-2.32,5.94-3.67l2.45,0.06c-1.47,1.33-3.24,2.52-4.06,2.92
      c-0.76,6.06-3.44,30.19-3.48,31.43c-0.54,0-2.2-0.32-3.77-0.67C265.74,515.16,267.06,505.16,267.52,495.11z"/>
    <path fill="#E6AD8D" d="M327.16,347.9c-2.3,4.67-6.88,9.52-12.64,12.22c-0.01,0-0.01-0.01-0.02-0.01
      c-2.53-0.32-5.28,0.3-7.84,1c8.51-3.79,16.04-9.38,20.22-16.44c4.38,2.21,8.74,6.05,11.35,9.9c4.18,6.2,5.6,10.1,5.95,14.22
      c-0.51-0.46-1.02-0.91-1.52-1.35c-1.32-4.47-3.63-8.7-6.55-12.64C333.51,351.3,327.78,346.65,327.16,347.9z"/>
    <path fill="#E6AD8D" d="M309.01,365.25c-0.84-0.16-1.66-0.27-2.48-0.31c1.58-0.66,3.04-1.46,4.5-2.25
      C310.4,363.56,309.74,364.42,309.01,365.25z"/>
    <path fill="#E6AD8D" d="M332.13,364c3.26,1.31,5.38,2.91,6.25,4.09c-1.41-0.19-2.8-0.2-4.14-0.04
      C333.79,366.64,333.03,365.3,332.13,364z"/>
    <path fill="#E6AD8D" d="M297.51,367.42c-2.46,1.12-2.94,0.34-3.16,0.17c-2.17,1.81-4.93,3.38-7.91,4.22
      c2.31,0.75,4.76,1.35,6.09,2.2C293.69,370.94,295.97,368.52,297.51,367.42z"/>
    <path fill="#E6AD8D" d="M314.98,391.3c-3.33,2.16-7.93,3.61-12.26,3.07c-7.23-0.9-10.34-5.88-10.66-11.11
      c0.69,2.55,2.22,4.93,4.99,6.87c4.95,3.46,14.06,4.31,19.55-0.42C316.15,390.32,315.65,390.88,314.98,391.3z"/>
    <path fill="#E6AD8D" d="M333.68,406.98c2.29-0.15,3.74-1.21,4.73-2.62c-0.92,3.63-5.32,5.83-9.84,4.93
      c-3.17-0.63-6.76-2.25-6.36-5.13C325.38,406.35,329.53,407.25,333.68,406.98z"/>
    <path fill="#E6AD8D" d="M322.55,416.15c1.37,0.8,3.06,1.1,4.67,0.44c0.29,0.67,0.28,1.44-0.05,2.08
      c-0.58,0.41-1.4,0.62-2.13,0.64c-0.93,0.03-2.02-0.11-2.63-0.75C321.75,417.86,322.11,416.9,322.55,416.15z"/>
    <path fill="#E6AD8D" d="M304.37,406.05c2.03,2.81,3.78,5.73,5.25,8.75c-4.01-3.08-7.71-6.64-10.3-10.01
      c-1.18-1.53-3.16-4.79-5.13-8.6C298.5,398.43,301.99,402.77,304.37,406.05z"/>
    <path fill="#E6AD8D" d="M334.23,392.81c-4.68-1.12-7.35-3.36-9.89-6.59c-0.99-1.26-2.2-1.97-3.95-1.11
      c-0.27,0.14-0.5,0.3-0.72,0.47c0.31-0.67,0.58-1.38,0.79-2.16c0.79-0.55,1.92-0.59,2.64-0.23c1.89,5.37,8.8,9.22,15.89,8.23
      c3.45-0.48,6.51-1.89,8.66-4.29C345.28,391.34,340.96,394.41,334.23,392.81z"/>
    <path fill="#E6AD8D" d="M296.41,520.63c0.77-0.58,1.63-1.03,2.58-1.24c2.52,0.67,4.51,0.5,6.22-0.41
      c0.77,0.46,1.6,1.06,2.46,1.72C303.8,522.32,300.2,521.72,296.41,520.63z"/>
    <path fill="#E6AD8D" d="M298.38,534.76c0.85-0.07,1.75-0.62,2.3-1.14c1.44-1.4,2.69-0.07,3.87,0.85
      c1,0.79,2.71,0.42,3.6-0.3c1.87-1.51,3.53-0.04,5.42,0.04c-0.62,0.79-1.62,1.2-3.28,0.35c-0.8-0.4-1.62-0.53-2.57,0.85
      c-1.35,1.96-3.38,0.07-4.55-1.44c-0.4-0.51-0.91-0.27-1.03-0.13c-0.83,0.92-3.53,2.91-4.72,0.89
      C297.71,534.78,298.02,534.78,298.38,534.76z"/>
    <path d="M326.54,376.65c1.67-5.34,8.2-7.48,14.14-5.57c4.95,1.59,7.03,5.02,6.22,9.8c-0.91,5.4-6.88,8.76-12.66,7.54
      C327.24,386.94,324.89,381.94,326.54,376.65z"/>
    <path fill="#F2DCC6" d="M333.58,387.45c5.39,1.33,11.89-2.13,12.38-6.91c0.38-3.73-0.95-6.89-4.7-8.39
      c-5.15-2.07-11.03-0.59-13.26,3.54C325.36,380.61,328.33,386.15,333.58,387.45z"/>
    <path d="M295.51,376.77c0.87-5.27,6.38-9.01,12.93-8.29c6.57,0.72,10.17,6.31,9.38,11.32c-1.11,7.01-7.01,10.35-13.01,9.72
      C297.31,388.72,294.59,382.35,295.51,376.77z"/>
    <path fill="#F2DCC6" d="M304.44,388.32c6.24,0.9,11.71-3.33,12.23-8.61c0.53-5.28-2.95-9.47-7.51-10.27
      c-6.03-1.05-11.52,2.34-12.34,6.99C295.91,381.51,298.2,387.42,304.44,388.32z"/>
    <path fill="#FBF2EB" d="M331.69,372.29c1.32-0.64,2.83-1.01,4.41-1.06c2.85,4.28,5.44,8.66,8.09,13.03
      c-1.17,1.29-2.8,2.3-4.62,2.89C337.24,382.1,334.63,377.13,331.69,372.29z"/>
    <path fill="#FBF2EB" d="M311.98,386.8c-2.75-5.71-5.51-11.41-8.29-17.1c1.68-0.47,3.55-0.59,5.47-0.25
      c0.19,0.03,0.37,0.08,0.56,0.13c2.23,4.29,4.25,8.64,6.13,13.04C315.03,384.3,313.67,385.76,311.98,386.8z"/>
    <path d="M327.74,379.53c-0.05,1.78,1.68,3.26,3.87,3.32c2.18,0.05,3.99-1.35,4.04-3.13c0.05-1.78-1.68-3.27-3.87-3.32
      C329.6,376.35,327.79,377.75,327.74,379.53z"/>
    <path d="M299.64,379.17c-0.07,2.37,2.23,4.33,5.13,4.41c2.9,0.07,5.31-1.79,5.38-4.15c0.06-2.37-2.23-4.34-5.14-4.41
      C302.11,374.95,299.7,376.81,299.64,379.17z"/>
    <path fill="#FFFFFF" d="M300.97,377.99c-0.01,0.68,0.64,1.25,1.48,1.27c0.84,0.02,1.53-0.52,1.55-1.2
      c0.02-0.68-0.65-1.25-1.48-1.27C301.68,376.77,300.99,377.3,300.97,377.99z"/>
    <path fill="#FFFFFF" d="M303.93,376.6c-0.01,0.31,0.3,0.58,0.69,0.59c0.39,0.01,0.71-0.24,0.72-0.56
      c0.01-0.32-0.3-0.58-0.69-0.59C304.26,376.03,303.94,376.28,303.93,376.6z"/>
    <path fill="#FFFFFF" d="M329.18,378.6c-0.01,0.46,0.44,0.85,1,0.86c0.57,0.02,1.04-0.35,1.05-0.81
      c0.02-0.46-0.44-0.85-1.01-0.86C329.66,377.77,329.19,378.14,329.18,378.6z"/>
    <path fill="#FFFFFF" d="M331.48,377.55c-0.01,0.24,0.23,0.45,0.53,0.46c0.3,0.01,0.55-0.19,0.56-0.43
      c0.01-0.24-0.23-0.45-0.53-0.45C331.74,377.12,331.49,377.31,331.48,377.55z"/>
    <path d="M321.57,410.17c1.79,1.27,5.26,2.72,7.93,2.33c0.67-0.19,0.95-0.9,0.2-1.22c-1.21-0.52-2.47-1.68-2.54-3.73
      c-4.79-1.93-4.78-4.04-4.3-3.78c3.82,2.14,11.71,4.91,14.6,0.23c1.59-2.58-1.18-1.74-3.24-1.4c-0.96,0.16-1.99-0.01-2.83-0.42
      c-5.05-2.41-7.19-9.01-6.57-13.41c0.03-0.19,0.39-0.25,0.46-0.06c1.3,3.28,3.97,13.19,9.93,11.59c2.21-0.59,5.09-0.06,4.84,2.34
      c-0.29,2.67-2.32,4.64-5.56,5.29c-2.32,0.47-4.45,0.41-6.39-0.1c0,0.98,0.87,2.49,2.81,3.28c0.84,0.34,0.04,2.25-0.96,2.53
      c-3.03,0.87-7.93-0.53-9.06-3.09C320.73,410.24,321.3,409.98,321.57,410.17z"/>
    <path d="M322.97,415.62c2.75,1.11,3.93-0.05,4.22,0.55c1.05,2.21-3.98,3.17-5.04,0.47C321.98,416.2,322.42,415.39,322.97,415.62z"
      />
    <path fill="#FFE5D5" d="M336.67,402.92c-1.58-0.34-4.26,0.49-4.69,2.23C331.55,406.88,338.78,403.38,336.67,402.92z"/>
    <path fill="#FFE5D5" d="M311.42,397.83c-2.85-1.87-6.81-2.71-9.1-1.03C300.01,398.47,314.49,399.84,311.42,397.83z"/>
    <path fill="#FFE5D5" d="M345.41,394.57c-2.2-1.08-4.87-0.42-5.3,1.71C339.67,398.41,347.38,395.54,345.41,394.57z"/>
    <path fill="#FFE5D5" d="M325.42,420.39c-1.63-0.04-2.28,1.17-0.54,1.52C326.63,422.25,327.43,420.44,325.42,420.39z"/>
    <path fill="#FFE5D5" d="M295.35,523.48c-2.61,2.28-2.93,5.26-2.59,7.67C293.1,533.56,297.27,521.79,295.35,523.48z"/>
    <path fill="#FFE5D5" d="M324.15,414.09c-1.46-0.31-1.64,0.73-0.31,1.2C325.18,415.77,325.89,414.46,324.15,414.09z"/>
    <path fill="#8A6C69" d="M309.97,336.46c-2.16,0.28-8.31,2.04-12.82,4.92c-4.51,2.88-7.03,5.31-8.13,6.53
      C287.92,349.13,313.2,336.04,309.97,336.46z"/>
    <path fill="#8A6C69" d="M308.22,339.04c-1.92,1.27-9.32,5.25-11.49,6.32c-2.17,1.07,2.9,0.07,7.25-2.44
      C308.34,340.41,309.32,338.32,308.22,339.04z"/>
    <path fill="#8A6C69" d="M346.17,353.9c-0.7-1.47-2.02-4.41-4.56-6.59c-2.54-2.18-5.09-3.61-6.61-4.14
      c-1.51-0.53,1.72-1.45,7.09,3.28C347.46,351.19,346.89,355.41,346.17,353.9z"/>
    <path fill="#AD989A" d="M298.16,371.17c1.74-1.83,4.08-3.76,2.38-3.06c-1.16,0.48-4.21,2.89-5.52,6.1
      C294.65,375.11,296.42,373,298.16,371.17z"/>
    <path fill="#AD989A" d="M327.58,373.75c1.47-1.72,3.24-3.26,2.37-2.93c-1.92,0.73-4.69,3.75-5.21,5.85
      C324.48,377.78,325.92,375.68,327.58,373.75z"/>
    <path fill="#A6A3A6" d="M327.29,632.06c-0.81-1.14-4.45-3.68-6.26-4.16C317.95,627.08,329.4,635,327.29,632.06z"/>
    <path fill="#A6A3A6" d="M340.53,637.72c-1.62-0.79-7.23-2.25-9.23-2.38C329.3,635.22,343.85,639.35,340.53,637.72z"/>
    <path fill="#ABA4AB" d="M334.38,642.32c-2.27-1.31-11.45-4.13-14.65-3.92C316.54,638.63,337.35,644.03,334.38,642.32z"/>
    <path fill="#ABA4AB" d="M316.64,635.22c-0.88-1.11-5.38-3.1-7.39-3.07C307.25,632.16,318.13,637.1,316.64,635.22z"/>
    <path fill="#99C2ED" d="M297.17,474.28c0.39,3.76,1.83,17.25,2.77,21.76c0.94,4.5-2.88-4.84-3.45-9.77
      C295.91,481.33,296.75,470.22,297.17,474.28z"/>
    <path fill="#99C2ED" d="M317.04,461.89c-1.68-5.1-6.08-17.64-7.92-21.59c-1.84-3.95,0.3,8.25,1.46,11.46
      C311.73,454.96,319,467.86,317.04,461.89z"/>
    <path fill="#99C2ED" d="M295.85,425.95c-1.27,2.88-2.35,7.9-2.8,11.21c-0.45,3.32,2.76-3.55,3.17-5.71
      C296.64,429.29,296.99,423.37,295.85,425.95z"/>
    <path fill="#6A87D3" d="M320.84,551.49c-0.13,4.7,1.02,14.9,0.57,18.09c-0.45,3.2-2.46-1.52-2.68-6.48
      C318.51,558.14,320.99,546.25,320.84,551.49z"/>
    <path fill="#6A87D3" d="M320.57,599.61c-0.85,4.91-2.24,11.14-3.3,15.67c-1.05,4.53-1.7-2.96-0.87-7.31
      C317.25,603.61,321.43,594.67,320.57,599.61z"/>
    <path fill="#BD4028" d="M333.01,498.36c-3.64,0.31-18.3-0.58-21.09-0.79c-2.78-0.21,7.07,3.15,11.08,3.4
      C327,501.21,339.49,497.81,333.01,498.36z"/>
    <path fill="#BD4028" d="M284.03,496.62c-1.94,0.18-7.83-0.76-9.07,0.07c-0.82,0.54-1.1,8.08-1.3,9.21
      C273.44,507.03,286.48,496.39,284.03,496.62z"/>
    <path fill="#94B67A" d="M273.21,483.98c-2.44-0.06-7.13-0.89-7.67-0.33c-0.54,0.56-0.69,5.81-0.72,7.09
      C264.79,492.03,277.22,484.08,273.21,483.98z"/>
    <path fill="#94B67A" d="M325.67,486.39c-3.14-0.08-13.6-0.33-16.21-0.53c-2.61-0.2,4.83,1.97,7.8,1.89
      C320.22,487.67,330.03,486.49,325.67,486.39z"/>
  </g>
  <g id="item_6_">
    <path fill="#D7819A" d="M511.35,424.06c0-0.49-0.35-1.28-0.85-1.8c-0.5-0.53-0.9-0.82-1.34-0.65
      c-0.44,0.18-4.18,1.95-6.65,4.38c-2.47,2.41-4.59,6.26-3.77,6.78c0.81,0.52,1.96,0.5,2.59,0.41c0.62-0.09,1.19-2.28,3.72-4.58
      C507.57,426.31,511.35,424.06,511.35,424.06z"/>
    <path fill="#7D7D7D" d="M543.22,651.84l-4.52,0.29c0,0,1.41-2.1,2.45-2.83c1.04-0.73,2.12-1.39,2.12-1.39L543.22,651.84z"/>
    <path fill="#C73F33" d="M542.99,648.2c0,0,0.86-3.56,0-5.28c-0.85-1.71-1.74-2.58-2.21-2.3c-0.47,0.28-2.52,2.43-3.57,2.61
      c-1.05,0.18-4.34-0.68-4.73-1.41c-0.39-0.74-1.05-1.04-1.65-0.24c-0.59,0.79-1.77,2.75-2.75,3.31c-0.99,0.55-3.29,1.22-3.29,1.22
      s-0.72-1.16-1.17-2.39c-0.47-1.23-0.86-4.42-1.12-4.42c-0.26,0-2.43,2.76-4.4,1.35c-1.96-1.42-3.08-3.75-3.08-3.75
      s-0.92,3.87-1.77,5.52c-0.86,1.65-2.17,2.82-2.56,3.8c-0.4,0.98-0.6,1.97-0.6,1.97s-3.28,3.49-0.78,6.81
      c2.49,3.31,7.55,1.66,7.42,1.84c-0.12,0.19,3.75,5.64,11.17,3.92c7.42-1.72,8.61-5.95,10.58-8.16
      C540.43,650.41,542.99,648.2,542.99,648.2z"/>
    <path fill="#AC2C1F" d="M515.48,649.16c0.26-1.45,0.98-2.77,2.51-3.55c1.81-0.93,3.91-0.86,5.89-1.23
      c0.24,0.54,0.51,1.02,0.69,1.34c-2.66,0.47-5.66,0.85-7.87,2.33C516.21,648.39,515.8,648.76,515.48,649.16z"/>
    <path fill="#AC2C1F" d="M537.01,651.64c1.53-2.13,3.72-4.95,6.36-6.32c-0.03,1.48-0.38,2.89-0.38,2.89s-2.55,2.2-4.53,4.41
      c-1.27,1.43-2.23,3.71-4.77,5.61C534.41,655.89,535.55,653.66,537.01,651.64z"/>
    <path fill="#DE6E5D" d="M517.33,657.74c-1.68-2.58-3.13-5.92-1.3-8.7c1.31-2,2.91-2.24,5.19-2.52
      c1.16-0.14,2.27-0.44,3.39-0.72c0.11,0.2,0.18,0.32,0.18,0.32s2.3-0.67,3.29-1.22c0.98-0.56,2.16-2.52,2.75-3.31
      c0.43-0.58,0.89-0.57,1.26-0.25c-1.55,3.65-4.25,7.26-9.13,7.2c-2.81-0.05-4.79,0.7-5.26,3.47c-0.32,1.88-0.03,4.68,0.99,6.99
      C518.1,658.55,517.65,658.11,517.33,657.74z"/>
    <path fill="#DE6E5D" d="M513.52,644.95c-0.78,1.26-1.92,1.77-3.16,2.18c0.08-0.28,0.19-0.59,0.32-0.9
      c0.39-0.98,1.7-2.14,2.56-3.8c0.85-1.66,1.77-5.52,1.77-5.52s0.24,0.51,0.7,1.19C515.31,640.48,514.84,642.83,513.52,644.95z"/>
    <path fill="#D7819A" d="M546.98,555.55c0.21,1.6,1.37,5.69,2.87,9.51c1.5,3.83,4.17,9.2,8.13,12.38
      c3.96,3.19,7.04,4.91,8.95,6.57c1.92,1.66,1.78,2.3,0.76,2.17c-1.03-0.13-3.9,0-5.33,0.52c-1.44,0.5-4.52,0.56-6.56,0
      c-2.05-0.58-5.27-1.27-7.59-0.58c-2.33,0.7-4.99,1.86-7.52,1.21c-2.53-0.63-6.97-0.89-8.27-0.51c-1.3,0.38-5.6,2.3-7.31,2.04
      c-1.72-0.26-6.36-0.77-8.13-0.51c-1.78,0.26-7.19,0.32-8.62-0.06c-1.43-0.39-6.83,0.77-8.54,0.89c-1.71,0.13-4.44-0.83-5.33-1.02
      c-0.9-0.19-4.37,1.09-5.82,0.25c-1.43-0.83-4.51-2.62-3.41-3.13c1.09-0.51,6.15-3.13,8.89-4.91c2.74-1.79,8.89-8.17,11.27-10.84
      c2.39-2.69,4.44-5.94,5.54-8.24c1.09-2.29,1.23-3.76,1.23-3.76s3.49,1.21,10.46-0.38c6.97-1.6,9.16-1.98,11.62-1.85
      c2.46,0.13,3.69-0.33,4.78-0.39c1.09-0.06,2.87,0.19,4.1,0.32C544.38,555.36,546.98,555.55,546.98,555.55z"/>
    <path fill="#DF99AD" d="M496,587.63c1.08-0.01,2.15,0,3.22-0.08c1.09-0.08,2.09-0.49,3.17-0.53c1.84-0.08,3.65,0.11,5.48,0
      c3.92-0.25,7.87-0.11,11.77,0.21c2.67,0.21,5.7,0.48,8.08-0.9c1.19-0.69,2.39-1.75,3.82-1.94c2.13-0.28,4.12,0.41,6.16,0.86
      c3.39,0.76,5.89,0.29,9.09-0.86c1.98-0.71,4.25-1.44,6.38-0.79c1.58,0.48,3.09,1.18,4.76,1.41c1.59,0.21,3.22-0.25,4.82-0.17
      c1.98,0.09,3.84,0.47,5.67,1.1c-0.03,0.22-0.3,0.31-0.72,0.25c-1.03-0.13-3.9,0-5.33,0.52c-1.44,0.5-4.52,0.56-6.56,0
      c-2.05-0.58-5.27-1.27-7.59-0.58c-2.33,0.7-4.99,1.86-7.52,1.21c-2.53-0.63-6.97-0.89-8.27-0.51c-1.3,0.38-5.6,2.3-7.31,2.04
      c-1.72-0.26-6.36-0.77-8.13-0.51c-1.78,0.26-7.19,0.32-8.62-0.06c-1.43-0.39-6.83,0.77-8.54,0.89c-1.71,0.13-4.44-0.83-5.33-1.02
      c-0.44-0.1-1.52,0.17-2.67,0.36C493.11,587.99,494.47,587.65,496,587.63z"/>
    <path fill="#CA6A7C" d="M542.95,557.77c-1.66-0.03-2.94,0.68-4.52,0.91c-1.84,0.28-3.72-0.21-5.58,0.06
      c-1.99,0.29-4.17,0.2-6.11,0.75c-1.93,0.55-3.69,1.51-5.7,1.83c-2.1,0.33-4.49,0.5-6.6,0.02c-1.1-0.24-2.08-0.68-2.97-1.22
      c0.62-1.61,0.72-2.59,0.72-2.59s3.49,1.21,10.46-0.38c6.97-1.6,9.16-1.98,11.62-1.85c2.46,0.13,3.69-0.33,4.78-0.39
      c1.09-0.06,2.87,0.19,4.1,0.32c1.23,0.13,3.83,0.32,3.83,0.32c0.07,0.54,0.25,1.36,0.5,2.36
      C546.02,558.17,544.63,557.81,542.95,557.77z"/>
    <path fill="#D95D4C" d="M529.39,499.67c0.59,0,1.42-0.11,1.52,0.51c0.1,0.63,1.09,2.81,1.86,4.37
      c0.78,1.56,6.08,10.71,7.71,12.61c1.62,1.91,4.51,4.92,4.64,5.25c0.12,0.33,2.19,8.97,2.74,13.18c0.53,4.21,1.12,14.45,0.89,15.72
      c-0.23,1.27-0.88,3.17-1.88,3.86c-1.01,0.68-4.47,0.53-5.84,0.27c-1.35-0.25-1.96-0.41-2.44-0.25c-0.48,0.15-0.79,0.52-1.53,0.57
      c-0.72,0.03-4.93-0.54-7.81-0.12c-2.89,0.4-9.65,2.57-12.07,2.63c-2.43,0.05-6.23,0.02-7.04-4.03c-0.81-4.03-0.46-9.88-0.15-12.65
      c0.31-2.77,0.79-12.28,0.96-14.41c0.18-2.13,1.28-8.25,1.76-8.84c0.48-0.58,3.9-5.67,4.88-6.94c0.98-1.27,3.76-6.8,4.18-8.19
      c0.42-1.38,0.15-2.88,0.63-3.29c0.49-0.41,0.36-0.57,0.85-0.67C523.77,499.17,529.39,499.67,529.39,499.67z"/>
    <path fill="#BD4B3D" d="M525.85,547.02c1.82-0.03,3.67,0.25,5.44,0.76c2.22,0.64,4.68,2.92,3.1,2.63
      c-1.58-0.28-3.74-0.66-5.47-0.51c-2.04,0.17-4.11,1.02-6.06,1.63c-2.01,0.63-4.15,1.58-6.25,1.85c-2.53,0.34-1.26-1.71-0.3-2.72
      C518.72,548.17,522.32,547.07,525.85,547.02z"/>
    <path fill="#EB826F" d="M524.22,503.18c-0.82-0.26-1.55-0.63-2.22-1.07c0.06-0.55,0.08-1.06,0.14-1.46
      c0.81,0.97,2.04,1.62,3.43,1.66c1.81,0.06,4.33-0.43,5.39-1.94c0.07,0.23,0.19,0.57,0.35,0.96
      C530.82,503.75,526.07,503.76,524.22,503.18z"/>
    <path fill="#EB826F" d="M547.96,553.76c-1.62-4.35-0.6-9.57-0.71-14.03c-0.08-3.41-0.57-6.81-0.57-10.23
      c0-0.09,0-0.19,0.01-0.29c0.48,2.29,0.94,4.69,1.17,6.38c0.53,4.21,1.12,14.45,0.89,15.72
      C548.62,552.04,548.35,552.97,547.96,553.76z"/>
    <path fill="#EB826F" d="M525.72,552.45c1.49-0.25,3.06-0.39,4.58-0.3c1.03,0.06,2.02,0.22,2.99,0.57
      c0.04,0.02,1.21,0.46,1.43,0.8c-3.13,0.15-6.26,0.31-9.24,1.28c-1.53,0.5-3.05,1.07-4.62,1.43c-0.91,0.21-1.87,0.44-2.81,0.36
      c-0.3-0.02,0.54-1.27,0.59-1.31C520.41,553.56,523.27,552.85,525.72,552.45z"/>
    <path fill="#EB826F" d="M531.56,510.28c-0.17-0.72-1.05-4.27,0.17-4.11c0.85,2.76,1.61,4.49,2.68,7.03
      c0.31,0.73,1.59,2.84,1.97,3.53c0.23,0.41,1.28,1.4,0.12,0.76c-1.11-0.62-1.78-1.82-2.56-2.77
      C532.74,513.27,532.02,512.05,531.56,510.28z"/>
    <path fill="#EB826F" d="M519.58,511.65c-1.22,2.3-3.16,4.1-4.69,6.19c-1.22,1.69-2.35,3.47-3.34,5.33
      c0.39-2.23,0.89-4.48,1.18-4.83c0.48-0.58,3.9-5.67,4.88-6.94c0.76-0.98,2.59-4.51,3.58-6.71
      C521.18,507.08,520.7,509.53,519.58,511.65z"/>
    <path fill="#88441C" d="M508.4,422.04c0.56-0.85,0.56-2.68,0.21-4.25c-0.35-1.57-0.07-3.99,0.77-6.02
      c0.84-2.02,1.39-4.47,0.98-7.38c-0.98-6.99-4.61-4.64-4.61-4.64s-0.76-1.26-2.45-1.9c-1.68-0.63-2.64-0.29-2.98-0.12
      c-0.33,0.18,0.51,0.43,1.05,0.81c0.55,0.37,1.05,0.88,1.05,0.88s-1.41-0.08-2.87,0.82c-1.47,0.9-2.44,2.35-2.54,2.78
      c-0.11,0.44-0.55,0.83-0.82,0.57c-0.28-0.26-3.5-2.96-6.02-2.47c-2.52,0.49-3.25,1-3.99,1.68c-0.73,0.68-2.24,2.95-2.24,2.95
      s-0.41-2.09,0.32-3.16c0.73-1.06,1.83-2.18,1.83-2.18s-2.33,0.13-4.12,3.12c-1.78,2.98-1.68,3.28-1.64,5.29
      c0.04,2.01,0.59,4.61,0.59,4.61s-2.38-0.3-4.11-0.05c-1.74,0.26-2.07,0.98-2.75,2.3c-0.68,1.33-1,2.77-1,2.77
      c-1.14,0.34-4.39,2.01-5.77,3.46c-1.37,1.46-2.05,3.33-1.4,4.35c0.64,1.03,2.69,2.09,2.74,2.82c0.04,0.73-0.5,2.18-0.6,2.98
      c-0.09,0.81,2.19,4.74,4.48,6.36c2.29,1.63,6.11,4.83,13.98,1.71c4.3-1.71,7.18-5.08,8.72-6.4c1.56-1.33,2.11-1.63,2.56-1.93
      c0.46-0.3,1.28-0.51,1.28-0.51s1.87-3.97,4.53-6.06C506.24,423.17,508.4,422.04,508.4,422.04z"/>
    <path fill="#743808" d="M480.73,440.45c1.46-0.64,2.99-1.47,4.05-2.63c1.06-1.14,1.82-2.45,2.75-3.69
      c1.18-1.59,2.7-2.46,4.3-3.63c1.39-1.02,2.63-2.15,3.71-3.45c2.15-2.59,5.03-4.57,8.42-5.47c1.56-0.4,3.38-0.68,4.89-1.38
      c-0.03,0.75-0.17,1.41-0.45,1.83c0,0-2.17,1.13-4.81,3.22c-2.65,2.1-4.53,6.06-4.53,6.06s-0.82,0.21-1.28,0.51
      c-0.46,0.3-1,0.6-2.56,1.93c-1.55,1.31-4.42,4.69-8.72,6.4c-3.54,1.41-6.26,1.53-8.39,1.09
      C479.01,441.08,479.88,440.82,480.73,440.45z"/>
    <path fill="#B16A3A" d="M506.57,399.44c1.14-0.24,3.11,0.09,3.79,4.96c0.22,1.51,0.17,2.89-0.04,4.14
      C509.76,405.39,508.69,401.96,506.57,399.44z"/>
    <path fill="#B16A3A" d="M505.75,399.76c0,0,0.04-0.03,0.11-0.06c1.72,2.3,1.78,5.4,1.72,8.41c-0.88-3.3-1.82-6.69-4.64-9.03
      c-0.51-0.43-1.25-1.02-2.06-1.52c0.52-0.1,1.31-0.11,2.41,0.3C504.99,398.5,505.75,399.76,505.75,399.76z"/>
    <path fill="#B16A3A" d="M498.4,402.38c-0.34,0.97-0.66,3.57-2.32,2.67c-1-0.53-1.82-1.28-2.85-1.77
      c-3.13-1.5-6.63,0.01-8.96,2.18c-0.01-0.04-0.03-0.07-0.06-0.1c0.47-0.68,1.43-2.04,1.96-2.54c0.74-0.68,1.47-1.18,3.99-1.68
      c2.52-0.49,5.74,2.22,6.02,2.47c0.27,0.26,0.7-0.14,0.82-0.57c0.1-0.42,1.04-1.83,2.47-2.73C499,400.91,498.65,401.63,498.4,402.38
      z"/>
    <path fill="#B16A3A" d="M467.3,421.93c1.38-1.44,4.63-3.11,5.77-3.46c0,0,0.32-1.45,1-2.77c0.68-1.32,1.01-2.05,2.75-2.3
      c1.34-0.2,3.06-0.06,3.77,0.01c-2.07,0.29-3.73,0.83-4.82,2.7c-0.63,1.08-0.97,3.04-2.25,3.61c-1.07,0.47-2.17,0.85-3.16,1.46
      c-1.68,1.02-3.61,2.54-3.44,4.61c0.08,0.88,1.06,1.35,1.43,2.08c0.65,1.28,0.34,2.48-0.2,3.64c0.18-0.8,0.51-1.81,0.48-2.39
      c-0.04-0.73-2.1-1.79-2.74-2.82C465.25,425.26,465.93,423.39,467.3,421.93z"/>
    <path fill="#88441C" d="M553.2,476.39c0,0,2.17-3.1,0.47-7.89c-1.71-4.8-3.32-9.2-4.72-12.59
      c-1.41-3.39-4.67-7.89-7.42-10.51c-2.74-2.61-5.76-4.89-9.02-6.2c-3.28-1.31-6.28-1.6-6.28-1.6s1.14,4.21,3.38,7.35
      c2.22,3.16,4.41,5.77,5.23,6.06c0.83,0.29,1.68,0.52,1.68,0.52s-2.04,1.51-4.9,0.5c-2.85-1.02-4.67-3.4-6.43-5.81
      c-1.76-2.42-2.7-4.88-2.7-4.88s-3.62-0.45-6.58,2.69c-2.96,3.15-5.5,7.94-6.43,11.48c-0.94,3.53-2.55,6.88-3.38,8.86
      c-0.82,1.99-3.68,5.28-3.68,5.28s-1.51,7.66-1.2,8.28c0.31,0.64,3.48,4.75,3.48,4.75s-1.3,1.35-2.81,1.69
      c-1.51,0.34-7.62-2.86-10.42-6.59c-2.8-3.73-4.98-7.74-3.58-16.65c1.4-8.91,5.03-14.24,6.27-16.65c1.24-2.42,4.51-5.43,4.51-5.43
      s1.09-2.71,1.76-3.92c0.68-1.21,4.26-9.54,15.87-12.64c11.62-3.1,17.59,0.2,21.27,1.5c3.68,1.31,8.61,5.71,8.61,5.71
      s8.35,2.13,12.76,8.67c4.41,6.54,5.96,14.14,5.81,21.36c-0.16,7.21-1.19,11.91-2.71,14.19c-1.5,2.28-2.74,3.29-4.92,3.53
      C554.96,477.7,553.2,476.39,553.2,476.39z"/>
    <path fill="#743808" d="M504.23,461.45c1-2.02,1.83-4.55,2.6-6.66c0.85-2.33,1.79-4.15,2.75-6.43
      c-0.17,2.24-0.72,4.83-1.26,7.02c-0.54,2.14-0.88,4.96-1.8,6.97c-1.07,2.33-2.43,4.83-3.91,7.06
      C502.8,467.07,503.16,463.61,504.23,461.45z"/>
    <path fill="#743808" d="M518.74,431.52c0.31,1.3,0.75,2.57,1.12,3.85c0.62,2.08,1.43,4.09,2.54,5.98
      c-0.21-0.02-0.87-0.04-1.75,0.11c-0.24-0.69-0.46-1.4-0.66-2.1C519.28,436.94,518.12,434.06,518.74,431.52z"/>
    <path fill="#B16A3A" d="M529.63,447.43c-2.32-3.25-5.9-11.17-4.94-13c1.66,4.79,3.4,8.15,4.91,10.53
      c2.68,4.28,4.41,5.77,5.23,6.06c0.53,0.18,1.07,0.34,1.38,0.43c-0.32,0.2-0.67,0.35-1.06,0.44
      C533.32,452.29,530.74,448.99,529.63,447.43z"/>
    <path fill="#B16A3A" d="M493.03,452.09c-1.45,5.21-2.45,10.54-2.79,15.93c-0.16,2.63-0.34,5.3-0.06,7.94
      c-2.12-3.32-3.47-7.34-2.29-14.82c1.4-8.91,5.03-14.24,6.27-16.65c0.92-1.79,2.94-3.9,3.94-4.88
      C495.78,443.53,494.23,447.76,493.03,452.09z"/>
    <path fill="#B16A3A" d="M501.24,477.98c-0.52-0.46-0.85-1.11-0.92-2.07c-0.09-1.21,0-2.4,0.19-3.6
      c0.47-3.07,0.67-6.22,1.48-9.24c0.86-3.15,2.01-6.77,3.91-9.52c1.05-1.56,2.37-3.43,3.46-4.96c-1.44,4.11-3.66,8.42-5.09,12.55
      c-1.59,4.58-2.47,9.32-2.84,14.11c-0.19,1.34-0.32,2.46-0.21,2.68C501.23,477.95,501.23,477.97,501.24,477.98z"/>
    <path fill="#B16A3A" d="M564.17,456.32c-0.88-5.61-2.69-11.13-6.12-15.86c-2.95-4.08-6.83-7.24-11.87-8.83
      c-2.4-0.76-5.79-1.78-8.66-1.42c0.94-0.34,1.98-0.54,2.85-0.65c2.07-0.28,4.04,0.16,6.06,0.24c1.37,0.39,8.53,2.7,12.52,8.6
      c4.37,6.49,5.93,14.02,5.81,21.19C564.54,458.49,564.35,457.41,564.17,456.32z"/>
    <path fill="#B16A3A" d="M533.66,424.66c-5.4-1.05-12.75-0.94-18.01,0.79c-6.76,2.22-11.85,5.65-15.17,11.2
      c-0.01,0.01-0.03,0.02-0.05,0.03c-0.01,0.02-0.03,0.04-0.04,0.05c-0.17-0.1-0.39-0.11-0.49,0.1c-0.02,0.04-0.04,0.08-0.05,0.12
      c-0.15,0-0.3,0.07-0.37,0.23c-0.01,0.04-0.03,0.09-0.05,0.13c0,0-0.01-0.01-0.01-0.01c0.33-0.77,0.72-1.63,1.03-2.17
      c0.68-1.21,4.26-9.54,15.87-12.64c11.62-3.1,17.59,0.2,21.27,1.5c2.16,0.77,4.75,2.6,6.52,3.99c-0.8-0.3-1.62-0.56-2.34-0.85
      C539.19,426.11,536.4,425.19,533.66,424.66z"/>
    <path fill="#E6A979" d="M523.11,588.98c0.09,1.11,0.62,19.74,0.62,22.71c0,2.97-0.35,13.76-0.82,16.91
      c-0.45,3.15-0.37,5.78-0.45,7.14c-0.09,1.36,0.21,2.97,0.21,2.97s-1.14,2.64-3.7,2.08c-2.55-0.55-4.11-3.1-3.98-4.08
      c0.12-0.97,0.65-2.58,0.68-3.51c0.03-0.94,0.12-5.97-0.28-7.82c-0.41-1.86-1.26-6.35-1.62-7.82c-0.37-1.48-1.25-8.19-1.41-10.08
      c-0.16-1.89,0.19-7.08,0.38-8.72c0.19-1.62,1.54-10,1.54-10s2.6-0.53,4.83-0.52C521.32,588.26,523.11,588.98,523.11,588.98z"/>
    <path fill="#E6A979" d="M542.58,587.82c0,1.1,0,7.48,0.44,11.56c0.47,4.07,3.83,14.19,2.73,19.03
      c-1.09,4.84-1.82,7.47-2.82,11.3c-1,3.83-0.73,6.8-1.09,8.42c-0.36,1.61-1.18,3.22-1.27,3.9c-0.09,0.69-2.64,4.6-5.55,2.9
      c-2.92-1.7-3.74-3.66-3.74-3.66s0.01-1.02,0.74-1.7c0.73-0.68,1.63-3.82,2.73-7.99c1.1-4.16,1.18-11.22,0.54-17.16
      c-0.64-5.95-1.54-11.89-1.54-11.89s-1.55-1.78-1.37-4.5c0.19-2.72,0.28-4.08,0.09-6.71c-0.18-2.64-0.63-4.59-0.63-4.59
      s1.73-0.43,4.64,0C539.39,587.14,542.58,587.82,542.58,587.82z"/>
    <path fill="#CC8D60" d="M517.62,593.2c-1.31-0.15-2.65-0.28-4-0.37c0.34-2.17,0.65-4.07,0.65-4.07s2.6-0.53,4.83-0.52
      c2.23,0.02,4.02,0.74,4.02,0.74c0.03,0.3,0.09,1.91,0.16,4.14C521.4,593.45,519.61,593.41,517.62,593.2z"/>
    <path fill="#CC8D60" d="M538.23,591.54c-1.9-0.3-3.84-0.61-5.78-0.51c-0.19-2.49-0.61-4.31-0.61-4.31s1.73-0.43,4.64,0
      c2.91,0.42,6.1,1.1,6.1,1.1c0,0.49,0,2.01,0.04,3.9C541.19,591.85,539.75,591.78,538.23,591.54z"/>
    <path fill="#E6A979" d="M521.66,503.39c-0.55,0.65-1.19,0.85-2.03,1.42c-0.82,0.57-2.44,1.4-3.6,1.79
      c-1.16,0.4-4.89,1.52-6.42,2.63c-1.53,1.12-2.66,2.77-3.08,5.28c-0.43,2.51-0.16,8.73-0.46,11.01c-0.31,2.28-0.59,14.15-0.77,16.46
      c-0.18,2.31-0.36,13.18,0.16,16.11c0.52,2.95,1.44,5.66,1.78,6.34c0.34,0.68,0.76,1.17,0.91,1.26c0.15,0.08,1.8-2.2,2.75-3.91
      c0.95-1.71,1.37-3.62,1.44-4.05c0.05-0.43-2.58-1.17-2.88-5.28c-0.31-4.11,0.7-12.87,0.94-16.15c0.25-3.28,0.74-14.8,1.53-16.49
      c0.8-1.69,5.08-6.82,6.3-9.16C519.45,508.32,521.66,503.39,521.66,503.39z"/>
    <path fill="#CC8D60" d="M512.34,557.74c-0.06,0.34-0.35,1.65-0.95,3.04c-0.14-0.67-0.32-1.33-0.59-2
      c-0.53-1.36-1.47-2.57-1.96-3.95c-0.53-1.5-0.83-2.99-0.77-4.58c0.13-2.93,0.71-5.84,0.97-8.77c0.26-2.87,0.41-5.74,0.55-8.61
      c0.28-5.66,0.6-11.4,4.04-16.28c2.55-3.63,5.02-7.15,5.55-11.48c0.16-0.1,0.32-0.2,0.45-0.29c0.83-0.57,1.47-0.77,2.03-1.42
      c0,0-2.21,4.93-3.43,7.27c-1.22,2.34-5.5,7.47-6.3,9.16c-0.79,1.68-1.28,13.21-1.53,16.49c-0.24,3.28-1.25,12.04-0.94,16.15
      C509.76,556.57,512.39,557.31,512.34,557.74z"/>
    <path fill="#E6A979" d="M531.65,502.21c0.71,0.66,1.8,1.46,2.84,1.86c1.04,0.4,2.7,0.62,4.25,0.83
      c1.56,0.2,4.61,0.48,6.87,3.22c2.26,2.74,3.91,8.33,5.07,16.63c1.17,8.3,2.73,18.83,2.39,23.39c-0.34,4.56-1.99,11.19-2.66,12.53
      c-0.67,1.34-1.41,1.91-1.41,1.91s-1.94-5.48-2.14-6.02c-0.2-0.55-0.43-1.4-0.43-1.4s1.51-1.33,1.9-2.28
      c0.54-1.38,0.89-5.06,0.58-7.77c-0.31-2.71-1.74-14.26-2.48-16.98c-0.74-2.71-0.97-5.53-1.71-6.45c-0.74-0.91-4.07-4.11-6.12-7.13
      c-2.04-3.02-4.49-6.96-5.32-8.58C532.47,504.35,531.65,502.21,531.65,502.21z"/>
    <path fill="#CC8D60" d="M547.89,536.98c1.63,3.83,2.72,8.1,2.82,12.06c0.07,2.68-0.33,5.39-1.6,7.83
      c-0.38,0.73-0.82,1.51-1.33,2.25c-0.43-1.21-0.83-2.34-0.91-2.56c-0.2-0.55-0.43-1.4-0.43-1.4s1.51-1.33,1.9-2.28
      c0.54-1.38,0.89-5.06,0.58-7.77C548.77,543.81,548.36,540.43,547.89,536.98z"/>
    <path fill="#CC8D60" d="M533.89,503.79c0.21,1.25,0.82,2.41,1.36,3.58c0.91,1.99,1.92,3.95,3.07,5.82
      c1.5,2.44,3.19,4.76,5.01,6.98c-1.36-1.44-3.32-3.56-4.71-5.6c-2.04-3.02-4.49-6.96-5.32-8.58c-0.82-1.63-1.64-3.77-1.64-3.77
      C532.22,502.75,533.04,503.36,533.89,503.79z"/>
    <path fill="#E6A979" d="M551.49,479.56c0.95-1.68,1.9-3.37,2.67-5.06c0.77-1.7,0.43-4.59-0.69-7.41
      c-1.12-2.82-3.54-7.89-4.66-11.03c-1.12-3.14-3.18-7.49-7.76-11.51c-4.56-4.02-6.21-4.51-8.88-5.47c-2.67-0.96-6.29-1.53-6.29-1.53
      s2.15,4.19,3.97,7.49c1.81,3.3,3.02,4.53,4.24,5.42c1.21,0.89,1.97,1.02,1.97,1.02s-1.23,0.95-2.73,0.81
      c-1.51-0.13-4.76-2.02-6.18-3.73c-1.42-1.71-4.66-7.2-4.66-7.2s-2.21-0.15-3.47,0.43c-1.25,0.58-4.35,2.29-7.15,8.02
      c-2.81,5.75-3.84,10.3-5,12.75c-1.15,2.45-2.19,3.87-2.92,5.12c-0.74,1.25-1.82,2.16-1.82,2.16s-2.23,6.54-1.26,8.07
      c0.98,1.53,5.83,8.66,10.57,11.73c4.75,3.07,12.25,5.06,12.25,5.06s-0.18,5.74-0.3,6.25c-0.13,0.51,1.4,1.43,3.72,1.14
      c2.31-0.29,2.56-0.85,2.31-1.82c-0.24-0.97-0.18-5.52-0.18-5.52s6.52-1.14,12.8-5C548.3,485.88,551.49,479.56,551.49,479.56z"/>
    <path fill="#CC8D60" d="M525.07,486.74c-0.62,0.49-1.3,0.99-2.05,1.2c-1.39,0.38-2.56-0.43-2.98-1.67
      c-0.34-0.99-0.39-2.07-0.17-3.08C520.81,485.05,522.85,486.48,525.07,486.74z"/>
    <path fill="#CC8D60" d="M552.13,470c-0.87-2.61-1.88-5.17-2.74-7.78c-0.78-2.4-1.31-4.88-2.23-7.24
      c-1.67-4.25-4.96-8.64-8.63-11.59c-1.82-1.46-3.82-2.54-6.01-3.43c-2.14-0.87-4.44-1.28-6.54-2.24c-0.05-0.11-0.09-0.18-0.09-0.18
      s3.62,0.57,6.29,1.53c2.67,0.96,4.32,1.45,8.88,5.47c4.57,4.02,6.64,8.37,7.76,11.51c1.12,3.14,3.54,8.21,4.66,11.03
      c1.13,2.82,1.47,5.71,0.69,7.41c-0.58,1.26-1.26,2.52-1.96,3.79C552.84,475.55,553.03,472.76,552.13,470z"/>
    <path fill="#CC8D60" d="M530.86,451.39c0.02,0.05,0.04,0.1,0.08,0.14c1.21,1.27,2.62,1.99,4.34,2.34
      c-2.52,2.19-6.64,1.03-8.89-1.04c-2-1.83-3.38-4.06-4.98-6.19c-1.01-1.34-2.43-3.02-4.41-2.59c-2.1,0.45-2.87,3.93-3.21,5.5
      c-0.38,1.69-0.5,3.52-1.11,5.15c-0.61,1.59-1.56,3.04-2.36,4.55c-0.86,1.61-1.51,3.29-2.26,4.94c-1.41,3.12-3.28,6.72-6.64,7.96
      c0.36-1.32,0.7-2.33,0.7-2.33s1.09-0.91,1.82-2.16c0.73-1.25,1.77-2.67,2.92-5.12c1.16-2.45,2.2-7,5-12.75
      c2.8-5.74,5.89-7.45,7.15-8.02c1.26-0.58,3.47-0.43,3.47-0.43s3.24,5.49,4.66,7.2C527.98,449.58,529.47,450.65,530.86,451.39z"/>
    <path fill="#CC8D60" d="M536.64,477.33c1.44-0.37,2.99-1.09,3.39-2.35c0.65,1.41,0.35,3.06-1.07,4.14
      c-1.53,1.15-3.66,1.38-5.52,0.84c-2.56-0.75-4.71-2.43-6.17-4.53C529.45,477.61,533.11,478.24,536.64,477.33z"/>
    <path fill="#F0C095" d="M536.18,475.57c0.41-1.01,0.57-1.99,0.43-3.07c-0.11-0.78-0.44-2-1-3.13
      c2.2,1.01,4.13,2.58,4.82,4.78c-0.12,0.89-0.7,1.72-1.96,2.31c-1.27,0.62-2.68,1-4.11,1.23
      C535.15,477.2,535.8,476.47,536.18,475.57z"/>
    <path fill="#F0C095" d="M522.83,481.98c1.21,0.14,3.84,0.93,4.2,2.24c0.28,1.03-0.91,1.44-1.82,1.34
      c-0.63-0.07-1.23-0.27-1.82-0.48c-0.56-0.27-1.07-0.64-1.47-1c-0.31-0.3-0.89-0.83-0.74-1.31
      C521.39,482.11,522.16,481.9,522.83,481.98z"/>
    <path fill="#CC8D60" d="M529.23,496.96c-1.51,0.64-3.55,0.77-5.4,0.66c-0.09,0-0.16-0.01-0.25-0.02
      c0.04-0.99,0.08-1.96,0.1-2.49c1.39,0.03,2.78,0.02,4.16-0.08c0.46-0.02,0.92-0.06,1.39-0.1
      C529.23,495.29,529.22,496.08,529.23,496.96z"/>
    <path fill="#F0C095" d="M511.1,468.56c0.64,0.08,2.58,0.57,3.28,1.53c0.59,0.82-0.13,1.35-0.83,1.19
      c-0.81-0.2-2.18-0.6-2.83-1.1C509.76,469.45,509.06,468.3,511.1,468.56z"/>
    <path fill="#AE5D00" d="M522.18,461.88c0.49,0.3,1.41,1.32,1.43,3.31c0.01,1.99-0.85,2.23-1.2,2.57
      c-0.35,0.34-0.92,0.64-0.92,0.64l-2.11,0.01c0,0,1.76-1.83,1.07-3.56c-0.7-1.74-1.51-2.8-1.51-2.8S521.53,461.48,522.18,461.88z"/>
    <path fill="#AE5D00" d="M541.04,463.72c-0.04-0.51-0.16-1.31-0.31-1.5c-0.15-0.19-1.6-0.29-1.8-0.21
      c-0.21,0.08,0.05,0.74,0.02,1.72c-0.04,0.98-0.54,1.99-0.83,2.22c-0.3,0.24,0.52,0.19,1.27,0.19
      C540.59,466.14,541.17,465.24,541.04,463.72z"/>
    <path fill="#954E00" d="M520.41,464.72c-0.69-1.65-1.46-2.67-1.46-2.67s2.58-0.57,3.23-0.17c0.35,0.21,0.89,0.78,1.21,1.78
      C522.39,464.02,521.39,464.35,520.41,464.72z"/>
    <path fill="#954E00" d="M520.61,465.91c0.01-0.17,0.01-0.35-0.01-0.52c0.98-0.38,1.96-0.7,2.95-1.05
      c0.03,0.18,0.05,0.37,0.06,0.57C522.58,465.22,521.59,465.56,520.61,465.91z"/>
    <path fill="#954E00" d="M538.9,464.16c0.03-0.15,0.04-0.28,0.05-0.43c0.03-0.98-0.23-1.63-0.02-1.72
      c0.2-0.09,1.65,0.02,1.8,0.21c0.15,0.19,0.27,0.98,0.31,1.5c0.01,0.1,0.01,0.18,0.01,0.28
      C540.32,463.97,539.61,464.04,538.9,464.16z"/>
    <path fill="#FFFFFF" d="M527.87,463.11c-0.03-0.51-1.5-0.81-2.61-1.06c-1.12-0.26-2.39-0.4-2.78-0.42
      c-0.4-0.02-0.67-0.05-0.67-0.05s1.3,0.73,1.52,2.95c0.22,2.23-0.3,2.27-0.68,2.86c-0.37,0.57-1.34,0.97-1.61,1.21
      c-0.28,0.23,1.94-0.14,3.13-0.79c1.2-0.65,2.86-2.12,3.26-2.95C527.82,464.02,527.89,463.51,527.87,463.11z"/>
    <path fill="#FFFFFF" d="M545.45,462.51c-0.17,0.7-1.54,2.27-3.13,3.02c-1.59,0.74-2.57,0.74-2.57,0.74s1.15-1.19,1.15-2.32
      c0-1.14-0.3-1.85-0.3-1.85L545.45,462.51z"/>
    <path d="M519.18,468.86c-2.81,0.06-5.12-1.47-6.55-3.49c0-0.07-0.01-0.15-0.01-0.23c0-0.86,0.26-1.66,0.71-2.32
      c0.63-0.06,1.3-0.25,1.69-0.33c1.44-0.3,2.89-0.73,4.35-0.95c1.24,0.75,2.06,2.08,2.06,3.61
      C521.44,466.74,520.53,468.14,519.18,468.86z"/>
    <path d="M517.87,468.07c-2.24,0.04-4.09-1.18-5.24-2.79c0-0.06-0.01-0.13-0.01-0.18c0-0.68,0.21-1.33,0.57-1.86
      c0.5-0.04,1.05-0.2,1.35-0.27c1.15-0.23,2.3-0.58,3.48-0.76c0.99,0.61,1.65,1.67,1.65,2.89
      C519.68,466.37,518.95,467.48,517.87,468.07z"/>
    <path d="M538.23,466.4c-2.03-0.3-3.75-1.02-5.08-2.31c-0.15-0.25-0.29-0.52-0.39-0.81c-0.02-0.06-0.06-0.09-0.1-0.14
      c-0.06-0.35-0.11-0.7-0.18-1.04c0.04-0.13,0.1-0.25,0.17-0.37c1.1-0.06,2.21-0.29,3.31-0.33c1.01,0.11,2.03,0.27,3.02,0.22
      c0.31,0.56,0.5,1.21,0.5,1.91C539.49,464.7,538.99,465.7,538.23,466.4z"/>
    <path d="M536.92,465.84c-1.59-0.22-2.92-0.8-3.97-1.8c-0.12-0.19-0.22-0.41-0.3-0.64c-0.02-0.04-0.05-0.07-0.08-0.1
      c-0.05-0.27-0.09-0.54-0.15-0.82c0.04-0.09,0.08-0.19,0.13-0.29c0.86-0.04,1.73-0.22,2.59-0.26c0.79,0.08,1.59,0.21,2.37,0.17
      c0.24,0.44,0.38,0.95,0.38,1.5C537.9,464.51,537.52,465.3,536.92,465.84z"/>
    <path d="M527.54,475.19c3,1.46,4.73,1.17,7.3,0.85c1.3-0.15,3-0.9,3.77-1.94c1.18-1.58-2.3-3.91-3.56-5.25
      c-0.09-0.1,0.89,0,1.66,0.65c1.6,1.35,3.53,2.46,4.03,4.59c0.36,1.51-1.05,2.67-2.3,3.35c-2.81,1.52-7.6,1.16-10.32-0.59
      C526.89,476.05,527.22,475.04,527.54,475.19z"/>
    <path d="M517.46,474.55c1.84,2.5,3.63,4.6,6.16,6.56c1.86,1.45,5.74,4.09,7.51,1.23c0.04-0.07,0.15-0.03,0.13,0.05
      c-1.61,6.74-13.81-4.11-14.16-7.75C517.09,474.45,517.37,474.42,517.46,474.55z"/>
    <path d="M520.41,482.74c0.8,1.17,1.32,1.97,2.6,2.6c0.99,0.49,1.95,0.83,2.99,0.94c0.07,0.01,0.06,0.13,0,0.15
      c-1.35,0.72-3.08,0.56-4.32-0.31c-1.07-0.75-1.78-2.06-1.67-3.29C520.02,482.63,520.29,482.56,520.41,482.74z"/>
    <path d="M529.41,461.51c1.98-0.34,4.15-0.14,6.17-0.26c2.17-0.13,4.35-0.21,6.51,0.02c2.03,0.22,5.57,1.14,6.91-0.79
      c0.04-0.06,0.17-0.08,0.21,0c1.15,2.48-4.01,2.22-5.27,2.11c-1.89-0.16-3.73-0.36-5.64-0.33c-2.77,0.03-6.24,0.67-8.89-0.19
      C529.14,461.98,529.09,461.56,529.41,461.51z"/>
    <path d="M509.07,460.68c0.13-0.07,0.36-0.01,0.38,0.13c0.25,1.46,0.76,1.9,2.49,1.88c1.45-0.03,2.94-0.43,4.33-0.76
      c3.14-0.74,6.28-0.69,9.42,0.12c0.13,0.03,0.07,0.18-0.05,0.16c-2.81-0.53-5.31-0.39-8.02,0.38c-1.67,0.48-3.36,0.97-5.11,1.17
      c-1.26,0.16-2.5,0.09-3.51-0.62C508.05,462.48,508.13,461.24,509.07,460.68z"/>
    <path d="M537.24,453.7c3.87-0.29,8.42,3.39,8.79,6.95c0.01,0.1-0.13,0.1-0.18,0.02c-1.42-3.26-4.19-5.93-8.17-6.13
      c-3.62-0.2-4.85,3.03-5.46,5.72c-0.07,0.27-0.52,0.27-0.59,0C530.91,457.09,534.01,453.94,537.24,453.7z"/>
    <path d="M514.35,456.9c1.21-1.41,2.93-2.36,4.95-2.58c4.17-0.43,7.94,4.05,8.23,7.24c0.01,0.13-0.22,0.18-0.3,0.06
      c-1.72-2.75-3.24-6.23-7.44-6.52c-2.06-0.15-3.82,1.24-4.96,2.57c-1.06,1.23-1.47,2.63-2,4c-0.06,0.19-0.41,0.13-0.48-0.05
      C511.84,460.12,513.37,458.05,514.35,456.9z"/>
    <path d="M532.48,462.25c0.52,1.69,1.34,2.42,2.46,3.07c1.11,0.64,2.76,0.73,4.03,0.73c2.62-0.01,4.72-1.33,6.3-3.17
      c0.06-0.06,0.15,0.01,0.14,0.07c-0.58,2.57-3.95,3.72-6.41,3.79c-2.99,0.08-6.18-1.13-6.66-3.8
      C532.26,462.41,532.41,462,532.48,462.25z"/>
    <path d="M512.88,463.54c0.32,1.58,1.36,2.46,2.3,3.22c1.28,1.02,2.96,1.49,4.66,1.58c3.63,0.17,7.08-2.31,7.97-5.31
      c0.02-0.08,0.17-0.06,0.17,0.02c0.05,3.16-3.6,5.86-7.07,6.17c-3.54,0.33-7.91-1.42-8.63-4.65
      C512.03,463.46,512.82,463.15,512.88,463.54z"/>
    <path d="M522.17,462.02c-0.31-0.46,0.64,0.21,1.07,0.92c0.94,1.57,0.85,4.12-0.86,4.92c-0.14,0.07-0.52,0.16-0.42,0.06
      C524.12,466.02,523.05,463.32,522.17,462.02z"/>
    <path d="M540.74,464.39c0.13-0.55,0.14-1.28-0.07-1.96c-0.04-0.1,0.19,0.09,0.24,0.2c0.53,1.05,0.17,2.42-0.54,3.04
      c-0.11,0.09-0.43,0.21-0.34,0.1C540.38,465.36,540.63,464.87,540.74,464.39z"/>
    <path d="M534.47,446.53c2.68-2.41,7.25,1.22,7.57,4c0.03,0.16-0.2,0.2-0.3,0.11c-0.96-0.75-1.92-1.55-2.93-2.24
      c-1.16-0.8-2.69-1.14-4.08-1.28C534.43,447.08,534.21,446.75,534.47,446.53z"/>
    <path d="M515.51,449.54c0.76-1.16,1.89-2.38,3.6-2.44c0.54-0.03,1.75,0.18,1.27,0.62c-1.06,1-5.39,5.16-6.3,6.52
      c-0.09,0.13-0.35,0.09-0.33-0.09C513.99,452.39,514.49,451.07,515.51,449.54z"/>
    <path d="M501.85,478.62c3.69,4.99,7.3,9.17,12.4,12.01c4.05,2.25,9.29,3.52,13.91,3.25c9.69-0.59,18.97-7.29,23.59-15.23
      c0.11-0.19,0.43-0.06,0.37,0.14c-2.18,8.35-14.18,15.58-22.64,16.59c-0.07,1.86,0.16,3.09,0.22,4.94c0,0.18-0.45-0.01-0.63-0.47
      c-0.47-1.22-0.64-2.64-0.46-4.38c-1.49,0.13-2.87,0.15-4.21,0.06c0.01,0.44,0.06,3.36-0.1,4.58c-0.04,0.34-0.99,1.02-0.97,0.59
      c0.05-1.04,0.1-3.77,0.06-5.25c-2.62-0.29-5.1-1.02-7.83-2.27c-5.89-2.66-10.9-8.39-13.7-13.63
      C501.6,479.11,501.57,478.22,501.85,478.62z"/>
    <path d="M488.85,455.04c0.85-2.81,2.65-6.64,3.98-9.28c1.37-2.74,4.06-5.64,5.78-7c1.13-4.06,3.43-7.56,6.6-10.73
      c3.55-3.54,8.56-5.6,13.58-6.56c5.09-0.98,10.6-0.81,15.55,0.7c4.69,1.42,9.04,4.3,12.57,7.51c3.81,1.12,7.39,3.39,10.07,5.96
      c6.03,5.78,7.47,13.34,7.88,21.1c0.31,6.07,0.82,12.11-2.57,17.58c-1.12,1.8-2.81,3.39-5.12,3.61c-1.18,0.11-2.62-0.01-3.57-0.62
      c-1.43-0.93-1.28-2.19-1.24-2.92c0.04-0.73,0.49-1.02,0.47-0.84c-0.09,0.85-0.06,1.81,0.39,2.35c1.77-3.54-0.18-8.01-1.59-11.35
      c-1.63-3.86-2.57-7.85-4.55-11.61c-1.97-3.74-4.8-7.04-8.22-9.74c-3.65-2.89-7.89-4.41-12.48-5.46c0.83,2.44,1.85,4.51,3.33,6.83
      c1.68,2.62,3.83,6.79,6.84,6.63c0.23-0.01,0.22,0.31,0,0.33c-0.02,0-0.03,0-0.05,0c-1.57,1.42-4.19,1.2-5.99,0.38
      c-2.61-1.19-4.44-3.76-5.88-6.02c-0.83-1.3-1.63-2.69-2.36-4.12c-5.59-0.35-9.15,5.71-10.83,9.96c-2.5,6.24-4.24,13.33-8.83,18.55
      c-0.66,3.43-1.07,6.87-1.3,10.43c-0.02,0.25-0.37,0.29-0.45,0.06c-0.85-2.47-0.43-4.97-0.05-7.48c0.46-3.18,1.28-6.3,2.21-9.39
      c1.74-5.74,4.2-11.47,6.95-16.85c0.04-0.08,0.2-0.02,0.17,0.06c-1.93,5.5-4.19,10.86-5.87,16.44c-0.47,1.57-0.88,3.13-1.22,4.69
      c1.66-3.15,3.21-6.34,4.51-9.66c1.2-3.07,2.22-6.24,3.73-9.2c1.76-3.47,5.73-9.11,10.58-8.43c-1.61-3.34-2.84-6.92-3.29-10.41
      c-0.03-0.25,0.38-0.27,0.46-0.06c1.57,4.55,3.22,9.12,5.59,13.37c1.31,2.35,2.9,4.67,5.07,6.4c0.92,0.73,2.01,1.26,3.19,1.5
      c1.04,0.2,1.92-0.05,2.85-0.29c-2.42-0.5-4.34-3.19-5.48-4.85c-2.75-4.01-5.24-8.76-5.92-13c-0.02-0.13,0.2-0.18,0.25-0.07
      c0.58,1.28,1.11,2.57,1.64,3.86c9.63,0.72,17.33,8.13,21.76,15.59c2.28,3.82,3.21,8.13,4.98,12.14c1.47,3.35,3.09,7.93,0.69,11.19
      c1.35,1.05,3.87,0.89,5.36,0.03c2.1-1.22,3.22-3.79,4-5.85c1.92-5.04,1.2-10.56,0.83-15.78c-0.49-6.93-2.39-13.3-7.61-18.44
      c-5.12-5.04-13.75-8.41-20.84-5c-0.33,0.15,0.13-0.79,1.11-1.19c2.49-1.02,5.89-1.41,8.87-0.79c-3.28-2.22-6.43-4.51-10.3-5.91
      c-4.87-1.78-10.39-1.89-15.49-1.07c-4.92,0.79-9.56,2.83-13.19,6.03c-3.82,3.36-6.73,8.54-8.03,13.22
      c-0.07,0.22-0.06-1.53,0.12-2.23c-3.39,4.11-6.14,9.1-7.75,13.99c-1.49,4.48-2.39,9.98-2.36,14.68c0.03,4.37,2.32,7.94,5.5,11.13
      c1.24,1.23,4.54,4.13,7.13,4.6c0.66,0.12,1.94,0.09,2.72-0.6c0.38-0.33,0.08,0.65-0.34,0.95c-2.8,2.03-7.22-1.25-9.15-2.93
      c-3.41-2.97-6.35-6.15-6.95-10.54C486.58,465.32,487.3,460.15,488.85,455.04z"/>
    <path d="M502.33,475.45c0.09-0.18,0.3-0.22,0.49-0.19c1.03,0.14,1.85-0.67,2.39-1.4c0.06-0.08,0.19-0.05,0.19,0.05
      c0,0.67-0.2,1.23-0.77,1.66c-0.58,0.44-1.3,0.59-2.03,0.46c-0.19-0.03-0.32-0.22-0.32-0.39
      C502.28,475.57,502.3,475.51,502.33,475.45z"/>
    <path d="M498.14,432.8c1.09-5.38,5.91-9.21,11.17-11.42c0.15-0.06,0.32-0.07,0.45,0.05c0.6,0.58,1.07,1.09,1.45,1.81
      c0.16,0.32-0.28,0.54-0.49,0.27c-0.08-0.09-1.01-1.55-1.51-1.32c-0.63,0.3-1.22,0.7-1.82,1.05c-1.22,0.73-2.31,1.59-3.35,2.52
      c-2.07,1.83-3.63,4.13-4.53,6.65c0.41,0.07,1.17,0.26,1.49,0.59C501.39,433.43,498.03,433.36,498.14,432.8z"/>
    <path d="M487.27,401.57c1.52-0.87,3.42-1.14,5.12-0.68c1.46,0.39,2.7,1.41,4,2.17c0.76-1.85,3.34-3.71,5.4-3.74
      c-0.71-0.54-1.64-1.3-2.68-1.24c-0.6,0.03,3.66-2.27,6.47,1.24c3.17-1.53,4.74,2.77,5.15,5.37c0.37,2.23,0.18,4.32-0.53,6.49
      c-0.54,1.64-1.36,3.21-1.48,4.95c-0.1,1.63,0.8,3.14,0.14,4.73c-0.05,0.12-0.21,0.09-0.23-0.03c-0.33-2.32-1.07-4.61-0.47-6.93
      c0.63-2.42,2.02-5.17,1.95-7.72c-0.06-2.38-1.14-7.59-4.1-6.2c1.82,3.05,2,7.84,1.58,10.77c-0.02,0.16-0.26,0.16-0.26,0
      c0.04-2.87-0.74-7.95-1.78-10.03c-1.05-2.09-2.62-3.28-4.94-2.86c0.52,0.34,1.99,1.13,2.32,1.66c0.61,0.26,1.11,0.78,1.39,1.64
      c0.06,0.16-0.19,0.25-0.26,0.11c-1.73-2.72-6.57,0.1-6.87,2.49c-0.04,0.36-0.39,0.59-0.76,0.4c-1.16-0.57-2.21-1.33-3.35-1.96
      c-1.71-0.95-3.61-0.67-5.32,0.17c-3.29,1.62-4.39,5.36-5.33,8.43c-0.06,0.2-0.41,0.16-0.38-0.05
      C482.44,407.15,483.83,403.54,487.27,401.57z"/>
    <path d="M472.63,418.36c0.4-2.99,2.99-6.2,6.37-5c0.13,0.05,0.13,0.27-0.04,0.26c-4.09-0.34-5.26,3.74-5.82,6.75
      c-0.04,0.2-0.37,0.19-0.42,0c-0.13-0.46-0.17-0.97-0.14-1.48c-1.82,0.77-3.47,1.95-4.83,3.3c-0.84,0.82-1.44,1.84-1.55,2.98
      c-0.12,1.38,0.97,1.97,2.03,2.61c0.8,0.49,1.61,1.6,1.02,2.5c-1.42,2.14,0.44,4.29,1.93,5.97c1.52,1.7,3.24,3.31,5.51,4.1
      c2.45,0.85,5.19,0.52,7.64-0.13c2.64-0.7,4.96-2.01,6.99-3.71c1.9-1.59,3.61-3.37,5.88-4.5c0.12-0.06,0.26,0.1,0.15,0.19
      c-2.43,1.82-4.29,4.19-6.64,6.09c-2.11,1.71-4.97,2.79-7.68,3.36c-2.37,0.5-5.06,0.48-7.32-0.43c-2.5-1.01-4.4-2.94-6.07-4.9
      c-1.05-1.23-2.85-3.6-2.16-5.32c0.48-1.21,1.18-1.87-0.31-2.75c-0.69-0.42-1.47-0.97-1.7-1.76
      C464.33,422.47,469.02,419.42,472.63,418.36z"/>
    <path d="M486.26,400.27c0.11-0.02,0.16,0.12,0.05,0.16c-4.26,1.21-5.5,6.77-5.31,10.35c0.21,3.95,2.45,7.15,6.14,9
      c0.27,0.14,0.08,0.48-0.19,0.43c-4.68-0.83-6.75-5.45-7.04-9.43C479.62,406.95,481.51,400.9,486.26,400.27z"/>
    <path d="M477.34,429.62c8.38,2.87,15.07-6.25,23.51-3.94c0.61,0.17-0.69,0.54-1.31,0.47c-6.84-0.82-12.26,4.8-18.68,4.57
      C477.7,430.6,477.24,429.59,477.34,429.62z"/>
    <path d="M484.69,421.2c3.88,3.32,6.37,2.88,9.62,2.78c2.42-0.08,5.33-0.75,7.22,1.06c0.07,0.06-0.03,0.17-0.11,0.12
      c-2.69-1.81-6.16-0.43-9.19-0.3c-2.67,0.11-4.99-0.72-6.84-2.51C485.2,422.17,484.49,421.02,484.69,421.2z"/>
    <path d="M488.78,448.61c0.03-0.07,0.16-0.02,0.13,0.05c-1.49,4.21-3.07,8.46-3.88,12.83c-0.88,4.83-0.4,9.51,0.89,14.23
      c0.05,0.2-0.26,0.35-0.37,0.15C481.08,466.8,484.88,457.19,488.78,448.61z"/>
    <path d="M509.01,545.6c0.42-3.1,0.72-6.21,0.9-9.33c0.24-3.84,0.51-7.68,0.89-11.52c0.28-2.81-0.08-3.52,1.12-6.13
      c0.59-1.3,5.57-8.18,6.57-9.59c1.72-2.45,3.52-6.59,3.54-8.47c0.02-0.98,1.25-1.57,1.17-1.06c-0.04,0.24-0.59,0.33-0.48,0.74
      c0.12,0.41,1.18,1.4,3.9,1.37c2.72-0.04,4.25-1.91,4.3-1.65c0.08,0.51,0.55,1.49,0.71,1.95c0.17,0.45,2.68,5.14,4.42,7.94
      c1.81,2.94,3.35,5.62,5.56,8c1.61,1.72,3.62,3.56,3.87,4.02c1.22,2.26,3.33,16.74,3.65,20.19c0.26,2.85,0.7,5.94,0.28,8.79
      c-0.32,2.11-1.06,4.14-3.31,4.98c-2.22,0.84-4.87-0.38-7.06-0.68c-0.35-0.05-0.25-0.58,0.09-0.57c2.14,0.03,4.56,0.71,6.65,0.16
      c2.95-0.79,2.62-5.6,2.51-7.76c-0.32-6.06-3-23.83-3.93-24.86c-1.7-1.91-4.06-4.46-5.55-6.54c-1.57-2.17-2.78-4.46-4.21-6.73
      c-1.18-1.87-2.09-3.7-2.88-5.69c0.35,1.95,0.69,3.9,1.34,5.83c1.08,3.19,2.68,6.18,4.4,9.1c0.11,0.19-0.42,0.03-1.16-1.15
      c-1.76-2.76-2.62-4.18-3.75-7.21c-1.08-2.88-1.76-5.69-1.92-8.86c-1.61,1.41-3.93,1.72-6.22,1.27c-0.66-0.13-1.32-0.49-1.83-0.96
      c-0.17,3.62-2.77,7.55-4.75,10.73c-1.68,2.71-4.3,6.11-4.96,7.25c-0.65,1.13-1.45,6.24-1.74,12.54c-0.17,3.48-0.22,6.97-0.54,10.44
      c-0.2,2.09-0.51,4.16-0.69,6.27c-0.3,3.71,0.47,8.48,4.14,9.22c3.66,0.74,7.57-0.65,11.3-1.62c3.79-0.99,8.53-1.07,12.41-0.38
      c0.14,0.03-0.32,0.29-1.14,0.21c-1.85-0.16-3.97-0.16-5.82,0.05c-2.3,0.26-4.16,0.89-6.3,1.48c-2.84,0.78-5.98,1.37-8.94,1.23
      c-2.03-0.09-3.63-0.31-5.04-1.78c-1.27-1.31-1.66-3.15-1.83-4.85C508.43,549.85,508.72,547.7,509.01,545.6z"/>
    <path d="M519.61,556.37c4.67-2.44,10-3.6,15.32-2.8c0.12,0.02,0.08,0.19-0.03,0.19c-5.27,0.24-10.14,1.52-15.11,3.02
      C519.53,556.86,519.37,556.5,519.61,556.37z"/>
    <path d="M543.98,514.18c0.84,2.01,1.18,3.33,1.27,5.52c0.01,0.33-0.25,1.34-0.28,1.03c-0.23-1.99-0.65-5.25-1.55-7.16
      C543.21,513.08,543.71,513.52,543.98,514.18z"/>
    <path d="M532.72,502.93c2.53,1.8,5.31,1.61,7.48,2.03c1.48,0.28,3.03,0.79,4.22,1.67c3.12,2.32,4.19,6.62,5.13,10.03
      c1.45,5.29,1.75,10.91,2.63,16.31c0.91,5.52,1.58,11,1.09,16.61c-0.17,2.01-0.81,6.93-1.91,9.65c-0.53,1.31-1.45,2.42-1.34,2.06
      c1.06-3.38,2.24-8.53,2.56-11.84c0.5-5.24-0.55-10.37-1.39-15.57c-0.37-2.36-0.79-4.71-1.12-7.08c-0.4-2.9-0.58-5.82-1.2-8.68
      c-0.81-3.69-2-8.9-5.62-11.12c-3.15-1.94-6.85-0.97-9.88-3.25C532.96,503.44,532.63,502.86,532.72,502.93z"/>
    <path d="M504.66,546.48c0.4-6.8,0.66-13.62,1.12-20.41c0.2-2.94,0.26-5.85,0.32-8.79c0.04-2.23,0.2-4.76,1.53-6.69
      c2.7-3.9,8.82-3.38,12.36-6.16c0.14-0.1,0.33,0.07,0.2,0.18c-1.54,1.36-3.12,2.14-5.12,2.87c-2.34,0.85-4.97,1.64-6.54,3.61
      c-1.63,2.02-1.38,5.34-1.39,7.73c-0.02,2.86-0.18,5.71-0.35,8.58c-0.37,6.16-0.51,12.32-0.88,18.48
      c-0.38,6.11-0.07,13.08,1.91,18.96c0.11,0.35-0.74,0.3-1.79-3.03c-0.43-1.39-0.92-4.25-1.08-5.68
      C504.58,552.9,504.47,549.69,504.66,546.48z"/>
    <path d="M484.83,584.84c5.87-1.89,10.66-6.34,14.92-10.37c4.93-4.66,9.73-9.98,12.32-16.14c0.1-0.21,0.32,0.33,0.04,1.08
      c-2.28,6.14-6.51,10.93-11.15,15.46c-4.33,4.24-9.23,8.81-15.22,10.91c0.84,1.54,3.73,2.04,5.28,2.07c0.78,0.02,1.63-0.39,2.4-0.5
      c0.64-0.1,1.32,0.09,1.92,0.26c0.05,0.01,0.1,0.03,0.15,0.04c0,0,0,0,0.01,0c1.39-0.25,2.67-2.06,3.47-3.01
      c1.39-1.64,2.62-3.41,3.85-5.15c3.29-4.63,7.25-8.79,9.32-14.09c0.04-0.11,0.29,0.49-0.44,1.96c-2.66,5.32-5.22,8.28-8.49,13.16
      c-1.25,1.86-2.54,3.71-4.03,5.42c-0.69,0.8-1.54,1.75-2.56,2.15c0.2,0.09,0.4,0.17,0.59,0.24c0.93,0.35,1.97,0.25,2.95,0.15
      c1.91-0.17,3.76-0.77,5.67-0.94c0.28-0.02,0.54-0.03,0.81-0.04c1.84-0.27,2.92-2.05,3.55-3.07c1.44-2.29,2.4-4.89,3.27-7.41
      c1.43-4.16,2.84-8.5,3.6-12.81c0.02-0.1,0.32,0.12,0.04,1.66c-0.91,4.96-1.78,8.32-3.42,13.13c-0.67,1.95-2.26,7.12-4.81,8.59
      c0.94,0.08,1.86,0.2,2.8,0.28c1.13,0.11,2.28,0.05,3.4-0.05c3.92-0.57,5.5-7.58,6.23-10.74c0.88-3.84,1.46-7.89,1.52-11.82
      c0.01-0.21,0.4,0.44,0.33,2.24c-0.17,4.35-0.43,6.55-1.32,10.82c-0.51,2.46-1.55,7.54-4.3,9.3c1.48-0.09,2.93-0.02,4.39,0.19
      c0.94,0.14,2.19,0.37,3.04,0.42c2.02-1.84,2.65-3.41,3.03-4.56c0.68-2.06,0.9-4.25,1.25-6.38c1-5.84,1.82-11.76,0.6-17.64
      c-0.03-0.09,0.39,0.11,0.6,1.51c0.93,6.23,0.28,10.82-0.53,16.97c-0.3,2.35-0.54,4.69-1.41,6.94c-0.33,0.84-1.01,2.1-1.92,2.98
      c0.26-0.04,0.52-0.11,0.79-0.2c1.53-0.48,2.94-1.26,4.52-1.62c1.46-0.33,4.53,0.15,5.14,0.19c0.71-1.78,0.74-3.57,0.86-5.38
      c0.17-2.21-0.01-4.44-0.24-6.64c-0.45-4.31-1.03-6.55-1.66-10.84c-0.28-1.93,0.03-2.15,0.05-2.05c0.62,4.32,1.74,8.57,2.34,12.89
      c0.3,2.21,0.49,4.41,0.48,6.64c-0.02,1.7-0.08,3.96-0.98,5.51c1.45,0.23,2.9,0.45,4.38,0.37c1.95-0.09,3.76-1.15,5.66-1.49
      c1.48-0.29,2.96-0.18,4.45,0.03c-0.27-2.17-3.74-8.63-4.75-10.74c-1.83-3.78-2.58-5.48-3.93-9.46c-0.63-1.85-0.37-2.36-0.34-2.26
      c1.12,4.17,3.02,8.15,4.93,12.05c0.95,1.95,4.69,8.9,4.78,10.51c1.41,0.34,3.23,0.65,4.84,0.79c2.02,0.19,3.93-0.42,5.91-0.68
      c1.43-0.2,2.8-0.12,4.19,0.13c-1.46-1.76-4.1-2.91-5.85-4.28c-2.65-2.08-5.41-4.01-7.58-6.58c-2.17-2.57-3.5-5.5-4.71-8.55
      c-1.21-3.02-2.7-7.16-3.22-10.38c-0.02-0.13,0.49,0.16,0.73,0.84c1.06,2.96,1.98,5.64,3.12,8.55c1.4,3.57,3.22,7.13,5.95,9.99
      c2.15,2.25,4.89,4.24,7.49,6.02c1.89,1.29,4.41,2.5,5.2,4.63c0.4,0.23,0.15,0.9-0.38,0.79c-4.11-0.78-7.88,0.97-11.97,0.49
      c-1.58-0.18-3.13-0.54-4.71-0.79c-2.34-0.36-4.39,0.01-6.59,0.74c-3.62,1.21-6.77,0.4-10.46-0.05c-3.51-0.41-6.09,1.81-9.43,2.12
      c-1.7,0.15-3.42-0.43-5.09-0.61c-1.86-0.2-3.74,0.04-5.59,0.2c-1.98,0.16-3.86,0-5.83-0.22c-2.23-0.25-4.29,0.15-6.47,0.55
      c-1.54,0.3-3.1,0.52-4.68,0.46c-1.92-0.08-3.15-1.58-5.21-1.01c-2.72,0.74-6.25,0.05-7.88-2.4
      C484.34,585.56,484.57,584.92,484.83,584.84z"/>
    <path d="M542.93,627.58c1.35-4.35,2.42-8.45,2.08-13.02c-0.34-4.42-1.7-8.69-2.44-13.05c-0.67-3.97-0.82-9.41-0.15-13.39
      c0.02-0.11,0.22-0.11,0.24,0c0.73,4.43,0.29,9.95,1.17,14.39c0.84,4.23,2.05,8.37,2.4,12.67c0.35,4.4-0.84,8.5-2.12,12.7
      c-0.64,2.1-1.19,4.21-1.4,6.4c-0.18,1.91-0.2,4.18-1.34,5.83c-0.19,0.28-0.6,0.09-0.54-0.21
      C541.49,635.89,541.71,631.52,542.93,627.58z"/>
    <path d="M531.46,640.2c1.02-1.76,2.01-4.47,2.97-9.15c0.65-3.23,0.98-6.52,0.95-9.81c-0.06-6.26-1.43-12.38-2-18.59
      c-0.74-0.79-1.24-1.73-1.46-3.18c-0.22-1.58,0.03-3.21,0.12-4.8c0.11-2.01-0.05-4.9,0.1-6.9c0.01-0.16,0.23-0.18,0.3-0.04
      c1.18,2.65,0.52,7.06,0.44,9.86c-0.03,1.57,0.1,3.33,1.27,4.53c0.82,0.85,1.72,1.5,2.42,2.46c0.12,0.16-0.13,0.41-0.31,0.29
      c-0.78-0.55-1.48-1.02-2.09-1.51c1.45,6.03,2.28,12.32,2.34,18.47c0.04,3.2-0.26,6.38-0.94,9.51c-0.57,2.62-1.61,6.09-3.17,8.39
      C532.18,640.07,531.29,640.49,531.46,640.2z"/>
    <path d="M522.2,631.01c0.36-2.16,0.23-4.12,0.38-6.3c0.28-3.81,0.32-7.64,0.31-11.45c-0.03-7.61-0.72-16.01-0.15-23.6
      c0.03-0.32,0.51-0.32,0.54,0c0.59,7.59,0.69,15.99,0.75,23.6c0.03,3.82-0.05,7.64-0.28,11.45c-0.22,3.74-1.05,9.36-1.1,13.1
      c0,0.7-0.72-0.03-0.75-1.46C521.72,634.77,521.94,632.58,522.2,631.01z"/>
    <path d="M514.23,589.34c0.05-0.19,0.32-0.14,0.35,0.04c0.36,3.41-1.03,7.8-1.43,11.18c-0.45,3.81-0.03,7.7,0.48,11.48
      c0.52,3.81,1.33,7.58,2.14,11.34c0.7,3.23,1.23,7.05,0.2,10.22c-0.08,0.24-0.5,1.05-0.49,0.79c0.07-3.46-0.4-8.57-1.14-11.97
      c-0.8-3.65-1.52-7.33-2-11.02C511.42,604.32,512.15,596.21,514.23,589.34z"/>
    <path d="M517.77,602.62c0.63,0.1,1.24,0.23,1.88,0.27c0.52,0.03,0.98-0.14,1.48-0.25c0.14-0.03,0.3,0.09,0.25,0.23
      c-0.54,1.45-2.98,0.95-3.83,0.1C517.4,602.81,517.56,602.59,517.77,602.62z"/>
    <path d="M508.15,654.13c1.21,3.41,5.05,3.92,8.5,3.36c0.43,0.58,0.9,1.1,1.41,1.51c4.32,3.58,10.15,3.33,14.65,0.18
      c4.07-2.85,6.45-8.12,10.71-10.84c0.75-1.7,0.57-4.07,0.01-5.8c-0.5-1.58-2.48-2.33-2.64-1.92c-0.68,1.7-1.6,3.9-3.74,4.42
      c-1.43,0.35-2.47-0.46-3.25-1.5c-0.54-0.73-2.34-3.45-2.82-2.65c-0.23,0.37-2.7,4.18-6.11,4.7c-0.48-1.05-1.11-2-1.35-3.17
      c-0.27-1.32-0.61-3.43-0.61-3.73c0-0.31-0.47-0.22-0.52-0.07c-0.47,1.51-2.59,2.49-4.48,1.39c-1.78-1.04-2.34-3.87-2.34-4.21
      c0-0.35-0.69-0.08-0.78,0.25c-0.09,0.33-1.23,5-2.66,7.32c-0.79,1.28-1.91,2.61-2.1,4.09c-0.27,0.21-0.52,0.47-0.73,0.79
      C508.26,649.8,507.5,652.29,508.15,654.13z M515.91,651.79c0-3.31,2.58-4.33,5.7-4.69c2.1-0.24,4.57-0.36,6.33-1.6
      c1.45-1.02,2.2-2.36,3.21-3.69c0.38,0.61,0.81,1.29,1.29,1.92c-0.2,0.32-0.28,0.67-0.45,1.05c-0.32,0.7-0.65,1.39-1.09,2.04
      c-0.88,1.27-2.05,2.23-3.54,2.86c-0.08,0.03-0.02,0.16,0.06,0.13c1.65-0.49,2.92-1.37,3.99-2.62c0.56-0.67,1.35-1.75,1.64-2.74
      c0.6,0.65,1.25,1.19,1.99,1.47c3.15,1.16,5.68-2.22,6.03-4.83c2.21,1.15,2.29,4.77,1.46,6.83c-3.47,2.47-5.59,6.1-8.7,8.92
      c-3.98,3.62-10.42,5.46-14.89,1.34C517.15,656.55,515.91,654.16,515.91,651.79z M510.72,648.18c1.07-0.88,2.56-1.34,3.91-1.75
      c0.11-0.04,0.06-0.19-0.05-0.17c-1.08,0.16-2.25,0.22-3.28,0.56c0.59-1.73,1.83-2.99,2.48-4.52c0.47-1.11,0.96-2.39,1.23-3.67
      c0.58,1.15,1.74,2.15,2.88,2.59c1.83,0.71,3.79,0.19,4.44-1.35c0,1.96,0.45,4.48,1.68,5.89c-2.43,0.35-5.71,0.24-7.64,1.9
      c-2.52,2.17-2.04,5.98-0.39,8.83c-2.77,0.31-6.41-0.46-6.92-3.44C508.79,651.46,509.36,649.28,510.72,648.18z"/>
    <path d="M540.41,651.33c0.07-0.01,2.14-0.23,2.22-0.26c0.06-0.03,0.11-1.93,0.11-2.08c0-0.15,0.45,0.14,0.5,0.33
      c0.22,0.81,0.25,1.68,0.27,2.48c0,0.3-3.29,0.29-3.45,0.32C539.89,652.15,540.34,651.35,540.41,651.33z"/>
    <path fill="#FFFFFF" d="M515.59,462.98c0.84,0,1.51,0.63,1.51,1.43c0,0.78-0.67,1.42-1.51,1.42c-0.85,0-1.53-0.64-1.53-1.42
      C514.06,463.61,514.74,462.98,515.59,462.98z"/>
    <path fill="#FFFFFF" d="M517.53,465.33c0.38,0,0.69,0.29,0.69,0.65c0,0.35-0.31,0.64-0.69,0.64c-0.38,0-0.69-0.29-0.69-0.64
      C516.84,465.63,517.15,465.33,517.53,465.33z"/>
    <path fill="#FFFFFF" d="M534.71,462.18c0.55,0,1,0.42,1,0.94c0,0.52-0.45,0.95-1,0.95c-0.56,0-1.01-0.42-1.01-0.95
      C533.7,462.6,534.15,462.18,534.71,462.18z"/>
    <path fill="#FFFFFF" d="M536,463.74c0.24,0,0.44,0.19,0.44,0.42c0,0.24-0.2,0.43-0.44,0.43c-0.26,0-0.46-0.19-0.46-0.43
      C535.54,463.93,535.74,463.74,536,463.74z"/>
    <path fill="#F0C095" d="M544.97,509.82c-0.54-1.03-3-3.17-4.66-2.94C538.51,507.11,546.17,512.09,544.97,509.82z"/>
    <path fill="#F0C095" d="M510.4,510.36c-1.06,0.32-2.5,2.21-2.43,4C508.08,517.07,512.32,509.77,510.4,510.36z"/>
    <path fill="#F0C095" d="M521.07,601.17c-0.65,1.16-3.04,0.85-3.46-0.16C516.89,599.26,522.1,599.34,521.07,601.17z"/>
  </g>
  <g id="item_5_">
    <path fill="#FFCA4C" d="M432.73,541.19l-0.72-1.45h-4.69l-0.19,2.99l2.25,13.01l1.27,14.74l0.12,3.48l1.51-0.1l-0.47,4.14
      c0,0,2.93-0.29,8.78-0.67c5.85-0.39,11.9-1.44,12.78-5.1c0.88-3.66-0.09-7.04-1.76-12.14c-1.67-5.09-3.8-12.42-5.27-15.12
      c-1.47-2.7-1.86-4.14-4.49-4.62c-2.63-0.48-4.58-0.19-6.15,0.19C434.16,540.9,432.73,541.19,432.73,541.19z"/>
    <path fill="#E3B62B" d="M434.87,573.66c0.19-1.2,0.15-2.4,0.15-3.6c0-0.06-0.01-0.11-0.04-0.15c0.06-0.07,0.1-0.16,0.1-0.25
      c0-0.11,0-0.23,0.01-0.34c0.66,1.85,1.13,3.76,1.08,5.72c-0.01,0.42-2.81,0.89-4.16,1.15l0.26-2.33c0.74,0.02,1.47,0.07,2.21,0.08
      C434.65,573.95,434.85,573.83,434.87,573.66z"/>
    <path fill="#E3B62B" d="M432.97,545.47c0.02,2.72,0.17,5.44,0.18,8.16c0,0.16-0.1,0.31-0.25,0.37
      c1.42,0.7,2.76,1.8,3.32,2.48c2.45,2.96,2.54,7.54-1.18,9.35c0.09,0.6,0.1,1.21,0.1,1.8c2.35-1.6,4.22-2.45,7.14-1.85
      c1.43,0.29,2.72,0.66,4.2,0.6c1.59-0.05,3.11-0.8,3.2-2.64c0.04-0.67-0.27-1.42-0.56-2.11c-0.09-0.05-0.16-0.13-0.2-0.25
      c-0.5-1.94-2.14-3.22-3.57-4.52c-1.6-1.46-3.07-3.06-4.6-4.59c-1.46-1.46-3.09-2.74-4.57-4.18c-1.04-1.01-2.02-2.23-3.23-3.09
      C432.97,545.17,432.97,545.32,432.97,545.47z M435.72,552.3c0.66-0.38,1.43,0.25,1.92,0.63c1.15,0.92,2.11,2.18,3.09,3.26
      c1.41,1.58,2.72,3.24,3.73,5.09c0.19,0.35-0.19,0.66-0.48,0.58c-1.58,1.76-3.64,0.08-4.86-1.35c-0.69-0.81-1.17-1.76-1.76-2.63
      c-0.78-1.13-1.58-2.25-2.07-3.55C435.05,553.7,435.06,552.68,435.72,552.3z"/>
    <path fill="#E3B62B" d="M429.26,554.86c0.51-1.19,3.01-0.44,3.85-0.02c1.31,0.66,2.53,1.89,3.19,3.2
      c1.37,2.73,1.27,5.79-1.81,7.19c-0.13,0.06-0.19,0.14-0.2,0.24c-0.05,0.08-0.07,0.17-0.05,0.28c0.22,1.16,0.1,2.35,0.06,3.52
      c-1.21,0.01-2.41,0.01-3.62,0.07c-0.05,0.01-0.08,0.02-0.13,0.03l-1.17-13.64l-0.14-0.83
      C429.24,554.89,429.26,554.88,429.26,554.86z"/>
    <path fill="#FFDE8F" d="M447.68,549.7c-1.03-1.97-1.99-4.24-3.75-5.72c-1.49-1.25-3.22-1.95-5.17-2.16
      c-0.95-0.1-1.93-0.19-2.88-0.03c-0.86,0.13-3.03,0.89-3.44-0.36c-0.07-0.22-0.27-0.3-0.49-0.28c-1.59,0.22-3.14,0.5-4.74,0.48
      l0.12-1.89h4.69l0.72,1.45c0,0,1.43-0.29,2.99-0.68c1.56-0.39,3.51-0.67,6.15-0.19c2.63,0.48,3.02,1.93,4.49,4.62
      c0.85,1.58,1.94,4.75,3.03,8.12C448.82,551.93,448.25,550.82,447.68,549.7z"/>
    <path fill="#F059A6" d="M408,481.36c1.17-0.87-0.37-6.11-0.61-7.55c-0.24-1.44,0.85-6.35,0.61-8.51
      c-0.25-2.16-0.61-5.87-2.43-5.51c-1.82,0.37-3.28,2.4-4.5,4.44c-1.21,2.03-2.06,4.43-2.55,6.11c-0.48,1.68-1.46,4.19-1.46,4.19
      s-2.43-1.31-4.37-0.59c-1.94,0.72-2.67,1.79-2.91,2.51c-0.24,0.72-0.61,1.44-0.61,1.44s-1.82-1.2-2.67-2.87
      c-0.85-1.68-3.52-5.03-5.22-5.63c-1.7-0.61-4.79-0.35-4.98,1.19c-0.18,1.54,0.24,5.39,0.61,8.03c0.36,2.64,0.12,10.66,1.46,12.22
      c1.33,1.56,4.01,1.56,5.46,0.6c1.46-0.96,2.07-2.88,3.16-5.04c1.09-2.16,2.31-4.43,2.31-4.43s3.04,3.35,6.43,1.43
      c3.39-1.92,2.67-5.52,2.67-5.52s4.97,3.36,6.31,3.71C406.05,481.97,406.54,482.44,408,481.36z"/>
    <path fill="#FFCA4C" d="M403.62,538.58c0,0-3.51-1.16-3.8-0.09c-0.29,1.05-0.39,1.63-0.29,3.17c0.1,1.54-2.15,1.93-4,2.12
      c-1.85,0.2-6.34,0.2-7.12-0.19c-0.78-0.39-1.46,0.77-2.14,1.83c-0.69,1.06-1.17,2.8-0.88,4.82c0.29,2.02,0.19,8.09,0.58,11.37
      c0.39,3.27,0.88,8.58,1.37,11.65c0.49,3.08,0.29,5.49,3.22,6.07c2.93,0.58,9.42,0.22,9.42,0.22l1.4-15.24c0,0,2.15-12.81,2.15-13.1
      c0-0.29-0.58-4.53-0.29-7.03C403.52,541.66,403.62,538.58,403.62,538.58z"/>
    <path fill="#E3B62B" d="M397.97,560.44c0.72,0.11,2.05,0.16,2.76,0.32c-0.33,1.99,0.17,3.21,0.17,3.21l-0.31,3.24
      c-0.75,0-2.22-0.05-2.97-0.06C397.64,566.97,397.82,562.54,397.97,560.44z"/>
    <path fill="#E3B62B" d="M385.61,554.7c0.07,2.37,0.13,5.07,0.35,6.9c0.09,0.76,0.19,1.63,0.29,2.54
      c0.5-0.02,1.01-0.07,1.54-0.18c1.34-0.27,2.65-0.74,3.97-1.06c0.19-0.05,0.45,0.06,0.5,0.27c0.31,1.8,0.97,3.04,2.72,3.82
      c0.15,0.07,0.24,0.29,0.18,0.44c-0.69,1.74-1.04,3.73-1,5.59c0.02,1.5,1.66,1.34,2.76,1.41c1.19,0.07,2.3,0.42,3.48,0.48l0.26-2.82
      c-1.1,0.01-2.19,0.02-3.28-0.14c-0.2-0.03-0.35-0.3-0.28-0.49c0.37-0.95,0.62-1.94,0.78-2.94c-0.1-0.06-0.19-0.17-0.19-0.32
      c-0.08-1.62,0.04-3.12,0.61-4.65c0.07-0.19,0.24-0.27,0.41-0.27c0.3-2.18,0.42-4.4,0.46-6.61c-0.11-0.05-0.2-0.17-0.18-0.34
      c0.33-3.06,0.79-6.12,0.82-9.21c0.02-2.29-0.18-4.6-0.31-6.9c-0.02,0.38-0.01,0.83,0.03,1.41c0.1,1.54-2.15,1.93-4,2.12
      c-1.85,0.2-6.34,0.2-7.12-0.19c-0.78-0.39-1.46,0.77-2.14,1.83c-0.03,0.05-0.07,0.12-0.1,0.17c2.04,0.91,4.52,0.39,6.72,1.1
      c0.22,0.07,0.4,0.37,0.23,0.59c-1.73,2.34-4.06,4.39-6.27,6.29C386.43,553.92,386.02,554.31,385.61,554.7z M387.17,558.14
      c0.53-1.59,1.9-2.58,3.09-3.69c1.88-1.74,3.9-3.32,5.93-4.89c0.06-0.04,0.12-0.06,0.18-0.08c0.13-0.12,0.32-0.11,0.47-0.04
      c0.7,0.3,0.68,1.93,0.79,2.59c0.14,0.88,0.08,1.69-0.38,2.46c-0.57,0.97-2.01,1.3-2.98,1.67c-2.19,0.84-4.38,1.7-6.6,2.47
      C387.38,558.72,387.08,558.42,387.17,558.14z"/>
    <path fill="#F29FBF" d="M403.84,464.76c-1.04,1.39-1.53,3.08-2.59,4.46c-0.91,1.18-2.05,2.07-3.29,2.86
      c0.21-0.59,0.41-1.21,0.56-1.74c0.49-1.68,1.33-4.08,2.55-6.11c1.22-2.04,2.67-4.07,4.5-4.44c1.45-0.29,1.98,2.04,2.26,4.08
      C406.73,462.56,405.06,463.14,403.84,464.76z"/>
    <path fill="#F29FBF" d="M377.78,473.77c-0.49,0.6-0.9,1.27-1.21,2c-0.22-2.06-0.38-4.14-0.26-5.19
      c0.19-1.55,3.28-1.8,4.98-1.19c1.7,0.6,4.37,3.95,5.22,5.63c0.09,0.17,0.18,0.34,0.29,0.5
      C384.31,473.23,380.59,470.32,377.78,473.77z"/>
    <path fill="#F29FBF" d="M393.43,483.99c-1.69-0.05-3.08-1.08-3.73-1.65c-0.35-1.62-0.25-3.44-0.23-5.06
      c0.1-0.25,0.22-0.53,0.32-0.82c0.24-0.72,0.97-1.8,2.91-2.51c1.51-0.56,3.31,0.11,4.04,0.44c1,1.34,1.68,3.05,1.71,4.74
      c-0.04,0.55-0.15,1.22-0.47,1.91c0.17-0.69,0.08-1.42-0.44-2.14c-0.08-0.11-0.19-0.16-0.29-0.17c-0.66-1.12-1.74-1.99-3.06-2.18
      c-2.16-0.31-3.74,2.08-3.48,4.02C390.91,482.07,391.95,483.62,393.43,483.99z"/>
    <path fill="#F08335" d="M414.31,481.36c0,0-1.7-1.8-3.15-2.87c-1.45-1.08-3.04-1.92-3.04-1.92s1.22,3.84-0.61,4.91
      c-1.82,1.07-3.15,0-4.25-0.72c-1.09-0.72-4.36-2.88-4.36-2.88s-1.34,5.39-4.61,5.76c-3.28,0.36-4.37-1.92-4.37-1.92
      s-2.31,2.51-2.92,4.67c-0.61,2.16-3.03,4.92-5.22,5.4c-2.18,0.48-3.64-0.12-4.37-2.64c-0.73-2.51-0.85-8.87-1.21-10.78
      c-0.37-1.92-0.73-5.39-0.73-5.39s-7.53-1.92-13.48,2.4c-5.95,4.31-6.67,8.14-7.04,11.62c-0.36,3.47,0,5.75,0.97,9.71
      c0.97,3.95,0.25,8.51-2.3,9.47c-2.55,0.96-8.37,0.84-11.65,2.63c-3.28,1.8-6.09,5.51-4.98,9.83c1.11,4.31,3.76,5.87,6.31,7.55
      c2.55,1.68,6.31,2.03,6.55,4.79c0.24,2.76-3.39,5.27-3.39,8.27c0,2.99,4.13,8.74,11.16,8.98c7.05,0.24,11.66-0.48,14.57-2.28
      c2.91-1.8,4.13-3.71,6.55-4.19c2.43-0.48,6.92,2.16,10.45,2.52c3.52,0.36,5.9,0.83,8.37-1.8c2.48-2.62,2.67-6.95,1.7-8.63
      c-0.97-1.67-1.94-2.63-4.85-3.35c-2.91-0.72-6.43-2.04-6.92-3.6c-0.49-1.56-1.58-3.24,0.36-4.44c1.94-1.2,4.5-1.56,4.98-3.36
      c0.49-1.79-0.73-5.51-1.21-7.18c-0.48-1.68,1.33-4.44,3.27-5.99c1.94-1.56,3.4-3.72,2.91-6.59c-0.48-2.88-1.21-6.47,0.25-9.11
      c1.45-2.63,2.43-3.59,4.73-3.71c2.31-0.13,5.22,1.32,7.77,1.32c2.55,0,3.52-0.96,4.73-2.63c1.21-1.68,2.07-2.28,3.4-1.8
      c1.34,0.48,1.1,1.68,3.16,2.52c2.07,0.84,4.98,1.56,5.83,3.71c0.85,2.16,0.24,4.31,2.43,6.35c2.18,2.04,5.01,3.81,5.01,3.81
      s0.82,3.02,0.82,5.42c0,2.4,0.61,5.63,2.67,7.55c2.07,1.92,4.86,6.83,6.07,10.43c1.21,3.6,0.85,8.27,1.82,11.87
      c0.97,3.59,3.76,10.66,11.65,11.98c7.89,1.32,10.68-2.76,12.98-6.71c2.31-3.95,2.55-5.87,4.49-6.35c1.94-0.48,4.73-0.96,5.71-2.28
      c0.97-1.32,0.55-3.32,1.55-3.82c1-0.49,5.98-3.98,3.91-8.16c-2.06-4.2-3.65-6.96-3.03-8.75c0.6-1.8,3.76-7.91,2.79-12.7
      c-0.97-4.79-3.4-6.23-4.98-7.79c-1.58-1.55-4.98-3.71-5.7-5.04c-0.73-1.31-1.22-5.02-2.18-7.42c-0.97-2.4-2.8-4.07-5.83-3.83
      c-3.04,0.24-5.34,1.68-6.67,2.99c-1.34,1.32-3.28,2.64-6.68,2.4c-2.43-0.17-5.34-0.72-6.92-2.64c-1.58-1.92-6.31-3.95-8.37-2.99
      c-2.07,0.96-5.34,3.11-6.56,4.67c-1.21,1.56-5.46,2.88-7.04,2.28c-1.58-0.6-4.01-0.72-5.82-0.96
      C417.95,479.69,414.31,481.36,414.31,481.36z"/>
    <path fill="#F4A868" d="M482.86,496.6c-1.49-3.87-5.46-5.15-7.96-8.13c-1.77-2.12-2.27-4.48-3-7.03
      c-0.38-1.32-0.98-2.79-2.27-3.49c-3.08-1.67-5.56-0.25-7.97,1.8c-1.89,1.61-4.17,3.88-6.81,3.67c-2.57-0.21-4.97-0.69-6.67-2.51
      c-2.8-3-5.82-4.74-9.17-3.12c-3.45,1.66-6.11,5.26-10.24,5.22c-2.73-0.03-5.15-0.95-7.66-1.92c-0.87-0.34-1.81-0.75-2.77-1.06
      c0.53-0.09,1.03-0.14,1.44-0.09c1.82,0.24,4.24,0.36,5.82,0.96c1.58,0.6,5.83-0.72,7.04-2.28c1.22-1.56,4.49-3.71,6.56-4.67
      c2.06-0.96,6.8,1.08,8.37,2.99c1.58,1.92,4.49,2.46,6.92,2.64c3.4,0.24,5.34-1.08,6.68-2.4c1.33-1.32,3.63-2.75,6.67-2.99
      c3.03-0.24,4.86,1.44,5.83,3.83c0.97,2.4,1.46,6.11,2.18,7.42c0.73,1.32,4.12,3.48,5.7,5.04c1.57,1.56,4,3,4.98,7.79
      c0.95,4.68-2.04,10.61-2.74,12.56C482.75,506.16,484.64,501.25,482.86,496.6z"/>
    <path fill="#F4A868" d="M452.09,488.14c1.4,1.66,2.96,3.43,5.16,4.06c2.37,0.67,4.65,0.2,7.07,0.15
      c2.46-0.05,5.02,0.12,7.34,0.98c2.61,0.98,4.92,2.67,6.34,5.09c1.92,3.31,1.52,6.59-0.54,9.73c-1.69,2.57-2.38,5.75-1.26,8.51
      c-1.87-1.79-3.4-4.11-3.28-6.7c0.12-2.61,2.05-4.49,2.29-7.04c0.38-4.08-2.72-7.87-6.84-8.53c-2.18-0.34-4.21,0.83-6.36,0.86
      c-3.13,0.04-6.41-2.1-8.61-4.16c-2.15-2.02-3.69-4.96-6.11-6.48C449.15,485.17,450.72,486.52,452.09,488.14z"/>
    <path fill="#F4A868" d="M353.31,506.84c-2.61,2.99-5.42,3.44-9.14,4.21c-3.12,0.65-5.77,2.28-7.29,5.14
      c-0.05,0.09-0.09,0.18-0.12,0.26c0.12-3.35,2.5-6.15,5.21-7.63c3.28-1.8,9.1-1.68,11.65-2.63c0.13-0.05,0.25-0.11,0.36-0.18
      C353.77,506.27,353.55,506.56,353.31,506.84z"/>
    <path fill="#F4A868" d="M365.64,476.58c-2.8,1.6-5.12,3.73-6.37,6.75c-2.32,5.6-1.33,12.16-2.98,17.87
      c0.13-1.44,0-3.01-0.37-4.5c-0.97-3.95-1.33-6.23-0.97-9.71c0.37-3.48,1.1-7.31,7.04-11.62c4.24-3.07,9.26-2.98,11.81-2.68
      C370.92,473.45,368.11,475.17,365.64,476.58z"/>
    <path fill="#95D7EB" d="M426.93,540.53l-2.72-0.2l-1.76,0.56c0,0,0.55,8.91-8.04,9.22c-8.59,0.3-9.45-9.45-9.45-9.45
      l-1.05-0.69c0,0-0.62,1.56-0.72,4.75c-0.1,3.19,0.31,5.68,0.2,8.58c-0.1,2.89-1.51,7.58-1.82,9.87c-0.31,2.3-1.11,17.46-1.11,17.46
      s3.74-0.2,8.59-0.5c4.85-0.3,13.74-0.1,16.77,0.1c3.03,0.21,5.76,0.7,5.76,0.7l0.7-7.09l-1.51,0.1l-0.4-7.78
      c0,0-0.71-7.19-0.91-9.58C429.26,554.2,426.93,540.53,426.93,540.53z"/>
    <path fill="#81C0DC" d="M426.59,567.33c-0.47-1.65-1.64-1.57-2.22-3.14c-0.23-0.62,0.37-1.55,0.35-2.4
      c-0.02-0.78-0.66-1.47-0.48-2.13c0.15-0.58,0.28-1.1,0.3-1.69c0.1-2.32,2.64-3.17,4.65-3.44c0.13,0.93,0.23,1.66,0.27,2.06
      c0.2,2.39,0.91,9.58,0.91,9.58l0.17,3.17C429.17,569.32,427.03,568.87,426.59,567.33z"/>
    <path fill="#81C0DC" d="M406.27,554.05c1.41,1.68-0.22,2.23,0,3.12c0.08,0.32,0.91,0.95,1.13,1.2
      c0.98,1.16,1.02,2.85,0.16,4.08c-0.49,0.71-1.38,1.01-1.73,1.41c-0.35,0.41,0.2,0.64-0.41,1.48c-0.86,1.16-3.05,1.44-4.15,1.47
      c0.11-1.72,0.22-3.07,0.3-3.64c0.29-2.19,1.59-6.56,1.79-9.46C404.26,553.74,405.69,553.36,406.27,554.05z"/>
    <path fill="#B3E4F1" d="M411.87,577.73c9.21-0.66,17.2,0.9,19.7,3.18v0.01c0,0-2.73-0.5-5.76-0.7
      c-3.03-0.2-11.92-0.4-16.77-0.1c-2.24,0.14-7.49,0.61-8.99,0.7C401.57,578.83,406.32,578.13,411.87,577.73z"/>
    <path fill="#B3E4F1" d="M423.15,540.66c1.74,6-2.43,13.15-9.43,11.52c-3.28-0.76-5.69-2.49-7.28-5.45
      c-1.06-1.97-1.94-4.25-2.18-6.54l0.7,0.46c0,0,0.86,9.75,9.45,9.45c8.59-0.3,8.04-9.22,8.04-9.22L423.15,540.66z"/>
    <path fill="#C281B3" d="M434.48,632.18c0,0-1.28-2.73-1.28-5.46c0-2.74,0.14-8.83,0.43-12.76
      c0.28-3.92,0.92-10.23,0.85-15.48c-0.07-5.25-1.14-9.04-1.14-9.04s0.43-3.93-0.28-4.27c-0.71-0.35-2.56,0-4.47,0.35
      c-1.91,0.36-12.06,1.61-14.62,1.61c-2.55,0-7.59,0-8.66-0.28c-1.07-0.28-2.63-1.05-2.63-0.28c0,0.77,0.92,18.5,1.28,21.79
      c0.35,3.3,0.28,16.96,0.14,18.92c-0.14,1.96-0.64,5.6-0.64,5.6s3.34,0.84,5.81,1.12c2.49,0.28,5.33,0.14,5.11-0.49
      c-0.21-0.63-1.13-3.43-1.28-5.81c-0.14-2.38-0.07-11.85,0.86-16.54c0.92-4.69,2.06-9.53,2.77-11.42c0.71-1.89,1.7-2.87,1.7-2.87
      s2.63,3.08,4.26,11.9c1.63,8.84,1.98,16.96,1.91,19.06c-0.07,2.11-0.64,4.9-0.93,5.54C423.4,634,435.04,633.16,434.48,632.18z"/>
    <path fill="#D09DC0" d="M433.36,589.52c-0.22,0.52-0.57,1.72-0.79,2.25c-0.06-1.14-0.51-4.83-0.69-5.22
      c-0.19-0.39-1.35,0.14-4.12,0.62c-3.33,0.58-13.75,1.26-14.57,0.16c4.01-0.39,14.02-1.56,15.39-1.81c1.92-0.35,3.76-0.7,4.47-0.35
      c0.71,0.35,0.28,4.27,0.28,4.27S433.34,589.47,433.36,589.52z"/>
    <path fill="#D09DC0" d="M431.78,612.42c0.62-3.8,1.37-8.71,2.19-12.47c0.17-0.72,0.33-1.44,0.49-2.17
      c0.01,0.23,0.02,0.46,0.02,0.69c0.07,5.25-0.57,11.56-0.85,15.48c-0.28,3.93-0.43,10.02-0.43,12.76c0,2.73,1.28,5.46,1.28,5.46
      c0.07,0.13-0.08,0.26-0.38,0.38c-2.24-1.15-2.98-4.52-3.17-8.02C430.71,620.42,431.12,616.46,431.78,612.42z"/>
    <path fill="#8B86BB" d="M411.13,646.77c0,0-5.47,4.55-7.52,5.4c-2.05,0.84-5.64,1.86-7.6,1.86c-1.97,0-2.47-0.42-2.99-1.44
      c-0.51-1.01-0.35-2.76-0.35-2.76s-1.87,0.49-3.32,1.59c-1.46,1.1-3,3.54-1.97,5.4c1.03,1.86,3.5,3.29,7.94,2.11
      c4.44-1.18,11.2-4.05,12.99-5.56c1.79-1.52,2.64-2.19,2.99-3.45C411.65,648.63,411.73,646.19,411.13,646.77z"/>
    <path fill="#A19DC8" d="M389.35,651.42c1.45-1.1,3.32-1.59,3.32-1.59s-0.06,0.69,0.01,1.44c-1.61,0.61-3.46,2.42-3.94,3.26
      c-0.51,0.86-0.87,1.79-1.05,2.75c-0.11-0.15-0.21-0.31-0.3-0.47C386.35,654.96,387.9,652.51,389.35,651.42z"/>
    <path fill="#8B86BB" d="M427.74,650.08c0,0,6.03,3.25,8.85,4.21c2.82,0.96,5.72,1.85,7.43,1.34
      c1.71-0.51,1.71-1.77,1.29-2.78c-0.43-1.01-1.48-2.51-1.48-2.51s4.29,1.5,5.92,4.7c1.62,3.2,0.77,4.73-2.48,5.65
      c-3.25,0.93-9.99-1.78-13.5-4.22C430.27,654.03,425.21,648.15,427.74,650.08z"/>
    <path fill="#A3A0C9" d="M447.52,654.63c-0.57-0.75-1.34-1.21-2.16-1.61c-0.02-0.06-0.03-0.12-0.06-0.18
      c-0.43-1.01-1.48-2.51-1.48-2.51s4.29,1.5,5.92,4.7c0.95,1.87,1.05,3.16,0.32,4.1C449.38,657.56,448.62,656.08,447.52,654.63z"/>
    <path fill="#FFCDB1" d="M431.07,585.16l-1.56-4.18c0,0-6.81-1-11.77-1c-4.98,0-12.49,0.26-12.49,0.26l-1.49,6
      c0,0,7.45,1.36,12.24,1C420.77,586.88,431.07,585.16,431.07,585.16z"/>
    <path fill="#E1B392" d="M413.42,582.48c-2.9-0.09-5.84-0.14-8.75,0.08l0.58-2.32c0,0,7.52-0.26,12.49-0.26
      c4.96,0,11.77,1,11.77,1l0.68,1.82C424.59,582.78,419.02,582.66,413.42,582.48z"/>
    <path fill="#FFCDB1" d="M410.49,633.71l-5.12-0.34c0,0,0.17,1.99,0.17,3.06c0,1.08,0.86,4.71-0.46,5.85
      c-1.32,1.13-3.85,2.33-5.4,3.35c-1.56,1.03-7.02,4.2-7.02,4.2s-0.86,3.29,1.96,4.2c2.81,0.91,6.38-0.51,8.68-1.59
      c2.31-1.08,7.65-5.28,7.65-5.28s-0.64-3.92-0.69-6.75C410.2,637.57,410.49,633.71,410.49,633.71z"/>
    <path fill="#FFCDB1" d="M443.83,650.34c-1.2-0.89-3.34-2.45-4.39-3.04c-1.05-0.59-3.77-2.31-5.04-2.9
      c-1.26-0.59-2.82-1.06-2.82-1.06s-0.57-3.32-0.62-4.95c-0.05-1.63-0.14-5.26-0.14-5.26l-3.92,0.35c0,0-0.33,0.78-0.36,1.48
      c-0.02,0.71-0.43,7.6,0,10.14c0.43,2.54,0.62,4.45,1.19,4.97c0.58,0.52,8.1,4.45,11.68,5.17c3.58,0.71,6.16,0.99,6.42-0.76
      C446.1,652.74,445.36,651.47,443.83,650.34z"/>
    <path fill="#FFE1CF" d="M439.97,649.41c-2.78-2.02-6.81-4.09-9.84-5.61c0.53-0.27,1.11-0.37,1.71-0.39
      c0.5,0.16,1.61,0.54,2.55,0.98c1.27,0.59,3.99,2.31,5.04,2.9c1.05,0.59,3.2,2.16,4.39,3.04c1.53,1.13,2.27,2.4,2.01,4.15
      c-0.08,0.56-0.4,0.9-0.91,1.1C444.42,652.96,442.07,650.92,439.97,649.41z"/>
    <path fill="#E1B392" d="M405.55,636.27c-0.01-1.1-0.17-2.9-0.17-2.9l5.12,0.34c0,0-0.07,0.92-0.13,2.18
      C408.75,635.81,407.15,636.01,405.55,636.27z"/>
    <path fill="#E1B392" d="M427.77,636.76c-0.44,0.08-0.88,0.15-1.31,0.22c0.04-1.02,0.09-1.79,0.09-2.01
      c0.03-0.7,0.36-1.48,0.36-1.48l3.92-0.35c0,0,0.03,1.38,0.07,2.82C429.85,636.21,428.83,636.57,427.77,636.76z"/>
    <path fill="#FFCDB1" d="M396.42,546.38c1.78-1.37,2.94-1.45,2.94-1.45l0.26-4.29c0,0-2.54,2.24-3.4,2.77
      c-0.87,0.53-2.88,1.91-4.28,3.29c-1.4,1.39-4.01,3.76-5.08,4.55c-1.07,0.79-3.74,3.3-4.54,4.48c-0.8,1.19-2.14,3.96-2.21,5.87
      c-0.06,1.92,0.8,2.7,2.54,2.44c1.74-0.26,4.07-1.12,5.81-1.78c1.73-0.66,4.54-1.98,4.54-1.98s0,3.03,2.47,3.82
      c2.47,0.79,3.88,0.83,5.75,0.53c2.93-0.47,2.93-2.18,2.93-2.18s2.21,0,2.61-0.86c0.4-0.86-0.27-1.38-0.74-1.91
      c-0.47-0.52,1.54-0.73,1.26-2.24c-0.26-1.52-2.33-1.72-2.33-1.72s1.34-0.79,1.14-1.85c-0.2-1.05-1.47-1.25-2.67-1.38
      c-1.2-0.14-2.88-0.47-3.27-0.47c-0.4,0-0.74-0.2-0.74-0.2s0.66-1.91,0.53-2.63c-0.13-0.72-0.73-2.24-1.73-2.3
      c-1-0.07-1.2,1.12-1.34,1.84c-0.13,0.73-0.6,2.63-1.07,3.29c-0.47,0.66-1.74,1.92-1.94,2.51c-0.2,0.6-0.8,1.38-0.8,1.38
      s-2.54,0.73-3.87,1.38c-1.34,0.67-4.34,1.65-4.74,1.58c-0.4-0.07,1.8-2.24,2.74-3.42c0.93-1.19,3-3.3,4.81-4.82
      C392.83,549.97,394.91,547.55,396.42,546.38z"/>
    <path fill="#E1B392" d="M396.42,546.38c-0.28,0.22-0.58,0.48-0.89,0.77c-1.09-0.41-2.17-0.65-3.26-0.75
      c1.37-1.28,3.15-2.5,3.95-2.99c0.86-0.53,3.4-2.77,3.4-2.77l-0.26,4.29C399.36,544.93,398.19,545.01,396.42,546.38z"/>
    <path fill="#FFCDB1" d="M432.54,542.41c0,0,2.3,1.22,4.28,3.08c1.97,1.87,5.59,6.33,7.64,8.27
      c2.05,1.95,5.42,7.05,5.18,8.67c-0.24,1.62-0.66,1.79-2.63,1.86c-1.97,0.09-8.29-0.72-8.29-0.72s-2.96,2.26-5.01,2.83
      c-2.05,0.57-4.52,0.57-5.26,0.16c-0.74-0.41-0.99-1.3-1.73-1.95c-0.74-0.65-0.74-1.38-0.65-2.27c0.08-0.89,0.24-1.7-0.33-2.19
      c-0.57-0.48-0.41-1.86-0.09-2.35c0.33-0.48-1.06-1.79-0.73-2.52c0.33-0.73,1.64-0.89,2.46-1.3c0.83-0.41,1.08-0.52,1.88-0.56
      c0.79-0.04,3.03-0.31,3.43-0.29c0.4,0.02-0.21-0.1-0.08-0.98c0.14-0.88,0.04-3.17,0.48-3.72c0.43-0.55,0.87-0.49,1.18-0.27
      c0.32,0.22,0.8,0.96,0.6,1.68c-0.2,0.72-0.02,1.82,0.06,2.55c0.08,0.72,0.72,1.27,1.34,1.68c0.64,0.41,1.51,1.33,2.04,1.98
      c0.54,0.64,1.7,2.6,1.8,3.13c0.1,0.53,0.28,0.79,0.48,0.79c0.2,0,1.88,0.22,2.59,0.24c0.71,0.01,2.11,0.01,2.2-0.18
      c0.1-0.2-1.31-1.94-1.88-2.72c-0.58-0.78-7.38-7.86-8.11-8.47c-0.73-0.61-2.06-1.11-2.06-1.11L432.54,542.41z"/>
    <path fill="#E1B392" d="M434.06,548.06c-0.41-0.2-0.73-0.32-0.73-0.32l-0.79-5.32c0,0,2.3,1.22,4.28,3.08
      c0.12,0.11,0.23,0.23,0.36,0.35C435.87,546.09,434.67,546.84,434.06,548.06z"/>
    <path fill="#FFCDB1" d="M422.52,541.32c0,0-1.58,0.27-2.69-0.55c-1.11-0.82-1.34-4.1-1.19-4.06
      c0.15,0.03-1.83-0.23-3.29-0.23c-1.47,0-3.92-0.71-3.92-0.71s0.28,3.79-1.03,4.34c-1.31,0.55-5.47,0.55-5.47,0.55
      s0.71,8.36,9.1,9.14C422.45,550.57,422.52,541.32,422.52,541.32z"/>
    <path fill="#E1B392" d="M419.34,540.15c-2.43,0.67-4.91,0.98-7.34-0.25c-0.32-0.17-0.64-0.34-0.95-0.53
      c0.57-1.26,0.4-3.61,0.4-3.61s2.45,0.71,3.92,0.71c1.35,0,3.14,0.23,3.28,0.23C418.52,536.71,418.66,538.92,419.34,540.15z"/>
    <path fill="#FFCDB1" d="M442.4,524.37c1.07-1.41,1.56-2.51,1.33-3.69c-0.22-1.19-2.36-4.88-3.83-6.6
      c-1.46-1.71-3.52-3.56-3.91-5.4c-0.4-1.85-0.32-3.69-0.27-5.23c0.04-1.54-0.62-3.65-0.62-3.65s-2.98-2.06-4.68-4.22
      c-1.69-2.15-2.62-6.51-3.91-7.69c-1.3-1.18-2.94-1.14-4.01-1.58c-1.07-0.44-1.96-1.32-2.63-2.15c-0.67-0.84-1.96-1.01-2.8-0.7
      c-0.85,0.31-2.32,2.51-3.3,3.29c-0.98,0.8-3.96,1.05-5.3,0.57c-1.33-0.48-3.16-0.66-4.54-0.61c-1.38,0.04-4.59,1.01-5.43,2.63
      c-0.85,1.63-0.75,8.79-0.71,10.82c0.05,2.02-0.76,3.87-1.87,4.66c-1.11,0.79-4.01,4.18-3.92,5.76c0.09,1.58,2.09,4.84,1.38,7.17
      c-0.71,2.33-2.31,3.16-2.31,3.16s4.63,10.33,16.42,13.8c11.8,3.47,19.1,1.1,24.18-0.93C436.74,531.75,442.4,524.37,442.4,524.37z"
      />
    <path fill="#E1B392" d="M437.01,516.84c-2.1-1.54-2.75-5.94-3-8.69c-0.16-1.81-0.24-3.75-1.46-5.24
      c-1.49-1.81-3.45-3.06-4.42-5.28c-0.89-2.04-1.27-4.69-3.1-6.17c-1.58-1.28-3.43-2.1-4.93-3.51c-0.72-0.67-1.28-1.39-2.23-0.7
      c-2.16,1.55-3.56,3.37-6.51,3.16c-2.92-0.2-8.09-2.03-10.09,1.17c-2,3.2,0.03,6.93-0.86,10.31c-1.03,3.88-5.2,6.11-4.93,10.47
      c0.19,3.08,1.17,6.22-0.89,8.94c-0.61,0.8-1.31,1.51-2.03,2.21c-1.03-1.56-1.51-2.62-1.51-2.62s1.6-0.84,2.31-3.16
      c0.72-2.33-1.29-5.59-1.38-7.17c-0.09-1.58,2.8-4.96,3.92-5.76c1.11-0.79,1.92-2.63,1.87-4.66c-0.04-2.02-0.14-9.19,0.71-10.82
      c0.85-1.62,4.05-2.59,5.43-2.63c1.38-0.05,3.2,0.13,4.54,0.61c1.34,0.48,4.32,0.22,5.3-0.57c0.98-0.79,2.45-2.99,3.3-3.29
      c0.84-0.31,2.13-0.14,2.8,0.7c0.67,0.84,1.56,1.71,2.63,2.15c1.07,0.44,2.71,0.4,4.01,1.58c1.29,1.19,2.22,5.54,3.91,7.69
      c1.69,2.15,4.68,4.22,4.68,4.22s0.66,2.11,0.62,3.65c-0.04,1.54-0.13,3.39,0.27,5.23c0.4,1.85,2.45,3.69,3.91,5.4
      c1.47,1.72,3.61,5.41,3.83,6.6c0.22,1.19-1.02,3.83-2.36,5.15C441.38,525.82,441.25,519.95,437.01,516.84z"/>
    <path d="M386.61,563.12c0.56,4.05,0.61,9.41,2.02,13.26c1.3,3.6,8.64,2.3,11.38,2.26c0.19-0.01,0.14,0.85-0.04,0.9
      c-2.38,0.73-9.47,1.51-11.49-0.48c-2.65-2.61-2.47-11.15-2.8-15.49C385.63,562.93,386.53,562.56,386.61,563.12z"/>
    <path d="M387.67,543.9c0.24-0.07,0.46,0.2,0.24,0.36c-2.5,1.79-1.97,5.12-1.78,7.63c0.02,0.23-0.99,1.13-1.01,0.71
      C384.97,549.95,384.11,544.97,387.67,543.9z"/>
    <path d="M432.78,540.71c3.42-0.9,9.69-2.6,12.25,0.93c1.62,2.24,2.42,4.81,3.25,7.42c1.28,4.05,2.94,7.96,4.13,12.06
      c1.4,4.84,3.93,11.66-1.25,14.61c-2.57,1.46-14.8,3.03-18.07,2.94c-1.4-0.03-2.24-1.45-1.14-1.5c7.99-0.37,16.31-1.94,18.29-2.68
      c3.57-1.56,3.01-4.88,2.61-7.46c-0.43-2.84-1.26-5.64-2.11-8.38c-1.19-3.88-2.78-7.63-4.06-11.48c-1.11-3.4-2.87-6.22-6.87-6.6
      c-2.03-0.19-5.1,0.36-7.1,0.61C432.47,541.21,432.53,540.77,432.78,540.71z"/>
    <path d="M404.23,580.27c0.11-0.29,0.99-0.34,1.01-0.03c0.09,1.04-0.59,4.7-0.84,5.97c-0.13,0.61-0.79,0.12-1.04-0.37
      C402.94,584.99,403.89,581.11,404.23,580.27z"/>
    <path d="M430.24,585.71c-0.07-0.07-0.11-0.16-0.14-0.26c-0.18-0.81-0.99-3.29-1.1-4.11c-0.11-0.78,1.19-1.04,1.38-0.28
      c0.26,1.03,1.05,3.12,1.43,4.22C431.3,585.47,430.76,585.56,430.24,585.71z"/>
    <path d="M400.53,564.89c0.02-0.16,1.32-0.37,1.32-0.13c-0.01,0.24-0.57,12.94-0.74,15.46c4.41-0.74,9.59-0.96,14-1.01
      c5.31-0.07,10.59,0.56,15.86,1.06c-0.31-1.25,0.02-2.6,0.18-3.95c0.1-0.83,0.23-1.66,0.37-2.49c0.4,0.02,0.94,0.01,1.4-0.11
      c-0.27,2.42-0.5,4.84-0.4,7.31c0.01,0.33-0.37,0.56-0.67,0.54c-10.37-0.74-21.23-1.45-31.49,0.54c-0.65,0.13-1.02-0.54-0.99-1.06
      C399.56,577.61,400.51,565.05,400.53,564.89z"/>
    <path d="M430.56,567.27c0.52-0.19,0.91,4.95,0.82,5.83c0.06,0.05,2.33-0.34,2.81-0.35c0.19-2.12,0.19-4.03,0.01-6.19
      c-0.05-0.61,0.83-0.37,0.88,0.17c0.04,0.54,0.27,4.84,0.22,7.22c-0.01,0.42-4.75,0.48-5.01,0.45
      C430.05,574.37,429.86,567.52,430.56,567.27z"/>
    <path d="M397.39,565.05c0.05-0.48,0.77-0.53,0.77-0.05c-0.01,1.76-0.51,6.27-0.33,6.31c0.18,0.04,1.57,0.09,1.97,0.11
      c0.03-0.82,0.55-6.36,0.63-6.52c0.07-0.16,0.69-0.39,0.7-0.04c0.01,0.35-0.22,6.22-0.28,7.41c-0.02,0.42-4.14,0.04-4.12-0.25
      C396.83,569.96,397.15,567.11,397.39,565.05z"/>
    <path d="M392.8,649.53c1.05-1.11,7.97-5.1,11.2-6.78c2.89-1.5,0.78-6.06,1.16-9.01c0.05-0.37,0.76-0.3,0.7,0.13
      c-0.43,2.89,1.97,7.71-0.98,9.32c-4.73,2.56-8.08,4.49-11.63,7.21C392.7,650.82,392.27,650.09,392.8,649.53z"/>
    <path d="M410.85,633.74c-0.02,2.25-0.15,4.5-0.08,6.76c0.07,2.22,0.66,4.35,0.66,6.57c-0.36,0.31-0.64,0.71-1.05,0.92
      c-0.18-0.12-0.31-0.33-0.3-0.65c0.07-2.25-0.42-4.49-0.51-6.74c-0.09-2.27,0.18-4.6,0.42-6.87
      C410.27,633.75,410.56,633.75,410.85,633.74z"/>
    <path d="M430.71,643.01c-0.56-2.78-0.21-6.63-0.37-9.47c-0.01-0.15,0.01-0.26,0.04-0.39c0.25-0.04,0.49-0.08,0.73-0.12
      c0,0.02,0,0.03,0,0.05c0.02,2.83,0.34,7.34,0.84,10.14c3.32,0.45,8.33,4.28,8.96,4.81c0.63,0.52,0.3,0.9,0.12,0.8
      c-3.57-2.09-7.92-4.37-11.58-4.87C429.03,643.89,429.51,643.15,430.71,643.01z"/>
    <path d="M426.19,633.54c0.07-0.64,0.82-0.46,0.79,0.19c-0.27,5.68-0.4,13.2,2.14,17.82c0.33,0.59-1.58-0.61-1.91-1.03
      C425.03,647.75,425.74,637.63,426.19,633.54z"/>
    <path d="M391.25,520.83c7.21,10.25,16.84,15.09,29.32,14.5c9.21-0.44,16.49-5.04,21.88-12.16c0.27-0.35,0.92-0.04,0.7,0.38
      c-4.67,9.09-13.43,13.49-23.51,14.06c-6.72,0.38-13.07-1.59-18.83-4.92c-4.24-2.45-9.41-7.5-10.54-11.56
      C390.06,520.37,390.78,520.16,391.25,520.83z"/>
    <path d="M418.42,536.59c0.11-0.4,1.27-0.25,1.24,0.18c-0.06,1.16-0.34,2.1,0.22,3.12c0.46,0.84,1.68,1.06,2.55,0.99
      c0.62-0.05,0.65,0.81,0.07,0.92c-1.28,0.25-2.53,0.06-3.51-0.82C417.92,540.04,418.06,537.93,418.42,536.59z"/>
    <path d="M404.82,540.08c1.26-0.07,3.4-0.16,4.46-0.33c1.86-0.3,1.41-2.03,1.52-3.43c0.06-0.73,1.29-0.73,1.3,0.01
      c0.01,1.38,0.17,3.05-1.03,4.04c-1.36,1.1-4.25,1.07-5.81,1.11C404.89,541.49,404.4,540.1,404.82,540.08z"/>
    <path d="M399.44,503.79c2.54,7.56,10.39,10.78,17.94,10.95c7.67,0.17,13.95-4.08,15.34-11.32c0.05-0.29,0.6,0.13,0.48,1.12
      c-2.09,16.78-30.8,15.4-34.05-0.02C399.01,503.89,399.23,503.15,399.44,503.79z"/>
    <path d="M417.69,501.48c2.09-0.23,3.43,1.63,3.32,3.06c-0.05,0.55-0.41,1.08-0.52,0.53c-0.25-1.26-1.24-2.31-2.77-2.06
      c-1.31,0.21-1.79,1.4-1.53,2.69c0.14,0.68-0.52,0.49-0.84-0.15C414.33,503.51,415.52,501.72,417.69,501.48z"/>
    <path d="M421.2,494.56c1.1-0.47,2.2,0.34,2.8,1.13c1.11,1.44,1.32,3.66,1.26,4.32c-0.06,0.61-0.41,0.84-0.47,0.57
      c-0.13-0.69-0.32-1.47-0.59-2.46c-0.35-1.24-1.56-3.17-2.51-2.54c-0.62,0.42-0.93,1.25-0.86,2.41c0.06,0.85,0.13,1.75,0.27,2.58
      c0.11,0.62-0.58,0.14-0.84-0.3C419.48,498.94,419.24,495.39,421.2,494.56z"/>
    <path d="M410.01,494.99c0.89-0.9,2.28-0.6,3.1,0.14c1.02,0.92,1.64,3.18,1.7,3.81c0.05,0.61-0.31,0.96-0.41,0.7
      c-0.41-1.14-0.62-1.83-1.3-2.85c-0.52-0.79-1.93-1.67-2.35-0.39c-0.5,1.51-0.46,2.85-0.1,4.37c0.17,0.73-0.59,0.19-0.9-0.39
      C409.21,499.39,408.61,496.38,410.01,494.99z"/>
    <path d="M375.79,542.47c4.14-2.35,7.22-3.43,11.33-0.72c4.15,2.74,9.48,2.07,11.24-2.81c2-5.56-3.55-8.17-7.8-8.98
      c-3.07-0.58-5.85-2.22-4.58-5.83c0.43-1.21,1.61-2.13,2.61-2.87c1.54-1.13,3.76-1.27,3.74-3.9c-0.02-1.35-0.91-2.53-1.21-3.8
      c-0.38-1.58-0.82-3.88,0.22-5.37c2.32-3.34,6.11-3.34,5.58-8.71c-0.43-4.31-2.05-8.73,1.82-12.21c3.36-3.02,6.67-1.89,10.61-0.65
      c3.71,1.17,4.82-1.73,7.32-3.33c0.83-0.53,1.63-0.26,2.43,0.12c0.23,0.1,0.62,0.45,0.37,0.36c-3.18-1.05-3.53,2.21-4.74,3.69
      c-1.05,1.29-2.85,1.55-4.4,1.59c-3.1,0.07-5.62-2.25-8.76-0.85c-4.18,1.87-3.24,7.76-3.02,11.19c0.11,1.81,0.02,3.18-0.81,4.79
      c-0.3,0.58-0.89,1.02-1.37,1.45c-2.17,1.92-3.9,4.6-3.22,7.51c0.75,3.28,2,5.61-0.89,8.14c-0.74,0.65-1.76,1.03-2.61,1.52
      c-3.06,1.77-1.82,5.56,1.18,5.99c2.34,0.33,4.98,1.06,6.95,2.29c3.17,1.97,2.85,5.43,1.9,8.65c-0.95,3.23-3.97,5.44-7.26,5.48
      c-2.23,0.04-4.66-0.19-6.62-1.37c-3.36-2.04-5.33-2.29-11.24,0.66C373.43,545.03,374.63,543.13,375.79,542.47z"/>
    <path d="M338.64,510.14c1.79-1.93,4.46-3.04,6.96-3.7c1.12-0.3,2.4-0.19,3.54-0.2c4.06-0.03,6.63-1.79,6.61-5.87
      c-0.02-3.12-1.45-6.25-2.11-9.27c-0.59-2.73,0.07-5.43,1.04-8c2.09-5.53,7.03-9.85,12.76-11.36c3-0.8,5.9-0.67,8.67,0
      c0,0.03-0.01,0.05-0.01,0.08c0.01,0.1,0.02,0.2,0.02,0.3l-0.01,0.33c-0.15,0.51-0.19,1.02-0.07,1.62c0.02,0.12,0.07,0.22,0.12,0.31
      c-2.62-0.53-5.33-0.66-8.27-0.15c-6.61,1.15-12.4,8.58-12.64,15.03c-0.15,4.06,2.09,7.95,2.08,12.06c0,4.01-3.29,6.08-6.96,6.45
      c-2.76,0.29-5.01,0.25-7.52,1.58c-3.95,2.09-6.16,6-4.63,10.27c1.17,3.29,4.62,5.31,7.66,6.58c1.52,0.63,3.64,1.38,4.64,2.79
      c3.75,5.3-7.18,9.33-0.61,14.6c4.42,3.54,16.36,6.17,23.24,0.78c0.48-0.38-0.09,1.35-1.57,2.38c-5.05,3.55-12.59,2.75-18.05,1.3
      c-4.36-1.16-12.16-6.44-7.81-11.69c1.35-1.63,5.46-4.21,2.95-6.69c-1.09-1.07-2.91-1.63-4.29-2.22c-2.48-1.06-4.5-2.51-6.28-4.51
      C334.63,519.05,335.26,513.79,338.64,510.14z"/>
    <path d="M412.49,482.4c2.49-2.24,4.61-3.88,8.32-3.11c3.38,0.7,6.22,1.46,9.3-0.43c1.9-1.15,3.41-2.9,5.21-4.2
      c3.31-2.4,7.01-1.99,10.66-0.44c2.4,1.01,3.13,4.13,5.68,4.48c1.75,0.25,3.82,0.44,6.11,0.1c2.9-2.88,6.42-5.87,10.5-5.73
      c2.83,0.09,5.04,2.29,6.12,4.66c1.04,2.27,0.73,4.87,1.91,7.15c1.11,2.14,3.88,3.66,5.67,5.19c5.22,4.45,6.77,10.12,4.24,16.45
      c-1.08,2.68-2.17,4.89-0.95,7.87c0.84,2.04,2.02,3.63,2.56,5.81c0.71,2.93-1.24,5.45-3.45,7.08c-0.54,0.39-1.9,1.05-1.51,0.61
      c5.13-5.7,3.03-8.37,0.52-12.58c-1.32-2.22-0.9-4.66-0.15-6.97c1.03-3.17,2.84-5.8,2.75-9.3c-0.1-3.77-2.93-6.7-5.61-9.01
      c-1.74-1.49-3.53-2.73-4.89-4.6c-2.15-2.97-0.61-6.77-3.96-9.3c-6.04-4.57-11.28,3.49-15.93,5.87c-0.6,0.31-1.53,0.5-1.03,0.05
      c0.58-0.53,1.6-1.46,2.2-2.06c-2.64,0.35-6.16,0.96-7.79-0.84c-4.07-4.5-9.69-5.05-14.29-1.41c-2.44,1.94-4.64,3.83-7.87,4.09
      c-1.72,0.15-3.58-0.49-5.22-0.94c-3.81-1.05-6.71,0.95-9.75,2.48C411.55,483.5,411.94,482.89,412.49,482.4z"/>
    <path d="M408.33,475.99c1.7,0.66,3.21,1.7,4.32,3.22c0.33,0.44,0.87,1.91,0.5,1.51c-0.9-0.96-3.11-2.68-4.24-3.21
      C408.71,477,408.52,476.5,408.33,475.99z"/>
    <path d="M445.45,483.68c1.65,0.18,4.19,1.12,6.58,3.62c1.71,1.79,3.22,3.63,5.66,4.44c2.06,0.69,4.75,0.09,6.85-0.09
      c5.6-0.48,12.57,2.65,14.79,8.07c1.2,2.92,1.03,5.15-0.62,7.85c-1.63,2.66-4.34,5.22-2.19,8.37c1.1,1.62,2.44,2.83,3.26,4.64
      c2.52,5.62-4,7.75-8.05,7.88c-0.61,0.02-1.66-0.37-1.05-0.44c3.13-0.31,6.52-0.96,7.92-4.16c1.47-3.38-2.74-6.37-4.21-8.97
      c-0.75-1.32-0.36-2.73,0.19-4.02c1.83-4.36,6.06-5.98,3-11.48c-1.59-2.86-4.71-4.68-7.67-5.83c-3.25-1.27-6.79-0.3-10.17-0.36
      c-1.98-0.04-3.65-0.95-5.15-2.15c-3.75-3-6.02-6.35-11.21-7C442.95,483.99,443.79,483.5,445.45,483.68z"/>
    <path d="M420.07,484.47c2.21,2.29,4.57,1.28,6.11,2.44c1.3,0.99,1.83,2.09,2.27,3.62c1.74,6.08,6.64,7.57,11.85,10.11
      c7.55,3.7,13.47,14.34,14.39,17.26c0.91,2.91,0.47,4.41,0.23,3.83c-3.64-8.87-10.57-18.67-19.16-20.78
      c0.24,0.66,0.38,1.37,0.49,2.16c0.22,1.64-0.11,3.14,0.21,4.81c0.42,2.22,2.57,3.91,4.01,5.53c2.41,2.72,4.16,5.99,5.32,9.39
      c1.28,3.75,0.65,7.88,1.57,11.72c2.38,10.01,14.7,16.85,22.05,7.19c1.47-1.94,2.31-7.14,4.88-8.3c0.57-0.26,1.02-0.46,1.67-0.5
      c3.08-0.21,4.86-1.15,6.62-3.7c0.39-0.56,0.31,3.76-5.05,4.78c-4.94,0.94-4.84,6.91-7.69,9.87c-5.5,5.73-12.37,5.71-18.44,0.79
      c-2.67-2.17-4.84-5.43-5.81-8.7c-1.59-5.36-0.75-10.86-3.14-16.08c-1.64-3.6-4.45-6.37-6.4-9.76c-1.74-3.03,0.15-6.78-1.39-9.71
      c-2.9-1.42-5.39-3.25-6.67-6.35c-1.16-2.82,0.6-5.8-4.43-7c-1.4-0.34-2.05-0.68-2.95-1.76
      C420.37,485.03,419.8,484.19,420.07,484.47z"/>
    <path d="M375.54,481.86c0.19,3.61-0.39,11.85,5.77,11.11c6.16-0.73,5.92-8.35,8.27-10.12c1.67,1.68,4.39,2.03,6.65,0.73
      c2.07-1.2,2.44-3.14,2.64-4.69c2.32,1.89,7.66,7.09,9.84,3.09c1.08-1.99,0.71-4.11,0.2-6.24c-0.75-3.09-0.27-5.88-0.14-9.01
      c0.05-1.43-0.73-10.45-4.68-7.27c-3.55,2.86-6.92,9.17-7.24,14.13c-0.67-0.51-1.49-0.82-2.47-0.86c-2.86-0.12-5.03,1.82-5.84,4.31
      c-2.52-3.78-6.3-9.95-11.38-8.54C372.98,469.65,375.41,479.39,375.54,481.86z M397.79,474.52c1.36-5.29,3.55-9.62,6.62-13.3
      c2.48-2.97,3.01,2.78,2.97,4.57c-0.05,1.94-0.35,3.81-0.59,5.73c-0.32,2.65,0.76,5.16,0.54,7.76c-0.39,4.34-6.43-1.93-8.47-2.26
      c-0.01,0-0.01,0-0.02,0C398.68,476.11,398.32,475.24,397.79,474.52z M390.18,479.01c0.17-2.45,2.16-5.19,4.95-4.23
      c1.66,0.58,2.35,2.47,2.18,4.08c-0.18,1.69-0.78,2.96-2.53,3.58C393.04,483.06,389.98,481.95,390.18,479.01z M377.14,470.57
      c0.3-1.33,3.22-1.76,6.09,1.24c1.87,1.89,3.42,4.06,5.09,6.12c-0.11,0.62-0.15,1.27-0.09,1.91c0.07,0.76,0.29,1.42,0.6,1.98
      c-1.93,2.17-2.18,5.69-4.3,7.6c-6.16,5.55-6.39-2.48-6.47-4.72c-0.05-1.46-0.1-2.92-0.25-4.36c-0.2-1.99-0.47-3.96-0.67-5.95
      C376.96,473.34,376.84,471.91,377.14,470.57z"/>
    <path d="M424.47,555.97c0.18-1.97,2.8-2.64,4.3-2.94c1.98-0.4,4.19-0.4,6.13,0.25c-0.42-0.86-0.56-1.77-0.49-2.8
      c0.07-0.93,0.37-1.69-0.51-2.28c-0.89-0.59-0.74,2.72-0.88,3.35c-0.11,0.49-0.47-0.48-0.45-1c0.03-0.65-0.16-5.06,2.95-2.47
      c4.19,3.5,10.08,11.08,10.66,12.01c0.57,0.93,0.32,1.61,0.13,1.28c-0.2-0.32-0.62-0.8-0.66-0.85c-0.94,0.36-4.42-0.03-4.98-0.25
      c0,0.11-0.44,1.16-0.52,0.8c-0.29-1.31-2.26-4.6-2.99-5.57c-0.14-0.2,0.1-0.5,0.31-0.37c1.38,0.83,2.72,2.75,3.18,4.43
      c0.93,0.18,3.49,0.5,4.41,0.35c-2.94-3.65-6.04-7.59-9.77-10.54c0.14,1.24-0.72,3.03,0.33,3.95c0.67,0.58,1.16,1.25,0.86,1.15
      c-2.41-0.77-5.21-1.08-7.65-0.58c-2.14,0.43-4.26,1.51-3.11,2.91c0.34,0.42,0.65,1.13,0.38,1.58c-0.91,1.53,1.02,1.82,0.51,2.88
      c-0.5,1.06-0.42,1.68-0.13,2.25c0.3,0.57,0.9,0.9,1.22,1.56c1.87,3.91,9.6-0.82,11.54-2.82c0.47-0.48,0.59,0.26,0.33,0.79
      c2.17-0.13,4.26,0.43,6.48,0.59c5.66,0.41,1.12-5.73,0.1-6.99c-3.68-4.58-8.63-10.27-13.43-13.75c-0.35-0.26-0.05-0.75,0.33-0.57
      c3.57,1.74,5.99,4.84,8.54,7.76c2.72,3.12,5.62,5.99,7.71,9.58c2.01,3.46,0.44,5-2.14,5.31c-2.59,0.31-4.6-0.95-8.62-0.64
      c-2.22,2.23-6.02,3.41-8.96,2.81c-1.58-0.32-2.27-1.21-2.59-1.86c-0.32-0.65-1.14-0.9-1.36-1.97c-0.2-0.99,0.57-1.76-0.04-2.67
      c-0.36-0.55-1.18-1.38-0.6-1.98C426.18,557.44,424.35,557.32,424.47,555.97z"/>
    <path d="M403.9,539.96c0-0.52,1.58-0.42,1.62-0.04c0.56,5.17,3.34,8.78,8.7,9.09c6.03,0.36,7.59-4.2,7.81-8.73
      c0.01-0.32,4.04-0.68,4.85-0.48c0.58,0.15,0.85,1.25,0.18,1.21c-1.2-0.08-2.63-0.02-3.92,0.22c-0.51,5.16-2.01,9.01-7.74,9.43
      C409.06,551.12,403.97,545.62,403.9,539.96z"/>
    <path d="M399.42,547.63c-0.01-0.05-0.04-0.1-0.07-0.14c-0.19-2.45-0.4-5.09-0.21-7.14c0.05-0.54,0.75-1.59,0.73-1.06
      c-0.11,2.96,0.53,7.01,0.31,10.05C400.05,548.72,399.61,548.26,399.42,547.63z"/>
    <path d="M399.73,538.19c0.59-1.94,5.34-0.58,5.08,0.1c-1.39,3.7-1.1,11.81-1.07,12.33c0.02,0.52-0.65,0.79-0.67,0.56
      c-0.01-0.23-0.97-8.08,0.48-12.1c-1.02-0.12-2.52-0.39-3.57-0.21C399.56,538.96,399.61,538.6,399.73,538.19z"/>
    <path d="M426.22,539.7c-0.05-0.36,0.17-0.55,0.54-0.62c1.75-0.3,3.58-0.32,5.35-0.16c0.33,0.03,0.33,0.19,0.42,0.54
      c1.06,4.07,1.31,9,0.77,12.74c-0.06,0.38-0.72,0.68-0.72,0.37c-0.02-4.31-0.4-7.86-1.04-12.16c-1.24-0.03-2.48-0.05-3.72,0.11
      c0.5,4.14,0.97,7.71,1.49,11.78c0.14,1.07-0.76,0.22-0.97-0.84C427.58,547.62,426.69,543.71,426.22,539.7z"/>
    <path d="M403.1,609.87c0.51,8.5,0.3,15.27-0.06,22.54c-0.92-0.04-1.3,1.58-0.15,1.73c3.66,0.46,12.85,0.31,12.4-0.24
      c-1.81-2.17-1.41-6.45-1.42-9.07c-0.01-3.53,0.16-7.06,0.61-10.57c0.76-5.98,1.56-11.3,3.9-16.71c1.91,2.18,2.51,6.97,3.1,9.48
      c0.61,2.62,1.07,5.23,1.48,7.88c0.96,6.19,2.43,12.62-0.17,18.61c-0.2,0.46,0.97,0.71,2.47,0.63c4.23-0.23,10.62-1.18,10.36-1.47
      c-0.49-0.57-1.11-1.23-1.35-2.38c-0.25-1.15-0.34-3.44-0.4-5.05c-0.13-4.02,0.21-8.03,0.64-12.02c0.88-8.12,1.41-16.81-0.76-24.79
      c0.23-1.72,0.14-4.05-1.14-3.73c-1.29,0.31-11.03,1.76-15.9,1.88c-4.87,0.13-10.77-0.14-12.21-0.6c-1.43-0.46-2.59-0.71-2.61-0.01
      C401.72,592.98,402.62,601.92,403.1,609.87z M404.06,632.45c1.24-7.24,0.72-15.28,0.59-22.55c-0.14-7.46-1.69-15.71-1.56-23.15
      c4.24,1.83,11.04,1.09,15.06,0.82c5.2-0.34,13.85-2.26,14.34-2.09c0.49,0.18,0.12,5.37,0,6.49c-0.06,0.61,0.71-0.78,0.83-1.31
      c0.59,8.07,0.19,15.8-0.6,23.96c-0.42,4.3-0.67,8.51-0.27,12.83c0.15,1.6,0.35,3.25,1.22,4.56c-3.07,0.62-6.09,0.74-9.21,1
      c2.28-6.17,0.62-12.97-0.17-19.32c-0.53-4.26-2.38-15.22-5.12-17.57c0.66-0.77,0.4-1.91,0.22-1.72c-0.13,0.15-1.26,1.39-2.09,2.93
      c-2.41,4.44-3.85,10.92-4.54,16.72c-0.53,4.4-0.56,8.69-0.3,13.11c0.12,2.06,0.35,4.21,1.48,5.94
      C410.71,633.08,407.26,633.01,404.06,632.45z"/>
    <path d="M386.9,657.57c1.51,3.07,5.47,2.78,8.34,2.13c3.8-0.85,15.01-4.83,16.59-9.91c0.28-0.89,0.06-3.87-0.27-3.62
      c-1.78,1.38-3.86,3.16-5.83,4.29c-2.18,1.26-4.54,2.38-7.03,2.87c-1.74,0.35-6.65,1.54-5.29-3.43c0.09-0.32-0.4-0.79-0.65-0.72
      C389.51,650.17,385.02,653.75,386.9,657.57z M392.12,650.09c-0.6,2.89,1.38,5.09,4.66,4.57c5.42-0.87,10.79-3.4,14.48-7.42
      c0.54,4.68-8.48,8.31-12.04,9.51c-2.34,0.78-8.11,3.25-10.89,0.4C385.69,654.45,389.46,651.26,392.12,650.09z"/>
    <path d="M425.93,649.68c2.25,3.83,5.87,6.76,9.98,9.09c3.35,1.91,8.81,3.99,12.67,2.08c6.65-3.3-1.2-9.6-5.18-11.5
      c-1.99-0.96-2.31-0.45-1.93-0.16c1.23,0.96,2.81,2.23,3.55,3.64c1.85,3.5-3.91,2.42-5.3,2.05c-3.56-0.97-9.7-3.23-12.73-6.11
      C426.39,648.18,425.54,649.03,425.93,649.68z M429.08,651.35c2.77,1.58,7.4,3.7,10.41,4.5c2.19,0.59,4.65,1.33,6.59-0.21
      c1.23-0.97,0.17-3.08-0.79-4.41c1.16,0.88,2.89,2.53,3.66,3.67c3.88,5.83-5.34,5.26-7.94,4.3
      C436.75,657.66,432.56,655.17,429.08,651.35z"/>
    <path d="M379.23,562.56c-0.56,4.35,10.14-0.24,13.37-1.52c0.53,2.66,2.17,3.36,3.92,3.79c2.69,0.66,8.03,0.1,8.01-2.1
      c1.99,0.07,3.51-1.64,2.03-3.1c1.86-0.69,1.57-3.62-0.84-4.24c0.71-0.37,1-0.93,0.54-1.93c-0.62-1.35-4.22-1.88-6.5-1.73
      c1.72-3.64-1.28-6.58-2.6-5.36c-0.4,0.37-1.22,3.9-1.36,4.44c-0.48,1.92-2.21,2.41-2.88,4.72c-1.9,0.4-6.22,2.37-7.75,2.91
      c2.52-3.75,7.61-7.9,10.66-11.17c0.73-0.79,1.02-1.67,0.71-1.41c-3.86,3.2-9.71,8.62-12.42,12.8c-0.3,0.11-0.73,0.24-1.03,0.35
      c-0.41,0.15,0.19,0.54,1.48,0.38c1.3-0.15,6.21-2.52,8.05-3.03c-0.17,0.88-0.21,2.35-0.23,3.3c-8.08,4.1-13.87,5.92-11.54-0.03
      c2.63-6.71,8.82-9.56,12.65-14.08c0.24-0.28-1.26,0.41-1.52,0.67C387.15,551.05,380.17,555.21,379.23,562.56z M395.74,552.75
      c1.09-1.18,1.18-3.48,1.66-5.05c0.47-1.56,2.3,0.13,2.23,1.61c-0.06,1.48-0.89,3.22-1.33,3.81c-0.49,0.64,0.99-0.12,1.23-0.6
      c1.43-0.11,4.17-0.12,5.62,0.85c1.37,0.9,0.26,1.79-1.23,1.78c-1.86-0.01-2.13,0.74-1.49,0.71c3.27-0.09,4.78,0.9,4.29,2.38
      c-0.43,1.3-3.6,0.53-4.48,0.48c-0.88-0.04,0.99,1.18,3.47,1.04c1.56,1.44,0.76,2.51-3.39,2.19c-0.55-0.04,0.05,0.63,1.44,0.8
      c-0.28,0.84-2.08,1.5-3.8,1.51c-1.72,0-2.89,0.07-4.25-0.55c-1.37-0.62-2.3-1.58-2.24-3.98
      C393.53,556.99,393.64,555.04,395.74,552.75z"/>
    <path d="M357.06,509.46c14.46-3.34,0.09-17.66,8.24-24.64c0.16-0.14,0.36,0.08,0.22,0.24c-4.07,4.81-0.64,9.74,0.06,14.96
      c0.43,3.21-0.13,7.75-3.19,9.68c-1.92,1.2-3.87,1.47-6.1,1.72c-5.89,0.64-4.18,6.05-2.47,9.19c2.45,4.51,4.33,7.36,1.03,12.13
      c-0.15,0.22-0.53,0.01-0.41-0.23c1.17-2.49,1.87-4.03,1.11-6.9c-0.38-1.48-1.91-3.1-2.78-4.43c-1.03-1.61-1.7-3.42-1.96-5.29
      C350.27,512.08,353.9,510.18,357.06,509.46z"/>
  </g>
  <g id="item_4_">
    <path fill="#FACBB0" d="M748.52,663.42c3.54-4.54,6.73-17.66,6.73-17.66s-3.85,1.63-5.68,2.45
      c-1.83,0.81-5.52,1.51-5.52,1.51c0.03,1.7-0.77,10.36-1.72,13.93L748.52,663.42z"/>
    <path fill="#FACBB0" d="M742.4,663.64c1.08-3.97,1.65-13.93,1.65-13.93l-20.96,1.5c0,0,0.73,9.33,1.12,13.08L742.4,663.64z"
      />
    <path fill="#E3AB96" d="M744.05,663.58c1.03-4.48,1.92-12.2,3.65-12.76c2.37-0.76,5.55-2.34,7.07-3.64
      c0.3-0.87,0.48-1.43,0.48-1.43s-3.85,1.63-5.68,2.45c-1.83,0.81-5.52,1.51-5.52,1.51c0.03,1.7-0.77,10.36-1.72,13.93L744.05,663.58
      z"/>
    <path fill="#E3AB96" d="M734.41,652.25c3.09,0.03,6.27,0.43,9.15,1.54c0.31-2.28,0.49-4.07,0.49-4.07l-20.96,1.5
      c0,0,0.12,1.2,0.28,2.86C726.87,652.71,730.78,652.21,734.41,652.25z"/>
    <path d="M735.19,504.95c-0.12-0.35-0.68-0.3-0.66,0.1c0.12,2.55,0.21,5.49,1.47,7.79c0.1,0.18,0.43,0.21,0.48-0.03
      C737.08,510.2,735.99,507.4,735.19,504.95z"/>
    <path d="M741.33,506.1c-1.03,1.5-2.39,4.35-1.78,6.23c0.08,0.23,0.43,0.37,0.6,0.14c0.6-0.8,0.79-1.87,1.09-2.82
      c0.34-1.08,0.65-2.26,0.74-3.39C742.01,505.87,741.5,505.86,741.33,506.1z"/>
    <path d="M750.09,508.09c-1.8,1.39-3.45,2.73-5,4.4c-0.32,0.34,0.19,0.89,0.56,0.6c1.78-1.4,3.26-2.93,4.78-4.61
      C750.64,508.25,750.36,507.88,750.09,508.09z"/>
    <path d="M753.04,513.79c-1.69,0.08-3.26,0.36-4.66,1.38c-0.26,0.19-0.18,0.68,0.19,0.65c1.67-0.15,3.06-0.88,4.59-1.48
      C753.43,514.23,753.32,513.78,753.04,513.79z"/>
    <path d="M803.99,514.05c-0.27-1.91-3.22-26.22-3.79-28.75c-0.12-0.54-21.07,0.2-21.05,0.54c0.43,7.93,6.14,46.49,6.54,46.55
      c0.35,0.06,0.94-0.05,1.64-0.13c-6.64,4.1-13.64,14.72-15.81,19.12c-3.59-2.25-10.71-3.01-13.92-3.03
      c0.73-4.99-4.91-7.16-7.91-10.46c1.33,0.52,4,0.59,4.69-1.12c-1.51-0.65-4.02-3.04-4.98-5.28c3.02,1.97,8.39,1.89,9.24-1.07
      c-2.07-0.56-6.44-1.91-8.18-3.27c0.65-0.37,1.28-0.77,1.87-1.22c2.12-1.64,4.55-4.88,4.33-7.69c-0.02-0.27-0.3-0.38-0.5-0.24
      c-0.01,0-0.01,0-0.02,0c-3.03,1.49-5.95,3.19-8.99,4.64c-1.43,0.68-2.96,1.39-4.26,2.36c0.01-0.02,0.01-0.03,0.02-0.05
      c0.23-0.61,0.25-1.54-0.62-2.01c0.74-1.24,3.79-5.98,4.34-7.78c-4.45-1.3-12.5-1.34-15.99,2.97c0.28,1,2.37,2.92,3.29,4.01
      c-0.65,0.12-1.19,0.44-1.75,0.98c-0.11-0.13-1.44-0.62-2.11-0.59c-0.07-1.1-1.13-4.43-1.81-6.43c2.5-2.25,3.84-5.9,4.71-8.69
      c0.59-1.91,1.07-3.82,1.45-5.74c3.7,0.76,7.4-1.43,9.74-4.24c1.81-2.18-0.48-5.29,2.55-7.14c1.96-1.19,3.5-2.34,4.73-4.36
      c1.27-2.08,1.77-3.73,1.61-6.13c-0.22-0.97-0.43-1.93-0.65-2.9c0-2.16,0.54-4.13,1.61-5.9c0.83-1.58,1.7-3.11,2.44-4.74
      c2.49-5.46,0.86-13.34-1.48-18.63c-0.9-2.04-1.79-2.5-1.82-5.27c-0.01-1.47,0.83-2.91,0.74-4.39c-0.1-1.63-0.5-3.38-1.85-4.68
      c-2.51-2.4-4.65-0.66-5.45,2.22c-0.6,2.15,1.07,3.04-0.53,4.12c-2,1.35-3.71,0.88-4.69,3.13c-0.71,1.61-1.7,3.21-2.88,3.1
      c1.37-3.01-0.09-4.59,0.15-7.62c0.22-2.91,0.13-5.89-1.32-8.51c-1.9-3.43-8.06-3.47-8.16,1.09c2.87-2.14,2.92,1.78,2.18,3.87
      c-0.86,2.42-2.37,3.54-3.88,4.5c-2.65,1.69-5.26,2.01-8.12,3.04c-1.76,0.64-3.33,1.53-4.46,3c-1.28,1.66-1.8,3.23-3.26,4.57
      c-2.75,0.18-5.29,0.76-7.27,3.33c-2.56,3.32-3.25,7.92-5.02,11.57c-2.82,0.06-5.57,0.8-7.38,2.41c-2,1.78-2.07,5.17-1.21,7.63
      c-1.44-0.02-2.93,0.43-4.15,1.17c-0.98,0.6-1.85,1.39-2.17,2.53c-0.15,0.53-0.06,1.08,0.18,1.56c-0.47-0.15-0.94-0.32-1.41-0.55
      c-2.98-1.45-0.63-6.07-3.47-7.88c0.62-1.74,0.95-3.49,0.16-5.34c-0.09-0.22-0.42-0.34-0.63-0.26c-3.06,1.14-3.38,3.56-4.03,6.51
      c-0.82,3.69-3.99,5.8-3.04,9.9c0.34,1.47,2.43,2.73,3.21,3.82c1.45,2,1.13,5.57,1.9,7.89c0.85,2.57,3.12,3.98,5.15,5.59
      c-1.05,2.25-0.7,5.36,0.65,7.38c1.83,2.76,6.33,2.16,8.79,3.93c2.54,1.83,4.05,4.15,7.4,4.25c2.35,0.07,3.82-1.88,5.15-3.78
      c1.69,0.9,3.35,1.49,5.39,1.46c1.47-0.02,2.65-0.66,2.9-2.12c2.52,3.29,6.3,6.59,10.32,7.02c0.19,0.86,1.56,4.57,2.09,5.39
      c-0.28,0.21-0.51,0.48-0.69,0.78c-0.54-0.18-1.1-0.26-1.68-0.19c-4.67,0.6-7.68,1.85-12.36,2.51c-3.2-0.86-5.1,1.97-5.39,5.15
      c-0.66,7.18,1.03,14.77,4.1,20.26c1.57,8.53,5.4,21.64,8.7,27.34c0.47,3.35,1.35,6.63,1.8,9.99c0.58,4.33-1.44,8-3.13,11.81
      c-2.68,6.04-3.2,17.31-3.33,20.64c-0.12,3.33-3.36,8.25-5.4,13.16c-1.01,2.42-1.08,5.22-1.96,7.69c-1.03,2.87-4.14,2.73-5.55,4.93
      c-0.47,0.73-1.37,2.25-1.18,2.95c0.11,0.41,16.7,1.18,23.18,0.95c0.7,3.23,1.26,9.7,1.7,13.07l0.7-0.03
      c0.03-2.4-0.24-8.95-0.96-12.75c6.61-0.19,13.25-0.67,19.81-1.47c-0.38,3.18-0.97,9.96-1.75,13.6l1.15-0.04
      c0.83-2.78,1.87-10.07,1.82-13.72c3.41-0.52,6.54-1.87,9.61-3.37c-1.09,4.79-3.61,12.89-5.98,16.89l0.65-0.02
      c0.35-0.36,0.73-0.79,1.11-1.29c1.77-2.39,5.24-11.34,5.99-16.44c2.24-1.28,4.9-2.32,7.25-3.2c5.49-2.03,11.11-3.36,14.4-8.5
      c0.75-1.17-3-2.64-5.45-1.65c-3.42,1.38-3.77-2.12-5.46-3.95c-1.68-1.81-3.49-3.13-4.92-5.2c-1.23-1.78-2.23-4.2-2.26-6.19
      c-0.05-3.24-2.17-18.36-8.34-25.95c-1.43-1.76-3.89-5.1-4.44-7.07c-1.63-7.18,3.88-18.17,5.15-26.85
      c7.85-0.07,14.85,2.15,17.16,4.12c0.74,0.63,1.39,1.86,2.67,1.89c2.61,0.05,5.17-3.22,6.83-6.04c2.79-4.74,5.17-9.77,7.83-14.58
      c1.62-2.94,3.15-6.9,6.02-8.87c2.5,1.04,6.25,0.8,6.15-1.29c0.78,0.12,1.87-0.15,2.54-0.69c1.22-0.99,0.86-3.87,0.48-6.02
      c0.69,1.02,1.58,2.04,2.79,2.28c1.33,0.27,2.56-0.34,2.96-1.86c0.47-1.75-0.91-4.22-2.22-5.69c-0.01-0.01-0.02-0.03-0.03-0.04
      c0.79,0.37,2.34,0.67,3.32,0.73c1.62,0.1,2.89-0.5,3.06-2.59C811.78,516.1,807.79,514.33,803.99,514.05z"/>
    <path fill="#FFFFFF" d="M786.82,531.32c-0.41-0.05-7-44.32-6.67-44.68c0.33-0.36,18.9-0.95,19.26-0.49
      c0.36,0.47,1.84,16.88,3.4,27.92c-0.79,0.06-2.1,0.09-2.46,0.29c-0.4,0.22,0.31,1.3,0.7,1.76c-0.91-0.7-2.35-1.58-3-1.38
      c-0.38,0.12-0.02,3,0.35,4.23c-0.79-1.44-1.99-3.33-3.4-3.58c-0.29-0.05-0.73,3.12-0.15,6.37c-0.58-0.96-1.11-1.75-1.92-2.52
      c-0.32-0.29-0.58-0.07-0.68,0.23c-1.08,3.33-0.08,7.01,1,10.27C791.45,530.46,787.23,531.37,786.82,531.32z"/>
    <path fill="#D9E8E8" d="M782.01,490.25c-0.1-0.55-0.86-2.03-1.86-3.21c0.31,4.22,5.83,41.23,6.6,44.1
      c0.66-0.2,1.1-0.92,1.18-1.34C788.01,529.39,782.16,491.09,782.01,490.25z"/>
    <path fill="#D9E8E8" d="M792.05,520.15c-1.46,1.77-1.41,4.76-1.22,6.87c0.1,1.09,0.23,2.23,0.48,3.34
      c0.74-0.21,1.43-0.42,1.92-0.62C792.23,526.71,791.3,523.3,792.05,520.15z"/>
    <path fill="#D9E8E8" d="M794.71,516.22c-0.87,0.89-1.25,2.43-1.18,3.67c0.5,0.57,0.89,1.18,1.31,1.88
      C794.45,519.55,794.53,517.37,794.71,516.22z"/>
    <path fill="#D9E8E8" d="M797.91,515.06c-0.53,0.34-0.83,0.98-1,1.68c0.58,0.69,1.08,1.51,1.48,2.24
      C798.09,517.97,797.79,515.85,797.91,515.06z"/>
    <path fill="#D9E8E8" d="M802.06,508.43c-0.51-4.12-0.99-8.46-1.4-12.2C799.18,500.45,798.97,505.44,802.06,508.43z"/>
    <path fill="#FACBB0" d="M753.04,536.98c-2.13,0.44-4.53-0.16-6.36-3.71c0.08-1.2-0.03-2.39-1.09-3.12
      c-0.3-0.2-0.64-0.3-1.01-0.35c0.86-0.34,1.74-0.68,2.6-1.04C747.45,530.47,750.56,535.36,753.04,536.98z"/>
    <path fill="#FACBB0" d="M757.39,530.89c-0.77,1.08-4.91,1.68-8.6-0.63c-0.31-0.61-0.62-1.22-1-1.77
      c0.64-0.28,1.27-0.58,1.89-0.9C752.31,530.02,756.04,530.5,757.39,530.89z"/>
    <path fill="#FACBB0" d="M755.76,518.66c-0.11,3.2-3.89,6.26-5.44,7.25c-3.34,2.15-5.87,2.65-7.29,3.79
      c-0.42,0.34-1.18,0.85-1.86,1.02c-0.84-0.72-0.73-2.65-0.57-3.25C742.81,525.81,751.36,522.07,755.76,518.66z"/>
    <path fill="#D9B00D" d="M740.23,531.43c-0.34,0.21-0.98,0.46-1.31,0.69c-0.88-0.85-1.39-2.62-0.47-4.12
      c0.27-0.45,1.07-0.69,1.4-0.5C739.45,528.86,739.46,530.36,740.23,531.43z"/>
    <path fill="#D9B00D" d="M738.35,527.21c-1.81-0.48-2.48-1.33-2.02-1.86c0.91-1.04,5.32-2.11,5.65-1.33
      c0.35,0.82-0.38,1.74-1.93,2.54C739.46,526.54,738.82,526.8,738.35,527.21z"/>
    <path fill="#C29100" d="M739.72,527.98c0.03-0.16,0.07-0.32,0.12-0.48c-0.33-0.19-1.13,0.05-1.4,0.5
      c-0.09,0.15-0.17,0.3-0.23,0.45C738.71,528.21,739.27,528.07,739.72,527.98z"/>
    <path fill="#C29100" d="M738.41,531.45c0.14,0.26,0.31,0.49,0.5,0.67c0.33-0.23,0.97-0.47,1.31-0.69
      c-0.13-0.18-0.24-0.38-0.33-0.59C739.58,530.81,738.69,531.15,738.41,531.45z"/>
    <path fill="#C29100" d="M739.02,525.85c-0.53,0.22-1.2,0.54-1.69,0.99c0.28,0.14,0.62,0.26,1.01,0.37
      c0.47-0.41,1.11-0.68,1.71-0.64c0.52-0.27,0.93-0.55,1.25-0.84C740.56,525.51,739.78,525.55,739.02,525.85z"/>
    <path fill="#F0C95B" d="M739.66,530.05c-0.07-0.4-0.09-0.82-0.06-1.24c0.01-0.14-1.58,0.3-1.6,0.45
      c-0.07,0.47-0.03,0.94,0.08,1.37C738.61,530.42,739.13,530.22,739.66,530.05z"/>
    <path fill="#F0C95B" d="M742.06,524.39c-0.01-0.12-0.03-0.24-0.08-0.36c-0.33-0.78-4.74,0.29-5.65,1.33
      c-0.19,0.21-0.18,0.48,0,0.75C736.97,525.22,740.16,524.05,742.06,524.39z"/>
    <path fill="#F5E3FA" d="M732.09,518.81c1.16-0.33,3.25-0.61,4.75-0.93c-0.45,0.86-0.92,1.71-1.36,2.57
      c-0.04,0.08,0.08,0.14,0.12,0.06c0.49-0.92,1.79-2.75,2.03-3.31c-1.36,0.04-4.49,0.71-5.13,0.85c1.34-1.17,2.62-2.01,4.58-2.36
      c1.61-0.29,3.27-0.28,4.93-0.18c-1.24,0.43-2.41,1.15-3.58,1.86c-0.08,0.05-0.01,0.19,0.07,0.15c1.66-0.82,5.02-2.24,7.09-1.74
      c-0.92,1.37-3.24,5.4-3.66,6.22c0.13-3.3-0.21-4.88-0.24-4.5c-0.14,1.54-0.36,5.33-0.37,5.42c-0.39,0-1.5,0.13-1.69,0.18
      c-0.56-1.6-1.5-4.62-1.6-5.1c-0.1-0.48-0.19,0.45-0.09,0.98c0.1,0.52,0.75,2.9,1.16,4.25c-0.87,0.29-1.73,0.63-2.03,0.78
      C735.58,522.5,733.27,520.15,732.09,518.81z"/>
    <path fill="#E9C2E4" d="M733.65,517.14c-0.39,0.27-0.76,0.58-1.14,0.91c0.48-0.11,2.37-0.52,3.86-0.73
      C735.46,517.23,734.56,517.16,733.65,517.14z"/>
    <path fill="#E9C2E4" d="M738.56,518.36c0.34,1.6,0.94,3.11,1.47,4.66c0.12-0.02,0.25-0.03,0.4-0.05
      c-0.08-0.73-0.15-1.47-0.19-2.21c-0.05-0.88,0.01-1.76-0.03-2.63C739.65,518.19,739.1,518.27,738.56,518.36z"/>
    <path fill="#E9C2E4" d="M741.98,520.34c0,0.49,0,1.04-0.03,1.65c0.41-0.82,2.74-4.85,3.66-6.22
      c-0.21-0.05-0.43-0.08-0.66-0.09C743.73,517.08,742.66,518.6,741.98,520.34z"/>
    <path fill="#E9C2E4" d="M736.45,519.13c-0.33,0.51-0.65,1.02-0.85,1.38c-0.04,0.08-0.17,0.02-0.12-0.06
      c0.27-0.52,0.55-1.03,0.82-1.55c-0.66,0.12-1.3,0.23-1.99,0.31c-0.54,0.06-1.01,0.24-1.48,0.41c1.25,1.35,3.02,3.14,4.26,4.37
      c0.19-0.1,0.62-0.27,1.13-0.46C737.45,522.14,736.87,520.67,736.45,519.13z"/>
    <path fill="#F0A80A" d="M709.06,511.91c-1.08-0.2-2.94-0.88-3.82-1.55c0.39-0.54,0.79-1.03,1.2-1.43
      c0.17-0.16,0.05-0.49-0.2-0.44c-1.8,0.38-2.78,1.93-3.97,3.24c-3.34,3.71-6.49,0.33-9.29-1.98c-1.71-1.41-3.59-1.69-5.68-2.16
      c-4.22-0.96-3.11-3.85-3.5-6.67c0.91,0.88,1.66,1.88,2.06,3.17c0.08,0.26,0.49,0.28,0.5-0.03c0.1-2.17-0.77-3.62-2.3-5.11
      c-1.14-1.11-2.48-2.01-3.65-3.1c-2.27-2.1-2.32-5.2-2.83-8.02c-0.21-1.21-0.82-2.67-1.69-3.52c-2.61-2.56-2.4-5.13-0.64-8.17
      c0.79-1.37,1.24-3.26,1.54-4.82c0.17-0.9,0.46-2.76,2.08-3.94c0.49-0.35,0.53,1.26,0.35,2.56c-0.17,1.21-0.89,3.47-1.03,4.07
      c-0.07,0.32,0.7-0.07,1.13-1.39c1.04,1.56,0.75,2.93,1.15,4.8c0.25,1.17,0.71,2.08,1.65,2.82c2.06,1.62,5.08,0.94,7.05,2.87
      c0.22,0.22-0.14-1.77-3.2-2.65c-0.95-1.25-0.49-2.64,1.73-3.88c1.73-0.97,4.48-0.59,6.3,0.19c0.31,0.13-0.46-1.29-2.35-1.84
      c-0.59-2.08-0.44-6.27,2.75-7.81c1.17-0.56,2.79-0.93,4.15-1.02c-0.41,0.62-1.02,1.32-1.57,1.87c-0.22,0.22,0.82,0.02,1.53-0.58
      c2.49-2.11,2.99-3.33,3.96-6.33c1.33-4.13,2.69-9.11,7.82-9.61c3.83-0.37,5.71-1.73,5.17-1.76c-0.74-0.04-1.47,0.05-2.2,0.08
      c1.13-0.67,2.76-5.16,6.32-6.18c4.32-1.24,8.98-2.46,11.94-6.09c1.72-2.11,2.43-8.74-0.91-8.01c0.63-1.47,2.61-1.54,3.75-0.93
      c2.97,1.6,3.79,5.71,3.18,9.83c-0.48,3.26,1.15,5.88-0.48,7.5c-0.78,0.45-1.58,0.91-2.37,1.48c-2.57,1.86-3.28,5.14-6.94,5.59
      c-0.16,0.02-0.2,0.28-0.02,0.3c3.02,0.31,4.45-1.31,6.42-3.41c1.67-1.79,3.91-1.27,5.88-2.39c1.13-0.64,1.67-2.02,2.12-3.15
      c0.83-2.11,2.18-2.86,4.08-3.73c0.68-0.31,1.07-0.74,1.29-1.41c0.1-0.3-0.31-2.06-0.19-2.9c0.41-2.87,3.41-3.88,5.09,0.1
      c0.96,2.29-0.63,6.11-0.02,8.7c0.44,1.88,1.79,3.51,2.32,5.39c0.92,3.21,1.25,7.22,1.08,10.55c-0.28,5.27-4.72,9.35-5.42,14.59
      c-0.13,0.98,0.26,2.15,0.49,3.08c0.68,2.71,0.02,5.09-1.58,7.3c-1.33,1.83-3.38,2.62-4.91,4.2c-0.9,0.94-0.98,2.22-0.98,3.45
      c-0.02,4.09-4.78,7.1-8.71,6.55c1.28-7.52,1.05-15.12-0.24-22.76c-0.74-4.36-1.88-7.8-4.33-11.85c-1.6-2.65-3.75-5.79-6.44-7.81
      c3.66,0.75,7.63-0.07,11.08-1.88c1.56-0.82,1.82-1.94,1.64-1.8c-2.03,1.4-6.77,3.01-13.61,2.43c-1.02-0.54-1.68-0.97-3.01-1.11
      c-4.78-0.51-8.43,3.94-11.9,6.72c0.1-1.85,0.66-4.14,1.38-4.8c0.78-0.73,1.41-0.1,1.72,0.59c0.11,0.24,0.4-0.18,0.36-0.75
      c-0.08-1.06-0.95-2.12-2.28-1.67c-2.06,0.69-2.33,4.83-2.53,7.73c-1.81,1.71-2.92,3.93-1.33,6.22c2.72,3.92,0.16,7.19-0.76,10.59
      c-0.27-0.7-0.55-1.61-0.68-2.7c-0.03-0.23-0.39,0.04-0.36,1.1c0.17,4.81,3.02,7.58,4.22,11.87c1.09,3.93,0.77,7.67-0.68,11.42
      c-0.75,1.93-0.88,4.09,0.44,5.77c0.13,0.17,0.58,0.08,0.49-0.11c-0.93-1.88-0.75-3.11,0.25-5.37c1,3.03,2.43,5.86,3.75,7.74
      C711.71,511.16,710.96,512.26,709.06,511.91z"/>
    <path fill="#F6C66D" d="M735.12,433.29c-0.29-2.83-1.59-3.75-3.27-3.67c1.9,0.99,1.18,6.09-0.3,7.9
      c-2.28,2.79-5.57,4.16-8.93,5.21c2.39-0.51,4.76-1.27,6.9-2.35C732.17,439.02,735.47,436.73,735.12,433.29z"/>
    <path fill="#F6C66D" d="M735.91,454.4c-2.28-0.07-4.56,0.21-6.84,0.71c-2.11,0.46-4.28,1.35-6.46,1.28
      c0.07,0.04,0.14,0.07,0.21,0.11C729.14,457.05,733.66,455.71,735.91,454.4z"/>
    <path fill="#F6C66D" d="M707.11,501.34c1.34-3.63,1.61-7.25,0.55-11.05c-0.38-1.38-0.94-2.6-1.52-3.78
      c0.4,2.42,0.93,4.82,1.21,7.26c0.18,1.56,0.24,3.05,0.05,4.6C707.27,499.37,707.14,500.36,707.11,501.34z"/>
    <path fill="#F6C66D" d="M693.78,467.52c-1.35,0.93-2.02,2.41-2.26,3.89C692.89,470.55,693.48,469.09,693.78,467.52z"/>
    <path fill="#F6C66D" d="M706.29,455.08c0.93-1.35,2.4-2.22,3.49-3.42c0.03-0.04,0.07-0.08,0.1-0.12
      c-4.74,0.75-6.07,5.54-7.36,9.54c-0.38,1.17-0.69,2.08-1.08,2.87C704.2,461.95,704.41,457.82,706.29,455.08z"/>
    <path fill="#F6C66D" d="M697.07,477.62c-0.8,2.87-0.07,5.43,0.49,8.27c0.47,2.37,0.09,4.75,0.05,7.14
      c-0.02,1.23,0.06,2.42,0.26,3.57c0.2-0.8,0.48-1.59,0.77-2.39c0.51-1.4,1.17-2.98,1.08-4.5c-0.09-1.35-0.59-2.47-1.11-3.69
      c-1.94-4.58-1.21-8.67,1.16-12.7C698.47,474.42,697.54,475.91,697.07,477.62z"/>
    <path fill="#F6C66D" d="M684.39,487.32c0.26,1.57,0.37,3.23,0.92,4.73c0.31,0.84,0.74,1.56,1.26,2.21
      c-0.79-3.87-0.6-8.48-3.32-11.24C683.89,484.35,684.17,485.98,684.39,487.32z"/>
    <path fill="#F6C66D" d="M676.7,474.73c0.94-2.41,1.72-4.85,2.21-7.36c-0.01,0-0.01,0.01-0.02,0.01
      c-1.62,1.18-1.91,3.03-2.08,3.94c-0.3,1.56-0.74,3.45-1.54,4.82c-1.21,2.09-1.69,3.96-1.02,5.76
      C674.47,479.41,675.78,477.09,676.7,474.73z"/>
    <path fill="#F6C66D" d="M696.91,493.05c0.04-1.55,0.26-3.12,0.21-4.66c-0.05-1.77-0.52-3.43-0.85-5.16
      c-0.53-2.77-0.21-5.69,1.09-8.03c-1.12,1.49-1.9,3.23-2.27,5.06c-0.51,2.53-0.14,4.69,0.21,7.2c0.51,3.59,0.58,7.42,2.16,10.59
      C697.03,496.44,696.86,494.78,696.91,493.05z"/>
    <path fill="#F6C66D" d="M705.24,469.42c-0.89-1.29-0.94-2.55-0.48-3.72c-0.5,0.79-0.82,1.68-0.82,2.65
      c0,1.72,0.69,2.86,1.42,4.32c0.51,1.03,0.63,2.09,0.59,3.17C706.61,473.82,706.85,471.73,705.24,469.42z"/>
    <path fill="#F6C66D" d="M739.32,463.38c-2.39,4.4-1.58,8.52-0.57,13.16c0.27,1.23,0.39,2.39,0.37,3.52
      c1.41-2.26,0.77-5.32,0.45-7.88c-0.36-2.84-0.2-5.67,1.41-8.13c1.59-2.43,3.92-4.13,5.85-6.26c0.77-0.86,1.39-1.76,1.88-2.7
      C745.56,457.8,741.27,459.79,739.32,463.38z"/>
    <path fill="#F6C66D" d="M731.14,452.59c1.13-0.25,2.24-0.69,3.37-1.27c1.85-0.94,3.75-1.64,5.64-2.47
      c2.22-0.98,2.79-2.8,4.09-4.67c0.96-1.39,3-2.09,3.66-3.66c0.68-1.62,0.09-3.51,1.42-4.9c0.59-0.62,1.37-1.06,2.2-0.83
      c-1.68-2.01-3.82-0.92-4.16,1.47c-0.12,0.84,0.29,2.6,0.19,2.9c-0.23,0.67-0.62,1.1-1.29,1.41c-1.9,0.87-3.25,1.62-4.08,3.73
      c-0.45,1.13-0.99,2.51-2.12,3.15c-1.97,1.12-4.21,0.6-5.88,2.39C733.12,450.98,732.22,451.97,731.14,452.59z"/>
    <path fill="#DB8C14" d="M707.51,503.77c0.23,1.2,0.8,2.47,1.38,3.36c0.54,0.84,0.81,1.54,0.24,2.44
      c-0.73,1.15-2.31,0.79-3.34,0.09c-0.18,0.22-0.36,0.45-0.53,0.69c0.88,0.67,2.74,1.35,3.82,1.55c1.9,0.35,2.65-0.75,2.85-2.17
      c-1.32-1.89-2.75-4.71-3.75-7.74C707.87,502.67,707.65,503.24,707.51,503.77z"/>
    <path fill="#DB8C14" d="M735.69,443.2c-1.99,1.69-5.26,2.04-6.81,4.21c-1.25,1.75-1.52,4.11-2.82,5.73
      c0.92-0.46,1.58-1.07,2.1-2.12c0.49-0.97,0.95-2.04,1.71-2.84c1.77-1.83,4.4-2.3,6.18-4.12c0.61-0.63,1.15-1.33,1.61-2.07
      c-0.03-0.26-0.07-0.52-0.1-0.79C737.02,441.91,736.41,442.59,735.69,443.2z"/>
    <path fill="#DB8C14" d="M735.8,455.17c-0.25,0.24-0.57,0.49-1,0.71c-3.45,1.8-7.42,2.63-11.08,1.88
      c1.55,1.16,2.91,2.71,4.1,4.31C731.88,462.09,735.07,459.13,735.8,455.17z"/>
    <path fill="#DB8C14" d="M736.49,455.34c-0.79,4.12-4.02,7.19-8.16,7.42c0.28,0.39,0.53,0.78,0.79,1.17
      C732.99,464.23,737,459.23,736.49,455.34z"/>
    <path fill="#DB8C14" d="M741.39,467.13c-0.93,3.57,0.89,7.22,0.47,10.88c-0.1,0.88-0.28,1.74-0.54,2.55
      c0.73-0.87,1.25-1.95,1.53-3.28c0.61-2.9,0.32-5.93,1.27-8.75c1-2.97,3.53-5.06,4.91-7.82c0.87-1.74,1.2-3.51,1.28-5.32
      c-0.35,0.89-0.81,1.76-1.37,2.6C746.78,461.2,742.41,463.2,741.39,467.13z"/>
    <path fill="#DB8C14" d="M709.3,457.31c-0.59,0.55-1.08,2.24-1.29,3.86c0.64-1.43,1.57-2.68,2.64-3.84
      C710.3,456.95,709.84,456.8,709.3,457.31z"/>
    <path fill="#DB8C14" d="M719.38,443.69c-3.4,1.15-4.98,5.44-6.09,6.1c0.53-0.02,1.06-0.07,1.59-0.08
      C715.97,447.51,717.52,445.31,719.38,443.69z"/>
    <path fill="#DB8C14" d="M698.7,478.17c-0.6,3.56,0.93,6.07,2.89,8.85c1.56,2.23,2.8,4.64,2.5,7.45
      c-0.23,2.22-0.95,4.38-0.96,6.54c0.86-1.63,2.35-3.06,2.57-5.01c0.48-4.38-2.05-7.16-3.87-10.82c-1.97-3.97-2.24-9.73,0.57-13.41
      C700.38,473.2,699.11,475.81,698.7,478.17z"/>
    <path fill="#DB8C14" d="M679.73,483.32c0.74,1.35,1.03,2.87,1.3,4.37c0.54,3,1.02,6.95,4.11,7.86
      c-1.68-1.6-1.92-4.34-2.06-7.05c-0.13-2.54-0.33-5.57-3.05-6.7c-1.16-0.48-2.38-0.71-3.5-1.12
      C677.62,481.52,679.04,482.06,679.73,483.32z"/>
    <path fill="#DB8C14" d="M693.72,476.17c-0.1-0.72-0.27-1.44-0.64-2.03c-0.42-0.67-0.99-1.01-1.66-1.18
      c0.01,0.75,0.1,1.44,0.26,1.99C692.67,475.23,693.34,475.76,693.72,476.17z"/>
    <path fill="#DB8C14" d="M688.49,481.84c-0.09-0.57-0.25-1.12-0.53-1.6c-0.54-0.92-1.37-1.39-2.3-1.67
      c-0.25,0.65-0.13,1.29,0.34,1.9C687.2,480.82,687.99,481.35,688.49,481.84z"/>
    <path fill="#DB8C14" d="M734.72,447.38c0.79-0.57,1.59-1.02,2.37-1.48c0.74-0.73,0.81-1.67,0.71-2.77
      c-0.29,0.39-0.59,0.76-0.93,1.1c-2.2,2.26-5.78,2.8-7.34,5.67c-0.64,1.19-1.15,2.13-1.88,2.88c0.17-0.04,0.34-0.05,0.53-0.01
      c0.1,0.02,0.19,0.05,0.29,0.07C731.54,452.08,732.32,449.11,734.72,447.38z"/>
    <path fill="#DB8C14" d="M697.41,467.58c0.43-0.47,0.86-0.99,1.18-1.46c-1.36,0.09-2.98,0.46-4.15,1.02
      c-0.11,0.05-0.2,0.11-0.3,0.17C695.22,467.16,696.32,467.25,697.41,467.58z"/>
    <path fill="#DB8C14" d="M694.22,509.15c-2.09-1.78-5.48-1.27-7.33-3.27c-0.55-0.6-0.91-1.29-1.11-2.03
      c-0.41-1.17-1.12-2.11-1.97-2.93c0.38,2.82-0.72,5.71,3.5,6.67c2.09,0.48,3.97,0.75,5.68,2.16c1.52,1.25,3.15,2.83,4.85,3.4
      C696.51,511.92,695.33,510.1,694.22,509.15z"/>
    <path fill="#DB8C14" d="M737.11,457.14c-0.61,3.68-4,7.43-7.53,7.52c0.2,0.31,0.39,0.62,0.57,0.92
      c0.12,0.19,0.21,0.37,0.32,0.56C734.09,465.34,737.18,460.95,737.11,457.14z"/>
    <path fill="#DB8C14" d="M752.73,446.01c-0.08,0.7-0.02,1.45,0.19,2.29c0.65,2.5,1.83,4.96,1.74,7.59
      c-0.1,3.23-1.26,6.03-3,8.71c-1.57,2.4-3.41,4.82-3.94,7.71c-0.89,4.79,0.98,10.39-3.71,13.68c-1.21,0.86-3.25,1.26-4.12,2.45
      c-0.98,1.35-1.09,2.99-1.86,4.42c-0.66,1.23-1.56,2.36-2.75,2.77c-0.14,1.52-0.32,3.03-0.58,4.54c3.93,0.55,8.69-2.46,8.71-6.55
      c0.01-1.22,0.08-2.5,0.98-3.45c1.53-1.59,3.58-2.37,4.91-4.2c1.6-2.22,2.26-4.59,1.58-7.3c-0.23-0.93-0.62-2.1-0.49-3.08
      c0.7-5.24,5.15-9.32,5.42-14.59c0.18-3.33-0.16-7.34-1.08-10.55C754.31,448.9,753.31,447.51,752.73,446.01z"/>
    <path fill="#FACBB0" d="M732.76,475.22c-1.46-7.9-9.45-17.44-12.28-18.36c-2.83-0.92-5.86,0.82-9,4.04
      c-1.01,1.04-2.66,1.85-3.88,2.93c-0.09,0.48-0.26,1.57-0.36,2.04c-0.06,0.29-0.55-0.66-0.62-1.42c-0.88,1.2-1.51,2.71-0.1,4.48
      c0.48,0.6,1.47,2.44,1.47,3.25c0.04,3.47-1.67,5.61-2.83,8.73c2.11,5.54,6.94,13.49,3.53,19.85c1.82,5.85,6.87,14.34,13.44,15.44
      c8.26,1.39,10.35-12.27,11.28-17.93C734.62,490.83,734.12,482.65,732.76,475.22z"/>
    <path fill="#E3AB96" d="M719.87,458.27c-2.16-0.63-3.2,0.95-3.23,2.82c2.46,0.15,4.87,0.54,7.07,1.52
      c-1.08-1.45-2.26-2.83-3.47-4.2C720.12,458.36,720,458.31,719.87,458.27z"/>
    <path fill="#E3AB96" d="M726.13,476.24c-0.22,2.39-2.25,3.94-4.47,4.7C724.74,483.45,728.51,477.88,726.13,476.24z"/>
    <path fill="#E3AB96" d="M706.87,469.46c-0.16-0.87-0.28-1.76-0.09-2.58c0.08-0.35,0.2-0.69,0.32-1.02
      c-0.17-0.2-0.44-0.86-0.49-1.41c-0.88,1.2-1.51,2.71-0.1,4.48C706.61,469.06,706.74,469.25,706.87,469.46z"/>
    <path fill="#E3AB96" d="M708.5,463.37c0.15-0.2,0.31-0.39,0.46-0.58c-0.48,0.33-0.95,0.67-1.37,1.04
      c-0.06,0.35-0.17,1-0.26,1.52C707.65,464.65,708.04,463.99,708.5,463.37z"/>
    <path fill="#FACBB0" d="M724.17,518.17c1.2-0.08,2.27-0.54,3.22-1.25c0.39,1.3,1.21,3.71,1.75,5.83
      c-0.46,0.08-1.21,0.75-0.64,0.65c1-0.18,2.27,0.08,3.22,0.7c-0.34,0.7-0.63,2.13-0.72,2.62c-0.76,3.84-2.53,0.43-3.3-0.37
      c-0.67-0.7-1.47-1.42-2.37-1.92c0.51-0.57,1.14-0.96,1.62-1.3c0.35-0.26-0.38-0.43-1.06-0.09
      C725.39,521.5,724.59,519.44,724.17,518.17z"/>
    <path fill="#E3AB96" d="M726.56,519.57c0.52-0.2,0.99-0.46,1.42-0.76c-0.23-0.74-0.44-1.39-0.59-1.89
      c-0.96,0.71-2.03,1.17-3.22,1.25c0.16,0.47,0.37,1.06,0.6,1.68C725.37,519.87,725.97,519.8,726.56,519.57z"/>
    <path fill="#CF2B6B" d="M718.72,525.96c2.26-0.44,4.31-1.39,6.58-0.25c1.71,0.86,2.39,2.81,4.01,3.6
      c0.47,0.23,1.25,0.63,1.64-0.08c0.48-0.91,0.77-1.75,1.02-2.73c0.54-2.13,1.18-3.18,2.67-3.64c0.28,0.27,0.55,0.53,0.82,0.8
      c0.29,0.29,0.56,0.6,0.89,0.78c-0.27,0.2-0.51,0.43-0.69,0.71c-0.32,0.48-0.22,1.22,0.17,1.65c0.27,0.29,1.02,0.73,2.07,0.93
      c-0.34,0.34-0.52,0.81-0.62,1.24c-1.31,0.83-2.24,1.84-3.03,2.86c-3.91,2-7.47,5.48-9.73,9.22c-2.4,3.97-2.19,7.77-1.9,12.17
      c-0.48,2.38-0.69,5.6-1.02,8.01c-0.34-1.67-1.82-8.32-2.63-10.64c-0.81-2.32-0.97-6.87-2.05-12.27c-0.99-4.93-2.6-9.49-5.49-11.09
      C713.98,526.88,715.96,526.5,718.72,525.96z"/>
    <path fill="#FACBB0" d="M716,572.15c-3.89-9.78-5.47-19.79-7.06-30.14c-0.21-1.37-0.51,4.11-0.19,6.1
      c-1.37-3.97-3.79-13.35-2.26-17.95c1.59-4.77,4.99-1.46,6.17,0.59c2.81,4.88,3.72,12.06,4.61,18.11
      c0.84,5.73,3.09,10.53,3.89,16.23c-0.63,7.96,0.35,15.8,5.51,22.57c0.15,0.2,0.43-0.02,0.31-0.22c-6.82-10.94-4.14-22.18-3.4-34.17
      c-0.52-5.59,0.55-9.49,2.16-12.18c1.72-2.88,4.99-6.27,7.07-7.28c-1.05,1.34-1.17,2.5-1.06,2.35c0.97-1.23,3.6-4.52,5.45-6.1
      c0.05,0.97,0.39,1.98,0.85,2.71c-2.42,1.95-3.23,3.55-3.06,3.41c2.48-1.98,11.03-8.36,10.53-2.87c-0.33,3.65-1.62,8.45-0.62,12.08
      c-1.78,0-2.51-1.79-2.65-3.92c-0.15-2.31,0.78-5.19,1.31-7.36c0.04-0.17-0.57,0.1-0.97,1.13c-2.07,1.01-3.93,1.93-6.02,2.88
      c-1.7,0.77-2.2,2.33-2.99,3.93c-1.07,2.17-2,4.68-3.34,6.52c-1.76,2.42-4.04,3.31-4.76,3.66c-0.19,0.09,0.54,0.35,1.07,0.14
      c-0.34,2.92,0.69,6.04,1.2,8.88c0.71,3.92,1.74,7.72,2.78,11.56c1.1,4.07,2.18,8.82,1.55,13.06c-0.25,1.66-0.81,6.09-2.54,6.41
      c-1.73,0.32-4.68-4.2-6.47-6.98C720.35,581.06,717.86,576.84,716,572.15z"/>
    <path fill="#CF2B6B" d="M743.11,559.17c-1.1,0.49,2.53,0.22,7.89-2.01c-1.25,4.8-2.69,9.54-4.11,14.29
      c-1.35,4.54-2.57,8.83-1.37,13.56c0.59,2.31,2.74,4.68,4.2,6.47c2.29,2.81,3.65,5.36,4.83,8.59c1.95,5.35,3.72,13.91,4,17.76
      c0.27,3.85,3.13,7.51,6.68,10.53c1.36,1.17,2.02,4.61,3.95,5.15c0.53,0.15,1.05,0.18,1.56,0.15l-0.01,0
      c-5.13,2.66-10.41,5.11-15.6,7.75c-6.94,3.54-15.17,3.87-22.76,4.66c-7.74,0.81-13.85,0.71-21.62,1.25
      c-1.22,0.09-1.89,0.47-1.72,0.48c11.82,0.34,23.65-0.3,35.37-2.04c5.99-0.89,11.09-3.11,16.32-6.04c2.37-1.32,5.55-2.89,7.96-4.13
      c1.25-0.64,3.57-2.14,4.67-2.44c1.68-0.46,3.25,0.18,3.26,0.6c0.02,0.45-2.52,2.59-4.71,3.96c-2.19,1.36-6.96,3.08-10.57,4.34
      c-3.63,1.27-7.03,3.14-10.49,4.8c-3.47,1.66-7.3,2-11.07,2.35c-13.09,1.2-39.09,0.48-39.42,0.29c-0.32-0.19,0.6-1.94,1.49-2.55
      c0.89-0.61,2.89-0.78,3.96-2.32c1.4-2.02,1.35-3.23,1.86-5.63c1.4-6.58,7-10.85,6.95-18.89c-0.04-8.04,1.94-15.26,5.4-22.3
      c2.67-5.44,0.7-10.63-0.79-16.28c1.46,2.5,2.59,4.22,4.18,6.63c1.02,1.55,3.36,5.12,5.88,5.51c3.23,0.51,4.41-7.69,4.49-10.02
      c0.19-5.19-1.83-10.49-3.17-15.42c-1.39-5.12-2.63-10.7-3.02-16.03c2.36-0.62,3.8-2.88,4.92-5.08c1.66-3.26,2.77-6.74,4.39-7.81
      c1.35-0.9,4.03-1.83,5.28-2.85c-0.74,2.02-1.11,4.27-0.77,6.34c0.44,2.71,1.68,4.41,4.49,4.02c-0.27-3.12-0.32-6.61,0.56-11.61
      c0.68,0.94,1.58,1.74,2.37,2.25c2.23,3.29,6.41,5.33,6.73,9.71C756.07,553.96,748.03,556.98,743.11,559.17z"/>
    <path fill="#B01A59" d="M735.59,525.32c0.02-0.06,0.03-0.12,0.07-0.17c0.18-0.28,0.43-0.5,0.69-0.71
      c-0.34-0.18-0.6-0.49-0.89-0.78c-0.27-0.27-0.55-0.53-0.82-0.8c-0.47,0.15-0.86,0.36-1.18,0.64
      C734.15,524.13,734.84,524.75,735.59,525.32z"/>
    <path fill="#B01A59" d="M735.83,526.8c-0.08-0.09-0.15-0.21-0.2-0.32c-0.04,0.35-0.17,0.83-0.19,1.06
      c-0.11,0.95-0.03,1.76,0.64,2.34c0.37-0.32,0.76-0.62,1.2-0.9c0.1-0.42,0.29-0.89,0.62-1.24
      C736.85,527.53,736.09,527.09,735.83,526.8z"/>
    <path fill="#B01A59" d="M718.58,549.16c0.11,0.54,0.23,1.02,0.37,1.42c0.81,2.33,2.29,8.97,2.63,10.64
      c0.27-1.97,0.46-4.48,0.79-6.63C720.58,553.33,719.4,551.32,718.58,549.16z"/>
    <path fill="#B01A59" d="M733.79,583.66c-0.05,1.35-0.47,4.69-1.44,7.15c0.48-0.45,0.91-1.01,1.27-1.7
      c3.92-7.54,0.59-15.39-2.13-22.69c-1.18-3.17-2.26-6.41-2.87-9.74c-0.16-0.86-0.53-2.18-0.09-3.02c0.19-0.36,0.68-0.6,1.01-0.78
      c0.99-0.57,1.59-1.32,2.19-2.3c2.81-4.57,4.18-10.69,9.89-12.2c0.15-0.65,0.33-1.29,0.56-1.91c-1.24,1.02-3.92,1.95-5.28,2.85
      c-1.62,1.07-2.73,4.55-4.39,7.81c-1.12,2.2-2.56,4.46-4.92,5.08c0.39,5.34,1.63,10.92,3.02,16.03
      C731.96,573.17,733.98,578.47,733.79,583.66z"/>
    <path fill="#B01A59" d="M746.72,541.08c0.28-1.48,0.95-2.7,1.74-3.9c-0.69-0.5-1.41-1.18-1.98-1.97
      c-0.8,4.54-0.83,7.84-0.63,10.74C747.26,545.3,746.49,542.33,746.72,541.08z"/>
    <path fill="#B01A59" d="M726.5,592.06c-1.34-1.27-2.45-2.95-3.07-3.9c-1.59-2.42-2.72-4.13-4.18-6.63
      c0.67,2.52,1.42,4.95,1.82,7.36C722.54,590.41,724.51,591.58,726.5,592.06z"/>
    <path fill="#B01A59" d="M743.11,559.17c2.45-1.09,5.66-2.39,8.22-4.25c-0.26,0.05-0.53,0.1-0.8,0.14
      c-3.13,0.43-6.65,0.17-9.09,2.55c-0.13,0.13-0.29,0.12-0.42,0.05c-1.16,0.69-2.44,1.21-3.63,1.61c-1.7,0.57-3.88,1.18-5.65,0.49
      c1,2.07,3.24,2.46,5.55,1.86c2.25-0.59,4.19-1.56,6.57-1.52c2.28,0.04,4.15,0.6,5.9,1.56c0.42-1.5,0.84-3,1.23-4.51
      C745.64,559.39,742.02,559.66,743.11,559.17z"/>
    <path fill="#B01A59" d="M738.73,643.7c-5.5,1.08-11.15,1.26-16.73,1.42c-2.28,0.07-4.58,0.15-6.83,0.52
      c-1.64,0.28-3.58,0.65-4.92,1.74c0.16-0.02,0.32-0.05,0.51-0.06c7.77-0.54,13.87-0.44,21.62-1.25c7.59-0.8,15.82-1.13,22.76-4.66
      c4.15-2.11,8.34-4.1,12.49-6.17c-0.54,0.14-1.08,0.26-1.63,0.37c-3.18,0.63-6.42,0.75-9.53,1.74
      C750.46,639.27,744.97,642.48,738.73,643.7z"/>
    <path fill="#B01A59" d="M762.27,640.87c-6.64,1.45-11.95,6.38-18.5,7.93c2.44-0.33,4.84-0.85,7.09-1.92
      c3.46-1.66,6.86-3.53,10.49-4.8c1.83-0.64,3.96-1.4,5.9-2.17C765.59,640.23,763.92,640.51,762.27,640.87z"/>
    <path fill="#B01A59" d="M733.03,648.85c-3.91-0.31-7.38,0.17-11.21,0.95c-0.15,0.03-0.29,0.06-0.44,0.08
      c6.3-0.03,12.83-0.2,17.75-0.61C737.09,649.25,735.05,649.01,733.03,648.85z"/>
    <path fill="#B01A59" d="M713.98,639.75c0.4-0.19,3.52-1.95,1.7-1.95c-0.84,0-1.68,0.16-2.49,0.36
      c-1.93,0.48-3.82,1.12-5.69,1.82c-0.15,0.82-0.27,1.5-0.46,2.17C709.44,641.7,711.69,640.8,713.98,639.75z"/>
    <path fill="#B01A59" d="M756.69,621.43c-0.9,0.82-8.18,6.23-5.17,7.47c1.3,0.53,3.1-0.25,4.43-0.47
      c1.8-0.29,3.6-0.55,5.39-0.89c0.82-0.15,1.63-0.32,2.44-0.5c-2.47-2.39-4.41-5.09-5.03-7.93
      C758.17,619.94,757.48,620.72,756.69,621.43z"/>
    <path fill="#B01A59" d="M716.15,622.43c0.71-3.19-0.33-5.97-0.72-9.1c-0.05-0.42-0.1-0.84-0.13-1.27
      c-0.44,2.6-0.68,5.28-0.66,8.05c0.02,3.37-0.96,6.07-2.2,8.57C714.19,626.86,715.61,624.83,716.15,622.43z"/>
    <path fill="#B01A59" d="M714.32,644.96c1.94-0.35,3.97-0.4,5.56-0.47c5.56-0.26,11.17-0.23,16.69-1.12
      c3.63-0.58,7.05-1.59,10.44-3.01c2.88-1.21,5.72-2.56,8.69-3.51c1.31-0.42,2.62-0.69,3.93-0.91c-1.91,0.2-3.81,0.41-5.65,0.76
      c-5.32,0.99-9.83,4.29-15.08,5.39C730.79,643.79,721.96,641.93,714.32,644.96z"/>
    <path fill="#FACBB0" d="M787.68,537.5c-2.41,4.34-4.81,8.6-7.28,12.84c-1.34,2.31-2.44,4.75-3.8,7.05
      c-0.76,1.29-4.25,5.98-5.79,3.57c-0.3-0.46-1.6-1.26-2.37-1.81c-1.67-1.2-13.12-3.66-15.28-3.49c1.78-1.46,3.84-4.26,4.29-6.58
      c4.56,0.64,10.51,2,13.53,3.41c-0.78,1.67-0.54,2.09-0.36,1.94c3.37-2.75,3.97-6.47,7.83-11.38c3.47-4.42,6.37-7.88,10.51-10.99
      c0.76,0.25,1.8,0.68,2.48,1.05C789.8,533.97,788.49,536.05,787.68,537.5z"/>
    <path fill="#FACBB0" d="M791.03,531.58c0.86-0.19,1.92-0.61,2.47-0.93c0.2,0.64,0.89,0.93,0.81,0.71
      c-0.54-1.69-1.15-3.81-1.53-5.79c-0.35-1.85-0.52-4.5,0.12-5.17c2.08,2.1,3.5,6.61,4.33,10.48
      C797.92,534.06,793.43,532.45,791.03,531.58z"/>
    <path fill="#FACBB0" d="M798.39,530.78c-0.38-1.46-1.71-5.61-2.18-6.84c-0.65-2.43-1.04-5.58-0.76-7.43
      c1.14,0.27,3.2,3.64,4.11,6.37c0.56,1.67,1.43,4.22,1.43,5.9C800.98,530.82,799.7,531.14,798.39,530.78z"/>
    <path fill="#FACBB0" d="M805.86,522.7c0.63,1.18,0.88,2.42,0.37,3.26c-0.44,0.73-1.53,0.98-2.49,0.24
      c-1.16-0.9-1.93-2.34-2.61-3.62c-1.12-2.08-2.74-5.43-2.38-6.7c0.7-0.28,3.16,1.57,4.44,3.19
      C804.46,520.68,805.22,521.51,805.86,522.7z"/>
    <path fill="#FACBB0" d="M809.7,519.14c-0.79,0.89-2.09,0.43-3.11,0.17c-1.27-0.33-2.83-1.2-3.41-1.74
      c-0.58-0.55-2.06-2.17-2.18-2.62c1.82-0.46,6.49,0.28,8.62,2.01C810.29,517.49,810.23,518.53,809.7,519.14z"/>
    <path fill="#CF0026" d="M750.45,536.08c-0.01,0.28,1.77,1.07,2.59,0.9c-0.73-0.48-1.52-1.24-2.27-2.12
      C750.57,535.24,750.47,535.66,750.45,536.08z"/>
    <path fill="#CF0026" d="M754.41,530.13c-0.15,0.33-0.27,0.67-0.36,1.01c-0.06,0.23,2.94,0.32,3.34-0.25
      C756.78,530.71,755.68,530.52,754.41,530.13z"/>
    <path fill="#CF0026" d="M752.71,522.42c0.33,0.36,2.99-2.15,3.05-3.76c-1.1,0.86-2.47,1.73-3.93,2.59
      C752.04,521.62,752.38,522.06,752.71,522.42z"/>
    <path fill="#CF0026" d="M744.52,541.82c-0.61,0.16-1.1,0.47-1.49,0.9c-0.17,0.19,0.64,2.67,1.86,2.67
      C744.59,544.29,744.5,543.08,744.52,541.82z"/>
    <path fill="#CF0026" d="M793.11,523.21c0.36-0.31,0.77-0.75,1.02-1.18c-0.38-0.63-0.79-1.18-1.23-1.62
      C792.47,520.85,792.88,523.41,793.11,523.21z"/>
    <path fill="#CF0026" d="M795.99,519.57c0.57-0.08,1.04-0.56,1.36-1.17c-0.69-1.03-1.39-1.77-1.9-1.89
      C795.33,517.32,795.82,519.6,795.99,519.57z"/>
    <path fill="#CF0026" d="M799.9,518.8c0.72,0.14,1.49-0.15,1.72-0.78c0.06-0.15-2.41-2.33-2.88-2.14
      C798.57,516.48,799.77,518.77,799.9,518.8z"/>
    <path fill="#CF0026" d="M803.75,517.23c0.57-0.52,0.53-1.5,0.21-2.32c-1.2-0.15-2.28-0.15-2.96,0.02
      C801.12,515.38,803.6,517.37,803.75,517.23z"/>
    <path fill="#E3AB96" d="M747.43,534.52c0.27-0.9,0.71-1.72,1.4-2.3c-0.89-1.4-1.53-2.73-1.66-3.47
      c-0.86,0.36-1.74,0.7-2.6,1.04c0.37,0.04,0.72,0.14,1.01,0.35c1.06,0.73,1.17,1.92,1.09,3.12
      C746.91,533.74,747.17,534.15,747.43,534.52z"/>
    <path fill="#E3AB96" d="M751.3,528.79c-0.56-0.34-1.11-0.73-1.62-1.19c-0.62,0.32-1.25,0.62-1.89,0.9
      c0.38,0.55,0.69,1.15,1,1.77c0.77,0.48,1.57,0.84,2.34,1.08C751.01,530.49,751.04,529.58,751.3,528.79z"/>
    <path fill="#E3AB96" d="M741.28,527.03c-0.26,0.16-0.49,0.31-0.67,0.45c-0.16,0.6-0.27,2.53,0.57,3.25
      c0.31-0.07,0.62-0.22,0.93-0.39C741.29,529.44,741.12,528.21,741.28,527.03z"/>
    <path fill="#E3AB96" d="M742.95,534.56c-3.55-0.23-7.75,3.35-9.35,6.1c-1.28,2.21-2.06,4.62-2.8,7.06
      c1.05-1.72,1.86-3.81,2.77-5.66c0.78-1.59,1.29-3.16,2.99-3.93c2.09-0.95,3.95-1.87,6.02-2.88
      C742.7,534.96,742.82,534.73,742.95,534.56z"/>
    <path fill="#E3AB96" d="M720.4,561.22c-0.71,4.18-1,8.51-0.17,12.68c0.57,2.86,1.61,5.48,3.06,7.88
      c-2.19-5.26-2.58-10.94-2.13-16.69C720.97,563.75,720.7,562.47,720.4,561.22z"/>
    <path fill="#E3AB96" d="M729.53,569.08c-0.28,4.73,0.81,9.53,1.22,14.21c0.28,3.18-0.38,6.08-1.24,8.98
      c0.01,0,0.02,0,0.03,0c1.73-0.32,2.29-4.75,2.54-6.41c0.63-4.24-0.45-8.99-1.55-13.06C730.19,571.56,729.86,570.32,729.53,569.08z"
      />
    <path fill="#E3AB96" d="M789.18,534.27c0.19,0.06,0.39,0.13,0.6,0.2c0.51-0.55,1.07-1.03,1.68-1.35
      c-0.69-0.37-1.72-0.8-2.48-1.05c-0.73,0.54-1.4,1.1-2.06,1.67C787.72,533.86,788.5,534.05,789.18,534.27z"/>
    <path fill="#E3AB96" d="M757.28,556.15c0.97-2.2,1.72-4.47,1.95-6.79c-0.6-0.1-1.19-0.19-1.76-0.27
      c-0.45,2.32-2.5,5.12-4.29,6.58C753.85,555.61,755.4,555.81,757.28,556.15z"/>
    <path fill="#E3AB96" d="M779.58,549.61c-1.64,1.96-3.2,4-4.51,6.21c-0.86,1.45-2.55,3.66-2.04,5.48
      c1.49-0.86,3.1-3.07,3.58-3.9c1.36-2.31,2.45-4.74,3.8-7.05c0.87-1.49,1.72-2.99,2.57-4.49
      C781.81,547.07,780.66,548.31,779.58,549.61z"/>
    <path fill="#E3AB96" d="M793.95,531.28c-0.17-0.13-0.36-0.34-0.44-0.63c-0.55,0.33-1.61,0.74-2.47,0.93
      c0.69,0.25,1.55,0.55,2.41,0.8C793.56,531.99,793.73,531.62,793.95,531.28z"/>
    <path fill="#E3AB96" d="M795.99,523.03c0.07,0.31,0.14,0.61,0.22,0.91c0.47,1.23,1.79,5.39,2.18,6.84
      c0.43,0.12,0.86,0.16,1.24,0.1C798.9,528.09,797.6,525.42,795.99,523.03z"/>
    <path d="M777.35,491.86c-1.77,0.42-3.29,1.05-4.66,2.28c-0.31,0.28,0.08,0.72,0.41,0.62c1.67-0.49,3.03-1.38,4.46-2.35
      C777.83,492.24,777.71,491.78,777.35,491.86z"/>
    <path d="M776.7,487.88c-0.74-0.38-4.51-1.44-4.89-1.38c-0.39,0.06-0.58,0.73-0.24,0.89c0.66,0.3,3.95,1,4.97,1.05
      C776.83,488.45,776.98,488.02,776.7,487.88z"/>
    <path d="M775.94,478.61c-0.27-0.35-0.91-0.07-0.72,0.38c0.76,1.74,1.96,3.62,2.88,4.38c0.23,0.19,0.61,0,0.51-0.27
      C778,481.4,776.45,479.27,775.94,478.61z"/>
    <path d="M783.18,477.41c-0.08-0.34-0.55-0.26-0.59,0.06c-0.2,1.59-0.11,3.07,0.25,4.45c0.06,0.23,0.37,0.24,0.42,0
      C783.52,480.42,783.56,479.02,783.18,477.41z"/>
    <path d="M787.81,479.17c-0.72,0.61-1.17,1.43-1.56,2.26c-0.12,0.26,0.22,0.39,0.42,0.22c0.52-0.46,1.23-1.31,1.54-1.98
      C788.35,479.36,788.12,478.91,787.81,479.17z"/>
    <path d="M742.81,557.68c-3.5,1.22-6.91,3.14-10.74,2.8c-0.17-0.02-0.27,0.22-0.1,0.3c3.6,1.73,8.15-0.57,11.1-2.68
      C743.32,557.92,743.06,557.59,742.81,557.68z"/>
    <path d="M725.7,474.6c-0.95,0.03-2.85,1.52-4.17,2.02c-0.84,0.32-1.81,0.31-2.39-0.31c-0.12-0.12-0.13,0.7,0.64,1.07
      c1.81,0.87,5-1.77,5.6-1.79c1.06-0.02-0.6,4.57-4.05,5.69c-0.27,0.09,0.51,0.32,1.31,0.02c1.79-0.67,3.29-1.94,3.92-4.49
      C726.94,475.23,726.35,474.58,725.7,474.6z"/>
    <path d="M718.11,475.58c-0.49,0.18-1.48,0.07-1.93-0.55c0.5-0.13,1.58-0.45,2.07-0.67c0.25-0.11-0.53-0.41-1.39-0.32
      c-1.24-1.96-2.34-4.09-3.31-6.27c-0.6-1.34-1.61-3.85-3.39-3.83c-1.59,0.01-1.96,2.67-1.95,3.76c0.03,2.87,2,6.32,0.3,8.97
      c-0.64,0.42-1.23,0.89-1.72,1.42c-0.11,0.12,0.03,0.33,0.18,0.27c0.41-0.16,0.81-0.34,1.22-0.52c-0.6,0.98-0.83,2.31-0.6,3.2
      c0.04,0.16,0.31,0.16,0.33-0.01c0.07-0.8,0.33-1.52,0.63-2.21c0.35-0.77,0.97-1.69,2.06-2.1c0.36,1.4,0.23,2.55-0.34,3.6
      c-0.06,0.11,0.4,0.04,0.65-0.32c0.51-0.73,1.11-2.18,0.06-3.44c0.54-0.24,1.08-0.46,1.65-0.65c-0.09,0.3-0.06,0.67,0,0.97
      c0.12,0.67,0.49,1.22,1.07,1.56c0.13,0.08,0.28-0.08,0.18-0.2c-0.35-0.42-0.69-1.54-0.48-2.56c0.42-0.11,0.74-0.22,1.16-0.31
      c-0.06,0.29,0.1,1.37,1.3,1.89c0.13,0.06,0.29-0.09,0.18-0.2c-0.4-0.41-0.81-0.99-0.95-1.76c0.07-0.02,0.39-0.08,0.46-0.09
      c0.39,0.93,1.9,1.28,2.62,0.61C718.22,475.74,718.21,475.54,718.11,475.58z"/>
    <path fill="#FFFFFF" d="M709.63,475.99c0.39-0.99,0.32-2.15,0.2-3.23c-0.1-0.8-1.79-8.81,0.75-7.54
      c1.39,0.7,2.34,2.74,3.07,4.52c-1.01-0.1-2.1,0.7-2.59,1.55c-0.68,1.19-0.87,2.87-0.16,4.08
      C710.47,475.56,710.04,475.76,709.63,475.99z"/>
    <path fill="#9ED6ED" d="M715.04,472.37c-1.25-1.25-2.66-0.26-3,0.56c-0.22,0.53-0.3,1.46-0.01,1.98
      c-0.3,0.12-0.61,0.25-0.91,0.37c-0.4-1.05-0.36-2.21,0.04-3.26c0.24-0.62,0.71-1.17,1.28-1.5c0.49-0.28,0.96-0.37,1.45-0.3
      C714.1,470.71,714.84,471.88,715.04,472.37z"/>
    <path d="M727.57,472.49c-0.22-0.08-0.95-0.06-1.18-0.1c-0.79-1.53-1.51-2.93-2.18-4.58c-0.46-1.15-0.69-2.39-1.12-3.55
      c-0.51-1.39-1.78-3.28-3.42-2.2c-1.45,0.95-1.22,3.53-1.08,4.97c0.22,2.27,1.5,5.33,1.25,7.21c-0.06,0.47,0.37,0,0.48-0.28
      c0.36,0,0.93-0.01,1.29-0.02c0,0.06,0,0.12,0.03,0.17c0.2,0.42-0.07,0.85-0.46,1.04c-0.1,0.05-0.05,0.21,0.06,0.17
      c0.3-0.1,0.57-0.18,0.79-0.41c0.26-0.26,0.27-0.57,0.28-0.92c0-0.03-0.02-0.05-0.02-0.09c0.3-0.02,0.6-0.04,0.91-0.06
      c0.12,0.22,0.24,0.43,0,0.77c-0.07,0.09,0.04,0.19,0.13,0.14c0.29-0.16,0.58-0.41,0.57-0.78c0-0.06-0.01-0.12-0.02-0.18
      c0.65-0.06,1.26-0.08,1.86-0.17c0.2,0.21,0.47,0.36,0.74,0.48c0.38,0.18,0.86,0.28,1.23,0.04c0.08-0.05,0.11-0.18,0.01-0.24
      c-0.33-0.2-0.72-0.27-1.06-0.47c0.32-0.09,0.64-0.2,0.96-0.37C727.9,472.93,727.81,472.58,727.57,472.49z"/>
    <path fill="#FFFFFF" d="M719.38,463.64c0.53-2.03,2.33-0.77,2.74,0.2c0.42,1,0.8,2.47,1.27,3.89
      c-0.71,0.01-1.71,0.65-2.05,1.24c-0.71,1.22-0.87,3.08-0.3,4.37c-0.15,0.05-0.56,0.06-0.71,0.1
      C720.57,470.2,718.52,466.97,719.38,463.64z"/>
    <path fill="#9ED6ED" d="M722.32,473.07c-0.36,0.11-0.67,0.18-1.09,0.21c-0.33-1.04-0.2-2.27,0.13-3.29
      c0.35-1.07,1.09-1.76,2.28-1.62c0.2,0.54,0.61,1.45,0.9,1.99C722.57,469.84,721.84,471.72,722.32,473.07z"/>
    <path fill="#79C6E6" d="M714.47,471.96c0.19,0.09,0.38,0.22,0.57,0.41c-0.21-0.49-0.95-1.66-1.14-2.15
      c-0.12-0.02-0.24-0.02-0.36-0.01C713.84,470.79,714.15,471.38,714.47,471.96z"/>
    <path fill="#79C6E6" d="M712.02,473.01c-0.34-0.17-0.67-0.34-1.01-0.5c-0.25,0.92-0.23,1.88,0.11,2.77
      c0.3-0.13,0.61-0.26,0.91-0.37C711.75,474.4,711.82,473.55,712.02,473.01z"/>
    <path fill="#79C6E6" d="M722.23,471.71c-0.37-0.24-0.74-0.49-1.1-0.75c-0.13,0.77-0.13,1.59,0.1,2.31
      c0.42-0.03,0.73-0.1,1.09-0.21C722.17,472.64,722.14,472.16,722.23,471.71z"/>
    <path d="M724.67,463.49c-0.87-2.17-2.48-4.23-4.55-5.32c-2.35-1.23-3.62,0.67-3.41,2.89c0.02,0.22,0.34,0.25,0.41,0.05
      c0.65-1.69,1.46-2.13,2.96-1c1.45,1.09,2.75,2.43,4.02,3.71C724.34,464.04,724.8,463.81,724.67,463.49z"/>
    <path d="M753.1,462.11c0.06-0.19-0.21-0.33-0.28-0.13c-1.24,3.19-3.21,5.99-4.43,9.2c-1.48,3.89,0.62,7.87-0.51,11.85
      c-0.84,2.96-4.06,4.29-6.2,6.11c-2.62,2.22-2.09,9.08-7.37,7.97c-0.45-0.09-0.61,0.61-0.16,0.69c2.25,0.42,3.67-0.47,4.93-2.33
      c0.85-1.26,1.3-3.02,1.94-4.37c0.99-2.07,3.31-3.24,5-4.69c1.99-1.7,2.95-4.32,2.71-6.92c-0.31-3.32-0.66-6.12,0.6-9.34
      C750.4,467.39,752.11,464.94,753.1,462.11z"/>
    <path d="M748.44,467.74c-1.86,2.76-2.64,5.4-2.75,8.72c-0.1,3.06,0.55,7.18-2.31,9.26c-0.08,0.06,0,0.19,0.08,0.13
      c1.99-1.31,2.6-3.66,2.83-5.93c0.42-4.17,0.13-8.34,2.27-12.1C748.61,467.75,748.49,467.67,748.44,467.74z"/>
    <path d="M755.51,639.87c-6.22,2.24-12.47,3.77-19.06,4.38c-0.11,0.01-0.09,0.17,0.02,0.17c6.38,0.06,13.66-0.77,19.16-4.26
      C755.76,640.06,755.68,639.81,755.51,639.87z"/>
    <path d="M760.59,635.34c-4.36,2.07-8.46,4.36-13.15,5.65c-0.09,0.03-0.06,0.18,0.03,0.16c4.72-0.74,9.41-2.74,13.26-5.59
      C760.85,635.46,760.72,635.28,760.59,635.34z"/>
    <path d="M702.51,474.58c0.04-0.09-0.09-0.15-0.13-0.07c-2.71,4.96,0.53,9.25,2.8,13.66c0.06,0.11,0.23,0.05,0.18-0.07
      C703.73,483.59,700.5,479.55,702.51,474.58z"/>
    <path d="M679.44,486.17c-0.38-2.14-1.83-3.11-3.44-4.4c-0.08-0.06-0.19,0.04-0.13,0.12c1.52,1.96,2.76,3.7,2.93,6.33
      c0.09,1.48,0.29,2.51,1.08,3.76c0.07,0.11,0.23,0,0.19-0.11C679.36,490.01,679.78,488.07,679.44,486.17z"/>
    <path d="M734.85,457.27c-3.42,2.39-8.4,2.48-11.82,0.14c-0.19-0.13-0.41,0.06-0.3,0.27c2.24,3.95,9.67,2.5,12.22-0.29
      C735.02,457.32,734.92,457.22,734.85,457.27z"/>
    <path fill="#FFFFFF" d="M724.36,470.78c-0.34,0.01-0.6,0.3-0.59,0.64c0.01,0.34,0.3,0.61,0.64,0.6
      c0.34-0.01,0.61-0.3,0.59-0.64C724.98,471.03,724.7,470.77,724.36,470.78z"/>
    <path d="M792.41,487.43l-7.99,0.52c-0.66,0.04-1.12,0.63-1.03,1.32l1.07,8.6c0.09,0.69,0.7,1.21,1.35,1.17l8-0.93
      c0.66-0.04,1.12-0.63,1.03-1.32l-1.08-8.19C793.67,487.91,793.07,487.39,792.41,487.43z"/>
    <path fill="#FFFFFF" d="M792.18,487.82l-7.44,0.48c-0.61,0.04-1.04,0.59-0.96,1.23l0.99,8.03c0.08,0.64,0.65,1.13,1.26,1.09
      l7.45-0.89c0.61-0.04,1.04-0.59,0.96-1.23l-1-7.62C793.36,488.27,792.79,487.78,792.18,487.82z"/>
    <g>
      <path d="M786.5,488.88c-1.09,0.04-1.94,0.96-1.91,2.06c0.04,1.1,0.95,1.95,2.05,1.91c1.09-0.04,1.95-0.96,1.91-2.06
        C788.51,489.69,787.59,488.84,786.5,488.88z"/>
      <path fill="#FFFFFF" d="M786.51,489.12c-0.96,0.03-1.71,0.84-1.67,1.81c0.03,0.96,0.84,1.72,1.8,1.68
        c0.96-0.04,1.71-0.84,1.67-1.81C788.28,489.84,787.47,489.08,786.51,489.12z"/>
      <path d="M786.53,489.55c-0.72,0.03-1.28,0.63-1.26,1.36c0.03,0.72,0.63,1.29,1.35,1.26c0.72-0.03,1.28-0.63,1.26-1.36
        C787.85,490.09,787.25,489.52,786.53,489.55z"/>
      <path fill="#FFFFFF" d="M787.19,489.96c-0.23-0.15-0.49-0.15-0.59,0c-0.1,0.15,0,0.39,0.23,0.54
        c0.23,0.15,0.49,0.15,0.59,0C787.52,490.35,787.41,490.11,787.19,489.96z"/>
      <path fill="#FFFFFF" d="M785.93,491.35c-0.11-0.07-0.24-0.07-0.29,0c-0.05,0.08,0,0.2,0.11,0.27
        c0.11,0.08,0.24,0.07,0.29,0C786.09,491.54,786.04,491.42,785.93,491.35z"/>
    </g>
    <g>
      <path d="M791.1,488.49c-1.09,0.04-1.94,0.96-1.9,2.06c0.04,1.1,0.96,1.95,2.05,1.91c1.09-0.04,1.94-0.96,1.91-2.06
        C793.11,489.3,792.19,488.45,791.1,488.49z"/>
      <path fill="#FFFFFF" d="M791.11,488.73c-0.96,0.03-1.71,0.84-1.67,1.81c0.03,0.96,0.84,1.71,1.8,1.68
        c0.96-0.03,1.71-0.84,1.67-1.8C792.87,489.44,792.07,488.69,791.11,488.73z"/>
      <path d="M791.13,489.16c-0.72,0.03-1.28,0.63-1.26,1.36c0.03,0.72,0.63,1.29,1.35,1.26c0.72-0.03,1.28-0.63,1.26-1.36
        C792.45,489.7,791.85,489.13,791.13,489.16z"/>
      <path fill="#FFFFFF" d="M791.79,489.56c-0.22-0.15-0.49-0.15-0.59,0c-0.1,0.15,0,0.4,0.23,0.54c0.23,0.15,0.49,0.15,0.59,0
        C792.11,489.96,792.01,489.71,791.79,489.56z"/>
      <path fill="#FFFFFF" d="M790.53,490.96c-0.11-0.07-0.24-0.08-0.29,0c-0.05,0.07,0,0.2,0.11,0.27
        c0.11,0.08,0.24,0.07,0.29,0C790.69,491.15,790.64,491.03,790.53,490.96z"/>
    </g>
    <g>
      <path d="M787.18,493.63c-1.09,0.06-1.92,1.01-1.86,2.1c0.07,1.1,1,1.93,2.09,1.87c1.09-0.07,1.92-1.01,1.86-2.1
        C789.2,494.4,788.27,493.56,787.18,493.63z"/>
      <path fill="#FFFFFF" d="M787.19,493.87c-0.96,0.06-1.69,0.88-1.63,1.84c0.06,0.96,0.88,1.7,1.84,1.64
        c0.96-0.06,1.69-0.89,1.63-1.85C788.97,494.54,788.15,493.81,787.19,493.87z"/>
      <path d="M787.22,494.3c-0.72,0.04-1.27,0.66-1.22,1.39c0.04,0.72,0.66,1.27,1.38,1.23c0.72-0.04,1.27-0.67,1.22-1.39
        C788.55,494.81,787.94,494.26,787.22,494.3z"/>
      <path fill="#FFFFFF" d="M787.89,494.69c-0.23-0.14-0.49-0.14-0.59,0.02c-0.1,0.15,0.01,0.39,0.24,0.54
        c0.23,0.14,0.49,0.14,0.59-0.02C788.22,495.07,788.12,494.83,787.89,494.69z"/>
      <path fill="#FFFFFF" d="M786.66,496.11c-0.11-0.07-0.24-0.07-0.29,0.01c-0.05,0.08,0.01,0.2,0.12,0.27
        c0.11,0.07,0.24,0.07,0.29-0.01C786.83,496.31,786.77,496.19,786.66,496.11z"/>
    </g>
    <g>
      <path d="M791.55,493.22c-1.09,0.07-1.92,1.01-1.86,2.1c0.07,1.09,1,1.93,2.09,1.86c1.09-0.07,1.92-1.01,1.86-2.1
        C793.58,493.99,792.64,493.15,791.55,493.22z"/>
      <path fill="#FFFFFF" d="M791.57,493.46c-0.96,0.06-1.69,0.88-1.63,1.84c0.06,0.96,0.88,1.69,1.84,1.64
        c0.96-0.06,1.69-0.88,1.63-1.84C793.35,494.14,792.52,493.41,791.57,493.46z"/>
      <path d="M791.59,493.9c-0.72,0.04-1.27,0.66-1.22,1.39c0.04,0.72,0.66,1.27,1.38,1.23c0.72-0.04,1.27-0.66,1.22-1.39
        C792.93,494.4,792.31,493.85,791.59,493.9z"/>
      <path fill="#FFFFFF" d="M792.26,494.28c-0.23-0.14-0.49-0.14-0.59,0.02c-0.1,0.15,0.01,0.39,0.24,0.54
        c0.23,0.14,0.49,0.14,0.59-0.02C792.6,494.67,792.49,494.43,792.26,494.28z"/>
      <path fill="#FFFFFF" d="M791.03,495.71c-0.11-0.07-0.24-0.07-0.29,0.01c-0.05,0.08,0.01,0.2,0.12,0.27
        c0.11,0.07,0.24,0.07,0.29-0.01C791.2,495.9,791.15,495.78,791.03,495.71z"/>
    </g>
    <path fill="#FFFFFF" d="M714.35,472.41c-0.34,0.01-0.6,0.3-0.59,0.64c0.01,0.34,0.3,0.61,0.64,0.6
      c0.34-0.01,0.61-0.3,0.59-0.64C714.98,472.67,714.69,472.4,714.35,472.41z"/>
    <path fill="#FFFFFF" d="M713.6,472.15c-0.12,0-0.22,0.11-0.21,0.23c0.01,0.12,0.11,0.22,0.23,0.21
      c0.12-0.01,0.22-0.11,0.21-0.23C713.83,472.24,713.73,472.15,713.6,472.15z"/>
    <path fill="#FFFFFF" d="M723.52,470.62c-0.12,0-0.22,0.11-0.21,0.23c0,0.12,0.11,0.22,0.23,0.22c0.12,0,0.22-0.11,0.21-0.23
      C723.75,470.71,723.65,470.61,723.52,470.62z"/>
    <path fill="#FAE1CF" d="M721.21,477.87c0.91-0.1,2.55-0.93,3.29-1.41c0.74-0.48-0.33,1.46-1.44,2.11
      C721.94,479.22,720.15,477.99,721.21,477.87z"/>
    <path fill="#FAE1CF" d="M728.3,474.65c1.09-0.88,2.83-1.19,3.64-0.05C732.58,475.48,727.12,475.6,728.3,474.65z"/>
    <path fill="#FAE1CF" d="M744.02,525.98c1-0.51,2.83-1.55,3.65-1.81c0.83-0.26-0.55,1.47-1.3,1.88
      C745.63,526.45,742.9,526.55,744.02,525.98z"/>
    <path fill="#FAE1CF" d="M725.48,544.4c0.04-1.78,2.23-5.64,3.93-6.92C731.11,536.2,725.4,548.37,725.48,544.4z"/>
    <path fill="#FAE1CF" d="M709.39,529.36c1.5,0.38,3.88,4.36,3.9,7.25C713.32,541.79,706.25,528.56,709.39,529.36z"/>
    <path fill="#FAE1CF" d="M716.92,553.17c0.67,2.06,1.65,5.96,1.86,7.55c0.21,1.59-2.22-0.68-2.59-2.57
      C715.81,556.26,715.64,549.25,716.92,553.17z"/>
    <path fill="#FAE1CF" d="M725.06,570.78c-0.24-3.49-1.1-6.8-1.49-5.59c-0.92,2.85-0.61,7.89,0.38,10.65
      C724.92,578.56,725.32,574.59,725.06,570.78z"/>
    <path fill="#FAE1CF" d="M779.62,545.58c-2.2,3.19-5.2,6.29-4.2,3.58c0.75-2.02,4.3-7.51,5.99-8.6
      C782.99,539.54,781.69,542.59,779.62,545.58z"/>
    <path fill="#FAE1CF" d="M763.04,550.82c1.67,0.02,4.74,0.9,5.75,1.55c1.01,0.65-1.8,0.52-3.18,0.19
      C764.23,552.23,761.44,550.8,763.04,550.82z"/>
    <path fill="#FAE1CF" d="M803.69,522.53c-0.85-1.78-1.41-3.7-0.25-2.43c0.94,1.03,2.37,3.34,2.21,4.42
      C805.48,525.65,804.46,524.15,803.69,522.53z"/>
    <path fill="#FAE1CF" d="M806.02,515.67c0.94,0.2,3.05,0.89,3.33,2.01C809.79,519.42,804.49,515.34,806.02,515.67z"/>
    <path fill="#E76E8C" d="M715.01,527.7c1.94-0.48,7.22-1.5,8.59-1.55c1.37-0.05-1.55,2.81-2.77,3.13
      C719.61,529.6,712.43,528.34,715.01,527.7z"/>
    <path fill="#E76E8C" d="M750.91,541.16c1.21,1.13,3.57,3.87,3.6,4.7c0.03,0.82-3.31-0.43-4.02-1.24
      C749.77,543.82,748.49,538.91,750.91,541.16z"/>
    <path fill="#E76E8C" d="M746.69,592.33c2.17,1.86,4.45,5.36,5.09,8.09c0.65,2.73-9.9,3.53-11.2,1.5
      C739.27,599.9,743.29,589.42,746.69,592.33z"/>
    <path fill="#E76E8C" d="M729.92,641.74c3.57-0.13,10.58-0.71,14.19-2.44c4.05-1.94,7.56-3.44,9.62-3.52
      c2.06-0.07-3.08-1.68-7.55,0c-4.47,1.68-9.21,3.36-11.12,3.71C733.15,639.83,726.36,641.87,729.92,641.74z"/>
    <path fill="#E76E8C" d="M740.73,636.8c2.57-1.18,5.96-2.28,8.83-2.52c2.87-0.24,4.53,0.11,5.64,0.35
      c1.11,0.23-1.63-3.39-7.21-2.09C742.41,633.85,737.49,638.3,740.73,636.8z"/>
    <path fill="#E76E8C" d="M721.02,600.01c-1.56,2.11-3.59,7.02-3.77,9.79c-0.18,2.76,4.71-2.65,5.09-3.63
      C722.72,605.19,723.12,597.18,721.02,600.01z"/>
    <path fill="#E76E8C" d="M771.55,635.14c-2.78,1.47-5.79,3.8-3.99,3.59c2.05-0.23,6.75-2.99,7.81-4.14
      C776.75,633.11,774.27,633.71,771.55,635.14z"/>
    <path d="M730.12,491.16c-0.48-0.83-0.68-1.94-1-2.97c-0.74-2.31-3.45-0.37-4.59-0.2c-1.74,0.26-3.28-0.06-4.27,1.47
      c-1,1.54-2.12,4.94-2.93,5.89c0.97,1.13,0.97,2.77,1.18,3.42c0.26,0.78,0.68,1.57,1.25,2.17c1.52,1.6,3.6,2.48,5.74,2.1
      c1.75-0.31,3.58-1.56,4.51-3.02c0.6-0.94,0.71-2.16,0.65-3.49c-0.05-1.14,0.76-2.22,0.71-3.38
      C731.33,492.49,730.44,491.71,730.12,491.16z"/>
    <path fill="#CF0026" d="M729.63,495.47c-0.22,0.78-0.36,1.57-0.56,2.35c-0.51,1.97-2.33,3.34-4.28,3.59
      c-1.78,0.23-3.47-0.72-4.43-2.18c-0.76-1.16-1.14-3.36-1.92-4.03c0.78-0.94,1.59-2.22,2-3.95c0.41-1.73,2.27-2.38,4.15-2.54
      c1.53-0.13,2.9-1.74,3.86-0.17c0.35,0.58,0.25,2.21,0.56,2.82c0.33,0.63,0.9,1.25,1.14,1.91
      C730.33,493.79,729.77,494.93,729.63,495.47z"/>
    <path d="M728.58,493.81c-0.49-0.96-2.52-0.93-3.41-0.79c-0.95,0.15-1.75,0.52-2.55,1.04c-0.72,0.46-1.69,0.64-1.59,0.71
      c0.59,0.4,1.93,0.67,2.54,0.14c1.83-1.57,3.99-0.35,4.79-0.64C728.54,494.21,728.67,493.98,728.58,493.81z"/>
    <path d="M724.51,493.52c-0.03,0-0.06-0.01-0.09-0.01c-0.09-0.34-0.23-0.66-0.29-1.01c-0.08-0.42-0.14-0.85-0.27-1.26
      c-0.03-0.09-0.16-0.05-0.14,0.04c0.12,0.46,0.15,0.93,0.21,1.4c0.04,0.25,0.08,0.57,0.2,0.81c-0.1,0-0.19-0.01-0.29-0.03
      c-0.29-0.06-0.52-0.24-0.7-0.47c-0.27-0.32-0.39-0.8-0.53-1.19c-0.03-0.09-0.17-0.05-0.14,0.04c0.28,0.84,0.78,2.11,1.85,1.89
      c0.04,0.03,0.11,0.01,0.13-0.04c0.02-0.01,0.05-0.01,0.08-0.02C724.61,493.66,724.6,493.53,724.51,493.52z"/>
    <path d="M727.85,491.71c-0.01-0.07-0.11-0.05-0.11,0.02c0.02,0.36-0.89,1.63-1.14,1.09c0,0,0,0-0.01-0.01
      c0.04-0.06,0.08-0.12,0.1-0.18c0.23-0.52,0.18-1.15,0.13-1.7c-0.01-0.1-0.16-0.1-0.16,0.01c0.03,0.48,0,0.97-0.17,1.43
      c-0.07,0.19-0.17,0.35-0.34,0.47c-0.11,0.08-0.23,0.08-0.36,0.09c0.14-0.83-0.04-1.68-0.1-2.51c-0.01-0.11-0.17-0.1-0.17,0.01
      c0.01,0.23,0.17,1.76,0,2.55c-0.06,0.04-0.06,0.1-0.04,0.16c-0.07,0.23-0.19,0.35-0.36,0.29c-0.1-0.04-0.23,0.03-0.18,0.15
      c0.16,0.44,0.59,0.19,0.72-0.15c0.02-0.04,0.03-0.09,0.04-0.14c0.22,0.06,0.44-0.02,0.62-0.17c0.03,0.04,0.06,0.08,0.1,0.12
      c0.06,0.06,0.16,0.1,0.24,0.11c0.23,0.03,0.44-0.11,0.59-0.27C727.58,492.74,727.94,492.17,727.85,491.71z"/>
    <path d="M724.64,497.84c-0.21-0.72-0.78-1.49-1.55-1.63c-0.05-0.01-0.07,0.07-0.02,0.08c0.63,0.2,1.04,0.74,1.3,1.32
      c0.33,0.75,0.17,1.34-0.31,1.96c-0.06,0.07,0.02,0.18,0.1,0.15C724.9,499.44,724.82,498.47,724.64,497.84z"/>
    <path d="M725.67,496.27c-0.07-0.05-0.14,0.06-0.07,0.12c1.11,0.8,1.17,2.01,0.69,3.2c-0.04,0.1,0.1,0.18,0.16,0.1
      C727.41,498.55,726.81,497,725.67,496.27z"/>
    <path d="M726.13,496.01c-0.1-0.04-0.17,0.1-0.08,0.14c0.42,0.19,0.75,0.48,0.99,0.88c0.25,0.41,0.27,0.88,0.43,1.33
      c0.03,0.08,0.15,0.08,0.16-0.01C727.78,497.36,727,496.36,726.13,496.01z"/>
    <path fill="#FFB2AB" d="M721.33,496.65c1.08-0.31,2.2,0.31,2.07,1.04C723.26,498.42,719.78,497.1,721.33,496.65z"/>
    <path fill="#FFB2AB" d="M726.23,488.98c0.39-0.4,1.77-0.85,1.5-0.12C727.46,489.59,725.44,489.79,726.23,488.98z"/>
  </g>
  <g id="item_3_">
    <path fill="#F7D9C3" d="M229.43,664c1.86-1.24,4.32-2.89,3.35-3.02c-1.24-0.15-6.15-3.44-9.18-4.85
      c-3.03-1.41-14.63-3.28-17.82-3.05c-3.19,0.23-21.87,4.69-21.87,4.69s-2.57,2.5-4.28,6.22H229.43z"/>
    <path fill="#E6BDA3" d="M191.63,656.56c2.34,0.2,5.96,2.03,6.24,4.67c0.73-1.03,1.34-2.17,2.05-3.18
      c0.6-0.86,1.33-2.1,2.43-2.38c0.4-0.1,0.66,0.22,0.68,0.53c0.98-0.48,1.91-1.08,3.06-1.25c1.51-0.22,3.31,0.22,4.81,0.42
      c2.93,0.39,6.18,0.84,8.76,2.39c2.42,1.45,4.31,3.67,5.47,6.24h4.31c1.86-1.24,4.32-2.89,3.35-3.02c-1.24-0.15-6.15-3.44-9.18-4.85
      c-3.03-1.41-14.63-3.28-17.82-3.05c-2.09,0.15-10.8,2.11-16.57,3.45C190.01,656.49,190.82,656.49,191.63,656.56z"/>
    <path fill="#2BBFE5" d="M182.66,658.01c0.54-0.86,4.36-4.93,5.6-6.96c1.25-2.03,7.55-12.19,9.26-14.85
      c1.71-2.66,6.22-7.9,8.01-9.22c1.79-1.33,3.19-1.56,4.98-1.41c1.79,0.15,12.92,2.34,15.72,3.13c2.8,0.78,10.51,1.09,12.61,0.78
      c2.1-0.31,4.36-0.47,5.06-0.16c0.7,0.31,4.59,4.5,5.06,11.02c0.47,6.52-2.49,13.29-5.99,16.18c-3.5,2.89-8.01,5.47-9.65,5.47
      c-0.86,0-3.03-1.25-4.98-3.13c-1.95-1.88-10.5-4.77-15.57-5.71c-7.57-1.41-14.66,1.41-17.43,2.11
      C189.82,656.68,182.66,658.01,182.66,658.01z"/>
    <path fill="#7ADBF5" d="M191,651.03c2.61-3.65,4.9-7.29,7.46-11.5c2.2-3.63,4.5-6.97,7.68-9.82
      c1.32-1.18,2.73-2.37,4.45-2.94c1.15-0.38,2.32-0.55,3.5-0.61c-1.69-0.31-3.03-0.53-3.56-0.58c-1.79-0.16-3.19,0.08-4.98,1.41
      c-1.79,1.33-6.3,6.57-8.01,9.22c-1.71,2.66-8.01,12.82-9.26,14.85c-1.24,2.03-5.06,6.1-5.6,6.96c0,0,0.96-0.18,2.45-0.47
      C187.14,655.56,189.36,653.32,191,651.03z"/>
    <path fill="#1C9BD4" d="M248.97,640.35c-0.1-1.4-0.36-2.69-0.71-3.86c0.27,5.84-3.16,11.76-7.76,15.21
      c-3.9,2.93-8.39,4.83-13.31,4.76c-1.48-0.02-2.93-0.17-4.38-0.4c2.55,0.97,4.68,1.98,5.54,2.81c1.95,1.88,4.12,3.13,4.98,3.13
      c1.63,0,6.15-2.58,9.65-5.47C246.48,653.63,249.43,646.87,248.97,640.35z"/>
    <path d="M165.07,511.92c-1.43,6.31,0.53,13.35,4.02,19.29c3.07-1.42,6.44-2.8,9.95-3.69c1.25,3.02,3.7,6.66,5.05,8.39
      c7.46,9.58,19.86,13.13,31.49,11.48c0.22,0.71,0.91,2.52,0.22,5.17c-0.69,2.66-3.97,5.07-5.85,7.28c-1.55,1.8-4.2,4.75-4.62,5.52
      c-0.11-0.65-1.63-12.48-1.8-13.54c-0.21-1.33-1.38-2.34-2.91-2.44c-1.36-0.1-27.41,2.72-29.71,3.19c-1.85,0.37-2.79,2.07-2.54,3.66
      c0.25,1.59,1.11,5.95,1.53,8.07c-0.95-0.85-4.38-1.63-3.65,0.64c0.36,1.13,1.69,7.12,1.96,8.34c0.26,1.22,1.48,10.94,1.37,12.96
      c-0.11,2.02-0.42,13.06,0.68,17.31c1.11,4.25,2.54,6.64,4.55,7.17c2.01,0.53,4.76,0.69,6.72,0.74c2.72,3.83,8.33,6.7,12.62,7.43
      c5.97,1.01,10.57-4.77,12.38-10.15c0.77-0.17,1.51-0.38,2.35-0.63c-0.39,1.91-0.83,5.13-0.58,5.14c0.47,0.02,0.82,0.02,1.28-0.09
      c-0.38,4.06-0.32,8.03-1.56,11.89c-5.04,1.58-8.98,8.15-11.68,12.01c-3.42,4.88-6.42,10.12-9.86,15c-0.92,1.3-3.69,4.64-4,5.04
      c-1.42,0.48-1.48,2.01-0.05,1.78c-1.07,1.37-2.25,3.16-3.31,5.13h1.57c1.02-1.88,2.12-3.69,3.1-5.32
      c6.07-1.16,12.8-3.28,18.87-4.19c-0.98,1.07-1.86,2.27-2.65,3.35c-1.45,1.97-2.86,4.05-4.21,6.16h1.56
      c0.44-0.62,0.86-1.22,1.24-1.78c2.17-3.17,5.14-7.49,8.53-8.18c8.03-0.37,15.31,1.67,22.31,6.16c0.43,0.28,1.08-0.26,0.68-0.68
      c-5.76-6.06-14.1-7.99-22.23-7.51c-7.72,0.45-15.93,2.17-23.61,4.61c0.75-0.97,3.99-4.76,5.47-6.79
      c6.42-8.79,11.62-20.29,19.13-23.73c3.96-0.31,8.93,1.5,15.64,2.83c4.23,0.84,13.66,2.52,18.86,0.46
      c4.38,5.41,6.32,10.6,3.86,17.73c-2.13,6.18-7.39,11.54-13.09,14.49c-0.35,0.18-0.12,0.79,0.26,0.65
      c7.22-2.56,12.56-8.1,15.02-15.39c2.17-6.43,0.18-14.67-5.62-18.51c-0.57-2.41-2.75-6.83-3.03-7.31c1.48,0.75,2.72,0.7,3.87-0.18
      c1.77-1.36,1.88-7.07,1.77-11.78c-0.08-3.68-1.53-10.82-2.14-14.45c-0.62-3.63-1.04-9.83-1.93-15.14
      c-0.49-2.9-2.55-10.56-4.17-13.65c0.22-0.22,0.7-0.51,0.7-0.85c-1.94-3.89-7.23-7.44-9.44-7.77c-1.81-3.17-3.4-8.91-3.68-12.87
      c9.25-4.38,15.85-12.54,18.8-21.89c-0.04,11.48-6.32,25.39-14.08,32.54c-0.49,0.45,1.22,0.46,2.45,0.29
      c3.14-0.45,4.65-0.86,7.17-1.88c-0.09,2.63-1.4,5.37-2.54,7.74c4.85-0.4,10.08-3.04,14.15-6.41c0.49,0.94,0.89,2.92,0.69,4.29
      c-0.11,0.77,3.46-1.8,7.26-6.42c2.5-3.05,4.21-6.67,5.73-10.44c2.67-6.66,4.01-12.48,3.39-19.48c0.52-5.4,0.07-11.03-1.42-14.55
      c0.17-9.12-1.89-18.24-5.88-26.76c-6.76-14.44-19.57-26.12-35.21-29.71c-8.05-1.85-16.55-1.15-24.33,1.5
      c-7.76,2.64-13.62,8-18.59,14.34c-9.53,1.89-15.16,14.95-17.41,23.27C165.34,493.08,159.94,512.05,165.07,511.92z M195.41,612.7
      c-2.08,0.42-3.41-0.28-4.31-1.4c2.15-0.22,6.29-0.73,8.41-1.18C198.74,611.23,197.49,612.28,195.41,612.7z"/>
    <path fill="#F7D9C3" d="M174.13,608.32c-1.27-0.96-2.06-3.61-2.6-5.94c-1.26-5.47-1.15-14.03-0.94-15.84
      c0.21-1.8-1-11.59-1.24-13.39c-0.24-1.8-2.07-7.09-1.93-8.01c0.21-1.43,2.4,0.22,2.59,0.48c1.11,5.2,3.62,17.76,4.1,20.44
      c-0.5,1.01-0.39,1.67-0.15,1.61c3.99-5.22,7.74-7.89,8.9-8.04c1.14-0.14,1.31,1.41,1.26,2.31c-2.73,0.61-7.34,7.25-8.45,8.84
      c-1.11,1.59-1.33,3.12-0.83,2.44c0.51-0.69,8.31-12.65,11.29-9.77c2.7,2.6,3.67,9.91,2.94,10.4c-0.85-2.81-2.46-5.73-4.62-6.57
      c-1.86-0.72-6.93,6.2-7.78,7.53c-0.84,1.33-0.74,2.85-0.29,2.14c0.45-0.71,6.53-9.68,8.23-8.3c1.93,1.57,3.63,5.09,3.95,7.22
      c0.32,2.12,0.96,3.67-0.21,3.4c-1.17-0.26-2.75-2.99-3.96-6.18c-0.18-0.46-2.18,1.93-2.5,2.41c-2.74,4.14-4.9,7.85-4.9,9.98
      c0,1.33,1.15,3.18,1.85,4.2C178.61,609.75,175.4,609.28,174.13,608.32z"/>
    <path fill="#F294B8" d="M169.34,570.97c0.53-1.42-0.76-4.35-1.21-5.63c-0.08-0.14-0.15-0.29-0.21-0.44
      c-0.03-0.07-0.06-0.15-0.09-0.22c-0.01-0.02-0.02-0.05-0.03-0.07c-0.19,0.07-0.34,0.23-0.38,0.54c-0.1,0.69,0.89,3.81,1.5,6.1
      C169.06,571.15,169.2,571.06,169.34,570.97z"/>
    <path fill="#F294B8" d="M188.44,595.28C188.44,595.28,188.44,595.28,188.44,595.28c-1.04-0.97-1.12,1.44-1.11,1.63
      c0.03,0.46,0.05,0.92,0.2,1.36c0.08,0.25,0.18,0.5,0.27,0.75c0.19,0.14,0.37,0.24,0.55,0.28c1.17,0.27,0.53-1.28,0.21-3.4
      C188.54,595.7,188.5,595.49,188.44,595.28z"/>
    <path fill="#E6BDA3" d="M173.52,605.01c-0.59-0.38-1.14-0.81-1.65-1.3c0.52,1.93,1.24,3.83,2.27,4.61
      c1.27,0.95,4.48,1.42,4.71,1.38c-0.58-0.85-1.47-2.26-1.76-3.48C175.82,606.04,174.6,605.7,173.52,605.01z"/>
    <path fill="#F7D9C3" d="M192.56,616.96c-3.55-1.09-7.06-2.88-9.17-5.24c2.5-0.07,4.91-0.09,6.57-0.15
      c1.21,1.66,3.59,2.56,5.56,2.32c2.44-0.3,4.18-1.92,5.17-4.01c1.35-0.33,2.94-0.55,4.26-0.95
      C202.65,614.15,199.26,619.01,192.56,616.96z"/>
    <path fill="#E6BDA3" d="M203.31,612.15c0.61-1.01,1.15-2.11,1.64-3.22c-1.32,0.4-2.92,0.62-4.26,0.95
      c-0.37,0.78-0.84,1.48-1.41,2.08C200.62,611.91,201.99,611.95,203.31,612.15z"/>
    <path fill="#E6BDA3" d="M187.18,614.67c1.5-0.61,3.02-1.13,4.58-1.55c-0.71-0.39-1.34-0.91-1.8-1.55
      c-1.65,0.05-4.07,0.08-6.57,0.15C184.38,612.84,185.7,613.83,187.18,614.67z"/>
    <path fill="#F2C938" d="M208.56,605.89c-5.18,1.61-20.52,3.18-24.38,3.35c-1.36,0.06-3.34,0.1-3.96-0.94
      c-0.63-1.05-1.64-2.53-1.7-3.22c-0.09-1.13,3.44-6.36,5.24-9.12c1.02,2.23,2.16,4.61,4.7,5.14c1.77,0.37,1.53-2.66,1.23-4.88
      c2.19-2.7-0.63-10.5-1.87-12.44c-1.13-1.75-1.73-1.81-2.64-2.02c-0.02-0.86-0.42-2.16-0.89-2.67c-0.91-0.98-2.34-0.46-3.32,0.09
      c-1.84,1.04-3.9,2.83-5.46,4.79c-0.22-1.16-0.44-2.32-0.67-3.47c-1.84-9.28-4.36-21.52-4.63-23.01c-0.33-1.85,0.05-3.03,1.38-3.4
      c1.32-0.37,27.02-2.92,28.23-2.82c1.27,0.11,1.9,1.12,2.06,2.44c0.15,1.33,2.79,21.08,3.3,23.74c0.29,1.49,1.27,5.42,1.56,6.92
      c-4.47,4.27-7.04,12.08-3.15,13.26c-0.83,4.05,1.68,5.84,3.41,4.66C206.15,603.61,207.1,605.68,208.56,605.89z"/>
    <path fill="#D6A30D" d="M192.06,594.67c-0.28-1.41-0.66-2.81-1.2-4.15c-0.36-0.87-0.76-1.76-1.28-2.55
      c0.92,2.97,1.47,6.59,0.12,8.26c0.3,2.22,0.53,5.25-1.23,4.88c-2.54-0.53-3.68-2.91-4.7-5.14c-1.8,2.76-5.34,7.99-5.24,9.12
      c0.02,0.26,0.18,0.62,0.39,1.04c1.01-2.06,2.41-4.15,4.47-4.3c0.19-0.11,0.44-0.11,0.63,0.09c1.04,1.06,2.23,2.76,3.79,3.02
      c1.72,0.28,3.26-0.76,3.92-2.31C192.77,600.18,192.55,597.21,192.06,594.67z"/>
    <path fill="#ECC200" d="M184.72,579.79c0.27,0.61,0.45,1.4,0.47,1.98c0.84,0.19,1.41,0.26,2.36,1.6
      c0.06-0.64,0.12-1.29-0.1-1.91C186.98,580.09,185.89,579.67,184.72,579.79z"/>
    <path fill="#ECC200" d="M203.6,597.65c-1.03-0.31-1.61-1.1-1.81-2.16c-0.12,0.62-0.19,1.25-0.17,1.87
      c0.04,1.04,0.3,2.1,1.1,2.82c0.35,0.31,0.96,0.54,0.98,1.08c0.03,1.32-0.39,3.14,0.62,4.18c0.47,0.48,0.98,0.85,1.55,1.11
      c1.05-0.22,1.97-0.44,2.69-0.66c-1.46-0.22-2.41-2.28-1.56-3.58C205.28,603.49,202.76,601.7,203.6,597.65z"/>
    <path fill="#FAE891" d="M173.77,555c4.39-0.53,8.78-0.85,13.19-1.17c2.32-0.17,4.64-0.41,6.95-0.64
      c1.85-0.18,3.81-0.46,5.67-0.21c0.94,0.12,1.73,0.6,2.37,1.25c-0.03-0.24-0.05-0.41-0.06-0.49c-0.16-1.33-0.79-2.34-2.06-2.44
      c-1.21-0.1-26.91,2.44-28.23,2.82c-1.26,0.36-1.67,1.44-1.41,3.15C170.71,555.97,172.09,555.2,173.77,555z"/>
    <path fill="#FAE891" d="M191.93,571.91c4.29,1.02,8.58,2.04,12.87,3.06c-0.31-2.07-0.73-5.06-1.15-8.15
      c-4.62-0.89-9.24-1.75-13.86-2.64c-6.39-1.23-12.71-2.72-19.05-4.1c0.35,1.74,0.84,4.18,1.4,6.93
      C178.54,569.24,185.4,570.36,191.93,571.91z"/>
    <path fill="#F7D9C3" d="M208.66,583.87c2.39-1.82,4.12,0.14,2.94,1.33c-1.18,1.19-2.65,3.2-3.74,4.94
      c-0.9,1.43-2.26,3.56-3.52,6.26c-0.2,0.6-1.9,0.05-1.69-2.36c0.17-1.95,1.03-3.81,2.42-6.04
      C206.46,585.76,207.16,585,208.66,583.87z"/>
    <path fill="#F7D9C3" d="M206.46,601.26c-1.96,0.59-2.27-2.02-1.25-4.34c0.88-1.99,4.03-7.26,5.28-8.92
      c1.39-1.84,3.08-4.13,3.98-2.95c1.21,1.59-0.23,4.34-1.2,6.09c-0.97,1.74-2.58,4.34-3.61,5.86
      C208.62,598.52,207.93,600.82,206.46,601.26z"/>
    <path fill="#F7D9C3" d="M210.03,604.75c-0.87,0.37-2.65-0.27-1.84-2.28c0.6-1.49,1.86-3.79,3.74-6.59
      c1.88-2.79,2.71-4.48,3.07-5.38c3.88-0.25,1.5,5.83-0.52,9.19C213.39,601.49,212.09,603.88,210.03,604.75z"/>
    <path fill="#F7D9C3" d="M216.85,597.4c1.05-0.87,1.9,0.05,1.84,1.25c-0.21,4.22-3.87,8.56-4.76,8.06
      c-1.29-0.74-0.75-2.63-0.25-3.6C214.79,601.35,216.25,599.22,216.85,597.4z"/>
    <path fill="#F294B8" d="M204.33,596.4c0.26-0.57,0.54-1.11,0.8-1.63c0.05-0.16,0.1-0.33,0.14-0.5
      c0.14-0.66,0.03-2.16-1.12-1.76c-0.72,0.25-1.21,0.88-1.51,1.56C202.45,596.45,204.14,597,204.33,596.4z"/>
    <path fill="#F294B8" d="M207.17,600.86c0.85-1.59,1.66-3.68,0.89-4.28c-1.23-0.97-2.51,1.47-3.39,2.37
      c-0.15,1.54,0.41,2.72,1.8,2.3C206.72,601.18,206.95,601.04,207.17,600.86z"/>
    <path fill="#F294B8" d="M211.36,600.81c-1.26-1.06-2.32,0.64-3.2,1.72c-0.7,1.84,0.79,2.5,1.69,2.28
      C211.11,603.4,212.34,601.63,211.36,600.81z"/>
    <path fill="#F294B8" d="M215.15,606.15c0.1-0.18,0.21-0.36,0.32-0.55c0.27-0.45,0.75-1.16,0.73-1.69
      c-0.02-0.4-0.33-1.21-0.88-1.15c-0.92,0.09-1.76,1.36-2.17,2.1c-0.07,0.74,0.1,1.46,0.79,1.86
      C214.18,606.85,214.63,606.63,215.15,606.15z"/>
    <path fill="#ED83B5" d="M209.41,611.84c0.05-1.35,0.31-3.25,0.52-4.23c0.53-0.35,1.55-1.76,2.1-2.8
      c-0.19,1.34,0.96,3.1,1.84,3.16c1.17,0.07,3.07-1.32,5.04-5.34c4.1,1.85,9.52,6.36,13.64,11.33
      C225.57,610.55,216.21,609.54,209.41,611.84z"/>
    <path fill="#C9618F" d="M227.92,612.15c1.62,0.49,3.17,1.1,4.63,1.81c-4.12-4.97-9.54-9.48-13.64-11.33
      c-0.53,1.08-1.05,1.96-1.56,2.68C221.39,606.86,224.93,609.2,227.92,612.15z"/>
    <path fill="#F7D9C3" d="M242.33,627.94c-10.66,3.26-29.07-3.67-32.59-3.24c1.11-3.78,1.29-7.89,0.92-11.77
      c8.4-1.65,17.62,0.16,25.1,4.3c1,0.92,2.22,2.16,3.88,3.18C240.42,622.39,241.94,625.69,242.33,627.94z"/>
    <path fill="#E6BDA3" d="M223.14,613.69c3.61,0.75,7.1,2.1,10.22,4.09c3,1.91,5.64,4.25,7.99,6.87
      c-0.58-1.55-1.26-3.11-1.71-4.25c-1.66-1.02-2.88-2.26-3.88-3.18c-7.49-4.14-16.71-5.94-25.1-4.3c0.01,0.11,0.02,0.23,0.03,0.34
      C214.86,613.01,219.03,612.83,223.14,613.69z"/>
    <path fill="#F7D9C3" d="M241.36,580.56c0.99,5.71,1.45,11.7,1.67,13.19c0.21,1.49,1.06,5.67,1.82,11.41
      c0.25,1.89,1.35,16.46-2.34,14.98c-8.49-3.41-14.79-15.7-23.22-18.87c0.49-2.99,0.53-5.5-1.79-5.06c0.12-0.55,0.39-1.51,0.44-2.12
      c3.22,2.07,15.35,11.18,20.07,15.27c-0.02,0.16-0.05,0.31-0.08,0.46c-0.12,0.61,1.15,0.08,1.61-1.78
      c0.78-3.19,0.21-8.01-1.04-13.09c-1.6-6.48-5.42-16.07-6.46-19.63c0.88-4.4,3.33-8.27,4.79-9.58
      C238.76,569.3,240.32,574.55,241.36,580.56z"/>
    <path fill="#E6BDA3" d="M223.5,603.65c-0.02-0.96-0.2-1.92-0.54-2.87c-0.6-1.67-1.65-4.02-3.17-5.43
      c-0.47-0.33-0.89-0.62-1.25-0.86c-0.2-0.1-0.41-0.18-0.63-0.24c-0.07,0.61-0.31,1.45-0.42,1.95c2.31-0.44,2.28,2.07,1.79,5.06
      C220.75,601.82,222.15,602.65,223.5,603.65z"/>
    <path fill="#E6BDA3" d="M237.51,567.1c-0.22-0.48-0.45-0.93-0.68-1.36c-1.01,0.9-2.48,3.02-3.59,5.69
      C234.38,569.59,235.82,567.98,237.51,567.1z"/>
    <path fill="#ED83B5" d="M231.72,576.98c-0.12,0.35-1-0.24-0.94-1.08c0.31-4.45,3.88-10.3,6.1-11.67
      c-1.59-2.45-4.56-4.6-6.94-5.5c-1.27,1.56-6.46,6.24-8.17,7.68c-2.71,2.26-6.03,5.06-8.2,7.78c-0.09,0.11-0.46-2-0.7-2.96
      c-0.81-3.26-2.15-8.2-2.64-9.38c-1.41,1.71-3.37,4.05-4.47,6.17c0.57,3.85,2.58,13.77,2.83,14.97c1.69-0.95,3.81-1.03,4.18,1.03
      c0.94-0.47,2.05-0.48,2.72,0.59c0.73,1.18,0.7,2.58-0.07,4.79c2.14-0.35,2.75,2.02,2.53,3.82c4.77,2.12,17.41,12.44,20.23,15.14
      C239.75,597.09,233.97,587.72,231.72,576.98z"/>
    <path fill="#C9618F" d="M209.76,578.75c-1.37-3.57-2.58-7.22-3.99-10.77c-0.01,0.01-0.01,0.02-0.02,0.03
      c0.57,3.85,2.58,13.77,2.83,14.97c0.84-0.48,1.79-0.73,2.58-0.61C210.69,581.16,210.22,579.96,209.76,578.75z"/>
    <path fill="#C9618F" d="M236.28,592.12c0.22,1.79,0.43,3.58,0.56,5.38c0.2,2.78,0.36,6.25-0.61,9.11
      c0.84,0.73,1.52,1.33,1.96,1.75C238.98,602.59,237.86,597.33,236.28,592.12z"/>
    <path fill="#F8B4D1" d="M233.77,563.39c0.66,0.59,1.32,1.2,1.95,1.84c0.41-0.44,0.81-0.78,1.17-1
      c-1.59-2.45-4.56-4.6-6.94-5.5c-0.22,0.27-0.57,0.64-0.99,1.07C230.73,560.71,232.29,562.07,233.77,563.39z"/>
    <path fill="#B74BA2" d="M213.74,572.85c2.35-2.7,5.06-4.91,7.74-7.17c1.33-1.13,2.61-2.32,3.89-3.51
      c-1.01,0.45-2.12,0.79-3.25,1.03c-3.94,0.82-7.81,0.13-11.44-1.74C211.34,563.03,213.3,570.65,213.74,572.85z"/>
    <path fill="#9A3381" d="M222.12,563.2c-0.41,0.09-0.83,0.15-1.24,0.21c-1.32,1.95-2.8,3.81-3.99,5.84
      c-0.16,0.28-0.32,0.56-0.47,0.84c1.63-1.54,3.35-2.96,5.06-4.4c1.33-1.13,2.61-2.32,3.89-3.51
      C224.36,562.62,223.25,562.97,222.12,563.2z"/>
    <path fill="#F7D9C3" d="M228.02,558.07c-2.73,3.03-6.31,3.49-8.32,3.58c-2.64,0.11-5.67-0.39-8.18-1.38
      c2.49-2.65,4.91-4.86,5.54-6.85c0.77-2.43,0.28-5.57-0.04-6.21c-0.01-0.02-0.01-0.03-0.02-0.04c2.52-0.46,4.91-1.17,7.16-2.1
      C224.21,546.95,226.46,555.68,228.02,558.07z"/>
    <path fill="#E6BDA3" d="M217.42,551.59c1.79-0.26,3.52-0.84,5.21-1.66c0.74-0.36,1.49-0.75,2.21-1.2
      c-0.39-1.65-0.65-3.03-0.67-3.65c-2.25,0.92-4.64,1.64-7.16,2.1c0.01,0.01,0.02,0.02,0.02,0.04
      C217.27,547.7,217.61,549.63,217.42,551.59z"/>
    <path fill="#F7D9C3" d="M219.36,544.32c-11.33,3.43-24.17,0.67-32.32-7.98c-8.04-8.53-9.8-22.87-9.1-30.39
      c5.29-9.73,12.11-18.13,22.47-23.69c2.96,2.72,4.26,8.36,3.97,12.12c-0.01,0.91,4.73-4.12,5.81-6.83c0.93,3.2-0.29,9.36-1.71,11.79
      c-0.18,0.31,1.19,0.02,2.78-0.98c4.24-2.65,7.34-6.73,8.74-12.36c1.12,2.1,2.01,5.04,2.17,7.48c0.04,0.55,4.59-3.07,6.03-7.31
      c0.94,2.5,1.26,5.02,0.71,7.8c-0.11,0.56,2.29-0.77,3.46-4.46c1.82,4.53,0.08,13.79-0.55,15.98c-0.13,0.46,3.34-0.98,5.76-4
      c0.11,2.64,0.22,5.28,0.01,7.95c-0.49,6.24-2.39,13.33-5.7,18.61c-0.45,0.7,6.39-1.4,10.4-4.83
      C238.76,533.66,229.77,541.17,219.36,544.32z"/>
    <path fill="#FFEEE2" d="M206.03,517.03c-1.16,1.2-2.57,0.96-4.05,1.41c-2.17,0.65-3.82,3.49-3.02,5.75
      c0.18,0.52,1.59,1.9,1.96,2.22c-0.79-1.75-0.47-5.32,1.51-5.54c1.87-0.21,2.58-0.53,3.48-1.48c0.73-0.78,0.65-1.87,0.42-2.81
      C206.27,516.74,206.17,516.89,206.03,517.03z"/>
    <path fill="#FFEEE2" d="M205.76,545.43c-4.91-1.51-10.01-3.73-14.04-6.98c-4.15-3.34-7.34-8.29-9.73-13.02
      c-1.25-2.47-2.48-5.08-3.41-7.7c1.15,6.5,3.71,13.57,8.46,18.61c5.09,5.41,12.01,8.51,19.25,9.24
      C206.11,545.53,205.93,545.48,205.76,545.43z"/>
    <path fill="#E6BDA3" d="M187.56,497.66c4.44-4.67,9.42-9.08,15.23-11.94c-0.62-1.35-1.4-2.57-2.38-3.46
      c-10.35,5.56-17.17,13.96-22.47,23.69c-0.1,1.07-0.15,2.27-0.14,3.58C180.22,505.02,184.05,501.35,187.56,497.66z"/>
    <path fill="#E6BDA3" d="M204.66,495.86c2.02-0.39,4.08-1.24,5.62-2.61c0.3-2.09,0.34-4.22-0.1-5.71
      c-0.82,2.05-3.73,5.43-5.09,6.56C204.99,494.7,204.86,495.29,204.66,495.86z"/>
    <path fill="#E6BDA3" d="M234.04,507.22c1.26-0.08,2.48-0.38,3.68-0.79c0.01-1.65-0.06-3.3-0.13-4.95
      c-0.8,1-1.72,1.83-2.58,2.47C234.81,505.07,234.49,506.16,234.04,507.22z"/>
    <path fill="#E6BDA3" d="M236.07,526.9c-0.17,0.36-0.35,0.73-0.55,1.09c-0.6,1.11-2.33,3.48-0.73,4.42
      c0.58,0.34,1.2,0.57,1.84,0.77c2.46-2.91,4.4-6.26,5.66-9.98C240.48,524.75,238.1,526.02,236.07,526.9z"/>
    <path fill="#E6BDA3" d="M212.1,498.74c1.52-0.83,3.03-1.75,4.24-2.99c1.32-1.34,2.33-2.92,3.48-4.39
      c0.32-0.41,0.92-0.04,0.93,0.39c0.04,1.03,0.22,2.04,0.31,3.06c1.66-0.39,3.32-0.81,4.82-1.66c0.38-0.22,0.79,0.07,0.82,0.47
      c0.1,1.04,0.27,2.07,0.38,3.1c2.51-0.12,4.34-1.65,6.03-3.45c-0.1-1.41-0.33-2.71-0.75-3.76c-1.17,3.69-3.57,5.02-3.46,4.46
      c0.55-2.78,0.23-5.3-0.71-7.8c-1.44,4.24-5.99,7.86-6.03,7.31c-0.16-2.44-1.06-5.38-2.17-7.48c-1.3,5.19-4.03,9.06-7.77,11.71
      C212.17,498.05,212.13,498.39,212.1,498.74z"/>
    <path fill="#404751" d="M234.74,553.02c4.1-4.48,7.23-9.72,9.18-15.78c1.39-4.33,2.17-9.7,2.23-12.22
      c0.07-3-0.14-5.16-0.7-7.61c-0.56-2.44-2.03-3.72-1.89-3.24c0.3,1.1,0.71,2.89,0.71,4.71c-2.83,3.15-5.56,4.73-9.31,6.56
      c3.26-6.34,3.89-13.05,4.1-17.59c0.2-4.33-0.14-8.35-0.42-10.75c-0.28-2.4-1.19-2.51-1.17-2.29c0.13,1.45,0.1,2.69,0.06,4.31
      c-0.83,1.74-2.02,3.28-4.24,4.61c0.33-1.58,1.81-7.47,0.49-13.96c-0.45-2.23-1.63-4.48-1.67-3.77c-0.1,1.78-1.09,3.95-1.85,5.36
      c0.11-3.96-2.15-8.51-2.26-8.01c-0.6,3-2.77,6.08-4.88,8.09c-0.61-3.69-3.48-8.51-3.6-7.95c-1.6,7.26-5.45,11.77-9.24,14.24
      c2.99-6.56,0.26-13.39,0.14-13.12c-1.32,2.79-3,5.59-4.65,7.4c-0.04-4.81-1.88-9.7-6.05-11.45c-2.47-1.04-4.17-0.13-3.77-0.12
      c1.56,0.06,2.83,0.56,3.88,1.33c-9.54,4.03-17.13,11.57-22.17,20.59c-1.87,3.35-4.12,8.75-4.91,11.49
      c-0.78,2.74-0.56,4.54-0.3,3.65c0.83-2.92,2.41-5.54,3.74-8.21c-0.27,5.5,0.54,11.55,2.35,16.72c-2.36,0.44-6.24,1.75-8.76,3.02
      c-3.68-7.22-4.84-16.22-1.14-23.68c0.11-0.22-1.87,0.68-3.26,4.94c-3.57-1.07,1.87-18.93,2.54-21.28
      c1.11-3.89,2.67-7.11,4.83-10.57c3.45-5.51,7.57-9.2,14.38-10.74c0.29-0.07-1.18-0.56-2.59-0.39c11.01-12.7,26.98-17.9,42.42-13.83
      c14.76,3.9,26.18,15.55,32.68,29.01c6.56,13.6,7.32,29,2.16,43.14C257.33,537.91,247.95,550.65,234.74,553.02z"/>
    <path fill="#29333D" d="M176.18,509.3c-0.97,1.96-2.09,3.9-2.94,5.95c-0.01,0.25-0.02,0.5-0.02,0.75
      c-0.05,2.54-0.11,5.22,0.4,7.73c0.23,1.16,0.57,2.28,0.99,3.35c1.44-0.48,2.84-0.86,3.92-1.06
      C176.73,520.85,175.91,514.8,176.18,509.3z"/>
    <path fill="#29333D" d="M191.07,484c-1.91,1.7-3.59,3.65-4.95,5.82c-0.62,1-1.19,2.02-1.7,3.07
      c4.28-4.74,9.47-8.61,15.4-11.12c-1.03-0.76-2.28-1.25-3.79-1.33C194.19,481.25,192.47,482.75,191.07,484z"/>
    <path fill="#6A6E77" d="M186.52,467.44c2.04-3.01,8.08-8.86,21.09-12.16c16.85-4.28,33.9,4.79,44.17,18.11
      c5.8,7.52,10.12,15.99,11.85,22.79c1.33,5.23,1.42,10.66,1.28,16.02c1.03-10.08-0.73-20.31-5.26-29.69
      c-6.49-13.46-17.92-25.11-32.68-29.01c-15.44-4.07-31.41,1.13-42.42,13.83C185.27,467.24,186.01,467.33,186.52,467.44z"/>
    <path fill="#6A6E77" d="M178.68,471.61c-2.31,1.8-4.2,4.09-5.92,6.84c-2.16,3.46-3.72,6.68-4.83,10.57
      c-0.67,2.35-6.12,20.21-2.54,21.28c0.29-0.88,0.6-1.62,0.91-2.23C164.1,500.93,172.72,477.83,178.68,471.61z"/>
    <path fill="#6A6E77" d="M198.86,480.26c0.28,0.07,0.56,0.17,0.86,0.29c3.8,1.59,5.66,5.79,5.99,10.15
      C207.09,485.88,203.93,479.56,198.86,480.26z"/>
    <path fill="#6A6E77" d="M220.31,484.37c0.72,1.16,1.76,3.24,2.39,5.3c0.04-0.24,0.07-0.48,0.08-0.73
      C222.83,487.37,221.63,484.71,220.31,484.37z"/>
    <path fill="#6A6E77" d="M235.07,525.21c2.97-3.05,4.34-8.27,4.8-12.44c0.82-7.57-0.45-11.62-1.01-13.52
      c0.2,2.34,0.35,5.38,0.2,8.6C238.85,512.33,238.23,518.94,235.07,525.21z"/>
    <path fill="#6A6E77" d="M210.91,485.75c0.67,2.17,1.56,7.11-0.6,11.93C213.28,494.04,214.89,488.54,210.91,485.75z"/>
    <path fill="#B74BA2" d="M241.86,552.88c10.53-4.95,17.71-15.22,21.93-26.21c1.67-4.34,2.72-8.78,3.24-13.25
      c0.22,2.24,0.37,4.49,0.23,6.8c-0.35,5.78-2.19,11.35-4.59,16.57c-4.29,9.29-12.43,19.74-22.76,22.57
      C241.04,557.55,241.6,555.02,241.86,552.88z"/>
    <path fill="#9A3381" d="M251.62,548.58c3.33-3.27,5.62-6.78,8.01-10.37c4.71-7.07,7.32-18.99,7.4-24.79
      c-0.51,4.47-1.57,8.91-3.24,13.25c-4.22,10.99-11.4,21.26-21.93,26.21c-0.1,0.8-0.24,1.65-0.44,2.5
      C241.88,555.43,246.27,553.84,251.62,548.58z"/>
    <path fill="#47BFE5" d="M264.8,539.33c-2.31,6.45-5.85,12.85-11.08,17.24c0.01-1.01-0.37-2-0.82-2.99
      c3.81-3.48,7.21-8.26,9.57-12.76c2.88-5.48,3.62-7.67,4.66-11.8C267.08,531.71,266.34,535.05,264.8,539.33z"/>
    <path fill="#129BC9" d="M262.47,540.83c-2.36,4.5-5.76,9.28-9.57,12.76c0.17,0.36,0.32,0.73,0.45,1.1
      c4.84-4.65,6.98-8.44,8.16-10.69c1.45-2.75,3.13-6.05,4.1-9.02c0.45-1.38,0.84-2.79,1.08-4.23c0.01-0.04,0.01-0.08,0.02-0.12
      C265.84,533.82,264.95,536.1,262.47,540.83z"/>
    <path d="M230.03,664c0.67-0.58,0.83-1.34,0.47-1.15c-0.7,0.38-1.41,0.76-2.12,1.15H230.03z"/>
    <path d="M195.65,553.45c-2.05,0-3.72,1.86-3.67,3.88c0.06,2.1,1.81,3.68,3.87,3.68c2.05,0,3.72-1.86,3.66-3.89
      C199.47,555.03,197.71,553.45,195.65,553.45z"/>
    <path fill="#B9BDBB" d="M195.66,553.91c-1.81,0-3.28,1.64-3.23,3.42c0.05,1.85,1.6,3.24,3.41,3.24
      c1.81,0,3.28-1.64,3.23-3.42C199.02,555.29,197.47,553.91,195.66,553.91z"/>
    <path fill="#E5E7E6" d="M198.64,558.86c0.29-0.51,0.45-1.1,0.43-1.71c-0.01-0.24-0.04-0.47-0.09-0.69
      c-1.31-0.84-2.66-1.64-3.98-2.48c-0.75,0.17-1.4,0.62-1.86,1.23C194.9,556.53,196.77,557.69,198.64,558.86z"/>
    <path fill="#E5E7E6" d="M192.87,558.88c0.58,1.01,1.69,1.68,2.92,1.69C194.82,560,193.85,559.43,192.87,558.88z"/>
    <path d="M195.69,554.65c-1.41,0-2.54,1.27-2.51,2.65c0.04,1.44,1.24,2.52,2.64,2.52c1.4,0,2.54-1.27,2.51-2.65
      C198.29,555.73,197.09,554.65,195.69,554.65z"/>
    <path d="M191.32,557.45c-0.1-0.84-0.81-1.24-1.58-1.24c-0.77,0-1.32,0.89-1.23,1.59c0.1,0.84,0.81,1.24,1.58,1.24
      C190.86,559.04,191.41,558.15,191.32,557.45z"/>
    <path d="M196.1,497.94c-5.49,0.66-10.04,5.72-9.94,11.45c0.1,5.72,4.92,9.32,9.04,9.35c5,0.04,8.83-2.79,10.35-7.96
      C207.55,504.06,203.23,497.09,196.1,497.94z"/>
    <path fill="#FFFFFF" d="M204.16,505.76c-0.31-3.92-4.06-6.71-7.92-6.18c-4.53,0.61-7.46,3.74-8.47,8.38
      c-1.01,4.65,3.18,8.31,7.21,8.7C199.99,517.15,204.73,512.89,204.16,505.76z"/>
    <path d="M206.58,517.19c0.07-1.41-0.46-1.63-0.5-1.36c-0.4,2.61-2.81,1.95-4.65,2.45c-1.87,0.52-2.89,1.92-3.26,3.45
      c-0.39,1.56,0.09,3.26,1.24,4.41c1.08,1.09,3.41,1.71,4.81,0.65c0.46-0.35,0.87-1.01,0.25-1c-1.56,0.03-3.32-0.04-4.27-1.54
      c-0.88-1.39-0.5-3.45,1.08-4.42c1.28-0.79,2.7-0.47,3.78-0.75C206.17,518.79,206.53,518.04,206.58,517.19z"/>
    <path d="M199.19,489.84c-1.32-1.24-3.8-1.65-5.79-0.91c-3.15,1.16-4.62,4.56-4.4,7.18c0.02,0.31,0.6,0.31,0.7,0.04
      c0.68-1.83,1.63-3.74,3.5-4.98c2.56-1.69,4.87-0.56,6.69,1.11C200.16,492.53,200.57,491.15,199.19,489.84z"/>
    <path d="M218.6,536.64c-0.85-0.51-2.37-0.78-3-0.77c-0.25,0-0.43,0.2-0.24,0.42c0.6,0.67,1.46,1.31,2.33,1.3
      C218.76,537.58,218.83,536.77,218.6,536.64z"/>
    <path d="M221.33,497.93c-7.32,0.14-11.09,6.77-10.63,11.39c0.56,5.58,4.51,9.32,9.88,9.32c5.21,0,8.93-2.74,10.24-8.91
      C232.26,502.92,227.55,497.82,221.33,497.93z"/>
    <path fill="#FFFFFF" d="M220.77,499.48c-4.03,0.26-6.81,2.93-8.06,6.63c-1.58,5.38,1.78,9.78,5.92,10.57
      c4.93,0.94,9.32-1.71,10.32-5.9C230.77,503.09,225.15,499.2,220.77,499.48z"/>
    <path d="M219.41,492.69c0.99,0.7,2.61,1.35,4.28,2.22c1.46,0.76,2.86,1.6,4.36,2.28c0.4,0.18,0.65-0.35,0.43-0.65
      c-1-1.39-3.05-2.93-4.56-3.7C220.42,491.04,219.25,492.58,219.41,492.69z"/>
    <path d="M221.17,525.62c-0.33,0.33-0.69,0.66-1.06,0.97c-1.04-1.72-2.48-3.16-4.61-1.87c-1.27,0.77-1.2,2.7-0.83,4.25
      c-0.69-0.88-1.61-1.68-2.57-1.25c-1.28,0.57-1.46,2.12-1.21,3.39c-0.54,0.13-1.08,0.23-1.6,0.31c-0.25,0.04-0.27,0.44,0,0.46
      c0.73,0.05,1.47,0.05,2.21,0.01c1.25,2.48,5.29,3.93,7.72,2.56c1.29-0.73,1.86-2.35,2-3.74c0.06-0.6,0.04-1.65-0.3-2.36
      c0.39-0.34,0.77-0.7,1.11-1.09C222.77,526.41,221.96,524.81,221.17,525.62z"/>
    <path fill="#D96BA2" d="M220.43,531.51c-0.2,1.08-0.81,2.05-1.75,2.62c-0.86,0.52-2.06,0.31-2.99,0.16
      c-1.66-0.26-2.9-1.08-3.83-2.41c3.04-0.23,6.07-1.21,8.47-3.05C220.62,529.67,220.57,530.76,220.43,531.51z"/>
    <path fill="#D96BA2" d="M215.47,525.51c0.76-1.07,2.25-0.73,3.06,0.03c0.43,0.4,0.75,0.94,1.04,1.48
      c-1.24,0.95-2.68,1.81-4.2,2.51C215.06,528.18,214.62,526.7,215.47,525.51z"/>
    <path fill="#D96BA2" d="M211.19,530.84c-0.01-0.19-0.01-0.39-0.01-0.58c0-0.62,0.1-1.22,0.46-1.73
      c0.94-1.31,2.39,0.19,3.12,1.28c-1.17,0.51-2.37,0.92-3.54,1.22C211.22,530.98,211.22,530.92,211.19,530.84z"/>
    <path fill="#FFFFFF" d="M196.58,555.41c-0.57,0-1.03,0.47-1.03,1.04c0,0.57,0.46,1.04,1.03,1.04c0.57,0,1.04-0.47,1.04-1.04
      C197.62,555.87,197.15,555.41,196.58,555.41z"/>
    <path fill="#FFFFFF" d="M195.52,557.38c-0.18,0-0.33,0.15-0.33,0.33c0,0.18,0.15,0.33,0.33,0.33c0.18,0,0.33-0.15,0.33-0.33
      C195.85,557.53,195.7,557.38,195.52,557.38z"/>
    <path fill="#FFFFFF" d="M194.44,558.69c-0.11,0-0.2,0.09-0.2,0.2c0,0.11,0.09,0.2,0.2,0.2c0.11,0,0.2-0.09,0.2-0.2
      C194.64,558.78,194.55,558.69,194.44,558.69z"/>
    <path d="M216.07,602.91c-1.08-1.26-2.69,1.14-3.11,1.95c-0.06,0.11,0.11,0.21,0.18,0.11c0.33-0.49,0.71-0.95,1.12-1.37
      c0.5-0.5,1.64-1.13,1.67,0.09c0.02,1.1-0.98,2.38-1.61,3.22c-0.07,0.09,0.04,0.24,0.14,0.16c0.65-0.53,1.09-1.23,1.46-1.98
      C216.2,604.5,216.57,603.49,216.07,602.91z"/>
    <path d="M208.28,596.54c-1.36-1.67-3.37,1.51-3.9,2.58c-0.07,0.14,0.14,0.28,0.23,0.15c0.41-0.64,0.88-1.25,1.4-1.81
      c0.62-0.67,2.05-1.49,2.09,0.12c0.03,1.45-1.23,3.14-2.01,4.26c-0.09,0.12,0.06,0.31,0.18,0.21c0.81-0.7,1.37-1.63,1.83-2.62
      C208.43,598.65,208.9,597.32,208.28,596.54z"/>
    <path d="M211.3,600.32c-1.43-1.01-3.33,2.07-3.57,2.98c-0.03,0.11,0.16,0.16,0.21,0.06c0.28-0.66,2.3-3.51,3.22-2.54
      c0.97,1.03-1.34,3.55-1.99,4.38c-0.07,0.09,0.05,0.23,0.16,0.16C210.51,604.55,213.06,601.57,211.3,600.32z"/>
    <path d="M204.67,592.37c-1.41-0.59-2.46,2.38-2.5,3.19c-0.01,0.1,0.17,0.11,0.19,0.01c0.11-0.61,1.32-3.41,2.28-2.75
      c1.02,0.69-0.49,3.26-0.89,4.08c-0.05,0.1,0.08,0.19,0.16,0.1C204.77,596.1,206.4,593.1,204.67,592.37z"/>
    <path d="M188.42,594.89c-0.4-0.25-0.84-0.13-1.11,0.24c-0.46,0.66-0.26,1.7-0.12,2.42c0.18,0.94,0.53,1.78,1.17,2.49
      c0.08,0.08,0.19-0.04,0.14-0.13c-0.6-1.1-0.98-2.53-0.93-3.78c0.02-0.47,0.17-1.11,0.71-0.71c0.36,0.27,0.67,0.81,0.86,1.21
      c0.05,0.11,0.23,0.08,0.21-0.05C189.25,595.9,189.04,595.27,188.42,594.89z"/>
    <path d="M167.89,564.57c-0.04-0.1-0.2-0.05-0.16,0.05c0.64,1.86,2.64,5.26,0.7,6.77c-0.08,0.06-0.04,0.18,0.06,0.18
      C171.19,571.54,168.27,565.55,167.89,564.57z"/>
    <path d="M217.18,620.65c-0.64-0.66-1.48-0.34-1.95,0.32c-0.06,0.09,0.05,0.2,0.13,0.13c0.38-0.3,0.95-0.2,1.23,0.2
      c0.2,0.3,0.06,0.88-0.03,1.19c-0.04,0.15,0.13,0.28,0.27,0.23c0.51-0.22,0.77-0.5,0.78-1.07
      C217.62,621.27,217.43,620.91,217.18,620.65z"/>
    <path d="M186.26,508.49c-1.16,0.23-2.05,0.69-3.27,0.39c-0.97-0.24-1.65-0.85-2.36-1.5c-0.14-0.13-0.47-0.1-0.45,0.16
      c0.09,1.25,1.09,1.92,2.21,2.25c1.57,0.46,2.68-0.27,4.02-0.97C186.6,508.73,186.46,508.46,186.26,508.49z"/>
    <path d="M187.65,505.21c-1.09-0.44-2.1-0.54-2.96-1.47c-0.68-0.73-0.92-1.62-1.16-2.55c-0.05-0.19-0.34-0.34-0.46-0.11
      c-0.6,1.09-0.14,2.21,0.62,3.1c1.07,1.24,2.39,1.24,3.89,1.39C187.81,505.59,187.84,505.29,187.65,505.21z"/>
    <path d="M189.88,501.64c-0.71-0.73-1.46-1.16-1.82-2.19c-0.28-0.81-0.16-1.6-0.03-2.41c0.03-0.17-0.15-0.38-0.32-0.25
      c-0.85,0.65-0.87,1.69-0.58,2.66c0.41,1.35,1.45,1.81,2.58,2.46C189.87,501.99,190,501.76,189.88,501.64z"/>
    <path d="M191.87,500.4c-0.49-0.8-1.08-1.34-1.19-2.34c-0.09-0.79,0.18-1.47,0.46-2.18c0.06-0.14-0.06-0.37-0.24-0.29
      c-0.9,0.41-1.13,1.34-1.06,2.27c0.09,1.3,0.94,1.93,1.82,2.73C191.79,500.71,191.95,500.54,191.87,500.4z"/>
    <path d="M230.54,505.87c1.16,0.23,2.05,0.69,3.27,0.39c0.96-0.24,1.65-0.85,2.36-1.5c0.14-0.13,0.47-0.1,0.45,0.16
      c-0.09,1.25-1.09,1.93-2.21,2.25c-1.57,0.46-2.68-0.27-4.02-0.97C230.2,506.11,230.35,505.83,230.54,505.87z"/>
    <path d="M230.51,507.91c0.88,0.79,1.42,1.64,2.62,2.01c0.95,0.29,1.85,0.11,2.79-0.09c0.19-0.04,0.45,0.15,0.31,0.36
      c-0.71,1.03-1.91,1.11-3.04,0.82c-1.58-0.4-2.17-1.59-2.97-2.88C230.1,507.94,230.36,507.77,230.51,507.91z"/>
    <path d="M228.78,501.96c1.14-0.3,2.15-0.28,3.11-1.08c0.76-0.64,1.11-1.49,1.47-2.39c0.07-0.18,0.38-0.29,0.47-0.06
      c0.46,1.16-0.14,2.21-1,2.99c-1.21,1.1-2.52,0.94-4.03,0.9C228.58,502.32,228.59,502.01,228.78,501.96z"/>
    <path d="M227.16,500.2c0.73-0.51,1.45-0.75,1.93-1.57c0.38-0.65,0.41-1.34,0.44-2.07c0-0.15,0.19-0.3,0.32-0.16
      c0.62,0.7,0.46,1.6,0.05,2.38c-0.58,1.09-1.55,1.32-2.63,1.67C227.11,500.5,227.04,500.28,227.16,500.2z"/>
    <path d="M199.02,504.35c-2.77,0-5.01,2.25-5.01,5.04c0,2.78,2.24,5.03,5.01,5.03c2.77,0,5.01-2.25,5.01-5.03
      C204.03,506.61,201.79,504.35,199.02,504.35z"/>
    <path d="M221.16,504.21c-2.77,0-5.01,2.25-5.01,5.03c0,2.78,2.24,5.03,5.01,5.03c2.77,0,5.01-2.25,5.01-5.03
      C226.17,506.47,223.93,504.21,221.16,504.21z"/>
    <path fill="#FFFFFF" d="M223.61,506.52c-1,0-1.81,0.81-1.81,1.82c0,1.01,0.81,1.82,1.81,1.82c1,0,1.81-0.81,1.81-1.82
      C225.42,507.33,224.61,506.52,223.61,506.52z"/>
    <path fill="#FFFFFF" d="M221.13,509.35c-0.4,0-0.74,0.33-0.74,0.74c0,0.41,0.33,0.74,0.74,0.74c0.41,0,0.74-0.33,0.74-0.74
      C221.87,509.68,221.54,509.35,221.13,509.35z"/>
    <path fill="#FFFFFF" d="M201.67,506.93c-0.87,0-1.57,0.71-1.57,1.58c0,0.87,0.7,1.58,1.57,1.58c0.87,0,1.57-0.71,1.57-1.58
      C203.24,507.64,202.54,506.93,201.67,506.93z"/>
    <path fill="#FFFFFF" d="M199.51,509.39c-0.36,0-0.64,0.29-0.64,0.64c0,0.35,0.28,0.64,0.64,0.64c0.35,0,0.64-0.29,0.64-0.64
      C200.15,509.67,199.87,509.39,199.51,509.39z"/>
  </g>
  <g id="item_2_">
    <path d="M171.75,320.53c-1.45,2.33-6.89,12.69-7.81,18.88c-0.36,2.41-1,6.12,2,6.97c4.14,1.17,8.25-1.18,10.86-4.28
      c-0.27,2.23-0.48,4.45-0.64,6.6c-0.48,6.19,0.16,13.12-3.18,18.6c-2.29,0.46-4.28,3.56-4.68,5.33c1.26,0.36,3.54,0.51,5.1-0.4
      c1.24-0.71,2.11,0.55,3.19,0.69c-0.18,3.63-1.48,9.66-3.14,12.47c3.25-0.9,7.58-1.89,10.96-2.59c0.57,2.37,0.16,5.43-0.15,8.03
      c-0.36,2.93-0.64,5.81-0.73,8.76c-0.2,6.53,0.78,12.3,1.42,14.95c-5.27-0.36-7.52,1.07-10.44,4.18c-0.88,0.46-1.85,1.14-2.56,1.8
      c-2.53,0.6-2.24,3.5-1.4,4.42c0,0.7-0.1,1.7-0.04,2.36c1.85,1.98,16.82,5.13,21.45,1.14c0.12-0.72,0.12-2.61,0.04-3.91
      c0.86-1.34,1.43-3.78,1.39-4.25c-0.04-0.46-0.92-2.14-1.81-2.94c1.65-4.1,1.84-10.34,1.84-15.28c0-2.7-0.1-8.23,0.15-13.35
      c0.52-0.85,0.57-2.09,0.11-3.01c-0.09-1.55-0.05-3.45,0.14-4.36c2.56-0.28,5.14-0.5,7.71-0.65c0.39,0.89,0.55,2.02,0.58,2.94
      c-3.28,1.34-1.79,5.67,0.28,5.93c1.31,6.58,0.54,24.49,0.04,26.73c-2.58,0.33-5.43,1.61-6.35,4.12c-3.74,1.22-3.41,5.64-0.92,6.69
      c-0.07,0.27-0.25,1.81-0.05,2.34c2.53,1.66,17.34,2.66,22.34-0.53c0.14-0.44,0.24-1.97-0.27-2.42c0.53-0.91,0.66-3.36-1.62-3.89
      c-0.21-2.23-3.58-4.54-4.97-5.12c1.26-3.58,2.86-8.79,2.86-12.79c0-6.14-1.33-10.78-2.94-15.63c-0.5-1.51-0.05-5.47,0.28-8.48
      c2.46-0.01,8.2-0.77,10.47,0.28c3.28,1.51,5.37,1.59,6.71,1.15c-0.88-1.54-4.84-9.16-5.77-13.56c0.95,0.3,4,3.57,7.54,2.72
      c0.17-1.09-2.24-4.72-2.56-5.35c0.29-1.26-1.06-2.55-1.98-4.65c-1.66-3.79-3.18-9.7-3.89-17.72c0.79,1.92,1.7,3.79,2.74,5.6
      c2.63,4.56,6.18,10.31,10.9,12.99c1.08,1.05-2.06,2.49-0.14,5.77c0.86-0.43,2.85-0.8,3.42-0.93c0.22,1.54,2.02,6.09,3.1,7.53
      c0.43,0.14,1.28-0.03,1.5-0.08c0.77,2.38,2.58,7.49,3.28,8.86c-1.82,2.38-3.05,5.02-3.76,7.97c-2.57-0.87-3.68,0.86-3.68,2.97
      c0,2.77,1.2,6.41,0.5,8.55c-1.19,3.7-5.64,7.34-2.66,11.93c1.83,2.81,6.56,3.3,9.17,0.6c1.1,2.74,2.9,6.27,4.22,7.97
      c1.65,0.73,8.04,2.53,9.02,2.73c-0.69,2.31,0.82,3.98,2.63,4.34c1.39,0.27,3.14-0.15,3.88-1.45c0.44-0.75,0.58-1.87,0.22-2.63
      c3.81-0.86,10.14-3.19,13.49-4.72c-0.19,2,2.48,3.98,5.37,1.95c1.07-0.75,2.06-3.38,0.48-4.95c1.78-0.93,4.92-3.16,5.66-3.86
      c-0.96-6.47-9.74-30.68-15.1-36.99c-3.22-3.78-6.72-4.89-10.2-5.27c-0.72-2.26-1.56-4.82-2.54-7.06c0.15-0.1,0.67-0.33,0.85-0.69
      c0.18-0.36-0.02-2.35-1.52-4.76c-0.94-1.52-2.27-2.56-3.83-2.63c-0.53-1.94-1.19-3.73-2.81-4.7c-0.54-0.32-1.01-0.25-1.28,0.09
      c-0.64-0.77-1.38-1.08-1.97,0.05c-1.26-1.31-3.3-1.38-4.1,0.82c-1.91-1.88-4.06-0.29-5.44,1.68c-0.36-0.93-1.57-2.68-2.01-2.5
      c-0.11,0.54-0.76,1.53-1.18,2c-5.56-2.07-20.25-23.14-22.5-30.58c1.91-0.72,3.67-1.69,5.04-2.77c6.44-5.11,15.38-0.1,21.75-5.69
      c3.55-3.11,6.86-7.91,6.78-12.79c-5.27,3.23-8.77-3.45-10.13-7.11c-2.19-5.88-1.16-13.37-0.86-19.46
      c0.36-7.35-0.83-14.16-4.85-20.48c-3.12-4.91-7.75-8.88-13.84-9.85c-3.07-0.49-8.99,0.16-12.74,0.16
      c-4.63-4.15-11.42-9.27-17.98-9.3c-11.76-0.05-16.74,6.05-20.4,12.9c-4.8,8.97-11.34,17.04-11.76,26.06
      c-0.4,8.48,1.71,11.51,2.36,16.33c0.91,6.84-0.12,16.06-7.77,12.49C150.52,317.73,167.25,322.13,171.75,320.53z M259.57,365.74
      c0.09,0.38,0.55,0.33,0.94,0.36c0.4,1.41,1.72,5.36,2.05,6.32c-4.95,0.57-10.38,3.48-13.99,7.97c-0.73-1.49-2.45-5.66-3.03-7.18
      c0.2-0.18,0.54-0.83,0.61-1.3c-0.96-2.16-2.85-5.3-2.1-7.06c1.28-0.2,2.8-0.82,2.86-2.08c0.72,2.86,2.34,4.52,3.39,4.45
      c0.68-0.05,1.01-0.94,1.08-3.19c0.6-0.72,0.97-1.8,1.23-2.85c0.18,1.35,0.84,2.99,1.91,2.83c1.07-0.16,1.65-2.12,1.73-3.39
      c0.36,0.34,1.16,0.46,1.55,0.06C258.71,361.67,259.49,365.36,259.57,365.74z M244.7,412.77c-1.01,1.11-4.56,1.87-5.64-0.61
      c-0.73-1.68-0.19-3.42,0.46-5.03c0.69-1.73,2.02-3.91,1.92-5.83c-0.08-1.37-0.46-2.71-0.69-4.06c-0.24-1.41-0.85-4.46,1.19-3.95
      c-0.36,2.33-0.42,4.72-0.19,7.07C242.17,404.64,243.27,408.74,244.7,412.77z"/>
    <path fill="#F5D0B2" d="M248.71,354.2c1.09,1.44,2.29,7.04,2.51,8.15c0.67-0.93,1.39-4.54,1.14-7.03
      C252.1,352.83,249.94,351.28,248.71,354.2z"/>
    <path fill="#F5D0B2" d="M252.61,353.26c0.61,1.23,0.73,4.51,0.4,6.06c0.71,5.05,2.74,3.39,2.48,0.4
      C255.37,358.24,254.41,351.28,252.61,353.26z"/>
    <path fill="#F5D0B2" d="M255.89,353.51c-0.45-0.57-1.26-0.83-1.41-0.32c0.76,1.59,1.48,4.15,1.66,6.31
      c0.76,0.94,1.6,0.49,1.65-0.98C257.84,357.05,256.94,354.85,255.89,353.51z"/>
    <path fill="#EB95AD" d="M246.51,361.48c-0.14-0.48-0.73-2.49-0.88-3.08c-0.7,0.28-1.31,0.68-1.64,1.5
      C245,360.19,245.97,360.7,246.51,361.48z"/>
    <path fill="#EB95AD" d="M258.45,357.91c0.14,0.61,0.08,1.53-0.17,2.11c1.22,1.07,1.94,4.53,2.01,4.97
      c0.07,0.43,2.59-0.67,2.53-1.08C262.42,361.34,260.84,358.17,258.45,357.91z"/>
    <path fill="#C2BFBD" d="M262.42,365.5c-0.38,0.2-0.79,0.35-1.21,0.45c0.45,1.64,1.61,4.74,2.06,6.38
      c0.46-0.03,0.92-0.04,1.37-0.03C264.05,370.22,263.11,367.73,262.42,365.5z"/>
    <path fill="#9C104E" d="M283.52,422.99c1.81-0.93,1.95-3.4,0.68-4.08c-0.18,0.09-0.61,0.27-0.79,0.36
      c0.57,0.55,0.54,1.7-0.09,2.24c-0.94,0.81-2.55,0.77-2.96-0.58C278.97,421.84,281.03,424.27,283.52,422.99z"/>
    <path fill="#C2BFBD" d="M283.08,419.43c-0.65,0.33-1.3,0.66-1.96,0.98c-0.07,0.44,0.4,1.38,1.59,0.74
      C283.47,420.73,283.41,419.75,283.08,419.43z"/>
    <path fill="#ED698C" d="M283.74,418c2.11-1.19,4.14-2.66,5.92-3.86c-1.03-5.21-6.48-20.45-10.37-28.59
      c-2.83-5.93-6.4-11.36-13.6-12.13c-4.46-0.48-8.68,1.22-11.65,3.79c-3.46,3-3.93,6.01-4.06,9.88c-0.2,5.85,1.34,11.07,3.43,16.71
      c2.08,5.64,7.07,15.45,10.3,21.46C269.32,424.41,278.12,421.15,283.74,418z"/>
    <path fill="#C2BFBD" d="M261.68,426.28c-0.36,0.54,0.16,1.17,0.74,1.25c0.58,0.08,1.23-0.49,0.89-1.1
      C263.14,426.44,262.09,426.3,261.68,426.28z"/>
    <path fill="#A9A7A3" d="M283.29,419.89c-0.03-0.19-0.11-0.36-0.21-0.46c-0.65,0.33-1.3,0.66-1.96,0.98
      c-0.02,0.13,0.01,0.31,0.09,0.48C281.75,420.55,282.62,420.08,283.29,419.89z"/>
    <path fill="#A9A7A3" d="M261.68,426.28c-0.15,0.22-0.15,0.45-0.06,0.66c0.39-0.08,1.39-0.11,1.76,0
      c0.04-0.16,0.03-0.33-0.07-0.51C263.14,426.44,262.09,426.3,261.68,426.28z"/>
    <path fill="#9C104E" d="M260.88,428.63c1.01,0.71,1.95,0.62,2.96,0.08c0.93-0.49,1.26-1.75,0.74-2.38
      c-0.24,0.02-0.67,0.05-0.91,0.07c0.31,0.69-0.1,1.68-1.23,1.54c-1.12-0.14-1.34-0.82-1.34-1.7c-0.13-0.01-0.94-0.15-1.07-0.16
      C259.62,426.6,259.95,427.97,260.88,428.63z"/>
    <path fill="#7C003B" d="M280.69,422.93c-0.81-0.55-1.09-1.5-0.32-2c0.41,1.34,2.02,1.39,2.96,0.58
      c0.63-0.55,0.66-1.7,0.09-2.24c0.18-0.09,0.61-0.27,0.79-0.36c0.76,0.41,1,1.47,0.69,2.45
      C283.47,421.84,282.07,422.35,280.69,422.93z"/>
    <path fill="#7C003B" d="M260.51,428.29c-0.64-0.71-0.83-1.76-0.48-2.21c0.13,0.02,0.94,0.15,1.07,0.16
      c0,0.88,0.22,1.56,1.34,1.7c1.12,0.14,1.53-0.85,1.23-1.54c0.24-0.02,0.67-0.05,0.91-0.07c0.4,0.48,0.3,1.34-0.2,1.93
      C263.08,428.14,261.79,428.16,260.51,428.29z"/>
    <path fill="#DE5983" d="M239.46,392.93c-0.3,1.04-0.32,2.3-0.08,3.59c0.34,1.86,0.73,2.94,0.62,4.83
      c-0.18,3.02-3.11,5.96-3.64,8.9c-0.53,2.97,1.18,5.05,3.73,5.74c2.13,0.58,4.07-0.29,5.22-1.29c-0.07-0.21-0.24-0.87-0.31-1.06
      c-2.37,1.39-5.07,1.35-6.44-0.43c-1.82-2.35,0.39-6.48,1.63-9.24c1.29-2.89-0.25-6.16-0.34-9.14c-0.05-1.58,0.81-2.34,2.19-2.16
      c0.06-0.33,0.14-0.65,0.21-0.97C241.02,391.43,239.88,391.42,239.46,392.93z"/>
    <path fill="#B83357" d="M237.88,410.93c-0.68-1.91,0.66-5.09,1.15-6.4c0.03-0.08,0.06-0.16,0.09-0.24
      c0.47-0.97,0.82-1.95,0.88-2.94c0-0.01,0-0.02,0-0.02c0.1-0.45,0.19-0.91,0.25-1.37c0.05-0.33,0.12-0.73,0.18-1.16
      c0.31,1.77,0.49,3.54-0.24,5.19C239.3,405.98,237.9,408.69,237.88,410.93z"/>
    <path fill="#B83357" d="M242.17,414.59c0.93-0.09,1.9-0.4,2.83-0.95c0.07,0.19,0.24,0.84,0.31,1.06
      c-0.74,0.65-1.82,1.24-3.07,1.42C242.28,415.6,242.26,415.08,242.17,414.59z"/>
    <path fill="#C93B69" d="M244.07,388.96c-1.32,3.74-1.58,7.62-1.2,11.53c0.38,3.9,1.57,8.83,3.06,12.44
      c1.38,3.34,2.43,5.76,4.4,9.13c1.97,0.96,9.26,3.21,12.2,3.17c-4.8-8.62-10.26-20.37-12.38-28.2c-1.16-4.26-1.98-11.43-0.22-16.31
      C248.52,381.68,245.77,384.14,244.07,388.96z"/>
    <path fill="#AB1F4F" d="M252.66,404.4c2.65,6.81,6.43,14.62,9.88,20.82c-2.94,0.04-10.23-2.21-12.2-3.17
      c-0.51-0.88-0.96-1.68-1.37-2.46C252.26,415.57,253.24,409.94,252.66,404.4z"/>
    <path fill="#C2BFBD" d="M246.79,382.34c0.38-0.44,0.78-0.87,1.2-1.27c-0.87-2.19-2.38-6.01-3.01-7.56
      c-0.22,0.13-1.16,0.54-1.44,0.64C244.21,375.74,246.01,380.82,246.79,382.34z"/>
    <path fill="#9E9995" d="M261.7,367.5c-0.19-0.58-0.36-1.12-0.48-1.55c0.41-0.1,0.83-0.25,1.21-0.45
      c0.15,0.48,0.31,0.97,0.47,1.46C262.72,367.23,262.15,367.52,261.7,367.5z"/>
    <path fill="#9E9995" d="M244.18,375.78c-0.26-0.68-0.48-1.26-0.63-1.64c0.28-0.09,1.21-0.51,1.44-0.64
      c0.15,0.38,0.36,0.9,0.6,1.49C245.27,375.31,244.62,375.67,244.18,375.78z"/>
    <path fill="#EB95AD" d="M241.83,373.11c0.97,0.21,2.81-0.51,3.15-1.24c-0.66-1.78-2.59-5.05-1.84-6.94
      c-0.95-0.02-2.45-0.33-3.41-0.81C238.65,366.18,240.02,369.71,241.83,373.11z"/>
    <path fill="#D16E90" d="M244.63,360.12c0.77,0.31,1.45,0.75,1.87,1.36c-0.1-0.36-0.46-1.57-0.7-2.41
      C245.73,358.78,244.42,360.03,244.63,360.12z"/>
    <path fill="#D16E90" d="M261.67,364.73c-0.65,0.29-1.34,0.47-1.37,0.25c-0.07-0.43-0.79-3.9-2.01-4.97
      c0.14-0.33,0.22-0.77,0.24-1.21C260.76,359.69,261.62,362.3,261.67,364.73z"/>
    <path fill="#D16E90" d="M241.64,367.23c0.05-1.19-0.45-2-2.06-2.78c0.04-0.11,0.09-0.23,0.15-0.33
      c0.95,0.48,2.45,0.79,3.41,0.81c-0.75,1.89,1.18,5.15,1.84,6.94c-0.2,0.44-0.94,0.86-1.71,1.1
      C242.34,370.98,241.56,369.11,241.64,367.23z"/>
    <path fill="#F5D0B2" d="M238.97,361.88c0.94,1.44,2.49,1.7,4.01,1.84c1.13,0.11,4.06-0.16,2.79-1.63
      c-0.9-1.05-2.93-1.89-4.27-2.1c-0.23-0.04-0.04-0.56,1.44-0.33c0.16-0.52,1.62-1.79,2.52-2.11c-0.14-1.31,0.25-2.1,0.44-1.49
      c0.55,1.86,1.22,4.43,1.77,6.35c0.54,1.92,2.34,4.65,2.8,3.37c0.62-1.74-0.88-7-1.72-9.43c-1.04-3.03-3.32-4.69-6.09,1.19
      C239.95,358.71,238.16,360.63,238.97,361.88z"/>
    <path fill="#E3AD8E" d="M251.01,356.13c0.03,0.76,0.04,1.51-0.02,2.27c-0.05,0.62-0.13,1.23-0.22,1.84
      c-0.46-1.95-1.18-4.6-1.87-5.77C250.77,352.68,250.99,355.45,251.01,356.13z"/>
    <path fill="#E3AD8E" d="M253.75,361.94c-0.29-0.51-0.55-1.34-0.73-2.62c0.32-1.51,0.21-4.61-0.35-5.92
      c0.07,0.04,0.13,0.09,0.19,0.13C254.25,355.54,254.25,359.06,253.75,361.94z"/>
    <path fill="#E3AD8E" d="M254.65,352.98c1.95,1.82,2.14,4.38,2.34,7c-0.26,0.02-0.56-0.13-0.84-0.48
      c-0.18-2.16-0.9-4.73-1.66-6.31C254.51,353.09,254.57,353.03,254.65,352.98z"/>
    <path fill="#E3AD8E" d="M242.82,357.76c0.78-0.76,1.68-1.15,2.68-1.41c-0.06,0.3-0.08,0.72-0.03,1.21
      c-0.9,0.32-2.36,1.59-2.52,2.11c-0.9-0.14-1.32-0.01-1.47,0.13c0-0.01,0-0.02-0.01-0.02C241.21,359.24,242.47,358.09,242.82,357.76
      z"/>
    <path fill="#FFFFFF" d="M235.04,366.76c0.94-0.36,2.17-0.54,3.12-0.74c-0.03-1.02,0.08-2.17,0.6-2.67
      c-2.64-2.51,0.86-5.34,2.82-6.09c0.16-0.43,0.39-0.9,0.66-1.37c-0.31-0.86-0.92-2.34-1.24-2.34c-0.14,1.05-0.72,1.55-1.52,2.09
      c-1.26,0.86-1.58,1.68-1.59,2.99c-0.01,1.63-2.29,1.61-2.02,3.32c0.18,1.15-0.45,1.85-0.72,2.45
      C234.78,365.23,234.98,366.02,235.04,366.76z"/>
    <path fill="#F5D0B2" d="M216.7,425.34c0.17-1.14-0.67-2.4-1.89-1.66c0.32,0.43,0.34,2.03,0.17,2.37
      C215.86,426.68,216.53,426.47,216.7,425.34z"/>
    <path fill="#F14A84" d="M195.8,428.91c3.62,1.09,15.81,2.31,20.86-0.55c0.09-0.34,0.18-0.91,0-1.3
      c-0.55,0.27-1.54,0.12-2.04-0.34c-1.04,0.82-3.14,0.29-3.97-0.54c-0.69,0.9-3.28,0.93-4.21-0.7c-0.92,1.42-3.33,1.77-4.84,0.98
      c-1.38,1.66-3.92,1.77-5.8,1.12C195.71,427.99,195.71,428.53,195.8,428.91z"/>
    <path fill="#F5D0B2" d="M201.15,422.2c0.92-1.7,3.82-1.82,5.35,0.05c1.08-1.08,3.43-0.95,4.55,0.63
      c0.37-0.26,1.09-0.25,1.58-0.23c-1.91-2-8.66-3.83-12.49-1.3C200.46,421.47,200.89,421.85,201.15,422.2z"/>
    <path fill="#F5D0B2" d="M202.06,425.23c1.18,0.86,3.88,0.52,3.78-1.51c-0.05-1.15-1.01-1.88-2.14-1.88
      c-1.09,0-1.74,0.46-2.02,1.05C202.14,423.62,202.23,424.34,202.06,425.23z"/>
    <path fill="#F5D0B2" d="M206.86,423.1c0.07,0.24,0.16,1.1,0.04,1.57c0.63,1.6,3.41,1.73,3.62-0.34
      C210.62,423.3,208.83,421.42,206.86,423.1z"/>
    <path fill="#F5D0B2" d="M211.36,423.64c0.11,0.41,0.03,1.53-0.26,1.79c0.8,0.97,2.84,1.34,3.2,0.04
      C214.73,423.9,213.16,422.85,211.36,423.64z"/>
    <path fill="#F5D0B2" d="M194.59,422.81c-0.97,1.41,0.15,2.99,1.33,3.62c1.31,0.7,3.22,0.55,4.39-0.43
      c1.77-1.48,0.33-4.17-1.71-4.56C196.55,421.05,195.45,421.56,194.59,422.81z"/>
    <path fill="#F14A84" d="M197.27,420.29c0.72-0.01,1.65,0.23,2.26,0.52c4.32-2.54,10.63-1.26,14.3,2.04
      c0.02,0.01,0.02,0.03,0.03,0.05c0.14,0.08,0.28,0.16,0.41,0.25c0.16-0.23,0.4-0.38,0.68-0.46c-0.86-2.44-3.55-4.59-8.09-5.17
      C202.32,416.93,199.14,417.39,197.27,420.29z"/>
    <path fill="#F5D0B2" d="M201.35,385.53c-0.6,1.67,0.83,3.08,2.32,3.46c0.76,0.19-0.33,0.73-0.41,0.73
      c1.51,6.69,0.87,23.77,0.35,26.39c0.88-0.04,3.99,0.35,5.92,0.98c0.98-2.39,2.58-8.67,2.58-13.34c0-4.68-1.39-11.47-2.27-13.73
      c-0.87-2.27-0.62-5.1,0.12-9.46c-2.42-0.04-4.83-0.03-7.25,0.08c0.18,0.48,0.46,2.56,0.37,3.09c0.58,0.16,1.99,1.51,1.19,1.19
      C203.47,384.62,201.84,384.16,201.35,385.53z"/>
    <path fill="#F14A84" d="M191.04,427.86c0.21-0.8,0.17-2.27,0.02-2.94c-0.77,1.16-2.41,1.64-3.72,1.94
      c-2.12,0.48-4.5,0.39-6.24-0.95c-1.42,1.62-3.72,0.67-4.32,0.06c-0.59,0.64-2.01,0.21-2.34-0.32c-0.54,0.29-1.24,0.12-1.74-0.28
      c-0.39,0.31-0.95,0.13-1.34-0.03c-0.08,0.34-0.13,1.18-0.08,1.56C177.29,429.88,188.18,430.47,191.04,427.86z"/>
    <path fill="#F5D0B2" d="M172.16,421.21c-2.65,0.17-1.39,4.33,0,3.57c-0.35-0.54-0.5-2.27,0.3-2.68
      c0.28-0.14-0.05,0.71,0.12,1.47c0.17,0.76,0.59,1.56,1.4,1.39c-0.56-1.18-0.39-2.52,0.28-3.28c0.39-0.44,1.43-0.52,1.08-0.12
      c-1.63,1.81-0.49,4.08,0.96,3.81c-1.16-1.97,0.35-4.91,2.67-4.99c0.72-0.03,0.92,0.48,0.73,0.5c-1.57,0.14-2.87,1.44-2.67,3.1
      c0.21,1.75,1.98,2.72,3.38,1.3c-1.59-1.93,0.06-4.82,2.32-5.21c1.89-0.33,4.39,1.04,5.28,2.66c0.11,0.21-0.15,0.42-0.32,0.3
      c-1.11-0.75-1.99-1.29-3.31-1.72c-3.14-1.01-4.33,3.03-1.44,4.26c2.37,1.01,6.06,0.26,7.97-1.49c-0.91-3.1-5.33-4.44-8.19-4.96
      C178.67,418.36,175.56,419.1,172.16,421.21z"/>
    <path fill="#F14A84" d="M185.49,415.62c-5.38-1.09-8.04,1.81-9,2.4c2.57-0.5,5.9-0.23,8.2,0.42
      c2.09,0.59,6.13,2.1,7.04,4.58c0.27-0.42,0.81-2.29,0.82-2.77C192.57,419.76,191.45,416.83,185.49,415.62z"/>
    <path fill="#F6A3B5" d="M179.93,415.95c1.4-0.52,3.22-0.8,5.56-0.32c5.96,1.21,7.08,4.14,7.07,4.62
      c0,0.11-0.04,0.31-0.09,0.54C189.43,417.19,185.35,416.09,179.93,415.95z"/>
    <path fill="#CB336A" d="M200.85,427.11c0.27-0.19,0.52-0.4,0.74-0.66c1.51,0.8,3.92,0.44,4.84-0.98
      c0.93,1.63,3.52,1.6,4.21,0.7c0.84,0.83,2.94,1.36,3.97,0.54c0.5,0.46,1.49,0.61,2.04,0.34c0.03,0.05,0.03,0.12,0.05,0.18
      C211.45,428.47,206.13,427.92,200.85,427.11z"/>
    <path fill="#CB336A" d="M205.26,418.33c2.47,0.05,4.67,0.79,6.4,2.29c0.21,0.2,0.43,0.4,0.63,0.61
      c0.04,0.04,0.07,0.08,0.11,0.12c0.22,0.25,0.43,0.51,0.64,0.79c0.04,0.05,0.07,0.1,0.1,0.15c-3.73-2.87-9.55-3.87-13.62-1.48
      c-0.52-0.24-1.26-0.44-1.91-0.49C199.59,418.65,202.88,418.28,205.26,418.33z"/>
    <path fill="#CB336A" d="M171.32,425.59c0.01-0.1,0.03-0.19,0.04-0.26c0.39,0.17,0.95,0.34,1.34,0.03
      c0.5,0.39,1.19,0.56,1.74,0.28c0.33,0.53,1.75,0.96,2.34,0.32c0.61,0.61,2.91,1.56,4.32-0.06c1.73,1.35,4.12,1.44,6.24,0.95
      c1.32-0.3,2.95-0.78,3.72-1.94c0.04,0.18,0.07,0.43,0.09,0.7C185.74,428.96,177.68,427.91,171.32,425.59z"/>
    <path fill="#CB336A" d="M183,417.37c3.74,0.38,6.85,1.96,9.04,4.92c-0.11,0.32-0.22,0.6-0.31,0.73
      c-0.91-2.49-4.95-3.99-7.04-4.58c-2.3-0.65-5.63-0.92-8.2-0.42c0.23-0.14,0.55-0.41,0.98-0.72
      C179.42,417.04,181.57,417.22,183,417.37z"/>
    <path fill="#F5D0B2" d="M186.07,414.67c1.44,0.24,3.24,0.76,4.67,1.65c1.4-3.44,1.51-8.28,1.48-13.41
      c-0.02-4.35-0.04-8.82,0.48-13.16c-1.32,0.68-2.47-0.25-2.17-0.29c1.04-0.11,2.03-0.76,2.19-2c0.15-1.14-0.25-1.6-1.38-1.96
      c-0.66-0.21,0.59-0.85,1.49-0.19c-0.05-0.41-0.02-2.49,0.09-3.83c-1.74,0.2-5.35,0.67-7.45,1.11c1.11,4.35-0.22,8.59-0.59,14.49
      C184.52,402.94,184.94,409.33,186.07,414.67z"/>
    <path fill="#E3AD8E" d="M201.15,422.2c0.92-1.7,3.82-1.82,5.35,0.05c1.08-1.08,3.43-0.95,4.55,0.63
      c0.37-0.26,1.09-0.25,1.58-0.23c-1.91-2-8.66-3.83-12.49-1.3C200.46,421.47,200.89,421.85,201.15,422.2z"/>
    <path fill="#E3AD8E" d="M201.68,422.9c0.01-0.03,0.04-0.06,0.06-0.09c0.13,0.4,0.42,0.79,0.69,1.03
      c0.8,0.72,2.05,1.28,3.1,1c-0.73,1.01-2.56,1.07-3.47,0.4C202.23,424.34,202.14,423.62,201.68,422.9z"/>
    <path fill="#E3AD8E" d="M210.45,424.61c-0.43,1.75-2.96,1.57-3.56,0.05c0.03-0.12,0.05-0.27,0.06-0.43
      C207.81,425,209.49,425.11,210.45,424.61z"/>
    <path fill="#E3AD8E" d="M211.4,423.89c0.35,1.03,1.92,1.72,2.93,1.39c-0.01,0.06-0.02,0.12-0.03,0.18
      c-0.36,1.3-2.4,0.93-3.2-0.04C211.35,425.21,211.44,424.39,211.4,423.89z"/>
    <path fill="#E3AD8E" d="M195.11,424.84c1.46,0.72,3.23,0.77,4.68-0.02c0.72-0.39,1.07-0.93,1.11-1.48
      c0.38,0.9,0.29,1.93-0.58,2.66c-1.17,0.98-3.08,1.14-4.39,0.43c-0.84-0.45-1.64-1.38-1.69-2.39
      C194.46,424.38,194.76,424.67,195.11,424.84z"/>
    <path fill="#E3AD8E" d="M202.95,381.81c-0.07-0.51-0.16-0.97-0.24-1.16c2.42-0.11,4.84-0.12,7.25-0.08
      c-0.08,0.47-0.15,0.91-0.22,1.35C207.61,381.42,205.17,381.52,202.95,381.81z"/>
    <path fill="#E3AD8E" d="M186.5,421.11c-0.05-0.04-0.11-0.07-0.16-0.1c-0.05-0.03-0.09-0.06-0.14-0.08
      c-0.46-0.29-0.96-0.52-1.47-0.67c-2.15-0.84-4.64-1.19-7.03-1.24c1.56-0.26,3.19-0.24,5.02,0.09c2.85,0.52,7.28,1.87,8.19,4.96
      c-0.34,0.31-0.75,0.58-1.18,0.82C189.12,423.23,187.97,422,186.5,421.11z"/>
    <path fill="#E3AD8E" d="M172.92,424.43c-0.27-0.77-0.08-1.67,0.57-2.23c0.18-0.16,0.39-0.26,0.61-0.34
      c-0.02,0.04-0.04,0.08-0.05,0.12c-0.48,0.78-0.56,1.95-0.07,2.98C173.49,425.06,173.15,424.81,172.92,424.43z"/>
    <path fill="#E3AD8E" d="M176.26,425.27c-0.13-0.04-0.25-0.1-0.37-0.17c-0.63-0.6-0.85-1.89-0.39-2.53
      c0.35-0.49,0.77-0.8,1.26-1.01C175.92,422.59,175.62,424.08,176.26,425.27z"/>
    <path fill="#E3AD8E" d="M180.42,425.27c-0.17,0.17-0.34,0.3-0.51,0.41c-1.05-0.32-1.6-1.71-1.36-2.78
      c0.24-1.13,1.35-1.78,2.48-1.96C179.81,422.02,179.28,423.88,180.42,425.27z"/>
    <path fill="#E3AD8E" d="M185.68,383.58c-0.06-0.33-0.12-0.66-0.21-0.99c2.09-0.44,5.71-0.91,7.45-1.11
      c-0.03,0.36-0.05,0.78-0.07,1.2C190.54,382.6,188.06,382.93,185.68,383.58z"/>
    <path fill="#A75DB0" d="M175.36,383.93c8-3.01,15.77-3.5,21.73-4c4.48-0.38,10.09-0.34,14.47-0.4c2.63-0.04,6.13-0.4,8.55,0
      c1.92,0.31,4.22,2.02,6.5,1.62c-1.05-1.69-4.62-7.72-5.28-13.01c-1.32-0.11-2.84,0.65-4.12,0.71c-1.06,0.04-2.24-0.82-3.06-1.38
      c-1.73-1.18-3.05-0.37-4.83,0.16c-1.06,0.32-2.24,0.05-3.29-0.13c-2.11-0.36-3.42,0.54-5.24,1.37c-1.34,0.61-2.81,0.28-4.19,0.02
      c-2.46-0.47-3.07,0.48-4.71,1.78c-0.56,0.44-1.34,0.95-2.1,0.72c-1.69-0.49-3.01-1.44-4.64,0.02c-0.58,0.52-1.15,0.78-1.94,0.53
      c-0.81-0.26-2.05-1.34-3.1-1.12c-1.05,0.23-2.12,1.84-2.63,1.96C177.46,376.39,176.39,381.87,175.36,383.93z"/>
    <path fill="#904D99" d="M214.41,369.85c-1.8-0.82-3.49-1.56-5.51-1.56c-2.48,0-5.09,0.13-7.52,0.66
      c-2.31,0.5-4.44,1.41-6.68,2.14c-2.86,0.93-5.54,0.8-8.47,1.09c-1.99,0.2-3.87,0.82-5.84,1.1c-1.05,0.15-2.03,0.31-2.96,0.53
      c0.02-0.35,0.03-0.7,0.03-1.02c0.51-0.12,1.58-1.73,2.63-1.96c1.05-0.23,2.29,0.86,3.1,1.12c0.79,0.25,1.36-0.01,1.94-0.53
      c1.63-1.45,2.95-0.51,4.64-0.02c0.76,0.22,1.54-0.28,2.1-0.72c1.64-1.31,2.25-2.25,4.71-1.78c1.37,0.26,2.85,0.58,4.19-0.02
      c1.82-0.83,3.13-1.73,5.24-1.37c1.06,0.18,2.23,0.45,3.29,0.13c1.77-0.54,3.09-1.34,4.83-0.16c0.82,0.56,2,1.42,3.06,1.38
      c1.28-0.05,2.8-0.81,4.12-0.71c0.06,0.51,0.16,1.04,0.28,1.56C219.12,369.81,217.04,371.04,214.41,369.85z"/>
    <path fill="#FFFFFF" d="M172.75,368.22c-1.42,0.45-3.21,3.24-3.26,3.73c0.71,0.2,2.04,0.21,2.92-0.04
      c0.9-0.25,1.7-1.1,2.64-0.41c2.28,1.66,2.59-0.36,4.3-1.29c0.69-0.37,1.41-0.21,2.06,0.11c1.39,0.66,2.23,1.67,3.69,0.31
      c0.82-0.76,1.55-1,2.64-0.74c1.25,0.3,1.68,1.57,3.41,0.59c0.54-0.31,0.92-0.92,1.39-1.33c0.81-0.7,1.7-1.09,2.8-1
      c2.4,0.19,4.1,0.76,6.42-0.43c1.19-0.6,2.15-1.01,3.51-0.95c0.93,0.04,1.85,0.33,2.79,0.39c1.07,0.07,2.09-0.47,3.05-0.86
      c0.84-0.34,1.72-0.24,2.53,0.1c1.29,0.53,2.18,1.4,3.61,1.7c1.36,0.28,2.3-0.79,3.63-0.86c0.75-0.04,1.69,0.36,2.34,0.97
      c0.65,0.61,3.03,2.27,5.56,2.38c-0.11-0.97-1.55-3.43-2.38-4.47c-1.7-1.37-4.04-2.34-8.44-2.89c-4.8-0.59-11.66,0.62-16.42,1.38
      c-5.57,0.89-11.07,2.07-16.58,3.26C180.86,368.73,175.32,368.92,172.75,368.22z"/>
    <path fill="#1A55BD" d="M170.05,325.87c-1.49,3.03-2.93,6.06-4.02,9.26c-0.86,2.55-1.95,5.7-1.1,8.38
      c0.57,1.8,2.53,2.12,4.19,1.99c3.09-0.24,5.65-2.66,7.84-4.66c0.62-4.51,1.62-9.01,3.5-12.99c0.05-0.1,0.24-0.09,0.21,0.04
      c-2.21,8.06-3.15,16.46-3.47,22.53c-0.28,5.16,0.37,12.7-3.28,16.99c5.14,0.97,10.82-0.6,15.78-1.7c5.44-1.2,10.5-2.31,16.03-2.98
      c3.87-0.47,8.28-0.85,12.15-0.31c3.09,0.43,6.35,0.94,8.19,2.67c0.4-0.79-0.94-2.17-1.59-3.79c-0.69-1.73-1.66-4.17-2.22-6.34
      c-1.18-4.6-1.47-9.17-1.86-13.86l0-0.01c-1.34-3.71-2.75-7.58-3.58-11.44c-0.05-0.21,1.22,2.64,2.32,5.61
      c1.09,2.97,2.51,7.27,4.3,10.79c2.19,4.3,7.18,12.15,11.76,15.03c0.29-0.93,1.52-1.11,1.99-2.05c0.33-0.67-0.05-1.31,0.25-2.23
      c0.27-0.84,1.23-1.09,1.63-1.63c-5.7-1.76-20.89-24.16-22.92-30.79c-3.29,1.06-6.9,1.32-9.64-0.03c-0.44,0.23-0.81,0.53-1.22,0.92
      c-0.42,0.41-1.01,0.85-1.61,0.92c-0.58,0.07-1.07,0.06-1.58-0.24c-0.61-0.36-2.31,0.98-2.75,1.56c0.25-1.52,0.87-5.63-1.02-9.01
      c-0.96,2.18-1.72,5.74-1.58,8.34c-0.79-0.93-2.24-1.94-3.23-0.97c-0.43,0.43-0.81,0.83-1.44,0.93c-1.01,0.17-1.61-0.38-2.19-1.09
      c-1.38-1.69-3.67-0.4-5.36-0.97c-1.07-0.36-1.91-1.21-2.31-2.18c0.31,1.72,0.46,5.13-0.02,5.68c-0.57-0.73-1.64-1.77-2.86-1.6
      c-1.47,3.78-2.28,7.35-4.05,10.51c-1.09,1.95-2.09,4.2-4.04,5.01c-1.72,0.72-2.74,0.17-2.38-0.07c3.51-2.27,5.62-5,7.17-8.8
      c0.86-2.12,1.88-4.24,2.56-6.66c-2.56-0.95-0.52-4.25-3.7-4.18c-1.03,0.02-1.74-0.54-1.59-1.41c0.07-0.37-0.13-0.77-0.5-0.87
      C172.18,322.07,170.93,324.07,170.05,325.87z"/>
    <path fill="#0049A4" d="M176.31,326.28c-0.67-0.69-0.62-2.1-1.22-2.52c-0.51-0.36-1.47-0.46-1.95-0.84
      c-0.48-0.38-0.61-1.17-0.77-1.65c0.16-0.37,0.3-0.75,0.42-1.12c0.37,0.11,0.57,0.5,0.5,0.87c-0.15,0.88,0.56,1.43,1.59,1.41
      c3.18-0.07,1.14,3.23,3.7,4.18C178.52,326.85,177.15,327.14,176.31,326.28z"/>
    <path fill="#0049A4" d="M197.04,326.28c-0.1,0.05-0.21,0.08-0.32,0.13c-0.05-2.55,0.67-5.85,1.58-7.92
      c1.25,2.23,1.4,4.78,1.29,6.69C198.68,325.42,197.89,325.85,197.04,326.28z"/>
    <path fill="#0049A4" d="M194.42,326.38c-0.98,0.21-1.67,1.73-2.71,1.77c-2.52,0.09-1.89-2.25-4.67-2.24
      c-0.96,0-2.71,0.84-4.62-1.44c-0.05-0.71-0.13-1.39-0.22-1.91c0.4,0.97,1.24,1.82,2.31,2.18c1.69,0.57,3.98-0.72,5.36,0.97
      c0.58,0.71,1.19,1.26,2.19,1.09c0.63-0.1,1-0.5,1.44-0.93c0.88-0.87,2.12-0.16,2.94,0.66
      C195.64,326.47,194.99,326.26,194.42,326.38z"/>
    <path fill="#0049A4" d="M220.39,341.1c0.16,1.97,0.32,3.93,0.52,5.87c-0.96-1.14-3.78-9.6-3.71-15.68
      C218.04,334.61,219.24,337.92,220.39,341.1L220.39,341.1z"/>
    <path fill="#0049A4" d="M204.53,325.86c0.28-0.17,0.53-0.38,0.74-0.59c0.41-0.39,0.79-0.69,1.22-0.92
      c2.74,1.35,6.35,1.1,9.64,0.03C213.03,327.29,206.18,326.27,204.53,325.86z"/>
    <path fill="#FFFFFF" d="M211.76,310.46c-2.68,2.14-6.76,4.23-10.14,4.7c-4.91,0.69-9.32,0.44-12.03-0.74
      c-0.68,2.63-2.37,5.37-4.01,4.79c-0.73,1.36-1.75,2.49-3.14,2.66c0.31,0.84,1.08,1.73,2.12,2.06c0.77,0.25,2.02-0.06,2.82-0.04
      c1.23,0.03,2.31,0.4,3.14,1.31c1.87,2.05,2.41-0.9,4.03-0.75c0.56,0.05,1.08,0.33,1.49,0.72c-0.16-2.9,1.17-6.45,2.45-8.72
      c0.06-0.11,0.24-0.03,0.22,0.09c-0.05,0.35-0.12,0.7-0.2,1.05c1.46,2.18,2.47,5.87,1.58,8.58c0.56-0.77,1.27-1.52,2.2-1.09
      c1.53,0.7,2.36-0.48,3.45-1.16c-1.27-0.82-2.37-2.97-3.02-4.7C207.66,319.07,211.43,314.68,211.76,310.46z"/>
    <path fill="#F5D0B2" d="M179.25,306.98c2.21-3.94,5.44-7.79,7.54-9.13c0.54-0.34,1.09-0.5,1.47-0.32
      c0.69,0.34,0.7,1.08,0.67,1.24c-0.45,2.12-2.27,4.22-3.64,6.14c1.85,0.04,2.8,1.49,2.72,2.38c1.87,0.76,1.55,2.48,1.16,3.4
      c0.31,0.54,0.7,1.96,0.62,2.47c1.95,0.83,6.74,1.42,10.09,0.89c5.59-0.89,10.15-3.47,12.52-6.26c3.92-7.69,1.85-19.78,3.37-28.3
      c0.92-5.14-0.28-8.94-2.74-13.55c-2.04-3.85-5.41-5.43-9.29-6.61c-3.03-0.92-5.2-1.65-6.28-4.61c-1.35-0.19-3.01-1.13-4.42-2
      c-3.2-1.96-5.48-0.1-7.8,1.82c-3.17,2.62-4.55,6.84-6,10.49c-1.12,2.79-1.53,5.69-3.28,8.23c-1.11,1.61-2.34,2.98-3.68,4.25
      C168.89,287.16,172.03,301.64,179.25,306.98z"/>
    <path fill="#E3AD8E" d="M212.14,301.22c0.27-3.99,0.28-8,0.66-11.99c0.35-3.7,1.16-7.33,1.23-11.06
      c0.1-5.27-0.09-11.44-4.76-14.83c-2-1.45-4.23-2.45-6.39-3.63c-1.54-0.84-4.23-2.19-4.99-4.07c1.18,2.18,3.18,2.85,5.85,3.66
      c3.88,1.18,7.25,2.76,9.29,6.61c2.45,4.61,3.65,8.41,2.74,13.55c-1.52,8.52,0.55,20.61-3.37,28.3c-0.54,0.64-1.21,1.26-1.96,1.86
      C211.42,307.05,211.93,304.27,212.14,301.22z"/>
    <path fill="#E3AD8E" d="M188.28,255.01c-2.69,1.55-4.4,4.26-5.77,6.96c-2.8,5.5-4.73,13.73-10.6,16.67
      c0.12-0.38,0.23-0.77,0.36-1.15c1.34-1.28,2.57-2.65,3.68-4.25c1.75-2.55,2.16-5.44,3.28-8.23c1.46-3.65,2.83-7.87,6-10.49
      c2.32-1.92,4.6-3.77,7.8-1.82c1.11,0.68,2.36,1.39,3.5,1.76C193.82,253.86,190.77,253.57,188.28,255.01z"/>
    <path fill="#E3AD8E" d="M186.18,285.54c-0.34-1-0.03-2.07,0.75-2.7c-0.31,1.73,1.23,2.56,2.96,2.78
      c0.66,0.23,1.35,0.33,1.94,0.28c-0.14,1.85-3.79,1.07-5.84,0.12C186.15,285.93,186.25,285.76,186.18,285.54z"/>
    <path fill="#E3AD8E" d="M185.29,304.9c1.35-1.88,3.13-3.94,3.62-6.02c0.62,1.68-0.63,4.39-2.23,6.38
      C186.29,305.06,185.83,304.91,185.29,304.9z"/>
    <path fill="#E3AD8E" d="M189.17,310.68c0.39-0.92,0.7-2.64-1.16-3.4c0.01-0.14-0.01-0.29-0.05-0.44
      c2.23,1.01,3.11,3.96,2.76,6.62c-0.35-0.1-0.67-0.21-0.93-0.32C189.87,312.64,189.48,311.22,189.17,310.68z"/>
    <path fill="#F5D0B2" d="M185.88,317.87c0.82,0.58,2.24-1.44,2.75-3.83c0.5-2.38-0.56-3.68-1.84-3.21
      c-0.3,0.11-0.2-0.48-0.02-0.71c0.67-0.83,1.32-0.71,1.84-0.21c0.2-0.98-0.18-1.71-0.94-1.94c-0.72-0.22-1.23,0.12-1.69,0.53
      c-0.26,0.23-0.29-0.99,1.19-1.37c-0.2-1.02-2.04-2.13-3.65,0.11c-0.13,0.18-0.12-1.23,0.62-1.94c1.89-2.44,4.3-5.6,4.21-6.77
      c-0.05-0.64-0.68-0.81-1.4-0.26c-1.59,1.21-4.29,4.59-5.83,6.9c-0.92,1.38-3.26,5.58-3.87,6.9c-0.35,0.76-0.48,1.53-0.49,2.29
      c0.19,2.44,1.1,4.44,3.35,5.84c3.24,2.02,5.3-2.9,5.9-5.22C186.21,314.27,186.52,316.24,185.88,317.87z"/>
    <path fill="#FFFFFF" d="M171.9,319.04c0.78,0.06,2.12,0.67,2.05,1.68c-0.11,1.66,1.29,0.85,1.85,0.96
      c0.55,0.11,1.14,0.38,1.49,0.84c0.78,1.02,0.32,3.37,2.16,3.33c0.82-0.02,1.84,0.39,2.32,1.07c0.3-0.65,0.01-3.66-0.29-5.04
      c-2.7-0.37-5.23-2.53-5.75-5.81c-1.31-0.02-3.98,1.05-5.5,2.21C170.58,318.67,171.28,319,171.9,319.04z"/>
    <path fill="#E4ECEC" d="M236.38,366.37c0.53-1.36,1.17-2.68,1.59-4.19c0.14,0.39,0.39,0.78,0.79,1.16
      c-0.52,0.49-0.63,1.65-0.6,2.67C237.62,366.13,237,366.24,236.38,366.37z"/>
    <path fill="#E4ECEC" d="M222.55,367.4c0.77,0.48,1.4,1.11,2.01,1.75c-0.61-0.37-1.08-0.73-1.31-0.95
      c-0.65-0.61-1.6-1.01-2.34-0.97c-1.34,0.07-2.28,1.15-3.63,0.86c-0.37-0.08-0.7-0.19-1.01-0.33
      C218.41,366.64,220.16,365.91,222.55,367.4z"/>
    <path fill="#E4ECEC" d="M198.66,316.44c-0.04-0.03-0.1-0.04-0.14,0c-3.22-0.06-6.65-0.07-9.9,0.52
      c0.41-0.78,0.75-1.66,0.98-2.54c2.71,1.18,7.12,1.42,12.03,0.74c3.37-0.47,7.46-2.56,10.14-4.7c-0.19,2.5-1.61,5.07-3.76,6.77
      C205.94,315.7,200.41,316.46,198.66,316.44z"/>
    <path fill="#E4ECEC" d="M205.74,323.93c-0.58,0.36-1.09,0.86-1.67,1.15c-1.09-1.09-1.85-2.48-2.02-4.06
      c0.45-0.09,0.9-0.15,1.35-0.19C204.04,322.12,204.85,323.36,205.74,323.93z"/>
    <path fill="#E4ECEC" d="M196.92,319.98c-0.59,1.71-0.98,3.56-0.89,5.2c-0.21-0.2-0.46-0.37-0.72-0.5
      C195.47,322.95,195.9,321.24,196.92,319.98z"/>
    <path fill="#F7BA0A" d="M170.52,319.37c-0.59-0.31-1.13-0.77-1.27-1.4c-0.04-0.18,3.8-2.53,6.42-2.7
      c-0.12-1.64,0.66-3.36,1.47-4.88c0.38-0.71,0.77-1.45,1.18-2.18c-4.26-2.37-7.9-8.5-8.67-13.86c-0.86-6.06-0.24-10.94,1.42-15.74
      c-0.94,0.84-5.2,4.17-3.05,1.55c3.6-4.4,7.44-7.77,8.79-13.53c0.94-3.97,2.33-7.61,4.64-11.01c1.47-2.16,4.13-4.49,6.85-4.89
      c3.75-0.56,6.3,2.21,8.82,2.92c-0.72-2.84-0.71-5.75-0.39-4.72c0.89,2.91,1.95,5.75,3.4,7.12c1.52,1.43,4.23,1.67,6.21,2.41
      c3.38,1.26,6.22,3.23,8.19,6.22c5.2,7.91,2.54,14.96,2.22,23.28c-0.16,4.13,0.23,8.21-0.19,12.34c-0.39,3.83-2.03,7.7-3.38,11.29
      c-1.33,3.53-4.34,8.31-8.42,8.72c0.32,1.05,1.33,2.14,2.69,2.86c2.67,1.43,6.43,0.78,9.13-0.16c3.3-1.14,5.34-4.45,8.79-5.22
      c4.99-1.11,10.16,0.54,14.89-1.78c4.24-2.08,8.13-8.67,8.64-11.55c-3,1.63-6.72-3.22-8.14-5.94c-4.2-8.08-1.64-18.28-2.15-26.91
      c-0.62-10.54-6.78-22.72-18.23-24.61c-5.88-0.97-12.65,1.94-18.76-0.68c-2.2-0.94-1.87-2.03-1.56-1.86
      c1.82,0.97,4.72,1.77,6.87,1.68c-2.49-1.57-5.51-4.17-7.99-5.73c-6.5-4.1-15.62-3.21-21.19,1.36c-3.82,3.13-6.23,7.73-8.73,11.79
      c-2.92,4.74-7.12,11.94-8.78,17.29c-3.05,9.79,2.82,17.92,1.7,27.81c-0.64,5.62-3.31,9.76-8.43,7.71
      C153.67,315.69,165.89,320.58,170.52,319.37z"/>
    <path fill="#F9D15D" d="M236.12,261.41c-1.92-2.78-4.04-5.65-6.54-7.95c-4.87-4.47-10.84-5.34-16.98-6.32
      c2.68-0.26,5.31-0.55,7.79-0.14c8.06,1.33,13.49,7.76,16.22,15.13C236.45,261.89,236.28,261.65,236.12,261.41z"/>
    <path fill="#F9D15D" d="M172.27,248.28c1.55-2.4,3.28-4.69,5.48-6.5c4.03-3.31,9.9-4.68,15.31-3.64
      C184.57,239.04,177.5,243.1,172.27,248.28z"/>
    <path fill="#F9D15D" d="M230.58,266.14c0.68,2.99-0.12,5.83-0.92,8.68c0.15-2.46,0.14-4.9-0.48-7.26
      c-1.08-4.07-3.71-7.46-7.65-9.08c-7.16-2.93-17.81-2.51-22.49-9.46c5.78,4.31,13.48,4.69,20.35,7
      C224.14,257.6,229.38,260.84,230.58,266.14z"/>
    <path fill="#D4990A" d="M196.55,249.03c-0.05,0.74,0.1,2.7,0.59,4.62c-1.4-0.39-2.82-1.42-4.44-2.17
      C193.64,250.27,194.94,249.37,196.55,249.03z"/>
    <path fill="#D4990A" d="M167.6,316.09c3.55-5.12,1.11-10.67,0.81-16.32c-0.22-4.25-0.33-8.82,0.95-12.91
      c-0.17,2.33-0.09,4.79,0.29,7.49c0.77,5.36,4.41,11.49,8.67,13.86c-0.41,0.73-0.8,1.47-1.18,2.18c-0.81,1.52-1.59,3.24-1.47,4.88
      c-2.62,0.17-6.46,2.52-6.42,2.7c0.13,0.63,0.68,1.09,1.27,1.4c-1.4,0.36-3.49,0.17-5.73-0.53
      C165.87,318.1,166.83,317.21,167.6,316.09z"/>
    <path fill="#C14266" d="M253.86,394.03c1.65-1.04,5.26-0.95,6.72,0.26c2.24-1.86,4.88-3.21,8.05-3.38
      c0.74-2.3,2.6-4.69,5.18-4.99c0.36,1.32,0.88,4.18,1.12,6.14c2.99,1.78,5.32,3.82,5.18,7.21c-0.11,2.66-1.41,4.72-3.39,6.35
      c2.08,3.05,2.12,5.96,0.71,6.77c-1.81,1.04-3.54-2.69-4.01-4.77c-1.81,0.79-3.77,1.25-5.58,1.33c0.6,1.33,1.21,2.64,1.32,3.61
      c0.12,1.01-0.47,2.5-1.66,2.52c-0.97,0.02-1.61-0.54-2.24-1.75c-0.63-1.21-1.23-3.21-1.51-4.52c-5.45-1.26-7.59-5.04-6.33-9.55
      C256.81,398.89,254.85,396.83,253.86,394.03z"/>
    <path d="M254.8,393.01c1.65-1.04,5.27-0.95,6.72,0.26c2.24-1.86,4.88-3.21,8.05-3.38c0.74-2.3,2.6-4.69,5.18-4.99
      c0.36,1.31,0.88,4.18,1.12,6.14c2.99,1.78,5.32,3.82,5.18,7.21c-0.11,2.66-1.41,4.72-3.39,6.35c2.08,3.05,2.12,5.96,0.71,6.77
      c-1.81,1.04-3.54-2.69-4.01-4.77c-1.81,0.79-3.77,1.25-5.58,1.33c0.6,1.33,1.21,2.64,1.32,3.61c0.12,1.01-0.47,2.5-1.66,2.52
      c-0.97,0.02-1.61-0.54-2.24-1.75c-0.63-1.21-1.23-3.21-1.51-4.52c-5.45-1.26-7.59-5.04-6.33-9.55
      C257.75,397.87,255.79,395.8,254.8,393.01z"/>
    <path fill="#FFFFFF" d="M274.29,385.65c-2.06,0.6-3.21,2.4-3.95,4.34c1.76-0.06,3.59,0.19,4.88,0.77
      C275.02,389.53,274.75,387.5,274.29,385.65z"/>
    <path fill="#FFFFFF" d="M265.57,407.94c0.15,0.82,0.53,2.06,0.96,3.15c0.52,1.31,1.6,3,2.49,1.59
      c0.61-0.96-0.08-2.69-1.12-4.71C267.24,408.05,266.26,408,265.57,407.94z"/>
    <path fill="#FFFFFF" d="M261.6,405.31c1.86,1.2,4.55,2.25,8.41,1.48c3.86-0.77,8.48-2.13,10.07-7.38
      c0.97-3.2-1.61-6.29-4.2-7.68c-1.8-0.97-3.86-1.23-6.36-1.16c-2.49,0.06-8.44,2.48-10.12,7.26
      C258.34,400.81,259.06,403.67,261.6,405.31z"/>
    <path fill="#FFFFFF" d="M258.6,397.35c0.48-1.43,1.27-2.57,2.39-3.6c-0.79-0.68-2.93-1.23-5.23-0.33
      C256.42,394.68,257.9,396.87,258.6,397.35z"/>
    <path d="M271.01,398.8c1.22-0.25,0.81,1.39,0.32,1.48C270.53,400.4,269.8,399.04,271.01,398.8z"/>
    <path d="M270.64,402.05c-0.03,1.13,0.47,1.6,1.35,1.5c0.82-0.09,0.82-1.08,0.52-1.71c-0.18-0.37,0.27,0.03,0.41,0.44
      c0.28,0.83,0.19,1.71-0.88,1.95c-0.49,0.11-1.13-0.12-1.48-0.66C270.06,402.79,270.65,401.53,270.64,402.05z"/>
    <path d="M271.25,392.47c1.05-1.14,3.12-0.78,4.21,0.56c1.53,1.87,1.34,5.62-1.73,5.7c-1.2,0.03-2.08-0.52-2.77-1.81
      C270.28,395.64,270.17,393.64,271.25,392.47z"/>
    <path d="M263.58,394.59c1.33-1.3,3.09-0.78,4.19,0.07c1.28,0.99,2.09,2.73,1.67,4.29c-0.84,3.13-4.43,3.63-6.19,0.65
      C262.45,398.26,262.36,395.78,263.58,394.59z"/>
    <path fill="#FFFFFF" d="M277.24,410.52c1,0.57,1.3-0.73,1.29-1.37c-0.02-1.31-0.6-3.07-1.43-4.1
      c-0.63,0.46-1.3,0.87-2.01,1.22C275.52,407.78,276.07,409.85,277.24,410.52z"/>
    <path fill="#E1D7EE" d="M274.98,389.21c0.09,0.59,0.17,1.12,0.24,1.56c-1.29-0.58-3.12-0.83-4.88-0.77
      c0.21-0.54,0.45-1.07,0.73-1.56C272.32,388.87,273.65,389.11,274.98,389.21z"/>
    <path fill="#E1D7EE" d="M266.22,410.26c-0.3-0.85-0.54-1.7-0.65-2.31c0.69,0.05,1.67,0.11,2.33,0.03
      c0.27,0.53,0.52,1.03,0.72,1.51C267.78,409.61,266.98,409.89,266.22,410.26z"/>
    <path fill="#E1D7EE" d="M260.08,393.26c0.38,0.14,0.7,0.3,0.91,0.49c-1.12,1.03-1.91,2.16-2.39,3.6
      c-0.26-0.18-0.64-0.6-1.03-1.12C258.49,395.31,259.31,394.28,260.08,393.26z"/>
    <path fill="#E1D7EE" d="M275.56,407.84c-0.18-0.54-0.34-1.08-0.47-1.57c0.71-0.35,1.38-0.76,2.01-1.22
      c0.38,0.47,0.7,1.08,0.94,1.74C277.15,406.98,276.29,407.33,275.56,407.84z"/>
    <path d="M175.86,281.62c0.64-0.78,1.41-1.57,2.28-2.31c0.03-0.85,0.21-1.71,0.58-2.55c1.27-2.87,4.21-4.87,7.54-4.25
      c1.23,0.23,2.7,1.15,3.57,2.12c0.6,0.04,1.19,0.12,1.77,0.27c0.17,0.04,0.19,0.3,0.01,0.34c-0.37,0.09-0.75,0.17-1.12,0.25
      c0.87,1.31,1.33,2.86,1.08,4.39c0.54,0.07,1.04,0.17,1.45,0.28c0.2,0.05,0.14,0.34-0.04,0.38c-1.49,0.25-5.47-0.07-4.76,2.53
      c0.38,1.38,1.65,2.29,3.42,2.56c1,0.16-1.41,1.18-2.79,0.18c-1.74,1.27-3.57,1.52-5.22,1.09c-0.15,0.11-0.25,0.24-0.33,0.4
      c-0.15,0.3-0.25,0.74-0.2,1.07c0.01,0.08-0.11,0.14-0.15,0.06c-0.19-0.47-0.29-0.85-0.13-1.35c0.05-0.14,0.13-0.26,0.22-0.38
      c-0.51-0.19-0.98-0.46-1.43-0.76c-0.21,0.08-0.4,0.19-0.55,0.4c-0.35,0.51-0.31,1.2-0.33,1.78c0,0.11-0.16,0.11-0.18,0.01
      c-0.18-0.7-0.19-1.52,0.17-2.16c0.1-0.17,0.23-0.31,0.38-0.41c-0.29-0.23-0.55-0.49-0.8-0.76c-0.25,0.05-0.49,0.12-0.7,0.34
      c-0.43,0.46-0.68,1.19-0.72,1.81c-0.01,0.09-0.13,0.09-0.14,0c-0.03-0.73-0.03-1.44,0.38-2.07c0.18-0.26,0.45-0.47,0.75-0.58
      c-0.32-0.41-0.59-0.85-0.83-1.31c-0.51-0.09-1.03-0.07-1.32,0.28c-0.07,0.09-0.21,0.01-0.15-0.1c0.24-0.42,0.6-0.66,1.08-0.7
      c0.04-0.01,0.09,0,0.13,0c-0.25-0.58-0.44-1.18-0.54-1.8c-0.66,0.46-1.32,0.92-1.99,1.34
      C175.98,282.16,175.67,281.86,175.86,281.62z"/>
    <path fill="#F5D0B2" d="M188.13,274.32c-2.77-2.24-6.3-0.61-7.93,2.06c-0.38,0.63-0.63,1.27-0.77,1.9
      c2.64-1.95,5.88-3.46,9.03-3.65C188.35,274.52,188.25,274.42,188.13,274.32z"/>
    <path fill="#FFFFFF" d="M190.92,279.82c0.07-1.49-0.42-3.22-1.52-4.13c-0.69,0.12-1.38,0.24-2.06,0.38
      c1.81,0.53,2.64,2.59,2.19,3.77C189.99,279.79,190.46,279.79,190.92,279.82z"/>
    <path fill="#FFFFFF" d="M187.88,285c-0.25-0.33-0.71-1.12-0.86-1.5c-2.67,1.5-6.64-1.41-5.37-5.16
      c-0.91,0.36-1.76,0.84-2.54,1.67C180.03,284.93,184.77,287.7,187.88,285z"/>
    <path d="M192.93,273.75c0.86,0.26,2.41,0.21,3.86,0.01c2.86-4.48,11.11-2.71,13,2.65c1.4,0.7,2.63,1.61,3.44,2.82
      c0.08,0.12-0.05,0.25-0.18,0.18c-0.88-0.54-1.81-1.04-2.78-1.51c0.28,1.2,0.32,2.4,0.11,3.48c0.21,0,0.43,0.06,0.64,0.15
      c0.56,0.27,0.95,0.73,1.1,1.33c0.02,0.09-0.11,0.14-0.15,0.06c-0.19-0.41-0.53-0.74-0.92-0.95c-0.26-0.14-0.51-0.14-0.77-0.09
      c-0.01,0.04-0.02,0.09-0.03,0.13c-0.22,0.7-0.54,1.3-0.91,1.83c0.68,0.39,1.15,1.43,1.09,2.16c-0.01,0.1-0.17,0.12-0.19,0.01
      c-0.11-0.75-0.55-1.45-1.22-1.75c-0.55,0.65-1.23,1.15-1.97,1.52c0.07,0.12,0.13,0.25,0.18,0.4c0.19,0.57,0.08,1.18-0.25,1.67
      c-0.06,0.09-0.24,0.03-0.21-0.08c0.14-0.63,0.09-1.25-0.23-1.76c-0.73,0.29-1.52,0.45-2.33,0.5c0.09,0.51-0.19,1.17-0.59,1.52
      c-0.09,0.08-0.21-0.04-0.15-0.14c0.22-0.38,0.3-0.91,0.17-1.37c-1.27,0-2.56-0.27-3.75-0.8c-4.21-1.88-5.79-6.78-4.19-10.77
      c-1.03-0.08-1.9-0.07-2.57-0.55C192.7,274.07,192.65,273.66,192.93,273.75z"/>
    <path fill="#F5D0B2" d="M198.21,273.76c3.89,0.42,8.26,1.31,9.98,1.96C206.71,272.23,200.36,270.93,198.21,273.76z"/>
    <path fill="#FFFFFF" d="M200.69,285c4.88,1.67,10.26-1.11,8.51-7.59c-0.87-0.59-2.21-1.01-3.2-1.15
      c1.91,1.53,2.02,3.96,1.06,5.44c-1.42,2.18-4.5,2.56-6.63,1.24c-1.82-1.13-2.86-2.96-2.57-5.01c0.21-1.46,1.06-2.57,2.26-2.91
      c-1.09-0.15-1.98-0.25-2.99-0.15C194.57,278.04,197.23,283.82,200.69,285z"/>
    <path d="M201.76,263.89c4.32-0.52,8.06,3.98,8.21,8.07c0.01,0.21-0.29,0.34-0.38,0.11c-1.31-3.49-4.55-5.98-7.9-7.38
      C201.33,264.55,201.32,263.94,201.76,263.89z"/>
    <path d="M180.88,267.64c1.15-1.85,3.31-2.83,5.08-1.17c0.24,0.22-0.04,0.62-0.35,0.59c-1.84-0.21-4.33,1.83-6.05,5.72
      c-0.03,0.08-0.25,0.01-0.25-0.07C179.32,271.72,180.25,268.66,180.88,267.64z"/>
    <path d="M169.76,275.04c3.55-3.75,4.26-8.45,5.7-13.21c0.95-3.15,2.28-6.34,4.23-9c1.9-2.59,4.32-4.65,7.61-4.98
      c4.09-0.42,7.26,3.1,10.56,4.87c0.29,0.16,0.13,0.62-0.2,0.49c-3.75-1.52-6.42-4.94-10.82-4.45c-3.92,0.44-6.56,4.41-8.23,7.58
      c-1.67,3.17-2.65,6.63-3.59,10.07c-0.86,3.17-2.01,7.17-5.16,8.77C169.77,275.23,169.69,275.11,169.76,275.04z"/>
    <path d="M213.05,316.17c3.61-3.89,5.95-7.64,5.97-13.07c0.02-4.2-0.47-8.32-0.23-12.53c0.53-9.3,1.4-18.69-3.53-27.04
      c-0.05-0.09,0.09-0.19,0.16-0.11c5.37,7.12,4.46,16.55,4.15,24.94c-0.2,5.52,0.82,10.95,0.65,16.43
      c-0.16,5.06-3.53,8.27-6.99,11.55C213.13,316.44,212.96,316.28,213.05,316.17z"/>
    <path d="M218.88,266.49c-0.03-0.09,0.13-0.15,0.17-0.06c2.55,5.96,2.47,12.31,2.84,18.66c0.17,3.06,0.21,6.12,0.47,9.17
      c0.31,3.59,1.64,7.32,0.66,10.9c-0.04,0.13-0.25,0.1-0.26-0.03c-0.19-3.08-0.76-6.04-1.16-9.09c-0.4-3.07-0.42-6.2-0.39-9.29
      C221.26,279.82,221.26,273.09,218.88,266.49z"/>
    <path d="M153.24,306.21c-0.06-0.2-0.11-0.4-0.14-0.59c-0.05-0.27,0.31-0.37,0.44-0.15c0.33,0.55,0.72,1.17,1.15,1.81
      c1.67,1.69,3.64,2.56,6.12,1.23c2.08-1.12,3.04-3.74,3.51-5.87c0.78-3.49,0.25-7.2-0.28-10.68c-1.1-7.24-4.37-15.61-0.59-22.54
      c0.06-0.11,0.21-0.01,0.16,0.1c-2.78,5.81-0.99,11.94,0.02,17.95c0.67,4.01,1.28,8.16,1.22,12.23c-0.05,3.25-0.7,7.45-3.7,9.41
      c-1.43,0.93-3.12,1.07-4.61,0.57c2.34,2.6,5.35,4.35,8.33,1.35c3.66-3.68,2.39-10.63,1.56-15.16c-0.02-0.11,0.15-0.15,0.17-0.04
      c1.1,5.22,2.54,11.88-1.52,16.18c-3.84,4.07-9.49-0.37-11.4-4.6c-0.18-0.29-0.34-0.6-0.47-0.94
      C153.17,306.35,153.2,306.27,153.24,306.21z"/>
    <path d="M235.24,262.83c4.47,9.31,0.88,19.59,1.83,29.41c0.39,4.01,1.67,7.2,4.4,10.12c1.17,1.25,2.55,2.4,4.15,3.05
      c1.64,0.66,2.33-0.07,3.05-1.03c0.39,0.15,0.79,0.21,1.19,0.21c-0.65,1.85-3.18,2.39-4.97,1.53c-2.9-1.39-5.39-4.11-6.94-6.9
      c-2.45-4.43-1.97-10.49-1.57-15.33c0.57-7.03,1.64-14.31-1.3-20.99C235.05,262.81,235.19,262.73,235.24,262.83z"/>
    <path d="M200.36,246.52c2.2,1.48,4.61,2.57,7.21,3.09c2.43,0.48,4.94,0.38,7.4,0.66c4.09,0.47,8.12,2.27,10.21,5.95
      c0.06,0.11-0.11,0.2-0.18,0.09c-2.75-4.6-7.87-5.44-12.8-5.5c-4.68-0.05-8.59-0.64-12.04-3.99
      C200.02,246.68,200.19,246.4,200.36,246.52z"/>
    <path d="M182.92,364.3c4.69-0.53,8.79-2.03,13.17-3.24c4.83-1.34,10.31-1.42,15.31-0.6c0.2,0.03,0.16,0.24-0.05,0.22
      c-5.83-0.48-11.6,0.23-16.94,1.88c-3.74,1.15-7.34,2.42-11.53,2.18C182.5,364.73,182.59,364.34,182.92,364.3z"/>
    <path fill="#FFFFFF" d="M270.75,393.99c0,0.57,0.46,1.03,1.03,1.03c0.57,0,1.03-0.46,1.03-1.03c0-0.57-0.46-1.03-1.03-1.03
      C271.21,392.96,270.75,393.42,270.75,393.99z"/>
    <path fill="#FFFFFF" d="M263.05,396.48c0,0.65,0.53,1.18,1.17,1.18c0.65,0,1.18-0.53,1.18-1.18s-0.53-1.17-1.18-1.17
      C263.58,395.31,263.05,395.83,263.05,396.48z"/>
    <path fill="#FFFFFF" d="M264.42,394.75c0,0.2,0.16,0.37,0.37,0.37s0.37-0.17,0.37-0.37c0-0.2-0.16-0.37-0.37-0.37
      S264.42,394.54,264.42,394.75z"/>
    <path fill="#FFFFFF" d="M272.1,392.63c0,0.15,0.12,0.28,0.28,0.28c0.16,0,0.28-0.13,0.28-0.28c0-0.15-0.12-0.28-0.28-0.28
      C272.22,392.35,272.1,392.48,272.1,392.63z"/>
    <path fill="#FFFFFF" d="M274.05,398.06c0.04,0.12,0.28,0.15,0.53,0.07c0.26-0.08,0.43-0.25,0.39-0.37
      c-0.04-0.12-0.28-0.15-0.54-0.07C274.19,397.78,274.01,397.94,274.05,398.06z"/>
    <path fill="#FFFFFF" d="M266.81,400.85c0.07,0.14,0.36,0.14,0.64-0.01c0.28-0.14,0.45-0.37,0.37-0.51
      c-0.07-0.14-0.35-0.14-0.63,0.01C266.9,400.48,266.74,400.71,266.81,400.85z"/>
    <path fill="#FFFFFF" d="M182.26,278.45c0,0.6,0.49,1.08,1.08,1.08c0.6,0,1.08-0.48,1.08-1.08c0-0.6-0.49-1.08-1.08-1.08
      C182.74,277.36,182.26,277.85,182.26,278.45z"/>
    <path fill="#FFFFFF" d="M184.34,279.57c0,0.31,0.25,0.56,0.56,0.56c0.31,0,0.56-0.25,0.56-0.56s-0.25-0.56-0.56-0.56
      C184.59,279.01,184.34,279.26,184.34,279.57z"/>
    <path fill="#FFFFFF" d="M199.3,277.09c0,0.76,0.62,1.37,1.37,1.37c0.76,0,1.38-0.61,1.38-1.37c0-0.76-0.62-1.37-1.38-1.37
      C199.91,275.72,199.3,276.33,199.3,277.09z"/>
    <path fill="#FFFFFF" d="M201.98,278.72c0,0.39,0.32,0.71,0.71,0.71c0.39,0,0.71-0.32,0.71-0.71c0-0.39-0.32-0.71-0.71-0.71
      C202.3,278.01,201.98,278.33,201.98,278.72z"/>
    <path fill="#FFE3CF" d="M180.92,306.92c-0.9,1.52-2.71,4-2.95,6.24c-0.24,2.24,1.88,1.06,2.95-0.12
      C181.97,311.86,182.45,304.33,180.92,306.92z"/>
    <path fill="#FFE3CF" d="M193.1,281.44c-1.09-0.22-3.22-0.36-3.92,0.87C187.87,284.61,194.19,281.66,193.1,281.44z"/>
    <path fill="#FFE3CF" d="M199.34,422.1c-1.07-0.55-3.35-0.62-4.36,0.48C193.78,423.9,200.72,422.8,199.34,422.1z"/>
    <path fill="#F396AF" d="M269.67,375.36c-1.1-0.61-4.63-1.91-9.59-0.5c-4.96,1.41-7.14,4.91-7.08,5.97
      c0.06,1.06,23.3,2.37,21.64-0.77C273.64,378.18,271.62,376.45,269.67,375.36z"/>
    <g>
      <path d="M198.92,303.7c-4.42,1.05-8.38-0.87-10.92-3.52c1.47-1.97-0.03-3.4-1.49-1.74c-0.9-1.3-1.31-2.51-1.33-3.66
        c6.73,2.19,17.42-1.18,20.35-4.84C207.66,294.07,205.13,302.23,198.92,303.7z"/>
      <path fill="#FFFFFF" d="M190.02,299.29c3.59,0.52,7.15-0.91,8.84-1.67c2.67-1.19,5.05-3.01,6.6-5.52
        c-0.05-0.3-0.12-0.59-0.21-0.86c-2.39,2.02-5.15,3.22-8.19,4.01c-2.4,0.62-5.81,1.1-8.72,0.78
        C188.63,297.17,189.23,298.47,190.02,299.29z"/>
      <path fill="#F2A6AB" d="M197.82,300.48c1.65-0.74,5.02-1.88,6.41-2.25c0.25-0.47,0.47-0.95,0.65-1.44
        C201.86,295.61,198.41,298.43,197.82,300.48z"/>
      <path fill="#FFFFFF" d="M195.53,301.85c-0.17,0.35-0.16,0.96-0.13,1.27c1.03,0.12,2.11,0.09,3.19-0.17
        c2.58-0.62,4.02-2.02,5.25-4.04C201.61,299.69,196.33,301.64,195.53,301.85z"/>
      <path d="M207.4,290.79c-0.77-1.25-3.16-1.62-4.18-0.52c-0.05,0.05,0.01,0.16,0.08,0.14c1.46-0.33,2.62-0.11,3.94,0.58
        C207.36,291.06,207.46,290.9,207.4,290.79z"/>
    </g>
  </g>
  <g id="item_1_">
    <path d="M701.99,281.04c-0.02-0.36-0.39-0.51-0.72-0.52c-0.15-0.59-0.74-0.86-1.64-0.65c-0.28,0.06-0.51,0.2-0.71,0.39
      c-1.55-0.86-2.79,2.08-2.77,3.39c-1.24,0.33-2.51,0.75-3.67,1.35c-1.12-1.18-2.6-2.29-4.03-3.09c0.55-1.55,1.14-3.58,0.5-5.84
      c0.46,0,1.3,0.04,1.76,0.03c0.33-0.01-0.32-8.62-0.92-12.43c-0.58-3.69-1.36-7.19-2.8-10.63c-0.59-1.41-1.8-4.37-2.92-8.12
      c-1.12-3.76-2.91-9.1-7.08-10.9c0.23-0.83-0.91-1.14-1.23-0.36c-4.46-2.54-8.87-5.19-13.28-7.81c5.38-4.4,10.91-9.63,12.71-16.42
      c2.31-0.02,4.43-1.42,5.09-3.95c0.42-1.58,0.19-3.76-1.7-4.67c-0.16-0.08-0.32-0.14-0.49-0.2c0.95-4.56,0.33-13.51-6.25-17.62
      c-0.03-5.97-14.36-8.51-20.74-8.68c-6.14-0.17-10.02,2.54-11.64,5.25c-0.2,0.33,2.82,1.34,5.19,1.4c-5.54,1.47-7.1,4.62-6.55,4.62
      c3.13,0,10.48,0.52,11.02,0.56c-5.5,6.19-11.27,11.9-15.26,19.18c-2.37,1.14-5.71,2.87-8.54,4.66c-2.02,1.28-4.07,2.53-5.7,4.31
      c-1.21,1.32-2.33,3.05-2.16,4.92c0.14,1.53,1.17,2.95,2.5,3.67c1.64,0.89,3.61,0.5,5.24-0.17c2.15-0.88,4.09-2.21,5.85-3.78
      c4.14,5.53,9.96,7.76,16.39,9.35c0.37,0.93,1.12,1.71,2.46,1.98c1.02,0.2,2.56,0.1,3.25-0.97c2.06,0.32,6.64,1.43,9.76,2.67
      c1.7,0.68,4.18,1.81,5.85,2.73c-0.4,0.56-0.97,1.43-1.09,2.98c-0.22,2.98-0.42,6.85-0.68,9.98c-1.47,2.93-4.86,7.13-7.95,9.7
      c-3.09,2.57-8.43,6.29-12.4,7.34c-0.02-0.58-0.07-0.89-0.42-0.95c-0.18-0.03-0.31,0.03-0.4,0.12c-0.68-0.85-1.47-1.6-2.44-2.14
      c-0.68-0.37-1.44-0.62-2.2-0.77c-0.43-0.09-1.7,0.04-2.63-0.01c-0.93-0.05-1.33-0.35-1.72-0.79c-0.39-0.44-1.29-2.18-1.56-3.18
      c-0.13-0.48-0.92-0.39-1.36,0.07c-1.14,1.2-1.34,3.29-0.18,5.28c-1.95-0.54-4.8-0.76-7.22-0.55c-2.02,0.17-3.48,0.82-3.35,1.93
      c0.07,0.64,0.29,1.05,0.81,1.26c-0.8,0.27-2.24,0.65-2.23,2.05c0.01,1.41,2.63,1.78,5.21,1.56c-1.54,0.74-3.18,1.83-2.76,3
      c0.42,1.17,2.26,1.21,4.05,0.68c1.33-0.39,2.41-0.81,3.68-1.34c-0.2,0.26-5.44,2.52-4.53,4.25c0.74,1.38,5.41,0.04,6.41-2.54
      c2.33,0.27,4.64,0.35,6.92,0.01c1.92-0.28,4.58-0.92,5.68-2.66c0.3,0.27,0.87,0.17,0.83-0.3c0-0.01,0-0.01,0-0.02
      c2.5-0.5,7.55-2.85,11.06-5.23c1.75-1.18,5.01-4.07,7.22-6.45c-0.14,1.31-0.27,2.25-0.36,2.66c-0.45,2.07-3.87,10.12-5,15.25
      c-0.28,1.25-0.45,2.85-0.51,4.13c-0.01,0.31,1.77,0.2,2.37,0.16c-2.9,2.37-5.84,4.69-8.52,7.32c-1.71,1.68-3.39,3.51-4.58,5.61
      c-0.52,0.91-1.02,1.96-1.04,3.03c-0.03,1.23,0.68,2.33,1.01,3.48c0.08,0.27,0.13,0.54,0.17,0.82c0.33,2.51,0.42,5.1,0.58,7.62
      c0.17,2.78,0.24,5.57-0.02,8.34c-0.03,0.35-0.07,0.7-0.12,1.05c0-0.02,0.01-0.05,0.01-0.07c0,0.03-0.01,0.06-0.01,0.09
      c0,0.02-0.01,0.04-0.01,0.06c0-0.02,0-0.03,0.01-0.05c-0.23,1.64-0.6,3.27-1.24,4.81c-0.38,0.87-0.85,1.71-1.42,2.47
      c-0.1,0.14,0.34,0.3,1.12,0.47c-5.61,1.3-10.09,2.88-15.32,5.72c-0.81,0.44-1.62,0.9-2.41,1.37c-0.49,0.29,0.56,1.33,1.9,1.39
      c6.05,0.3,15.96-1.41,20.41-4.08c0.05,0.67,0.24,2.34,0.37,2.78c2.87,0.36,5.1-0.42,7.39-2.35c0.27-0.23-0.6-2.94-1.58-4.28
      c0,0,0.01,0,0.01,0c0.27-0.05,0.39-0.31,0.42-0.55c0.43-3.57,0.48-7.18,0.06-10.76c-0.36-3.05-1.32-6.49-2.19-9.47
      c-0.76-2.59-2.63-7.43-2.21-7.99c0.41-0.56,10.35-8.14,14.18-9.51c-2.66,3.88-5.57,7.6-7.81,11.74c-0.24,0.45-1.45,1.69-2.17,2.08
      c-0.83,0.45-0.79,4.65,0.19,5.25c2,1.23,2.33,2.62,2.73,5.35c0.6,4.03,0.17,10.24-0.57,16.38c-0.17,1.44-0.45,2.87-0.55,4.32
      c-0.01,0.19,0.08,0.34,0.21,0.43c-3.67,0.9-10.69,1.75-14.55,2.87c-3.36,0.97-6.38,3.31-6.64,3.5c-0.27,0.2,0.5,1.28,1.1,1.51
      c4.38,1.67,10.55,1.98,11.73,1.95c1.65-0.05,11.24-1.17,13.14-2.19c0.92-0.5,3.35-2.02,4.03-2.87c0.05,0.76,0.21,2.38,0.21,3.35
      c3.26,0.36,8.42-1.31,9.98-2.82c-1.25-2.19-2.7-4.41-4.55-6.16c0.42-0.23,0.71-0.53,0.75-0.93c0.46-5.13-6.26-22.69-9.08-26.87
      c4.44-3.42,13.77-10.33,17.3-15.55c0.76,0.69,1.63,1.3,2.58,1.82c2.15,1.16,6.66,1.89,7.19,1.79c0.53-0.1,6.61-5.9,6.91-6.35
      C701.43,285.64,702.08,282.56,701.99,281.04z"/>
    <path fill="#FAD1B0" d="M645.6,269.65c-0.49,0.57-1.16,0.95-1.53,1.14c-0.85,0.4-1.76,0.65-2.68,0.82
      c0.01,0,0.02,0,0.03-0.01c-0.01,0-0.02,0-0.03,0.01c-0.03,0.01-0.06,0.01-0.09,0.02c0.03-0.01,0.06-0.01,0.08-0.02
      c-2.4,0.44-5.7,0.53-7.56,0.28c-0.15-0.48-0.39-0.5-0.39-0.35c0,0.15-0.39,1.54-1.69,2.41c-1.3,0.87-2.96,1.45-3.48,0.94
      c-1.02-1.01,3.45-2.75,4.5-3.97c0.46-0.54,0.59-1.29,0.24-1.16c-2.13,0.82-6.74,2.33-7.58,1.68c-1.08-0.85,2.94-3.25,6.81-3.59
      c0.57-0.05-0.3-0.75-0.81-0.68c-0.51,0.07-2.52,0.35-3.18,0.42c-2.34,0.26-5.33,0.08-5.54-0.68c-0.21-0.76,1.08-1.33,2.55-1.54
      c1.89-0.26,4.61-0.32,6.58-0.42c0.58-0.03-0.31-0.57-0.81-0.6c-0.85-0.04-4.06,0.04-5.18,0.1c-1.6,0.08-2.27-0.67-1.81-1.32
      c0.45-0.63,2.45-0.86,4.25-0.82c1.8,0.05,4.72,0.67,6.04,0.94c0.62,0.67,1.55,1.31,2.43,1.52c-0.66,2.38-0.7,5.49,2.14,5.39
      c0.63-0.02,1.18-0.8,0.58-0.7c-0.23,0.04-0.95,0.17-1.46-0.24c-1.2-0.95-0.62-3.77-0.48-4.29c0.44,0.07,1.26,0.06,1.6,0.04
      c0.14,0.43,0.96,1.32,0.89,0.72c-0.02-0.22-0.21-1.66-0.49-1.66c-0.64,0.01-2.51,0.09-3.72-0.61c-0.72-0.42-1.48-1.43-1.79-2.3
      c-0.31-0.87-0.37-1.89-0.19-2.72c0.18-0.82,0.91-1.42,1.02-0.89c0.12,0.52,0.74,2.07,1.16,2.73c0.42,0.65,1.17,1.1,1.67,1.23
      c0.54,0.14,1.87-0.03,2.63-0.04c0.75-0.01,1.81,0.04,3.26,1.12c1.18,0.87,1.74,1.6,2.08,2.17c0.06,0.9,0.22,2.77,0.45,4.16
      C646.01,269.11,645.84,269.37,645.6,269.65z"/>
    <path fill="#DBB08F" d="M641.04,269.92c-0.09-1.52-0.3-3.46-1.09-4.88c0.06,0.3,0.09,0.58,0.1,0.67
      c0.06,0.6-0.75-0.29-0.89-0.72c-0.34,0.01-1.16,0.03-1.6-0.04c-0.15,0.52-0.73,3.34,0.48,4.29c0.51,0.4,1.23,0.27,1.46,0.24
      c0.59-0.09,0.05,0.68-0.58,0.7c-2.85,0.11-2.81-3.01-2.14-5.39c-0.88-0.22-1.81-0.85-2.43-1.52c-1.32-0.27-4.24-0.89-6.04-0.94
      c-1.21-0.03-2.5,0.06-3.36,0.32c0.16,0.6,0.31,1.2,0.45,1.81c0.15,0.01,0.3,0.01,0.47,0c1.12-0.06,4.34-0.14,5.18-0.1
      c0.5,0.02,1.39,0.57,0.81,0.6c-1.86,0.09-4.39,0.15-6.26,0.38c0.18,0.79,0.37,1.58,0.58,2.37c0.7,0,1.42-0.03,2.09-0.11
      c0.66-0.07,2.67-0.35,3.18-0.42c0.51-0.07,1.38,0.63,0.81,0.68c-2.08,0.18-4.19,0.95-5.54,1.76c0.17,0.63,0.34,1.25,0.51,1.87
      c1.77-0.3,4.35-1.17,5.8-1.73c0.35-0.14,0.23,0.62-0.24,1.16c-0.86,0.99-3.95,2.32-4.55,3.33c0.09,0.23,0.18,0.47,0.27,0.7
      c0.01,0.03,0.02,0.05,0.03,0.08c0.69,0.21,2.09-0.33,3.23-1.08c1.3-0.87,1.69-2.25,1.69-2.41c0-0.15,0.24-0.14,0.39,0.35
      c1.77,0.24,4.83,0.17,7.19-0.22C641.07,271.07,641.07,270.48,641.04,269.92z"/>
    <path fill="#87CBBE" d="M686.8,254.73c1.02,2.37,3.06,14.34,2.98,20.14c-0.99,0.01-1.98-0.01-2.97-0.02
      c-0.24-4.95-2.37-21.21-2.59-23.01c-0.13-1.07-0.43-4.12-0.54-5.2C684.46,248.76,685.78,252.36,686.8,254.73z"/>
    <path fill="#5EACA1" d="M687.84,271.62c-0.23-2.6-0.67-5.19-0.97-7.78c-0.29-2.52-0.57-5.01-0.64-7.54
      c-0.03-1.08-0.05-2.17-0.13-3.24c-0.87-2.18-1.81-4.75-2.43-6.42c0.11,1.07,0.41,4.13,0.54,5.2c0.22,1.8,2.34,18.06,2.59,23.01
      c0.4,0.01,0.8,0.01,1.2,0.02C687.97,273.77,687.93,272.7,687.84,271.62z"/>
    <path fill="#8B979B" d="M686.9,280.05c0.02-1.33,0-2.67-0.05-4c0.39,0.01,0.78,0.01,1.18,0.01
      c0.28,1.83,0.07,3.99-0.54,5.34c-0.16-0.08-0.32-0.16-0.48-0.23C687.6,281.09,687.51,280.04,686.9,280.05z"/>
    <path fill="#DAAD58" d="M647.31,184.86c-2.38-0.17-5.26-0.3-7.63-0.37c1.2-2.52,7.15-3.42,9.05-4.44
      c0.51-0.28-1.54,0.06-2.66,0.09c-1.12,0.03-4.06-0.45-5.37-1.05c0.81-1.17,2.39-2.66,4.65-3.47c2.26-0.81,5.65-0.96,8.22-0.62
      c12.75,1.67,17.82,4.73,17.54,8.3c4.6,2.48,7.87,9.6,6.02,16.79c-1.94-0.34-4.01-0.16-5.83,0.61c1.47-3.27,1.97-7.69,2.32-11.23
      c0.03-0.26-0.59,0.14-0.81,0.73c-1.26-1.89-3.15-4.33-5.28-5.19c0.32-0.09,1.84-1.52,1.14-1.29c-1.73,0.58-3.51,0.97-5.32,1.22
      C658.02,185.61,652.67,185.23,647.31,184.86z"/>
    <path fill="#BC883C" d="M673.24,189.56c-0.41-1.17-1.04-2.26-1.78-3.26c-0.69-0.92-1.58-1.97-2.68-2.42
      c-0.25,0.35-1.02,1.04-1.24,1.11c2.12,0.86,4.02,3.3,5.28,5.19C672.91,189.92,673.08,189.71,673.24,189.56z"/>
    <path fill="#BC883C" d="M666.41,184.29c-1.18,0.11-2.35,0.18-3.53,0.15c-2.39-0.07-4.76-0.27-7.16-0.2
      c-2.56,0.07-5.17,0.09-7.72,0.36c-0.61,0.07-1.21,0.12-1.82,0.18c0.38,0.02,0.76,0.05,1.13,0.07c5.35,0.37,10.7,0.75,16.04,0.06
      c1.13-0.16,2.25-0.37,3.35-0.65C666.61,184.28,666.51,184.29,666.41,184.29z"/>
    <path fill="#E9CD7E" d="M654.49,175.95c4.82,0.77,8.17,1.7,11.26,2.81c1.97,0.71,4.35,1.87,5.32,3.7
      c-0.52-3.21-5.74-5.93-17.5-7.47c-2.57-0.34-5.96-0.19-8.22,0.62c-2.26,0.81-3.84,2.3-4.65,3.47c0.3,0.14,0.69,0.27,1.12,0.39
      C644.36,175.49,650.32,175.28,654.49,175.95z"/>
    <path fill="#E9CD7E" d="M674.65,187.74c0.96,1.79,1.65,3.85,1.99,5.84c0.27,1.63,0.63,3.84,0.63,5.89
      c1.45-6.63-1.38-13.08-5.5-15.78C673.11,185.12,673.81,186.16,674.65,187.74z"/>
    <path fill="#E9CD7E" d="M646.38,181.71c0.8-0.29,1.67-0.78,2.09-1.54c-2.13,0.97-7.64,1.9-8.79,4.32
      c0.61,0.02,1.25,0.04,1.91,0.07C641.66,183.47,644.16,182.5,646.38,181.71z"/>
    <path fill="#E9CD7E" d="M650.08,180.17c1.71-0.43,3.44-0.76,5.21-0.4c1.18,0.24,2.27,0.66,3.26,1.29
      C656.7,178.62,652.13,177.84,650.08,180.17z"/>
    <path fill="#FAD1B0" d="M656.68,229.2c-1.11-0.23-1.98-0.43-3.1-0.65c0.22-0.45,0.24-1,0.23-1.45c0-0.51-0.9-0.6-0.91-0.08
      c0,0.14-0.01,0.28-0.03,0.43c-0.04,0.24-0.1,0.47-0.18,0.7c-0.07,0.16-0.16,0.31-0.26,0.45c-0.1,0.12-0.21,0.23-0.33,0.33
      c-0.15,0.1-0.3,0.19-0.46,0.27c-0.18,0.07-0.36,0.11-0.56,0.15c-0.23,0.02-0.46,0.02-0.69,0c-0.26-0.04-0.51-0.11-0.75-0.21
      c-0.21-0.1-0.41-0.22-0.61-0.35c-0.16-0.13-0.3-0.27-0.43-0.43c-0.12-0.16-0.21-0.33-0.29-0.5c-0.06-0.17-0.11-0.34-0.14-0.52
      c-0.02-0.23-0.03-0.45,0-0.68c0.04-0.23,0.09-0.44,0.16-0.66c0.15-0.47-0.7-0.68-0.92-0.22c-0.22,0.47-0.23,0.95-0.19,1.42
      c-2.17-0.54-4.14-1.13-6.21-2c-1.86-0.8-3.63-1.79-5.24-3.02c-1.3-1.03-2.46-2.19-3.48-3.49c-0.13-0.16-0.24-0.33-0.36-0.5
      c0.34-0.33,0.68-0.68,1.01-1.03c1.13-1.21,1.12-2.9,0.64-2.34c-1.37,1.6-2.93,3.05-4.59,4.34c-1.27,0.97-2.62,1.86-4.09,2.5
      c-0.65,0.27-1.32,0.48-2.02,0.59c-0.4,0.05-0.8,0.06-1.2,0.02c-0.32-0.05-0.62-0.13-0.91-0.24c-0.25-0.12-0.48-0.25-0.71-0.42
      c-0.26-0.21-0.49-0.45-0.7-0.71c-0.2-0.27-0.37-0.55-0.51-0.85c-0.1-0.26-0.17-0.52-0.22-0.79c-0.03-0.27-0.03-0.54,0-0.81
      c0.06-0.37,0.17-0.73,0.31-1.08c0.25-0.55,0.57-1.05,0.92-1.53c0.91-1.15,2.01-2.14,3.17-3.04c4.12-3.16,8.83-5.23,13.42-7.59
      c0.5-0.26-0.22-0.51-1.27-0.21c1.22-2.05,2.59-4.02,4.04-5.91c3.47-4.48,7.37-8.59,11.11-12.84c5.38,0.25,11.41,0.13,16.31-0.84
      c2.4,0.92,4.63,3.63,5.99,5.98c-0.04,3.34-1.61,8.36-2.56,10.02c-0.2,0.34,0.18,0.74,0.55,0.55c0.92-0.49,2.16-0.82,3.39-1
      c-1.23,0.74-1.3,1.86-0.92,1.53c1.07-0.92,2.58-1.37,4.19-1c1.62,0.36,2.24,1.42,2.15,2.73c-0.12,1.93-1.15,3.07-2.6,3.63
      c-1.45,0.56-3.18,0.33-4-0.01c-0.67-0.28-0.1,1.24,1.21,1.53c-0.2,0.7-0.43,1.38-0.71,2.06c-0.82,1.88-1.9,3.61-3.15,5.23
      c-3.05,3.9-6.85,7.12-10.71,10.2c-0.58,0.46,1.45,0.42,2.41-0.43c4.58,2.61,9.13,5.15,13.65,8.02c-0.4,0.93-1.3,1.63-2.71,1.88
      C667.29,232.21,660.19,229.93,656.68,229.2z"/>
    <path fill="#FAE3CF" d="M634.37,206.23c-3.91,1.95-7.84,3.86-11.34,6.54c-1.15,0.9-2.26,1.88-3.17,3.04
      c-0.36,0.48-0.68,0.99-0.92,1.53c-0.14,0.35-0.25,0.71-0.31,1.08c-0.03,0.27-0.03,0.54,0,0.81c0.04,0.27,0.12,0.54,0.22,0.79
      c0.14,0.3,0.31,0.58,0.51,0.85c0.05,0.07,0.11,0.13,0.17,0.19C619.51,218.15,622.46,213.94,634.37,206.23z"/>
    <path fill="#FAE3CF" d="M649.13,187.58c-1.92,2.16-3.88,4.3-5.78,6.49C645.01,193.91,648.49,190.45,649.13,187.58z"/>
    <path fill="#FAE3CF" d="M677.35,202.7c0.92,0.64,1.53,1.59,1.81,2.66c0.01,0.03,0.02,0.07,0.02,0.1
      c0.13-0.38,0.21-0.8,0.24-1.27c0.08-1.31-0.54-2.37-2.15-2.73c-1.27-0.28-2.46-0.07-3.43,0.47
      C675.06,201.92,676.34,202,677.35,202.7z"/>
    <path fill="#FAE3CF" d="M674.05,233.49c-4.03-2.5-8.09-4.77-12.16-7.09C663.32,228.74,670.71,232.61,674.05,233.49z"/>
    <path fill="#DBB08F" d="M675.65,203.05c-0.49,0.03-0.95,0.19-1.35,0.46c0.8,0.5,1.22,1.81,0.69,2.58
      c0.96-0.14,1.99-0.71,2.17-1.51C677.35,203.67,676.49,202.99,675.65,203.05z"/>
    <path fill="#DBB08F" d="M651.86,215.62c-0.26,0.02-0.53,0.01-0.8-0.03c0.05,0.13-0.01,0.3-0.18,0.3
      c-0.61-0.01-1.18-0.15-1.75-0.36c0.38,1.43,1.41,3.49,3.13,2.39c1.16-0.74,1.33-2.23,1.5-3.55
      C653.26,214.97,652.64,215.55,651.86,215.62z"/>
    <path fill="#DBB08F" d="M645.04,197.59c0.7-0.06,0.92-0.72,0.5-1.26c-0.62-0.8-1.63-1.29-2.73-1.63
      c-0.5,0.58-0.98,1.15-1.47,1.74c0.6,0.27,1.19,0.55,1.81,0.76C643.74,197.39,644.41,197.64,645.04,197.59z"/>
    <path fill="#DBB08F" d="M647.3,226.01c-0.69,0.24-1.38,0.45-2.09,0.63c0.65,0.19,1.32,0.36,2,0.53
      C647.18,226.79,647.18,226.4,647.3,226.01z"/>
    <path fill="#DBB08F" d="M653.81,227.2c0,0.43-0.03,0.94-0.23,1.35c1.12,0.22,1.99,0.42,3.1,0.65
      c1.91,0.39,4.89,1.25,8.1,2.61C661.62,228.86,655.12,227.49,653.81,227.2z"/>
    <path fill="#DBB08F" d="M651.59,205.92c-0.91-0.29-1.65-0.01-2.4,0.26c1.65,0.07,3.17,0.59,4.31,1.7
      C653.15,206.99,652.58,206.23,651.59,205.92z"/>
    <path fill="#DBB08F" d="M634.12,220.71c0.19-0.57,0.3-1.16,0.27-1.78c-0.05-1.05-0.34-2.03-0.71-3
      c-0.15,0.39-0.39,0.83-0.76,1.22c-0.33,0.35-0.66,0.69-1.01,1.03c0.12,0.17,0.24,0.34,0.36,0.5
      C632.85,219.39,633.46,220.07,634.12,220.71z"/>
    <path fill="#DBB08F" d="M670.62,201.93c-0.06,0.03-0.11,0.04-0.17,0.05c-0.08,0.19-0.17,0.39-0.25,0.58
      c0.87-0.41,1.86-0.62,2.82-0.62c0.16-0.31,0.47-0.69,0.99-1C672.78,201.11,671.54,201.43,670.62,201.93z"/>
    <path fill="#DBB08F" d="M657.32,188.1c3.57-0.16,7.13-0.89,10.48-2.13c-0.38-0.24-0.77-0.44-1.16-0.59
      c-4.9,0.98-10.93,1.1-16.31,0.84c-0.35,0.4-0.7,0.79-1.05,1.18C649.21,187.5,655.2,188.2,657.32,188.1z"/>
    <path fill="#87CBBE" d="M657.48,263.7c-1.91,1.39-6.74,4.07-10.32,5.03c-0.1-0.7-0.23-1.4-0.34-2.1
      c-0.04-0.28-0.11-0.92-0.13-1.23c4.26-1.3,8.2-3.76,11.65-6.53c3.08-2.48,6.58-6.16,8.46-9.71c-0.19,2.22-0.64,5.23-0.81,6.94
      C663.54,258.8,659.29,262.39,657.48,263.7z"/>
    <path fill="#B0D9CC" d="M654.11,262.91c2.51-1.52,4.63-3.37,6.84-5.3c2.68-2.36,3.63-4.71,3.98-5.53
      c-1.9,2.55-4.35,4.99-6.59,6.79c-3.45,2.77-7.38,5.23-11.65,6.53c0.01,0.18,0.04,0.48,0.07,0.76
      C649.36,265.8,651.88,264.26,654.11,262.91z"/>
    <path fill="#5EACA1" d="M665.28,251.59c-0.2,2.13-0.56,4.25-1.07,6.33c0.65-0.63,1.25-1.25,1.78-1.82
      c0.17-1.7,0.62-4.72,0.81-6.94C666.37,249.97,665.85,250.79,665.28,251.59z"/>
    <path fill="#FFFFFF" d="M670.82,239.29c0.06,0.9,0.3,2.01,0.65,2.39c0.4-1.4,0.7-2.83,1.03-4.25
      c-0.49,0.01-0.98-0.05-1.46-0.18C670.85,237.82,670.77,238.68,670.82,239.29z"/>
    <path fill="#87CBBE" d="M684.01,259.08c0,0.01,0,0.02,0,0.03c-0.03-0.23-0.06-0.47-0.1-0.71c-0.01-0.03-0.01-0.06-0.01-0.1
      c-0.01-0.07-0.02-0.14-0.03-0.21c-0.62-4.58-0.81-11.97-1.24-14.44c-0.43-2.47-2.54-7.78-5.85-9.1c-0.08,0.18-0.16,0.36-0.26,0.53
      c1.08,1.98,1.13,4.76-0.42,6.15c-0.12,0.1-0.28,0.05-0.35-0.09c-0.44-0.87-1.82-3.35-2.23-3.85c-0.2,0.05-0.4,0.08-0.6,0.11
      c-0.38,1.67-0.72,3.34-1.23,4.98c-0.05,0.16-0.27,0.21-0.38,0.05c-0.57-0.78-0.73-1.58-0.87-2.6c-0.12-0.82-0.05-1.91,0.19-2.76
      c-0.41-0.32-0.24-0.96,0.39-0.89c-0.34-0.3-1.05-0.71-1.65-1.08c-0.54,0.7-0.85,1.64-0.9,2.66c-0.38,8.07-1.41,19.42-2.21,23.09
      c-0.93,4.23-4.39,10.67-5.3,17.12c-0.05,0.37-0.09,0.75-0.12,1.13c1.26-0.05,2.52-0.17,3.78-0.34c4.93-0.68,9.78-1.84,14.72-2.5
      c-0.05,0.01-0.1,0.01-0.15,0.02c0.11-0.02,0.22-0.03,0.34-0.05c0.29-7.09,0.54-14.29,0-21.37c-0.34-4.2-1.01-11.03-1.11-12.59
      c-0.1-1.56,1.16,1.85,1.24,2.94c0.37,4.58,1.06,9.11,1.18,13.71c0.17,6.76,0.01,13.52-0.31,20.27c0.22,0.11,0.45,0.21,0.68,0.29
      c1.03,0.36,2.09,0.54,3.16,0.61c0.46,0.02,0.92,0.03,1.39,0.01C685.86,273.07,684.95,266.06,684.01,259.08z"/>
    <path fill="#5EACA1" d="M670.49,237.81c0.04-0.25,0.09-0.5,0.16-0.73c-0.41-0.32-0.24-0.96,0.39-0.89
      c-0.34-0.3-1.05-0.71-1.65-1.08c-0.31,0.39-0.53,0.86-0.68,1.38C669.32,236.89,669.92,237.32,670.49,237.81z"/>
    <path fill="#5EACA1" d="M676.82,243.02c0.82-0.82,1.29-3.25,1.01-4.9c-0.14-0.82-0.38-2.18-1.13-2.68
      c0.91,1.95,0.87,4.49-0.59,5.8c-0.08,0.08-0.19,0.07-0.27,0C676.14,241.85,676.45,242.45,676.82,243.02z"/>
    <path fill="#5EACA1" d="M672.1,243.8c0.57-1.77,1.59-5.04,1.99-5.66c-0.24-0.39-0.44-0.71-0.55-0.85
      c-0.2,0.05-0.4,0.08-0.6,0.11c-0.38,1.67-0.72,3.34-1.23,4.98c-0.05,0.16-0.27,0.2-0.38,0.05
      C671.43,242.81,671.87,243.48,672.1,243.8z"/>
    <path fill="#5EACA1" d="M679.24,251.51c-0.05,0.75-0.1,1.49-0.13,2.24c-0.14,3.55-0.06,7.11-0.1,10.66
      c-0.04,3.44-0.56,6.68-1.14,10.05c-0.12,0.67-0.22,1.38-0.35,2.07c0.61-0.1,1.22-0.18,1.82-0.27c-0.05,0.01-0.1,0.01-0.15,0.02
      c0.11-0.02,0.22-0.03,0.34-0.05c0.29-7.09,0.54-14.29,0-21.37C679.45,253.86,679.35,252.71,679.24,251.51z"/>
    <path fill="#FFFFFF" d="M676.05,240.69c1.07-1.28,0.87-3.71,0.21-5.18c-0.59,0.8-1.4,1.36-2.3,1.66
      C674.38,237.74,675.82,240.07,676.05,240.69z"/>
    <path fill="#A69283" d="M659.11,329.29c-1.34,1.34-3.62,1.94-5.26,1.84c-0.17-0.74-0.34-1.4-0.27-2.21
      c0.44-0.4,0.57-1.6,0.13-1.34c-1.26,0.75-3.37,1.58-4.72,2.15c-2.52,1.04-5.16,1.71-7.86,2.08c-2.81,0.37-6.21,0.56-9.04,0.55
      c2.68-1.76,5.89-3.29,7.74-3.98c1.16-0.43,3.63-1.23,4.09-1.34c1.05-0.24,2.22-0.11,2.23,1.27c-0.24,0.35-0.09,0.98,0.47,0.98
      c0.74,0,0.78-1.08,0.12-1.2c-0.17-0.71-0.49-1.14-1.36-1.42c0.21-0.06,0.9-0.21,1.44-0.17c0.54,0.04,1.43,0.26,1.61,1.18
      c-0.17,0.35-0.01,0.87,0.5,0.87c0.75,0,0.66-1.25-0.03-1.17c-0.21-0.61-0.75-1.01-1.48-1.16c0.9-0.31,2.16-0.36,2.71,0.78
      c-0.44,0.29-0.17,0.93,0.32,0.93c0.7,0,0.73-1.02,0.1-1.12c-0.19-0.53-0.64-1.01-1.29-1.14c0.16-0.04,0.34-0.09,0.53-0.14
      c2.3,0.32,5.48,0.54,7.68,0.39C658.26,326.86,658.65,327.93,659.11,329.29z"/>
    <path fill="#BBAB99" d="M644.96,326.96c-0.33-0.03-0.69,0-1.04,0.08c-0.46,0.11-2.93,0.91-4.09,1.34
      c-1.85,0.69-5.06,2.22-7.74,3.98c0.92,0,1.89-0.02,2.89-0.06C636.27,330.72,640.31,328.67,644.96,326.96z"/>
    <path fill="#A69283" d="M678.52,337.72c-1.51,1.02-4.48,2.29-7.52,2.25c0.11-1.29-0.38-4.54-0.56-4.29
      c-1.15,1.62-3.27,3.09-5.08,3.93c-1.81,0.84-10.47,1.79-13.02,1.82c-3.98,0.03-7.99-0.74-10.71-1.85c0.27-0.67,2.81-2.04,4.91-2.68
      c2.73-0.83,9.54-2.13,10.17-2.19c0.63-0.06,1.25,0.08,1.57,0.66c0.32,0.58,0.36,1.26,0.36,1.51c-0.58,0.29-0.47,1.36,0.33,1.36
      c0.79,0,0.91-1.04,0.35-1.35c0-1.02-0.38-1.88-1.11-2.38c1.29-0.29,2.01-0.22,2.6,0.28c0.59,0.5,0.75,1.1,0.79,1.77
      c-0.54,0.35-0.41,1.39,0.4,1.39c0.89,0,0.96-1.26,0.22-1.47c-0.17-0.95-0.21-1.86-1.79-2.49c0.23-0.05,0.79-0.21,1-0.24
      c0.21-0.03,1.18,0.15,1.79,0.58c0.61,0.42,0.95,0.71,1.08,1.14c-0.36,0.42-0.18,1.26,0.55,1.26c0.95,0,0.9-1.38-0.02-1.43
      c-0.28-0.92-1.38-1.66-2.66-1.85c0.12-0.04,0.28-0.08,0.45-0.13c3.11,0.27,6.4,0.1,9.44-0.34c0.6-0.09,1.47-0.18,2.26-0.37
      C676.05,334.33,678.32,337.4,678.52,337.72z"/>
    <path fill="#BBAB99" d="M658.87,335.18c0.52-0.18,1.01-0.4,1.48-0.69c-0.51-0.22-1.16-0.2-2.13,0.02
      C658.48,334.68,658.7,334.91,658.87,335.18z"/>
    <path fill="#BBAB99" d="M649.6,337.16c1.86-0.42,8.2-1.66,8.69-1.8c0,0,0,0,0,0c-0.32-0.58-0.94-0.72-1.57-0.66
      c-0.63,0.06-7.44,1.36-10.17,2.19c-2.1,0.64-4.65,2.01-4.91,2.68c0.75,0.3,1.59,0.58,2.51,0.83
      C644.14,338.62,646.22,337.92,649.6,337.16z"/>
    <path fill="#8C7869" d="M650.26,326.29c0.38,0.1,0.77,0.19,1.15,0.3c1.1,0.3,2.22,0.52,3.36,0.59
      c1.17,0.07,2.32,0.03,3.46-0.11c-0.21-0.41-0.46-0.79-0.76-1.15c-2.21,0.15-5.38-0.08-7.68-0.39c-0.19,0.05-0.37,0.1-0.53,0.14
      C649.69,325.76,650.02,325.99,650.26,326.29z"/>
    <path fill="#8C7869" d="M670.38,334.29c1.55-0.25,3.02-0.62,4.52-1.08c-0.2-0.22-0.39-0.42-0.58-0.61
      c-0.79,0.19-1.66,0.28-2.26,0.37c-3.04,0.44-6.32,0.61-9.44,0.34c-0.17,0.05-0.33,0.09-0.45,0.13c0.83,0.13,1.59,0.48,2.09,0.97
      C666.31,334.76,668.5,334.6,670.38,334.29z"/>
    <path fill="#8B979B" d="M673.75,297.79c0.01-0.01,0.02-0.01,0.03-0.02c-3.4,2.62-4.93,3.42-8.14,6.28
      c2.4,3.49,6.36,13.37,7.78,17.5c1.35,3.93,1.84,8.73,1.52,9.43c-0.32,0.71-2.38,0.78-3.34,0.91c-3.09,0.41-6.44,0.56-9.57,0.22
      c0.78-3.53,1.31-11.99,1.36-13.67c0.07-2.56,0.07-7.24-1.35-10.51c-0.66-1.52-1.56-1.71-1.98-2.2c-0.41-0.49-0.68-3.41-0.22-3.79
      c0.45-0.37,1.46-1.09,1.84-1.65c0.38-0.56,1.42-2.16,2.9-4.4c1.87-2.84,7.44-10.97,7.84-11.95c0.4-0.98,0.25-2.29-0.13-1.65
      c-0.38,0.64-1.46,2.5-2.26,3.71c-4.34,0.75-12.14,7.2-14.02,8.59c-0.85,0.63-2.29,1.99-2.4,2.66c-0.11,0.68,1.85,5.95,2.7,8.96
      c0.67,2.37,1.39,5.17,1.72,7.6c0,0,0,0,0-0.01c0,0,0,0.01,0,0.01c0.01,0.05,0.01,0.09,0.02,0.14c0-0.03-0.01-0.06-0.01-0.08
      c0.47,3.6,0.46,7.24,0.07,10.85c-1.7,0.28-6.51,0.21-9.54-0.45c2.49-4.13,2.53-9.57,2.35-14.21c-0.09-2.39-0.3-4.76-0.49-7.14
      c-0.09-1.14-0.09-2.33-0.36-3.44c-0.25-1.02-0.69-1.85-0.92-2.67c-0.23-0.83-0.04-1.99,0.41-2.81c0.45-0.82,1.02-1.39,1.74-2.34
      c3.16-4.04,9.81-10.04,12.75-11.67c4.54-0.54,9-1.74,13.53-2.36c0.64-0.08,1.28-0.15,1.91-0.24c-0.02,0.39-0.04,0.78-0.06,1.17
      c-0.07-0.04-0.14-0.05-0.21-0.04c-0.4,0.03-0.61,0.79-0.15,1.11c0.57,0.4,1.17,0.71,1.81,0.95c-0.13,2.88,0.93,5.47,2.8,7.49
      C681.41,291.31,676.58,295.61,673.75,297.79z"/>
    <path fill="#ADB8BB" d="M665.87,295.91c1.04-1.93,2.36-3.66,3.6-5.46c1.17-1.69,2.72-4.31,2.79-6.21
      c-0.99,1.76-5.95,9-7.69,11.66c-1.48,2.24-2.52,3.84-2.9,4.4c-0.38,0.56-1.39,1.28-1.84,1.65c-0.19,0.16-0.25,0.77-0.23,1.46
      C662.84,302.29,664.33,298.75,665.87,295.91z"/>
    <path fill="#ADB8BB" d="M653.27,290.95c1.73-1.84,2.96-3.22,4.4-4.96c0.87-1.05,1.77-2.09,2.55-3.21
      c-3.09,2.56-6.8,6.17-8.92,8.89c-0.72,0.94-1.29,1.51-1.74,2.34c-0.45,0.82-0.64,1.98-0.41,2.81
      C649.81,294.84,651.67,292.65,653.27,290.95z"/>
    <path fill="#ADB8BB" d="M667.9,308.14c0.4,1.4,3.47,9.87,4.18,12.48c0.81,2.97,2,6.43,2.25,10.81
      c0.29-0.11,0.51-0.26,0.6-0.45c0.32-0.71-0.17-5.5-1.52-9.43C672.4,318.56,670.03,312.55,667.9,308.14z"/>
    <path fill="#67757B" d="M686.9,278.59c0.41-0.18,0.82-0.34,1.24-0.49c0.02-0.68-0.02-1.38-0.12-2.03
      c-0.39,0-0.78-0.01-1.18-0.01C686.88,276.9,686.89,277.74,686.9,278.59z"/>
    <path fill="#67757B" d="M669.39,280.54c3.53-0.73,6.42-0.72,8.26-0.62c-0.32,0.75,1.47,1.47,2.04,1.51
      c-0.22,2.47,0.86,5.62,3,7.93c0.36-0.44,0.69-0.87,0.98-1.29c-1.86-2.02-2.92-4.61-2.8-7.49c-0.63-0.24-1.24-0.55-1.81-0.95
      c-0.46-0.32-0.25-1.08,0.15-1.11c0.06-0.01,0.13,0.01,0.21,0.04c0.02-0.39,0.04-0.78,0.06-1.17c-0.63,0.09-1.27,0.16-1.91,0.24
      c-4.53,0.63-8.99,1.82-13.53,2.36c-0.6,0.33-1.36,0.85-2.22,1.5C664.37,281.45,666.88,281.06,669.39,280.54z"/>
    <path fill="#67757B" d="M658.05,313.97c-0.01-0.05-0.01-0.1-0.02-0.14c0,0,0,0,0-0.01c-0.33-2.41-1.04-5.17-1.7-7.52
      c0.51,3.16,0.67,6.42,0.53,9.53c-0.13,3.08-0.6,6.15-1.55,9.06c1.18,0,2.19-0.06,2.8-0.16c0.39-3.61,0.4-7.25-0.07-10.85
      C658.04,313.91,658.04,313.94,658.05,313.97z"/>
    <path fill="#67757B" d="M668.4,286.48c0.58-0.22,1.12-0.39,1.62-0.47c0.57-0.87,1.29-2.08,1.8-2.93
      C670.53,284.06,669.43,285.24,668.4,286.48z"/>
    <path fill="#FAD1B0" d="M700.54,285.56c-0.3,0.53-5.74,5.87-6.56,5.99c-0.82,0.11-3.92-0.68-5.22-1.22
      c-1.08-0.47-2.1-1.05-3.04-1.76c-0.74-0.58-1.41-1.23-1.99-1.97c-0.47-0.63-0.87-1.3-1.19-2.02c-0.28-0.69-0.48-1.41-0.58-2.15
      c-0.06-0.51-0.08-1.02-0.06-1.52c1.16,0.3,2.38,0.39,3.57,0.37c2.41,1.28,5.94,3.71,8.05,5.94c0.35,0.37-0.03-0.86-0.64-1.62
      c1.33-0.76,2.89-1.42,3.76-1.68c0.09-2.36,1.38-3.52,1.94-3.22c-0.63,0.99-0.74,2.59-0.75,3.29c-1.35,0.72-3,1.92-3.46,2.35
      c-0.47,0.43-0.7,1.43-0.25,0.98c0.49-0.49,2.68-2.32,4.35-3.12c-0.02-1.18,0.14-2.81,0.83-3.45c0.7-0.65,1.26-0.41,1.47-0.11
      c-1.41,0.82-1.86,2.26-1.87,4.31c-0.87,0.42-3,2.12-3.73,2.75c-0.73,0.63-0.97,1.87-0.55,1.45c0.71-0.71,4.1-3.49,4.9-4
      c-0.1-0.72,0.06-1.61,0.42-2.63c0.3-0.87,1.04-1.42,1.45-1.28C701.78,281.36,700.84,285.02,700.54,285.56z"/>
    <path fill="#DBB08F" d="M686.5,281.85c-0.36-0.21-0.71-0.41-1.04-0.58c-1.19,0.02-2.4-0.08-3.57-0.37
      c-0.02,0.49,0,0.99,0.06,1.48C683.42,282.66,685.09,282.46,686.5,281.85z"/>
    <polygon points="665.18,254.63 665.18,254.63 665.18,254.63 	"/>
    <polygon points="641.39,271.61 641.4,271.6 641.39,271.61 	"/>
    <path d="M647.36,197.49c-0.5-1.14-1.76-1.23-2.4-1.23c-3.71,0-6.31,4.63-7.03,7.17c-0.69,2.4-0.53,5.44,2.46,5.05
      C643.37,208.1,649.36,202.05,647.36,197.49z"/>
    <path fill="#95C6EF" d="M641.69,206.9c-0.03,0.02-0.06,0.03-0.09,0.05c-1.24-0.67-1.58-2.8-0.58-4.18
      c1.16-1.61,3.34-1.97,4.98-0.61c-0.21,0.48-0.47,0.92-0.74,1.28C643.78,205.36,642.61,206.34,641.69,206.9z"/>
    <path fill="#69A8DC" d="M645.61,201.88c-0.09,0.15-0.55,1.01-0.79,1.29c-0.18,0.21-0.25-0.08-0.17-0.22
      c0.07-0.14,0.49-1.15,0.56-1.29c-0.13-0.06-0.26-0.12-0.39-0.16c-0.22,0.51-0.43,1.02-0.65,1.52c-1.1,0.09-1.54,1.17-1.4,1.59
      c-0.76,0.07-1.54,0.29-2.31,0.38c0.02,0.17,0.05,0.34,0.09,0.5c0.93-0.22,1.67-0.42,2.52-0.67c0.26-0.08,0.2,0.19-0.06,0.27
      c-0.84,0.25-1.48,0.55-2.32,0.8c0.2,0.45,0.5,0.83,0.91,1.05c0.03-0.02,0.06-0.03,0.09-0.05c0.92-0.56,2.09-1.54,3.57-3.46
      c0.27-0.36,0.53-0.8,0.74-1.28C645.88,202.06,645.75,201.97,645.61,201.88z"/>
    <path fill="#FFFFFF" d="M646.29,201.41c-2.32-1.75-4.7-0.62-5.89,0.98c-1.08,1.45-0.95,4.03,0.39,4.93
      c-1.58,0.51-2.63-0.46-2.08-2.99c0.64-2.99,2.09-4.97,3.66-6.11c1.79-1.31,3.35-1.51,4.02-0.08
      C646.78,198.99,646.67,200.25,646.29,201.41z"/>
    <path d="M650.05,207.33c-0.73,0.07-0.73,1.15,0,1.14c1.02-0.01,0.77,0.49-0.9,2.66c-1.32,1.72-4.23,4.16-5.87,5.56
      c-0.93,0.8,1.17,0,2.32-0.87c2.65-2.01,5.08-4.47,6.06-6.75C652.34,207.46,650.38,207.3,650.05,207.33z"/>
    <path d="M653.83,213.32c-0.45,0.61-0.95,1.45-2.09,1.76c-1.14,0.31-2.06,0.09-2.82,0c-0.73-0.09,0.03,0.78,0.73,0.97
      c1.56,0.45,2.91,0.46,4.1-0.98C654.31,214.41,654.28,212.7,653.83,213.32z"/>
    <path d="M648.95,197.55c-1.09-1.09-4.55-4.1-8.29-4.02c-0.48,0.01-1.07,1.62-0.35,1.67c1.13,0.03,3.13,0.55,4.17,0.98
      c1.67,0.73,3.11,1.48,4.74,2.69C649.81,199.31,649.78,198.39,648.95,197.55z"/>
    <path d="M677.48,203.9c-0.24-0.92-1.49-1.72-3.25-0.58c-0.5,0.32-0.55,1.05-0.17,0.71c0.76-0.68,2.3-0.9,2.68-0.07
      c0.39,0.83-0.78,1.51-2.14,1.6c-0.51,0.03,0.11,0.57,0.61,0.5C676.37,205.88,677.87,205.4,677.48,203.9z"/>
    <path d="M629.52,212.26c-0.45,0.64-0.09,1.45,0.17,1.01c0.53-0.93,3.04-1.34,4.86-0.11c0.43,0.29,0.16-0.83-0.48-1.15
      C631.73,210.81,630.05,211.5,629.52,212.26z"/>
    <path d="M656.96,182.53c-1.87-0.23-3.76-0.26-5.64-0.33c-3.71-0.14-4.73,1.41-4.25,1.22c2.88-1.11,9.36-0.38,13.15,0.01
      C660.74,183.48,658.8,182.76,656.96,182.53z"/>
    <path d="M642.22,203.33c-1.16,1.32-0.55,3.03,0.71,3.01c0.34-0.01,2.06-1.41,2.53-2.09C646.3,203.05,643.7,201.65,642.22,203.33z"
      />
    <path fill="#FFFFFF" d="M644.34,203.08c-0.38,0-0.69,0.31-0.69,0.69c0,0.38,0.31,0.69,0.69,0.69c0.38,0,0.69-0.31,0.69-0.69
      C645.03,203.39,644.72,203.08,644.34,203.08z"/>
    <path fill="#FFFFFF" d="M643.27,203.97c-0.15,0-0.28,0.12-0.28,0.28c0,0.15,0.12,0.28,0.28,0.28c0.15,0,0.28-0.12,0.28-0.28
      C643.55,204.09,643.42,203.97,643.27,203.97z"/>
    <g>
      <g>
        <path fill="#FFFFFF" d="M638.95,264.93c-1.77,0.32-3.68-0.41-4.78-1.93c-0.98-1.35-1.9-4.32-0.38-5.64
          c-0.02-0.09,0.01-0.19,0.12-0.25c0.03-0.02,0.06-0.03,0.1-0.04c0.3-0.23,0.82-0.47,1.14-0.13c0.14,0.15,0.23,0.32,0.31,0.51
          c0.12,0.22,0.24,0.49,0.39,0.8c0.3,0.64,0.57,1.35,1.01,1.92c0.37,0.47,0.8,0.71,1.28,0.8l-4.08-19.64
          c-0.18-0.85-0.84-1.41-1.49-1.25l-9.37,2.34c-0.64,0.16-0.98,1.06-0.75,1.99l7.51,30.3c0.23,0.94,0.85,1.43,1.39,1.09l7.74-4.95
          c0.53-0.34,0.82-1.31,0.65-2.15L638.95,264.93z"/>
        <path fill="#DDDDDD" d="M638.95,264.93c-1.77,0.32-3.68-0.41-4.78-1.93c-0.54-0.74-1.06-1.97-1.16-3.15
          c-0.17,0.2-0.28,0.5-0.31,0.94c-0.08,1.07-0.12,2.33,0.39,3.31c0.03,0.06,0.06,0.11,0.1,0.17c0.18,0.01,0.35,0.11,0.46,0.33
          c1.1,2.22,3.5,3.04,5.9,3.13L638.95,264.93z"/>
        <path fill="#DDDDDD" d="M638.13,260.97l-0.11-0.53c-0.36-0.04-0.72-0.09-1.08-0.16
          C637.29,260.68,637.69,260.88,638.13,260.97z"/>
        <path fill="#DDDDDD" d="M623.37,248.12c0.5-2.05,1.06-4.09,1.58-6.14l-1.76,0.44c-0.64,0.16-0.98,1.06-0.75,1.99
          L623.37,248.12z"/>
        <path d="M639.15,264.89c-0.14,0.03-0.27,0.05-0.41,0.07l0.8,3.85c0.14,0.69-0.09,1.47-0.52,1.74l-7.75,4.93
          c-0.43,0.27-0.93-0.12-1.12-0.88l-7.48-30.24c-0.19-0.76,0.08-1.48,0.6-1.61l9.36-2.36c0.52-0.13,1.06,0.32,1.2,1.01l4.07,19.53
          c0.14,0.04,0.3,0.07,0.45,0.09l-4.08-19.73c-0.21-1.01-1-1.67-1.77-1.48l-9.93,2.32c-0.77,0.19-1.66,1.08-1.34,2.68l7.72,30.91
          c0.28,1.12,1.83,1.18,2.46,0.77l7.86-5.11c0.63-0.41,0.96-1.41,0.75-2.41L639.15,264.89z"/>
      </g>
      <path d="M634.17,263.01c-0.98-1.35-1.9-4.32-0.38-5.64c-0.02-0.09,0.01-0.19,0.12-0.25c0.03-0.02,0.06-0.03,0.1-0.04
        c0.3-0.23,0.82-0.47,1.14-0.13c0.14,0.15,0.23,0.32,0.31,0.51c0.1,0.25,0.2,0.59,0.34,0.9c0.18,0.38,0.4,0.78,0.62,1.13
        l-3.61-16.9l-8.67,2.39l6.45,26.48l7.46-4.34l-0.46-2.15C636.25,264.81,634.99,264.13,634.17,263.01z"/>
      <path fill="#9CDB7F" d="M634.17,263.01c-0.72-0.99-1.33-2.77-1.06-4.22c0.06-0.35,0.22-0.79,0.4-1.09
        c0.18-0.31,0.52-0.69,0.91-0.86c0.25-0.11,0.53-0.11,0.73,0.1c0.14,0.15,0.23,0.32,0.31,0.51c0.05,0.1,0.19,0.52,0.24,0.64
        l-3.22-14.95l-7.84,2.2l6.14,25.35l6.78-3.89l-0.41-1.9C635.98,264.66,634.9,264.01,634.17,263.01z"/>
      <path fill="#73AE4A" d="M634.17,263.01c-0.58-0.79-1.09-2.11-1.12-3.35c-0.48,1.39-0.27,3.07,0.42,4.31
        c0.74,1.33,2.21,2.8,3.78,3l0.31-0.18l-0.41-1.9C635.98,264.66,634.9,264.01,634.17,263.01z"/>
      <path fill="#C6EAB5" d="M628.89,259.52c0.51-2.68,1.23-5.33,2.02-7.94c0.63-2.08,1.32-4.13,2.05-6.17l-0.49-2.26l-1.44,0.4
        c-0.02,0.05-0.03,0.1-0.05,0.15c-0.84,2.25-1.67,4.52-2.35,6.83c-0.54,1.82-0.99,3.65-1.41,5.49l1.35,5.56
        C628.66,260.89,628.76,260.2,628.89,259.52z"/>
      <polygon points="631.7,272.68 631.09,273.05 630.84,272 631.45,271.64 		"/>
      <path d="M634.91,270.16c0.07,0.31-0.03,0.66-0.22,0.78c-0.19,0.12-0.4-0.05-0.48-0.36c-0.07-0.32,0.02-0.67,0.22-0.78
        C634.63,269.69,634.84,269.85,634.91,270.16z"/>
      <polygon points="637.98,268.77 637.61,268.99 637.68,268.38 637.76,267.76 638.05,268.16 638.34,268.55 		"/>
      <path fill="#D5C78E" d="M621.93,244.76c-0.09-0.37-0.04-0.98,0.07-1.28c0.19-0.49,0.49-0.56,0.43-0.43
        c-0.13,0.26-0.15,0.94-0.05,1.36c0.16,0.72,2.82,11.3,3.05,12.58c0.23,1.28,4.23,17.72,4.43,18.13c0.2,0.41,0.66,0.77,0.92,0.84
        c0.12,0.03-1.1,0.22-1.42-0.81C628.95,273.83,622.21,245.92,621.93,244.76z"/>
      <path d="M631.65,251.95c-0.7-0.14-2.19-0.28-2.44-1.11c-0.28-0.9,0.18-1.37,0.77-1.43c0.8-0.08,1.47,0.49,1.89,0.79
        c0.08-0.23,0.42-1.83,0.51-2.37c-0.31-0.36-1.37-0.96-2.19-0.99c-0.13-0.47-0.39-1.42-0.49-1.81c-0.01-0.06-2,0.47-1.97,0.56
        c0.2,0.7,0.35,1.28,0.53,1.87c-0.9,0.7-1.33,1.5-1.42,2.35c-0.17,1.81,0.53,2.96,1.59,3.58c0.61,0.36,1.31,0.47,1.89,0.48
        c0.43,0,1.25,0.07,1.14,0.93c-0.09,0.71-0.73,0.83-1.16,0.85c-0.46,0.02-1.4-0.06-1.94-0.3c0.15,0.85,0.43,2.29,0.63,2.93
        c0.5,0.11,1.44,0.17,2.07-0.15c0.15,0.58,0.35,1.82,0.41,1.84c0.02,0.01,1.46-0.37,2.13-0.77c-0.1-0.39-0.32-1.34-0.53-2.01
        c0.66-0.51,0.84-1.21,0.85-2.09C633.94,253.1,633.2,252.26,631.65,251.95z"/>
      <path fill="#FCB308" d="M633.56,254.6c0.07,0.93-0.19,1.88-0.89,2.36c0.19,0.55,0.51,1.76,0.52,1.85
        c0.01,0.08-1.51,0.67-1.53,0.52c-0.03-0.22-0.28-1.32-0.37-1.82c-0.57,0.34-1.39,0.43-1.96,0.32c-0.1-0.26-0.36-1.52-0.44-1.95
        c0.81,0.2,1.63,0.26,2.26-0.05c0.63-0.32,1.07-1.56,0.19-2.13c-0.46-0.3-1.01-0.27-1.49-0.31c-0.62-0.04-1.24-0.27-1.8-0.8
        c-0.72-0.67-0.98-1.93-0.83-2.9c0.11-0.7,0.59-1.69,1.31-2.05c-0.21-0.64-0.56-1.91-0.56-1.91l1.58-0.41c0,0,0.37,1.28,0.48,1.77
        c0.46-0.01,1.52,0.38,1.92,0.86c-0.03,0.3-0.23,1.3-0.3,1.57c-1.25-0.79-2.15-0.71-2.68,0.02c-0.57,0.81-0.08,1.9,0.72,2.29
        c0.85,0.42,1.76,0.29,2.59,0.68C633.08,252.9,633.48,253.51,633.56,254.6z"/>
      <path fill="#FBD575" d="M631.57,249.47c0.08-0.25,0.16-0.49,0.25-0.74c0.06-0.31,0.12-0.63,0.13-0.78
        c-0.4-0.47-1.46-0.86-1.92-0.86c-0.03-0.12-0.07-0.28-0.12-0.46c-0.35,1-0.69,2-1.01,3.02c0.02-0.04,0.04-0.07,0.07-0.11
        C629.49,248.82,630.37,248.73,631.57,249.47z"/>
      <path fill="#FBD575" d="M629.28,257.66c0.12-0.55,0.25-1.09,0.39-1.63c-0.25-0.03-0.51-0.07-0.77-0.14
        C628.96,256.25,629.16,257.21,629.28,257.66z"/>
      <path fill="#FBD575" d="M629.86,253.39c0.16,0.01,0.34,0.02,0.51,0.03c0.12-0.43,0.25-0.86,0.37-1.28
        c-0.35-0.06-0.71-0.14-1.05-0.31c-0.59-0.29-1.02-0.96-0.97-1.62c-0.03,0.1-0.07,0.2-0.1,0.31c-0.2,0.69-0.39,1.38-0.58,2.07
        c0,0,0,0,0,0C628.62,253.12,629.24,253.35,629.86,253.39z"/>
    </g>
    <path d="M657.55,181.33c-1.65-0.56-2.59-0.7-4.45-0.73c-1.54-0.02-3.05,0.22-3.72,0.44c-0.64,0.21-0.9,0.57-0.67,0.52
      c2.82-0.69,6.73-0.6,9.72,0.53C658.73,182.2,658.33,181.6,657.55,181.33z"/>
    <path d="M635.83,259.11c-0.3,0.15-0.54,0.4-0.88,0.47c-0.32,0.06-0.57-0.27-0.69-0.51c-0.23-0.44-0.24-1.02-0.22-1.5
      c0-0.03-0.04-0.03-0.05,0c-0.11,0.58-0.09,1.23,0.2,1.76c0.41,0.76,1.22,0.44,1.8,0.1C636.2,259.31,636.04,259.01,635.83,259.11z"
      />
    <path d="M701.54,283.06c-0.44-0.02-0.83-0.09-0.77-0.63c0.06-0.49,0.32-0.96,0.54-1.39c0.01-0.01-0.01-0.03-0.02-0.02
      c-0.45,0.28-0.83,1.17-0.73,1.7c0.09,0.48,0.58,0.54,0.99,0.56C701.68,283.29,701.68,283.07,701.54,283.06z"/>
    <path d="M700.69,280.31c-0.86-0.03-1.43,1.04-1.24,1.78c0.03,0.11,0.2,0.07,0.17-0.04c-0.2-0.81,0.41-1.38,1.08-1.67
      C700.73,280.37,700.73,280.31,700.69,280.31z"/>
  </g>
  <g id="item">
    <path d="M660.76,591.05c-1.14-3.71-2.75-6.27-5.1-8.04c-2.71-2.05-5.93-2.96-10.9-3c0.64-2.37,2.01-12.36,2.42-19.89
      c0.36-6.66,0.66-14.15-0.97-22.73c-1.63-8.59-3.88-14.2-6.74-18.57c-0.72-1.11-7.37-10.82-17.55-10.82c-2.5,0-5,0.61-7.46,1.8
      c-1.47-0.42-2.85-0.62-4.22-0.62c-7.13,0-13.76,5.77-20.88,18.15c-2.93,5.1-4.82,9.77-6.33,15.17c-2.42,8.61-3.39,19.98-2.84,27.84
      c0.54,7.86,1.76,14.52,3.18,18.22c-1.32,1.51-3.04,4.19-3.43,4.91c-0.98,1.81-1.66,3.77-2.02,5.82l0,0.03l0,0.03
      c-0.7,8.9,3.75,20.2,5.21,23.92c0.18,0.47,0.41,1.03,0.66,1.66c1.2,2.98,3.01,7.49,4.2,11.47c0.79,2.67,1.17,5.42,1.28,6.32
      c-2.44,1.82-5.66,3.24-8.51,4.49c-1.99,0.87-3.86,1.7-5.14,2.53c-1.36,0.88-2.25,2.88-3.28,5.2c-0.23,0.52-0.47,1.06-0.72,1.6
      c-0.24,0.52-0.49,1.05-0.74,1.58c-0.97,2.07-1.98,4.2-2.6,5.99h14.36c1.34-1.58,3.34-3.44,4.81-4.79c0.72-0.67,1.29-1.19,1.59-1.52
      c0.46-0.49,1-1.07,1.41-1.51c0.69,2.55,1.86,6.02,3.02,7.82h23.44c0.01-0.4,0.01-0.81,0.01-1.23c0-0.52,0.01-1.05,0.02-1.59
      c1.15,1.01,2.41,1.95,3.77,2.82h32.91c0.02-0.45,0.05-0.88,0.08-1.28l0-0.02v-0.02c0.07-3.57,0.63-8.21,1.28-13.58
      c0.12-1.03,0.26-2.39,0.42-3.97c0.37-3.68,0.84-8.27,1.49-12.44c0.59-3.79,1.32-7.32,2.3-11.1c1.16-4.5,2.2-10.12,2.86-15.41
      C662.71,600.97,662.68,597.33,660.76,591.05z"/>
    <path fill="#CD4D12" d="M646.03,545.01c0.48,8.95,0.3,18.44-1.09,26.54c-1.01,5.88-1.69,9.86-3.3,15.49
      c-1.61,5.63-3.86,10.33-5.85,13.76c-1.99,3.43-5.25,7.35-7.1,11.36c-1.57,3.91,0.38,7.18,1.43,10.59c0.59,1.9,0.96,5.39,0.5,7.18
      c-0.46,1.79-1.17,2.99-2.64,4.25c0.96-2.08,2.48-4.58,1.67-7.18c-0.45-2.1-1.9-4.04-2.93-6.04c-1.16-2.24-1.88-4.51-1.92-6.38
      c-0.07-3.52,0.46-5.91,2.18-10.16c1.72-4.25,7.62-15.24,10.13-22.76c2.51-7.52,3.91-12.9,4.3-20.98
      c0.39-8.08-0.82-15.13-2.38-19.58c-1.48-4.01-3.1-8.02-5.91-11.51c0.32-1.03,0.45-2.15-0.15-3.12c-1.92-1.86-4.36-2.52-6.65-3.71
      c-3.55-3.34-7.93-6.98-12.36-8.09c-0.37,0.15,1.6,1.89,2.53,2.48c-1.63,0.24-6.28,1.9-8.64,3.68c-3.33,1.19-6.58,2.67-9.31,5.19
      c0.58-3.32,3.38-6.94,5.17-8.46c-3.53-0.33-8.61,10.89-11.87,20.45c-3.26,9.55-5.05,22.05-2.78,33.03c1.45,7,3.79,11.66,6.21,14.32
      c-1.84,2.72-4.05,5.91-5.9,8.66c-0.67,0.07-4.13-5.1-4.96-7.29c-1.42-3.77-2.55-12.42-2.49-20.88c0.06-8.46,0.94-18.49,2.9-24.73
      c2.78-8.83,9.19-19.63,12.21-22.98c6.07-6.71,11.08-8.98,16.39-7.26c-0.97,0.54-2.44,1.95-2.27,3.08c3.12-2.72,5.7-5.08,11.76-4.71
      C637.18,510.13,645.19,529.35,646.03,545.01z"/>
    <path fill="#F8C292" d="M623.63,522.04c-0.68,0.79-0.99,2.23-0.84,3.33c3.55,1.4,7.67,3.48,9.43,4.94
      c1.09,0.91,1.91,2.22,2.39,3.66c-5.25-1.54-9.87-0.38-12.79,5.87c-0.77,0.1-2.03,0.09-3.32-0.08c0.29,1.19,1.61,1.67,3.42,1.3
      c-0.14,4.82,2.56,10.09,7.35,11.73c4.8,1.64,8.51,1.15,10.81-1.67c0.84,5.69,0.62,13.6-1.71,19.22
      c-1.43,3.46-7.67,12.5-11.59,15.56c-1.92,1.49-4.83,2.79-7.42,2.35c-0.33-0.68-0.95-1.5-1.6-2.18c-0.65-0.68-3.51-2.38-6.52-2.87
      c-2.59-2.08-6.78-5.06-10.33-4.02c-3.18-4.38-4.86-9.3-6.31-14.42c-1.45-5.11-1.71-13.87-1.09-18.59c1.77,5.58,7.98,6.67,13.5,5.15
      c5.74-1.58,6.79-6.45,7.04-10.12c0.96,0.07,2.6,0.63,3.27-0.26c-1.13-0.39-2.26-0.51-3.61-1.3c-1.53-3.62-4.67-6.6-7.92-6.64
      c-4.73-0.06-9.06,4.47-11.72,8.11c0,0,2.48-8.91,4.72-12.08c2-1.34,6.83-3.81,11.29-5.46c-0.22-0.93-0.78-1.73-1.33-2.15
      c2.37-1.93,6.55-3.24,8.88-3.8C619.57,518.7,621.63,520.15,623.63,522.04z"/>
    <path fill="#C9784A" d="M608.74,523.22c-3.42,1.06-9.27,4.43-9.59,4.64c-0.06-0.36-0.13-1.08-0.06-1.48
      c1.37-1.2,5.3-3.57,8.33-4.46C607.86,521.93,608.77,522.84,608.74,523.22z"/>
    <path fill="#C9784A" d="M632.4,526.86c0.15,0.44,0.41,1.48-0.09,2.25c-2-1.27-5.77-3.07-8.51-4.26
      c-0.09-0.65-0.03-1.39,0.32-1.89C626.94,523.7,629.96,525.01,632.4,526.86z"/>
    <path fill="#E75612" d="M597.96,528.31c-3.75,7.22-5.76,15.62-5.98,21.93c-0.89,11.21,3.6,22.81,7.86,29.41
      c-1.76,1.81-3.94,4.88-3.94,4.88c-2.66-4.16-4.44-7.94-5.66-13.29c-2.49-10.95,0.06-22.43,1.08-27.61
      c1.02-5.17,4.42-15.12,6.84-17.1C598.15,526.53,597.96,527.84,597.96,528.31z"/>
    <path fill="#00A0CE" d="M612.79,539.66c-7.41,0-17.62,3.23-18.23,3.66c1.48-3.01,5.3-7.98,9.53-9.11
      C607.11,533.4,611.57,536.18,612.79,539.66z"/>
    <path fill="#00A0CE" d="M635.39,535.53c1.41,2.55,3.66,7.92,4.1,10.82c-5.89-2.65-12.03-5.39-16.56-6.36
      C625.11,535.42,629,532.81,635.39,535.53z"/>
    <path fill="#82CFEF" d="M611.17,540.78c-0.59,2.67-2.25,6.11-5.52,7.08c-3.28,0.97-6.32-1.13-6.21-5.04
      c0.52-0.15,2.65-0.68,2.65-0.68s0.11,3.35,3.41,3.04c2.14-0.2,3.43-2.71,3.36-4.26C609.73,540.86,610.74,540.82,611.17,540.78z"/>
    <path fill="#FFFFFF" d="M605.25,542.96c-0.8-0.11-1.38-1.03-1.15-1.4c0.74-0.3,2.43-0.46,2.67-0.34
      C607.22,541.45,606.68,543.15,605.25,542.96z"/>
    <path fill="#FFFFFF" d="M613.12,540.86c-0.23,3.43-0.94,6.64-4.66,8.74c-3.11,1.76-8.24,1.45-10.78,0.1
      c-2.54-1.36-3.26-3.18-3.7-4.74c0.89-0.75,3.68-1.74,4.76-1.96c-0.29,2.15,0.69,4.5,2.61,5.54c1.78,0.89,4.11,0.82,6.09-0.21
      c1.97-1.02,3.89-4.58,4.33-7.4C612.11,540.93,612.65,540.86,613.12,540.86z"/>
    <path fill="#FFFFFF" d="M624.6,541.62c0,0-0.17,6.15,4.68,8.19c3.71,1.56,7.09-1.07,8.64-3.15
      c0.74,0.15,1.62,0.58,1.62,0.58s0.25,2.29,0.25,2.9c-0.79,0.98-2.45,2.72-6.5,2.61c-4.15-0.11-10.22-2.97-10.36-11.5
      C623.49,541.25,624.6,541.62,624.6,541.62z"/>
    <path fill="#82CFEF" d="M627.99,542.56c0,0-0.87,3.07,2.14,4.18c3.21,1.18,4.79-1.59,4.79-1.59l2.42,1.1
      c0,0-3.49,4.62-8.23,2.63c-2.66-1.11-3.89-4.17-3.79-7.1C626.28,542.1,627.99,542.56,627.99,542.56z"/>
    <path fill="#FFFFFF" d="M633.14,544.67c-0.58,0.54-1.82,0.99-2.56,0.31c-0.73-0.68,0.28-1.64,0.28-1.64
      C631.6,543.56,632.77,543.93,633.14,544.67z"/>
    <path d="M601.24,558.33c-1.91,0.98-2.87,2.26-2.87,2.26s1.65,3.39,4.76,5.91c3.11,2.52,9.01,4.4,14.28,4.39
      c3.72-0.01,7.11-0.52,10.65-2.55c4.13-2.37,5.15-4.65,5.52-7.54c-0.2-0.47-1.37-1.38-1.91-1.56c0-1.27,2.18-1.22,3.21,0.72
      c1.12,2.1,1.59,5.04,0.99,5.27c-0.34-1.54-1.09-2.77-1.6-3.46c-0.07,1.34-0.78,4.53-5.92,7.58c-5.02,2.97-11.08,3.19-17.54,1.85
      c-6.46-1.34-10.63-5-13.07-9.75c-0.49,0.41-1.19,1.2-1.55,1.96C595.72,560.8,598.43,557.14,601.24,558.33z"/>
    <path d="M620.05,558.44c0.36,2.54-3.65,3.16-5.46,1.3c-0.86-0.88-0.91-2.05-0.53-1.67C615.54,559.6,618.72,559.18,620.05,558.44z"
      />
    <path fill="#B24412" d="M638.46,572.59c-1.92,12.54-10.35,22.64-13.67,34.74c-0.59,2.15-1.18,3.94-1.11,6.38
      c-0.3,3.19,1.72,7.4,2.59,8.69c0.87,1.29,1.53,2.88,1.53,2.88s-1.39,4.25-4.43,5.18c0.79-1.26,1.45-2.99,1.52-6.24
      c0.06-3.08-2.63-6.66-3.2-8.94c-1.09-4.4-0.38-7.34,0.79-10.7c1.41-4.02,3.69-7.84,5.34-11.72c1.77-3.79,3.1-7.87,4.73-11.73
      C634.48,578.99,636.46,576.27,638.46,572.59z"/>
    <path d="M621.92,576c0.3,0.22,0.3,0.52,0.07,0.74c-2.07,1.48-5.17,1.04-7.32,0c-0.44-0.3-0.89-0.97-0.22-1.26
      C616.6,576.74,619.48,576.52,621.92,576z"/>
    <path fill="#F8C292" d="M610.67,584.24c1.85,1.49,4.78,3.89,5.12,5.02c0.34,1.14,0.91,6.38,1.3,7.97
      c0.4,1.59,0.36,2.94-0.95,2.52c-0.88-0.29-2.66-2.62-2.26-8.93c-1.35-0.28-4.75-1.48-6.45-3.19c-0.19,3.58-1.67,5.85-2.65,7.25
      c-1.55,2.2-3.69,4.14-5.64,6.14c-1.77,2-1.06,5.06-0.21,7.04c0.85,1.98,6.9,14.09,6.9,14.09c-3.97,1.09-8.45,2.21-10.38,3.07
      c-0.52-3.56-2.53-9.29-3.66-12.33c-1.31-3.51-2.54-7.05-3.21-10.83c-0.74-5.57,3.12-9.81,5.84-13.66c2.72-3.85,4.85-7.29,6.97-8.2
      C603.52,579.29,607.94,582.39,610.67,584.24z"/>
    <path fill="#F7CC14" d="M612.18,615.01c-0.06,2-0.11,4.26,1.7,5.11c1.8,0.85,2.39-3.28,2.64-4.1
      c1.7,0.94,2.05,3.89,1.37,6.28c-0.68,2.38-3.15,2.62-5.14,2.4c1.85,1.86,4.97,5.28,7.85,4.61c-0.68,3.34-4.41,3.61-7.41,2.07
      c3.55,2.75,8.49,8.65,4.3,13.36c-1.37-4.02-3.12-5.57-3.56-5.57c0.64,2.71,1.25,6.26,1.58,8.44c3.84,1.64,8.54,1.22,12.61,2.03
      c3.09-0.98,10.64,2.67,11.83,3.04c0,0-0.65-14.14,0.79-21.9c1.44-7.76,2.49-11.14,4.3-15.45c-0.03-1.34-0.21-6.06,1.26-7.12
      c-0.07,1.26-0.18,4.64-0.18,4.64c0.91-2.46,1.72-3.56,2.42-3.36c-2.19,5.68-5.99,16.17-6.89,21.96c-0.9,5.78-0.89,14.55-0.3,21.82
      c3.58,0.42,7.62,1.11,8.28,3.94c-1.01-0.83-5.67-2.68-7.15-2.61c6.86,4.13,6.04,5.87,5.75,5.95c-4.51-5.12-14.41-9.75-18.33-9.5
      c1.28,5.09,0.82,9.78,0.37,13.06h21.81c0-0.47,0-0.94,0.01-1.42c0.06-4.03,2.03-23.07,3.01-29.75c0.98-6.69,4.46-19.31,5.17-25.54
      c0.72-6.23,0.48-10.99-1.19-16.19c-1.67-5.21-4.29-9.91-14.74-9.74c-0.85,4.47-1.93,9.79-7.04,19c-3.15,5.68-9.99,11.09-7.63,17.77
      c1.11,2.67,2.23,4.91,2.29,8.31c0.06,3.4-0.99,7.16-3.1,8.89c-2.12,1.73-3.57,1.26-4.63,0.73c1.92-0.86,2.78-2.06,3.64-3.78
      c0.86-1.73,1.36-4.27,0.48-5.91c-0.44,2.08-1.93,3.65-2.99,4.38c-1.06,0.73-2.98,0.87-3.57,0.2c0.44-0.86,2.18-4.78,1.79-7.64
      c-0.4-2.86-1.93-4.34-2.5-6.38c-0.77-2.77-1.04-5.41-0.79-8c0.75-7.48,7.46-16.03,9.61-24.5c-2.37,3.42-6.4,4.95-10.31,4.88
      c0.68,1.52,1.26,3.12,1.88,4.39c0.61,1.27-0.1,2.17-0.95,2.39c-0.85,0.22-2.08,0.18-2.87-0.8c0.26,1.15,0.47,2.65,0.43,3.99
      c-0.04,1.35-2.01,2.05-3.28,0.68c-1.27-1.36-2.09-4.17-1.7-8.48c-1.95-0.18-4.85-2.11-4.85-2.11s-0.12,1.25-0.28,1.53
      c2.96,3.49,3.89,5.95,4.91,11.17C613.99,607.9,612.29,611.57,612.18,615.01z"/>
    <path fill="#F8C292" d="M618.39,588.32c0.49,1.17,1.95,5.08,2.31,5.98c0.36,0.91-1.66,1.7-2.71,0.15
      c-1.05-1.56-1.08-5.22-1.3-5.84c-0.22-0.62-3.61-3.5-4.1-3.95C613.36,584.42,617.31,585.71,618.39,588.32z"/>
    <path fill="#F7CC14" d="M588.57,595.38c-0.22,1.85-1.4,3.41-0.96,5.42c0.3,3.76,2.46,10.44,3.7,13.84
      c2.12,5.81,3.26,10.96,3.26,10.96s-0.82,0.43-1.05,0.66c0.64,2.33,1.25,6.52,1.64,9.32c0.22,1.59,0.73,5.61,0.44,7.46
      c-1.36-0.38-3.26-0.61-4.63-0.24c-0.17-1.46-0.34-3.63-1.2-6.89c-0.87-3.26-3.4-8.39-4.88-12.62c-1.83-5.58-4.29-10.96-5.15-16.89
      c-0.32-2.2-0.3-5.78-0.17-6.87c0.13-1.09,1.02-3.98,2.1-5.74c0.8-1.28,1.76-2.48,2.77-3.61
      C584.44,590.18,587.1,593.74,588.57,595.38z"/>
    <path fill="#E75612" d="M607.71,592.18c2.51,3.79,4.14,7.94,4.58,12.55c0.37,4.9-1.85,10.57-0.37,14.41
      c0.52,1.35,1.62,2.02,2.85,1.74c1.62-0.36,1.8-2.83,1.99-4.02c0.54,0.54,1.23,3.12,0.47,5.08c-0.76,1.96-3.83,2.94-5.87,0.85
      c-1.55-1.83-1.93-4.53-1.95-7.37c-0.01-2.84,0.55-5.54,0.41-8.32c-0.2-4.09-1.24-8.02-4.18-10.98
      C606.94,594.57,607.71,592.18,607.71,592.18z"/>
    <path fill="#CD4D12" d="M608.61,612.8c-0.43,4.68,0.22,9.5,3.83,13.02c2.14,2.09,4.53,4.02,6.97,4.17
      c-1.95,2.9-6.2,0.57-7.92-0.46c-0.74-1.26-1.84-5.65-3.42-7.86c-0.37-0.01-0.94,0.2-0.94,0.2s-6.9-13.58-7.41-15.16
      c-0.51-1.59-0.8-3.75,0.16-4.94c1.77-1.63,3.25-3.04,4.95-4.68C608.82,601.17,608.98,608.73,608.61,612.8z"/>
    <path fill="#F7CC14" d="M595.72,664.11h19.38c0.04-2.66-0.16-7.51-0.43-11.87c-0.31-4.92-1.32-10.1-2.21-14.34
      c-0.86-4.09-3.71-13.2-4.96-14.54c-4.85,1.01-9.97,2.64-12.62,3.75c1.55,6.38,2.24,14.17,1.45,19.35
      c-0.8,5.18,1.26,10.49,2.52,13.49c-0.85,0.56-2.03-3.22-2.55-4.48c-0.44,0.3,0.18,3.21,0.54,3.84c-1.37,0.22-1.28-4.01-1.51-5.42
      c-1.03-0.3-2.62,0.12-3.36,0.71c0.37,2.3,1.61,6.45,2.73,8.26C594.95,663.27,595.3,663.69,595.72,664.11z"/>
    <path fill="#B24412" d="M617.52,643.19c-0.54-2.61-2.07-4.4-3.79-5.29c-0.4-2.07-1.03-4.5-1.44-5.69
      C616.66,634.27,619.91,640.97,617.52,643.19z"/>
    <path fill="#FACB9E" d="M571.26,664.11l2.54-5.42c0.22,0.22,0.34,0.44,0.12,1.02c-0.11,0.31-0.89,2.17-1.85,4.39h2.66
      c0.16-0.5,0.28-0.88,0.34-1.06c0.29-0.83,0.72-0.65,0.87-0.5c-0.17,0.46-0.35,0.99-0.55,1.56h5.3c0.16-0.35,0.33-0.69,0.53-1.03
      c0.79-1.3,3.1-3.11,4.68-4.53c1.58-1.42,3.78-3.97,5.47-4.98c1.69-1.02,2.67-0.81,4.08-1.11c-0.66-2.45-0.37-5.45,0.05-8.07
      c-1.55-0.67-3.74-0.54-5.52-0.02c-4.36,3.27-11.76,5.67-13.57,6.91c-2.28,1.57-5.42,9.99-6.2,11.93c-0.13,0.31-0.24,0.62-0.35,0.9
      H571.26z"/>
    <path fill="#FACB9E" d="M623.54,664.11h5.43c0.79-4.05,0.65-11.01-0.49-12.91c-4.06-1.26-8.99-0.8-12.98-2.36
      c0.14,1.85,0.65,6.93,0.65,9.79C618.18,660.81,621.76,663.16,623.54,664.11z"/>
    <path fill="#FBD9BD" d="M617.7,525.53c7.15,1.01,8.93,7.01,5.75,6.45c-3.18-0.56-7.76-1.01-15.13-0.45
      C606.39,531.67,610.55,524.52,617.7,525.53z"/>
    <path fill="#FBD9BD" d="M614.11,555.53c1.65-1.49,4.25-1.49,5.91-0.37c0.55,0.37-0.1,1.63-1.2,2
      c-1.48,0.37-2.61,0.47-3.97-0.15C614.06,556.66,613.54,556.05,614.11,555.53z"/>
    <path fill="#FBD9BD" d="M617.22,572.82c2.45-0.2,4.37-0.32,5.47,0.03c1.63,0.53-0.11,2.24-1.89,2.49
      c-1.79,0.25-3.29,0.19-4.76-0.08C614.73,575.02,614.77,573.01,617.22,572.82z"/>
    <path fill="#E8600F" d="M619.17,512.72c4.07-0.07,7.76,1.67,11.08,4.27c1.48,1.11,2.59,2.78,3.7,4.46
      c1.85,3.34,3.33,6.68,4.43,10.39c0.19,0.74,0,1.48,0,2.23c-2.96-4.46-5.54-8.91-8.87-13.36c-2.4-2.97-6.12-5.27-9.54-5.92
      C618.89,514.57,617.18,512.75,619.17,512.72z"/>
    <path fill="#FBD9BD" d="M593.3,597.34c3.86-4.1,7.72-9.79,8.58-11.78c0.86-1.99,1.51-3.86,0.46-4.05
      c-1.04-0.19-3.86,2.57-5.53,4.88c-1.67,2.31-4.47,6.39-5.36,10.21C591.2,597.69,592.24,598.47,593.3,597.34z"/>
    <path fill="#FCE2C0" d="M578.52,652.09c3.77-2.83,8.41-4.38,12.19-6.63c0,0.93-0.36,1.99-0.91,2.54
      c-3.14,2.79-6.84,5.28-11.09,5.57C578,653.62,577.24,653.05,578.52,652.09z"/>
    <path fill="#FBE044" d="M657.6,598.83c0.74-3.9-0.07-7.31-2.1-10.65c-1.48-2.6-7-5.18-10.16-4.99
      C644.44,583.24,656.31,599.01,657.6,598.83z"/>
    <path fill="#FBE044" d="M643.75,640.59c2.79-1.49,3.33-4.45,3.51-7.24c0.18-3.53,0-7.05,0-10.58
      c-3.14,3.71-4.19,8.91-4.43,13.73C642.71,638.66,642.8,641.09,643.75,640.59z"/>
    <path fill="#FBE044" d="M633.4,657.84c3.61,1.09,7.21,2.23,10.72,3.53c0.19-0.93-0.55-1.67-0.92-2.41
      c-2.59-2.97-5.91-4.64-9.42-6.13c-0.37-0.19-0.99-0.21-1.29,0C632.16,653.06,632.55,657.59,633.4,657.84z"/>
    <path fill="#FBE044" d="M587.02,595.86c-0.74,0.74-1.6,1.1-2.69,2.9c-1.09,1.8-1.56,4.15-3.22,6.01
      c-0.92-2.23-0.72-3.99-0.42-6.18c0.31-2.19,2.08-5.14,4.11-5.88L587.02,595.86z"/>
    <path fill="#FBE044" d="M596.45,629.08c0.47,1.88,1.01,3.63,0.93,6.13c3.32-1.58,8.12-2.39,11.88-1.99
      c0.18,0.02-0.76-2.97-0.76-2.97c-2.96-0.56-5.52,0.29-8.73,1.25c-0.74,0,2.88-2.39,7.9-3.36c0.18-0.04-0.79-2.18-0.79-2.18
      C603.55,625.41,596.28,628.39,596.45,629.08z"/>
  </g>
  <g id="item_22_">
    <path d="M702.57,370.66c0.82-2.28,1.75-5.5,2.37-8.97c1.32-7.39,1.71-13.82,2.93-21.24c0.95-5.81,2.1-11.61,2.21-17.5
      c0.06-2.99-0.36-8.41,2.92-10.2c-0.63-2.31-3.66-6.1-5.01-14.32c-1.03,0.11-2.05,0.07-2.99-0.25c-0.32,0.16-0.67,0.27-1.09,0.25
      c-1.56-0.07-2.24-1.24-2.61-2.53c-0.55-1.9,0.6-4.02,1.48-5.65c1.25-2.34,2.25-4.67,3.22-7.1c0.17-2.55,0.57-5.09,0.68-7.64
      c0.13-2.78,0.22-5.47,0.76-8.21c0.93-4.78,2.92-9.38,5.64-13.52c5.31-8.07,11.92-13.52,23.3-16.97c10.34-3.14,23.78-3.5,32.73,3.06
      c6.19,4.54,9.69,11.88,11.88,19.22c0.98,1.56,3.14,4.63,4.45,6.84c2.08,3.51,4.61,6.02,8.17,8.3c3.34,2.14,7.13,3.56,10.94,4.79
      c1.79,0.58,4.47,0.45,6.28,1.33c0.16-0.87,0.47-1.72,0.74-2.6c0.47-1.53,0.1-2.99,0.3-4.51c0.16-1.23,1.34-2.01,2.15-2.91
      c1.33-1.47,1.05-2.88,0.92-4.67c-0.05-0.75,0.13-1.07,0.35-1.07c5.74,0.02,3.95,6.39,2.71,9.75c1.56-0.02,3,0.47,3.91,1.38
      c3.75-1.69,7.09-2.39,9.97-4.03c2.8-1.59,5.27-3.02,7.08-3.29c1.31-0.2,2.26,0.63,2.26,1.54c0,6.65-8.84,4.14-11.2,7.36
      c0.38,0.47,0.65,1.07,0.82,1.73c2.55-0.09,7.31-0.98,10.94-0.47c2.48,0.36,4.74,1.35,4.78,3.76c0.02,1.06-0.78,1.49-2.12,1.49
      c-1.36,0-2.83-0.46-5.03-0.88c-2.8-0.52-5.61-0.34-8.43-0.75c-0.07,0.61-0.17,0.96-0.35,1.52c3.87,0.99,12.24,2.8,11.24,7.01
      c-0.5,2.08-3.59,0.93-5.49-0.54c-1.9-1.48-3.77-2.61-5.44-2.72c1.34,1.91,5.67,5.95,2.94,7.52c-2.3,1.33-4.87-4-5.91-5.98
      c-4.13,1.55-9.23,2.91-12.97,0.04c-0.95-0.73-1.78-1.39-2.51-2.75c-5.99,1.7-13.49-2.1-18.65-4.76c-2.73-1.41-6.96-3.65-9.42-6.47
      c1.03,5.74,1.96,11.49,2.91,17.23c0.51,3.1,0.96,6.23,1.14,9.37c0.12,2.14,0.25,4.45,0.88,6.57c0.1-0.01,3.62-1.59,4.19-0.01
      c0.88,2.46-1.3,11.23-1.83,14.01c-1.15,6.12-1.82,12.25-2.49,18.43c-1.3,12.11-3.81,24.17-3.89,36.38
      c-0.27,0.33-1.24,0.47-1.67,0.58c1.55,0.18,5.43,1.17,7.57,1.67c5.59,1.3,10.3,3.92,10.52,6.69c-7.57,1.76-15.33,0.6-22.67-1.44
      c-3.9-1.08-18.4-5.05-21.49-6.41c-1.71-0.3-3.4-0.66-5.06-1.07c-0.26,0.74-0.56,1.45-0.93,2.14c-0.73,0.21-1.74,0.37-1.98,0.44
      c7.86,1.9,18.38,6.39,17.92,9.71c-5.45,2.18-11.99,1.17-17.43-0.49c-7.27-2.23-14.53-4.46-21.92-6.32
      c-10.21,0.88-19.11-2.12-27.11-3.71C700.67,376.41,701.76,372.94,702.57,370.66z"/>
    <path fill="#FFD4B8" d="M831.02,281.26c2.31,0.34,4.87,0.23,7.19,0.47c3.34,0.34,6.67,1.57,7.02,0.89
      c0.35-0.68,0.23-2.06-2.44-2.94c-2.66-0.88-9.1-0.16-11.79,0.18C831.04,280.21,831.03,280.9,831.02,281.26z"/>
    <path fill="#FFD4B8" d="M744.95,291.52c0.39-1.56,2.65-1.25,3.67-1.19c1.07,0.06,0.6-1.9-0.44-2.25
      c-1.05-0.35-1.98-1.13-1.9-1.95c0.12-1.23,1.64-1.38,2.56-1.41c1.95-0.06,1.24-2.07-0.37-2.15c-0.58-0.02-1.09-0.06-1.64-0.24
      c-0.76-0.25-1.09-1.1-0.96-1.77c0.08-0.38,0.43-0.61,0.75-0.8c0.86-0.53,2.02-1.04,2.49-1.93c1.3-2.45-3.82-2.93-7.3-2.52
      c-3.48,0.41-3.45,2.59-1.89,3.41c1.52-0.04,5.37-1.89,5.57-2.04c0.2-0.15,0.29,0.57-0.6,1.2c-1.13,0.81-2.36,1.44-4.45,1.93
      c-1.58,0.37-4.26,0.84-5.72-0.2c-1.39-0.99-0.99-4.58-0.36-5.84c0.63-1.27,1.57-2.08,2.5-3.15c1.71-1.97,0.7-5.57-1.73-5.25
      c0.17,1.26-0.04,2.67-0.69,3.76c-0.83,1.39-3.25,2.23-2.74,4.24c1.06,4.17-3.5,11.42-3.73,12.54c-0.23,1.12-0.13,5.41,1.5,6.87
      c1.22,1.08,4.05,0.61,5.57,0.66c2.69,0.1,5.35,1.01,8.02,1.34c0.89,0.11,5.33,0.02,3.11-1.48
      C745.57,292.91,744.76,292.26,744.95,291.52z"/>
    <path fill="#E3B395" d="M739.92,278.73c1.26-0.03,4.11-1.31,5.17-1.83c-1.09-0.85-2.58-0.72-3.89-0.45
      c-0.7,0.15-1.66,0.32-2.28,0.8C738.91,277.84,739.28,278.4,739.92,278.73z"/>
    <path fill="#88B7D3" d="M772.92,293.27c-1.32-6.4-2.53-12.81-3.81-19.21c-1.26-6.32-2.67-12.6-4.34-18.83
      c-0.77-2.89-2.92-12.82-4.36-17.96c-5.73-1.57-12.83-2.01-22.38,0.51c-1.89,0.52-3.58,1.19-4.4,1.49
      c-11.08,3.64-19.33,12.53-23.04,22.16c-1.28,3.32-1.96,6.79-2.27,10.3c-0.18,2.07-0.11,4.15-0.3,6.22
      c-0.03,0.35-0.07,0.71-0.1,1.06c1.85-3.39,4.21-6.54,6.3-9.8c2.67-4.17,2.92-7.37,6.27-11.19c1.4-1.59,2.01-1.32,1.91-1.19
      c-2.82,3.57-4.07,7.76-6.19,11.66c-1.88,3.45-4.33,6.6-6.37,9.96c-0.99,1.64-2.18,3.78-2.91,5.54c-0.73,1.76-2.8,6.26-3.84,8.43
      c-1.05,2.17,0.1,4.59,1.17,4.52c1.06-0.07,2.02-0.65,2.75-1.1c0.55-0.34,0.01,0.92-0.81,1.49c3.1,0.27,6.4-1.24,9.23-2.17
      c3.85-1.26,7.82-2.21,11.76-3.2c-0.5-1.63-0.32-3.68-0.12-5.36c-3.04,0.46-17.44,3.54-20.71,6.05c-0.24,0.18-0.35-0.96,1.89-2.15
      c-0.49,0.03-1.51,0.1-1.97,0.4c-0.27,0.18-0.08-1.27,2.11-1.25c0.67-2.19,1.97-4.03,3.36-5.89c1.92-2.58,3.68-5.3,5.14-8.13
      c2.94-5.69,6.83-10.75,8.77-16.86c0.19-0.61,0.71,0.98,0.01,2.9c-2.04,5.59-4.24,8.05-7.15,13.2c-1.47,2.62-3.05,5.83-4.85,8.28
      c-1.69,2.3-3.15,4.7-3.99,6.7c1.55-0.58,13.27-3.81,17.49-4.45c1.8-3.89,3.6-7.42,3.19-11.87c-0.12-1.33,0.58-2.28,1.62-3.13
      c2.35-1.93,2.61-3.62,2.19-5.32c-0.06-0.23,0-0.51,0.42-0.6c0.63-0.13,1.12-0.15,1.66-0.01c1.99,0.62,2.48,1.75,2.67,2.9
      c0.3,1.91-1.26,4.22-2.22,5.42c-0.24,0.27-0.33,0.38-0.58,0.69c-1.14,1.35-1.06,3.01-0.6,4.58c0.23,0.78,0.79,0.92,1.52,0.92
      c0.35-0.01,0.98-0.05,1.65-0.11c-2.32-1.95-0.36-4.79,5.37-4.53c1.7,0.08,4.22,0.33,5.62,1.45c0.96,0.77,0.6,1.99,0.02,2.98
      c-0.3,0.51-0.86,1.27-1.41,1.58c-0.25,0.15-0.44,0.22-0.73,0.31c-0.82,0.26,0.11,0.8,0.61,0.83c1.07,0.07,2.52,0.08,2.84,1.32
      c0.24,0.95,0.23,2.36-1.13,2.53c-0.4,0.05-2.32,0.08-2.28,0.91c0.04,0.83,1.37,1.08,1.94,1.59c0.97,0.87,1.08,2.25,0.42,2.96
      c-0.73,0.79-1.79,0.52-2.79,0.41c-0.54-0.06-1.21,0.74-0.5,1.07c1.02,0.48,1.14,0.88,1.34,1.29c0.64,1.32-0.29,1.92-1.56,2.17
      c-0.96,0.19-2.38,0.35-4.79-0.02c-2.41-0.36-4.52-0.66-5.52-0.77c-1.25-0.14-3.03-0.26-5.1-0.18c-1.8,0.07-2.86-0.85-3.46-1.92
      c-4.51,1.17-9,2.37-13.43,3.79c-1.39,0.45-3,1.08-4.63,1.46c0.49,3.5,1.97,8.31,3.09,10.59c0.79,1.61,2.34,3.84,1.94,5.68
      c4.92-0.01,9.8-0.13,14.67-0.18c1.62-0.02,11.4-0.02,15.76-0.09s10.68-0.4,13.19-0.6c4.17-0.34,8.33-0.88,12.47-1.43
      c0.2-1.2,1.44-1.83,2.21-1.8c0.55-1.11,1.41-2.72,2.1-3.98C774.57,301.85,773.82,297.69,772.92,293.27z"/>
    <path fill="#5D98C3" d="M736.1,273.5c-1.14,1.35-1.06,3.01-0.6,4.58c0.23,0.78,0.79,0.92,1.52,0.92c0.04,0,0.08,0,0.12,0
      c0.06-1.69,0.42-3.36,1.02-4.84c0.83-2.04,4.02-7.84,0.4-7.91c0.18,0.37,0.28,0.76,0.35,1.16c0.3,1.91-1.26,4.22-2.22,5.42
      C736.44,273.09,736.35,273.2,736.1,273.5z"/>
    <path fill="#C2AC56" d="M784.04,311.92c-0.16-0.68-1.12-1.05-2.08-0.81c-0.96,0.24-1.1,0.91-0.81,1.53
      c0.29,0.62,1.16,0.74,1.86,0.61C783.71,313.12,784.2,312.6,784.04,311.92z"/>
    <path fill="#CA000F" d="M782.07,310.21c-1.09-1.63-2.48-3.62-3.45-4.45c-0.5-0.01-1.58,0.27-1.94,0.53
      c1.34,1.42,2.77,3.06,3.7,4.89C780.62,310.74,781.29,310.27,782.07,310.21z"/>
    <path fill="#88B7D3" d="M776.28,311.38c0.82,0.01,2.37-0.08,3.17-0.26c-0.27-0.58-1.36-2-1.69-2.4
      C777.32,309.38,776.58,310.61,776.28,311.38z"/>
    <path fill="#CA000F" d="M777.14,307.99c-0.34-0.42-0.81-0.95-1.14-1.39c-0.84,1.29-1.85,3.34-2.17,4.01
      c0.63,0.15,1.05,0.51,1.36,0.72C775.18,311.33,776.78,308.57,777.14,307.99z"/>
    <path fill="#C2AC56" d="M774.13,311.64c-1.17-0.7-2.72,0.05-2.59,1.36c0.13,1.31,1.19,1.48,2.32,1.1
      C774.97,313.73,775.3,312.34,774.13,311.64z"/>
    <path fill="#CA000F" d="M761.54,237.6c1.47,5.75,2.88,11.76,4.41,17.48c1.74,6.49,3.14,13.05,4.45,19.62
      c1.26,6.39,2.48,12.78,3.78,19.17c0.8,3.89,1.45,7.75,1.8,11.69c0.92-0.4,2.4-0.57,2.52-0.6c-0.65-10.38-2.48-20.64-4.75-30.82
      c-2.61-11.64-5.48-23.42-8.45-34.99C764.11,238.53,762.84,238.02,761.54,237.6z"/>
    <path fill="#9D000F" d="M781.3,309.08c0.27,0.38,0.53,0.77,0.77,1.13c-0.77,0.06-1.45,0.53-1.7,0.98
      c-0.17-0.33-0.36-0.65-0.55-0.97C780.29,309.82,780.8,309.45,781.3,309.08z"/>
    <path fill="#9D000F" d="M774.85,308.58c0.36-0.68,0.77-1.41,1.14-1.98c0.33,0.44,0.8,0.97,1.14,1.39
      c-0.13,0.22-0.44,0.73-0.76,1.29C775.88,309.02,775.37,308.78,774.85,308.58z"/>
    <path fill="#9D000F" d="M775.64,310.55c-0.27,0.45-0.46,0.78-0.46,0.78c-0.31-0.21-0.73-0.57-1.36-0.72
      c0.09-0.18,0.22-0.46,0.39-0.79C774.69,310.06,775.16,310.3,775.64,310.55z"/>
    <path fill="#9D000F" d="M778.36,302.89c0.05,0.69,0.1,1.38,0.15,2.08c-0.12,0.02-1.6,0.2-2.52,0.6
      c-0.07-0.76-0.15-1.52-0.24-2.28C776.64,303.25,777.53,303.13,778.36,302.89z"/>
    <path fill="#9D000F" d="M769.76,265.75c-0.3,0.49-0.6,0.98-0.9,1.47c-0.88-4.06-1.83-8.12-2.9-12.14
      c-1.53-5.72-2.94-11.73-4.41-17.48c1.3,0.42,2.58,0.93,3.76,1.56c2.02,7.85,3.98,15.8,5.85,23.74
      C770.68,263.89,770.26,264.92,769.76,265.75z"/>
    <path fill="#FFD4B8" d="M812.3,281.35c0.44,3.7,3.46,6.92,5.73,7.67c3.15,1.05,7.39-0.27,10.5-1.33
      c0.59,1.79,3.5,5.96,4.37,6.33c0.87,0.36,1.18-0.58,0.52-1.95c-0.66-1.36-2.63-3.88-3.74-4.95c-0.23-0.22,0.15-0.55,0.46-0.58
      c1.66-0.14,3,0.5,4.35,1.34c1.06,0.65,2.65,1.78,3.76,2.36c1.6,0.85,3.33,0.6,2.28-1.12c-1.76-2.87-6.87-3.68-10.17-4.57
      c-0.79,1.57-2.55,2.07-2.31,1.69c1.73-2.72,2.49-7.65-0.59-9.87c-0.21-0.16,0.87-0.18,1.71,0.2c0.93-1.41,3.1-1.85,4.66-2.38
      c2.51-0.85,3.19-0.54,4.93-1.71c1.74-1.17,1.85-3.45,0.17-3.06c-2.21,0.52-4.05,1.58-5.96,2.69c-3.7,2.15-6.36,2.86-10.28,4.63
      c1.83,2.9,1.24,7.06-1.7,7.49c-0.53,0.08-1.55-0.42-1.03-0.53c2.23-0.51,2.73-3.24,2.14-4.97c-1.43-4.17-5.78-2.32-5.42-3.69
      c0.7-2.63,3.49-8.78-0.72-9.39c0,0.96,0.18,1.95-0.01,2.89c-0.67,3.29-2.71,3.53-3.04,4.71c-0.33,1.18,0.2,2.79-0.09,4.06
      C812.68,277.92,812.06,279.29,812.3,281.35z"/>
    <path fill="#E3B395" d="M745.27,294.74c-0.86,0.11-1.83,0.1-2.2,0.05c-2.67-0.33-5.33-1.24-8.02-1.34
      c-1.18-0.04-3.16,0.23-4.55-0.15C735.27,291.5,740.79,292.32,745.27,294.74z"/>
    <path fill="#E3B395" d="M825.21,275.66c1.05,1.14,1.74,2.5,1.51,4.14c-0.35,2.61-2.42,4.8-4.89,4.17
      c2.19-1.04,2.49-4.63,0.86-7.22C823.59,276.33,824.42,275.99,825.21,275.66z"/>
    <path fill="#88B7D3" d="M788.31,276.11c2.3,1.64,4.74,3.16,7.35,4.37c5.35,2.49,10.22,5.16,16.23,4.57
      c-0.44-0.95-1.18-2.43-1.18-3.79c-1.85-0.92-4.61-0.69-6.57-1.26c-4.19-1.21-8.24-2.81-11.86-5.13c-2.71-1.74-4.95-3.97-6.69-6.57
      c-0.98-1.47-3.65-6-4.12-6.84c0.61,2.28,2.11,8.97,2.34,10.2C785.14,273.33,786.46,274.79,788.31,276.11z"/>
    <path fill="#88B7D3" d="M787.44,304.13c-0.35-3.1-0.55-6.19-1-9.28c-1.02-7.02-2.53-14-3.74-21
      c-1.93-11.11-6.34-28.26-16.05-34c2.86,11.25,5.65,22.79,8.21,34.1c2.39,10.56,4.26,20.7,4.79,31.49c1.31,1.36,2.68,3.61,3.47,4.6
      c0.33,0.04,1.05,0.4,1.4,0.73c1.52-0.18,2.84-0.38,4.33-0.71C787.92,308.21,787.66,306.14,787.44,304.13z"/>
    <path fill="#5D98C3" d="M726.9,294.88c-3.02,0.01-5.43,0.9-8.24,1.86c-2.96,1.01-5.88,2.11-8.88,3.02
      c-0.11-0.55-0.2-1.09-0.27-1.6c1.63-0.38,3.24-1.01,4.63-1.46c4.43-1.42,8.92-2.62,13.43-3.79c0.6,1.07,1.66,1.99,3.46,1.92
      c1.03-0.04,1.99-0.03,2.85,0.01C731.6,295.19,729.23,294.87,726.9,294.88z"/>
    <path fill="#5D98C3" d="M724.84,314.3c-3.42,0.05-6.85,0.11-10.3,0.11c0.04-0.17,0.05-0.34,0.06-0.52
      C717.95,313.47,721.4,313.89,724.84,314.3z"/>
    <path fill="#5D98C3" d="M709.65,289.87c0.84-2,2.3-4.4,3.99-6.7c1.8-2.45,3.38-5.67,4.85-8.28c2.32-4.12,4.2-6.52,5.9-10.19
      c-0.24,0.91-0.53,1.81-0.9,2.68c-1.13,2.64-2.84,4.94-4.15,7.48c-1.25,2.41-1.96,5.11-3.48,7.39c-1.43,2.14-3.15,4.15-3.9,6.61
      c-0.03,0.11-0.01,0.2,0.03,0.27C710.84,289.48,710,289.74,709.65,289.87z"/>
    <path fill="#5D98C3" d="M720.57,257.93c-0.03,0.04-0.06,0.07-0.09,0.1c-3.35,3.82-3.6,7.02-6.27,11.19
      c-2.09,3.26-4.45,6.41-6.3,9.8c0.04-0.35,0.07-0.71,0.1-1.06c0.09-1.02,0.12-2.05,0.15-3.07c1.83-2.56,3.89-5,5.67-7.52
      C715.56,264.91,717.36,259.62,720.57,257.93z"/>
    <path fill="#5D98C3" d="M740.81,237.12c8.17-1.75,14.44-1.27,19.6,0.15c1.44,5.14,3.59,15.06,4.36,17.96
      c1.11,4.15,2.1,8.34,3.02,12.54c-0.42,0.64-0.85,1.27-1.33,1.88c-2.07,2.63-5.23,5.09-8.98,4.93c-2.63-0.11-4.88-1.45-6.61-3.24
      c-3.62-3.75-5.77-8.25-6.44-12.06C743.75,255.47,741.38,241.01,740.81,237.12z"/>
    <path fill="#5D98C3" d="M773.5,306.08c0.22-1.51,0.04-2.96-0.2-4.45c-0.35-2.24-0.49-4.5-0.7-6.76
      c-0.43-4.53-1.67-8.92-2.16-13.44c-0.04-0.42-0.08-0.84-0.12-1.26c0.85,4.37,1.7,8.74,2.6,13.1c0.91,4.42,1.65,8.58,2.02,13.07
      c-0.69,1.26-1.54,2.87-2.1,3.98c-0.37-0.02-0.86,0.13-1.28,0.41C772.33,309.27,773.26,307.69,773.5,306.08z"/>
    <path fill="#5D98C3" d="M751.6,289.1c0.74,1.31-0.36,1.94-1.16,2.51c-0.56,0.4-1.13,0.8-1.51,1.36
      c-0.38,0.55-0.49,1.08-0.73,1.53c0.02-0.25-0.04-0.54-0.2-0.88c-0.2-0.41-0.32-0.82-1.34-1.29c-0.7-0.33-0.04-1.13,0.5-1.07
      c1,0.11,2.05,0.38,2.79-0.41c0.66-0.71,0.55-2.1-0.42-2.96c-0.58-0.52-1.91-0.76-1.94-1.59c-0.04-0.83,1.88-0.86,2.28-0.91
      c1.35-0.17,1.37-1.58,1.13-2.53c-0.31-1.24-1.76-1.25-2.84-1.32c-0.51-0.03-1.43-0.57-0.61-0.83c0.28-0.09,0.47-0.16,0.73-0.31
      c0.23-0.13,0.46-0.34,0.67-0.58c1.4,0.5,2.91,1.15,3.5,2.36c0.53,1.08-0.71,1.86-1.35,2.59c-0.73,0.83-1.31,2.2-0.52,3.16
      C750.89,288.29,751.36,288.68,751.6,289.1z"/>
    <path fill="#5D98C3" d="M777.27,311.35c-0.37,0.02-0.72,0.03-0.99,0.02c0.3-0.76,1.04-2,1.48-2.66
      c0.12,0.14,0.33,0.41,0.57,0.73C777.97,310.08,777.6,310.71,777.27,311.35z"/>
    <path fill="#5D98C3" d="M809.62,285.12c-0.18-0.38-0.33-0.76-0.45-1.15c-0.33-1.09-0.35-2.18-0.3-3.27
      c0.66,0.12,1.29,0.29,1.84,0.57c0,1.36,0.73,2.85,1.18,3.79C811.11,285.12,810.36,285.15,809.62,285.12z"/>
    <path fill="#5D98C3" d="M785.59,268.31c0.15,0.22,0.3,0.43,0.46,0.64c-0.08,1.92,0.09,3.85,0.49,5.71
      c-1-0.93-1.87-1.92-2.74-3c-0.22-1.22-1.73-7.91-2.34-10.2C781.94,262.31,784.61,266.84,785.59,268.31z"/>
    <path fill="#5D98C3" d="M772.07,261.06c-0.05,0.07-0.1,0.14-0.16,0.21c-1.7-7.17-3.47-14.35-5.27-21.42
      c4.68,2.76,8.12,8.17,10.65,14.27C776.34,256.71,773.65,259.05,772.07,261.06z"/>
    <path fill="#5D98C3" d="M788.23,308.52c0.16,0.52,0.36,1.04,0.6,1.53c-1.49,0.33-2.81,0.54-4.33,0.71
      c-0.08-0.08-0.19-0.16-0.3-0.23C785.62,309.99,787,309.36,788.23,308.52z"/>
    <path fill="#39747A" d="M799.99,385.04c-1.99-2.92-5.97-4.2-9.55-5.01c-5.72,0.36-8.13,2.46-8.13,4
      C788.15,385.47,793.89,386.16,799.99,385.04z"/>
    <path fill="#56928D" d="M778.61,383.04c0.74,0.21,1.49,0.42,2.22,0.62c0.59-2.6,4.6-3.67,7.69-4.17
      c-3.02-0.57-4.84-1.01-7.88-1.38c-4.51,0.76-13.65,0.98-16.02,0.83C769.26,380.37,773.93,381.68,778.61,383.04z"/>
    <path fill="#39747A" d="M748.02,386.76c5.78,1.82,12.34,3.8,18.36,1.8c-1.51-2.47-5.12-4.17-10.64-6.12
      c-3.67,0.03-8.07,0.23-8.91,3.94C747.22,386.52,747.62,386.64,748.02,386.76z"/>
    <path fill="#1E6268" d="M792.33,383.84c1.97,0.01,4.4,0.25,6.53-0.11c0.42,0.4,0.81,0.83,1.14,1.31
      c-6.11,1.12-11.84,0.43-17.69-1.01c0-0.49,0.24-1.03,0.75-1.55C786.1,383.17,789.19,383.83,792.33,383.84z"/>
    <path fill="#1E6268" d="M765.82,387.8c0.21,0.25,0.4,0.5,0.56,0.77c-6.02,2-12.58,0.02-18.36-1.8
      c-0.4-0.13-0.8-0.25-1.19-0.37c0.13-0.58,0.35-1.06,0.64-1.48C753.37,387.05,759.47,388.38,765.82,387.8z"/>
    <path fill="#56928D" d="M745.35,385.93c1.48-3.18,5.12-3.9,8.71-3.97c-2.27-0.75-4.59-1.37-6.94-1.74
      c-4.74,1.07-9.54,1.71-14.4,2.05C736.97,383.36,741.17,384.62,745.35,385.93z"/>
    <path fill="#357D77" d="M779.05,379.37c-1.75,0.6-3.45,1.34-4.87,2.39c-3.2-0.92-6.39-1.84-9.56-2.82
      c2.37,0.15,11.51-0.07,16.02-0.83c0.74,0.09,1.41,0.18,2.05,0.28C781.48,378.63,780.27,378.95,779.05,379.37z"/>
    <path fill="#357D77" d="M741,384.6c-2.75-0.82-5.5-1.61-8.29-2.32c4.85-0.34,9.66-0.98,14.4-2.05
      c0.86,0.14,1.73,0.31,2.58,0.51C746.47,381.37,743.33,382.61,741,384.6z"/>
    <path fill="#7F544A" d="M725.39,380.79c8.37,0.01,16.76-0.76,24.88-2.68c1.43-3.15,1.96-9.96,2.33-13.49
      c0.46-4.55,1.53-8.96,2.38-13.45c1.03-5.4,1.5-13.7,8.53-15.15c0.19-0.04,0.33,0.25,0.15,0.35c-6.18,3.51-6.8,10.9-8.04,16.84
      c-1.04,4.97-1.74,9.81-1.82,14.88c-0.04,2.44-0.19,5-0.81,7.43c5.25,0.86,10.45,1.81,15.82,1.86c2.38,0.02,11.09-0.36,14.63-1.19
      c-0.08-12.6,2.64-24.24,4.25-36.69c0.68-5.3,1.41-10.57,2.53-15.8c0.58-2.7,1.2-5.39,1.75-8.1c0.44-2.16,1.18-6.53,0.8-7
      c-0.39-0.47-2.14,0.22-3.05,0.62c0.07,0.19,0.14,0.38,0.22,0.56c0.58-0.15,1.17-0.32,1.75-0.5c0.53-0.18-0.31,0.92-0.83,1.13
      c-1.97,0.77-3.87,1.25-5.77,1.58c0.23,1.19-1.15,2.13-2.24,2.18c-1.53,0.07-2.65-0.54-2.78-1.57c-1.42,0.12-2.75,0.23-4.24,0.37
      c0.02,1.68-1.63,2.34-2.91,2.31c-1.04-0.02-2.05-0.52-2.28-1.72c-4.09,0.51-8.14,1.03-12.22,1.31c-2.23,0.15-14.68,0.64-18.33,0.61
      c-3.65-0.03-10.71-0.02-12.77,0.07c-4.53,0.2-9.05,0.44-13.59,0.43c-0.95,0-0.1-0.87-0.44-2.15c-1.38,0.98-1.51,3.29-1.68,4.74
      c-0.24,2.06-0.17,4.14-0.16,6.21c0.02,5.63-1.18,11.29-2.04,16.84c-0.96,6.17-1.13,12.46-2.35,18.58
      c-0.59,3.01-1.41,5.98-2.16,8.96c-0.67,2.66-2.13,5.26-2.21,8.03C710.28,378.62,717.58,380.78,725.39,380.79z"/>
    <path fill="#6D3D32" d="M785.3,311.95c1.42,4.6-5.16,3.32-7.43,0.83c0.73-0.06,1.46-0.12,2.2-0.19
      c0.13,1.02,1.25,1.64,2.78,1.57c1.09-0.05,2.47-0.99,2.24-2.18C785.16,311.97,785.23,311.96,785.3,311.95z"/>
    <path fill="#6D3D32" d="M765.77,314.15c1.62-0.19,3.24-0.39,4.88-0.6c0.23,1.19,1.24,1.69,2.28,1.72
      c1.28,0.03,2.92-0.62,2.91-2.31c0.32-0.03,0.64-0.06,0.95-0.09C776.88,318.26,769.14,316.96,765.77,314.15z"/>
    <path fill="#6D3D32" d="M779.14,353.09c0.13-2.95,4.36-2.79,6.56-3.47c0.18-0.06,0.35-0.12,0.52-0.19
      c-0.61,3.86-1.23,7.69-1.72,11.54c-0.07-0.07-0.15-0.14-0.22-0.21C782.4,358.9,779.01,355.91,779.14,353.09z"/>
    <path fill="#6D3D32" d="M708.27,351.54c1.91,3.97,4.61,7.64,7.64,11.12c0.94,1.08,2.1,2.8,0.58,3.95
      c-0.66,0.5-1.4,0.91-2.17,1.27c-1.53,0.71-3.15,1.38-4.78,1.87c-1.69,0.51-3.41,0.85-5.15,1.1c0.2-0.57,0.38-1.14,0.52-1.72
      c0.75-2.98,1.57-5.95,2.16-8.96C707.64,357.33,707.97,354.44,708.27,351.54z"/>
    <path fill="#6D3D32" d="M747.94,357.76c-0.79-3.31,3.77-2.72,6.3-2.87c-0.55,2.68-1.08,5.35-1.45,8.06
      C750.99,361.57,748.46,359.9,747.94,357.76z"/>
    <g>
      <path d="M723.15,212.84c0.38-0.39,1.03-0.56,1.6-0.56c0.27,0,0.55,0.03,0.8,0.09c1.1,0.23,1.58,1.27,2.37,1.88
        c0.15,0.1,0.31,0.18,0.48,0.25c0.1,0.03,0.21,0.05,0.31,0.06c0.25,0.01,0.5,0,0.75-0.01c0.46-0.03,0.92-0.05,1.37,0.1
        c0.51,0.18,0.95,0.5,1.27,0.89c0.57,0.7,0.32,1.72,0.41,2.53c0.05,0.27,0.13,0.53,0.24,0.79c0.13,0.27,0.29,0.51,0.48,0.75
        c0.26,0.29,0.56,0.55,0.88,0.79c0.82,0.55,1.75,0.94,2.69,1.3c0.49,0.18,0.98,0.36,1.47,0.54c-0.81-0.58-1.58-1.18-2.25-1.89
        c-0.57-0.61-1.01-1.3-1.32-2.04c-0.32-0.76-0.4-1.58-0.73-2.33c-0.11-0.22-0.23-0.41-0.39-0.6c-0.2-0.22-0.42-0.58-1-0.78
        c-0.58-0.21-2.35-0.68-3.12-1.17c-0.77-0.49-1.92-1.27-1.86-2.74c0-0.1,0.2,0.62,0.73,1.25c0.66,0.79,1.28,1.21,2.59,1.67
        c0.52,0.18,1.53,0.38,2.04,0.6c0.5,0.21,0.95,0.71,1.25,1.11c0.68,0.91,0.7,2.12,1.14,3.13c0.24,0.48,0.53,0.91,0.88,1.33
        c0.76,0.87,1.56,1.59,2.42,2.29c-0.55-0.93-1.01-1.88-1.42-2.85c-0.37-0.68-0.69-1.39-1.02-2.1c-0.43-0.91-0.9-1.82-1.55-2.62
        c-0.27-0.31-0.56-0.58-0.9-0.82c-0.35-0.23-0.72-0.4-1.11-0.56c-0.85-0.32-1.74-0.58-2.53-1.02c-0.62-0.36-1.1-0.93-1.48-1.48
        c-0.66-0.97-1.25-2.57-0.16-3.47c0.39-0.32,0.87-0.47,1.35-0.47c0.8,0,1.59,0.42,1.94,1.12c0.51,1.02-0.17,2-1.28,1.8
        c-0.24-0.04-0.28-0.22-0.04-0.19c0.02,0,0.05,0.01,0.08,0.01c0.1,0,0.2,0.01,0.31,0c0.08-0.01,0.16-0.03,0.24-0.05
        c0.06-0.02,0.12-0.05,0.17-0.08c0.05-0.04,0.09-0.08,0.14-0.12c0.04-0.05,0.08-0.1,0.11-0.16c0.04-0.08,0.06-0.16,0.08-0.25
        c0.01-0.12,0.02-0.24,0-0.36c-0.02-0.16-0.07-0.3-0.13-0.45c-0.07-0.13-0.14-0.24-0.23-0.36c-0.1-0.1-0.2-0.2-0.31-0.29
        c-0.11-0.07-0.21-0.12-0.33-0.17c-0.12-0.04-0.25-0.07-0.37-0.09c-0.15-0.02-0.29-0.02-0.44,0c-0.21,0.03-0.4,0.07-0.59,0.13
        c-0.13,0.05-0.24,0.11-0.35,0.18c-0.09,0.07-0.18,0.14-0.26,0.23c-0.07,0.09-0.12,0.18-0.17,0.27c-0.04,0.11-0.08,0.23-0.1,0.35
        c-0.03,0.21-0.02,0.42,0,0.63c0.06,0.35,0.18,0.69,0.33,1.02c0.21,0.42,0.46,0.8,0.77,1.17c0.24,0.26,0.49,0.5,0.78,0.72
        c0.39,0.26,0.84,0.45,1.28,0.62c1.21,0.45,2.37,0.79,3.27,1.7c0.67,0.67,1.15,1.5,1.56,2.35c-0.83-3.15-0.98-6.45-0.53-9.75
        c0.37-2.65,1.13-5.13,2.49-7.51c1.34-2.35,3.16-4.51,5.32-6.3c2.05-1.71,4.51-3.09,7.24-3.74c1.05-0.25,2.13-0.35,3.21-0.35
        c1.26,0,2.53,0.14,3.75,0.38c3.86,0.76,7.17,2.84,9.69,5.52c1.21,1.29,2.24,2.69,3.15,4.17c0.05-0.21,0.1-0.43,0.13-0.64
        c0.09-0.61,0.1-1.23,0.02-1.85c-0.09-0.54-0.25-1.06-0.47-1.57c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0
        c-0.28-0.58-0.64-1.12-1.07-1.62c-0.57-0.64-1.22-1.2-1.94-1.71c-1.29-0.88-2.72-1.57-4.2-2.13c-2.67-0.99-5.49-1.6-8.35-1.95
        c-2.18-0.26-4.42-0.39-6.61-0.14c-1.45,0.18-2.87,0.55-4.22,1.05c-1.2,0.46-2.67,1.39-3.25,2.09c-1.15,1.35-2.97,4.33-3.5,5.34
        c-0.46,0.87-0.97,1.75-1.88,1.48c-0.7-0.2-1.07-0.88-0.87-1.85c0.13-0.59,0.58-0.96,1.12-0.89c0.33,0.04,0.43,0.2,0.27,0.18
        c-1.47-0.24-1.42,2.16-0.42,2.27c0.8,0.09,1.07-0.62,1.39-1.18c0.32-0.55,1.2-2.05,1.71-3.02c0.51-0.98,1.63-2.47,2.5-3.18
        c2.04-1.63,4.34-2.37,7.24-2.61c4.48-0.37,8.68,0.45,11.46,1.14c3.14,0.77,6.36,1.96,8.76,4c2.22,1.89,3.1,4.45,2.38,7.11
        c1.07,1.79,1.96,3.67,2.73,5.57c0.38,0.92,0.72,1.86,1.04,2.79c0.36,0.25,0.71,0.5,1.07,0.75c0.77,0.56,0.64,1.72,0,2.29
        c-0.03,0.02-0.06,0.05-0.09,0.07c0.56,1.96,1.05,3.95,1.48,5.93c0.27,1.23,0.51,2.45,0.74,3.69c0.01-0.06,0.03-0.12,0.03-0.18
        c0.1-0.83,0.06-1.64-0.05-2.47c-0.38-2.46-1.59-5.34-0.36-7.75c0.91-1.78,2.51-3.21,3.38-5.02c0.15-0.34,0.27-0.69,0.34-1.05
        c0.02-0.21,0.03-0.42,0-0.63c-0.03-0.15-0.07-0.28-0.13-0.42c-0.08-0.16-0.18-0.31-0.3-0.45c-0.15-0.16-0.32-0.3-0.5-0.43
        c-0.27-0.18-0.56-0.33-0.87-0.45c-0.21-0.07-0.42-0.13-0.64-0.16c-0.18-0.02-0.34-0.02-0.52,0c-0.12,0.02-0.24,0.05-0.36,0.09
        c-0.11,0.04-0.2,0.09-0.3,0.15c-0.1,0.08-0.2,0.16-0.28,0.25c-0.07,0.09-0.12,0.17-0.18,0.27c-0.06,0.13-0.1,0.27-0.12,0.41
        c-0.01,0.13-0.01,0.25,0.01,0.37c0.02,0.13,0.06,0.24,0.11,0.36c0.05,0.09,0.11,0.18,0.17,0.26c0.08,0.08,0.15,0.15,0.24,0.22
        c0.11,0.07,0.21,0.12,0.33,0.17c0.15,0.05,0.29,0.08,0.44,0.11c0.12,0.01,0.23,0.01,0.35,0c0.07-0.01,0.13-0.03,0.19-0.05
        c0.05-0.02,0.1-0.05,0.15-0.07c0.04-0.03,0.08-0.06,0.11-0.1c0.05-0.06,0.08-0.11,0.11-0.18c0.01-0.04,0.03-0.07,0.03-0.11
        c0.01-0.05,0.01-0.1,0-0.14c-0.01-0.04-0.02-0.09-0.04-0.13c-0.02-0.04-0.05-0.08-0.08-0.12c-0.03-0.04-0.07-0.07-0.11-0.1
        c-0.05-0.03-0.1-0.07-0.16-0.09c-0.27-0.08-0.47-0.06-0.82,0.18c-0.16,0.11,0.07-0.57,0.7-0.5c0.67,0.07,1.14,0.85,0.59,1.37
        c-0.59,0.56-1.59,0.31-2.15-0.16c-1.17-0.98-0.35-2.81,1.34-2.76c0.91,0.03,1.96,0.55,2.49,1.18c0.53,0.62,0.6,1.35,0.4,2.1
        c-0.5,1.91-2.19,3.47-3.28,5.12c-0.18,0.27-0.34,0.55-0.48,0.84c-0.15,0.33-0.24,0.66-0.3,1.01c-0.12,0.88-0.07,1.75,0.06,2.62
        c0.32,1.97,0.96,3.94,0.81,5.93c0.34-0.92,0.68-1.83,0.84-2.79c0.29-2.2-0.72-4.41-0.11-6.6c0.39-1.36,1.65-2.04,3.08-2.04
        c0.22,0,0.44,0.02,0.66,0.05c1.35,0.19,2.78,1.08,2.6,2.45c-0.11,0.8-0.73,1.25-1.52,1.22c-0.89-0.03-1.9-0.83-1.33-1.8
        c0.36-0.62,1.35-0.37,1.28,0.3c-0.03,0.25-0.41,0.23-0.3,0.08c0.1-0.14,0.11-0.38-0.16-0.45c-0.27-0.07-0.48,0.04-0.58,0.26
        c-0.17,0.39-0.08,0.67,0.18,0.91c0.26,0.25,0.54,0.34,0.87,0.4c0.35,0.06,0.62-0.03,0.89-0.25c0.24-0.19,0.36-0.47,0.4-0.91
        c0.03-0.36-0.26-0.82-0.35-0.94c-0.13-0.14-0.26-0.26-0.41-0.38c-0.24-0.16-0.48-0.28-0.75-0.39c-0.33-0.12-0.66-0.2-1.01-0.25
        c-0.35-0.04-0.68-0.04-1.02,0c-0.27,0.04-0.52,0.1-0.77,0.19c-0.2,0.08-0.39,0.18-0.57,0.3c-0.15,0.11-0.28,0.23-0.39,0.36
        c-0.14,0.18-0.25,0.36-0.35,0.56c-0.13,0.32-0.22,0.65-0.28,0.99c-0.22,1.66,0.29,3.27,0.32,4.92c0.03,1.47-0.54,2.86-1.06,4.24
        c0.15-0.07,0.3-0.15,0.44-0.24c0.17-0.12,0.32-0.26,0.45-0.41c0.29-0.34,0.5-0.72,0.69-1.11c0.56-1.23,0.93-2.85,2.44-3.43
        c0.34-0.13,0.73-0.2,1.12-0.2c0.5,0,0.98,0.11,1.37,0.37c0.67,0.42,0.68,1.37,0.12,1.91c-0.43,0.4-1.22,0.1-0.62-0.07
        c0.33-0.09,0.43-0.32,0.42-0.3c0.05-0.13,0.33-0.63-0.07-1.06c-0.24-0.26-0.72-0.36-0.89-0.38c-0.24-0.02-0.47-0.02-0.7,0.01
        c-0.19,0.03-0.37,0.07-0.55,0.13c-0.18,0.07-0.33,0.15-0.49,0.25c-0.15,0.12-0.29,0.24-0.42,0.38c-0.25,0.31-0.44,0.65-0.61,1
        c-0.53,1.17-0.94,2.53-2.04,3.29c1.08,0.14,2.69-0.29,3.53-0.6c0.28-0.11,0.54-0.23,0.79-0.39c0.09-0.07,0.18-0.14,0.26-0.23
        c0.05-0.06,0.09-0.12,0.13-0.19c0.02-0.05,0.03-0.1,0.05-0.15c0.01-0.07,0.01-0.13,0-0.2c-0.02-0.06-0.04-0.12-0.06-0.18
        c-0.05-0.09-0.1-0.17-0.16-0.26c-0.05-0.06-0.1-0.11-0.16-0.15c-0.03-0.02-0.07-0.04-0.11-0.06c-0.05-0.02-0.11-0.04-0.17-0.05
        c-0.04,0-0.07,0-0.11,0c0.01,0,0.03,0,0.03-0.01c-0.07,0.01-0.13,0.02-0.19,0.04c-0.03,0.01-0.06,0.03-0.09,0.05
        c-0.05,0.03-0.09,0.07-0.12,0.11c-0.02,0.03-0.04,0.05-0.05,0.08c-0.02,0.06-0.04,0.11-0.05,0.17c0,0.04,0,0.08,0,0.12
        c0-0.01,0-0.01,0-0.02c0.01,0.04,0.02,0.29,0.22,0.53c0.09,0.11-0.36-0.05-0.44-0.35c-0.15-0.57,0.29-0.91,0.73-0.91
        c0.3,0,0.62,0.15,0.83,0.48c0.4,0.61-0.01,1.16-0.6,1.49c-0.73,0.42-1.59,0.66-2.49,0.81c1.73,0.37,3.32,1.38,3.96,2.86
        c0.49,1.14,0.18,2.27-0.65,3.2c-1.25,1.39-3.06,1.48-4.88,1.46c0.13,0.72,0.27,1.43,0.41,2.15c0.32-0.03,0.64-0.07,0.96-0.11
        c1.38-0.16,2.77-0.32,4.16-0.24c1.54,0.09,3.02,0.59,4.32,1.32c1.04,0.59,1.91,1.28,2.44,2.43c0.85,1.84-0.01,3.65-1.47,4.97
        c-1.68,1.53-4.04,2.52-6.36,2.99c-0.93,0.19-1.89,0.28-2.85,0.34c0.35,2.25,0.83,4.7,0.03,6.91c-0.91,2.51-3.62,3.33-6.29,3.61
        c0.48,1.58-0.33,3.54-1.43,4.9c-1.39,1.71-3.12,3.16-4.58,4.83c-0.43,0.52-0.78,1.08-1.07,1.67c-0.96,2.13-1.32,5.05-3.54,6.49
        c-2.11,1.37-5.17,1.2-7.57,0.67c-2.51-0.56-4.39-1.92-5.79-3.84c-1.51-2.07-2.34-4.54-2.98-6.92c-0.93-3.47-1.61-7.04-2.02-10.59
        c-0.5-4.43-0.62-8.89-1.27-13.31c-0.21-1.39-0.34-2.89-0.69-4.28c-0.12,0.13-0.26,0.26-0.41,0.39c-1.15,0.94-2.8,1.3-4.32,1.21
        c-1.66-0.09-3.29-0.76-4.33-1.95c-1.4-1.6-1.56-3.74-1.17-5.65c0.33-1.64,1.3-3.29,3.35-3.44c-1.82-0.46-3.6-1.1-5.48-1.36
        c-0.64-0.07-1.27-0.09-1.92-0.03c-0.17,0.03-0.33,0.07-0.48,0.13c-0.2,0.09-0.38,0.19-0.56,0.31c-0.21,0.16-0.4,0.33-0.58,0.52
        c-0.13,0.16-0.25,0.33-0.34,0.52c-0.06,0.13-0.1,0.26-0.14,0.4c-0.01,0.09-0.01,0.18,0,0.27c0.02,0.06,0.03,0.12,0.06,0.18
        c0.02,0.03,0.04,0.06,0.06,0.09c0.04,0.05,0.09,0.09,0.14,0.13c0.07,0.04,0.14,0.08,0.22,0.11c0.17,0.05,0.33,0.08,0.5,0.11
        c0.36,0.05-0.06,0.27-0.42,0.22c-1.01-0.14-1.23-0.83-1.05-1.41c0.29-0.9,1.18-1.69,2.14-2c0.4-0.13,0.84-0.18,1.29-0.18
        c1.13,0,2.35,0.32,3.32,0.56c-0.23-0.11-0.45-0.21-0.68-0.32c-1.79-0.87-3.48-1.94-5.35-2.66c-0.49-0.18-0.98-0.33-1.51-0.39
        c-0.82-0.09-1.28,0.02-1.48,0.29c-0.34,0.46-0.35,0.95,0.2,1.46c0.17,0.15-1.01-0.18-0.67-1.36c0.21-0.74,0.89-0.78,1.61-0.75
        c1.13,0.05,2.4,0.58,3.29,1.01c1.69,0.82,3.3,1.75,5,2.53c-0.18-0.13-0.36-0.26-0.53-0.4c-0.63-0.53-1.07-1.22-1.27-1.97
        c-0.18-0.67-0.06-1.37-0.15-2.05c-0.03-0.13-0.06-0.24-0.11-0.37c-0.05-0.11-0.12-0.21-0.19-0.3c-0.11-0.12-0.22-0.21-0.35-0.31
        c-0.19-0.12-0.38-0.22-0.58-0.31c-0.08-0.03-0.17-0.06-0.26-0.07c-0.31-0.03-0.61-0.02-0.89-0.02c-0.65,0.02-1.26,0.04-1.87-0.37
        c-0.64-0.42-1-1.09-1.61-1.54c-0.11-0.07-0.22-0.13-0.35-0.18c-0.18-0.06-0.35-0.1-0.54-0.13c-0.2-0.02-0.4-0.02-0.6-0.01
        c-0.16,0.02-0.31,0.06-0.46,0.11c-0.1,0.05-0.2,0.1-0.29,0.15c-0.08,0.06-0.14,0.12-0.21,0.19c-0.04,0.06-0.08,0.12-0.12,0.18
        c-0.03,0.07-0.05,0.14-0.07,0.22c-0.01,0.07-0.01,0.13,0,0.2c0.01,0.05,0.02,0.1,0.04,0.15c0.02,0.04,0.05,0.08,0.08,0.12
        c0.04,0.04,0.07,0.07,0.11,0.1c0.05,0.03,0.11,0.06,0.16,0.08c0.08,0.02,0.15,0.04,0.24,0.06c0.17,0.01,0.49,0.05,0.76-0.06
        c0.33-0.13,0.16,0.23-0.19,0.3c-0.71,0.15-1.28,0.17-1.64-0.42C722.71,213.82,722.76,213.24,723.15,212.84z"/>
      <path d="M740.47,189.95C740.47,189.95,740.48,189.95,740.47,189.95L740.47,189.95C740.48,189.95,740.47,189.95,740.47,189.95z"/>
      <path fill="#FFD4B8" d="M775.99,216.99c-0.02-0.05-0.04-0.1-0.04-0.15c0-0.01,0-0.02,0-0.02
        c-0.02-0.09-0.04-0.19-0.06-0.28c-0.01-0.04-0.02-0.07-0.03-0.1c-0.06-0.11-0.13-0.21-0.2-0.3c-0.13-0.14-0.28-0.27-0.44-0.39
        c-0.37-0.25-0.78-0.44-1.21-0.61c-0.02-0.01-0.03-0.01-0.05-0.02c-0.15,0.43-0.39,0.85-0.73,1.23c-1.37,1.55-3.83,1.85-5.9,1.44
        c0.01,0.05,0.02,0.1,0.03,0.15c0.07,0.06,0.15,0.12,0.23,0.18c0.28,0.18,0.57,0.33,0.88,0.46c0.39,0.14,0.79,0.24,1.21,0.29
        c0.39,0.04,0.77,0.04,1.15,0.01c0.37-0.05,0.72-0.13,1.07-0.25c0.3-0.12,0.58-0.27,0.85-0.44c0.02-0.02,0.05-0.03,0.07-0.05
        c0.37-0.27,0.12,0.43-0.26,0.7c-1.25,0.89-3.28,1.01-5.03,0.04c0.39,2,0.86,3.97,1.72,5.86c0.44,0.91,0.97,1.78,1.63,2.57
        c0.52,0.58,1.09,1.11,1.74,1.57c0.51,0.34,1.04,0.64,1.62,0.88c0.51,0.18,1.03,0.29,1.56,0.37c0.89,0.09,1.78,0.08,2.67,0.01
        C777.6,225.75,776.91,221.35,775.99,216.99z"/>
      <path fill="#B67230" d="M767.84,214.46c1.38,0,1.77,1.38,1.17,2.16c0.18,0,0.37-0.01,0.56-0.02
        c0.12-0.02,0.24-0.04,0.35-0.06c0-0.01,0-0.02,0-0.03c0.02-0.23,0.02-0.46-0.01-0.69c-0.05-0.23-0.11-0.45-0.2-0.67
        c-0.09-0.18-0.19-0.35-0.32-0.51c-0.14-0.14-0.28-0.26-0.44-0.37c-0.11-0.07-0.22-0.13-0.33-0.19c-0.69-0.06-1.38-0.11-2.07-0.13
        c0.07,0.31,0.14,0.62,0.21,0.94C767,214.63,767.36,214.46,767.84,214.46z"/>
      <path fill="#FFFFFF" d="M766.77,216.01c0,0.02,0,0.05,0,0.07c0,0.01,0,0.01,0.01,0.02c0.01,0.02,0.01,0.03,0.02,0.05
        c0,0.01,0,0.02,0.01,0.02c0.01,0.02,0.02,0.04,0.03,0.06c0.01,0.01,0.01,0.02,0.01,0.02c0.01,0.02,0.02,0.03,0.03,0.04
        c0.01,0.01,0.01,0.01,0.02,0.02c0.02,0.02,0.03,0.03,0.05,0.04c0,0,0.01,0,0.01,0.01c0,0,0.01,0,0.01,0.01
        c0.02,0.01,0.03,0.02,0.05,0.03c0.01,0.01,0.02,0.01,0.03,0.01c0.03,0.01,0.05,0.02,0.08,0.02c0.01,0,0.01,0,0.02,0
        c0.03,0,0.06,0.01,0.1,0.01c0,0,0,0,0,0c0,0,0,0,0.01,0c0.03,0,0.06-0.01,0.09-0.01c0.01,0,0.02-0.01,0.03-0.01
        c0.51-0.11,0.47-0.88-0.13-0.88c-0.16,0-0.27,0.05-0.35,0.13c0,0-0.01,0.01-0.01,0.01c-0.02,0.02-0.03,0.04-0.05,0.06
        c0,0-0.01,0.01-0.01,0.01c-0.01,0.02-0.02,0.03-0.02,0.05c0,0.01,0,0.01-0.01,0.02c-0.01,0.02-0.02,0.04-0.02,0.07
        c0,0,0,0.01,0,0.02c0,0.02,0,0.03,0,0.05C766.77,215.99,766.77,216,766.77,216.01z"/>
      <path fill="#FFFFFF" d="M770.25,216.46c0.17-0.04,0.34-0.09,0.5-0.15c0.31-0.13,0.61-0.28,0.89-0.46
        c0.19-0.14,0.36-0.29,0.52-0.46c0.12-0.16,0.23-0.32,0.32-0.49c0.03-0.07,0.05-0.14,0.07-0.2c-0.96-0.24-1.95-0.39-2.93-0.51
        c-0.12-0.01-0.23-0.03-0.36-0.04c0.03,0.02,0.06,0.04,0.09,0.06C770.03,214.71,770.36,215.63,770.25,216.46z"/>
      <path fill="#DEA791" d="M772.3,213.65c0.12,0.03,0.23,0.05,0.35,0.08c0-0.01,0-0.03,0-0.04c-0.05-0.26-0.13-0.51-0.23-0.75
        c-0.14-0.27-0.31-0.53-0.51-0.77c-0.22-0.25-0.47-0.47-0.74-0.67c-0.29-0.19-0.59-0.35-0.92-0.49c-0.34-0.11-0.68-0.2-1.04-0.25
        c-0.36-0.03-0.72-0.03-1.09,0.01c-0.41,0.06-0.81,0.17-1.2,0.3c-0.36,0.15-0.71,0.32-1.04,0.52c0.16,0.47,0.3,0.93,0.43,1.4
        C768.32,213.03,770.35,213.24,772.3,213.65z"/>
      <path fill="#FFD4B8" d="M781.01,226.96c0.34-0.05,0.66-0.12,0.97-0.23c0.22-0.1,0.42-0.21,0.62-0.33
        c0.27-0.2,0.52-0.42,0.75-0.66c0.15-0.18,0.27-0.37,0.38-0.57c0.07-0.18,0.13-0.36,0.16-0.55c0.02-0.18,0.01-0.36-0.01-0.54
        c-0.04-0.2-0.1-0.38-0.18-0.57c-0.13-0.25-0.28-0.47-0.46-0.7c-0.24-0.26-0.5-0.5-0.8-0.71c-0.38-0.26-0.79-0.46-1.22-0.64
        c-0.43-0.15-0.87-0.26-1.32-0.33c-0.41-0.04-1.35-0.21-1.98,0.14c0.04,0.24,0.09,0.48,0.13,0.73c1.62-0.01,4.18,0.61,4.33,2.15
        c0.03,0.36-0.27,0.9-0.3,0.54c-0.03-0.39-0.14-0.89-0.46-1.16c-1.04-0.86-2.35-1.05-3.48-0.98c0.26,1.48,0.52,2.95,0.78,4.43
        C779.64,227,780.32,227.02,781.01,226.96z"/>
      <path fill="#F7AC8B" d="M785.46,239.46c0.92-0.36,1.8-0.8,2.61-1.34c0.52-0.37,1-0.78,1.41-1.24
        c0.23-0.28,0.92-1.19,0.81-2.21c-0.08-0.81-0.52-1.55-1.8-2.46c-0.99-0.71-2.48-1.07-3.45-1.14c-0.97-0.08-1.68-0.11-2.4-0.02
        c-0.14,0.08-0.27,0.17-0.41,0.26c-0.52,0.37-0.99,0.8-1.41,1.28c-0.39,0.47-0.72,0.97-0.98,1.5c-0.23,0.51-0.41,1.04-0.5,1.6
        c-0.07,0.45-0.07,0.9-0.01,1.36c0.06,0.34,0.16,0.67,0.3,0.99c0.13,0.26,0.29,0.51,0.48,0.74c0.2,0.23,0.43,0.43,0.68,0.61
        c0.29,0.19,0.6,0.35,0.92,0.48c0.38,0.14,0.77,0.24,1.17,0.3C783.77,240.01,784.63,239.77,785.46,239.46z"/>
      <path fill="#F7B89F" d="M781.95,240.29c-0.63-0.19-1.22-0.48-1.72-0.9c-0.94-0.78-1.32-1.94-1.32-3.06
        c0.01-2.04,1.25-3.97,3.03-5.22c-0.51,0.05-1.02,0.1-1.52,0.17c-0.32,0.04-0.64,0.07-0.96,0.11c-0.42,0.51-0.76,1.07-1.04,1.65
        c-0.39,0.85-0.66,1.73-0.81,2.64c-0.1,0.71-0.12,1.43-0.01,2.15c0.08,0.48,0.21,0.95,0.42,1.39c0.15,0.31,0.33,0.59,0.55,0.86
        c0.15,0.17,0.32,0.32,0.49,0.47C780.03,240.47,780.99,240.39,781.95,240.29z"/>
      <path fill="#FFD4B8" d="M734.39,225.68c-0.26,1-0.19,2.08-0.05,3.1c0.07,0.42,0.19,0.83,0.37,1.23
        c0.16,0.32,0.35,0.62,0.58,0.9c0.24,0.26,0.5,0.5,0.8,0.71c0.33,0.22,0.68,0.4,1.05,0.54c0.44,0.16,0.89,0.27,1.36,0.33
        c0.5,0.05,1,0.05,1.5,0c0.46-0.06,0.91-0.17,1.34-0.32c0.32-0.13,0.62-0.28,0.9-0.47c0.2-0.15,0.39-0.31,0.56-0.49
        c0.13-0.17,0.57-0.68,0.61-1.49c0.03-0.81-0.15-1.63-0.56-2.34c-0.31-0.54,1.09,0.51,1.3,1.09c0.28,0.79,0.31,1.59,0.04,2.33
        c1.44,3.33,2.02,14.32,3,20.96c0.32,2.08,0.77,4.15,1.24,6.21c0.47,2.08,1.04,4.12,1.96,6.13c1.17,2.54,3.44,4.5,4.81,5.09
        c1.37,0.59,3.93,1.18,4.99,0.52c0.65-0.4,0.9-1.36,0.95-1.63c0.04-0.3,0.03-0.59,0-0.88c-0.05-0.32-0.17-1.12-0.7-1.7
        c-0.52-0.59-1.34-0.97-1.57-1.06c-0.32-0.12,0.2-0.29,0.73-0.1c1.55,0.56,2.28,2.13,2.19,3.56c-0.05,0.78-0.38,1.46-0.91,1.96
        c0.49-0.07,0.98-0.18,1.45-0.34c0.29-0.12,0.57-0.26,0.83-0.42c0.22-0.16,0.42-0.35,0.6-0.55c0.32-0.4,0.56-0.82,0.78-1.26
        c0.92-2,1.32-4.23,2.81-5.99c1.34-1.57,3.01-2.91,4.31-4.51c1.15-1.41,2.03-3.54,1.37-4.42c-6.12,0.33-12.26-1.78-17.53-4.36
        c-1.93-0.94-3.63-1.8-5.19-3.19c-1.08-0.97-1.39-2.48-0.79-1.89c2.08,2.05,7.48,4.56,10.97,5.9c2.73,1.02,5.56,1.81,8.49,2.18
        c1.8,0.2,3.6,0.23,5.39,0.03c0.99-0.13,1.98-0.3,2.91-0.63c0.34-0.14,0.67-0.3,0.97-0.5c0.22-0.16,0.42-0.34,0.6-0.54
        c0.2-0.25,0.37-0.51,0.51-0.79c0.19-0.44,0.31-0.88,0.39-1.34c0.22-1.62-0.08-4.16-0.34-5.41c-1.48,0.1-2.97,0.16-4.46,0.18
        c-0.76,0.01-1.52,0.01-2.28-0.01c0.2,1.22,0.23,3.1-0.39,4.47c-0.58,1.27-1.96,2.07-3.51,1.78c-1.44-0.27-2.11-1.7-2.41-2.85
        c-0.3-1.21-0.34-2.62,0.09-3.82c-2.74-0.36-6.37-0.91-9.45-2.46c-0.99-0.5-2.35-1.49-2.54-2.51c-0.22-1.19,0.69-2.69,2.41-3.44
        c-1.77,0.23-4.53,2.87-5.29,3.74c-0.71,0.83-1.32,1.73-1.79,2.69c-0.41,0.89-0.67,1.81-0.81,2.76c-0.22,1.69-0.16,3.69,0.1,5.22
        c0.25,1.54,0.67,3.43,1.27,4.9c0.14,0.33-0.67-0.18-0.87-0.73c-1.41-3.8-2.3-8.65,0.05-12.97c1.79-3.3,6.13-6.88,10.03-6.64
        c0.91,0.05,1.03,1.24,0.06,1.43c-0.97,0.19-2.12,0.45-2.89,1.29c-0.77,0.85-0.27,2.05,0.66,2.66c2.17,1.42,6.84,2.44,9.31,2.75
        c3.92,0.46,7.92,0.53,11.88,0.29c-2.34-2.16-1.59-6.59,0.39-9.14c-0.91,0.08-1.82,0.13-2.74,0.08c-2.83-0.18-5.11-1.68-6.75-3.68
        c-3.1-3.78-3.64-10.42-4.34-13.33c-0.7-2.91-1.31-4.96-1.8-6.13c-0.2-0.49,1.25,0.47,2.01,1.94c1.04-0.56,2.19-0.91,3.39-0.91
        c0.48,0,0.96,0.06,1.45,0.17c1.56,0.38,2.92,1.45,3.56,2.77c0.28,0.59,0.42,1.2,0.4,1.79c0.47,0.16,0.93,0.35,1.35,0.59
        c-0.29-1.27-0.7-2.91-1.06-4.17c-2.54-1.64-5.26-2.94-6.97-3.61c-0.9-0.35-1.16-1.44-0.65-2.21c0.23-0.35,0.6-0.59,1.01-0.72
        c-1.15-0.41-1.68,0.7-1.56,1.59c0.03,0.21-0.24-0.11-0.25-0.42c-0.03-1.12,0.74-1.73,1.64-1.5c-0.4-0.15-0.94-0.27-1.17-0.25
        c-0.27,0.02-0.42,0.15-0.49,0.33c-0.08,0.21-0.11,0.52-0.11,0.59c0,0.11-0.11-0.06-0.09-0.28c0.05-0.57,0.27-0.81,0.76-0.8
        c0.63,0.01,1.36,0.34,1.89,0.59c-0.53-0.32-1.06-0.59-1.63-0.83c-0.27-0.12-0.55-0.16-0.75-0.13c-0.42,0.07-0.42,0.41-0.43,0.51
        c-0.01,0.1-0.31-0.62,0.48-0.71c0.41-0.05,0.97,0.22,1.42,0.46c0.45,0.24,1.12,0.6,1.77,0.96c0.65,0.36,2.56,1.49,3.79,2.3
        c-0.47-1.28-0.99-2.54-1.57-3.78c-1.13-2.38-2.49-4.69-4.25-6.75c-1.06-1.23-2.26-2.35-3.62-3.31c-1.09-0.74-2.27-1.37-3.52-1.85
        c-1.29-0.48-2.64-0.8-4.03-0.97c-1.22-0.13-2.45-0.18-3.67-0.05c-1.09,0.14-2.13,0.41-3.15,0.79c-1.3,0.51-2.52,1.17-3.65,1.95
        c-1.5,1.06-2.83,2.29-4.01,3.64c-1.29,1.53-2.38,3.18-3.22,4.94c-0.8,1.74-1.26,3.53-1.55,5.4c-0.35,2.45-0.41,4.93-0.06,7.38
        c0.32,2.05,0.91,4.04,1.8,5.95c0.66,1.37,1.46,2.67,2.38,3.91c0.24,0.33,0.22,0.98-0.17,0.75c-1.14-0.66-2.35-1.15-3.81-0.99
        C735.36,223.63,734.69,224.55,734.39,225.68z"/>
      <path fill="#E3B395" d="M774.34,218.17c-0.42,0.97-1.19,1.75-2.2,2.24c-0.73,0.35-1.51,0.6-2.3,0.8
        c-0.43,0.11-1.17,0.34-1.73,0.27c-0.23-0.86-0.41-1.73-0.58-2.6c1.75,0.97,3.78,0.85,5.03-0.04c0.38-0.27,0.63-0.97,0.26-0.7
        c-0.02,0.02-0.05,0.03-0.07,0.05c-0.27,0.17-0.55,0.32-0.85,0.44c-0.35,0.12-0.71,0.2-1.07,0.25c-0.38,0.04-0.76,0.03-1.15-0.01
        c-0.41-0.06-0.81-0.15-1.21-0.29c-0.31-0.13-0.6-0.28-0.88-0.46c-0.08-0.06-0.16-0.12-0.23-0.18c-0.01-0.05-0.02-0.1-0.03-0.15
        c2.07,0.41,4.53,0.11,5.9-1.44c0.34-0.38,0.58-0.8,0.73-1.23c0.02,0.01,0.03,0.01,0.05,0.02c0.22,0.09,0.43,0.18,0.64,0.28
        C774.71,216.33,774.71,217.3,774.34,218.17z"/>
      <path fill="#E3B395" d="M772.54,227.59c1.76,0.92,3.71,1.34,5.71,1.37c0.07,0.39,0.15,0.78,0.23,1.17
        c-0.89,0.07-1.78,0.09-2.67-0.01c-0.53-0.08-1.05-0.19-1.56-0.37c-0.58-0.23-1.11-0.54-1.62-0.88c-0.65-0.47-1.22-0.99-1.74-1.57
        c-0.57-0.69-1.05-1.43-1.45-2.21C770.29,226.09,771.33,226.95,772.54,227.59z"/>
      <path fill="#E3B395" d="M779.95,222.71c0.44,1.39,0.66,2.85,0.57,4.28c-0.52,0.02-1.04,0.01-1.56,0
        c-0.27-1.48-0.52-2.95-0.78-4.43C778.73,222.53,779.35,222.56,779.95,222.71z"/>
      <path fill="#E3B395" d="M779.33,221.08c0.16,0.37,0.31,0.74,0.45,1.12c-0.59-0.13-1.2-0.19-1.71-0.18
        c-0.04-0.24-0.09-0.48-0.13-0.73C778.35,221.05,778.89,221.05,779.33,221.08z"/>
      <path fill="#E3B395" d="M748.22,214.82c0.36-0.39,0.87-0.66,1.36-0.9c0.73-0.36,1.48-0.66,2.25-0.93
        c1.67-0.59,3.4-1.08,5.15-1.43c0.67-0.13,1.35-0.26,2.04-0.29c0.22-0.01,0.45-0.03,0.67,0.01c0.11,0.02,0.2,0.06,0.2,0.07
        c0,0,0,0,0,0c0,0,0,0.01,0,0.01c0.01,0.02,0.01,0.03,0.02,0.04c1.37,2.25,1.59,5.4,0.05,7.63c-1.74,2.52-5.59,3.3-8.42,1.89
        c-1.23-0.61-2.34-1.59-3.04-2.71c-0.4-0.62-0.71-1.33-0.67-2.06C747.84,215.73,747.91,215.16,748.22,214.82z"/>
      <path fill="#E3B395" d="M755.54,240.54c-1.77-1.15-3.01-3.52-1.97-5.52c1.06-0.88,2.32-1.72,3.29-1.85
        c-1.71,0.75-2.62,2.25-2.41,3.44c0.18,1.03,1.55,2.02,2.54,2.51c3.08,1.55,6.71,2.1,9.45,2.46c-0.23,0.64-0.33,1.33-0.32,2.02
        C764.97,243.55,758.87,242.7,755.54,240.54z"/>
      <path fill="#E3B395" d="M774.93,242.01c1.49-0.02,2.97-0.08,4.46-0.18c0.08,0.38,0.16,0.88,0.23,1.43
        c-1.02,0.26-4.32,0.6-6.84,0.46c0.01-0.63-0.05-1.23-0.13-1.72C773.41,242.02,774.17,242.02,774.93,242.01z"/>
      <path fill="#E3B395" d="M738.04,226.14c0.16-0.04,0.32-0.05,0.48-0.04c0.02,0.01,0.04,0.03,0.07,0.04
        c1.11,0.36,1.92,1.58,2.01,2.62c0.05,0.57-0.06,1.16-0.57,1.54c-0.56,0.41-1.31,0.42-1.93,0.12c-1.2-0.6-1.86-1.93-1.33-3.13
        C736.99,226.77,737.43,226.27,738.04,226.14z"/>
      <path fill="#E3B395" d="M756.26,252.48c0.23,0.98,0.97,1.83,1.8,2.47c1.09,0.86,2.41,1.56,3.8,1.91
        c1.18,0.3,2.54,0.36,3.75,0.23c0.79-0.08,1.56-0.28,2.14-0.7c-0.22,1.08-1.38,1.91-2.36,2.5c-1.29,0.77-2.86,1.87-4.48,1.7
        c-1.96-0.2-3.53-1.98-4.31-3.5c-0.43-0.84-0.73-1.78-0.71-2.72c0.01-0.51,0.1-1,0.24-1.49
        C756.17,252.75,756.21,252.62,756.26,252.48z"/>
      <path fill="#E3B395" d="M760.13,264.61c1.29-0.25,2.31,0.78,2.61,1.99c0.23,0.93,0.01,2.11-0.57,2.97
        c-0.43,0.14-0.87,0.24-1.32,0.3c0.53-0.5,0.87-1.18,0.91-1.96C761.84,266.66,761.3,265.31,760.13,264.61z"/>
      <path fill="#E3B395" d="M749.84,251.06c-0.34-1.16-0.6-2.4-0.78-3.48c-0.26-1.54-0.32-3.53-0.1-5.22
        c0.15-0.95,0.4-1.87,0.81-2.76c0.4-0.82,0.91-1.59,1.49-2.32c-0.71,1.69-0.75,3.69-0.95,5.43c-0.03,0.28-0.07,0.57-0.1,0.85
        c-0.27-0.22-0.51-0.42-0.72-0.63c-0.56-0.55-0.33,0.74,0.6,1.71c-0.12,1.13-0.23,2.27-0.28,3.41c-0.04,0.85-0.05,1.71,0,2.56
        C749.82,250.76,749.83,250.91,749.84,251.06z"/>
      <path fill="#E3B395" d="M773.37,211.71c-0.72-1.02-1.89-1.82-3.19-2.14c-0.09-0.02-0.18-0.04-0.27-0.06
        c-0.34-0.26-0.67-0.53-0.98-0.82c-0.53-0.48-1.32-1.11-1.66-1.82c0.06,0.03,0.12,0.06,0.18,0.08c1.71,0.67,4.44,1.97,6.97,3.61
        c0.16,0.58,0.34,1.24,0.51,1.91C774.41,212.24,773.88,211.98,773.37,211.71z"/>
      <path fill="#E3B395" d="M751.12,202.17c1-0.58,2.06-1.12,3.11-1.63c0.88-0.42,2.15-1.1,3.17-0.72
        c0.01,0,0.02,0.01,0.04,0.01c0.73,0.45,0.99,1.11,0.55,1.88c-0.75,1.32-2.32,2.44-3.76,3.09c-1.41,0.63-2.93,1.02-4.37,1.61
        c-0.71,0.29-1.41,0.61-2.07,1c-0.24,0.14-0.49,0.33-0.77,0.39c-0.39,0.09-0.52-0.26-0.51-0.56c0.02-1.02,0.91-1.98,1.62-2.7
        C749.02,203.62,749.99,202.82,751.12,202.17z"/>
      <path fill="#E3B395" d="M740.79,205.68c0.72,0.96,1.11,1.94,1.24,3.11c0.28,2.62,0.07,5.27-0.37,7.86
        c-0.57,3.3-1.33,5.23-1.45,5.97c-0.61-0.92-1.16-1.87-1.63-2.85c-0.89-1.91-1.49-3.9-1.8-5.95c-0.35-2.45-0.29-4.93,0.06-7.38
        c0.19-1.28,0.47-2.52,0.88-3.73c0.29,0.21,0.56,0.43,0.83,0.66C739.38,204.08,740.17,204.83,740.79,205.68z"/>
      <path fill="#E3B395" d="M744.67,194.87c-0.7,1.09-1.52,2.16-2.48,3.09c-1.1,1.07-2.35,1.96-3.65,2.79
        c0.82-1.64,1.85-3.19,3.06-4.62c1.17-1.35,2.51-2.58,4.01-3.64c0.05-0.03,0.1-0.07,0.15-0.1
        C745.58,193.25,745.17,194.1,744.67,194.87z"/>
      <path fill="#E3B395" d="M745.36,230.29c0.48,1.35,0.7,2.78,0.72,4.2c0.02,1.41-0.13,2.8-0.39,4.18
        c-0.41-3.43-0.88-6.4-1.51-7.87c0.26-0.73,0.24-1.54-0.04-2.33c-0.08-0.23-0.35-0.53-0.62-0.78
        C744.49,228.21,745.03,229.36,745.36,230.29z"/>
      <path fill="#E3B395" d="M768,253.71c-2.35-0.15-4.56-0.62-6.74-1.45c-1.21-0.46-2.39-0.98-3.58-1.46
        c-0.94-0.38-1.89-0.8-2.75-1.32c-0.86-0.52-1.64-1.14-2.27-1.88c-0.5-0.59-0.89-1.23-1.27-1.88c1.25,0.9,2.6,1.58,4.08,2.3
        c5.28,2.59,11.42,4.69,17.53,4.36c0.19,0.25,0.25,0.61,0.22,1.02C771.54,253.81,769.71,253.82,768,253.71z"/>
      <path fill="#DEA791" d="M772.2,241.99c-1.76-0.04-3.51-0.15-5.26-0.35c-0.11,0.3-0.18,0.6-0.23,0.9
        c-0.1,0.72-0.1,1.45,0.01,2.18c0.09,0.55,0.24,1.1,0.47,1.61c0.13,0.27,0.3,0.53,0.49,0.77c0.13,0.14,0.28,0.27,0.43,0.39
        c0.15,0.1,0.3,0.18,0.47,0.25c0.16,0.05,0.31,0.09,0.48,0.11c0.22,0.02,0.44,0.02,0.67-0.01c0.21-0.03,0.42-0.08,0.62-0.15
        c0.2-0.08,0.39-0.18,0.56-0.29c0.15-0.12,0.29-0.24,0.42-0.37c0.19-0.23,0.33-0.46,0.46-0.72c0.21-0.46,0.33-0.94,0.41-1.44
        C772.33,243.96,772.32,242.86,772.2,241.99z"/>
      <path fill="#D48D70" d="M766.65,243.42c0.01-0.29,0.02-0.59,0.07-0.88c0.05-0.31,0.12-0.61,0.23-0.9
        c1.75,0.2,3.5,0.31,5.26,0.35c0.05,0.39,0.08,0.83,0.08,1.28C770.41,243.33,768.53,243.39,766.65,243.42z"/>
      <polygon points="749.11,247.42 749.11,247.43 749.11,247.42 		"/>
      <path d="M745.09,214.37c1.24-0.17,2.43-0.53,3.61-0.9c-0.88-1.86-0.55-3.97,1.29-5.44c0.91-0.72,1.96-1.03,3.02-1.03
        c1.44,0,2.9,0.56,4.08,1.41c0.93,0.62,1.71,1.46,2.28,2.42c1.04-0.26,2.68-0.61,3.27-0.88c0.59-0.27-0.14,0.8-0.67,1.09
        c-0.52,0.28-1.51,0.6-2.15,0.67c0.38,1.38,0.83,3.76,0.09,5.39c-0.93,2.05-4.19,4.17-6.38,2.86c-0.4-0.24-0.71-0.97-0.31-0.73
        c0.78,0.45,1.51,0.59,2.68,0.29c1.17-0.3,2.37-1.38,2.86-1.94c0.49-0.56,0.91-1.68,0.89-2.79c-0.56,1-1.56,1.66-2.69,2.09
        c-2.66,1-5.67-0.09-7.38-2.04c-0.15-0.17-0.28-0.34-0.41-0.52c-1.25,0.39-2.51,0.78-3.83,0.97
        C744.7,215.37,744.41,214.46,745.09,214.37z"/>
      <path fill="#FFFFFF" d="M756.6,215.57c0.31-0.13,0.61-0.29,0.88-0.47c0.22-0.16,0.41-0.34,0.59-0.54
        c0.13-0.17,0.25-0.35,0.35-0.54c0.08-0.2,0.14-0.39,0.18-0.6c0.02-0.22,0.02-0.44,0-0.66c-0.05-0.24-0.12-0.48-0.21-0.71
        c-1.21,0.28-2.42,0.55-3.63,0.82c0.09,0.05,0.18,0.1,0.26,0.16c0.75,0.56,1.16,1.72,1.02,2.7
        C756.23,215.69,756.41,215.63,756.6,215.57z"/>
      <path fill="#FFFFFF" d="M751.75,215.08c0,0,0,0.01,0,0.01c0,0.02,0,0.03,0.01,0.05c0,0,0,0.01,0,0.02
        c0,0.01,0.01,0.03,0.01,0.04c0,0.01,0.01,0.02,0.01,0.02c0,0.01,0.01,0.02,0.01,0.03c0,0.01,0.01,0.02,0.01,0.02
        c0,0.01,0.01,0.02,0.02,0.03c0,0,0.01,0.01,0.01,0.02c0.01,0.01,0.02,0.02,0.02,0.03c0.01,0,0.01,0.01,0.01,0.02
        c0.02,0.02,0.03,0.03,0.04,0.05c0,0,0,0,0,0c0.01,0.01,0.03,0.03,0.04,0.04c0.01,0,0.01,0.01,0.02,0.02
        c0.01,0.01,0.02,0.02,0.03,0.02c0.01,0.01,0.02,0.01,0.02,0.02c0.01,0.01,0.02,0.01,0.03,0.02c0,0,0.02,0.01,0.03,0.01
        c0.01,0.01,0.02,0.01,0.04,0.01c0.01,0,0.02,0.01,0.03,0.01c0.01,0,0.02,0.01,0.04,0.01c0.01,0,0.02,0.01,0.03,0.01
        c0.01,0.01,0.03,0.01,0.05,0.01c0.01,0,0.02,0,0.03,0.01c0.02,0,0.04,0,0.06,0c0.01,0,0.01,0,0.02,0c0,0,0,0,0,0
        c0.04,0,0.07,0,0.1-0.01c0.01,0,0.02,0,0.02,0c0.03-0.01,0.07-0.01,0.09-0.02c0.01,0,0.01-0.01,0.02-0.01
        c0.03-0.01,0.05-0.02,0.08-0.03c0.01,0,0.01,0,0.01,0c0.03-0.01,0.05-0.03,0.07-0.05c0.41-0.3,0.27-1.05-0.4-1.05
        c-0.36,0-0.57,0.22-0.62,0.47c0,0.01-0.01,0.03-0.01,0.05c0,0.01,0,0.01,0,0.01C751.75,215,751.75,215.04,751.75,215.08z"/>
      <path fill="#B67230" d="M750.73,213.96c0.24,0.27,0.51,0.51,0.79,0.75c0.15-0.63,0.69-1.17,1.61-1.17
        c1.51,0,1.99,1.44,1.43,2.34c0.07,0,0.15,0.01,0.22,0.01c0.21,0,0.42-0.01,0.64-0.03c0.1-0.02,0.19-0.03,0.28-0.05
        c0.04-0.28,0.03-0.55-0.01-0.84c-0.06-0.31-0.15-0.6-0.27-0.9c-0.11-0.22-0.23-0.42-0.39-0.62c-0.09-0.1-0.19-0.2-0.31-0.28
        c-0.1-0.06-0.2-0.11-0.31-0.16c-0.05-0.02-0.09-0.03-0.14-0.04c-0.1,0.02-0.2,0.05-0.31,0.07c-1.11,0.25-2.22,0.52-3.32,0.83
        C750.68,213.9,750.7,213.93,750.73,213.96z"/>
      <path fill="#9D5606" d="M769.11,215.14c-0.05-0.09-0.11-0.17-0.18-0.24c0.23,0.02,0.47,0.05,0.7,0.1
        c0.03,0.05,0.05,0.1,0.08,0.15c0.01,0.03,0.03,0.07,0.04,0.1C769.54,215.19,769.33,215.16,769.11,215.14z"/>
      <path fill="#9D5606" d="M768.75,214.73c-0.09-0.07-0.19-0.12-0.31-0.17c0.34,0.02,0.67,0.06,0.99,0.12
        c0.04,0.04,0.06,0.09,0.09,0.13C769.27,214.77,769.01,214.75,768.75,214.73z"/>
      <path fill="#9D5606" d="M768.28,214.41c-0.54-0.01-1.07,0.05-1.6,0.12c-0.04-0.2-0.09-0.39-0.13-0.59
        c0.69,0.02,1.38,0.07,2.07,0.13c0.11,0.06,0.22,0.12,0.33,0.19c0.11,0.08,0.2,0.15,0.29,0.24
        C768.93,214.45,768.6,214.41,768.28,214.41z"/>
      <path fill="#9D5606" d="M767.11,214.63c-0.14,0.07-0.25,0.15-0.35,0.25c-0.01-0.07-0.03-0.13-0.04-0.2
        C766.85,214.66,766.98,214.64,767.11,214.63z"/>
      <path fill="#9D5606" d="M754.33,213.98c-0.11-0.11-0.24-0.2-0.39-0.27c0.4-0.08,0.8-0.09,1.21-0.1
        c0.06,0.09,0.11,0.17,0.16,0.26C754.96,213.86,754.65,213.9,754.33,213.98z"/>
      <path fill="#9D5606" d="M754.7,214.57c-0.06-0.17-0.14-0.32-0.25-0.46c0.3-0.07,0.61-0.1,0.94-0.1
        c0.01,0.03,0.02,0.04,0.04,0.07c0.05,0.13,0.1,0.25,0.14,0.38C755.28,214.47,754.99,214.5,754.7,214.57z"/>
      <path fill="#9D5606" d="M753.66,213.61c-0.16-0.04-0.34-0.07-0.53-0.07c-0.79,0-1.29,0.39-1.52,0.89
        c-0.04,0.02-0.08,0.04-0.12,0.06c-0.04,0.02-0.1,0.05-0.14,0.07c-0.22-0.19-0.43-0.39-0.62-0.61c-0.02-0.03-0.05-0.06-0.07-0.09
        c1.09-0.31,2.2-0.58,3.32-0.83c0.1-0.02,0.2-0.04,0.31-0.07c0.05,0.02,0.09,0.03,0.14,0.04c0.11,0.05,0.21,0.1,0.31,0.16
        c0.11,0.08,0.21,0.18,0.3,0.28C754.57,213.47,754.12,213.49,753.66,213.61z"/>
      <path fill="#DEA791" d="M749.7,211.88c0.07,0.33,0.17,0.66,0.3,0.97c0.03,0.06,0.07,0.12,0.1,0.18
        c1.11-0.32,2.23-0.6,3.36-0.86c1.42-0.33,2.85-0.63,4.27-0.96c-0.18-0.32-0.39-0.61-0.63-0.9c-0.36-0.39-0.75-0.74-1.19-1.06
        c-0.38-0.25-0.79-0.47-1.22-0.65c-0.38-0.14-0.76-0.23-1.16-0.29c-0.29-0.02-0.58-0.03-0.86,0c-0.27,0.04-0.53,0.1-0.78,0.19
        c-0.27,0.11-0.51,0.24-0.75,0.4c-0.27,0.21-0.51,0.43-0.74,0.68c-0.18,0.22-0.33,0.44-0.46,0.69c-0.11,0.25-0.19,0.5-0.23,0.77
        C749.67,211.33,749.67,211.6,749.7,211.88z"/>
      <path d="M756.94,201.09c-4.4,1.2-8.57,4.38-10.29,7.6c-0.43,0.82-1.62-0.47-0.63-2.65c-0.14-1.27,0.52-2.45,1.37-3.47
        c0.06-0.39,0.18-0.77,0.36-1.15c0.39-0.79,1.08-1.41,1.88-1.86c0.88-0.51,1.89-0.79,2.74-1.35c0.27-0.2,0.58-0.38,0.74-0.66
        c0.21-0.37,0.07-0.76-0.04-0.91c-0.11-0.15,0.55,0.3,0.33,0.82c-0.22,0.52-0.84,0.89-1.59,1.27c-0.83,0.43-1.73,0.72-2.51,1.23
        c-0.34,0.24-0.63,0.51-0.89,0.81c-0.22,0.27-0.4,0.55-0.55,0.85c-0.1,0.22-0.17,0.44-0.24,0.67c0.02-0.03,0.05-0.06,0.08-0.09
        c0.6-0.66,1.28-1.3,2.04-1.81c1.33-0.89,4.26-1.8,3.97-3.11c-0.05-0.22,0.31,0.04,0.27,0.38c-0.16,1.32-2.26,1.81-4.02,3.01
        c-0.85,0.57-1.56,1.28-2.21,2.03c-0.07,0.09-0.15,0.18-0.22,0.27c0,0.17,0,0.34,0.02,0.52c0.02,0.11,0.04,0.21,0.07,0.32
        c2.12-2.61,4.9-4.79,8.36-5.83c0.16-0.05,0.32-0.07,0.48-0.07c0.78,0,1.53,0.53,1.74,1.2
        C758.48,199.99,757.88,200.84,756.94,201.09z"/>
      <path d="M747.37,203.19c-0.24,0.32-0.45,0.65-0.63,1c-0.16,0.35-0.27,0.7-0.34,1.08c-0.01,0.08-0.01,0.16-0.02,0.24
        c0.35-0.52,0.72-1.04,1.12-1.54C747.42,203.71,747.38,203.46,747.37,203.19z"/>
      <path d="M738.24,225.61c0.63,0,1.11,0.33,1.26,0.59c0.24,0.42,0.09,0.87-0.06,0.66c-0.08-0.11-0.58-0.42-0.72-0.48
        c-0.09-0.03-0.18-0.05-0.27-0.07c-0.08,0-0.15,0-0.23,0c-0.08,0.01-0.15,0.03-0.22,0.05c-0.09,0.04-0.18,0.08-0.26,0.13
        c-0.11,0.08-0.82,0.57-0.81,1.48c0,0.45,0.19,1.17,0.52,1.53c0.34,0.36,0.77,0.78,1.69,0.78c0.92,0.01,1.05-0.43,1.08-0.67
        c0.04-0.24,0.6,0.06,0.48,0.52c-0.41,1.6-3.64,0.97-4.25-0.79c-0.43-1.25-0.32-2.25,0.44-3.11
        C737.27,225.8,737.75,225.61,738.24,225.61z"/>
      <path d="M757.08,251.47c0.24,0.68,0.48,1.26,1.19,2.15c0.71,0.9,2.36,1.72,3.02,1.99c0.72,0.26,2.47,0.62,3.43,0.59
        c0.97-0.03,2.02-0.13,3.29-0.97c0.57-0.38,0.59,1.15-0.24,1.71c-1.88,1.26-4.78,1.1-7.18,0.37c-1.99-0.6-3.63-1.94-4.48-3.94
        C755.58,252.11,756.85,250.79,757.08,251.47z"/>
      <path fill="#FFEBD4" d="M747.4,223.28c2.96,2.04,7.06,2.8,4.02-0.78c-1.11-1.3-5.29-3.68-7.19-3.49
        C743.42,219.1,745.22,221.78,747.4,223.28z"/>
      <path fill="#FFEBD4" d="M765.15,220.28c0.34,2.47,1.48,6.11,3.07,7.95c1.59,1.84,3.18,3.1,4.55,3.58
        c1.38,0.49-4.33,1.36-6.87-2.52C763.36,225.42,764.73,217.18,765.15,220.28z"/>
      <path fill="#FFEBD4" d="M764.1,197.7c2.51,2.74,6.4,4.92,5.28,2.62c-0.81-1.66-4.33-6.4-7.19-7.56
        C760.83,192.2,761.81,195.21,764.1,197.7z"/>
      <path fill="#FFEBD4" d="M755.64,190.43c-1.81-0.32-4.67-0.02-6.34,1.16C746.13,193.82,761.03,191.4,755.64,190.43z"/>
      <path fill="#FFEBD4" d="M759.45,266.89c-0.45-1.57-1.92-2.98-3.7-2.71C752.58,264.66,760.08,269.12,759.45,266.89z"/>
      <path fill="#FAD2B3" d="M785.39,234.17c1.77,0.73,4.08,0.64,3.39-0.42c-0.56-0.86-2.94-2.14-5.19-1.91
        C782.48,231.96,783.67,233.46,785.39,234.17z"/>
    </g>
    <path fill="#FFEBD4" d="M820.45,282.19c1.24-1.88,0-5.36-2.64-5.64C815.17,276.26,819.21,284.07,820.45,282.19z"/>
    <path fill="#FFEBD4" d="M828.84,280.75c0.46,1.41-0.89,4.81-1.87,5.64C823.4,289.43,827.76,277.42,828.84,280.75z"/>
    <path fill="#FFEBD4" d="M741.37,281.18c-1.98,0.61-4.66,0.58-6.37-0.14c-1.71-0.73-1.4,2.75,2.17,2.89
      C740.75,284.07,744.63,280.16,741.37,281.18z"/>
    <path fill="#FF7A62" d="M773.65,275.74c0.78,3.29,2.31,12.67,2.8,15.89c0.49,3.22-2.48-4.83-3.05-7.98
      C772.83,280.5,771.84,268.06,773.65,275.74z"/>
    <path fill="#A8CADF" d="M798.55,278.66c-1.65-0.85-4.53-2.07-5.85-2.68c-1.32-0.61,0,1.69,1.98,2.76
      C796.65,279.81,802.42,280.65,798.55,278.66z"/>
    <path fill="#A8CADF" d="M713.8,274.05c-1.9,1.44-3.59,4.59-4.78,7.68C706.13,289.17,718.67,270.37,713.8,274.05z"/>
    <path fill="#A8CADF" d="M720.56,288.48c-3.26,0.83-7.75,1.69-9.73,3.07c-1.98,1.38,3.05,0.77,5.19,0.38
      C718.17,291.55,727.16,286.8,720.56,288.48z"/>
    <path fill="#A8CADF" d="M725.51,245.11c-2.28,0.69-8.41,5.45-9.89,11.28C714.13,262.23,734.82,242.27,725.51,245.11z"/>
    <path fill="#A8CADF" d="M722.46,258.39c-1.28,1.42-3.22,5.3-4.21,8.06c-0.99,2.76,2.97-1.54,3.55-2.92
      C722.38,262.15,725.1,255.47,722.46,258.39z"/>
    <path fill="#A8CADF" d="M780.08,268.37c1.16,4.44,3.55,17.43,3.87,21.26c0.33,3.84-3.46-4.07-4.78-10.52
      C778.24,274.55,778.6,262.69,780.08,268.37z"/>
    <path fill="#A8CADF" d="M768.05,300.92c-6.35-3.03-14.34-2.53-15.5,1.38C751.39,306.22,774.31,303.91,768.05,300.92z"/>
    <path fill="#A8CADF" d="M768.21,304.83c-2.99,1.09-11.21,1.69-14.59,0.92c-3.38-0.77,1.9,4.07,7.5,2.99
      C766.73,307.67,771.18,303.76,768.21,304.83z"/>
    <path fill="#6097A2" d="M794.23,381.72c-1.21-0.72-4.35-1.14-6.34-0.7C785.9,381.46,796.88,383.31,794.23,381.72z"/>
    <path fill="#6097A2" d="M761.11,385.87c-1.05-0.97-4.16-2.38-5.87-2.2C753.54,383.84,762.91,387.54,761.11,385.87z"/>
    <path fill="#936C62" d="M736.17,351.42c-0.29-1.98,6.29-2.49,10.02-1.74c4.58,0.93,6.76,3.47,0.23,4.34
      C743.16,354.45,736.46,353.4,736.17,351.42z"/>
    <path fill="#936C62" d="M781.62,348.16c-2.46,2.56-7.23,1.95-10.72,0.65C767.4,347.51,785.58,344.04,781.62,348.16z"/>
    <path fill="#936C62" d="M788.37,320.6c-0.92,3.43-2.56,9.76-2.79,13.02c-0.23,3.25-3.5-1.74-3.5-5.21
      C782.08,324.94,789.54,316.26,788.37,320.6z"/>
    <path fill="#936C62" d="M742.93,378.11c-5.61,1.82-16.82,2.24-22.61,1.3C708.21,377.46,754.34,374.42,742.93,378.11z"/>
    <path fill="#936C62" d="M778.82,375.72c-4.1,1.04-12.12,0.43-17.71-0.43C755.51,374.42,789.07,373.12,778.82,375.72z"/>
  </g>
  <g id="item_10_">
    <path d="M752.5,395.52c-1.07,3.66-2.14,7.32-3.69,10.83c-2.51,5.48-6.27,10.28-9.67,15.3c-1.41,2.1-3.29,4.56-2.86,7.19
      c0.34,2.1,2.04,3.32,4.02,4.11c4.43,1.79,9.65,2.49,14.32,3.6c5.71,1.36,11.46,2.74,16.91,4.86c1.11,0.44,2.2,0.84,3.27,1.36
      c0.25,0.9,0.43,1.9,0.57,2.82c0.42,3.14,0.42,6.34-0.01,9.48c-0.21,1.39-0.51,2.79-1,4.12c-2.08-1.77-4.08-4.48-7.02-5.06
      c-0.75-0.15-0.55,1.05-0.54,1.54c-0.47-0.16-2-0.09-1.9,0.3c0.76,2.77,2.53,4.91,5.07,6.79c-5.16-1.62-7.36,0.47-6.67,0.81
      c4.97,2.51,8.68,3.09,14.09,3.42c-3.82,18.3,2.2,42.42,14.67,52.02c0.86,0.66,1.13,6.07-0.56,9.64c-1.7,3.57-5.02,6.81-7.32,7.52
      c-2.3,0.71-4.54,0.82-5.34,0.8c-0.81-0.01,1.02,1.34,2.28,1.44c1.26,0.1,11.29-0.89,15.79-1.32c-0.87,4.88-3.3,7.56-4.17,14.23
      c-1.03,7.89-0.85,12.77-0.19,18.28c1.13,9.42,3.42,17.34,5.27,26.58c-1.13-0.15-2.83,0.8-3,3.13c-0.43,6.06,0.59,20.22-0.64,27.52
      c0.64,0.84,1.88,0.98,2.88,0.75c-0.64-5.37-1.03-25.62-0.22-25.14c0.81,0.48,2.24,1.24,3.2,2.14c0.6,5.77,0.27,16.89-0.16,19.62
      c-0.43,2.73,0.16,5.76,0.91,7c0.74,1.25,2.03,1.51,3.75,1.88c4.79,1.03,15.19,1.41,14.65,0.61c-0.77-1.15-1.84-2.76-2.82-3.79
      c4.58-0.48,11.25-2.97,10.7-3.49c-2.89-2.73-6.49-4.28-10.38-5.58c-1.09-2.19-0.64-9.49-1.71-13.56c-1.07-4.07-3.14-6.9-4.43-8.74
      c0.69-4.27,1.66-15.65,1.69-30.86c0.02-9.38-0.44-19.18,0.54-27.24c0.16-1.28,0.55-3.4,1.02-4.74c3.26-0.14,7.36-0.48,9.11-0.73
      c1.35-0.2,3.79-1.31,2.62-1.32c-1.13-0.02-4.2-0.11-5.25-1.22c-1.05-1.1,0.54-6.16,2.13-10.07c1.58-3.92,4.48-7.27,6.23-18.01
      c1.75-10.73,1.93-24.28-0.16-34.41c-2.01-9.7-7.89-17.61-15.43-24.36c1.69-0.1,7.25-0.15,6.89-0.86c-1.08-2.09-5.82-2-8.83-1.9
      c0.45-0.64,1.71-1.14,2.56-1.47c0.71-0.27,2.84-0.94,4.25-2.2c1.4-1.25,4.84-9.4,7-14.17c1.98-4.33,4.8-8.6,5.79-13.24
      c1-4.67-0.86-8.67-3.06-9.76c-2.43-1.21-12.59-6.91-19.15-10.18c-9.1-4.54-16.35-7.31-17.09-7.74c0.15-9.44-0.46-24.45-5.64-36.55
      c-3.14-7.33-8.89-13.97-17.5-15.92c-1.67-0.38-3.34-0.55-5-0.55c-9.05,0-17.81,5.2-23.93,11.11c-8.03,7.75-11.66,17.94-8.2,28.47
      C734.55,380.94,742.82,389.73,752.5,395.52z M825,409c-2.18,6.26-6.07,16.06-6.56,17.8c-0.49,1.74-1.32,4.23-1.87,4.92
      c-0.85,1.06-1.91,1.62-3.61,2.19c-1.94,0.65-3.87,1.32-5.67,2.28c-0.01,0-0.02,0.01-0.03,0.01c-4.49-10.59-9.56-26.35-11.45-39.19
      c-0.12-0.86-0.22-1.72-0.32-2.59C805.23,399.48,814.22,406.28,825,409z M804.62,600.81c-0.58-0.77-1.16-1.53-1.74-2.29
      c0-5.52-0.04-11.67,0.16-17.7c0.79,6.9,2.17,13.3,3.69,16.87C805.56,597.73,804.65,598.88,804.62,600.81z M786.14,442.59
      c0.76,1.02,0.14,1.78-0.56,2.95c-1.05,1.75-2.35,4.06-3.22,6c-0.82-3.6-2.28-7.53-3.87-10.69c-2.94-5.88-20.42-12.94-32.43-14.66
      c3.73-4.04,6.23-9.12,8.13-14.05c1.05-2.73,1.96-5.53,2.66-8.37C771.67,429.03,785.38,441.56,786.14,442.59z"/>
    <path d="M778.74,488.8c0.01,0.04,0.01,0.08,0.02,0.12c0-0.02-0.01-0.03-0.01-0.05C778.75,488.85,778.74,488.83,778.74,488.8z"/>
    <path fill="#F5BA97" d="M816.76,438.55c1.74-1.14,3.08-5.14,4.38-8c2.2-4.85,4.57-9.62,6.77-14.47
      c0.8-1.81,1.85-4.05,2.05-6.39c0.2-2.34-0.44-5.71-2.31-6.62c-1.87-0.91-24.05-12.48-28.31-14.34c-4.27-1.85-8.24-3.65-10.7-5.03
      c-1.83-1.02-4.12-1.75-5.36-1.19c-0.73,0.33-1.77,1.21-2.38,1.7c-0.62,0.51-1.28,1.02-2.13,1.19c1.42,1.59,3.03,3.37,4.35,5.03
      c2.94,3.7,5.94,9.49,6.88,13.62c2.74-2.57,4.39-7.22,4.14-10.25c-0.73-0.49-5.77-4.62-4.61-4.18c6.32,2.4,23.87,15.52,38.29,18.74
      c1.33,0.29-0.92,1.22-1.28,1.14c-2.36,6.79-6.47,16.26-7.45,19.25c-0.98,2.99-1.58,4.31-3,5.32c-1.7,1.21-5.7,1.75-8.26,3.23
      c0.75,1.72,1.7,3.82,2.39,5.18C811.11,441.14,815.02,439.68,816.76,438.55z"/>
    <path fill="#FFD1B9" d="M824.83,402.57c-2.79-1.17-5.4-2.67-8.21-3.82c-2.85-1.17-6.57-3.34-9.3-4.73
      c-5.42-2.76-9.73-4.89-15.19-7.58c-2.23-1.09-4.68-2.61-7.22-2.94c-1.43-0.18-2.79,0.38-4.16,0.83c0.05-0.04,0.1-0.08,0.15-0.12
      c0.61-0.49,1.64-1.37,2.38-1.7c1.24-0.56,3.53,0.17,5.36,1.19c2.47,1.38,6.43,3.18,10.7,5.03c4.27,1.85,26.44,13.43,28.31,14.34
      c0.63,0.31,1.11,0.89,1.48,1.61C827.81,403.78,826.17,403.13,824.83,402.57z"/>
    <path fill="#F5BA97" d="M818.78,444.68c0.25-0.3-0.78-0.82-2.42-1.03c-1.64-0.22-3.75-0.33-5.33-0.03
      c0.57,0.46,1.18,0.95,1.67,1.43C814.31,444.83,818.59,444.92,818.78,444.68z"/>
    <path fill="#DEA283" d="M811.19,441.59c-0.43,0.3-0.77,0.6-0.97,0.89c-0.69-1.37-1.64-3.47-2.39-5.18
      c0.25-0.15,0.53-0.28,0.81-0.42C809.28,438.54,810.13,440.13,811.19,441.59z"/>
    <path fill="#DEA283" d="M824.22,422.43c1.12-2.67,2.15-5.37,3.3-8.03c0.65-1.51,1.6-2.9,2.39-4.35
      c-0.28,2.21-1.24,4.3-2,6.01c-2.2,4.85-4.57,9.62-6.77,14.47c-0.01,0.02-0.01,0.03-0.02,0.05
      C822.08,427.85,823.1,425.12,824.22,422.43z"/>
    <path fill="#DEA283" d="M794.13,393.8c0.06,0.79,0,1.68-0.18,2.63c-0.65-1.68-1.54-3.29-2.56-4.83
      C792.54,392.56,793.8,393.57,794.13,393.8z"/>
    <path fill="#DEA283" d="M814.26,444.93c-0.61,0.02-1.17,0.06-1.56,0.11c-0.49-0.48-1.1-0.97-1.67-1.43
      c0.52-0.1,1.09-0.15,1.68-0.17C813.19,443.97,813.71,444.47,814.26,444.93z"/>
    <path fill="#F5BA97" d="M810.87,562.85c-0.12-9.03-0.09-18.07,1.73-26.96c-0.62,0.03-1.63,0.14-2.26,0.18
      c0.11,1.16,0.22,2.61,0.11,4.95c-0.11,2.34-1.04,5.56-2.79,10.38c-1.85,5.11-3.17,12.31-3.93,18.26c0.18,2.54,0.4,7.47,0.82,11.34
      c0.69,6.41,2.05,16.23,5.08,16.23C810.77,587.93,810.99,571.52,810.87,562.85z"/>
    <path fill="#C54458" d="M808.17,599.17c-1.99-0.47-2.47,1.32-2.57,2.96c0.43,0.65,1.58,2.31,2.04,3.12
      C807.32,603.15,807.31,600.88,808.17,599.17z"/>
    <path fill="#F5BA97" d="M814.37,610.26c-0.29-1.91-0.73-3.78-1.51-5.57c-0.41-0.86-2.54-4.14-3.85-5.21
      c-1.13,2.37-0.38,5.8-0.09,8.35c1.9,4.82,0.57,11.03,1.1,15.4c3.68,0.1,5.15-1.18,5.55-2.3
      C814.87,618.45,814.77,613.35,814.37,610.26z"/>
    <path fill="#C54458" d="M825.03,626.38c-1.02-0.94-4.79-3.51-8.46-4.35c-2.23,2.1-5.69,2.39-6.49,2.34
      c1.39,1.24,3.77,2.89,5.28,4.27C819.05,628.27,822.27,627.57,825.03,626.38z"/>
    <path fill="#C54458" d="M802.88,630.98c1.71,1.3,14.03,2.29,14.03,1.84c0-0.45-3.15-4.62-6.51-6.41
      c-1.71-0.45-5.07-0.5-8.91-0.45C801.38,626.87,801.17,629.69,802.88,630.98z"/>
    <path fill="#E17684" d="M815.74,622.69c0.28-0.2,0.56-0.42,0.82-0.66c3.67,0.84,7.45,3.41,8.46,4.35
      c-0.27,0.12-0.56,0.23-0.84,0.34C821.96,624.59,818.92,623.31,815.74,622.69z"/>
    <path fill="#E17684" d="M809.36,627.22c-0.86-0.3-3.97-0.92-5.31-1.27c2.74,0.01,5.04,0.12,6.35,0.46
      c2.03,1.08,3.98,3.04,5.21,4.51C814.96,630.41,810.21,627.52,809.36,627.22z"/>
    <path fill="#F5BA97" d="M801.49,624.87c2.13-0.15,5.65-0.1,7.52,0.3c-0.8-3.23,0.32-8.99-0.48-14.36
      c-0.57-3.8-5.54-12-7.73-12.32C802.35,606.37,801.6,623.78,801.49,624.87z"/>
    <path fill="#FFD1B9" d="M813,607.82c-0.22-1.61-0.58-3.17-1.07-4.69c0.44,0.68,0.79,1.26,0.94,1.56
      c0.78,1.79,1.22,3.66,1.51,5.57c0.39,3.09,0.5,8.2,1.2,10.67c-0.03,0.1-0.08,0.19-0.13,0.29
      C813.09,617.18,813.62,612.26,813,607.82z"/>
    <path fill="#FFD1B9" d="M808.73,622.88c-0.84-1.99-0.87-4.26-0.93-6.39c-0.06-2.32-0.24-4.66-0.69-6.95
      c-0.55-2.75-1.45-5.34-2.83-7.7c1.97,2.79,3.91,6.68,4.25,8.97C809.17,615.08,808.6,619.59,808.73,622.88z"/>
    <path fill="#C54458" d="M796.32,598.89c-0.27,1.04,0.15,2.08,0.75,2.49c0.82,0.56,2.19,1.25,2.93,1.84
      c-0.22-1.32-0.28-4.17-0.64-5.46C797.97,597.26,796.59,597.85,796.32,598.89z"/>
    <path fill="#A92A44" d="M807.45,603.2c0.02,0.69,0.09,1.38,0.19,2.05c-0.47-0.81-1.61-2.48-2.04-3.12
      c0.03-0.44,0.09-0.89,0.19-1.3C806.28,601.65,806.84,602.44,807.45,603.2z"/>
    <path fill="#A92A44" d="M810.95,624.34c1.83,1.41,4.06,2.31,6.46,2.74c1.2,0.22,2.66,0.46,4.1,0.49
      c-1.88,0.5-3.93,0.84-6.15,1.07c-1.51-1.39-3.9-3.03-5.28-4.27C810.25,624.38,810.57,624.38,810.95,624.34z"/>
    <path fill="#A92A44" d="M804,629.95c2.11,0.75,4.3,1.16,6.53,1.42c1.11,0.13,2.23,0.3,3.35,0.39
      c0.82,0.06,1.66,0.02,2.47,0.11c0.35,0.49,0.55,0.83,0.55,0.95c0,0.44-12.32-0.55-14.03-1.84c-0.75-0.56-1.12-1.41-1.31-2.27
      C802.29,629.21,803.11,629.63,804,629.95z"/>
    <path fill="#A92A44" d="M799.84,601.8c0.04,0.55,0.09,1.04,0.16,1.41c-0.75-0.59-2.12-1.28-2.93-1.84
      c-0.16-0.11-0.3-0.26-0.42-0.43C797.63,601.47,798.79,601.49,799.84,601.8z"/>
    <path fill="#F5BA97" d="M795.13,549.44c-1.24,6.15-1.53,10.92-0.85,20.14c0.64,8.6,3.65,17.53,5.29,26.78
      c0.69,0.15,1.49,0.49,2.24,0.94c-0.03-9.73-1.05-20.05,0.32-29.7c0.67-4.71,1.49-9.47,2.98-14.02c1.4-4.28,3.86-10.19,3.97-11.84
      c0.12-1.65-0.03-4.04-0.17-5.58c-3.38,0.2-7.17,0.64-10.53,0.95C797.81,541.4,796.26,543.8,795.13,549.44z"/>
    <path fill="#DEA283" d="M809.94,594.28c-0.1,1.05-0.2,2.04-0.31,2.96c-0.85,0-1.56-0.77-2.17-2.02
      C808.27,595.18,809.14,594.82,809.94,594.28z"/>
    <path fill="#DEA283" d="M807.66,551.41c1.75-4.82,2.68-8.04,2.79-10.38c0.11-2.34,0.01-3.79-0.11-4.95
      c0.63-0.04,1.64-0.15,2.26-0.18c-1.11,5.42-1.55,10.89-1.7,16.38c-0.45-0.15-0.94-0.08-1.48,0.28c-2.21,1.53-2.24,4.96-2.58,7.27
      c-0.84,5.72-1.03,11.53-1.54,17.27c-0.19,2.09-0.36,4.19-0.48,6.29c-0.1-0.83-0.19-1.63-0.28-2.38c-0.41-3.88-0.64-8.8-0.82-11.34
      C804.49,563.72,805.81,556.52,807.66,551.41z"/>
    <path fill="#DEA283" d="M814.68,622.13c-0.84,0.66-2.29,1.17-4.65,1.1c-0.17-1.38-0.15-2.95-0.1-4.6
      C810.96,620.39,812.45,621.72,814.68,622.13z"/>
    <path fill="#DEA283" d="M808.9,624.61c0.03,0.19,0.07,0.38,0.12,0.56c-1.63-0.35-4.51-0.43-6.64-0.35
      C804.45,624.27,806.75,624.27,808.9,624.61z"/>
    <path fill="#DEA283" d="M797.98,539.27c0.16-0.68,0.29-1.39,0.4-2.17c3.37-0.3,7.16-0.74,10.53-0.95
      c0.07,0.82,0.15,1.87,0.19,2.93C805.97,537.19,801.22,537.89,797.98,539.27z"/>
    <path fill="#DEA283" d="M801.77,594.11c0.02,1.07,0.04,2.14,0.04,3.19c-0.75-0.45-1.55-0.8-2.24-0.94
      c-0.06-0.34-0.13-0.69-0.19-1.03C800.34,595.18,801.13,594.74,801.77,594.11z"/>
    <path fill="#CF4479" d="M778.74,488.79c0.01,0.03,0.01,0.05,0.01,0.08c0.01,0.06,0.02,0.11,0.02,0.17
      c-0.01-0.04-0.01-0.08-0.02-0.12c0.71,4.75,2.02,9.41,4,13.85c1.68,3.66,4.19,7.84,5.83,10.43c1.64,2.59,4.12,4.11,4.86,4.73
      c0.74,0.62,1.41,3.11,0.99,6.82c-0.49,4.27-2.02,8.91-6.67,11.5c9.93-1.12,21.2-1.93,31.19-2.54c-2.79-2.54,3.44-14.55,5.57-19.64
      c2.13-5.09,3.17-13.08,3.61-20.96c0.43-7.88-0.3-14.14-1.02-19.37c-0.63-4.29-1.74-8.5-3.52-12.51c-1.54-3.35-3.22-5.87-6.17-9.33
      c-2.95-3.46-7.32-7.07-8.33-8.04c-1.01-0.97-2.65-4.99-4.72-10.79c-2.08-5.8-7.03-19.29-9.48-34.49c-0.53,2.25-2.35,5.49-4.52,6.88
      c1.09,4.03,1.85,8.14,2.37,12.26c0.37,2.97,0.44,5.06,0.32,8.95c-0.12,3.9-1.02,6.32-1.59,6.01c-0.47-3.03-1.5-5.96-2.76-8.79
      c-2.49-5.43-5.43-10.06-9.13-14.85c-1.63,0.34-6.71,0.89-10.21,0.1c-2.28-0.51-7.09-1.42-10.42-5.02
      c8.29,14.21,19.47,29.5,28.94,38.2c1.58,1.46,0.11,3.06-1.37,5.39c-1.47,2.31-2.52,5.35-3.55,8.16c-1.04,2.81-3.26,9.86-4.15,15.75
      C778.01,477.32,777.95,483.09,778.74,488.79z"/>
    <path fill="#E3769D" d="M806.94,439.91c-2.39-4.01-4.23-8.25-5.85-12.58c-1.81-4.84-2.88-9.86-4.02-14.86
      c-0.91-4.02-1.6-8.09-1.87-12.18c2.53,14.5,7.19,27.2,9.19,32.78c1.02,2.85,1.93,5.26,2.72,7.1
      C807.05,440.09,806.99,440,806.94,439.91z"/>
    <path fill="#E3769D" d="M818.08,533.76c-6.17,0.38-12.78,0.84-19.24,1.4c2.97-0.94,5.9-1.76,9.09-2.01
      C811.37,532.86,814.72,533.23,818.08,533.76z"/>
    <path fill="#E3769D" d="M782.98,455.87c0.94-2.54,1.9-5.27,3.16-7.48c-0.69,1.78-1.24,3.62-1.73,5.46
      c-0.79,2.98-1.63,5.96-2.36,8.95c-2.37,9.8-3.56,20.03-2.54,30.06c-0.29-1.31-0.54-2.62-0.74-3.95c0.01,0.04,0.01,0.08,0.02,0.12
      c-0.01-0.06-0.02-0.11-0.02-0.17c0-0.03-0.01-0.05-0.01-0.08c-0.79-5.71-0.73-11.48,0.08-17.17
      C779.71,465.73,781.94,458.68,782.98,455.87z"/>
    <path fill="#B62968" d="M793.08,426.67c0-0.04,0-0.08,0-0.12c0.09,1.97,0.08,3.92-0.2,5.89c-0.36,2.45-0.91,4.87-1.37,7.31
      c-1.2-3.54-2.71-6.97-4.22-10.4c-1.56-3.57-3.01-7.2-4.8-10.67c-1.73-3.33-3.92-6.36-6.35-9.22c1.5-0.1,2.77-0.28,3.45-0.42
      c3.71,4.79,6.65,9.42,9.13,14.85c1.26,2.83,2.29,5.75,2.76,8.79C792.06,432.98,792.96,430.56,793.08,426.67z"/>
    <path fill="#B62968" d="M822.5,514.89c1.39-4.57,3.2-9.01,4.2-13.68c0.52-2.44,1-4.89,1.4-7.35
      c-0.46,7.64-1.5,15.28-3.56,20.21c-0.65,1.56-1.7,3.78-2.75,6.18C821.7,518.45,821.94,516.71,822.5,514.89z"/>
    <path fill="#B62968" d="M771.96,422.12c2.03,3.57,4.24,7.07,6.73,10.38c0.09,0.11,0.17,0.22,0.26,0.32
      c-7.03-8.36-14.21-18.79-19.99-28.7c1.87,2.02,4.21,3.19,6.31,3.92C767.15,412.87,769.38,417.59,771.96,422.12z"/>
    <path fill="#B62968" d="M788.67,513.31c2.01,1.76,4.39,2.75,6.62,4.2c1.3,0.84,2.38,1.94,2.78,3.41
      c0.4,1.44,0.4,3.04,0.33,4.52c-0.11,2.39-0.67,4.72-2.49,6.52c-1.57,1.56-3.62,2.59-5.79,3.25c-0.43,0.13-0.86,0.25-1.29,0.36
      c3.82-2.67,5.16-6.9,5.61-10.83c0.42-3.7-0.25-6.2-0.99-6.82C792.73,517.32,790.31,515.84,788.67,513.31z"/>
    <path fill="#F5BA97" d="M764.57,463.42c0.76,0.35,2.14,0.93,3.1,1.27c1.89,0.67,6.9,1.5,7.72,1.49
      c-1.29-0.59-2.49-1.37-3.05-1.71C770.06,463.41,765.95,462.25,764.57,463.42z"/>
    <path fill="#F5BA97" d="M769.7,456.04c-0.84-0.59-2.05-1.22-2.18-0.99c-0.12,0.22-0.14,0.89,0.16,1.17
      c1.3,0.96,4.16,3.85,6.03,4.55c0.22,0.08,0.36-0.41,0.5-0.78C772.89,458.94,770.41,456.55,769.7,456.04z"/>
    <path fill="#F5BA97" d="M749.9,407.81c-3.01,6.46-8.3,11.69-11.46,18.02c-0.26,0.6-0.47,1.2-0.59,1.84
      c-0.04,0.37-0.05,0.73-0.02,1.11c0.06,0.32,0.15,0.63,0.28,0.93c0.13,0.24,0.28,0.45,0.44,0.66c0.22,0.22,0.44,0.43,0.69,0.62
      c0.57,0.38,1.18,0.68,1.82,0.94c2.38,0.9,4.92,1.31,7.41,1.88c3,0.69,5.99,1.46,8.99,2.18c5.73,1.39,11.54,2.87,16.9,5.26
      c-0.02-0.04,0.63-1.18,0.89-0.64c1.56,3.24,2.09,7.26,2.01,10.77c-0.06,2.96-1.65,7.84-2.01,8.86c-0.36,1.02-0.8,1.66-1.32,1.47
      c-0.24-0.08-1.6-0.82-3-1.97c-1.39-1.15-2.47-2.28-2.97-2.64c-0.92-0.65-2.2-0.93-2.1-0.58c0.1,0.35,1.29,2.2,1.76,2.79
      c1.22,1.42,2.37,2.28,3.59,3.29c1.22,1,4.57,3.09,6.58,3.31c0.39-1.7,0.85-3.38,1.37-5.03c0.61-1.93,1.44-4.83,2.36-7.32
      c0.02-1.28-1.93-9.55-4.1-13.04c-0.97-1.56-3.42-3.36-5.28-4.44c-2.87-1.67-6.19-3.24-9.07-4.4c-5.32-2.08-12.52-3.74-17.62-4.46
      c-1.3,1.36-3.28,1.84-2.92,1.51c0.36-0.34,1.93-2.23,3.53-4.33c1.6-2.1,4.07-5.99,5.57-9.25c2.47-5.5,4.89-13.41,5.95-19.58
      c0.28-1.66,0.81,0.87,0.49,5.26c2.15,5.45,14.26,8.51,20.54,6.96c-0.55-0.69-1.1-1.38-1.66-2.06c-4.8-5.77-10.23-10.97-16.41-15.46
      c0,0,0,0,0,0c-1.24-0.88-2.5-1.75-3.81-2.56C754.15,394.33,752.92,401.33,749.9,407.81z"/>
    <path fill="#FFD1B9" d="M751.67,406.17c-1.84,4.29-4.57,8.1-7.52,11.79c-1.43,1.79-2.92,3.59-4.35,5.42
      c3.16-5.28,7.49-9.96,10.1-15.56c2.09-4.48,3.33-9.22,4.74-13.9C754.27,398.1,753.34,402.28,751.67,406.17z"/>
    <path fill="#FFD1B9" d="M752.39,428.54c3.66,0.83,7.5,1.92,10.67,3.16c2.88,1.15,6.21,2.73,9.07,4.4
      c1.25,0.73,2.78,1.79,3.94,2.88c-0.05-0.03-0.1-0.06-0.15-0.09c-2.92-1.56-6.02-2.77-9.08-4.06
      C762.01,432.75,757.23,430.59,752.39,428.54z"/>
    <path fill="#DEA283" d="M774.44,408.19c-5.65-6.49-11.27-13.33-18.67-17.83c0.3-0.89,0.62-1.77,0.96-2.64
      c1.3,0.81,2.57,1.67,3.81,2.56c0,0,0,0,0-0.01c6.18,4.48,11.61,9.69,16.41,15.46c0.56,0.68,1.11,1.36,1.66,2.06
      C777.39,408.09,775.96,408.22,774.44,408.19z"/>
    <path fill="#DEA283" d="M777.27,461.29c1.16-3.41,2.23-6.93,3.83-10.21c0.26,1.21,0.42,2.12,0.41,2.48
      c-0.92,2.49-1.75,5.39-2.36,7.32c-0.52,1.65-0.98,3.33-1.37,5.03c-0.52-0.06-1.12-0.24-1.76-0.49
      C776.38,464.03,776.81,462.65,777.27,461.29z"/>
    <path fill="#DEA283" d="M757.65,435.41c6.13,0.84,12.03,1.86,17.09,5.24c-0.22,0.23-0.42,0.59-0.41,0.61
      c-5.36-2.4-11.16-3.88-16.9-5.26c-1.5-0.36-3.01-0.73-4.51-1.1C754.51,435.03,756.09,435.19,757.65,435.41z"/>
    <path fill="#DEA283" d="M745.44,427.23c0.95,0.14,1.99,0.31,3.07,0.51c-1.53,0.43-3.1,0.7-4.65,0.66
      C744.37,428.12,744.96,427.74,745.44,427.23z"/>
    <path fill="#DEA283" d="M762.84,404c0.17,0.52,0.33,1.05,0.49,1.58c-2.52-1.23-4.51-2.85-5.26-4.76
      c0.13-1.72,0.12-3.15,0.05-4.16C760.19,398.88,761.89,401.12,762.84,404z"/>
    <path fill="#D49632" d="M749.54,392.05c1.13,0.81,2.31,1.58,3.5,2.31c0.72-2.43,1.85-5.48,2.5-7.36
      c-1.81-1.07-5.56-2.92-8.69-3.91c-0.86-0.27,0.92-0.59,3.37,0.27c4.47,1.57,8.06,3.62,11.77,6.32c4.21,3.07,8.1,6.5,11.64,10.24
      c7.37,7.78,15.31,18.66,18.11,28.27c0.57-2.42,0.4-7.97-0.12-11.75c-0.88-6.03-2.11-12.18-4.63-17.82
      c-1.45-3.09-3.83-6.58-6.03-9.25c-3.61-4.32-8.28-8.68-11.12-13.76c-2.84-5.08-4.53-8.08-5.07-13.23
      c-0.54-5.15-0.23-9.51,2.99-15.02c3.23-5.5,8.69-7.51,7.08-6.14c-0.67,0.57-1.28,1.02-1.92,1.6c1.91,1.1,3.73,2.33,5.31,3.84
      c1.32,1.26,2.34,2.75,2.96,4.42c0.79,2.13,0.28,4.38,0.22,6.55c0.5,0.03,2.41,0.96,1.66,0.88c-0.44-0.05-1-0.02-1.43-0.03
      c1.03,1.02,2.54,2.39,3.61,3.35c1,0.9-0.62,2.28-1.09,2.6c-0.56,0.37-1.18,0.59-1.71,0.77c-0.04,0.23,0,0.56,0.3,0.9
      c0.56,0.64,1.69,1.2,2.04,1.89c0.31,0.62-0.38,1.03-1.05,1.27c0.61,0.25,1,0.68,0.96,1.3c-0.08,1-1.81,1.5-2.31,1.68
      c-0.5,0.19-0.81,0.33-0.79,0.86c0.02,0.53,0.18,1.2,0.26,1.59c0.19,1.01-0.2,2.4-2.26,2.98c-2.26,0.64-5.09,0.3-7.48-0.63
      c1.57,2.64,5.02,6.55,6.16,7.83c2.19-0.94,2.95-2.18,3.83-2.8c1.21-0.85,2.92-0.9,4.29-0.43c1.5,0.51,2.85,1.29,4.2,2.08
      c-0.02-12.91-2.17-25.92-6.14-35.96c-1.04-2.63-2.63-5.03-4.45-7.26c-1.27-1.51-2.71-2.89-4.34-4.08
      c-1.39-0.98-2.89-1.81-4.49-2.46c-1.75-0.67-3.57-1.13-5.44-1.39c-1.63-0.18-3.26-0.16-4.88,0.02c-2.31,0.31-4.54,0.92-6.69,1.74
      c-3.18,1.26-6.12,2.94-8.87,4.89c-2.92,2.12-5.57,4.55-7.86,7.27c-1.61,1.95-2.99,4.04-4.04,6.31c-0.9,2.05-1.52,4.18-1.85,6.37
      c-0.28,2.21-0.26,4.43,0.04,6.64c0.42,2.74,1.24,5.4,2.38,7.96c1.52,3.31,3.53,6.39,5.87,9.25
      C742.65,386.39,745.92,389.41,749.54,392.05z"/>
    <path fill="#F0BB6B" d="M777.71,339.23c-4.03-3.18-9.71-6.02-15.17-5.53c-3.04,0.28-5.91,1.41-8.69,2.53
      c-2.74,1.1-5.39,2.42-7.72,4.16c-2.72,2.03-8.51,7.64-10,10.3c-2.84,5.08-4.45,9.43-4.78,12.24c-0.03-1.26,0.03-2.51,0.19-3.77
      c0.32-2.2,0.95-4.32,1.85-6.37c1.05-2.27,2.43-4.35,4.04-6.31c2.29-2.72,4.94-5.14,7.86-7.27c2.74-1.95,5.69-3.63,8.87-4.89
      c2.15-0.82,4.38-1.43,6.69-1.74c1.63-0.18,3.25-0.2,4.88-0.02c1.87,0.26,3.69,0.71,5.44,1.39c1.6,0.66,3.1,1.48,4.49,2.46
      c1.63,1.19,3.06,2.57,4.34,4.08c1.14,1.39,2.19,2.86,3.07,4.4C781.56,342.79,779.78,340.85,777.71,339.23z"/>
    <path fill="#F0BB6B" d="M769.83,375.61c2.84,5.08,7.51,9.44,11.12,13.76c2.2,2.66,4.59,6.16,6.03,9.25
      c0.34,0.76,0.65,1.53,0.95,2.3c-0.54-1.25-1.15-2.48-1.83-3.68c-1.4-2.46-3.04-4.8-4.91-6.98c-2.02-2.35-4.3-4.42-6.55-6.57
      c-4.85-4.62-8.86-10.32-10.36-16.68c-1.28-5.42-1.44-11.71,0.97-16.9c0.96-2.06,2.14-4.12,3.67-5.87c1.22-1.39,2.92-3,4.94-2.98
      c-1.55,0.84-4.22,2.86-6.11,6.1c-3.22,5.51-3.53,9.87-2.99,15.02C765.3,367.53,766.99,370.54,769.83,375.61z"/>
    <path fill="#B87712" d="M750.81,390.08c-1.17-1.33-2.4-2.87-2.97-4.53c-0.28-0.8-0.5-1.61-0.8-2.39
      c3.09,1,6.71,2.79,8.49,3.84c-0.51,1.49-1.32,3.7-1.99,5.76C752.58,391.91,751.66,391.03,750.81,390.08z"/>
    <path fill="#B87712" d="M783.84,367.04c-0.4-0.31-0.82-0.61-1.1-0.94c-0.3-0.34-0.35-0.67-0.3-0.9
      c0.34-0.11,0.71-0.25,1.07-0.42c0.08,0.55,0.16,1.11,0.24,1.67C783.78,366.64,783.81,366.84,783.84,367.04z"/>
    <path fill="#B87712" d="M784.61,368.78c0.22-0.21,0.33-0.47,0.17-0.79c-0.08-0.16-0.22-0.32-0.37-0.48
      c-0.06-0.4-0.13-0.8-0.18-1.19c-0.09-0.59-0.18-1.19-0.26-1.79c0.06-0.03,0.12-0.07,0.18-0.11c0.47-0.31,2.09-1.7,1.09-2.6
      c-0.64-0.57-1.43-1.29-2.19-1.99c-0.13-0.45-0.28-0.9-0.45-1.34c0.16,0,0.32,0.01,0.46,0.02c0.46,0.05-0.08-0.28-0.67-0.55
      c-0.17-0.39-0.35-0.77-0.56-1.15c-0.11-0.2-0.23-0.38-0.34-0.57c0.14-1.73,0.32-3.49-0.3-5.17c-0.62-1.67-1.64-3.16-2.96-4.42
      c-1.58-1.51-3.4-2.74-5.31-3.84c0.22-0.2,0.45-0.39,0.67-0.57c4.01,0.15,6.4,3.4,8.36,6.99c2.41,4.44,3.07,8.46,3.85,13.33
      c0.13,0.85,0.36,3.59,0.67,6.27c0.12,1.05,0.54,3.84,0.73,6.21c0.3,3.67,0.34,7.07,0.24,7.02c-0.34-0.15-0.69-0.29-1.05-0.42
      c-0.16-0.05-0.32-0.1-0.49-0.14C785.97,377.27,785.28,372.96,784.61,368.78z"/>
    <path fill="#B87712" d="M779.58,377.68c2.07-0.58,2.45-1.98,2.26-2.98c-0.07-0.39-0.24-1.06-0.26-1.59
      c-0.02-0.53,0.29-0.67,0.79-0.86c0.4-0.15,1.62-0.5,2.11-1.17c0.52,3.42,0.98,6.9,0.93,10.34c-1.14-0.17-2.38,0-3.31,0.66
      c-0.89,0.62-1.65,1.86-3.83,2.8c-1.14-1.28-4.59-5.19-6.16-7.83C774.49,377.98,777.31,378.33,779.58,377.68z"/>
    <path fill="#F5BA97" d="M781.34,374.98c-0.15-0.98-0.29-2.18,0.1-2.65c0.29-0.34,0.79-0.51,1.14-0.65
      c0.35-0.14,0.75-0.34,1.12-0.61c0.38-0.27,0.39-0.89,0.06-1.15c-0.34-0.25-0.79-0.36-1.14-0.35c-0.18,0-0.91-0.21-0.47-0.31
      c0.37-0.09,1.95-0.45,2.1-1.08c0.11-0.47-2.48-1.61-2.32-3.33c0.46-0.19,1.47-0.7,1.85-0.93c0.38-0.23,0.81-0.76,0.95-1.03
      c0.14-0.28,0.24-0.48,0-0.72c-0.23-0.24-2.96-2.8-3.7-3.54c-0.32-0.01-0.96,0.04-1.44,0.08c-1.41,2.39-6.18,2.91-7.69,0.7
      c-0.16-0.25-0.02-1.12,0.44-1.18c0.45-0.06,5.62-0.45,6.32-0.52c0.07-0.5-0.05-1.66-0.44-2.65c-0.39-1-1.36-2.24-2.6-2.99
      c0.18-0.15,0.97-0.27,1.53,0.27c0.55,0.54,1.4,1.48,1.75,2.57c0.36,1.09,0.46,2.21,0.46,2.84c0.4-0.03,0.93-0.01,1.33-0.03
      c0.06-1.63,0.39-3.41,0.17-5.05c-0.14-0.74-0.36-1.44-0.66-2.13c-0.41-0.85-0.92-1.62-1.52-2.36c-0.96-1.11-2.06-2.06-3.26-2.94
      c-0.97-0.69-1.99-1.31-3.02-1.9c-0.75,0.74-1.59,1.73-2.68,3.32c-2.31,3.39-3.1,6.58-3.55,8.67c-0.38,2-0.49,4.01-0.35,6.03
      c0.23,2.51,0.83,4.94,1.73,7.32c0.95,2.41,2.17,4.72,3.58,6.92c1.64,1.03,4.52,1.77,6.71,1.74c0.84-0.01,2.21-0.28,2.97-0.99
      C781.15,376,781.41,375.43,781.34,374.98z"/>
    <path fill="#FFD1B9" d="M784.75,362.21c-1.66-0.43-2.93-1.98-3.55-3.43c0.9,0.89,3.32,3.16,3.54,3.38
      c0.02,0.02,0.04,0.05,0.06,0.07C784.78,362.23,784.77,362.22,784.75,362.21z"/>
    <path fill="#FFD1B9" d="M780.21,352.77c-0.3-1.58-1.04-2.9-2.07-4.22c-1.25-1.61-2.7-3.24-4.43-4.46
      c0.58,0.36,1.16,0.73,1.71,1.12c1.2,0.88,2.3,1.83,3.26,2.94c0.6,0.74,1.11,1.52,1.52,2.36c0.3,0.69,0.52,1.4,0.66,2.13
      c0.17,1.3,0,2.68-0.11,4.01C780.54,355.47,780.43,353.96,780.21,352.77z"/>
    <path fill="#DEA283" d="M783.78,363.91c-0.38,0.23-1.39,0.74-1.85,0.93c-0.01,0.06,0,0.11,0,0.16
      c-0.15-0.29-0.22-0.6-0.01-0.87c0.27-0.35,0.73-0.57,1.15-0.74c0.58-0.25,1.2-0.46,1.76-0.75c-0.03,0.07-0.06,0.15-0.1,0.24
      C784.59,363.15,784.16,363.68,783.78,363.91z"/>
    <path fill="#DEA283" d="M766.17,355.31c0.45-2.09,1.24-5.28,3.55-8.67c1.09-1.6,1.93-2.58,2.68-3.32
      c0.38,0.22,0.75,0.44,1.11,0.67c-1.65,1.77-2.76,3.98-3.58,6.2c-1.41,3.83-2.09,7.87-1.75,11.92c0.41,4.84,1.97,10.05,4.84,14.36
      c-0.72-0.25-1.38-0.55-1.9-0.89c-1.41-2.21-2.63-4.51-3.58-6.92c-0.89-2.38-1.5-4.81-1.73-7.32
      C765.68,359.31,765.79,357.3,766.17,355.31z"/>
    <path fill="#C75862" d="M782.62,369.57c0.34-0.01,0.8,0.1,1.14,0.35c0.33,0.26,0.32,0.88-0.06,1.15
      c-0.14,0.1-0.57,0.32-0.88,0.36c-0.52,0.08-1.07-0.11-1.16-0.58c-0.09-0.47-0.42-1.19-0.66-1.33c-0.24-0.14-0.41-0.35-0.13-0.37
      c1.05-0.04,2.28-0.38,2.69-1.73c0.41,0.34,0.72,0.6,0.68,0.77c-0.15,0.63-1.73,0.99-2.1,1.08
      C781.72,369.37,782.44,369.58,782.62,369.57z"/>
    <path fill="#FFFFFF" d="M775.17,360.27c2.02-0.12,3.31-0.79,3.77-1.66c-0.21,0.02-2.31,0.12-3.44,0.27
      C775.53,359.23,775.59,359.94,775.17,360.27z"/>
    <path d="M787.72,478.22c4.04-0.04,7.01,2.62,10.01,4.8c1.26,0.89,4.48,3.02,6.86,3.02c0.91,0-1.08,0.88-1.97,0.77
      c-2.99-0.38-4.76-2.44-7.79-4.64c-1.58-1.14-3.1-2.18-4.86-2.62c-1.76-0.44-2.87-0.33-4.22-0.22
      C784.84,479.4,785.75,478.24,787.72,478.22z"/>
    <path d="M781.33,375.38L781.33,375.38C781.33,375.39,781.33,375.39,781.33,375.38C781.33,375.38,781.33,375.38,781.33,375.38z"/>
    <path d="M770.26,353.49c-0.07,0.42-0.47-0.71-0.11-1.91c0.7-2.28,2.23-4.14,4.38-4.01c0.93,0.06,1.35,0.31,1.82,0.67
      c0.6,0.46,0.72,0.99,0.52,0.84c-0.44-0.32-0.86-0.57-1.22-0.73c-0.29-0.1-0.57-0.18-0.87-0.22c-0.21-0.01-0.41-0.01-0.62,0
      c-0.2,0.03-0.39,0.08-0.58,0.14c-0.26,0.11-0.48,0.23-0.71,0.38c-0.35,0.26-0.65,0.56-0.93,0.88c-0.4,0.52-0.72,1.06-0.99,1.65
      C770.61,351.94,770.39,352.7,770.26,353.49z"/>
    <path d="M781.75,362.85c0.11,0.02,0.11,0.19,0,0.2c-0.23,0.03-0.48,0.06-0.67,0.2c-0.29,0.21-0.39,0.53-0.44,0.86
      c-0.05,0.31-0.43,0.03-0.5-0.33C779.98,362.89,780.91,362.71,781.75,362.85z"/>
    <path d="M805.92,539.37c0.04-0.11,0.19-0.08,0.18,0.04c-0.18,1.6,0.03,3.19-0.05,4.79c-0.01,0.23-0.38,0.33-0.45,0.08
      C805.16,542.67,805.37,540.93,805.92,539.37z"/>
    <path d="M819.26,444.59c0.1,0.04,0.03,0.19-0.08,0.15c-0.71-0.27-1.35-0.51-2.13-0.49c-0.66,0.02-1.53,0.25-1.68,0.94
      c-0.03,0.14-0.27,0.07-0.23-0.07C815.49,443.51,818.23,444.21,819.26,444.59z"/>
    <path d="M763.75,463.45c0.93-0.38,2.32-0.36,3.28-0.11c0.61,0.16,1.44,1.14,0.59,1.49c-0.05,0.02-0.1-0.03-0.07-0.08
      c0.68-1.08-0.97-1.3-1.71-1.32c-0.69-0.02-1.35,0.16-2.03,0.22C763.69,463.66,763.65,463.5,763.75,463.45z"/>
    <path d="M766.04,456.17c1.3,0.3,3.34,3.41,1.61,4.2c-0.12,0.05-0.24-0.09-0.14-0.17c1.29-1.08-0.41-3.25-1.55-3.86
      C765.86,456.29,765.92,456.14,766.04,456.17z"/>
    <path fill="#FFFFFF" d="M773.34,358.5c0.32,0.04,0.56,0.31,0.51,0.62c-0.04,0.3-0.34,0.51-0.66,0.47
      c-0.32-0.04-0.55-0.31-0.51-0.61C772.72,358.68,773.01,358.47,773.34,358.5z"/>
    <path fill="#FFFFFF" d="M774.15,359.25c0.13,0.01,0.23,0.13,0.21,0.25c-0.01,0.12-0.14,0.21-0.27,0.19
      c-0.13-0.01-0.22-0.13-0.21-0.25C773.89,359.32,774.01,359.24,774.15,359.25z"/>
    <path d="M763.51,353.92c0.51-2.47,0.98-3.65,1.83-6.04c0.38-1.08,0.76-1.19,0.73-1.11c-4.37,10.6-3.82,21.61,0.69,31.54
      c2.52,5.55,7.6,9.7,11.8,14.15c4.67,4.94,9.12,10.18,11.22,16.53c1.03,3.11,1.71,6.31,2.49,9.47c0.03,0.11-0.15,0.16-0.18,0.05
      c-0.96-3.26-1.63-6.57-2.73-9.8c-1.14-3.32-2.89-6.2-5.05-9.03c-2.2-2.88-4.64-5.59-7.17-8.22c-2.25-2.33-4.78-4.46-6.88-6.92
      C763.08,376.13,761.39,364.25,763.51,353.92z"/>
    <path d="M756.4,355.58c0.16-1.05,0.37-1.18,0.36-1.08c-2.32,12.69,3.38,24.55,11.31,34.57c3.87,4.88,8.47,9.15,12.57,13.84
      c4.2,4.8,7.19,10.4,9.17,16.28c1.25,3.71,2.02,7.55,2.41,11.41c0.02,0.2-0.32,0.21-0.34,0.01c-0.62-6.35-2.36-12.56-5.3-18.33
      c-1.49-2.93-3.34-5.7-5.47-8.25c-1.99-2.4-4.35-4.51-6.51-6.78c-8.21-8.6-15.94-18.47-18.12-30.05
      C755.69,362.99,755.78,359.82,756.4,355.58z"/>
    <path d="M781.81,372.24c0.83,3.22,1.47,6.49,1.87,9.78c-0.1,0.04-0.19,0.08-0.29,0.13c-0.39-3.3-0.97-6.57-1.75-9.82
      C781.7,372.31,781.75,372.28,781.81,372.24z"/>
    <path d="M780.3,377c0.56,2,1.03,4.02,1.42,6.06c-0.15,0.1-0.3,0.19-0.45,0.29c-0.42-2.08-0.85-4.15-1.37-6.2
      C780.04,377.13,780.17,377.08,780.3,377z"/>
    <path d="M778.53,377.64c0.61,2.14,1.09,4.32,1.59,6.48c-0.05,0.04-0.11,0.08-0.16,0.11c-0.58-2.17-1.08-4.36-1.68-6.53
      C778.37,377.7,778.45,377.68,778.53,377.64z"/>
    <path d="M773.71,341.71c2.6-0.36,4.69,1.59,6.19,3.31c2.24,2.56,3.74,5.67,4.74,8.81c1.18,3.73,1.59,7.66,1.97,11.52
      c0.21,2.12,0.37,4.26,0.64,6.38c0.24,1.88,0.41,3.7,0.34,5.6c-0.01,0.27-0.1,0.44-0.1,0.37c0.05-1.72-0.18-3.95-0.41-5.65
      c-0.24-1.84-0.41-3.56-0.56-5.41c-0.31-3.67-0.66-7.29-1.58-10.89c-0.8-3.14-2.1-6.14-3.97-8.85c-1.54-2.22-4-5.33-7.22-4.96
      C773.6,341.96,773.55,341.74,773.71,341.71z"/>
    <path d="M785.66,368.36c0-0.59,0.12-0.65,0.12-0.59c-0.06,4.83,1.28,9.56,1.35,14.38c0,0.17-0.28,0.2-0.28,0.03
      C786.84,377.35,785.62,373.24,785.66,368.36z"/>
    <path d="M753.83,360.12c0.21-3.73,0.92-6.87,2.23-9.87c0.5-1.14,0.75-1.13,0.7-1.03c-1.65,3.45-2.27,7.2-2.47,10.94
      c-0.2,3.7,0.06,7.48,0.91,11.1c0.05,0.22-0.41-0.09-0.63-1.2C753.86,366.35,753.62,363.88,753.83,360.12z"/>
    <path d="M753.07,353.29c0.26-0.75,0.55-0.86,0.52-0.79c-1.79,4.44-1.73,9.29-0.84,13.9c0.04,0.21-0.39-0.03-0.66-1.59
      C751.34,360.31,751.55,357.6,753.07,353.29z"/>
    <path d="M738.48,353.13c0.02-0.06,0.13-0.03,0.1,0.03c-2.46,7.55-1.42,15.8,0.96,23.25c1.15,3.6,2.97,6.89,5.31,9.94
      c1.15,1.5,2.44,2.91,3.77,4.28c1.35,1.39,2.89,3.27,4.9,3.79c0.26,0.07,0.1,0.42-0.16,0.36c-1.75-0.45-3.03-1.9-4.24-3.09
      c-1.47-1.45-2.85-2.98-4.12-4.57c-2.57-3.22-4.49-6.76-5.75-10.6C736.83,369.09,735.81,360.62,738.48,353.13z"/>
    <path d="M735.39,357.92c0.02-0.08,0.15-0.07,0.13,0.02c-0.81,4.72-0.41,9.47,0.47,14.16c0.45,2.39,1.05,4.75,1.86,7.06
      c0.74,2.12,1.71,5.1,3.7,6.48c0.19,0.13,0,0.43-0.2,0.29c-1.72-1.19-2.48-3.35-3.23-5.15c-1-2.41-1.66-4.95-2.19-7.48
      C734.88,368.29,734.25,362.95,735.39,357.92z"/>
    <path fill="#DC97A7" d="M776.85,357.31c-1.12,0.03-3.54,0.17-4.41,0.29c-0.87,0.12-0.12-3.06,1.68-2.66
      C775.92,355.34,779.08,357.25,776.85,357.31z"/>
  </g>
          </svg>
        </div>
        <div className='content_pistas'>
          <Pistas2></Pistas2>
        </div>
    </div>
  )
}

export default Personajes