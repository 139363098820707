import { createContext, useState } from "react";

const PaletaContext = createContext();


const ContextProvider = ({children}) => {
    const [correcto, setCorrecto] = useState(false);
    const [incorrecto, setIncorrecto] = useState(false);


    return(
        <PaletaContext.Provider
            value = {{
                correcto,
                incorrecto,
                setCorrecto,
                setIncorrecto                
            }}
        >{children}</PaletaContext.Provider>
    )
}


export {
    ContextProvider
}

export default PaletaContext;